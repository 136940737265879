import React from "react";
import Select from 'react-select'

class LabeledDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.select_ref = React.createRef();

    this.update_input_value = this.update_input_value.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.show_errors && prevProps.show_errors != this.props.show_errors) {
      if (this.select_ref.current) {
        this.select_ref.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
      }
    }
  }

  update_input_value(e) {
    this.props.update_input_value(e.target.value)
  }

  render() {
    let show_error = this.props.show_errors && this.props.error_message

    return (
      <div className="labeled-dropdown">
        <div className="dropdown-label">
          {this.props.label}
        </div>
        <select id={this.props.id || ""} value={this.props.selected_value || ""} style={{ color: (this.props.selected_value ? "#000" : "rgba(0, 0, 0, 0.6)") }} onChange={this.update_input_value} ref={this.select_ref} disabled={this.props.disabled || false} className={"dropdown-input" + (show_error ? " dropdown-input-error" : "")}>
          <option value='' hidden={true} label="Select"></option>
          {this.props.options.map((option, i) => (
            <option key={`sel${i}`} value={option.value}>{option.label}</option>
          ))}
        </select>
        {show_error &&
          <div className="dropdown-error">
            {this.props.error_message}
          </div>
        }
      </div>
    );
  }
}

export default LabeledDropdown