import React from "react";
import GraphImage from 'human-connection-graph.svg';
import MemeberIcon from 'icon-join-the-movement-1.svg';
import MoneySpentIcon from 'icon-join-the-movement-2.svg';
import ExperienceIcon from 'icon-join-the-movement-3.svg';

class JoinTheMovementSection extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className="signup-bubble-sections jtm-section">
        <div className="bubble-title">Join the movement!</div>
        <div className="bubble-subtitle">Offline has powered over 30 million Minutes of Human Connection — Members who have <b>used Offline to spend time in the real world.</b></div>
        <div className="human-connection-container">
          <div className="context-bubble">
            <div className="context-title">Minutes of Human Connection™</div>
            <div className="context-subtitle">as of Jan 2024</div>
            <div className="context-value">31,761,288</div>
          </div>
          <img src={GraphImage} alt="" />
        </div>
        <div className="middle-message">
          We've also made $9M+ in revenue for local restaurants. <b>When we say we support local, we mean it.</b>
        </div>
        <div className="footer-plaques">
          <div className="footer-plaque">
            <img src={MemeberIcon} alt="" />
            <div className="footer-plaque-value">18k+ members + 300+ partners</div>
          </div>
          <div className="footer-plaque">
            <img src={MoneySpentIcon} alt="" />
            <div className="footer-plaque-value">$9M+ spent by members at local restaurants</div>
          </div>
          <div className="footer-plaque">
            <img src={ExperienceIcon} alt="" />
            <div className="footer-plaque-value">250k+ IRL experiences</div>
          </div>
        </div>
      </div>
    )
  }
}

export default JoinTheMovementSection