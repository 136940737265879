import React from "react";
import { getIDforGTM } from "../../v2/state_components/gtm_methods"

class EmailInputGUI extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="signup-v3-column signup-column membership-information">
          <div className="membership-info-title">Great, next up email.</div>
          <div className='membership-info-subtitle'>We'll email you reminders about the restaurants you pick.</div>
          <div className="email-input">
            {/* <div className="input-label">Email Address</div> */}
            <input type="text"
              className="membership-info-input signup-v3-input signup-input"
              autoCapitalize="none"
              id="input_email"
              name="email"
              placeholder="Email Address"
              onChange={this.props.changeInputField}
              value={this.props.email} />
            <div className="input-error">
              {this.props.error_email &&
                <span>
                  {this.props.error_email}<br />
                  If you were a previous member, please redownload the app (
                  <a href="https://apps.apple.com/us/app/offline-restaurant-club/id1632270215" >iOS App Store</a> |
                  <a href="https://play.google.com/store/apps/details?id=com.letsgetoffline.lgomobile">Google Play Store</a>
                  ) to sign back in.
                </span>
              }
            </div>

          </div>
          <button
            className={"full-blue-button smaller-text aux-full-width aux-footer"}
            // disabled={this.canContinue() ? "" : "disabled"}
            onClick={this.props.validateAccountDetails}
            id={getIDforGTM('submit-phone-number', this.props)}>
            CONTINUE
          </button>
          <button
            className={"footer-back-button-link aux-full-width aux-footer-low"}
            // disabled={this.canContinue() ? "" : "disabled"}
            onClick={this.props.previousScreen}
            id={getIDforGTM('back-to-previous-screen', this.props)}>
            back
          </button>
        </div>
      </>
    );
  }
}

export default EmailInputGUI