import React from "react";
import CategoryToggle from "./category_toggle"

class CategoryEdit extends React.Component {
  constructor(props) {
    super(props);
    var category_values = {}
    var all_true = true;
    let self = this;
    var pref_data = this.props.assignment_preference.preference_data;
    if (pref_data ==  null) {
      pref_data = this.props.assignment_preference.full_preference_data;
    }


    this.props.categories.forEach(function(category) { 
      var category_value = pref_data.categories.includes(category.id)
      category_values[category.id] = category_value
      all_true = all_true && category_value
    });

    this.state = {
      category_values: category_values,
      give_all_categories: all_true
    }
    this.handleAllCategoryToggle = this.handleAllCategoryToggle.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.getSelectedCategoryIds = this.getSelectedCategoryIds.bind(this);
  }

  getSelectedCategoryIds(selected_hash) {
    var category_ids = [];
    Object.keys(selected_hash).forEach(function(id) {
      if (selected_hash[id]) {
        category_ids.push(parseInt(id));
      }
    });
    return category_ids;
  }

  sendCategoryUpdate(category_ids) {
    this.props.updateToCategories(category_ids);
    $.ajax({
      method: "POST",
      url: "/home/update_assignment_preferences/" + this.props.assignment_preference.id,
      data: {assignment_preferences: {category_ids: category_ids}},
      dataType: 'json'
    });
  }

  handleAllCategoryToggle(event) {
    const new_state = event.target.checked;
    this.setState({give_all_categories: new_state});
    if (new_state) {
      this.sendCategoryUpdate(Object.keys(this.state.category_values));
    } else {
      this.sendCategoryUpdate(this.getSelectedCategoryIds(this.state.category_values))
    }
  }

  handleCategoryChange(event) {
    const target = event.target;
    var category_values_copy = Object.assign({},this.state.category_values);
    category_values_copy[target.value] = target.checked;
    this.setState({
      category_values: category_values_copy    
    });

    this.sendCategoryUpdate(this.getSelectedCategoryIds(category_values_copy));
  }

  renderAllCategories() {
    if (this.state.give_all_categories) {
      return "";
    }

    const categories = this.props.categories.map(category =>(
      <CategoryToggle
        key={category.id}
        id={category.id}
        name={category.name}
        description={category.description}
        isChecked={this.state.category_values[category.id]}
        handleOnChange={this.handleCategoryChange} />
    ))
    return (
      <div>
        {categories}
      </div>
    )
  }

  render() {
    return (
      <div className="member__app-section member__app-section-body">
        <h3 className='flat'>What type of places?</h3>
        <p>Don't want a certain type of spot? Turn it off and we won't send you Offers in that category.</p>
        <div className='text-left'>
          <div className="one-line">
            <div style={{width: "80%"}}>
              <h5>Give me everything</h5>
            </div>
            <div style={{width: "20%"}} className='text-right'>
              <label className="switch">
                <input 
                  type="checkbox"
                  value="all"
                  checked={this.state.give_all_categories}
                  onChange={this.handleAllCategoryToggle} />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          {this.renderAllCategories()}
        </div>
      </div>
    );
  }
}

export default CategoryEdit