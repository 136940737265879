import React from "react";

// Import Icons
import LocationFlag from "flag.svg";

class SignupOfferConfirmCard extends React.Component {
  /* ====== PROPS ======
    * offer:
        An Offer object
    * index (optional):
        Index of offer in parent array
    * is_selected (optional):
        boolean indicating if offer is selected
    * disable_outlinks (optional):
        boolean indicating if links should be disabled
    * select_offer (optional):
        function to call when card is selected
  =================== */
  constructor(props) {
    super(props);

    this.select_offer = this.edit_offer.bind(this);
  }

  edit_offer() {
    if (this.props.select_offer) {
      this.props.select_offer(this.props.offer);
    }
  }

  render_fine_print_days(offer) {
    if (!offer) return (<></>);

    let day_indicators = offer.fine_print_days.map((fpd, i) =>
      <div className="day" key={`${offer.id}${i}`} data-lbl={fpd.state} >
        {fpd.name.substr(0, 1)}
      </div>
    )

    return (
      <div className="fine-print-days">
        {day_indicators}
      </div>
    );
  }

  render() {
    if (!this.props.offer) {
      return (<></>);
    }

    let img_url = this.props.offer.images[0]?.image;
    let instagram_url = this.props.offer.images[0]?.credit_link;

    let banner_media = <img className="card-header-image" src={img_url || ""} />;
    if (!this.props.disable_outlinks && instagram_url) {
      banner_media = (
        <a className="card-header-image" href={instagram_url} target="_blank">
          {banner_media}
        </a>
      )
    }

    let banner_msg = (<></>);
    return (
      <div data-num={this.props.index || 0} className={"signup-offer-confirm-card" + (this.props.is_selected ? " card-selected" : "")}>
        <div className="is-selected">
          <div className="selected-overlay"></div>
          <div className="image-container" ref={this.confetti_ref}>
          </div>
        </div>
        {/* <div className={"already-selected-card" + (this.props.chosen ? "" : " hide-selected")}>
          <div className="stamp-container">
            <div className="stamp">
              ALREADY CONFIRMED
            </div>
          </div>
        </div> */}
        {banner_media}
        {banner_msg}
        <div className="spending-banner">
          <div className="max-one-text-lines w-side-pad">
            {this.props.offer?.offer_amount || ""}
          </div>
        </div>
        <div className="text-area relative">
          <div className="location-subtitle">
            <img src={LocationFlag} />
            {this.props.offer?.location || ""}
          </div>
          <div className="edit-offer-card" onClick={this.props.edit_offer}>
            Edit
          </div>
          <div className="signup-offer-card-title max-one-text-lines">{this.props.offer.name}</div>
          <div className="business-teaser">
            <div className="max-one-text-lines">
              {this.props.offer?.business_teaser || ""}
            </div>
          </div>
          {this.render_fine_print_days(this.props.offer)}
        </div>
      </div>
    );
  }
}

export default SignupOfferConfirmCard;