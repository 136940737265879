import React from "react";

class BulletPoint extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='listed-bullet-point'>
        { this.props.icon &&
          <div className='bullet-point-icon'>
            <img src={this.props.icon} />
          </div>
        }
        <div className='bullet-point-text'>
          {this.props.text}
        </div>
      </div>
    );
  }
}

export default BulletPoint