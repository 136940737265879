import React from "react";
import IconClose from "icon-close.svg"

class ActiveSelection extends React.Component {
  constructor(props) {
    super(props);

    this.clear_selection = this.clear_selection.bind(this);
  }

  clear_selection() {
    // this.setState({ selected_offer: null });
    this.props.clear_selection(this.props.slot_id)
  }

  render() {
    let label = this.props.selected_offer ? this.props.selected_offer.name : "No Offer Selected";
    label = this.props.error_message? "SELECT AN OFFER TO CONTINUE" : label;

    return (
      <div className={"offer-indicator" + (this.props.selected_offer ? " offer-selected" : "") + (this.props.error_message ? " error-display" : "")}>
        <div className="offer-title">
          {/* <div className="offer-number">{this.props.total_selected_offers}</div> */}
          <div className="offer-label max-two-text-lines">{label}</div>
        </div>
        <button className="clear-offer-selection" onClick={this.clear_selection}>
          <img src={IconClose} alt="✕" />
        </button>
      </div>
    );
  }
}

export default ActiveSelection