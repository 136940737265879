import React from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentElement from "../../../sign_up/stripe_payment_element";
import { getIDforGTM } from "../../v2/state_components/gtm_methods"
import IconLock from "icon-secure.svg";
import PostHogEnabled from "../../../../helpers/posthog_enabled";

class PaymentInputGUIV3 extends React.Component {
  constructor(props) {
    super(props);

    this.stripePromise = loadStripe(this.props.stripe_key);
  }

  render() {
    const fonts = [
      { cssSrc: "https://fonts.googleapis.com/css?family=Zilla+Slab:400,400i,600,600i|Patrick+Hand+SC&display=swap" },
      { cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i|Source+Sans+Pro:400,400i,700,700i" },
      { cssSrc: "https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700;800&display=swap" }
    ];

    return (
      <div className="signup-payment-column sub-fix">
        <div className="payment-description-container">
          <div className="payment-title">
            <PostHogEnabled
              flag={"signup-payment-info"}
              variant={"first-month-free"}
              fallback={<span>Choose how to pay</span>}>
              <span>First month <span className="payment-underscore">free</span></span>
            </PostHogEnabled>
            <img src={IconLock} alt="" />

          </div>
          <div className="payment-subtitle">
            Select a payment method below
          </div>
          <PostHogEnabled
            flag={"signup-payment-info"}
            variant={"first-month-free"}
            fallback={
              <div className="payment-description">
                <div className="payment-item-box">
                  <div className="trial-duration">
                    1 Month Free
                  </div>
                  <div className="due-today">
                    $0.00 due today
                  </div>
                </div>
              </div>
            }
          >
            <div className="payment-description">
              <div className="description-item">
                ✅ $0 due today
              </div>
              <div className="description-item">
                ✅ skip/cancel any time
              </div>
              <div className="description-item">
                {`✅ we'll text ${this.props.phone_number || "you"} before your first charge so you have a heads up`}
              </div>
            </div>
          </PostHogEnabled>

        </div>
        <Elements
          stripe={this.stripePromise}
          options={
            {
              clientSecret: this.props.setup_intent.client_secret,
              fonts: fonts,
              appearance: {
                variables: {
                  fontFamily: "Zilla Slab",
                  fontWeightNormal: "600",
                  colorPrimary: "#000"
                },
                rules: {
                  '.Label': {
                    fontSize: '16px'
                  },
                  '.Input': {
                    boxShadow: 'none',
                    border: "2px solid #000"
                  },
                  '.Tab--selected': {
                    backgroundColor: "#FFBD54",
                    border: "3px solid #000"
                  },
                  '.Text--terms': {
                    color: "rgba(0,0,0,0.0)",
                    fontSize: "0.1px"
                  }
                }
              }
            }
          }>
          <StripePaymentElement
            redirect_url={this.props.confirmation_path}
            button_class={"full-blue-button smaller-text"}
            subscription_terms_object={{ payment_submit_text: "START YOUR FREE TRIAL" }}
            submit_event={this.props.trackPaymentSubmit}
            button_id={getIDforGTM("submit-payment", this.props)}
          />

        </Elements>
        <button
          className={"footer-back-button-link aux-full-width"}
          // disabled={this.canContinue() ? "" : "disabled"}
          onClick={this.props.previousScreen}
          id={getIDforGTM('back-to-previous-screen', this.props)}>
          back
        </button>
      </div>
    );
  }
}

export default PaymentInputGUIV3