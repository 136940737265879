import React from "react";
import ProgressBar from "../../shared/progress_bar"
import CodeOfConduct from "./code_of_conduct"
import SetupProfile from "./setup_profile"
import SetupPhoneNumber from "./setup_phone_number"
import SetupOfferCustomize from "./setup_offer_customize"
import SetupComplete from "./setup_complete"

class MemberSetup extends React.Component {
  constructor(props) {
    super(props);

    this.setup_total_steps = 4

    var current_step = this.props.preference_setup_step + 1

    // Skip phone number step if already filled in
    // if (current_step == 3 && this.props.phone_number != '' && this.props.phone_number != null) {
    //   current_step = 4
    // }

    this.state = {
      setup_progress: current_step,
      link_offer: this.props.preference_link_offer,
      phone_number: this.props.phone_number
    }

    this.proceedToNextStep = this.proceedToNextStep.bind(this);
    this.goBackOneStep = this.goBackOneStep.bind(this);
    this.updatePhoneNumber = this.updatePhoneNumber.bind(this);
  }

  updatePhoneNumber(number) {
    this.setState({phone_number: number})
  }

  proceedToNextStep(num) {
    var next_step = num;

    // if (next_step > this.setup_total_steps) {
    //   window.location.href = this.props.home_path
    // } 

    // if (next_step == 3 && this.props.phone_number) {
    //   next_step = 4;
    // }

    this.setState({setup_progress: next_step})
    window.scrollTo({
      top: 0
    });
  }

  goBackOneStep() {
    var next_step = this.state.setup_progress - 1
    // if (next_step == 3 && this.props.phone_number) {
    //   next_step = 2;
    // }

    this.setState({setup_progress: next_step})
    window.scrollTo({
      top: 0
    });
  }

  checkDisplayState(val) {
    if (val == this.state.setup_progress) {
      return 'block'
    }
    return 'none'
  }

  render() {
    return (
      <div className='text-left'>
        <ProgressBar step={this.props.currentScreen + 1} total_steps={this.props.total_screens + 1} />

        <div style={{display: this.checkDisplayState(1)}}>
          <CodeOfConduct 
            proceedWithSetup={this.proceedToNextStep}
            setupStep={1}
            account_id={this.props.id}/>
        </div>
        <div style={{display: this.checkDisplayState(2)}}>
          <SetupProfile 
            proceedWithSetup={this.proceedToNextStep}
            setupStep={2}
            backAction={this.goBackOneStep}
            account_id={this.props.id}
            social_handles={this.props.social_handles}
            preference_birthday_month={this.props.preference_birthday_month}
            preference_gender={this.props.preference_gender}
            preference_relationship={this.props.preference_relationship}
            preference_income={this.props.preference_income}
            preference_spending={this.props.preference_spending}
            preference_dog_friendly={this.props.preference_dog_friendly}
            preference_family_offers={this.props.preference_family_offers}/>
        </div>
        <div style={{display: this.checkDisplayState(3)}}>
          <SetupPhoneNumber 
            proceedWithSetup={this.proceedToNextStep}
            setupStep={3}
            goBackOneStep={this.goBackOneStep}
            account_id={this.props.id}
            phone_number={this.props.phone_number}
            syncPhoneNumber={this.updatePhoneNumber}/>
        </div>
        <div style={{display: this.checkDisplayState(4)}}>
          <SetupOfferCustomize 
            goBackOneStep={this.goBackOneStep}
            account_id={this.props.id}
            phone_number={this.state.phone_number}
            link_account={this.state.link_offer}
            proceedWithSetup={this.proceedToNextStep}
            setupStep={4}/>
        </div>
        <div style={{display: this.checkDisplayState(5)}}>
          <SetupComplete
            is_active={this.state.setup_progress == 5}
            homePath={this.props.home_path}/>
        </div>
      </div>
    );
  }
}

export default MemberSetup