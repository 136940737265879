import React from "react";

class GoodFitQuestions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="good-fit-questions">
          <div className="new-member-questions">
            <div className="input-label">When it comes to <i>supporting</i> local restaurants, you</div>
            <select
              id="supporting_local"
              name="supporting_local"
              onChange={this.props.changeInputField}
              value={this.props.supporting_local}
              style={{ color: (this.props.supporting_local ? "#000" : "rgba(0, 0, 0, 0.6)") }} >
              <option value="" label="Select"></option>
              <option value="constantly_tip">Constantly tip generously</option>
              <option value="tip_based_on_service">Vary tip based on service</option>
              <option value="tipping_is_questionable">Think the concept of tipping is questionable</option>
            </select>
            {this.props.error_supporting_local &&
              <div className="input-error">{this.props.error_supporting_local}</div>
            }

            <div className="input-label">If you had to pick one, what's the #1 reason you want to become a Member?</div>
            <select
              id="reason_to_become_member"
              name="reason_to_become_member"
              onChange={this.props.changeInputField}
              value={this.props.reason_to_become_member}
              style={{ color: (this.props.reason_to_become_member ? "#000" : "rgba(0, 0, 0, 0.6)") }} >
              <option value="" label="Select"></option>
              <option value="explore_restaurants">Explore new restaurants</option>
              <option value="save_money">Save a few bucks dining out</option>
              <option value="meet_people">Meet new people in town</option>
            </select>
            {this.props.error_reason_to_become_member &&
              <div className="input-error">{this.props.error_reason_to_become_member}</div>
            }
          </div>
      </div>
    );
  }
}

export default GoodFitQuestions