import React from "react";
import SideBanner0 from 'sign-up-side-banner-0.jpg';
import SideBanner480 from 'sign-up-side-banner-480.jpg';
import SideBanner640 from 'sign-up-side-banner-640.jpg';
import SideBanner960 from 'sign-up-side-banner-960.jpg';
import SideBanner1024 from 'sign-up-side-banner-1024.jpg';
import IconConfirmation from "icon-confirmation";

import CityLogo from 'logo-city.png'
import WelcomePage from "./welcome_page";
import CardValidationPage from "./card_validation_page"

class ConfirmationPage extends React.Component {
  constructor(props) {
    super(props);

    // Define city_logos locally so we can conditionally
    // chose a logo
    let area = props.area || { name: "Raleigh" }

    this.state = {
      area,
      intent: this.props.intent,
      account_status: this.props.account_status || "pending"
    }

    // Function bindings
    this.handleIntentCheckResponse = this.handleIntentCheckResponse.bind(this);
    this.selectContent = this.selectContent.bind(this);
    this.renderMainContent = this.renderMainContent.bind(this);
    this.renderSideContent = this.renderSideContent.bind(this);
  }

  handleIntentCheckResponse(data) {
    if (data.account_status == "success") {
      this.setState({ account_status: data.account_status });
    } else if (data.account_status == 'error') {
      window.location.href = '/add-new-card?id=' + this.state.intent.customer.id
    }
  }

  selectContent() {

    if (this.state.account_status == 'success') {
      if (this.props.reactivating) {
        return (
          <div id="pick_your_own_offers">
            <div className="confirmation-container" style={{textAlign: 'center'}}>
              <div className="offer-confirmation-title">Account Reactivated!</div>
              <div className="offer-confirmation-subtitle">Head back to the app to get started.</div>
              <img src={IconConfirmation} className="confirmation-icon" />
            </div>
          </div>
        )
      }
      return (
        <WelcomePage
          city={this.state.area.name}
        />
      );
    } else {
      return (
        <CardValidationPage
          messaging={"Please wait while we setup your Offline account"}
          handleResponse={this.handleIntentCheckResponse}
          intent={this.state.intent} />
      );
    }

  }

  renderMainContent() {
    let content = this.selectContent();

    return (
      <div className="confirmation-main-content">
        <img className="confirmation-banner" src={CityLogo} />
        {content}
      </div>
    );
  }

  renderSideContent() {
    return (
      <div className="confirmation-side-content">
        <img
          srcSet={`${SideBanner0} 1w, ${SideBanner480} 480w, ${SideBanner640} 640w, ${SideBanner960} 960w, ${SideBanner1024} 1024w`}
          sizes="(max-width: 1224px) 1px, (max-width: 1800px) 480px, (max-width: 2600px) 640px, (max-width: 3200px) 960px, 1024px"
        />
      </div>
    );
  }

  render() {
    return (
      <div className="confirmation-page-container">
        {this.renderMainContent()}
        {this.renderSideContent()}
      </div>
    );
  }
}

export default ConfirmationPage