export const validateEmail = (context, onSuccess) => {
  return function() {
    $.ajax({
      method: "POST",
      url: "/signupvx/validate-email",
      data: {email: context.state.email},
      success: (data) => { 
        // Can standardize the data here
        onSuccess({email: context.state.email});
      },
      error: (data) => { context.setState({error_email: data.responseJSON.error.message})}
    });  
  }
};

export const validateName = (context, onSuccess) => {
  return function() {
    let errors = {};
    if (context.state.first_name.length == 0) {
      errors.error_first_name = "Please enter your first name."
    } 
    if (context.state.last_name.length == 0) {
      errors.error_last_name = "Please enter your last name."
    }
    if (Object.keys(errors).length > 0) {
      context.setState(errors);
    } else {
      onSuccess({first_name: context.state.first_name, last_name: context.state.last_name});
    }
  }
};

export const validatePhoneNumber = (context, onSuccess) => {
  return function() {
    $.ajax({
      method: "POST",
      url: "/signupvx/validate-phone-number",
      data: {phone_number: context.state.phone_number},
      success: (data) => { 
        // Can standardize the data here
        onSuccess({phone_number: context.state.phone_number});
      },
      error: (data) => { context.setState({error_phone_number: data.responseJSON.error.message})}
    });  
  }
};
