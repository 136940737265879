import React from "react";

import PlacesAutocomplete from "../shared/places_autocomplete";

class MultiPlacesAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addresses: this.props.addresses,
      unsaved: this.props.unsaved || false
    }
  }

  componentDidUpdate(prev_props) {
    if (prev_props.addresses !== this.props.addresses || prev_props.unsaved !== this.props.unsaved) {
      this.setState({ addresses: this.props.addresses, unsaved: this.props.unsaved });
    }
  }

  onAddressUpdate = (update_address) => {
    let addresses = this.state.addresses;
    addresses[update_address.index] = update_address.full_address_object;
    addresses[update_address.index].unsaved = true;

    this.setState({ addresses, unsaved: true });
    if (this.props.onAddressUpdate) this.props.onAddressUpdate(addresses);
  }

  getAddressString = (address) => {
    if (!address || Object.keys(address).length < 2) return '';
    let address_join_array = [];

    address_join_array.push(address.name);
    address_join_array.push(address.street_line_1);
    if (address.street_line_2) address_join_array.push(address.street_line_2);
    address_join_array.push(address.city);
    address_join_array.push(address.state + ' ' + address.zip);

    return address_join_array.join(', ')
  }

  canAddNewAddress = () => {
    let addresses = this.state.addresses;
    let compare_addresses = addresses.filter(x => !x["_destroy"]);

    return !compare_addresses.length || (compare_addresses.length && Object.keys(compare_addresses[compare_addresses.length - 1]).length > 1);
  }

  addAddress = () => {
    let addresses = this.state.addresses;
    if (this.canAddNewAddress()) {
      addresses.push({});
    }

    this.setState({ addresses, unsaved: true });
    if (this.props.onAddressUpdate) this.props.onAddressUpdate(addresses);
  }

  removeAddress = (event) => {
    let addresses = this.state.addresses;
    let index = event.target.dataset.index;

    if (addresses[index].id) {
      addresses[index]["_destroy"] = true;
    } else {
      addresses.splice(index, 1);
    }

    this.setState({ addresses, unsaved: true });
    this.forceUpdate();
    if (this.props.onAddressUpdate) this.props.onAddressUpdate(addresses);
  }

  getDefaultButton(index) {
    return (
      <button
        data-index={index}
        onClick={this.removeAddress}
      >
        remove
      </button>
    )
  }

  render() {
    let address_inputs = this.state.addresses.map((address, index) => {

      let classList = ['multi-places-address'];

      let button = this.getDefaultButton(index);
      if (address["_destroy"]) {
        button = <></>
      } else {
        classList.push('has_button');
      }


      if (address["_destroy"]) classList.push('_destroy');
      if (address["unsaved"]) classList.push('unsaved-changes');

      return (
        <div key={this.getAddressString(address) + index} className={classList.join(' ')}>
          <PlacesAutocomplete
            index={index}
            address={address}
            onWebRequestComplete={this.onAddressUpdate}
          />
          {button}
        </div>
      )
    });

    let add_new_address_button_class = this.canAddNewAddress() ? '' : 'disable-input';

    return (
      <div className={"multi-places-autocomplete-container" + (this.props.unsaved ? ' unsaved' : '')}>
        <div className="multi-places-addresses">
          {address_inputs}
        </div>
        <button className={" " + add_new_address_button_class} onClick={this.addAddress}>
          Add Address
        </button>
      </div>
    )
  }
}

export default MultiPlacesAutocomplete;