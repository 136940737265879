// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import 'selectric/public/jquery.selectric.min.js'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import $ from 'jquery';
import 'selectric';

document.addEventListener("turbolinks:load", () => {
  $(".selectric-list").selectric({
     labelBuilder: '{value}'
  });

  $(".selectric-list-with-name").selectric();

  $(".selectric-selection-per-line").selectric({
    multiple: {
      separator: "\n"
    }
  });
})
// require("jquery")
// import {} from 'jquery-ujs'

// new webpack.ProvidePlugin({
//   $: 'jquery',
//   jQuery: 'jquery'
// })

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
