export const retrieveSignupOffers = (area, onSuccess, onError = null) => {
  if (!onError) {
    onError = () => { };
  }

  $.ajax({
    method: "GET",
    url: `/signup2/${area.slug.toLowerCase()}/retrieve_signup_offers`,
    data: {},
    dataType: 'json',
    success: (data) => { onSuccess(data, area) },
    error: onError
  });
};

export const createOrUpdateStripeCustomer = (params, onSuccess, onError = null) => {
  if (!onError) {
    onError = () => { };
  }

  $.ajax({
    method: "POST",
    url: `/signup2/update-stripe-customer`,
    data: params,
    dataType: 'json',
    success: (data) => { onSuccess(data) },
    error: onError
  });
};