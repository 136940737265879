import React from "react";

class ReportCalculator extends React.Component {
  constructor(props) {
    super(props);

    this.filterToReviewedOfferAssignments = this.filterToReviewedOfferAssignments.bind(this);
    this.filterToHighlyReviewedOfferAssignments = this.filterToHighlyReviewedOfferAssignments.bind(this);
    this.filterToOtherReviewedOfferAssignments = this.filterToOtherReviewedOfferAssignments.bind(this);
    this.calculateOverallRating = this.calculateOverallRating.bind(this);
    this.calculateNewCustomers = this.calculateNewCustomers.bind(this);
    this.calculateBroughtNonOfflineFriends = this.calculateBroughtNonOfflineFriends.bind(this);
    this.calculateWouldGoAgain = this.calculateWouldGoAgain.bind(this);
    this.calculateReportFields = this.calculateReportFields.bind(this);
  }

  filterToReviewedOfferAssignments(offer_assignments) {
    return offer_assignments.filter(oa => oa.redemption_status == "reviewed");
  }

  filterToHighlyReviewedOfferAssignments(offer_assignments, areFilteredReviewedAssignments = true) {
    if (!areFilteredReviewedAssignments) {
      offer_assignments = this.filterToReviewedOfferAssignments(offer_assignments);
    }
    return offer_assignments.filter(oa => ![1, 2, 3].includes(parseInt(oa.customer_rating)));
  }

  filterToOtherReviewedOfferAssignments(offer_assignments, highly_reviewed_offer_assignments, areFilteredReviewedAssignments = true, areFilteredHighlyReviewedAssignments = true) {
    if (!areFilteredReviewedAssignments) {
      offer_assignments = this.filterToReviewedOfferAssignments(offer_assignments);
    }
    if (!areFilteredHighlyReviewedAssignments) {
      highly_reviewed_offer_assignments = this.filterToHighlyReviewedOfferAssignments(offer_assignments);
    }
    return offer_assignments.filter(oa => !highly_reviewed_offer_assignments.includes(oa));
  }

  calculateOverallRating(reviewed_offer_assignments) {
    if (reviewed_offer_assignments.length == 0) return "NO DATA";
    let overallRating = 0;
    let offer_assignments_with_rating = reviewed_offer_assignments.filter(oa => oa.customer_rating);
    offer_assignments_with_rating.forEach((offer_assignment) => {
      overallRating += parseInt(offer_assignment.customer_rating);
    });
    return (overallRating / offer_assignments_with_rating.length).toFixed(1);
  }

  calculateNewCustomers(highly_reviewed_offer_assignments, as_percentage = true) {
    if (highly_reviewed_offer_assignments.length == 0) return 0;
    let answered_has_been_before = highly_reviewed_offer_assignments.filter(oa => oa.been_in_prior_months != null || this.isABoolean(this.survey_answer_boolean(oa.survey_answers?.has_been_before)));
    let total = answered_has_been_before.filter(oa => oa.been_in_prior_months == false || this.survey_answer_boolean(oa.survey_answers?.has_been_before) == false);
    total = total.filter(oa => !this.survey_answer_boolean(oa.survey_answers?.is_regular)).length;
    if(!as_percentage) return total;
    return Math.round((total / answered_has_been_before.length) * 100);
  }

  calculateBroughtNonOfflineFriends(reviewed_offer_assignments) {
    if (reviewed_offer_assignments.length == 0) return "NO DATA";
    let answered_has_went_with_non_member = reviewed_offer_assignments.filter(oa => oa.went_with_non_member != null || this.isABoolean(this.survey_answer_boolean(oa.survey_answers?.went_with_non_premium)));

    return Math.round(answered_has_went_with_non_member.filter(oa => oa.went_with_non_member || this.survey_answer_boolean(oa.survey_answers?.went_with_non_premium)).length / answered_has_went_with_non_member.length * 100);
  }

  calculateWouldGoAgain(offer_assignments) {
    if (offer_assignments.length == 0) return "NO DATA";

    let answered_would_go_again = offer_assignments.filter(oa => oa.redemption_status == "reviewed" && (oa.will_go_again != null || this.isABoolean(this.survey_answer_boolean(oa.survey_answers?.will_go_again))));
    let will_not_go_again = answered_would_go_again.filter(oa => oa.will_go_again == false || this.survey_answer_boolean(oa.survey_answers?.will_go_again) == false || [1, 2, 3].includes(parseInt(oa.customer_rating)));

    return Math.round((offer_assignments.length - will_not_go_again.length) / offer_assignments.length * 100);
  }

  calculateReportFields(partner_report) {
    let reviewed_offer_assignments = null;
    let highly_reviewed_offer_assignments = null;
    let other_reviewed_offer_assignments = null;
    let discounts = null;
    let total_cost_of_sales = null;
    let new_sales_above_discount = null;
    let cost_of_goods_sold = null;
    let total_customers = null;
    let members_matched = null;
    let social_links = null;
    let total_cash = null;
    let net_revenue = null;
    let kickback_due = null;
    let cost_per_customer = null;
    let average_spend_per_user = null;
    let average_discount_per_user = null;
    let total_spent = partner_report.total_spent;

    reviewed_offer_assignments = this.filterToReviewedOfferAssignments(partner_report.offer_assignments);
    highly_reviewed_offer_assignments = this.filterToHighlyReviewedOfferAssignments(reviewed_offer_assignments);
    other_reviewed_offer_assignments = this.filterToOtherReviewedOfferAssignments(reviewed_offer_assignments, highly_reviewed_offer_assignments);

    discounts = partner_report.total_discounted;
    total_cost_of_sales = (-1) * partner_report.total_spent * partner_report.cost_of_goods_sold;
    new_sales_above_discount = total_spent - discounts;
    cost_of_goods_sold = partner_report.cost_of_goods_sold;
    total_customers = partner_report.customers_redeemed || partner_report.offer_assignments.filter(oa => oa.redemption_status == "reviewed" || oa.redemption_status == "redeemed").length;
    social_links = partner_report.social_post_block;
    members_matched = partner_report.offer_assignments.length;

    if (discounts != null && total_spent != null && cost_of_goods_sold != null && (partner_report.customers_redeemed != null || total_customers != 0)) {
      total_cash = total_spent - discounts;
      if(total_customers) {
        average_spend_per_user = (total_spent / total_customers).toFixed(2);
        average_discount_per_user = (discounts / total_customers).toFixed(2);
      } else {
        average_spend_per_user = 'NO DATA';
      }
      net_revenue = (total_cash - (total_spent * cost_of_goods_sold)).toFixed(2);

      if (net_revenue > 0) {
        kickback_due = 0;
        cost_per_customer = (net_revenue / total_customers).toFixed(2);
      } else {
        // We share the costs if the business did not make money
        // kickback_due = Math.abs(net_revenue * partner_report.cost_share / 100.0).toFixed(2);
        // cost_per_customer = (net_revenue * (100 - partner_report.cost_share) / 100.0 / total_customers).toFixed(2);
        cost_per_customer = (net_revenue / total_customers).toFixed(2);
      }

      
    }

    let report_fields = {
      overall_rating: this.calculateOverallRating(reviewed_offer_assignments),
      new_customers: this.calculateNewCustomers(highly_reviewed_offer_assignments),
      brought_non_offline_friends: this.calculateBroughtNonOfflineFriends(reviewed_offer_assignments),
      would_go_again: this.calculateWouldGoAgain(reviewed_offer_assignments),
      above_4_new_customers: this.calculateNewCustomers(highly_reviewed_offer_assignments),
      above_4_brought_non_offline_friends: this.calculateBroughtNonOfflineFriends(highly_reviewed_offer_assignments),
      above_4_would_go_again: this.calculateWouldGoAgain(highly_reviewed_offer_assignments),
      reviewed_offer_assignments,
      highly_reviewed_offer_assignments,
      other_reviewed_offer_assignments,
      total_customers,
      discounts,
      total_cost_of_sales,
      average_spend_per_user,
      average_discount_per_user,
      new_sales_above_discount,
      cost_of_goods_sold,
      total_cash,
      net_revenue,
      kickback_due,
      cost_per_customer,
      social_links,
      members_matched,
      total_spent
    }

    return report_fields;
  }

  survey_answer_boolean(value) {
    if (value == "yes") return true;
    if (value == "no") return false;
    return value;
  }

  isABoolean(value) {
    return typeof value === 'boolean';
  }

  render() {
    return (<></>)
  }
}

export default ReportCalculator