import React from "react";
import StarIcon from 'icon-testimonial-star.svg'
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'

class TestimonialSection extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className="testimonial-section signup-bubble-sections">
        <div className="bubble-banner">
          <img src={StarIcon} alt="" />
          <img src={StarIcon} alt="" />
          <img src={StarIcon} alt="" />
          <img src={StarIcon} alt="" />
          <img src={StarIcon} alt="" />
        </div>
        <div className="bubble-title">Don't take our word for it</div>
        <div className="bubble-subtitle">Unfiltered experiences from real people who are loving their Offline memberships.</div>
        <div className="testimonial-flex">
          {
            this.props.testimonials.map((testimonial, index) => {
              return (
                <div key={index} className="testimonial-container">
                  <div className="testimonial-content">
                    <div className="testimonial-quote">
                      <ReactMarkdown
                        className="rendered-message"
                        remarkPlugins={[remarkGfm]}
                        children={testimonial.message}
                      />
                    </div>
                    <div className="testimonial-name">{testimonial.name}</div>
                    <div className="testimonial-year">
                      {"Member since " + testimonial.year_joined}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default TestimonialSection