import React from "react";
import MessageIconOne from 'icon-messaging-stock-1.png'
import MessageIconTwo from 'icon-messaging-stock-2.png'
import MessageIconThree from 'icon-messaging-stock-3.png'
import MessageIconFour from 'icon-messaging-stock-4.png'
import MessageIconFive from 'icon-messaging-stock-5.png'

class MessagingSection extends React.Component {
  constructor(props) {
    super(props);

    this.messages = [
      {
        image: MessageIconOne,
        name: "Sarah B.",
        message: "As someone new to the triangle area, Offline has made it fun and easy for me to try new places and discover new hot spots with their events."
      },
      {
        image: MessageIconTwo,
        name: "Anne C.",
        message: "I love being to able to support businesses in the community I may not have known about otherwise.",
      },
      {
        image: MessageIconThree,
        name: "Milan P.",
        message: "I love that we can try new places we don’t know about, without having to do all the “research”.",
      },
      {
        image: MessageIconFour,
        name: "Mark D.",
        message: "This makes our friends dates a commitment for everyone while we get to try out new fun places in town. So far we found some hidden gems and loved them!",
      },
      {
        image: MessageIconFive,
        name: "Gretchen D.",
        message: "I love going to know my area, and all the new spots opening up! I love feeling “in the know”.",
      }
    ];
  }


  render() {
    return (
      <div className="signup-bubble-sections messaging-section">
        <div className="bubble-title">Random people keep messaging our CEO...</div>
        <div className="messages-container">
          {
            this.messages.map((message, index) => {
              return (
                <div key={"msg_0" + index} className="message-container">
                  <div className="message-content">
                    <div className="message-image">
                      <img src={message.image} alt="" />
                    </div>
                    <div className="message-text">{message.message}</div>
                    <div className="message-name">{"-" + message.name}</div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="bottom-gradient"></div>
      </div>
    )
  }
}

export default MessagingSection