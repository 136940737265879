import React from "react";

class WrappingTooltip extends React.Component {
  constructor(props) {
    super(props);

    this.wrapper_ref = React.createRef();
    this.removeFocus = this.removeFocus.bind(this);
    this.getStyleObject = this.getStyleObject.bind(this);
  }

  getStyleObject() {
    let style = {};
    this.setStyle(style, "--left-offset", this.props.left_offset);
    this.setStyle(style, "--right-offset", this.props.right_offset);
    this.setStyle(style, "--max-width", this.props.max_width);
    if (this.props.width) {
      this.setStyle(style, "width", this.props.width);
    }

    let top_offset = this.props.top_offset;
    let bottom_offset = this.props.bottom_offset;
    if (this.props.open_upwards) {
      bottom_offset = bottom_offset || 0;
    } else {
      top_offset = top_offset || 0;
    }

    this.setStyle(style, "--top-offset", top_offset);
    this.setStyle(style, "--bottom-offset", bottom_offset);

    return style;
  }

  setStyle(style, name, value) {
    if (isNaN(parseInt(value))) return false
    style[name] = `${value}px`;
  }

  removeFocus(e) {
    if (this.wrapper_ref.current) {
      if (document.activeElement === this.wrapper_ref.current) {
        e.preventDefault();
        this.wrapper_ref.current.blur();
      }
    }
  }

  render() {
    let container_class = "wrapping-tooltip-container";
    if (this.props.display_inline) container_class += " inline";
    if (this.props.shrink_fit) container_class += " wrap-small-element";

    if (this.props.tooltip_element) {
      return (
        <div ref={this.wrapper_ref} className={container_class} tabIndex={0} onMouseDown={this.removeFocus}>
          {this.props.children}
          <div style={this.getStyleObject()} className={"wrapping-tooltip-dialog"}>
            {this.props.tooltip_element}
          </div>
        </div>
      );
    }

    return (
      <div ref={this.wrapper_ref} className={container_class} tabIndex={0} onMouseDown={this.removeFocus}>
        {this.props.children}
        <div style={this.getStyleObject()} className={"wrapping-tooltip-dialog"}>
          {this.props.tooltip_text}
        </div>
      </div>
    );
  }
}

export default WrappingTooltip