import React from "react";
import moment from "moment-timezone";
import OfferModal from "./offer_modal";

class AccountAssignmentSlots extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assignment_slot_modal_open: null,
    };

    this.getCategoryNameFromID = this.getCategoryNameFromID.bind(this);
    this.getLocationNameFromID = this.getLocationNameFromID.bind(this);
    this.getOfferTypeFromID = this.getOfferTypeFromID.bind(this);
    this.getOfferNameFromID = this.getOfferNameFromID.bind(this);
    this.getOfferForAssignmentSlot = this.getOfferForAssignmentSlot.bind(this);
    this.open_assignment_slot_modal = this.open_assignment_slot_modal.bind(this);
  }

  getCategoryNameFromID(id) {
    let category = this.props.offer_select.categories.find((category) => { return category.id == id });
    return category ? category.name : "Unknown";
  }

  getLocationNameFromID(id) {
    let location = this.props.offer_select.locations.find((location) => { return location.id == id });
    return location ? location.name : "Unknown";
  }

  getOfferTypeFromID(id) {
    let offer_type = this.props.offer_select.offer_types.find((offer_type) => { return offer_type.id == id });
    return offer_type ? offer_type.name : "Unknown";
  }

  getOfferNameFromID(id) {
    let offer = this.props.offer_select.offers.find((offer) => { return offer.id == id });
    return offer ? offer.name : "Unknown";
  }

  getOfferForAssignmentSlot(slot_id) {
    let offer_assignment = this.props.offer_assignments.find((assignment) => { return assignment.assignment_slot.id == slot_id });
    return offer_assignment ? offer_assignment : { offer_name: "None", offer_start: "None", offer_end: "None" };
  }

  open_assignment_slot_modal(e) {
    let target = e.target;
    let slot_id = parseInt(target.getAttribute("data-id"));
    let slot = this.props.assignment_slots.find((slot) => { return slot.id == slot_id });

    this.setState({ assignment_slot_modal_open: slot });
  }

  render_assignment_slots() {
    let assignment_slots = this.props.assignment_slots;

    return assignment_slots.map((slot) => {
      let offer_assignment = this.getOfferForAssignmentSlot(slot.id);
      let offer_start = offer_assignment.offer_start == "None" ? null : moment(offer_assignment.offer_start).format("MMM Do, YYYY");
      let offer_end = offer_assignment.offer_end == "None" ? null : moment(offer_assignment.offer_end).format("MMM Do, YYYY");

      let this_month_style = {};
      let this_month_text = "Yes";
      let assigned_through = `${offer_start} — ${offer_end}`;
      let assignment_type = slot.assignment_type;
      if (!offer_end) {
        this_month_style = { color: "red" };
        this_month_text = "No";
        assigned_through = "N/A";
        assignment_type = "N/A";
      } else if (moment(offer_end).isBefore(moment())) {
        this_month_style = { color: "red" };
        this_month_text = "No";
      }
      

      return (<div key={'as' + slot.id} className="admin__card-basic">
        <h2>{slot.name}</h2>
        <div>
          <b>{`Last Assigned Offer: `}</b>
          {offer_assignment.offer_name}
          <br />
          <b style={this_month_style}>Slot has an Active Assignment</b>
          {` ${this_month_text}`}
          <br />
          <b>{`Assigned through: `}</b>
          {assigned_through}
          <br />
          <b>{`Assignment Type: `}</b>
          {assignment_type}
          <br />
          <br />
          <input type="button" value="Assign an Offer" data-id={slot.id} className="admin__btn--create-new" onClick={this.open_assignment_slot_modal} />
          {/* <b>Valid Offers:</b>
          <br />
          <b>Already assigned but not used:</b> */}
          {/* <button
            style={{ marginLeft: "0.5em" }}
            onclick="copyLinksToClipboard(this)"
          >
            Copy Offer Links
          </button> */}
          <ul style={{ marginTop: "1em" }}></ul>
        </div>
      </div>)
    });
  }

  render() {
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    return (
      <>
        <div>
          <h2 onClick={() => {
            let collapse_action = this.props.collapse_action;
            collapse_action.action(collapse_action.args);
          }}>
            Assignment Slots:{" "}
            <span
              className={caret_style}
              data-section-id="assignment-slot-section"
            />
          </h2>
          <h4 style={{ margin: "10px 0" }}>{"Can get expensive offers: " + (this.props.can_get_expensive ? "TRUE" : "FALSE")}</h4>
          <div className="admin__card-basic" style={{ marginLeft: 0, padding: 14, ...collapsed_style }}>
            <h2>Preferences</h2>
            <div>
              <b>Locations:</b>{" "}
              {this.props.offer_select.account_scope.filter_locations.map((category) => this.getLocationNameFromID(category)).join(", ")}
            </div>
            <div>
              <b>Categories:</b>{" "}
              {this.props.offer_select.account_scope.filter_categories.map((category) => this.getCategoryNameFromID(category)).join(", ")}
            </div>
            <div>
              <b>Dining Types:</b>{" "}
              {this.props.offer_select.account_scope.filter_offer_types.map((dining_type) => this.getOfferTypeFromID(dining_type)).join(", ")}
            </div>
          </div>
          <div
            id="assignment-slot-section"
            className="admin__cards no-padding"
            style={collapsed_style}
          >
            {this.render_assignment_slots()}
          </div>
        </div>
        {this.state.assignment_slot_modal_open &&
          <OfferModal
            assignment_slot={this.state.assignment_slot_modal_open}
            all_valid_offers={this.props.all_valid_offers}
            filter_offers={this.props.offer_select.account_scope.filter_offers}
            close_modal={() => { this.setState({ assignment_slot_modal_open: null }) }}
          />
        }
      </>
    );
  }
}

export default AccountAssignmentSlots;