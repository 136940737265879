import React from "react";
import PostHogEnabled from "../../../../helpers/posthog_enabled";
import { getIDforGTM } from "../../v2/state_components/gtm_methods"

class PhoneInputGUI extends React.Component {
  constructor(props) {
    super(props);
  }

  // Sets up phone number subtitle information
  // and is PostHog aware
  signup_phone_info() {
    let base_subtitle_text = "You'll use this to log into Offline on your phone.";
    let base_subtitle = <div className='membership-info-subtitle'>{base_subtitle_text}</div>;

    return (
      <PostHogEnabled
        flag={'signup-phone-info'}
        variant={'checkmarks'}
        fallback={base_subtitle}
        renderWithPayload={(variant_data) => {
          return (
            <div className='membership-info-subtitle posthog-content'>{variant_data.subtitle}</div>
          )
        }}
      />
    )
  }

  render() {
    return (
      <>
        <div className="signup-v3-column signup-column membership-information">
          <div className="membership-info-title">Your digits, plz.</div>
          {this.signup_phone_info()}
          <div className="phone-input">
            {/* <div className="input-label">Phone Number</div> */}
            <input type="text"
              className="membership-info-input signup-v3-input signup-input"
              id="input_phone_number"
              name="phone_number"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder="Phone Number"
              onChange={this.props.changeInputField}
              value={this.props.phone_number} />
            <div className="input-error">
              {this.props.error_phone_number &&
                <span>
                  {this.props.error_phone_number}<br />
                  If you were a previous member, please redownload the app (
                  <a href="https://apps.apple.com/us/app/offline-restaurant-club/id1632270215" >iOS App Store</a> |
                  <a href="https://play.google.com/store/apps/details?id=com.letsgetoffline.lgomobile">Google Play Store</a>
                  ) to sign back in.
                </span>
              }
            </div>
            <div className="input-subtitle">Only real team members will text you...promise</div>
          </div>
          <button
            className={"full-blue-button smaller-text aux-full-width aux-footer"}
            // disabled={this.canContinue() ? "" : "disabled"}
            onClick={this.props.validateAccountDetails}
            id={getIDforGTM('submit-phone-number', this.props)}>
            CONTINUE
          </button>
          <button
            className={"footer-back-button-link aux-full-width aux-footer-low"}
            // disabled={this.canContinue() ? "" : "disabled"}
            onClick={this.props.previousScreen}
            id={getIDforGTM('back-to-previous-screen', this.props)}>
            back
          </button>
        </div>
      </>
    );
  }
}

export default PhoneInputGUI