import React from "react";

class ExampleSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    }
  }
  render() {
    if (this.state.opened) {
      return (
        <div className='example-body'>
          {this.props.children}
        </div>
      );
    }
    return (
      <div className='example-slider' onClick={(e) => this.setState({opened: true})} >
        Examples
      </div>
    );
  }
}

export default ExampleSlider