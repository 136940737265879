import React from "react";

import FriendLink from 'friend-link-icon.svg'
import CustomizeIcon from 'customize-icon.svg'
import WarningIcon from 'warning-icon.svg'

class SetupOfferCustomize extends React.Component {
  constructor(props) {
    super(props);
    this.completeSetup = this.completeSetup.bind(this);
    this.goBackWithSetup = this.goBackWithSetup.bind(this);
  }

  completeSetup(event) {
    let self = this;
    $.ajax({
      method: "POST",
      url: "/setup-complete",
      dataType: 'json',
      success: function(data) {
        self.props.proceedWithSetup(self.props.setupStep + 1); 
      }
    });
  }

  friendLinkMessaging() {
    if (this.props.phone_number) {
      return "Find your friends + request the same Offers so you can use them together."
    }
    return "You can’t link your Offers with friends without entering your phone number."
  }

  goBackWithSetup() {
    this.props.goBackOneStep(this.props.setupStep - 1);
  }

  render() {
    return (
      <div>
        <div style={{margin: "13px"}}>
          <a className='member__app-btn btn-small' href={this.props.accountPath} onClick={this.goBackWithSetup}>back</a>
        </div>
        <div className='member__app-section-padded text-center'>
          <span>Last Step</span>
          <h2 className='top-margin-zero'>Offer Setup</h2>
        </div>
        <div className="member__app-section-padded-large">
          <p className='text-center'>Almost done here. On the next page you’ll get the chance to personalize your Offer preferences.</p>
          <div className='added-margin-large bottom-margin-zero'>
            <div className='one-line' style={{marginTop: '20px'}}>
              <div className='text-right'><img src={CustomizeIcon} /></div>
              <div className='text-left'>
                <h4>CUSTOMIZING YOUR OFFERS</h4>
              </div>
            </div>
            <p className='top-margin-zero'>By default, we’ll send you curated Offline picks within 15 mins of downtown, but if you want, we can expand or limit your preferences based on location + types of places.</p>
          </div>

          <div>
            <div className='one-line' style={{marginTop: '20px'}}>
              <div className='text-right'><img src={ this.props.phone_number ? FriendLink : WarningIcon} /></div>
              <div className='text-left'>
                <h4>link Offers W friends</h4>
              </div>
            </div>
            <p className='top-margin-zero'>{this.friendLinkMessaging()}</p>
          </div>
          <div className='text-center added-margin-large bottom-margin-zero'>
            <button className='member__app-btn btn-primary' onClick={this.completeSetup}>Let's Go</button>
          </div>
        </div>

      </div>
    );
  }
}

export default SetupOfferCustomize
