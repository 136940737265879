import React from "react";
import LearnMoreIcon from 'icon-learn-more.svg'

class FAQSection extends React.Component {
  constructor(props) {
    super(props);

    this.first_checkbox_ref = React.createRef();
  }

  componentDidMount() {
    this.first_checkbox_ref.current.checked = true;
  }


  render() {
    return (
      <div className="signup-bubble-sections faq-section">
        <div className="faq-content">
          <div className="faq-banner">
            <img src={LearnMoreIcon} alt="" />
            <span>Learn More</span>
          </div>
          <div className="faq-title">FAQs</div>
          <div className="faq-subtitle">You've got Q's, we've got A's</div>
          <div className="faq-dropdowns">
            {
              this.props.faqs.map((faq, index) => {

                let input = <input type="checkbox" id={`faq-${index}`} className="faq-dropdown-cb" />

                if (index === 0) {
                  input = <input type="checkbox" id={`faq-${index}`} className="faq-dropdown-cb" ref={this.first_checkbox_ref} />
                }

                return (
                  <div key={index} className="faq-dropdown-container">
                    <div className="question-toggle">
                      {input}
                      <label htmlFor={`faq-${index}`} className="question-label">
                        <div key={index} className="faq-dropdown">
                          <div className="faq-question">{faq.question}</div>
                        </div>
                      </label>
                      <div className={"faq-answer"}>{faq.answer}</div>
                    </div>
                    
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default FAQSection