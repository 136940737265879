import React from "react";
import BadMatch from "bad-match.svg";
import OfflineLogo from "logo-city.png";

class NotGoodFit extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Not Good Fit");
      posthog.capture("Signup: Not Good Fit");
    }
  }

  render() {
    return (
      <div className="signup-v2-column not-good-fit" style={{textAlign: 'center', paddingTop: '75px', paddingBottom: '122px'}}>
        <div><img src={OfflineLogo} className="page-logo" style={{width: '75px', paddingBottom: '32px'}} /></div>
        <div><img src={BadMatch} className="page-main-image" /></div>
        <p className='emphasis' style={{marginTop: '0'}}>Bit of a mix-up here, huh?</p>
        <p>At Offline, we're passionate about backing our local restaurants, which includes tipping and a bit of spending. We're definitely not a second act of Groupon.</p>
        { this.props.supporting_local == "tipping_is_questionable" &&
          <p className='emphasis'>Given your not-so-sure stance on tipping, we might not be the perfect match.</p>
        }
        { this.props.reason_to_become_member == "save_money" &&
          <p className='emphasis'>Given your main motivation of saving money eating out, we might not be the perfect match.</p>
        }
        <p>{`But hey, don't disappear! We'd love to help you stay in the know about ${this.props.selected_area.name} via our Instagram. Lots of local gems to discover there, just presented a bit differently.`}</p>
      </div>
    );
  }
}

export default NotGoodFit