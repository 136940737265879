import React from 'react';

class PaymentSummary extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { area, month, price } = this.props;

    return (
      <div className='signup__total-box margin__top-small'>
        <div className='form-row'>
          <div className='form-item'>Subscription:</div><div className='form-item subscription-area'>{area}</div>
        </div>
        <div className='form-row'>
          <div className='form-item cost-per-time'>Cost/Month:</div><div className='form-item subscription-cost'>${price}</div>
        </div>
        <div className='form-row'>
          <div className='form-item'>Total Months:</div><div className='form-item subscription-months'>{month}</div>
        </div>
        <div className='form-row'>
          <div className='form-item'><b>Total Billed Today:</b></div><div className='form-item final'><b>${price}</b></div>
        </div>
      </div>
    );
  }
};

export default PaymentSummary;