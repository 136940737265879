import React from "react";
import Bag from "bag.svg";
import Calendar from "calendar.svg";
import Chef from "chef.svg";
import Star from "star.svg";
import Swap from "swap.svg";

class AnnualBenefitSummary extends React.Component {
  constructor(props) {
    super(props);

    this.summaries = [
      {
        icon: Chef,
        title: 'Pick 2 local restaurants per month',
        content: 'and get $10-30 to spend at each'
      },
      {
        icon: Swap,
        title: 'Swap restaurants anytime',
        content: 'Switch any restaurant you haven’t used yet before the end of the month'
      },
      {
        icon: Star,
        title: 'Exclusive grand opening access',
        content: 'Get early access to events + happenings'
      },
      {
        icon: Calendar,
        title: '2 Annual restaurant picks',
        content: 'Pick 2 extra restaurants, valid for a whole year'
      },
      {
        icon: Bag,
        title: 'Pick restaurants in any Offline city',
        content: 'Raleigh-Durham, Charlotte, Nashville, Tampa Bay, Austin, Denver and Orlando'
      },
    ];
  }

  render() {
    let price = this.props.special_price || this.props.price
    let summary = <>
      <span className="summary-dollar">${price}</span>
      <span className="summary-term">/month</span>
    </>
    let billed = `billed as $${price * 12}/yr`


    if (this.props.special_price) {
      summary = <>
        <span className="summary-dollar strike">${this.props.price}</span>
        <span className="summary-dollar">{` $${price.toFixed(2)}`}</span>
        <span className="summary-term">/month</span>
      </>
      billed = <>
        {`billed as $`}
        <del>
          {this.props.price * 12}
        </del>
        {` $${price * 12}/yr`}
      </>
    }

    return (
      <div className="annual-benefit-summary">
        <div className="summary-card">
          <div className="summary-header">
            <label className="summary-tag">Annual Membership</label>
            <div className="summary-price">
              <div>
                {summary}
              </div>
              <div>
                {billed}
              </div>
            </div>
          </div>
          <div className="summary-list">
            <div className="summary-wrap">
              {this.summaries.map((summary, idx) => (
                <div className="summary" key={idx}>
                  <div className="summary-icon">
                    <img src={summary.icon} alt="" />
                  </div>
                  <div className="summary-detail">
                    <div className="summary-title">{summary.title}</div>
                    <div className="summary-content">{summary.content}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {this.props.can_show_both_plans &&
          <div className="summary-all-plans">
            <a onClick={this.props.show_plans}>View All Plans</a>
          </div>
        }
      </div>
    );
  }
};

export default AnnualBenefitSummary
