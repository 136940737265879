import React from "react";

class EventAppPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: false,
      count: 0
    }

    this.updatePreview = this.updatePreview.bind(this);
  }

  updatePreview() {
    let new_count = this.state.count;
    this.setState({preview: true, count: new_count + 1});
  }

  render() {
    return (
      <div className='offer-preview-wrapper'>
        <button className="preview-btn preview admin__actionbar__preview" onClick={this.updatePreview}>Preview</button>
        <div className='offer-preview builder__preview-inner'>
          {this.state.preview &&
            <iframe key={this.state.count} style={{width: '100%', height: '99%'}} src={`/events/${this.props.id}/app_preview`}></iframe>
          }
        </div>
      </div>
    )
  }
}

export default EventAppPreview;