import React from "react";

/**
 * PostHogEnabled ** NOTE: this component assumes PostHog is loaded and available as a global variable
 * @augments flag string to match with a PostHog Feature Flag
 * @augments renderWithPayload this is to render elements containing the payload defined in the Feature Flag.  Pass a function whose argument is the payload and returns elements to render.
 * @augments variant (optional) only render when matching a specific Feature Flag variant
 * @augments variantRenderMap (optional) hash with multiple defined variants and their render children / functions
 * @augments fallback (optional) render passed in elements if Feature Flag is not matched
 */
class PostHogEnabled extends React.Component {
  constructor(props) {
    super(props);
  }

  renderChild(child, fallback) {
    if (typeof child === 'function') {
      return child();
    } else if (!child) {
      return fallback;
    } else {
      return child;
    }
  }

  render() {
    let fallback = this.props.fallback || (<></>);
    let flag_matched = null

    if (!posthog || !posthog.getFeatureFlag(this.props.flag)) {
      return this.renderChild(fallback, (<></>));
    } else {
      flag_matched = posthog.getFeatureFlag(this.props.flag);
    }

    if (this.props.variant && flag_matched != this.props.variant) {
      return this.renderChild(fallback, (<></>));;
    }

    let payload = posthog.getFeatureFlagPayload(this.props.flag);
    if (this.props.renderWithPayload && typeof this.props.renderWithPayload === 'function') {
      if (payload) {
        return this.props.renderWithPayload(payload);
      } else {
        return this.renderChild(fallback, (<></>));
      }
    }

    if (this.props.variantRenderMap && this.props.variantRenderMap[flag_matched]) {
      return this.renderChild(this.props.variantRenderMap[this.props.variant], this.renderChild(fallback, (<></>)));
    }

    return (
      <>
        {this.renderChild(this.props.children, fallback)}
      </>
    )
  }
}

/* Example Usage:

// Render elements if Feature Flag is matched
<PostHogEnabled flag={'banana'}>
  <div>You are in the banana feature group</div>
</PostHogEnabled>

// Render elements if Feature Flag is matched and variant is 'yellow'
<PostHogEnabled flag={'banana'} variant={'yellow'}>
  <div>You are in the yellow banana feature group</div>
</PostHogEnabled>

// Render fallback elements if the Feature Flag is not matched
<PostHogEnabled flag={'bananaaaaaa'} fallback={
  <div>You are in the Default group</div>
}>
  <div>You are in the banana feature group</div>
</PostHogEnabled>

// Render elements with payload data if Feature Flag is matched
<PostHogEnabled
  flag={'banana'}
  renderWithPayload={(variant_data) => {
    return (
      <div>{"We found the " + variant_data.banana_type}</div>
    )
  }}
/>

// Define multiple variants and their render children / functions
<PostHogEnabled
  flag={'banana'}
  variantRenderMap={{
    'yellow': <div>You are in the yellow banana feature group</div>,
    'green': <div>You are in the green banana feature group</div>,
    'spoiled': (variant_data)=>{<div>{"We found the " + variant_data.banana_type}</div>},
  }}
  renderWithPayload={(variant_data) => {
    return (
      <div>{"We found the " + variant_data.banana_type}</div>
    )
  }}
/>

*/

export default PostHogEnabled