import React from "react";

import IconConfirmation from "icon-confirmation";

class LandingLoadingScreen extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Offers Loading");
      posthog.capture("Signup: Offers Loading");
    }
  }

  render() {
    return (
      <div className="loading-page-container">
        <div className="offer-confirmation-title">Loading Offers</div>
        <div className="offer-confirmation-subtitle">One sec while we get your Offer choices ready.</div>
        <img src={IconConfirmation} className="confirmation-icon" />
      </div>
    );
  }
}

export default LandingLoadingScreen