import React from "react";
import FormBuilder from "../../shared/form_builder";
import moment from "moment-timezone";

class AccountCYOO extends React.Component {
  constructor(props) {
    super(props);

    let job = this.props.job;
    if (Array.isArray(job)) {
      job = {};
    }

    this.state = {
      job_id: job.id,
      run_at: job.run_at,
      title: "Already Assigned",
    };

    this.countdown_timer = null;
    this.schedule_countdown = this.schedule_countdown.bind(this);
    this.start_countdown = this.start_countdown.bind(this);
    this.updateCountdownTimers = this.updateCountdownTimers.bind(this);
  }

  componentDidMount() {
    this.countdown_timer = window.setInterval(() => {
      this.updateCountdownTimers();
    }, 1000);
    // this.updateCountdownTimers();
  }

  schedule_countdown() {
    let path = `/offer_select/${this.props.account.token}/schedule_countdown`;

    $.ajax({
      method: "POST",
      url: path,
      data: {},
      processData: false,
      contentType: false,
      success: (data) => {
        this.setState({ job_id: data.job_id, run_at: data.run_at });
      },
      error: (error) => {
        showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }

  start_countdown(e) {
    let target = e.target;
    if(confirm(target.getAttribute("data-confirm"))) {
      this.schedule_countdown();
    }
  }

  updateCountdownTimers() {
    if (!this.state.job_id) {
      return;
    }
    const time_moment = moment(this.props.job.run_at);
    const time_left = time_moment.diff(moment(), "milliseconds");

    if (this.state.job_id) {
      let title = moment.utc(time_left).format("(HH[H]:mm[M]:ss[S])");
      if (time_left > 86400000) {
        title = moment.utc(time_left).format("DD[D]:HH[H]:mm[M]:ss[S]");
      }
      if (time_moment.isBefore(moment())) {
        title = "Job ID exists, but no time is left to choose. (Job set to run " + time_moment.format("MMM Do, YYYY HH:mm:ss A") + ")";
      }
      this.setState({ title });
      return;
    }

    this.setState({ title: "Already Assigned" });
  }

  render() {
    let title = <h4>{"Time left to choose: "}<span>{this.state.title}</span> </h4>;
    let button = <></>;
    if (!this.state.job_id) {
      title = <h4>Already Assigned</h4>;
      button = <input type="button" value="Start Countdown" onClick={this.start_countdown} className="admin__btn--create-new" style={{"marginTop":"10px","padding":"0px 40px","paddingBottom":"0px","paddingLeft":"40px","paddingRight":"40px","paddingTop":"0px"}} data-confirm="This will delete 0 assignments already created for this month."></input>;
    }

    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    return (
      <>
        <h2>CYOO:</h2>
        {title}
        {button}
      </>
    );
  }
}

export default AccountCYOO;