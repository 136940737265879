import React from "react";
import SignupHeaderV3 from "../gui_components/signup_header_v3";
import PaymentInputGUIV3 from "../gui_components/payment_input_v3_gui";

class PaymentInputV3 extends React.Component {
  constructor(props) {
    super(props);

    this.trackPaymentSubmit = this.trackPaymentSubmit.bind(this);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Payment");
      posthog.capture("Signup: Payment");
    }
  }

  trackPaymentSubmit() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Complete");
      posthog.capture("Signup: Complete");
    }

    if(typeof(dataLayer) !== "undefined" && dataLayer) {
      let value = this.props.area?.monthly_subscription_price || 1500;
      dataLayer.push({
        event: "purchase",
        ecommerce: {
          currency: "USD",
          items: [
            {
              item_id: this.props.area?.premium_monthly_plan,
              item_name: this.props.area?.name + " Monthly",
              coupon: this.props.valid_referral,
              item_category: "Subscription",
              item_category_2: "Trial Activation",
              item_variant: "Monthly",
              price: (value / 100),
            }
          ],
          transaction_id: this.props.setup_intent?.id,
          value: (value / 100),
        }
      });
    }

    $.ajax({
      method: "POST",
      url: "/payment-submit",
      data: {
        waitlist_id: this.props.waitlist_id
      },
      dataType: 'json',
      success: (data) => {
      },
      error: (error) => {
      }
    });
  }

  render() {
    return (
      <>
        <SignupHeaderV3
          markets={this.props.markets}
          selected_market={this.props.selected_market}
          updateStateValue={() => { }}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
          select_enabled={false}
          currentScreen={this.props.currentScreen}
          total_screens={this.props.total_screens}
        />
        <PaymentInputGUIV3
          stripe_key={this.props.stripe_key}
          setup_intent={this.props.setup_intent}
          phone_number={this.props.phone_number}
          confirmation_path={this.props.confirmation_path}
          waitlist_id={this.props.waitlist_id}
          trackPaymentSubmit={this.trackPaymentSubmit}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
          previousScreen={this.props.previousScreen}
        />
      </>
    );
  }
}

export default PaymentInputV3