import React from "react";

class UpdateTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show_non_selectable_cells: this.props.show_selectable_by_default || true,
      selectable_on: this.props.selectable_on || {}
    }

    this.toggleShowSelectable = this.toggleShowSelectable.bind(this);
    this.updateCheckBox = this.updateCheckBox.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.renderHeaderCells = this.renderHeaderCells.bind(this);
    this.renderRowSelect = this.renderRowSelect.bind(this);
    this.renderCells = this.renderCells.bind(this);
    this.renderCellValue = this.renderCellValue.bind(this);

    this.body_ref = React.createRef();

    this.warning = null;
  }

  selectAll() {
    let all_rows = Array.from(this.body_ref.current.querySelectorAll('.table-row'));
    let toggle = null;
    all_rows.forEach((row, i) => {
      let input = row.querySelector('input[type="checkbox"]');
      if (!input) return false;
      if (toggle == null) {
        toggle = !input.checked;
      }
      input.checked = toggle;
    })

    if (this.props.onSelectUpdate) {
      let all_selected = toggle ? this.props.rows.filter(row => row[this.props.selectable_on.value_name]) : [];
      this.props.onSelectUpdate(all_selected);
    }
  }

  toggleShowSelectable() {
    this.setState({ show_non_selectable_cells: !this.state.show_non_selectable_cells })
  }

  updateCheckBox(event) {
    let currently_selected_rows = Array.from(this.body_ref.current.querySelectorAll('.table-row:has(input:checked)'));

    let indexes = currently_selected_rows.map(node => node.dataset.row);
    if (this.props.onSelectUpdate) {
      let all_selected = indexes.map(index => this.props.rows[index])
      this.props.onSelectUpdate(all_selected);
    }
  }

  createCellProperties(row, template, row_index, cell_index) {
    let is_valid = true;
    if (template.validator) {
      is_valid = template.validator(row, template.value_name);
    }
    let className = "table-cell";
    if (!is_valid) {
      className += " warning-cell";
      if (!this.warning) {
        this.warning = template.warning;
      } else if (!this.warning.includes(template.warning)) {
        this.warning += ("\n" + template.warning)
      }
    }

    return {
      ["data-" + template.data_label]: true,
      className,
      key: (this.props.base_key || 'ut') + "0" + row_index + cell_index
    };
  }

  renderLoading() {
    return (
      <div className={"loading-container" + (this.props.loading_data ? "" : " no-display")}>
        <div className="loading-spin"><div></div><div></div><div></div><div></div></div>
      </div>
    )
  }

  renderHeaderCells() {
    let header_labels = this.props.header_labels || [];
    if (this.props.selectable) {
      header_labels = ['SELECT', ...header_labels];
    }

    let render_buttons = (header, i) => {
      let select_all = (<></>);
      let hide = (<></>);
      if (this.props.selectable) {
        if (i == 0) {
          select_all = (<button style={{ marginLeft: "1em", width: "132px" }} onClick={this.selectAll}>Toggle All</button>);
        }

        if (header == this.state.selectable_on.header) {
          hide = (
            <button style={{ marginLeft: "1em", width: "132px" }} onClick={this.toggleShowSelectable}>
              {this.state.show_non_selectable_cells ? this.state.selectable_on.hide_label : this.state.selectable_on.show_label}
            </button>
          );
        }
      }

      return (
        <>
          {select_all}
          {hide}
        </>
      )
    }

    let header_cells = (header_labels || []).map((header, i) =>
      <div key={(this.props.base_key || 'ut') + 'h' + i} className="table-cell">
        {header}
        {render_buttons(header, i)}
      </div>
    )
    return (
      <>
        {header_cells}
      </>
    )
  }

  renderRowSelect(show) {
    if (!show) {
      return (<div></div>)
    }
    return (
      <input type="checkbox" onChange={this.updateCheckBox} />
    )
  }

  renderCellValue(row, template, cell_index) {
    let format_function = template.formatter;

    let cell_value = row[template.value_name];
    if (this.props.selectable && cell_index == 0 && !row.changes?.includes("invalid")) {
      cell_value = row[this.props.selectable_on.value_name || "undefined"]
    }

    if (format_function) {
      cell_value = format_function(cell_value);
    }

    return cell_value;
  }

  renderCells(row, row_index) {
    let row_cells = this.props.row_templates;

    if (!this.state.show_non_selectable_cells && !row[this.state.selectable_on.value_name]) {
      return (<></>);
    }

    if (this.props.selectable) {
      row_cells = [{ formatter: this.renderRowSelect }, ...row_cells];
    }

    return (
      <>
        {row_cells.map((template, j) =>
          <div {...this.createCellProperties(row, template, row_index, j)}>
            {this.renderCellValue(row, template, j)}
          </div>
        )}
      </>
    )
  }

  render() {
    this.warning = null
    let col_count = (this.props.row_templates.length + (this.props.selectable ? 1 : 0));
    let table_style = { "--column-count": col_count };
    if (this.props.override_height) {
      table_style.maxHeight = this.props.override_height;
    }
    let table = (
      <div className={"update-table-container " + (this.props.extra_class ? this.props.extra_class : "")}>
        <div className="table-top">
          {this.renderLoading()}
          <div className="table-body" ref={this.body_ref} style={table_style}>
            <div className="table-row header">
              {this.renderHeaderCells()}
            </div>
            {this.props.rows.map((row, i) =>
              <div className="table-row" data-row={i} key={(this.props.base_key || 'ut') + i} data-changes={row.changes || ''}>
                {this.renderCells(row, i)}
              </div>
            )}
          </div>
        </div>
      </div>
    )
    return (
      <>
        {this.warning &&
          <div className="update-table-warning">{this.warning}</div>
        }
        {table}
      </>
    );
  }
}

export default UpdateTable