import { convertIntegerToCleanPrice } from '../../helpers/price_formatter';
import { convertDateString } from '../../helpers/datestring_formatter'
import moment from 'moment-timezone';

// Get information to display on the subscription page
// for the "Annually" and "Monthly" cards.
export const getSubscriptionCardMessaging = (referral, area) => {
  let referral_type = referral.hasOwnProperty("gift_code") ? "gift_code" : "referral_code";

  if (referral_type == "gift_code") {
    let annual_cost_today = Math.max(area.yearly_subscription_price - referral.amount, 0);
    let monthly_cost_today = Math.max(area.monthly_subscription_price - referral.amount, 0);

    return {
      annual_cost_today,
      annual_cost: area.yearly_subscription_price,
      monthly_cost_today,
      monthly_cost: area.monthly_subscription_price,
      show_discount_message: referral.amount > 0
    }
  }

  if (referral.months_discounted == 0) {
    return {
      annual_cost_today: area.yearly_subscription_price,
      annual_cost: area.yearly_subscription_price,
      monthly_cost_today: area.monthly_subscription_price,
      monthly_cost: area.monthly_subscription_price,
      show_discount_message: false
    }
  }

  if (referral.percent_discounted == 100) {
    return {
      annual_cost_today: 0,
      annual_cost: area.yearly_subscription_price,
      monthly_cost_today: 0,
      monthly_cost: area.monthly_subscription_price,
      show_discount_message: true
    }
  }

  let annual_discount = (area.yearly_subscription_price / 12)
    * referral.months_discounted
    * (referral.percent_discounted / 100);

  let annual_cost_today = area.yearly_subscription_price - annual_discount;

  let month_discount = area.monthly_subscription_price * (referral.percent_discounted / 100)
  let monthly_cost_today = area.monthly_subscription_price - month_discount;

  return {
    annual_cost_today,
    annual_cost: area.yearly_subscription_price,
    monthly_cost_today,
    monthly_cost: area.monthly_subscription_price,
    show_discount_message: true
  }
};

// Get messaging to display on the subscription page
// in the subscription terms fields
export const getSubscriptionTermsText = (referral, membership_start, selected_plan, area) => {

  // Return no messages if any of the arguments are
  // not defined.
  if (!referral || !membership_start || !selected_plan) {
    return [];
  }

  let annual_discount = 0;
  let month_discount = 0;
  let annual_cost = area.yearly_subscription_price;
  let monthly_cost = area.monthly_subscription_price;
  let full_monthly_cost = monthly_cost;
  let full_annual_cost = annual_cost;

  if (referral.hasOwnProperty("percent_discounted")) {
    annual_cost = area.yearly_subscription_price - annual_discount;

    month_discount = area.monthly_subscription_price * (referral.percent_discounted / 100)
    monthly_cost = area.monthly_subscription_price - month_discount;
  }

  if (referral.hasOwnProperty("gift_code")) {
    annual_cost = Math.max(area.yearly_subscription_price - referral.amount, 0);
    monthly_cost = Math.max(area.monthly_subscription_price - referral.amount, 0);
  }

  if (area.hasOwnProperty("has_active_annual_sale?")) {
    annual_cost = annual_cost - area.annual_sale_discount;
    full_annual_cost = annual_cost;
  }

  let selected_price = selected_plan == "annual" ? annual_cost : monthly_cost;

  // UI Message Dictionary
  let subscription_terms = {
    monthly: {
      defer: {
        gift_code_with_enough_funds: [
          `Your membership will begin on ${getNextMonth(area)} (you'll pick your first restaurants that day).`,
          "We'll start billing you for your monthly membership once your Gift Code balance no longer covers the monthly membership fee."
        ],
        gift_code_with_not_enough_funds: [
          `Your membership will begin on ${getNextMonth(area)} (you'll pick your first restaurants that day).`,
          `We'll bill you for your membership today.`,
        ],
        referral_code_with_no_discount: [
          `Your membership will begin on ${getNextMonth(area)} (you'll pick your first restaurants that day).`,
          `We'll bill you for your membership today.`,
        ],
        referral_code_with_slight_discount: [
          `Your membership will begin on ${getNextMonth(area)} (you'll pick your first restaurants that day).`,
          `We'll start billing you for your monthly membership on ${getNextMonth(area)}`,
        ],
        referral_code_with_full_discount: [
          "You can change or cancel any time during your trial.",
          `Your trial will begin on ${getNextMonth(area)} (you'll pick your first restaurants that day).`,
          `Your first payment for your monthly membership will be on  ${getNextMonth(area, referral.months_discounted + 1)}`,
          "We'll notify you 2 days before your free trial ends."
        ]
      },
      today: {
        gift_code_with_enough_funds: [
          "Your membership will begin today.",
          "We'll start billing you for your monthly membership once your Gift Code balance no longer covers the monthly membership fee."
        ],
        gift_code_with_not_enough_funds: [
          "Your membership will begin today.",
          "We'll bill you for your membership today."
        ],
        referral_code_with_no_discount: [
          "Your membership will begin today.",
          "We'll start billing you for your monthly membership once your account is created."
        ],
        referral_code_with_slight_discount: [
          "Your membership will begin today.",
          "We'll start billing you for your monthly membership once your account is created."
        ],
        referral_code_with_full_discount: [
          "You can change or cancel any time during your trial.",
          "Your trial will begin today.",
          `Your first payment for your monthly membership will be on  ${getNextMonth(area, referral.months_discounted)}`,
          "We'll notify you 2 days before your free trial ends."
        ]
      }
    },
    annual: {
      defer: {
        gift_code_with_enough_funds: [
          `Your membership will begin on ${getNextMonth(area)} (you'll pick your first restaurants that day).`,
          "We'll start billing you for your annual membership once your Gift Code balance no longer covers the annual membership fee."
        ],
        gift_code_with_not_enough_funds: [
          `Your membership will begin on ${getNextMonth(area)} (you'll pick your first restaurants that day).`,
          `We'll bill you for your membership today.`,
        ],
        referral_code_with_no_discount: [
          `Your membership will begin on ${getNextMonth(area)} (you'll pick your first restaurants that day).`,
          `We'll bill you for your membership today.`,
        ],
        referral_code_with_slight_discount: [
          `Your membership will begin on ${getNextMonth(area)} (you'll pick your first restaurants that day).`,
          `We'll start billing you for your annual membership on ${getNextMonth(area)}`,
        ],
        referral_code_with_full_discount: [
          "You can change or cancel any time during your trial.",
          `Your trial will begin on ${getNextMonth(area)} (you'll pick your first restaurants that day).`,
          `Your first payment for your annual membership will be on ${getNextMonth(area, referral.months_discounted + 1)}`,
          "We'll notify you 2 days before your free trial ends."
        ]
      },
      today: {
        gift_code_with_enough_funds: [
          "Your membership will begin today.",
          "We'll start billing you for your annual membership once your Gift Code balance no longer covers the annual membership fee."
        ],
        gift_code_with_not_enough_funds: [
          "Your membership will begin today.",
          "We'll start billing you for your annual membership once your account is created."
        ],
        referral_code_with_no_discount: [
          "Your membership will begin today.",
          "We'll start billing you for your annual membership once your account is created."
        ],
        referral_code_with_slight_discount: [
          "Your membership will begin today.",
          "We'll start billing you for your annual membership once your account is created."
        ],
        referral_code_with_full_discount: [
          "You can change or cancel any time during your trial.",
          "Your trial will begin today.",
          `Your first payment for your annual membership will be on ${getNextMonth(area, referral.months_discounted)}`,
          "We'll notify you 2 days before your free trial ends."
        ]
      }
    }
  }

  let key_selected_plan = selected_plan == "monthly" ? "monthly" : "annual";
  let available_terms = subscription_terms[key_selected_plan][membership_start];

  let referral_type = referral.hasOwnProperty("gift_code") ? "gift_code" : "referral_code";

  if (referral_type == "gift_code") {
    if (selected_price == 0) {
      return available_terms["gift_code_with_enough_funds"];
    } else {
      return available_terms["gift_code_with_not_enough_funds"];
    }
  } else {
    if (referral.months_discounted == 0) {
      return available_terms["referral_code_with_no_discount"];
    } else if (referral.percent_discounted < 100) {
      return available_terms["referral_code_with_slight_discount"];
    } else {
      return available_terms["referral_code_with_full_discount"];
    }
  }
};

export const getPaymentTerms = (referral, membership_start, selected_plan, area) => {
  // Return no messages if any of the arguments are
  // not defined.
  if (!referral || !membership_start || !selected_plan) {
    return [];
  }

  let annual_discount = 0;
  let month_discount = 0;
  let annual_cost = area.yearly_subscription_price;
  let monthly_cost = area.monthly_subscription_price;
  let full_monthly_cost = monthly_cost;
  let full_annual_cost = annual_cost;

  if (referral.hasOwnProperty("percent_discounted")) {
    month_discount = area.monthly_subscription_price * (referral.percent_discounted / 100)
    monthly_cost = area.monthly_subscription_price - month_discount;
  }

  if (referral.hasOwnProperty("gift_code")) {
    annual_cost = Math.max(area.yearly_subscription_price - referral.amount, 0);
    monthly_cost = Math.max(area.monthly_subscription_price - referral.amount, 0);
  }

  if (area.hasOwnProperty("has_active_annual_sale?") && area["has_active_annual_sale?"] == true) {
    annual_cost = annual_cost - area.annual_sale_discount;
    full_annual_cost = annual_cost;
  }

  let selected_price = selected_plan == "annual" ? annual_cost : monthly_cost;

  // UI Message Dictionary
  let payment_terms = {
    monthly: {
      defer: {
        gift_code_with_enough_funds: [
          `Starting on ${getNextMonth(area, 2)} we'll start billing you for your monthly membership once your Gift Code balance no longer covers the monthly membership fee.`
        ],
        gift_code_with_not_enough_funds: [
          `Your card will be charged $${convertIntegerToCleanPrice(monthly_cost)} today.`
        ],
        referral_code_with_no_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(monthly_cost)} today.`
        ],
        referral_code_with_slight_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(monthly_cost)} today.`
        ],
        referral_code_with_full_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(full_monthly_cost)} on ${getNextMonth(area, referral.months_discounted + 1)}. We will pre-auth your card which will be released in a few days.`
        ]
      },
      today: {
        gift_code_with_enough_funds: [
          "Your card will be charged $0 today and we'll start billing you for your monthly membership once your Gift Code balance no longer covers the monthly membership fee."
        ],
        gift_code_with_not_enough_funds: [
          `Your card will be charged $${convertIntegerToCleanPrice(monthly_cost)} today.`
        ],
        referral_code_with_no_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(monthly_cost)} today.`
        ],
        referral_code_with_slight_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(monthly_cost)} today.`
        ],
        referral_code_with_full_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(full_monthly_cost)} on ${getNextMonth(area, referral.months_discounted)}. We will pre-auth your card which will be released in a few days.`
        ]
      }
    },
    annual: {
      defer: {
        gift_code_with_enough_funds: [
          `Starting on ${getNextMonth(area, 13)} we'll start billing you for your annual membership once your Gift Code balance no longer covers the annual membership fee.`
        ],
        gift_code_with_not_enough_funds: [
          `Your card will be charged $${convertIntegerToCleanPrice(annual_cost)} today.`
        ],
        referral_code_with_no_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(annual_cost)} today.`
        ],
        referral_code_with_slight_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(annual_cost)} today.`
        ],
        referral_code_with_full_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(full_annual_cost)} on ${getNextMonth(area, referral.months_discounted + 1)}. We will pre-auth your card which will be released in a few days.`
        ]
      },
      today: {
        gift_code_with_enough_funds: [
          "Your card will be charged $0 today and we'll start billing you for your annual membership once your Gift Code balance no longer covers the annual membership fee."
        ],
        gift_code_with_not_enough_funds: [
          `Your card will be charged $${convertIntegerToCleanPrice(annual_cost)} today.`
        ],
        referral_code_with_no_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(annual_cost)} today.`
        ],
        referral_code_with_slight_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(annual_cost)} today.`
        ],
        referral_code_with_full_discount: [
          `Your card will be charged $${convertIntegerToCleanPrice(full_annual_cost)} on ${getNextMonth(area, referral.months_discounted)}. We will pre-auth your card which will be released in a few days.`
        ]
      }
    }
  }

  let key_selected_plan = selected_plan == "monthly" ? "monthly" : "annual";
  let available_terms = payment_terms[key_selected_plan][membership_start];

  let referral_type = referral.hasOwnProperty("gift_code") ? "gift_code" : "referral_code";

  let payment_ui_options = {
    payment_submit_text: "START YOUR MEMBERSHIP"
  };

  if (referral_type == "gift_code") {
    if (selected_price == 0) {
      payment_ui_options.terms = available_terms["gift_code_with_enough_funds"];
    } else {
      payment_ui_options.terms = available_terms["gift_code_with_not_enough_funds"];
    }
  } else {
    if (referral.months_discounted == 0) {
      payment_ui_options.terms = available_terms["referral_code_with_no_discount"];
    } else if (referral.percent_discounted < 100) {
      payment_ui_options.terms = available_terms["referral_code_with_slight_discount"];
    } else {
      payment_ui_options.terms = available_terms["referral_code_with_full_discount"];
      payment_ui_options.payment_submit_text = "START YOUR FREE TRIAL";
    }
  }

  return payment_ui_options;
};

// Gets the next month the market will charge for
//  --> this is a future date for launching markets
const getNextMonth = (area, itter = 1) => {
  if (itter == undefined) {
    itter = 1;
  }

  const current_date = new Date();

  let current_month = current_date.getMonth();
  let next_month = current_month + itter;
  let year = current_date.getFullYear();


  // If the area has a future start date, skip if the start date is in the past
  if (area.start_date) {
    var converted_date = convertDateString(area.start_date);
    if (converted_date > new Date()) {
      next_month = converted_date.getMonth();
      if (itter > 1) {
        next_month = next_month + itter - 1;
      }
      year = converted_date.getFullYear();
    }
  }

  while (next_month > 11) {
    next_month = next_month - 12;
    year++;
  }

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return `${months[next_month]} 1st, ${year}`;
};