import React from "react";

import WrappingTooltip from "../shared/wrapping-tooltip";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentElement from "../../sign_up/stripe_payment_element";
import ReferralCodeInput from "../shared/account_inputs/referral_code_input";
import { getIDforGTM } from "../v2/state_components/gtm_methods";
import BackButton from "../shared/back_button";

class SignupV4Payment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valid_referral: props.valid_referral || ""
    }
    this.onInputChange = this.onInputChange.bind(this);
    this.receiveReferralDetails = this.receiveReferralDetails.bind(this);
    this.clearReferralDetails = this.clearReferralDetails.bind(this);

    this.stripePromise = loadStripe(this.props.stripe_key);
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value || "",
      ["error_" + event.target.name]: ''
    });
  }

  receiveReferralDetails(referral_code, referral_details) {
    this.setState({ valid_referral: referral_code });
    this.props.onSubmit({ valid_referral: referral_code, referral_details: referral_details }, false);
  }

  clearReferralDetails() {
    this.setState({ valid_referral: "" });
  }

  render() {
    return (
      <div className="signup-v4-payment">
        <div className="payment-description">
          <div className="payment-item-box">
            <div className="trial-duration">
              1 Month Free
            </div>
            <WrappingTooltip
              tooltip_text="If you're on a trial, we need your card info on file to charge if you continue to a paid membership. Additionally, if you attend an event during your trial, these are billed separately."
              open_upwards={true}
              bottom_offset={30}
              max_width={200} >
              <div className="due-today">
                Why do you need my card info?
              </div>
            </WrappingTooltip>

          </div>
        </div>

        <ReferralCodeInput
          referral_code={this.state.valid_referral}
          valid_referral={this.state.valid_referral}
          referral_details={this.props.referral_details}
          selected_area={this.props.selected_area_id}
          onRefferalSubmit={this.receiveReferralDetails}
          onReferralClear={this.clearReferralDetails} />

        <Elements
          stripe={this.stripePromise}
          options={
            {
              clientSecret: this.props.setup_intent.client_secret,
              fonts: [
                { cssSrc: "https://fonts.googleapis.com/css?family=Zilla+Slab:400,400i,600,600i|Patrick+Hand+SC&display=swap" },
                { cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i|Source+Sans+Pro:400,400i,700,700i" },
                { cssSrc: "https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700;800&display=swap" }
              ],
              appearance: {
                variables: {
                  fontFamily: "Zilla Slab",
                  fontWeightNormal: "600",
                  colorPrimary: "#000"
                },
                rules: {
                  '.Label': {
                    fontSize: '16px'
                  },
                  '.Input': {
                    boxShadow: 'none',
                    border: "2px solid #000"
                  },
                  '.Tab--selected': {
                    backgroundColor: "#FFBD54",
                    border: "3px solid #000"
                  },
                  '.Text--terms': {
                    color: "rgba(0,0,0,0.0)",
                    fontSize: "0.1px"
                  }
                }
              }
            }
          }>
          <StripePaymentElement
            redirect_url={this.props.confirmation_path}
            button_class={"full-blue-button smaller-text"}
            subscription_terms_object={{ payment_submit_text: "START YOUR FREE TRIAL" }}
            submit_event={this.props.trackPaymentSubmit}
            button_id={getIDforGTM("submit-payment", this.props)}
          />

        </Elements>
        <BackButton
          onPress={this.props.onBack}
          gtm_id={getIDforGTM('back-to-previous-screen', this.props)}
        />
        <div>
          <p>By clicking the button above, you agree to the <a href="https://support.letsgetoffline.com/en/articles/7157053-offline-terms-of-service" target="_blank"> Terms</a>,
            and your free trial will begin. If you don’t cancel before the trial ends, you’ll be <b>automatically charged for a full-priced monthly plan</b>, until you cancel or change your plan. Cancel anytime in the Offline app to avoid renewal. </p>
        </div>
      </div>
    );
  }
}

export default SignupV4Payment