import React from "react";
import LocationFlag from "flag.svg";
import OfferSelected from "offer-selected.svg";
import CheckMark from "icon-checkmark.svg";
import IconTooltip from 'icon-tooltip-white.svg';
import IconFinePrint from 'icon-fine-print-message.svg';
import IconTooltipBlack from 'icon-tooltip.svg';
import IconInterested from 'icon-heart.svg';
import IconInvited from 'icon-invite.svg';
import IconPicked from 'icon-slider-checked.svg';
import IconSolidHeart from 'icon-solid-heart.svg';
import IconDetails from 'icon-right-circle.svg';

class OfferCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      video_enabled: false,
      video_loaded: false,
      show_message: false,
      show_tooltip: false,
      show_more_interested: false,
      show_more_picked: false,
      show_more_invited: false,
    }

    this.render_full_height = this.render_full_height.bind(this);
    this.select_card = this.select_card.bind(this);
    this.disableAfterFullscreen = this.disableAfterFullscreen.bind(this);
    this.toggle_message = this.toggle_message.bind(this);
    this.open_slider = this.open_slider.bind(this);
    this.show_tooltip = this.show_tooltip.bind(this);
    this.showMoreInterested = this.showMoreInterested.bind(this);
    this.showMorePicked = this.showMorePicked.bind(this);
    this.showMoreInvited = this.showMoreInvited.bind(this);
    this.hide_tooltip = this.hide_tooltip.bind(this);
    this.renderInterestButton = this.renderInterestButton.bind(this);
    this.addInterestToOffer = this.addInterestToOffer.bind(this);
    this.removeInterestFromOffer = this.removeInterestFromOffer.bind(this);
    this.confetti_ref = React.createRef();
    this.player_ref = React.createRef();
    this.card_ref = React.createRef();
    this.banner_media_ref = React.createRef();
  }

  componentDidUpdate() {
    if (this.player_ref.current && this.state.video_enabled) {
      // Safari IOS does not support 'fullscreenchange', so we remove the video on pause events
      if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        this.player_ref.current.removeEventListener('pause', this.disableAfterFullscreen);
        this.player_ref.current.addEventListener('pause', this.disableAfterFullscreen);
      } else {
        this.player_ref.current.removeEventListener('fullscreenchange', this.disableAfterFullscreen);
        this.player_ref.current.addEventListener('fullscreenchange', this.disableAfterFullscreen);
      }
    }
  }

  isAccountInterested() {
    return this.props.offer.interested.map(a => a.id).includes(this.props.account_id);
  }

  disableAfterFullscreen(e) {
    if (!document.fullscreenElement) {
      this.setState({ video_enabled: false });
    }
  }

  select_card() {
    this.props.on_select(this.props.offer.id);
  }

  toggle_message(e) {
    this.card_ref.current?.scrollIntoView({ block: "end", inline: "start" });
    let message = ""
    if (e.target.dataset.type == "repeat") {
      message = "Repeat Offers are Offers you've had more than a year ago and rated highly.";
    } else if (e.target.dataset.type == "new_partner") {
      message = "This biz is new to the Offline fam as of this month, so we let 'em cut to the front of the CYOO line."
    } else if (e.target.dataset.type == "going_fast") {
      message = "Lots of Members are picking this restaurant and it will be unavailable soon. If you miss it, don't sweat it too much—you'll get another chance next month."
    } else {
      message = "Since you've had this Offer before, you can only choose it one more time and then lose it forever.";
    }

    this.props.toggle_message(message);
  }

  show_tooltip(e) {
    if (!this.props.full_height || this.props.is_selected) return false;
    this.setState({ show_tooltip: !this.state.show_tooltip, show_more_interested: false });
  }

  showMoreInterested(e) {
    if (this.props.offer.interested.length < 3) return false;
    this.setState({ show_more_interested: !this.state.show_more_interested, show_tooltip: false, show_more_picked: false, show_more_invited: false });
  }

  showMorePicked(e) {
    if (this.props.offer.picked_this_month.length < 3) return false;
    this.setState({ show_more_picked: !this.state.show_more_picked, show_tooltip: false, show_more_interested: false, show_more_invited: false });
  }

  showMoreInvited(e) {
    if (this.props.invite_list.length < 3) return false;
    this.setState({ show_more_invited: !this.state.show_more_invited, show_tooltip: false, show_more_interested: false, show_more_picked: false });
  }
  

  open_slider(e) {
    if (this.state.show_tooltip) {
      this.setState({ show_tooltip: false });
    }
    this.props.open_slider(this.props.offer.id);
  }

  hide_tooltip(e) {
    if (!this.state.show_tooltip) return false;
    this.setState({ show_tooltip: false, show_more_interested: false });
  }

  renderInviteAcceptance() {
    if (this.props.invitation == null)return (<></>);

    let inviting_member = this.props.invitation.invited_by.first_name + " " + this.props.invitation.invited_by.last_name.substr(0,1);
    return (
      <div className="friend-subtitle" style={{margin: "10px 0 0"}}>
        <img src={IconInvited} alt="" />
        <span>Accepting {inviting_member}'s invite</span>
      </div>
    );
  }


  render_fine_print_days(offer) {
    if (!offer) return (<></>);

    let day_indicators = offer.fine_print_days.map((fpd, i) =>
      <div className="day" key={`${offer.id}${i}`} data-lbl={fpd.state} >{fpd.name.substr(0, 1)}</div>
    )

    if (!this.props.full_height) {
      return (
        <div className="fine-print-days">
          {day_indicators}
        </div>
      );
    }

    return (
      <div className="fine-print-days" onClick={this.show_tooltip}>
        {day_indicators}
        <img src={IconTooltipBlack} alt="" />
        <div className={"fine-print-tooltip" + (this.state.show_tooltip ? " display-tooltip" : "")}>
          <img src={IconFinePrint} alt="" />
          <div className="fine-print-tooltip-text">{"Red = unable to use the Offer on that day\nYellow = valid that day, but there are time restrictions\nGreen = valid any time the restaurant is open that day"}</div>
        </div>
      </div>
    );
  }

  renderPicked(offer) {
    if (!offer || !this.props.full_height) return (<></>);
    if (offer.picked_this_month.length == 0) return (<></>);

    let ordered_picks = offer.picked_this_month.sort(((a,b) => a.id == this.props.account_id ? -1 : (a.id - b.id)));
    let all_picked = ordered_picks.map(account => account.first_name +" "+ account.last_name).join("\n");

    let first_picked = ordered_picks.slice(0,2);
    let first_picked_string = first_picked.map(account =>
      (account.id == this.props.account_id ? "You" : (account.first_name +" "+ account.last_name.substr(0,1)))
    ).join(", ");

    let more_picked = offer.picked_this_month.slice(2).length
    let more_picked_string = more_picked > 0 ? `, +${more_picked} more` : "";

    return (
      <div className="friend-subtitle" onClick={this.showMorePicked}>
        <img src={IconPicked} alt="" />
        <span>Chosen by <b>{first_picked_string}{more_picked_string}</b></span>
        <div className={"general-tooltip" + (this.state.show_more_picked ? " display-tooltip" : "")}>
          <div className="tooltip-content">{all_picked}</div>
        </div>
      </div>
    );
  }
  
  renderInterested(offer) {
    if (!offer || !this.props.full_height) return (<></>);
    if (offer.interested.length == 0) return (<></>);

    let ordered_interest = offer.interested.sort(((a,b) => a.id == this.props.account_id ? -1 : (a.id - b.id)));
    let all_interested = ordered_interest.map(account => account.first_name +" "+ account.last_name).join("\n");

    let first_interested = ordered_interest.slice(0,2);
    let first_interested_string = first_interested.map(account =>
      (account.id == this.props.account_id ? "You" : (account.first_name +" "+ account.last_name.substr(0,1)))
    ).join(", ");

    let more_interested = offer.interested.slice(2).length
    let more_interested_string = more_interested > 0 ? `, +${more_interested} more` : "";

    return (
      <div className="friend-subtitle" onClick={this.showMoreInterested}>
        <img src={IconInterested} alt="" />
        <span>Interested <b>{first_interested_string}{more_interested_string}</b></span>
        <div className={"general-tooltip" + (this.state.show_more_interested ? " display-tooltip" : "")}>
          <div className="tooltip-content">{all_interested}</div>
        </div>
      </div>
    );
  }

  renderNewInvitationInfo() {
    if (!this.props.invite_list) return (<></>);
    let ordered_invites = this.props.invite_list.sort(((a,b) => a.id == this.props.account_id ? -1 : (a.id - b.id)));
    let all_invites = ordered_invites.map(account => account.first_name +" "+ account.last_name).join("\n");

    let first_invited = ordered_invites.slice(0,2);
    let first_invited_string = first_invited.map(account =>
      (account.first_name +" "+ account.last_name.substr(0,1))
    ).join(", ");

    let more_invited = this.props.invite_list.slice(2).length
    let more_invited_string = more_invited > 0 ? `, +${more_invited} more` : "";

    return (
      <div className="friend-subtitle" style={{margin: "10px 0 0"}} onClick={this.showMoreInvited}>
        <img src={IconInvited} alt="" />
        <span>Inviting <b>{first_invited_string}{more_invited_string}</b></span>
        <div className={"general-tooltip" + (this.state.show_more_invited ? " display-tooltip" : "")}>
          <div className="tooltip-content">{all_invites}</div>
        </div>
      </div>
    );
  }

  addInterestToOffer() {
    $.ajax({
      method: "POST",
      url: "/interests",
      dataType: 'json',
      data: { 
        token: this.props.token, 
        offer_id: this.props.offer.id 
      },
      success: (data) => {
        console.log(data);
        this.props.updateOfferData(data);
      }
    });
  }

  removeInterestFromOffer() {
    $.ajax({
      method: "DELETE",
      url: "/interests/destroy",
      dataType: 'json',
      data: { 
        token: this.props.token, 
        offer_id: this.props.offer.id 
      },
      success: (data) => {
        this.props.updateOfferData(data);
      }
    });
  }

  renderInterestButton() {
    if (this.isAccountInterested()) {
      return (
        <button onClick={this.removeInterestFromOffer} className="offer-button-min w-media selected">
          <img src={IconSolidHeart} alt="" />
          <div className="button-text">Interested</div>
        </button>
      )
    } else {
      return (
        <button onClick={this.addInterestToOffer} className="offer-button-min w-media">
          <div className="button-text">Interested</div>
        </button>
      )
    }
  }

  render_full_height() {
    if (!this.props.full_height) return (<></>);

    let content = (
      <>
        {this.renderPicked(this.props.offer)}
        {this.renderInterested(this.props.offer)}
        <div className="card-select-options">
          <button onClick={this.select_card} className="offer-button-min choose-offer-button">
            Choose
          </button>
          {this.renderInterestButton()}
        </div>
      </>
    );
    if (this.props.is_selected) {
      content = (
        <>
          <div className="footer-message">
            <img src={CheckMark} />
            <div>OFFER SELECTED</div>
          </div>
        </>
      );
    }

    return (
      <div className={"card-footer" + (this.props.is_selected ? " selected-footer" : "")}>
        {content}
      </div>
    )
  }

  render() {
    if (!this.props.offer) {
      return (<></>);
    }

    let img_url = this.props.offer.images[0]?.image;
    let instagram_url = this.props.offer.images[0]?.credit_link;
    // let video_url = this.props.offer.video_url;
    // <img style={{display: !this.props.full_height || !this.state.video_enabled? "" : ""}} className="card-header-image" src={img_url || ""} />

    let banner_media = <img className="card-header-image" src={img_url || ""} />;
    if (this.props.full_height && instagram_url) {
      banner_media = (
        <a className="card-header-image" href={instagram_url} ref={this.banner_media_ref} target="_blank">
          {banner_media}
        </a>
      )
    }

    let banner_msg = (<></>);

    if (this.props.full_height) {
      if (this.props.offer?.going_fast_offer) {
        banner_msg = (
          <div className="last-chance-banner" data-type="going_fast" onClick={this.toggle_message}>
            <div className="msg" data-type="going_fast">Going Fast</div>
            <img src={IconTooltip} alt="" data-type="going_fast" />
          </div>
        )
      } else if (this.props.offer?.is_new_offer) {
        banner_msg = (
          <div className="last-chance-banner" data-type="new_partner" onClick={this.toggle_message}>
            <div className="msg" data-type="new_partner">New Partner</div>
            <img src={IconTooltip} alt="" data-type="new_partner" />
          </div>
        )
      } else if (this.props.offer?.last_chance_offer) {
        banner_msg = (<></>)
      } else if (this.props.offer?.repeat_offer) {
        banner_msg = (
          <div className="last-chance-banner repeat-banner" data-type="repeat" onClick={this.toggle_message}>
            <div className="msg" data-type="repeat">Repeat Offer</div>
            <img src={IconTooltip} alt="" data-type="repeat" />
          </div>
        )
      }
    }

    return (
      <div ref={this.card_ref} data-num={this.props.index} className={"offer-card" + (this.props.full_height ? " full-height" : "") + (this.props.is_selected ? " card-selected" : "")} onClick={this.hide_tooltip}>
        <div className="is-selected">
          <div className="selected-overlay"></div>
          <div className="image-container" ref={this.confetti_ref}>
            <img className="selected-image" src={OfferSelected} />
          </div>
        </div>
        <div className={"already-selected-card" + (this.props.chosen ? "" : " hide-selected")}>
          <div className="stamp-container">
            <div className="stamp">
              ALREADY CONFIRMED
            </div>
          </div>
        </div>
        {banner_media}
        {banner_msg}
        <div className="spending-banner">
          <div className="max-two-text-lines w-side-pad">
            {this.props.offer?.offer_amount || ""}
          </div>
        </div>
        <div className="text-area relative">
          <div className="location-subtitle">
            <img src={LocationFlag} />
            {this.props.offer?.location || ""}
          </div>
          { this.props.full_height &&
            <div className="more-details" onClick={this.open_slider} >
              <img src={IconDetails} alt="" />
            </div>
          }
          <div className="offer-card-title max-two-text-lines">{this.props.offer.name}</div>
          <div className="business-teaser">
            <div className={(this.props.full_height ? "max-two-text-lines" : "max-one-text-lines")}>
              {this.props.offer?.business_teaser || ""}
            </div>
          </div>
          {this.render_fine_print_days(this.props.offer)}
          {this.renderInviteAcceptance()}
          {this.renderNewInvitationInfo()}
        </div>
        {this.render_full_height()}
      </div>
    );
  }
}

export default OfferCard