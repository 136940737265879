import React from "react";

class MemberEdit extends React.Component {
  constructor(props) {
    super(props);
    var converted_birthday_date = this.props.preference_birthday_date ?
        new Date(this.props.preference_birthday_date + "T00:00:00Z") 
        : null;
    this.state = {
      get_same_offers: true,
      social_handles: this.props.social_handles,
      birthday_month: this.props.preference_birthday_month,
      birthday_date: converted_birthday_date,
      days: this.getBirthdayMonthDays(converted_birthday_date, this.props.preference_birthday_month),
      select_month: this.getBirthdayMonth(converted_birthday_date, this.props.preference_birthday_month),
      select_day: converted_birthday_date ? converted_birthday_date.getUTCDate() : 0,
      select_year: converted_birthday_date ? converted_birthday_date.getUTCFullYear() : 0,
      gender: this.props.preference_gender,
      relationship: this.props.preference_relationship,
      income: this.props.preference_income,
      spending: this.props.preference_spending,
      dog_friendly: this.props.preference_dog_friendly,
      family_offers: this.props.preference_family_offers,
      email: this.props.email,
      phone_number: this.props.phone_number,
      first_name: this.props.first_name,
      last_name: this.props.last_name,
      error_message: '',
      error_key: '',
      dirty_class: '',
      show_required_fields: false
    }

    this.changeGetSameOffers = this.changeGetSameOffers.bind(this);
    this.changeInstagramHandle = this.changeInstagramHandle.bind(this);
    this.changeEmailAddress = this.changeEmailAddress.bind(this);
    this.changeFirstName = this.changeFirstName.bind(this);
    this.changeLastName = this.changeLastName.bind(this);
    this.changePhoneNumber = this.changePhoneNumber.bind(this);
    this.saveProfileChanges = this.saveProfileChanges.bind(this);
    this.changeBirthday = this.changeBirthday.bind(this);
    this.changeSelectOption = this.changeSelectOption.bind(this);
    this.changeToggleOption = this.changeToggleOption.bind(this);
  }

  changeGetSameOffers(event) {
    this.setState({get_same_offers: !this.state.get_same_offers})
  }

  changeInstagramHandle(event) {
    const target = event.target;
    this.setState({social_handles: target.value, dirty_class: 'btn-primary', error_message: ''});
  }

  changeEmailAddress(event) {
    const target = event.target;
    this.setState({email: target.value, dirty_class: 'btn-primary', error_message: ''});
  }

  changeFirstName(event) {
    const target = event.target;
    this.setState({first_name: target.value, dirty_class: 'btn-primary', error_message: ''});
  }

  changeLastName(event) {
    const target = event.target;
    this.setState({last_name: target.value, dirty_class: 'btn-primary', error_message: ''});
  }

  changePhoneNumber(event) {
    const target = event.target;
    this.setState({phone_number: target.value, dirty_class: 'btn-primary', error_message: ''});
  }

  changeBirthday(event) {
    const target = event.target;
    const name = target.name;
    let localState = { [name]: target.value, dirty_class: 'btn-primary', error_message: '' };

    if (name == "select_month") {
      localState.days = this.fillSequence(1, this.datesInMonth(this.state.select_year, target.value));
      localState.birthday_month = target.value;
    }

    if (name == "select_year" && this.state.select_month) {
      localState.days = this.fillSequence(1, this.datesInMonth(target.value, this.state.select_month));
    }

    let birthdayObj = {
      day: this.state.select_day,
      month: this.state.select_month,
      year: this.state.select_year
    }
    
    birthdayObj[name.replace('select_', '')] = target.value;

    if (!Object.values(birthdayObj).includes(0)) {
      let birthday_date = new Date(`${birthdayObj.year}-${String(birthdayObj.month).padStart(2, '0')}-${String(birthdayObj.day).padStart(2, '0')}T00:00:00Z`)
      localState.birthday_date = birthday_date;
    }

    this.setState(localState); 
  }

  changeSelectOption(event) {
    const target = event.target;
    const name = target.name;

    this.setState({[name]: target.value, dirty_class: 'btn-primary', error_message: ''});
  }

  changeToggleOption(event) {
    const target = event.target;
    const name = target.name;
    this.setState({[name]: target.checked, dirty_class: 'btn-primary', error_message: ''});
  }

  getBirthdayMonth(date, month) {
    if (date) {
      return date.getUTCMonth() + 1;
    } else if (month) {
      return month;
    }
    return 0;
  }

  getBirthdayMonthDays(date, month) {
    if (date || month) {
      return this.fillSequence(1, this.datesInMonth(this.getBirthdayMonth(date, month)))
    }
    return [];
  }

  fillSequence(start, end) {
    var array = [];
    for (var i = start; i <= end; i += 1) {
        array.push(i);
    }
    return array;
  }

  datesInMonth(year, month) {
    switch (parseInt(month)) {
        case 2:
            var leapYear = ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
            return leapYear ? 29 : 28;

        case 1: case 3: case 5: case 7: case 8: case 10: case 12:
            return 31;

        default:
            return 30;
    }
  }

  getMonthNames() {
    return ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  }

  saveProfileChanges(event) {
    if (this.state.phone_number && this.state.phone_number.length > 0) {
      var phone = this.state.phone_number.replace(/\D/g,'');
      if (phone && phone[0] == "1") {
        phone = phone.slice(1)
      }
      if (phone.length != 10) {
        this.setState({error_message: "Invalid Phone Number", error_key: 'unique_phone_number'})
        return
      }
    }
    if (this.state.first_name == "" || this.state.last_name == "") {
      return;
    }

    if (this.state.birthday_month == 0) {
      this.state.birthday_month = null;
    }

    var submit_data = {account: {
      preference_birthday_month: this.state.birthday_month,
      preference_gender: this.state.gender,
      preference_relationship: this.state.relationship,
      preference_income: this.state.income,
      preference_spending: this.state.spending,
      preference_dog_friendly: this.state.dog_friendly,
      preference_family_offers: this.state.family_offers,
      social_handles: this.state.social_handles,
      phone_number: this.state.phone_number,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email
    }}

    if (this.state.birthday_date) {
      submit_data.account.preference_birthday_date = this.state.birthday_date;
    }

    if (this.props.in_setup) {
      if (this.state.birthday_date == null || 
          this.state.gender == null || 
          this.state.income == null || 
          this.state.spending == null || 
          this.state.relationship == null) {
        this.setState({show_required_fields: true})
        return
      }
    }

    if (this.props.setSetupStep) {
      submit_data["account"]["preference_setup_step"] = this.props.setSetupStep;
    }

    let self = this;
    var path = this.props.in_setup ? "/login/" : "/home/";
    $.ajax({
      method: "PUT",
      url: path+this.props.id,
      data: submit_data,
      dataType: 'json',
      success: function(data) {
        if (self.props.doneAction) {
          self.props.doneAction();
        } else {
          window.location.href = self.props.backLink;
        }
      },
      error: function(error) {
        self.setState({error_message: error.responseJSON.message, error_key: error.responseJSON.key});
      }
    });
  }

  nameInputClassState(value) {
    if (value == "") {
      return "large-input error";
    }
    return "large-input";
  }

  emailInputClassState() {
    if (this.state.error_key == 'unique_email' && this.state.error_message) {
      return 'large-input error'
    } 
    return 'large-input'
  }

  phoneNumberInputClassState() {
    if (this.state.error_key == 'unique_phone_number' && this.state.error_message) {
      return 'large-input error'
    } 
    return 'large-input'
  }

  renderMemberNameBlock() {
    return (
      <>
        <div className="added-margin">
          <h5>First Name:</h5>
          <input 
            className={this.nameInputClassState(this.state.first_name)}
            type="text"
            value={this.state.first_name || ''}
            onChange={this.changeFirstName}/>
          {this.state.first_name == '' &&
            <p className='error-text text-center'>First name can not be blank</p>
          }
        </div>
        <div className="added-margin">
          <h5>Last Name:</h5>
          <input 
            className={this.nameInputClassState(this.state.last_name)}
            type="text"
            value={this.state.last_name || ''}
            onChange={this.changeLastName}/>
          {this.state.last_name == '' &&
            <p className='error-text text-center'>Last name can not be blank</p>
          }
        </div>
      </>
    )
  }

  renderEmailBlock() {
    if (this.props.in_setup) {
      return ""
    }
    return (
      <div className="added-margin">
        <h5>Email Address:</h5>
        <input 
          className={this.emailInputClassState()}
          type="text"
          value={this.state.email || ''}
          onChange={this.changeEmailAddress}/>
        {this.state.error_key == 'unique_email' && this.state.error_message &&
          <p className='error-text text-center'>{this.state.error_message}</p>
        }
      </div>
    )
  }

  renderPhoneNumberBlock() {
    if (this.props.in_setup) {
      return ""
    }
    return (
      <div className="added-margin">
        <h5>Phone Number:</h5>
        <input 
          className={this.phoneNumberInputClassState()}
          type="text"
          value={this.state.phone_number || ''}
          onChange={this.changePhoneNumber}/>
        {this.state.error_key == 'unique_phone_number' && this.state.error_message &&
          <p className='error-text text-center'>{this.state.error_message}</p>
        }
      </div>
    )
  }

  canPauseOrCancel() {
    if (this.props.status == "schedule_to_pause" || this.props.status == "paused" || this.props.status == "covid_paused" ) {
      return false;
    }
    for (const subscription of this.props.subscriptions) {
      if (subscription.status != "TO CANCEL") {
        return true
      }
    }
    return false
  }

  renderSubscription(subscription) {
    var date = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
    if (subscription.subscription_data.period_end) {
      date = new Date(subscription.subscription_data.period_end);
    }

    var due_amount = "";
    if (subscription.subscription_data.plan_amount) {
      due_amount = " for $"+subscription.subscription_data.plan_amount;
    }

    var status = "Renews" 
    if (subscription.subscription_data.start_date) {
      status = "Starts on";
    }
    if (this.props.status == "schedule_to_pause") {
      status = "Pausing on";
      due_amount = "";
      date = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
    } else if (subscription.status == "TO CANCEL") {
      status = "Cancelling";
      due_amount = "";
    }

    var changes = "";

    // If there is a change being made to the subscription of any sort...
    if (subscription.subscription_data.new_subscription_area || 
        subscription.subscription_data.new_subscription_is_annual ||
        subscription.subscription_data.new_plan_amount) {
      // Skip if the changes are not actually any different that what is live
      if (subscription.subscription_data.new_subscription_area != subscription.area_name || 
          subscription.subscription_data.new_subscription_is_annual != subscription.is_annual ||
          subscription.subscription_data.new_plan_amount != subscription.subscription_data.plan_amount) {
        status = status +" and changes";
        due_amount = "";
        changes = (
            <>
              <br/>
              Currently {subscription.area_name} 
              {subscription.is_annual ? " Annual " : " Monthly "} 
              for ${subscription.subscription_data.plan_amount}
              <br/>
              Becomes {subscription.subscription_data.new_subscription_area} 
              {subscription.subscription_data.new_subscription_is_annual ? " Annual " : " Monthly "} 
              for ${subscription.subscription_data.new_plan_amount}
            </>
          )
      }
    }
    

    var date_string = date.getMonth()+1+"/"+date.getDate()+"/"+date.getFullYear();
    if (subscription.subscription_data.start_date) {
      date_string = subscription.subscription_data.start_date;
    }

    var period = subscription.is_annual ? "Annual" : "Monthly";

    return (
      <>
        <h5>Offline {subscription.area_name} {period}</h5>
        <p>{status} {date_string} {due_amount} {changes}</p>
      </>
    )
  }

  renderSubscriptionBlock() {
    if (this.props.status == "canceled") {
      return (
        <>
          <p>Cancelled, email <a style={{color: "#004FFF"}} href="mailto:support@letsgetoffline.com">support@letsgetoffline.com</a> to reactivate</p>
        </>
      )
    }

    return (
      this.props.subscriptions.map( subscription => (
          <div key={subscription.id}>
            {this.renderSubscription(subscription)}
          </div>
        )
      )
    )
  }

  render() {
    return (
      <div className='text-left'>
        <div className='one-line' style={{margin: "13px"}}>
          <div style={{width: '100%'}}>
            <a className='member__app-btn btn-small' href={this.props.backLink} onClick={this.props.backAction}>back</a>
          </div>
          { !this.props.in_setup && 
            <div className='text-right'>
              <button 
                className={'member__app-btn btn-small '+this.state.dirty_class}
                onClick={this.saveProfileChanges}>
                Save
              </button>
            </div>
          }
        </div>
        <h2 className='bottom-margin-zero'>Your Profile</h2>
        
        <div className="member__app-section-padded-large">
          { this.props.children }
          { this.renderMemberNameBlock() }
          { this.renderEmailBlock() }
          { this.renderPhoneNumberBlock() }
          <div className="added-margin">
            <h5>Instagram handle:</h5>
            <input 
              className='large-input'
              type="text"
              placeholder="@yourhandle"
              value={this.state.social_handles || ''}
              onChange={this.changeInstagramHandle}/>
          </div>

          <div className="added-margin">
            <h5>Birthday:</h5>
            <div className="one-line">
              <select name="select_month" id="birth_month" className='large-input' value={this.state.select_month} onChange={this.changeBirthday}>
                <option disabled value="0" style={{ display: 'none' }}>--</option>
                {this.getMonthNames().map((monthName, i) => <option key={monthName} label={monthName} value={i + 1} data-item-name="month" >{monthName}</option>)}
              </select>
              <select name="select_day" id="birth_day" className='large-input' value={this.state.select_day} onChange={this.changeBirthday}>
                <option disabled value="0" style={{ display: 'none' }}>--</option>
                {this.state.days.map((day) => <option key={`${this.state.birthday_month || 0}${day}`} label={day} value={day} >{day}</option>)}
              </select>
              <select name="select_year" id="birth_year" className='large-input' value={this.state.select_year} onChange={this.changeBirthday}>
                <option disabled value="0" style={{ display: 'none' }}>--</option>
                {this.fillSequence(new Date().getFullYear() - 120, new Date().getFullYear() - 18).reverse().map((year) => <option key={`year${year}`} label={year} value={year} data-item-name="year" >{year}</option>)}
              </select>
            </div>
            <div className="one-line-spread">
              <div>
                {!this.state.select_month && this.state.show_required_fields &&
                  <p className='error-text'>Required</p>
                }
              </div>
              <div>
                {this.props.in_setup && !this.state.select_day && this.state.show_required_fields &&
                  <p className='error-text'>Required</p>
                }
              </div>
              <div>
                {this.props.in_setup && !this.state.select_year && this.state.show_required_fields &&
                  <p className='error-text'>Required</p>
                }
              </div>
            </div>
          </div>


          <div className="added-margin">
            <h5>Gender:</h5>
            <select name="gender" id="gender" className='large-input' value={this.state.gender || ''} onChange={this.changeSelectOption}>
              <option disabled value="" style={{display: 'none'}}>--</option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="non-binary">Non-binary</option>
              <option value="not-answered">Prefer not to say</option>
            </select>
            {this.state.gender == null && this.state.show_required_fields && 
              <p className='error-text'>Required</p>
            }
          </div>


          <div className="added-margin">
            <h5>Relationship status:</h5>
            <select name="relationship" id="relationship" className='large-input' value={this.state.relationship || ''} onChange={this.changeSelectOption}>
              <option disabled value="" style={{display: 'none'}}>--</option>
              <option value="single">Single</option>
              <option value="dating">Dating</option>
              <option value="relationship">In a relationship</option>
              <option value="married">Married, or in a domestic partnership</option>
              <option value="not-answered">Prefer not to say</option>
            </select>
            {this.state.relationship == null && this.state.show_required_fields && 
              <p className='error-text'>Required</p>
            }
          </div>


          <div className="added-margin">
            <h5>Household income:</h5>
            <select name="income" id="income" className='large-input' value={this.state.income || ''} onChange={this.changeSelectOption}>
              <option disabled value="" style={{display: 'none'}}>--</option>
              <option value="Less than $50K">Less than $50K</option>
              <option value="$50k - $100k">$50k - $100k</option>
              <option value="$100k - $150k">$100k - $150k</option>
              <option value="$150k - $200k">$150k - $200k</option>
              <option value="$200k+">$200k+</option>
              <option value="not-answered">Prefer not to say</option>
            </select>
            {this.state.income == null && this.state.show_required_fields && 
              <p className='error-text'>Required</p>
            }
          </div>


          <div className="added-margin">
            <h5>How often are you comfortable spending $50+/person on dinner?</h5>
            <select name="spending" id="spending" className='large-input' value={this.state.spending || ''} onChange={this.changeSelectOption}>
              <option disabled value="" style={{display: 'none'}}>--</option>
              <option value="Regularly">Regularly</option>
              <option value="Around once/month">Around once/month</option>
              <option value="Rarely or only on special occasions">Rarely or only on special occasions</option>
              <option value="Never">Never</option>
            </select>
            {this.state.spending == null && this.state.show_required_fields && 
              <p className='error-text'>Required</p>
            }
          </div>

          {/* <div className="one-line added-margin">
            <div style={{width: "80%"}}>
              <h5>Are you interested in family Offers/Events/Perks?</h5>
            </div>
            <div style={{width: "20%"}} className='text-right'>
              <label className="switch large-switch" style={{display: 'inline-flex'}}>
                <input 
                  type="checkbox"
                  name="family_offers"
                  checked={this.state.family_offers}
                  onChange={this.changeToggleOption} />
                <span className="slider round"></span>
              </label>
            </div>
          </div>

          <div className="one-line added-margin">
            <div style={{width: "80%"}}>
              <h5>Are you interested in dog-friendly Offers/Events/Perks?</h5>
            </div>
            <div style={{width: "20%"}} className='text-right'>
              <label className="switch large-switch" style={{display: 'inline-flex'}}>
                <input 
                  type="checkbox"
                  name="dog_friendly"
                  checked={this.state.dog_friendly}
                  onChange={this.changeToggleOption} />
                <span className="slider round"></span>
              </label>
            </div>
          </div> */}

          { this.props.in_setup &&
            <div className='text-center'>
              <button 
                className='member__app-btn btn-primary'
                onClick={this.saveProfileChanges}>
                Next
              </button>
            </div>
          }

          { !this.props.in_setup && 
            <div>
              <hr className='subtle-divider'/>
              <div className='text-center'>
                <h3>Membership</h3>
                {this.renderSubscriptionBlock()}
                { this.canPauseOrCancel() &&
                  <>
                    <div>
                      <p><small><a className='blue-underline' href={'/settings/'+this.props.id+'/pause'}>Pause your account</a></small></p>
                    </div>
                    <div>
                      <p><small><a className='blue-underline' href={'/settings/'+this.props.id+'/cancel'}>Cancel your account</a></small></p>
                    </div>
                    <p><small><i>Your membership renewal cost may be less due to existing credits. Write into support with questions.</i></small></p>
                  </>
                }
              </div>
              <hr className='subtle-divider'/>
              <p className='text-center'><a href={"/home/update_card?id="+this.props.id}>Update Payment Method</a></p>
              <hr className='subtle-divider'/>
              <p className='text-center'><a href='/login/logout'>Sign out</a></p>
            </div>
          }

          <br/>
          <br/>
        </div>
      </div>
    );
  }
}

export default MemberEdit