import React from "react";

class ExpandingText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: this.props.expanded || false
    }

    this.toggle_expanded = this.toggle_expanded.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  toggle_expanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    let classes = ["expanding-text"];

    if(this.props.extra_classes) {
      classes.push(this.props.extra_classes);
    }

    if(!this.props.prop_controlled) {
      classes.push("auto-control");
    }

    if(this.state.expanded) {
      classes.push("expanded");
    }

    let style_object = {
      "--collapsed-height": this.state.expanded ? "100%" : (this.props.min_height || "60px")
    };

    return (
      <>
        <div
          suppressContentEditableWarning={true}
          contentEditable={true}
          className={classes.join(" ")}
          style={style_object}
          onChange={() => {}}
          onClick={this.toggle_expanded}
          onKeyDown={(e)=>{
            if(e.key == 'c' && (e.ctrlKey || e.metaKey)) {
              return;
            }
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {this.props.value}
        </div>
      </>
    )
  }
}

export default ExpandingText;