import React from "react";
import CYOOActiveSelectionGUI from "../../gui_components/cyoo/cyoo_active_selection.gui";

class CYOOActiveSelection extends React.Component {
  constructor(props) {
    super(props);

    this.clear_selection = this.clear_selection.bind(this);
  }

  clear_selection() {
    // this.setState({ selected_offer: null });
    this.props.clear_selection(this.props.slot_id)
  }

  render() {
    let label = this.props.selected_offer ? this.props.selected_offer.name : "No Offer Selected";
    label = this.props.error_message ? "SELECT AN OFFER TO CONTINUE" : label;

    return (
      <CYOOActiveSelectionGUI
        selected_offer={this.props.selected_offer}
        error_message={this.props.error_message}
        label={label}
        clear_selection={this.clear_selection}
      />
    );
  }
}

export default CYOOActiveSelection