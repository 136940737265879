import React from "react";

import RichTextEditor from 'react-rte';
import { RichTextEditorToolbarConfig } from '../../helpers/rte_toolbar_settings';

class FormMarkdownInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      content_editor_state: RichTextEditor.createValueFromString(props.value || '', 'markdown'),
      show_import: props.show_import || false
    }

    this.convertDescriptionToHTML = this.convertDescriptionToHTML.bind(this);
    this.onRichTextEditorChange = this.onRichTextEditorChange.bind(this);
  }

  convertDescriptionToHTML(){
    let markdown = RichTextEditor.createValueFromString(this.state.value, 'html')
      .toString('markdown')
      .replace(/[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000]/g, '');

      this.props.onChange(this.props.name, markdown);
      this.setState({
        show_import: false,
        value: markdown,
        content_editor_state: RichTextEditor.createValueFromString(markdown, 'markdown')
      });
  }

  onRichTextEditorChange(editor_state) {
    var new_value = editor_state.toString('markdown')
                                .replace(/[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000]/g, '')
                                .replaceAll(/\*\*(\s*)([^*\s]+[^*]*[^*\s]+)(\s*)\*\*/g, '$1**$2**$3')
                                .replaceAll(/\+\+(\s*)([^+\s]+[^+]*[^+\s]+)(\s*)\+\+/g, '$1++$2++$3')
                                .replaceAll(/\~\~(\s*)([^~\s]+[^~]*[^~\s]+)(\s*)\~\~/g, '$1~~$2~~$3')
                                .replaceAll(/\_(\s*)([^_\s]+[^_]*[^_\s]+)(\s*)\_/g, '$1_$2_$3');
    this.props.onChange(this.props.name, new_value);
    this.setState({
      value: new_value,
      content_editor_state: editor_state
    })
  }

  render() {
    const toolbarConfig = RichTextEditorToolbarConfig();
    return (
      <>
        {this.state.show_import &&
          <button
            style={{ margin: '10px 10px 0 0' }}
            className="standard-btn--rounded"
            onClick={this.convertDescriptionToHTML}>
            Convert to Markdown
          </button>
        }
        <RichTextEditor
          value={this.state.content_editor_state}
          onChange={this.onRichTextEditorChange}
          toolbarConfig={toolbarConfig} />
      </>
    )
  }
}

export default FormMarkdownInput;