import React from "react";
import IconFlag from "flag.svg";
import IconLinkMenu from "link-menu.png";
import IconLinkWebsite from "link-website.png";
import IconLinkInstagram from "link-instagram.png";
import IconLinkReserve from "link-reserve.png";
import IconDayOpen from "day-open.png";
import IconDayClosed from "day-closed.png";
import IconDayWarning from "day-warning.png";
import ReactHlsPlayer from 'react-hls-player';
// import MuxPlayer from "@mux/mux-player-react";
import GoogleMapReact from 'google-map-react';

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";

class OfferFull extends React.Component {
  constructor(props) {
    super(props);

    let video_image_url = this.props.offer.video_url ? this.props.offer.video_url.split('/').filter(s => s.endsWith('.m3u8'))[0] : null;
    if (video_image_url) {
      video_image_url = `https://image.mux.com/${video_image_url.replace('.m3u8', '')}/thumbnail.jpg`;
    }


    this.state = {
      show_video: props.show_video || false,
      show_image: props.show_image || false,
      video_image_url: video_image_url || "",
      video_playing: false,
    }

    this.player_ref = React.createRef();
    this.render_out_links = this.render_out_links.bind(this);
    this.map_image_to_fine_print_day = this.map_image_to_fine_print_day.bind(this);
  }

  componentDidMount() {
    if (this.player_ref.current) {
      this.player_ref.current.poster = this.state.video_image_url;
      this.player_ref.current.addEventListener('play', () => {
        this.setState({ video_playing: true });
        let player = this.player_ref.current;
        if(player.currentTime > player.duration - 1) {
          player.currentTime = 0;
        }
      });
      this.player_ref.current.addEventListener('pause', () => {
        this.setState({ video_playing: false });
      });
    }
  }

  map_image_to_fine_print_day(day) {
    if (day.state == "Valid") {
      return IconDayOpen;
    } else if (day.state == "Not Valid") {
      return IconDayClosed;
    } else if (day.state == "Closed") {
      return IconDayClosed;
    } else if (day.state == "Warning") {
      return IconDayWarning;
    }
  }

  render_out_links() {
    return (
      <div className="bordered-tray multi-row">
        {this.props.offer.menu_link &&
          <div className="button-in-tray full-width">
            <a target="_blank" href={this.props.offer.menu_link}>
              <div><img style={{ maxWidth: "20px" }} src={IconLinkMenu} /></div>
              <div className="button-text">MENU</div>
            </a>
          </div>
        }
        {this.props.offer.web_link &&
          <div className="button-in-tray full-width">
            <a target="_blank" href={this.props.offer.web_link}>
              <div><img style={{ maxWidth: "20px" }} src={IconLinkWebsite} /></div>
              <div className="button-text">WEBSITE</div>
            </a>
          </div>
        }
        {this.props.offer.instagram_link &&
          <div className="button-in-tray full-width">
            <a target="_blank" href={this.props.offer.instagram_link}>
              <div><img style={{ maxWidth: "20px" }} src={IconLinkInstagram} /></div>
              <div className="button-text">INSTA</div>
            </a>
          </div>
        }
        {this.props.offer.reservation_link &&
          <div className="button-in-tray full-width">
            <a target="_blank" href={this.props.offer.reservation_link}>
              <div><img style={{ maxWidth: "20px" }} src={IconLinkReserve} /></div>
              <div className="button-text">RESERVE</div>
            </a>
          </div>
        }
      </div>
    )
  }

  handleApiLoaded(map, maps, address) {
    if (map) {
      map.setOptions({
        clickableIcons: false,
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        fullscreenControl: false,
        gestureHandling: "none",
        keyboardShortcuts: false,
        draggable: false,
      });

      let marker = new maps.Marker({
        position: {
          lat: address.latitude,
          lng: address.longitude
        },
        map,
        title: address.name
      });
    }
  }

  render() {
    let img_url = this.props.offer.images[0]?.image;
    let instagram_url = this.props.offer.images[0]?.credit_link;
    let video_container_class = this.state.video_playing ? "video-playing-now" : "video-paused-now";
    return (
      <div id={this.props.offer.id} className={"react-offer " + (this.props.className ? this.props.className : "")}>
        <div className={this.state.show_video ? video_container_class : ""}>
          {this.state.show_image && (!this.state.show_video || !this.props.offer.video_url) &&
            <a target="_blank" href={instagram_url || "/offers/app_proof"}>
              <img className="mcnImage" align="middle" src={img_url} />
            </a>
          }
          {this.state.show_video && this.props.offer.video_url &&
            <ReactHlsPlayer
              className="mcnImage"
              src={this.props.offer.video_url}
              playerRef={this.player_ref}
              controls={true}
              autoPlay={false}
              muted={true}
            />
            // <MuxPlayer
            //   autoPlay
            //   loop
            //   muted
            //   thumbnailTime={0}
            //   streamType="on-demand"
            //   playbackId={this.props.offer.video_url.split('/').filter(s => s.endsWith('.m3u8'))[0]}
            // />
          }
          <div className="yellow-bkg full-width desktop-label text-center">
            {this.props.offer.offer_amount}
          </div>
        </div>
        <div className="app-section">
          <div className="multi-row">
            <div className="location-text full-width">
              <img src={IconFlag} />
              {this.props.offer.locations[0]}
            </div>
          </div>
          <h3>{this.props.offer.name}</h3>
          <p>{this.props.offer.business_teaser}</p>
          {this.render_out_links()}
          <div className="multi-row">
            <div style={{ flexGrow: 1 }}>
              <h4>Offer Availability</h4>
            </div>
          </div>
          <div className="offer-fine-print-days">
            {
              this.props.offer.fine_print_days.map((day, i) =>
                <div key={i + day.name} className={"day-square " + day.state.toLowerCase().replaceAll(' ', '-')}>
                  <div>{day.name.slice(0, 2)}</div>
                  <img src={this.map_image_to_fine_print_day(day)} alt="" />
                </div>
              )
            }
          </div>
          <p className="offer-fine-print-days-subtitle">Check the business website for up-to-date hours</p>
          {this.props.offer.important_notes &&
            <ReactMarkdown
              className="yellow-bkg full-width text-center offer-markdown-wrapper side-bumper"
              remarkPlugins={[remarkGfm]}
              // rehypePlugins={[rehypeRaw]}
              children={this.props.offer.important_notes}
            />
          }
        </div>
        {this.props.offer.addresses.length > 0 &&
          <div className="address-block">
            <div className="app-section address-section">
              <div className="address-slide">
                {this.props.offer.addresses.map((address, i) =>
                (
                  <div key={i} className="address-slide-card">
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: "AIzaSyDn0-hIOkUfB3xu2eJEYVKGePEZ02bTKyw" }}
                      center={{
                        lat: address.latitude,
                        lng: address.longitude
                      }}
                      zoom={16}
                      className="address-image"
                      yesIWantToUseGoogleMapApiInternals={true}
                      onGoogleApiLoaded={({ map, maps }) => { this.handleApiLoaded(map, maps, address) }}
                    >
                      {/* 
                        If we want the name of the business to be displayed, uncomment 
                        <Marker
                          lat={address.latitude}
                          lng={address.longitude}
                          name={address.name}
                        />
                      */}
                    </GoogleMapReact>
                    <div className="address-text">
                      <div className="address-block-text">
                        <div className="address-name">{address.name}</div>
                        <p>
                          {address.street}<br />
                          {address.city}, {address.state} {address.zip}
                        </p>
                      </div>
                      <a className='btn btn-white' style={{ display: "block" }} href={"https://www.google.com/maps/search/?api=1&output=classic&query=" + encodeURIComponent([address.name, address.street, address.city, address.state, address.zip].join(', '))} target="_blank">Get Directions</a>
                    </div>
                  </div>
                )
                )}
              </div>
            </div>
          </div>
        }
        <div className="app-section">
          {this.props.offer.sections.map((section, i) => (
            <div key={i}>
              <div className="desktop-label">
                <span className="yellow-bkg section-header header-spacing">
                  {section.icon &&
                    <img src={section.icon} alt="" />
                  }
                  <span>{section.title}</span>
                </span>
              </div>
              {section.content_type == "text" && section.content &&
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  // rehypePlugins={[rehypeRaw]}
                  children={section.content}
                />
              }
              {section.content_type == "image" &&
                <p><img style={{ maxWidth: "100%" }} src={section.content} alt="" /></p>
              }
            </div>
          ))}
          {this.props.offer.dont_forget.length > 0 &&
            <div className='bordered-tray' style={{ padding: "10px" }}>
              {/* <div className='desktop-label'>⚠ DONT FORGET</div> */}
              {this.props.offer.dont_forget.map((item, i) => (
                <p key={i}>{item}</p>
              ))}
            </div>
          }
        </div>
      </div>
    );
  }
}

class Marker extends React.Component {
  render() {
    return <div className="g-map-pin">{this.props.name}</div>
  }
}

export default OfferFull