import React from "react";
import SideBanner0 from 'sign-up-side-banner-0.jpg';
import SideBanner480 from 'sign-up-side-banner-480.jpg';
import SideBanner640 from 'sign-up-side-banner-640.jpg';
import SideBanner960 from 'sign-up-side-banner-960.jpg';
import SideBanner1024 from 'sign-up-side-banner-1024.jpg';

// All City Logos
import CityLogo from 'logo-city.png'
import CardUpdateSuccess from "./card_update_success";
import CardValidationPage from "./card_validation_page"

class CardUpdateConfirmationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      intent: this.props.intent
    }

    // Function bindings
    this.handleIntentCheckResponse = this.handleIntentCheckResponse.bind(this);
    this.selectContent = this.selectContent.bind(this);
    this.renderMainContent = this.renderMainContent.bind(this);
    this.renderSideContent = this.renderSideContent.bind(this);
  }

  handleIntentCheckResponse(data) {
    this.setState({ intent: data.intent })
    if (data.intent.status != 'processing' && data.intent.status != 'succeeded') {
      window.location.href = '/add-new-card?id=' + this.state.intent.customer
    }
  }

  selectContent() {

    if (this.state.intent.status == 'succeeded') {
      return (
        <CardUpdateSuccess />
      );
    } else {
      return (
        <CardValidationPage
          messaging={"Please wait while we processes this payment method"}
          handleResponse={this.handleIntentCheckResponse}
          intent={this.state.intent} />
      );
    }

  }

  renderMainContent() {
    let content = this.selectContent();

    return (
      <div className="confirmation-main-content">
        <img className="confirmation-banner" src={CityLogo} />
        {content}
      </div>
    );
  }

  renderSideContent() {
    return (
      <div className="confirmation-side-content">
        <img
          srcSet={`${SideBanner0} 1w, ${SideBanner480} 480w, ${SideBanner640} 640w, ${SideBanner960} 960w, ${SideBanner1024} 1024w`}
          sizes="(max-width: 1224px) 1px, (max-width: 1800px) 480px, (max-width: 2600px) 640px, (max-width: 3200px) 960px, 1024px"
        />
      </div>
    );
  }

  render() {
    return (
      <div className="confirmation-page-container">
        {this.renderMainContent()}
        {this.renderSideContent()}
      </div>
    );
  }
}

export default CardUpdateConfirmationPage