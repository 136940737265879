import React from "react";
import BasicScreenLayout from "./screen_formats/basic_screen_layout";
import SignupHeader from "./shared/signup_header";
import ProgressBar from "./shared/progress_bar";
import NotGoodFit from "./shared/not_good_fit";

import SignupV4Gist from "./v4/signup_v4_gist";
import SignupV4ProfileName from "./v4/signup_v4_profile_name";
import SignupV4PhoneNumber from "./v4/signup_v4_phone_number";
import SignupV4MemberQuestions from "./v4/signup_v4_member_questions";
import SignupV4Subscription from "./v4/signup_v4_subscription";
import SignupV4Payment from "./v4/signup_v4_payment";

import {trackPaymentSubmit} from "./utils/analytic_tracking.jsx";
import LogoArea from "icon-area-location.svg";
import CityLogo from "logo-city.svg";
import CityDropdown from "../shared/city_dropdown";

export const getScreen = (screen_code, context = this) => {

  let header = (
    <SignupHeader
      mobile_left={<img className="page-header-image" src={CityLogo} alt="Offline" />}
      mobile_center={<ProgressBar key={'mp'} step={context.state.currentScreen + 1} total_steps={context.screen_order.length + 1} />}
      mobile_right={<CityDropdown options={context.state.markets} value={context.state.selected_area.id} className="disable-select sign-up" icon={LogoArea}/>}
      desktop_left={<img className="page-header-image" src={CityLogo} alt="Offline" />}
      desktop_center={<ProgressBar key={'dp'} step={context.state.currentScreen + 1} total_steps={context.screen_order.length + 1} />}
      desktop_right={<CityDropdown options={context.state.markets} value={context.state.selected_area.id} className="disable-select sign-up" icon={LogoArea}/>}
    />
  );

  let signup_version = "4.0"

  switch (screen_code) {
    case "SignupV4Gist":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={
            <SignupHeader
              mobile_left={<img className="page-header-image" src={CityLogo} alt="Offline" />}
              desktop_left={<img className="page-header-image" src={CityLogo} alt="Offline" />}
            />
          }
          title="What does an Offline membership include?"
          subtitle="Choose 2 local restaurants and we’ll make sure you actually try them"
          analytic_tracking="Signup: Explainer + Email">
          <SignupV4Gist
            onSubmit={context.updateStateAndProgress}
            email={context.state.email}
            signup_version={signup_version}
            selected_area={context.state.selected_area}
            updateArea={context.updateArea}
            gtm_screen_name="membership-information"
            markets={context.state.markets}
            />
        </BasicScreenLayout>
      );
    
    case "SignupV4ProfileName":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={header}
          title="What's your name?"
          analytic_tracking="Signup: Name">
          <SignupV4ProfileName
            onSubmit={context.updateStateAndProgress}
            onBack={context.previousStep}
            first_name={context.state.first_name}
            last_name={context.state.last_name}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );
    
    case "SignupV4PhoneNumber":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={header}
          title="Your digits, plz."
          subtitle="You'll use this to log into the Offline app."
          analytic_tracking="Signup: Phone">
          <SignupV4PhoneNumber
            onSubmit={context.updateStateAndProgress}
            onBack={context.previousStep}
            phone_number={context.state.phone_number}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );
    
    case "SignupV4MemberQuestions":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={header}
          title="Lastly, real quick..."
          subtitle="Before you pick your restaurants tell us about yourself."
          analytic_tracking="Signup: Questions"
          customerio_tracking={context.createCioProgressCallback(1)}>
          <SignupV4MemberQuestions
            onSubmit={context.updateStateAndProgress}
            cioStep={1}
            onBack={context.previousStep}
            reason_to_become_member={context.state.reason_to_become_member}
            supporting_local={context.state.supporting_local}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );
    
    case "SignupV4Subscription":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={header}
          title="When should your trial start?"
          analytic_tracking="Signup: Subscription"
          customerio_tracking={context.createCioProgressCallback(2)}>
          <SignupV4Subscription
            when_to_start={context.state.when_to_start}
            selected_area={context.state.selected_area}
            selected_plan={context.state.selected_plan}
            referral_details={context.state.referral_details}
            onSubmit={context.updateStateAndProgress}
            onBack={context.previousStep}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );
    
    case "SignupV4Payment":
      let onPaymentSubmit = function() {
        trackPaymentSubmit(context);
      }
      return (
        <BasicScreenLayout
          key={screen_code}
          header={header}
          title="What is your payment info for future reference"
          subtitle="Don't worry. We won't charge your card on file during your trial. You can cancel your trial anytime."
          analytic_tracking="Signup: Payment">
          <SignupV4Payment
            referral_details={context.state.referral_details}
            valid_referral={context.state.valid_referral}
            selected_area={context.state.selected_area.id}
            stripe_key={context.props.stripe_key}
            setup_intent={context.state.setup_intent}
            confirmation_path={context.props.confirmation_path}
            onSubmit={context.updateStateAndProgress}
            onBack={context.previousStep}
            trackPaymentSubmit={onPaymentSubmit}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );

    case "SignupV4BadFit":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={
            <SignupHeader
              mobile_center={<img className="page-header-image" src={CityLogo} alt="Offline" />}
              desktop_center={<img className="page-header-image" src={CityLogo} alt="Offline" />}
            />
          }
          analytic_tracking="Signup: Not Good Fit">
          <NotGoodFit
            selected_area={context.state.selected_area}
            reason_to_become_member={context.state.reason_to_become_member}
            supporting_local={context.state.supporting_local}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );

    default:
      return (<></>);

  }
}