import { posthog } from "posthog-js";
import React from "react";

class MembershipInformationGUI extends React.Component {
  constructor(props) {
    super(props);

    this.pending_submission = false;

    this.changeInputField = this.changeInputField.bind(this);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Profile");
      posthog.capture("Signup: Profile");
    }
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;
    if (target.type == "checkbox") {
      return this.props.onStateUpdate(name, target.checked);
    } else {
      return this.props.onStateUpdate(name, target.value);
    }
  }

  render() {
    return (
      <div className="signup-v2-column membership-information">
        <div className="membership-info-title">Real quick...</div>
        <div className='membership-info-subtitle'>Before we make this official, a few quick q's.</div>

        <div className="name-input">
          <div className="input-label">Name</div>
          <div className="membership-info-input-container">
            <input type="text"
              className="membership-info-input signup-v2-input"
              id="input_first_name"
              name="first_name"
              placeholder="First Name"
              onChange={this.changeInputField}
              value={this.props.first_name} />
            <input type="text"
              className="membership-info-input signup-v2-input"
              id="input_last_name"
              name="last_name"
              placeholder="Last Name"
              onChange={this.changeInputField}
              value={this.props.last_name}
              style={{ marginLeft: '1rem' }} />
          </div>
        </div>

        <div className="email-input">
          <div className="input-label">Email Address</div>
          <input type="text"
            className="membership-info-input signup-v2-input"
            id="input_email"
            name="email"
            placeholder="Email Address"
            onChange={this.changeInputField}
            value={this.props.email} />
          <div className="input-error">
            { this.props.error_email && 
              <span>
                {this.props.error_email}<br/>
                If you were a previous member, please redownload the app (
                <a href="https://apps.apple.com/us/app/offline-restaurant-club/id1632270215" >iOS App Store</a> | 
                <a href="https://play.google.com/store/apps/details?id=com.letsgetoffline.lgomobile">Google Play Store</a>
                ) to sign back in.
              </span>
            }
          </div>
          
        </div>

        <div className="phone-input">
          <div className="input-label">Phone Number</div>
          <input type="text"
            className="membership-info-input signup-v2-input"
            id="input_phone_number"
            name="phone_number"
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="Phone Number"
            onChange={this.changeInputField}
            value={this.props.phone_number} />
          <div className="input-error">
            {this.props.error_phone_number &&
              <span>
                {this.props.error_phone_number}<br/>
                If you were a previous member, please redownload the app (
                <a href="https://apps.apple.com/us/app/offline-restaurant-club/id1632270215" >iOS App Store</a> | 
                <a href="https://play.google.com/store/apps/details?id=com.letsgetoffline.lgomobile">Google Play Store</a>
                ) to sign back in.
              </span>
            }
          </div>
          <div className="input-subtitle">Only real team members will text you...promise</div>
        </div>

        <div className="new-member-questions">
          <div className="input-label">When it comes to <i>supporting</i> local restaurants, you</div>
          <select
            id="supporting_local"
            name="supporting_local"
            onChange={this.changeInputField}
            value={this.props.supporting_local}
            style={{color: (this.props.supporting_local? "#000" : "rgba(0, 0, 0, 0.6)")}} >
            <option value="" disabled hidden={true} label="Select"></option>
            <option value="constantly_tip">Constantly tip generously</option>
            <option value="tip_based_on_service">Vary tip based on service</option>
            <option value="tipping_is_questionable">Think the concept of tipping is questionable</option>
          </select>

          <div className="input-label">If you had to pick one, what's the #1 reason you want to become a Member?</div>
          <select
            id="reason_to_become_member"
            name="reason_to_become_member"
            onChange={this.changeInputField}
            value={this.props.reason_to_become_member}
            style={{color: (this.props.reason_to_become_member? "#000" : "rgba(0, 0, 0, 0.6)")}} >
            <option value="" disabled hidden={true} label="Select"></option>
            <option value="explore_restaurants">Explore new restaurants</option>
            <option value="save_money">Save a few bucks dining out</option>
            <option value="meet_people">Meet new people in town</option>
          </select>
        </div>


        {this.props.children}

      </div>
    );
  }
}

export default MembershipInformationGUI