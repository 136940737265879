import React from "react";
import IconError from "icon-error.svg"

class FooterButton extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div style={{margin: "14px"}}>
        <div className={"error-text" + (this.props.error_message ? "" : " show-none")}>
          <img src={IconError} />
          <div className="error-body">{this.props.error_message}</div>
        </div>
        <button className="offer-button-full full" ref={this.props.child_ref} onClick={this.props.on_click_action || (() => { })} disabled={!this.props.is_active || false}>
          {this.props.button_label || "temp button label"}
        </button>
      </div>
    );
  }
}

export default FooterButton