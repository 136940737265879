import React from "react";
import IconConfirmation from "icon-confirmation";
import Background from "signup-confirmation-background.png";

import CityLogo from 'logo-city.png'
import WelcomePage from "./welcome_page";
import CardValidationPage from "./card_validation_page"

class ConfirmationPageV2 extends React.Component {
  constructor(props) {
    super(props);

    // Define city_logos locally so we can conditionally
    // chose a logo
    let area = props.area || { name: "Raleigh" }

    this.state = {
      area,
      intent: this.props.intent,
      account_status: this.props.account_status || "pending"
    }

    // Function bindings
    this.handleIntentCheckResponse = this.handleIntentCheckResponse.bind(this);
    this.selectContent = this.selectContent.bind(this);
    this.renderMainContent = this.renderMainContent.bind(this);
  }

  handleIntentCheckResponse(data) {
    if (data.account_status == "success") {
      this.setState({ account_status: data.account_status });
    } else if (data.account_status == 'error') {
      window.location.href = '/add-new-card?id=' + this.state.intent.customer.id
    }
  }

  selectContent() {

    if (this.state.account_status == 'success') {
      if (this.props.reactivating) {
        return (
          <div id="pick_your_own_offers">
            <div className="confirmation-container" style={{ textAlign: 'center' }}>
              <div className="offer-confirmation-title">Account Reactivated!</div>
              <div className="offer-confirmation-subtitle">Head back to the app to get started.</div>
              <img src={IconConfirmation} className="confirmation-icon" />
            </div>
          </div>
        )
      }
      return (
        <WelcomePage
          city={this.state.area.name}
        />
      );
    } else {
      return (
        <CardValidationPage
          messaging={"Please wait while we setup your Offline account"}
          handleResponse={this.handleIntentCheckResponse}
          intent={this.state.intent} />
      );
    }

  }

  renderMainContent() {
    let content = this.selectContent();

    return (
      <div className="confirmation-main-content">
        <img className="confirmation-banner" src={CityLogo} />
        {content}
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="confirmation-page-v2-container">
          {this.renderMainContent()}
        </div>
        <div className="confirmation-page-v2-background-container">
          <img className="confirmation-page-v2-background-image" src={Background} alt="" />
        </div>
      </>
    );
  }
}

export default ConfirmationPageV2