import React from "react";

class TextWrapper extends React.Component {
    constructor(props) {
        super(props);
        let localText = props.text || "";
        let maxCharacters = props.maxCharacters || 0;
        this.state = {
            expand: props.startExpanded || false,
            canExpand: localText.length > maxCharacters
        }
        this.getTextWrap = this.getTextWrap.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
    }

    getTextWrap() {
        if (this.state.expand) return this.props.text;

        let text = this.props.text;
        if (this.props.maxCharacters) {
            text = text.slice(0, this.props.maxCharacters);
        }
        return text;
    }

    toggleExpanded() {
        this.setState({ expand: !this.state.expand });
    }

    render() {
        return (
            <div className={"text-wrapper" + (this.state.expand ? " wrap-expanded" : "")} onClick={this.state.expand && this.state.canExpand ? this.toggleExpanded : undefined}>
                {this.getTextWrap()}
                {!this.state.expand && this.state.canExpand &&
                    <button onClick={this.toggleExpanded}>
                        ...
                    </button>
                }
            </div>
        )
    }
}

export default TextWrapper;