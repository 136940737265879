import React from "react";

class NameInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="name-input">
          <input type="text"
            className="membership-info-input signup-input signup-input"
            autoCapitalize="none"
            id="input_first_name"
            name="first_name"
            placeholder="First Name"
            onChange={this.props.changeInputField}
            value={this.props.first_name} />
          <div className="input-error">
            {this.props.error_first_name &&
              <span>
                {this.props.error_first_name}
              </span>
            }
          </div>
          <input type="text"
            className="membership-info-input signup-input signup-input"
            autoCapitalize="none"
            id="input_last_name"
            name="last_name"
            placeholder="Last Name"
            onChange={this.props.changeInputField}
            value={this.props.last_name} />
          <div className="input-error">
            {this.props.error_last_name &&
              <span>
                {this.props.error_last_name}
              </span>
            }
          </div>
        </div>
    );
  }
}

export default NameInput