import React from "react";
import PaymentInputGUI from "../gui_components/stripe_checkout/payment_input_gui";
import SignupHeader from "../gui_components/signup_header";
import ProgressBar from "../../../shared/progress_bar";
import { getIDforGTM } from "./gtm_methods";

import BackIcon from "icon-back-left.svg";

class PaymentInput extends React.Component {
  constructor(props) {
    super(props);

    this.trackPaymentSubmit = this.trackPaymentSubmit.bind(this);
  }

  componentDidMount() {
    document.scrollingElement.scrollTop = 0;
    if (analytics_loaded) {
      mixpanel.track("Signup: Payment");
      posthog.capture("Signup: Payment");
    }
  }

  trackPaymentSubmit() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Complete");
      posthog.capture("Signup: Complete");
    }

    if(typeof(dataLayer) !== "undefined" && dataLayer) {
      let value = this.props.area?.monthly_subscription_price || 1500;
      dataLayer.push({
        event: "purchase",
        ecommerce: {
          currency: "USD",
          items: [
            {
              item_id: this.props.area?.premium_monthly_plan,
              item_name: this.props.area?.name + " Monthly",
              coupon: this.props.valid_referral,
              item_category: "Subscription",
              item_category_2: "Trial Activation",
              item_variant: "Monthly",
              price: (value / 100),
            }
          ],
          transaction_id: this.props.setup_intent?.id,
          value:  (value / 100),
        }
      });
    }

    $.ajax({
      method: "POST",
      url: "/payment-submit",
      data: {
        waitlist_id: this.props.waitlist_id
      },
      dataType: 'json',
      success: (data) => {
      },
      error: (error) => {
      }
    });
  }

  render() {
    return (
      <>
        <SignupHeader
          mobile_left={this.props.currentScreen == 0 ? (null) : (<img className="header-back-arrow-button" id={getIDforGTM("back-to-previous-screen", this.props)} src={BackIcon} alt="Back" onClick={this.props.previousScreen} />)}
          mobile_center={<ProgressBar step={this.props.currentScreen + 1} total_steps={this.props.total_screens + 1} />}
          desktop_left={this.props.city_logo_img}
          desktop_right={<ProgressBar step={this.props.currentScreen + 1} total_steps={this.props.total_screens + 1} />}
        />
        <PaymentInputGUI
          stripe_key={this.props.stripe_key}
          setup_intent={this.props.setup_intent}
          phone_number={this.props.phone_number}
          confirmation_path={this.props.confirmation_path}
          waitlist_id={this.props.waitlist_id}
          previousScreen={this.props.previousScreen}
          trackPaymentSubmit={this.trackPaymentSubmit}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
        />
      </>
    );
  }
}

export default PaymentInput