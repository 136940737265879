import React from "react";
import CustomerReviews from "./customer_reviews";
import RadialGraph from "../admin_app/shared/radial_graph";

class BelowThreeStars extends React.Component {
  constructor(props) {
    super(props);

    this.getGraphData = this.getGraphData.bind(this);
  }

  getGraphData() {
    let improvement_areas = {};
    let total = 0;

    this.props.offer_assignments.forEach(offer_assignment => {
      total++;
      let areas_of_improvement = offer_assignment.areas_of_improvement;

      areas_of_improvement.forEach(area_of_improvement => {
        if (!improvement_areas[area_of_improvement]) {
          improvement_areas[area_of_improvement] = 0;
        }
        improvement_areas[area_of_improvement] += 1;
      });
    });

    let graphs = [];
    let colors = ["blue", "teal", "yellow", "red", "brown", "green"];
    Object.keys(improvement_areas).forEach((area_of_improvement, i) => {
      graphs.push({
        label: area_of_improvement,
        value: Math.round(100 * improvement_areas[area_of_improvement] / total),
        class_color: colors[i]
      });
    });

    return { graphs, improvement_areas };
  }

  render() {
    if(this.props.offer_assignments.length == 0) return (<></>);
    
    let graph_data = this.getGraphData();

    return (
      <div className="partner-report-below-three-stars">
        <div className="below-three-stars-title">
          3 Stars & Below Ratings Breakdown
        </div>
        <div className="below-three-stars-container">
          {
            graph_data.graphs.length > 0 &&
            <RadialGraph
              graph_title="Areas That Require Improvements"
              graphs={graph_data.graphs}
              include_animation={true}
            />
          }
          <CustomerReviews
            offer_assignment={this.props.offer_assignments}
            highly_reviewed={false}
          />
        </div>
      </div>
    )
  }
}

export default BelowThreeStars