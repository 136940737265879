import React from "react";

class SectionWithHeader extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='section-grouping'>
        { this.props.header &&
          <div className='section-header'>
            {this.props.header}
          </div>
        }
        <div className='section-body'>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default SectionWithHeader