import React from "react";
import SideBanner0 from 'sign-up-side-banner-0.jpg';
import SideBanner480 from 'sign-up-side-banner-480.jpg';
import SideBanner640 from 'sign-up-side-banner-640.jpg';
import SideBanner960 from 'sign-up-side-banner-960.jpg';
import SideBanner1024 from 'sign-up-side-banner-1024.jpg';
import InitialUserForm from "./initial_user_form";
import SubscriptionForm from "./subscription_form";
import StripePaymentForm from "./stripe_payment_form";
import moment from 'moment-timezone';

// All City Logos
import CityLogo from 'logo-city.png'
import CharlotteLogo from 'logo-charlotte.png'
import RaleighLogo from 'logo-raleigh.png'
import DurhamLogo from 'logo-durham.png'

import BackArrow from 'icon-back-arrow.svg'
import ProgressFilled from 'icon-progress-filled.svg'
import ProgressEmpty from 'icon-progress-empty.svg'

import SubscriptionForm678 from "./subscription_form_6_7_8";
import ExtendedFooterContent from "./extended_footer_content";
import RecapSection from "./sign_up_sections/recap_section";

class SignUpPage extends React.Component {
  constructor(props) {
    super(props);

    // Define which screens can be loaded
    const screens = {
      INITIAL_USER_INFORMATION: 0,
      SUBSCRIPTION_INFORMATION: 1,
      PAYMENT_INFORMATION: 2,
      OUTBOUND_ACTION: 3
    }
    this.screens = screens;

    this.city_logos = { CityLogo, CharlotteLogo, RaleighLogo, DurhamLogo }
    let area = this.getAreaFromId(props.selected_market) || { name: "Raleigh" }

    let membership_start = new Date().getDate() <= 10 ? "today" : "defer";

    let mixpanel_original_id = null;
    if (analytics_loaded) {
      mixpanel_original_id = mixpanel.get_distinct_id();
    }

    // Setup state
    this.state = {
      currentScreen: 0,
      selected_market: props.selected_market || 2,
      mixpanel_original_id,
      email: props.email || "",
      phone_number: props.phone_number || "",
      first_name: props.first_name || "",
      last_name: props.last_name || "",
      referral_details: props.referral_details,
      selected_plan: props.selected_plan,
      membership_start,
      city_logo: this.city_logos[area.name + "Logo"] || CityLogo,
      show_both_plans: props.signup_version == 8,
      sent_plan_event: false,
      render_conversion_modal: false,
      can_show_conversion_modal: true,
    }

    if ([6,7,8,9].includes(this.props.signup_version)) {
      this.state["selected_plan"] = "annual";
    }

    //gift_code

    let autofill_referral = props.referral_details?.referral.referral_code
      || props.referral_details?.referral.gift_code
      || ''

    this.static_params = {
      autofill_referral,
      selected_plan: props.selected_plan
    }

    this.event_button = null;

    // Function bindings
    this.nextAction = this.nextAction.bind(this);
    this.previousAction = this.previousAction.bind(this);
    this.selectContent = this.selectContent.bind(this);
    this.renderStatusBar = this.renderStatusBar.bind(this);
    this.renderMainContent = this.renderMainContent.bind(this);
    this.renderSideContent = this.renderSideContent.bind(this);
    this.toggleConversionModal = this.toggleConversionModal.bind(this);
    this.updateStateValue = this.updateStateValue.bind(this);
    this.getAreaFromId = this.getAreaFromId.bind(this);

    if (analytics_loaded) {
      var signup_version = "1.0";
      if ([6,7,8,9].includes(this.props.signup_version)) {
        signup_version = this.props.signup_version + ".0";
      }
      mixpanel.register({
        "Market": area.name,
        "Signup Version": signup_version
      });
      posthog.register({
        "Market": area.name,
        "Signup Version": signup_version
      });
    }
  }

  updateStateValue(name, value) {
    let newState = { [name]: value };
    if (name == "selected_market") {
      let area = this.getAreaFromId(value)
      let city_logo = this.city_logos[area.name + "Logo"] || CityLogo
      newState.city_logo = city_logo;
      this.setCityURL(this.props.areas.filter(area => area.id == value)[0].slug);

      
      if (this.props.signup_version != 6 && area.name == "Denver") {
        window.location.href = "/signup6/" + area.slug;
      }
      newState.membership_start = area.deferral_only ? "defer" : this.state.membership_start;

      if (analytics_loaded) {
        mixpanel.register({
          "Market": area.name
        });
        posthog.register({
          "Market": area.name
        });
      }
    }
    this.setState(newState)
  }

  nextAction(event) {
    // Only allow an individial back button
    // to be clicked once.
    let target = event?.target;
    if (target && target == this.event_button) {
      return false;
    } else {
      this.event_button = target;
    }

    var new_state = {}

    if (this.state.currentScreen == this.screens.SUBSCRIPTION_INFORMATION) {
      if (!this.state.sent_plan_event) {
        new_state.sent_plan_event = true;
        this.trackConvergeEvent("Picked Plan")
      }
    }

    if (this.state.currentScreen < 2) {
      new_state.currentScreen = this.state.currentScreen + 1;
    }
    window.scrollTo(0, 0);
    this.setState(new_state);
  }

  toggleConversionModal() {
    this.setState({ show_conversion_modal: !this.state.show_conversion_modal, can_show_conversion_modal: false });
  }

  trackConvergeEvent(event_name) {
    var signup_version = (this.props.signup_version || 1) + ".0";

    if (typeof(cvg) != 'undefined') {
      var selected_area = this.props.areas.find(area => area.id == this.state.selected_market)
      var state = selected_area.state_name
      var sanitized_email = this.state.email.toLowerCase().replace(/\s/g, '');
      var plan_type = this.state.selected_plan == "annual" ? "Annual" : "Monthly";
      var plan_cost = ((plan_type == "Annual" ? selected_area.yearly_subscription_price : selected_area.monthly_subscription_price) / 100);
      cvg({
        method: "track",
        eventName: event_name,
        properties: {
          total_price: plan_cost,
          plan_type: plan_type
        },
        profileProperties: {
          // Pass if available
          $email: sanitized_email,
          $first_name: this.state.first_name,
          $last_name: this.state.last_name,
          $phone_number: this.state.phone_number,
          $country_code: "US",
          $state: state
        },
        aliases: ["urn:email:"+sanitized_email],
      });
    }
  }

  previousAction(event) {
    // Only allow an individial back button
    // to be clicked once.
    let target = event?.target;
    if (target && target == this.event_button) {
      return false;
    } else {
      // Allow header back button to be clicked
      // multiple times
      this.event_button = target.dataset.back ? null : target;
    }

    if (this.state.currentScreen > 0) {
      window.scrollTo(0, 0);
      this.setState({ currentScreen: this.state.currentScreen - 1 });
    }
  }

  getAreaFromId(id) {
    let areas = this.props.areas || [];
    return areas.filter(area => area.id == id)[0];
  }

  setCityURL(city) {
    let searchURL = Object.keys(this.static_params)
      .map(key => this.static_params[key] ? key + '=' + this.static_params[key] : null)
      .filter(param => param)
      .join('&');

    searchURL = searchURL ? '?' + searchURL : "";
    var url_version = this.props.signup_version
    if (url_version == 1) {
      url_version = ""
    }

    history.pushState({ pageID: city.toLowerCase() }, city, '/signup'+url_version+'/' + city.toLowerCase() + searchURL);
  }

  selectContent(screen_id) {
    switch (screen_id) {
      case this.screens.INITIAL_USER_INFORMATION:
        return (
          <InitialUserForm
            areas={this.props.areas}
            email={this.state.email}
            phone_number={this.state.phone_number}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            selected_market={this.state.selected_market}
            referral_details={this.state.referral_details}
            signup_version={this.props.signup_version}
            faqs={this.props.faqs || null}
            testimonials={this.props.testimonials || []}
            popular_offers={this.props.popular_offers || {}}
            nextAction={this.nextAction}
            onStateUpdate={this.updateStateValue}>
          </InitialUserForm>
        );

      case this.screens.SUBSCRIPTION_INFORMATION:

        if([6,7,8,9].includes(this.props.signup_version)) {
          return (
            <SubscriptionForm678
            areas={this.props.areas}
            waitlist_id={this.state.waitlist_id}
            email={this.state.email}
            phone_number={this.state.phone_number}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            selected_market={this.state.selected_market}
            referral_details={this.state.referral_details}
            selected_plan={this.state.selected_plan}
            membership_start={this.state.membership_start}
            mixpanel_original_id={this.state.mixpanel_original_id}
            setup_intent={this.state.setup_intent}
            onStateUpdate={this.updateStateValue}
            nextAction={this.nextAction}
            previousAction={this.previousAction}
            signup_version={this.props.signup_version}
            show_both_plans={this.state.show_both_plans}
            show_conversion_modal={this.toggleConversionModal}
            render_conversion_modal={this.state.show_conversion_modal}
            can_show_conversion_modal={this.state.can_show_conversion_modal}
            toggle_show_both_plans={()=>{
              this.setState({show_both_plans: !this.state.show_both_plans})
            }}
          />
          )
        }

        return (
          <SubscriptionForm
            areas={this.props.areas}
            waitlist_id={this.state.waitlist_id}
            email={this.state.email}
            phone_number={this.state.phone_number}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            selected_market={this.state.selected_market}
            referral_details={this.state.referral_details}
            selected_plan={this.state.selected_plan}
            membership_start={this.state.membership_start}
            mixpanel_original_id={this.state.mixpanel_original_id}
            setup_intent={this.state.setup_intent}
            onStateUpdate={this.updateStateValue}
            nextAction={this.nextAction}
            previousAction={this.previousAction}
          />
        )

      case this.screens.PAYMENT_INFORMATION:
        return (
          <StripePaymentForm
            waitlist_id={this.state.waitlist_id}
            stripe_key={this.props.stripe_key}
            setup_intent={this.state.setup_intent}
            confirmation_path={this.props.confirmation_path}
            onStateUpdate={this.updateStateValue}
            nextAction={this.nextAction}
            previousAction={this.previousAction}
            referral_details={this.state.referral_details?.referral}
            selected_plan={this.state.selected_plan}
            selected_area={this.getAreaFromId(this.state.selected_market)}
            membership_start={this.state.membership_start}
            signup_version={this.props.signup_version}
          />
        )

      case this.screens.OUTBOUND_ACTION:
        return //renderOutboundAction();

      default:
        return //renderInitialUserInformation();
    }
  }

  renderStatusBar() {
    let progress = [];
    for (let i = 0; i < 3; i++) {
      if (i <= this.state.currentScreen) {
        progress.push(ProgressFilled);
      } else {
        progress.push(ProgressEmpty);
      }
    }

    return (
      <>
        {
          progress.map((icon, i) =>
            <img key={"pro" + i} src={icon}></img>
          )
        }
      </>
    )
  }

  renderMainContent() {
    let content = this.selectContent(this.state.currentScreen);
    let button_class = this.state.currentScreen < 1 ? "sign-up-header-back-spacer" : "sign-up-header-back-button"
    let status_footer_class = (this.props.signup_version == 9 && this.state.currentScreen == 0) ? "hide-on-9" : ""

    return (
      <div className={"sign-up-main-content signup_v" + this.props.signup_version + " step_0" + this.state.currentScreen}>
        <div className="sign-up-page-header">
          <button
            data-back="true"
            className={button_class}
            onClick={this.previousAction}
          >
            <img src={BackArrow} id={"back_to_step_" + this.state.currentScreen} alt="Back" data-back="true" />
          </button>
          <a href={`https://www.letsgetoffline.com/join${location.search}`} target="_blank">
            <img className="sign-up-page-header-image" src={this.state.city_logo} alt="Offline" />
          </a>
          <div className="sign-up-step">
            {"Step " + (this.state.currentScreen + 1) + " of 3"}
          </div>
        </div>

        {content}

        {(this.state.currentScreen == 2 && this.props.signup_version == 9) &&
          <ExtendedFooterContent extra_class="sign-up-column space-top">
            <RecapSection/>
          </ExtendedFooterContent>
        }

        <div className={"sign-up-page-status-footer " + status_footer_class}>
          {this.renderStatusBar()}
        </div>

        <div className="sign-up-page-desktop-footer">
          <div>© 2024 Offline Media, Inc.</div>
          <div className="questions-link">
            <a href="https://support.letsgetoffline.com/en/" target="_blank" style={{ color: "#4f3b1a", textDecoration: "none" }}>Have Questions?</a>
          </div>
        </div>
      </div>
    );
  }

  renderSideContent() {
    return (
      <div className="sign-up-side-content">
        <img
          srcSet={`${SideBanner0} 1w, ${SideBanner480} 480w, ${SideBanner640} 640w, ${SideBanner960} 960w, ${SideBanner1024} 1024w`}
          sizes="(max-width: 1224px) 1px, (max-width: 1800px) 480px, (max-width: 2600px) 640px, (max-width: 3200px) 960px, 1024px"
        />
      </div>
    );
  }

  render() {
    return (
      <div className="sign-up-page-container">
        {this.renderMainContent()}
        {this.renderSideContent()}
      </div>
    );
  }
}

export default SignUpPage