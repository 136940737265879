import React from "react";

class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <label className={"switch" + (this.props.large ? " large-switch" : "")} style={{ display: (this.props.hide ? "none" : "block") }}>
        <input type="checkbox"
          checked={this.props.value || false}
          onChange={this.props.onChange || (() => { })}
        />
        <span className="slider round"></span>
      </label>
    )
  }
}

export default ToggleSwitch;