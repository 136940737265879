import React from "react";
import CityLogo from "logo-city.svg";
import CityDropdown from "../../../shared/city_dropdown";
import LogoArea from "icon-area-location.svg";
import { getIDforGTM } from "../../v2/state_components/gtm_methods"
import ProgressBar from "../../../shared/progress_bar";

class SignupHeaderV3 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="signup-header-v3">
          <div className="header-items">
            <img className="page-header-image" src={CityLogo} alt="Offline" />
            <ProgressBar step={this.props.currentScreen + 1} total_steps={this.props.total_screens + 1} />
            <CityDropdown
              options={this.props.markets}
              value={this.props.selected_market}
              onChange={this.props.updateStateValue}
              className={(this.props.select_enabled ? "enable-select " : "disable-select ") + "sign-up"}
              icon={LogoArea}
              id={getIDforGTM("choose-city", this.props)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default SignupHeaderV3