import React from "react";
import OfferCardsIcon from 'icon-offer-cards.svg'
import PresentIcon from 'icon-offer-present.svg'

class OfferIntertitials extends React.Component {
  constructor(props) {
    super(props);
  }

  numberToOrdinal(number) {
    return ['Zeroth', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth', 'Tenth', 'Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth', 'Twentieth', 'Twenty-first', 'Twenty-second', 'Twenty-third', 'Twenty-fourth', 'Twenty-fifth', 'Twenty-sixth', 'Twenty-seventh', 'Twenty-eighth', 'Twenty-ninth', 'Thirtieth', 'Thirty-first'][number]
  }

  selfParams() {
    return {
      banner: (<div className="self-card-container"><img className="self-card-banner" src={PresentIcon} alt="" /></div>),
      title: `Choose Your ${this.numberToOrdinal(this.props.offer_slot_number)} Offer`,
      description: (
        <div className="interstitial-description">
          <span className="emp-highlight">You're picking for yourself. </span>
          You'll have until the end of the month to redeem your Offer. Your options will be based on your location + category preferences.
          <span className="emp-highlight after-padding">Scroll down to get started</span>
        </div>
      ),
    }
  }

  render() {
    let params = this.selfParams();
    return (
      <div className="interstitial-container">
        {params.banner}
        <div className="int-title-container">
          <div className="offers-title interstitial">{params.title}</div>
        </div>
        {params.description}
        <img className="faux-card-icon" src={OfferCardsIcon} />
      </div>
    );
  }
}

export default OfferIntertitials