import React from "react";
import IconSparkle from "icon-sparkle.svg";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'

class SubscriptionCard extends React.Component {
  constructor(props) {
    super(props);

    this.choose_price = this.choose_price.bind(this);
    this.choose_discount = this.choose_discount.bind(this);
  }

  choose_price() {
    if (this.props.is_annual && this.props.special_price) {
      return this.props.special_price;
    } else {
      return this.props.price;
    }
  }

  choose_discount() {
    if (this.props.is_annual && this.props.special_discount) {
      return this.props.special_discount;
    } else {
      return this.props.discount;
    }
  }

  render() {
    let card_class = this.props.is_annual ? " yearly" : " monthly";

    return (
      <div>
        <div class="plan-title">
          {this.props.title}
        </div>
        <div className={"plan-card" + card_class}>
          <ReactMarkdown
            className={"savings-callout" + (this.props.discount ? " show-discount" : "")}
            remarkPlugins={[remarkGfm]}
            children={this.choose_discount()}
          />
          <div className="plan-header">
            <img src={IconSparkle} alt="" />
            <div>{this.props.name}</div>
          </div>
          <div className="plan-right-col">
            <ReactMarkdown
              className={"plan-price has-discount"}
              remarkPlugins={[remarkGfm]}
              children={this.choose_price()}
            />
            <div className="plan-discount">
              {this.props.sizzle}
            </div>
          </div>
          {/* <div className="plan-impact">
          {this.props.is_annual &&
            <img src={IconCheckSubscription}/>
          }
          <div className="impact-text">
            {this.props.impact}
          </div>
        </div>
        <div className="plan-footer">{this.props.sell}</div> */}
        </div>
      </div>
    )
  }
}

export default SubscriptionCard;