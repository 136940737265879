import React from "react";

class AccountInvitations extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    return (
      <>
        <h2 onClick={() => {
          let collapse_action = this.props.collapse_action;
          collapse_action.action(collapse_action.args);
        }}>
          Invitations Received:{" "}
          <span
            className={caret_style}
            data-section-id="invitation-section"
          />
        </h2>
        <div id="invitation-section" style={collapsed_style}>
          {this.props.invitations.map((invitation, index) => {
            return (
              <div key={"inv" + invitation.id}>
                <a target="_blank" href={"/accounts/" + invitation.invited_by.id + "/edit"}>
                  {invitation.invited_by.first_name + " " + invitation.invited_by.last_name}
                </a>
                {"invited you to "  + invitation.offer.name + ` (${invitation.status})`}
              </div>
            )
          })}
        </div>
      </>
    );
  }
}

export default AccountInvitations;