import React from "react";
import BasicScreenLayout from "./screen_formats/basic_screen_layout";
import InfoScreenLayout from "./screen_formats/info_screen_layout";

import SignupHeader from "./shared/signup_header";
import SignupFooter from "./shared/signup_footer";
import ProgressBar from "./shared/progress_bar";
import NotGoodFit from "./shared/not_good_fit";
import CityDropdown from "../shared/city_dropdown";
import ContinueButton from "./shared/continue_button";

import SignupV5Gist from "./v5/signup_v5_gist";
import SignupV4ProfileName from "./v4/signup_v4_profile_name";
import SignupV4PhoneNumber from "./v4/signup_v4_phone_number";
import SignupV4MemberQuestions from "./v4/signup_v4_member_questions";
import SignupV5Subscription from "./v5/signup_v5_subscription";
import SignupV5SubscriptionStartSelect from "./v5/signup_v5_subscription_start_select";
import SignupV4Payment from "./v4/signup_v4_payment";

import ProgressSteps from "./shared/progress_steps.jsx";
import {trackPaymentSubmit} from "./utils/analytic_tracking.jsx";
import { getIDforGTM } from "./v2/state_components/gtm_methods";

import LogoArea from "icon-area-location.svg";
import CityLogo from "logo-city.svg";
import FlagLogo from "logo-flag.svg";
import BackIcon from "icon-back-arrow.svg"
import IconGift from "icon-gift.svg"
import IconCalendar from "icon-calendar.svg"
import IconForkAndSpoon from "icon-fork-and-spoon.svg"
import IconSparkle from "icon-sparkle.svg"
import IconUsers from "icon-users.svg"

import IconBullet1 from "icon-bullet-01.svg"
import IconBullet2 from "icon-bullet-02.svg"
import IconBullet3 from "icon-bullet-03.svg"
import IconBullet4 from "icon-bullet-04.svg"

export const getScreen = (screen_code, context = this) => {

  let header = (
    <SignupHeader
      mobile_left={<img className="page-header-image" src={CityLogo} alt="Offline" />}
      mobile_center={<ProgressBar key={'mp'} step={context.state.currentScreen + 1} total_steps={context.screen_order.length + 1} />}
      mobile_right={<CityDropdown options={context.state.markets} value={context.state.selected_area.id} className="disable-select sign-up" icon={LogoArea}/>}
      desktop_left={<img className="page-header-image" src={CityLogo} alt="Offline" />}
      desktop_center={<ProgressBar key={'dp'} step={context.state.currentScreen + 1} total_steps={context.screen_order.length + 1} />}
      desktop_right={<CityDropdown options={context.state.markets} value={context.state.selected_area.id} className="disable-select sign-up" icon={LogoArea}/>}
    />
  );

  let clear_header = (
    <SignupHeader
      mobile_left={<img className="page-header-image" src={CityLogo} alt="Offline" />}
      mobile_center={<ProgressBar key={'mp'} step={context.state.currentScreen + 1} total_steps={context.screen_order.length + 1} />}
      mobile_right={<CityDropdown options={context.state.markets} value={context.state.selected_area.id} className="disable-select sign-up" icon={LogoArea}/>}
      desktop_left={<img className="page-header-image" src={CityLogo} alt="Offline" />}
      desktop_center={<ProgressBar key={'dp'} step={context.state.currentScreen + 1} total_steps={context.screen_order.length + 1} />}
      desktop_right={<CityDropdown options={context.state.markets} value={context.state.selected_area.id} className="disable-select sign-up" icon={LogoArea}/>}
      apply_class="clear-header"
    />
  );

  let intro_header = (
    <SignupHeader
      mobile_left={<img className="page-header-image" src={BackIcon} alt="Offline" onClick={context.previousStep} />}
      desktop_left={<img className="page-header-image" src={BackIcon} alt="Offline" onClick={context.previousStep} />}
      mobile_center={<img className="page-header-image" src={FlagLogo} alt="Offline" />}
      desktop_center={<img className="page-header-image" src={FlagLogo} alt="Offline" />}/>
  )

  let intro_footer = (
    <SignupFooter
      sticky={true}
      center={
        <ContinueButton
          onPress={context.progressStep}
          gtm_id={getIDforGTM('continue-to-next-screen', context.props)}
        />
      } />
  )

  let signup_version = "5.0"
  console.log(screen_code);

  switch (screen_code) {
    case "SliderTrialGist":
      return (
        <InfoScreenLayout
          key={screen_code}
          header={
            <SignupHeader
              mobile_center={<img className="page-header-image" src={FlagLogo} alt="Offline" />}
              desktop_center={<img className="page-header-image" src={FlagLogo} alt="Offline" />}/>
          }
          footer={intro_footer}
          sticky_footer_offset="124px"
          banner={
            <div><img src={IconGift} />what your trial includes</div>
          }
          title={
            <div>You'll get to choose 2 of our curated restaurants to try <span className='underlined'>for free</span> with up to $30 towards the tab at each spot.</div>
          }
          analytic_tracking={"Signup: Trial Gist"}>
            <div>We scour the city to discover Nashville's best restaurants (we've got a high bar) + add them to our ever-growing roster. </div>
            <ProgressSteps
              step={1}
              total_steps={3}/>
        </InfoScreenLayout>
      )
    case "SliderTrialStart":
      return (
        <InfoScreenLayout
          key={screen_code}
          header={intro_header}
          footer={intro_footer}
          sticky_footer_offset="124px"
          banner={
            <div><img src={IconForkAndSpoon} />how your trial works</div>
          }
          title="Your trial is totally free and lasts for one calendar month."
          analytic_tracking={"Signup: Trial Start"}>
            <SignupV5SubscriptionStartSelect
              when_to_start={context.state.when_to_start}
              selected_area={context.state.selected_area}
              referral_details={context.state.referral_details}
              onSubmit={context.updateStateAndProgress}
              onBack={context.previousStep}
              signup_version={signup_version}
              gtm_screen_name="membership-information"/>
            <ProgressSteps
              step={2}
              total_steps={3}/>
        </InfoScreenLayout>
      )
    case "SliderBringFriend":
      return (
        <InfoScreenLayout
          key={screen_code}
          header={intro_header}
          footer={intro_footer}
          sticky_footer_offset="124px"
          banner={
            <div><img src={IconUsers} />can I bring a friend?</div>
          }
          title={
            <>
              <div>Offline is designed to be used with others.</div>
              <div className='spacer-large'>Anyone can tag along while you explore your city, whether they have a Membership or not.</div>
            </>
          }
          analytic_tracking={"Signup: Trial Bring Friend"}>
            <div className='spacer'>More burning questions? <a href='https://support.letsgetoffline.com' target='_blank'>We've got ya covered here</a>.</div>
            <ProgressSteps
              step={3}
              total_steps={3}/>
        </InfoScreenLayout>
      )
    case "SliderOfflineGist":
      return (
        <InfoScreenLayout
          key={screen_code}
          header={
            <SignupHeader
              mobile_center={<img className="page-header-image" src={FlagLogo} alt="Offline" />}
              desktop_center={<img className="page-header-image" src={FlagLogo} alt="Offline" />}/>
          }
          footer={intro_footer}
          sticky_footer_offset="124px"
          banner={
            <div><img src={IconSparkle} />why does offline exist?</div>
          }
          title={
            <>
              <div>Everyone has a list of restaurants they want to try.</div>
              <div className='spacer-large'>But 99% of us end up going to the same few over and over (and over). </div>
              <div className='spacer-large'>We built Offline to fix that.</div>
            </>
          }
          analytic_tracking={"Signup: Offline Gist"}>
            <div>Why'd we call it Offline? 'Cuz we're all <a href='https://davidmshaner.medium.com/why-i-founded-offline-fd7c105bbf6d' target='_blank'>about human connection</a>.</div>
            <ProgressSteps
              step={1}
              total_steps={4}/>
        </InfoScreenLayout>
      )
    case "SliderHowItWorks":
      return (
        <InfoScreenLayout
          key={screen_code}
          header={intro_header}
          footer={intro_footer}
          sticky_footer_offset="124px"
          title= {
            <>
              <div className='title-bullet-list '><img src={IconBullet1}/><div>Each month, choose two local restaurants.</div></div>
              <div className='title-bullet-list spacer-large'><img src={IconBullet2}/><div>Get $10-$25 to spend at each spot.</div></div>
              <div className='title-bullet-list spacer-large'><img src={IconBullet3}/><div>The catch? You have that month to visit or your credits expire.</div></div>
              <div className='title-bullet-list spacer-large'><img src={IconBullet4}/><div>Repeat next month.</div></div>
            </>
          }
          banner={
            <div><img src={IconCalendar} />How it works</div>
          }
          analytic_tracking={"Signup: Offline How It Works"}>
            <ProgressSteps
              step={2}
              total_steps={4}/>
        </InfoScreenLayout>
      )
    case "SliderBenefits":
      return (
        <InfoScreenLayout
          key={screen_code}
          header={intro_header}
          footer={intro_footer}
          sticky_footer_offset="124px"
          banner={
            <div><img src={IconGift} />What is the benefit of using offline?</div>
          }
          title={
            <div>
              Offline <span className='underline'>pays for itself</span> every single month and <span className='underline'>pushes you</span> to try amazing new places you never would have heard of.
            </div>
          }
          analytic_tracking={"Signup: Offline Benefits"}>
            <div>Our membership is $15/month, but you're getting $15-$30 off the tab at two different spots. We're no math experts, but basically, we're giving you money to try new things.</div>
            <ProgressSteps
              step={3}
              total_steps={4}/>
        </InfoScreenLayout>
      )
    case "SliderCommit":
      return (
        <InfoScreenLayout
          key={screen_code}
          header={intro_header}
          footer={intro_footer}
          sticky_footer_offset="124px"
          banner={
            <div><img src={IconUsers} />what if i'm not ready to commit?</div>
          }
          title={
            <>
              <div>Try a month for free.</div>
              <div className='spacer-large'>You'll choose 2 of our curated restaurants to try with up to $30 off the tab at each spot.</div>
            </>
          }
          analytic_tracking={"Signup: Offline Commit"}>
            <div className='nup-v5-subscription' style={{marginBottom: '10px'}}>
              <div className="when-to-start-input">
                <div className='sign-up-list-container'>When do you want to start your trial?</div>
              </div>
            </div>
            <SignupV5SubscriptionStartSelect
              when_to_start={context.state.when_to_start}
              selected_area={context.state.selected_area}
              referral_details={context.state.referral_details}
              onSubmit={context.updateStateAndProgress}
              onBack={context.previousStep}
              signup_version={signup_version}
              gtm_screen_name="membership-information"/>
            <ProgressSteps
              step={4}
              total_steps={4}/>
        </InfoScreenLayout>
      )
    case "SignupV5Gist":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={
            <SignupHeader
              mobile_left={<img className="page-header-image" src={CityLogo} alt="Offline" />}
              desktop_left={<img className="page-header-image" src={CityLogo} alt="Offline" />}
              apply_class="clear-header"
            />
          }
          banner={
            <div><img src={IconUsers} />What does an Offline membership include?</div>
          }
          title="Free for one month"
          analytic_tracking="Signup: Recap + Email">
          <SignupV5Gist
            onSubmit={context.updateStateAndProgress}
            onBack={context.previousStep}
            email={context.state.email}
            signup_version={signup_version}
            selected_area={context.state.selected_area}
            updateArea={context.updateArea}
            gtm_screen_name="membership-information"
            markets={context.state.markets}
            />
        </BasicScreenLayout>
      );
    
    case "SignupV4ProfileName":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={clear_header}
          title="What's your name?"
          analytic_tracking="Signup: Name">
          <SignupV4ProfileName
            onSubmit={context.updateStateAndProgress}
            onBack={context.previousStep}
            first_name={context.state.first_name}
            last_name={context.state.last_name}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );
    
    case "SignupV4PhoneNumber":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={clear_header}
          title="Your digits, plz."
          subtitle="You'll use this to log into the Offline app."
          analytic_tracking="Signup: Phone">
          <SignupV4PhoneNumber
            onSubmit={context.updateStateAndProgress}
            onBack={context.previousStep}
            phone_number={context.state.phone_number}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );
    
    case "SignupV4MemberQuestions":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={clear_header}
          title="Lastly, real quick..."
          subtitle="Before you pick your restaurants tell us about yourself."
          analytic_tracking="Signup: Questions"
          customerio_tracking={context.createCioProgressCallback(1)}>
          <SignupV4MemberQuestions
            onSubmit={context.updateStateAndProgress}
            cioStep={1}
            onBack={context.previousStep}
            reason_to_become_member={context.state.reason_to_become_member}
            supporting_local={context.state.supporting_local}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );
    
    case "SignupV4Subscription":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={clear_header}
          title="Choose your plan"
          analytic_tracking="Signup: Subscription"
          customerio_tracking={context.createCioProgressCallback(2)}>
          <SignupV5Subscription
            selected_area={context.state.selected_area}
            plan_id={context.state.plan_id}
            referral_details={context.state.referral_details}
            onSubmit={context.updateStateAndProgress}
            onBack={context.previousStep}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );
    
    case "SignupV4Payment":
      let onPaymentSubmit = function() {
        trackPaymentSubmit(context);
      }
      return (
        <BasicScreenLayout
          key={screen_code}
          header={clear_header}
          title="What is your payment info for future reference"
          subtitle="Don't worry. We won't charge your card on file during your trial. You can cancel your trial anytime."
          analytic_tracking="Signup: Payment">
          <SignupV4Payment
            referral_details={context.state.referral_details}
            valid_referral={context.state.valid_referral}
            selected_area={context.state.selected_area.id}
            stripe_key={context.props.stripe_key}
            setup_intent={context.state.setup_intent}
            confirmation_path={context.props.confirmation_path}
            onSubmit={context.updateStateAndProgress}
            onBack={context.previousStep}
            trackPaymentSubmit={onPaymentSubmit}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );

    case "SignupV4BadFit":
      return (
        <BasicScreenLayout
          key={screen_code}
          header={
            <SignupHeader
              mobile_center={<img className="page-header-image" src={CityLogo} alt="Offline" />}
              desktop_center={<img className="page-header-image" src={CityLogo} alt="Offline" />}
            />
          }
          analytic_tracking="Signup: Not Good Fit">
          <NotGoodFit
            selected_area={context.state.selected_area}
            reason_to_become_member={context.state.reason_to_become_member}
            supporting_local={context.state.supporting_local}
            signup_version={signup_version}
            gtm_screen_name="membership-information"/>
        </BasicScreenLayout>
      );

    default:
      return (<></>);

  }
}