import React from "react";
import SubscriptionBenefits from "./subscription_benefits";
import SubscriptionUpdateSuccess from "./subscription_update_success";
import SubscriptionModal from "./subscription_modal";

class SubscriptionUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_ineligible: this.props.is_ineligible,
      is_annual: this.props.is_annual,
      confirmation_pending: false,
      subscription_data: null,
      is_loading: false,
      show_error: this.props.is_ineligible && !this.props.is_annual,
      show_confirmation: false,
      error_title: null,
      error_message: null,
      error_code: null,
    }

    if (this.props.account_status == 'canceled') {
      this.state.is_ineligible = true;
      this.state.show_error = true;
      this.state.error_title = this.props.canceled_account_error.title;
      this.state.error_message = this.props.canceled_account_error.message;
     }

    if (!this.props.has_subscription) {
      this.state.is_ineligible = true;
      this.state.show_error = true;
      this.state.error_title = this.props.no_subscription_error.title;
      this.state.error_message = this.props.no_subscription_error.message;
    }

    this.state['can_load_data'] = this.can_load_data();

    this.network_busy = false;

    this.can_load_data = this.can_load_data.bind(this);
    this.initial_load = this.initial_load.bind(this);
    this.get_subscription_data = this.get_subscription_data.bind(this);
    this.go_back = this.go_back.bind(this);
    this.mixpanel_update = this.mixpanel_update.bind(this);
    this.continue_action = this.continue_action.bind(this);
    this.confirm_update = this.confirm_update.bind(this);
    this.clear_error = this.clear_error.bind(this);
    this.component_select = this.component_select.bind(this);
    this.render_error_modal = this.render_error_modal.bind(this);
    this.update_card_redirect = this.update_card_redirect.bind(this);
  }

  componentDidMount() {
    // this.initial_load();
  }

  can_load_data() {
    return !this.state.is_ineligible && this.props.account_status != 'canceled' && this.props.is_annual == false;
  }

  mixpanel_update(action) {
    $.ajax({
      method: "POST",
      url: `/subscriptions/${this.props.token}/mp?event=${action}`,
      dataType: 'json',
      success: (data) => { },
      error: (error) => { },
    });
  };

  initial_load() {
    if (this.state.can_load_data) {
      // Get subscription data
      this.setState({ is_loading: true });
      this.get_subscription_data((data) => {
        this.network_busy = false;
        this.setState({
          subscription_data: data.subscription_data,
          is_loading: false,
        });
      });
    }
  }

  get_subscription_data = (onSuccess, onError = null) => {
    if (this.network_busy) return false;
    this.network_busy = true;
    if (!onError) {
      onError = () => { this.network_busy = false; };
    }

    $.ajax({
      method: "GET",
      url: `/subscriptions/${this.props.token}/load`,
      dataType: 'json',
      success: (data) => { onSuccess(data) },
      error: onError
    });
  };

  post_update = (onSuccess, onError = null) => {
    if (this.network_busy) return false;
    this.network_busy = true;
    if (!onError) {
      onError = () => { this.network_busy = false; };
    }

    $.ajax({
      method: "POST",
      url: `/subscriptions/${this.props.token}/upgrade`,
      dataType: 'json',
      success: (data) => { onSuccess(data) },
      error: onError
    });
  };

  go_back() {
    this.mixpanel_update('annual_upgrade_go_back_tapped');
    this.setState({ show_confirmation: false })
  }

  continue_action() {
    this.mixpanel_update('annual_upgrade_continue');
    this.setState({ show_confirmation: true });
  }

  confirm_update() {
    this.setState({ confirmation_pending: true });
    this.post_update(
      (data) => {
        this.network_busy = false;
        this.setState({
          is_annual: true,
          confirmation_pending: false,
          show_confirmation: false,
        });
      },
      (error) => {
        this.network_busy = false;
        this.setState({
          confirmation_pending: false,
          show_confirmation: false,
          show_error: true,
          error_title: error?.responseJSON?.error_title || null,
          error_message: error?.responseJSON?.error_message || null,
          error_code: error?.responseJSON?.error_code || null,
        });
      }
    );
  }

  clear_error() {
    if (this.state.is_ineligible) {
      return false;
    }
    this.setState({ 
      error_title: null,
      error_message: null,
      show_error: false
    })
  }

  update_card_redirect() {
    window.location.href = this.props.update_card_url;
  }

  component_select() {
    if (this.state.is_annual) {
      return (
        <SubscriptionUpdateSuccess
          {...this.props.success_page}
        />
      )
    }

    return (
      <SubscriptionBenefits
        monthly_card={this.props.monthly_card}
        benefits_page_subtitle={this.props.benefits_page_subtitle}
        yearly_card={this.props.yearly_card}
        benefits_page_title={this.props.benefits_page_title}
        benefits_page_button={this.props.benefits_page_button}
        details_section={this.props.details_section}
        continue_action={this.continue_action}
      />
    )
  }

  render_error_modal() {
    if (!this.state.show_error) return (<></>);

    if (this.state.error_code == 3) {
      return (
        <SubscriptionModal
          {...this.props.error_modal}
          title={this.state.error_title || this.props.error_modal.title}
          message={this.state.error_message || this.props.error_modal.message}
          confirm_action={this.update_card_redirect}
          confirm_button={"Update Card"}
        />
      )
    }

    return (
      <SubscriptionModal
        {...this.props.error_modal}
        title={this.state.error_title || this.props.error_modal.title}
        message={this.state.error_message || this.props.error_modal.message}
        confirm_action={this.clear_error}
      />
    )
  }

  render() {
    return (
      <div className="basic-layout__body">
        <div className="basic-layout__grow_content">
          {this.state.show_confirmation &&
            <SubscriptionModal
              {...this.props.confirmation_modal}
              processing={this.state.confirmation_pending}
              show_spinner={this.state.confirmation_pending}
              confirm_action={this.confirm_update}
              cancel_action={this.go_back}
            />
          }
          {this.render_error_modal()}
          {this.component_select()}
        </div>
      </div>
    )
  }

}

export default SubscriptionUpdate;