import React from "react";

class FormBuilder extends React.Component {
    constructor(props) {
        super(props);
    }

    createForm(object = this.props.formObject, form_data = null, parentAddress = "", top = "") {
        form_data = form_data || new FormData();
        for (let key in object) {
            let chart_key = Array.isArray(object) || !top ? "" : key;
            
            if (Object.prototype.toString.call(object[key]) === '[object Date]') {
                form_data.append(
                    `${top? top: key}[${parentAddress}${parentAddress ? `[${key}]` : key}]`,
                    this.convertDateToUTC(object[key]));
            } else if (Object.prototype.toString.call(object[key]) === '[object File]'){
                form_data.append(`${top? top: key}[${parentAddress}${parentAddress ? `[${key}]` : key}]`,
                    object[key] == undefined || object[key] == null? "": object[key]);
            } else if (Array.isArray(object[key])) {
                if (this.doesArrayOnlyContainValues(object[key])) {
                    if (object[key].length == 0) {
                        form_data.append(`${top}[${key}]`, []);
                    } else {
                        object[key].forEach(value => {
                            form_data.append(`${top}[${key}][]`, value);
                        })
                    }
                } else {
                    this.createForm(object[key], form_data, `${parentAddress + chart_key}[]`, top? top: key);
                }
            } else if (object[key] && typeof object[key] === 'object') {
                this.createForm(object[key], form_data, parentAddress + chart_key, top? top: key);
            } else {
                form_data.append(`${top? top: key}[${parentAddress}${parentAddress ? `[${key}]` : key}]`,
                    object[key] == undefined || object[key] == null? "": object[key]);
            }
        }
        return form_data;
    }

    doesArrayOnlyContainValues(array){
        for(let value of array){
            if(value && typeof value === 'object'){
                return false;
            }
        }
        return true;
    }

    convertDateToUTC(date) {
        if (date == null) {
          return null;
        }

        //Check if date is already UTC
        if ((date.getHours() + new Date().getTimezoneOffset() / 60) == 24) {
            return new Date(`${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')
                }-${String(date.getUTCDate()).padStart(2, '0')
                }T00:00:00Z`);
        }

        return new Date(`${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')
            }-${String(date.getDate()).padStart(2, '0')
            }T00:00:00Z`);
      }

    render() {
        return (
            <></>
        )
    }
}

export default FormBuilder;