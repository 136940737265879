import React from "react";

import PhoneInput from "../shared/account_inputs/phone_input";
import ContinueButton from "../shared/continue_button";
import BackButton from "../shared/back_button";
import { getIDforGTM } from "../v2/state_components/gtm_methods";
import { validatePhoneNumber } from "../utils/account_validations";

class SignupV4PhoneNumber extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phone_number: this.props.phone_number || '',
    }
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value || "",
      ["error_"+event.target.name]: ''
    });
  }

  render() {
    let phone_subtext = "By providing your phone, you agree it may be used to send you text messages about account updates. Standard message + data rates may apply. Contact us or reply STOP to the text to opt out. "
    return (
      <div className="signup-v4-phone-number">
        <PhoneInput
          phone_number={this.state.phone_number}
          error_phone_number={this.state.error_phone_number}
          subtext={phone_subtext}
          changeInputField={this.onInputChange} />

        <ContinueButton
          disabled={(this.state.phone_number.length == 0)}
          onPress={validatePhoneNumber(this, this.props.onSubmit)}
          gtm_id={getIDforGTM('continue-to-next-screen', this.props)} />

        <BackButton
          onPress={this.props.onBack}
          gtm_id={getIDforGTM('back-to-previous-screen', this.props)}
        />
      </div>
    );
  }
}

export default SignupV4PhoneNumber