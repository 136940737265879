import React from "react";
import IconError from "icon-error.svg"
import IconUhOh from "icon-uh-oh.svg"
import IconTooltip from "icon-tooltip.svg"
import IconCheckboxSelected from "icon-checkbox-selected.svg"
import IconCheckboxUnselected from "icon-checkbox-unselected.svg"

class NoOffersAvailable extends React.Component {
  constructor(props) {
    super(props);

    this.changeInputField = this.changeInputField.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  changeInputField(event) {
    this.props.on_take_credit(this.props.slot_id, event.target.checked);
  }

  toggle(event) {
    if (event.target.tagName == "INPUT" || event.target.tagName == "IMG" || event.target.tagName == "LABEL") return;
    
    this.props.on_take_credit(this.props.slot_id, !this.props.take_credits_for_month);
  }

  render() {
    return (
      <div className="no-offers-container">
        <div className="offer-subtitle">{`Offer Choice ${this.props.index} of ${this.props.slot_total}`}</div>
        <img src={IconUhOh} alt=":(" className="no-offers-icon" />
        <div className="no-offers-title">UH-OH</div>
        <div className="no-offers-body">{"We weren't able to match you with any restaurants based on your "+ this.props.slot_name +" preferences."}</div>
        <div className="no-offers-link">{"Update Preferences by\r\nvisiting your Membership tab"}</div>
        <div className="take-credit-container" onClick={this.toggle}>
          <div className="credit-input">
            <input
              name="take_credits_for_month"
              id="cb-take-credits"
              type="checkbox"
              value="Take Credit for This Offer"
              checked={this.props.take_credits_for_month || false}
              onChange={this.changeInputField} />
            <label htmlFor="cb-take-credits" className="cb-take-credits">
              <img src={(this.props.take_credits_for_month || false) ? IconCheckboxSelected : IconCheckboxUnselected} alt="" />
            </label>
            <span>Take Credit for This Offer</span>
          </div>
          <img src={IconTooltip} alt="" />
        </div>
        <div className={"error-text" + (this.props.error_message ? "" : " show-blank")}>
          <img src={IconError} />
          <div className="error-body">{'To continue, update your preferences or select "take credits for this month."'}</div>
        </div>
      </div>
    );
  }
}

export default NoOffersAvailable