import React from "react";
import BannerImg from 'free-trial-banner-background.svg'

class ReviewSection extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className="review-section">
        <div className="star-rating">4.7</div>
        <div className="review-sizzle">{`Join over 5,000+ members in ${this.props.area_name}`}</div>
      </div>
    )
  }
}

export default ReviewSection