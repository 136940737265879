import React from "react";
import SubscriptionCard from "./subscription_card";
import ConfirmationButton from "../../offer_selection/confirmation_button";
import BackArrow from 'back-arrow.svg'

class SubscriptionBenefits extends React.Component {
  constructor(props) {
    super(props);

    this.confirmation_button_ref = React.createRef();
  }

  render() {
    return (
      <>
        <div className="basic-layout__dynamic_height-col">
          {/* <a className='back-btn'><img src={BackArrow} /></a> */}
          <div className="benefits-title">
            {this.props.benefits_page_title}
          </div>
          <div className="benefits-subtitle">
            Exclusive benefits for a lower price.
          </div>
          <div className="benefits-cards">
            <SubscriptionCard
              {...this.props.monthly_card}
            />
            <SubscriptionCard
              is_annual={true}
              {...this.props.yearly_card}
            />
          </div>
          <div className="benefit-details">
            <div className="details-header">
              {this.props.details_section.title}
            </div>
            {this.props.details_section.details.map((detail, i) =>
              (<div key={'di' + i} className="detail-item">{detail}</div>)
            )}
          </div>
        </div>
        <div className="basic-layout__footer">
          <div className="benefit-footer-title">
            {this.props.benefits_page_subtitle}
          </div>
          <ConfirmationButton
            on_click_action={this.props.continue_action}
            button_label={this.props.benefits_page_button}
            is_active={true}
            error_message={null}
            child_ref={this.confirmation_button_ref}
            wrapper_class={"no-margin"}
          />
          <div className="benefit-footer-button-desc">
            Selecting confirm will finalize your upgrade
          </div>
        </div>
      </>
    )
  }
}

export default SubscriptionBenefits;