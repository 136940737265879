import React from "react";
import moment from "moment-timezone";
import FormMarkdownInput from "./form_markdown_input";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'

class FinePrintDays extends React.Component {
  constructor(props) {
    super(props);

    let day_statuses = props.fine_print_days || null;

    if (!day_statuses) {
      day_statuses = {};
      for (let index = 0; index < 7; index++) {
        let day = this.getNameOfDay(index);
        day_statuses[day.toLowerCase()] = {
          day: this.getNameOfDay(index),
          availability: "Valid",
          message: ""
        }
      }
    }

    this.state = JSON.parse(JSON.stringify(day_statuses));
    this.state.current_day_message = null;
    this.state.current_day = null;

    this.saveCurrentEdit = this.saveCurrentEdit.bind(this);
    this.updateEditMessage = this.updateEditMessage.bind(this);
    this.getListOfDays = this.getListOfDays.bind(this);
    this.getNameOfDay = this.getNameOfDay.bind(this);
  }

  getNameOfDay(index) {
    return moment().weekday(index + 1).format('dddd');
  }

  getListOfDays() {
    return [...Array(7).keys()].map(i => this.getNameOfDay(i).toLowerCase());
  }

  saveCurrentEdit() {
    if (!this.state.current_day) return;

    let update_day = this.state[this.state.current_day];
    update_day.message = this.state.current_day_message.trim() || "";

    this.setState({ [this.state.current_day]: update_day, current_day: null, current_day_message: null });
    if (this.props.onChange) {
      let days = this.getListOfDays();
      let local = {};
      days.forEach(day => {
        local[day] = this.state[day];
      });
      local[this.state.current_day] = update_day;

      this.props.onChange(local);
    }
  }

  updateEditMessage(day, message) {
    this.setState({ current_day_message: message });
  }

  onChange = (event) => {
    let newValue = event.target.value;
    let days = this.getListOfDays();
    let day_update = this.state[event.target.name];

    day_update.availability = newValue;

    this.setState({ [event.target.name]: day_update })

    if (this.props.onChange) {

      let local = {};
      days.forEach(day => {
        local[day] = this.state[day];
      });

      local[event.target.name] = day_update;
      this.props.onChange(local);
    }
  }

  render() {

    let option_strings = [
      "Valid",
      "Not Valid",
      "Warning"
    ]

    const options = option_strings.map((option, i) => <option key={i} value={option}>{option}</option>);

    let days = this.getListOfDays();

    return (
      <div className="fine-print-day-container">
        {
          days.map((day, i) => {
            let day_data = this.state[day];
            let current_day = this.state.current_day;
            if(current_day && current_day != day) {
              return (<></>)
            }

            return (
              <div className="day-cell" key={i}>
                <span className={"cell-banner " + day_data.availability.toLowerCase().replace(' ', '-')}>{day_data.day}</span>
                <span className="cell-status">{day_data.availability}</span>
                {this.state.current_day == day &&
                  <>
                    <div className="editing-message">Editing Message for {day_data.day}</div>
                    <button className="fpd-btn" onClick={() => { this.setState({ current_day_message: null, current_day: null }) }}>Cancel</button>
                    <button className="fpd-btn" onClick={this.saveCurrentEdit}>Save</button>
                    <FormMarkdownInput
                      name={day}
                      data-type={"message"}
                      value={day_data.message}
                      onChange={this.updateEditMessage} />
                  </>
                }
                <ReactMarkdown
                  className="rendered-message"
                  remarkPlugins={[remarkGfm]}
                  children={day_data.message}
                />
                <button className="fpd-btn edit-message" onClick={() => { this.setState({ current_day_message: day_data.message, current_day: day }) }}>Edit Message</button>
                <select
                  name={day}
                  data-attr={"availability"}
                  className="select-availability"
                  value={day_data.availability}
                  onChange={(e) => { this.onChange(e) }}
                >
                  {options}
                </select>
              </div>
            )
          })
        }
        {/* <div className="day-cell">
          <span className={"cell-banner " + this.state.mon.toLowerCase().replace(' ', '-')}>Monday</span>
          <span className="cell-status">{this.state.mon}</span>
          <select
            name="mon"
            value={this.state.mon}
            onChange={(e) => { this.onChange(e) }}
          >
            {options}
          </select>
        </div>
        <div className="day-cell">
          <span className={"cell-banner " + this.state.tue.toLowerCase().replace(' ', '-')}>Tuesday</span>
          <span className="cell-status">{this.state.tue}</span>
          <select
            name="tue"
            value={this.state.tue}
            onChange={(e) => { this.onChange(e) }}
          >
            {options}
          </select>
        </div>
        <div className="day-cell">
          <span className={"cell-banner " + this.state.wed.toLowerCase().replace(' ', '-')}>Wednesday</span>
          <span className="cell-status">{this.state.wed}</span>
          <select
            name="wed"
            value={this.state.wed}
            onChange={(e) => { this.onChange(e) }}
          >
            {options}
          </select>
        </div>
        <div className="day-cell">
          <span className={"cell-banner " + this.state.thur.toLowerCase().replace(' ', '-')}>Thursday</span>
          <span className="cell-status">{this.state.thur}</span>
          <select
            name="thur"
            value={this.state.thur}
            onChange={(e) => { this.onChange(e) }}
          >
            {options}
          </select>
        </div>
        <div className="day-cell">
          <span className={"cell-banner " + this.state.fri.toLowerCase().replace(' ', '-')}>Friday</span>
          <span className="cell-status">{this.state.fri}</span>
          <select
            name="fri"
            value={this.state.fri}
            onChange={(e) => { this.onChange(e) }}
          >
            {options}
          </select>
        </div>
        <div className="day-cell">
          <span className={"cell-banner " + this.state.sat.toLowerCase().replace(' ', '-')}>Saturday</span>
          <span className="cell-status">{this.state.sat}</span>
          <select
            name="sat"
            value={this.state.sat}
            onChange={(e) => { this.onChange(e) }}
          >
            {options}
          </select>
        </div>
        <div className="day-cell">
          <span className={"cell-banner " + this.state.sun.toLowerCase().replace(' ', '-')}>Sun</span>
          <span className="cell-status">{this.state.sun}</span>
          <select
            name="sun"
            value={this.state.sun}
            onChange={(e) => { this.onChange(e) }}
          >
            {options}
          </select>
        </div> */}
      </div>
    )
  }
}

export default FinePrintDays;