import React from "react";

class SignupHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let base_class = "signup-header-content";
    let wrapping_class = "signup-header ";
    if (this.props.apply_class) wrapping_class += this.props.apply_class;
    let style_obj = {};
    if (this.props.constrain_middle) {
      style_obj["--middle-width"] = this.props.constrain_middle;
      base_class += " constrain-middle";
    }
    if (this.props.className) base_class += " " + this.props.className;

    return (
      <div className={wrapping_class}>
        <div className={base_class + " signup-mobile-styling"} style={style_obj}>
          {this.props.mobile_left   || (<div></div>)}
          {this.props.mobile_center || (<div></div>)}
          {this.props.mobile_right  || (<div></div>)}
        </div>
        <div className={base_class + " signup-desktop-styling"} style={style_obj}>
          {this.props.desktop_left   || (<div></div>)}
          {this.props.desktop_center || (<div></div>)}
          {this.props.desktop_right  || (<div></div>)}
        </div>
      </div>
    );
  }
}

export default SignupHeader