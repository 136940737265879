import React from "react";
import EventReportComment from "../admin_app/events/report/event_report_comment";
import EventReportOfflineComment from "../admin_app/events/report/event_report_offline_comment";
import TicketBreakdown from "../admin_app/events/report/ticket_breakdown";
import EventNavLinks from "../admin_app/events/event_nav_links";
import moment, { min } from 'moment-timezone';

import IconTotalCustomers from "icon-total-customers.svg";
import IconBroughtFriends from "icon-brought-friends.svg";
import IconOverallRating from "icon-overall-rating.svg";
import IconNewCustomers from "icon-new-customers.svg";
import IconWouldReturn from "icon-would-return.svg";
import IconMembersMatched from "icon-members-matched.svg";

import { convertIntegerToPrice } from '../helpers/price_formatter';
import { convertDateString } from '../helpers/datestring_formatter'
import EventAboveFourStars from "./event_above_four_stars";
import EventBelowThreeStars from "./event_below_three_stars";
import EventReportFormatter from "./event_report_formatter";
import SocialImpact from "../partner_report/social_impact";
import FormBuilder from "../admin_app/shared/form_builder";


class EventReportV2 extends React.Component {
  constructor(props) {
    super(props);

    let date_array = this.props.event_dates.map(ed => { return convertDateString(ed.date) });
    date_array.sort((a, b) => a - b);

    let sorted_dates = [...this.props.event_dates].sort((a, b) => convertDateString(a.date) - convertDateString(b.date));
    let all_times = sorted_dates.flatMap(ed => ed.event_times).sort((a, b) => a.minutes - b.minutes);
    let times_by_minutes = {};
    all_times.forEach(et => {
      if (!times_by_minutes[et.minutes]) {
        times_by_minutes[et.minutes] = [];
      }
      times_by_minutes[et.minutes].push(et);
    });

    this.time_rows = {};

    this.sorted_times = Object.keys(times_by_minutes).sort((a, b) => a - b);
    this.sorted_times.forEach(minutes => {
      let event_time = times_by_minutes[minutes];
      this.time_rows[minutes] = event_time.reduce((a, v) => ({ ...a, [v.event_date_id]: v}), {});
    });

    this.earliest_date = date_array[0];
    this.latest_date = date_array.at(-1);

    this.all_dates = date_array.map(ed => moment(ed).format("MMM Do, YYYY")).join(" + ");
    // Ticket Data
    let ticket_count = 0;
    let checkin_count = 0;
    let tickets_no_charge_count = props.viewing_as_admin ? 0 : null;
    let time_map = all_times.reduce((a, v) => ({ ...a, [v.id]: v}), {});
    let date_map = sorted_dates.reduce((a, v) => ({ ...a, [v.id]: v}), {});
    for (let assignment of this.props.event_assignments) {
      ticket_count += assignment.tickets;
      checkin_count += assignment.tickets_redeemed;
      this.incrementObjectValue(date_map[assignment.event_date_id], "calc_tickets", assignment.tickets);
      this.incrementObjectValue(time_map[assignment.event_time_id], "calc_tickets", assignment.tickets);
      this.incrementObjectValue(time_map[assignment.event_time_id], "calc_redeemed_tickets", assignment.tickets_redeemed);
      this.getEventTimeFromTimeslotString(assignment.timeslot, times_by_minutes)
      if (props.viewing_as_admin) {
        tickets_no_charge_count += assignment.tickets_no_charge;
      }
    }

    this.total_tickets = ticket_count;
    this.total_checkings = checkin_count;
    this.percent_attended = checkin_count / ticket_count * 100;
    this.total_no_charge_tickets = tickets_no_charge_count;
    this.total_paid_tickets = this.total_tickets - this.total_no_charge_tickets;

    // Price Data
    this.offline_split = this.props.offline_split || this.props.ticket_price;
    this.partner_split = this.props.partner_split || 0;
    this.offline_price = convertIntegerToPrice(this.offline_split);
    this.partner_price = convertIntegerToPrice(this.partner_split);
    this.offline_revenue = convertIntegerToPrice(this.total_paid_tickets * this.offline_split);
    this.partner_revenue = convertIntegerToPrice(this.total_paid_tickets * this.partner_split);
    this.total_revenue = convertIntegerToPrice(this.total_paid_tickets * this.props.ticket_price);

    // Review Data
    this.reviewed_assignments = [...this.props.event_assignments];
    this.reviewed_assignments = this.reviewed_assignments.filter(ea => ea.status == "reviewed")

    this.highly_reviewed_assignments = this.reviewed_assignments.filter(ea => ea.customer_rating >= 4);
    this.low_reviewed_assignments = this.reviewed_assignments.filter(ea => ea.customer_rating < 4 && ea.customer_rating > 0);

    let reviewed_count = 0;
    let reviewed_score = 0;
    for (let review of this.reviewed_assignments) {
      // 0 Implies they didn't give it a rating from 1-5 and skipped this part of the review
      if (review.customer_rating > 0) {
        reviewed_count++;
        reviewed_score += review.customer_rating;
      }
    }
    this.reviewed_average_rating = reviewed_count > 0 ? reviewed_score / reviewed_count : "NO DATA";

    this.state = {
      can_edit: props.viewing_as_admin,
      show_edit: false,
      social_links: props.social_post_block || "",
      admin_banner: props.viewing_as_admin,
      sorted_dates,
      date_map
    }

    // this.getTotalReviewed = this.getTotalReviewed.bind(this);
    this.renderDate = this.renderDate.bind(this);
    this.updateFields = this.updateFields.bind(this);
    this.selectEditButton = this.selectEditButton.bind(this);
    this.updateSocialLinks = this.updateSocialLinks.bind(this);
    this.renderTicketPriceBreakdown = this.renderTicketPriceBreakdown.bind(this);
    this.render_ticket_breakdown_row_cells = this.render_ticket_breakdown_row_cells.bind(this);
  }

  getEventTimeFromTimeslotString(timeslot_string, times_by_minutes) {
    let moment_date = moment(timeslot_string, "h:mm A");
    let start_of_day = moment_date.clone().startOf('day');
    if(!moment_date.isValid()){
      moment(timeslot_string, "DDD h:mm A");
    }
    let minutes = moment_date.diff(start_of_day, 'minutes');
    return times_by_minutes[minutes];
  }

  incrementObjectValue(object, key, increment=1) {
    if (!object) return;
    if(typeof object[key] == "undefined") {
      object[key] = increment || 0;
    } else {
      object[key] += increment || 0;
    }
  }

  getTotalReviewed() {
    return this.reviewed_assignments.length
  }

  getPercentReviewed() {
    if (this.props.event_assignments.length == 0) {
      return ""
    }
    return (this.reviewed_assignments.length / this.props.event_assignments.length * 100).toFixed(1);
  }

  minutesToTimeString(minutes) {
    if (!minutes) return "";
    let date = moment().startOf('day');
    date.minutes(minutes);
    return date.format('h:mm A');
  }

  getAverageRating() {
    if (typeof this.reviewed_average_rating === "string") {
      return this.reviewed_average_rating;
    }
    return this.reviewed_average_rating.toFixed(1);
  }

  getAverageSurveyRating(key) {
    let count = 0;
    let score = 0;
    for (let assignment of this.reviewed_assignments) {
      if (assignment.survey_answers[key] && assignment.survey_answers[key] != "") {
        count++;
        score += parseInt(assignment.survey_answers[key]);
      }
    }
    if (count == 0) {
      return ""
    }
    return (score / count).toFixed(1);
  }

  getPercentSurveyAnswer(key, new_key=null, source = this.reviewed_assignments) {
    let count = 0;
    let score = 0;
    for (let assignment of source) {
      if ((typeof assignment[new_key] === "boolean") || (assignment.survey_answers[key] && assignment.survey_answers[key] != "")) {
        count++;
        if (assignment[new_key] || assignment.survey_answers[key] == "yes")
          score++;
      }
    }
    if (count == 0) {
      return ""
    }
    return (score * 100 / count).toFixed(1);
  }

  renderTicketPriceBreakdown() {
    if (this.props.viewing_as_admin) {
      return (
        <p>
          Ticket Price: ${convertIntegerToPrice(this.props.ticket_price)}<br />
          Split (Offline/Partner): ${this.offline_price} / ${this.partner_price}<br />
          Offline Revenue: ${this.offline_revenue}<br />
          Partner Revenue: ${this.partner_revenue}<br />
          Total Revenue: ${this.total_revenue}<br />
        </p>
      )
    }
    return (<></>);
  }

  selectEditButton() {
    let show_edit = !this.state.show_edit;

    if (show_edit) {
      this.setState({ show_edit });
    } else {
      this.updateSocialLinks();
    }
  }

  renderPartnerComments() {
    return (this.reviewed_assignments.filter(e => e.customer_feedback).map((e) =>
      <EventReportComment
        key={e.id}
        rating={e.customer_rating}
        comment={e.customer_feedback} />
    ));
  }

  renderOfflineComments() {
    return (this.reviewed_assignments.map((e) =>
      <EventReportOfflineComment key={e.id} event_assignment={e} />
    ));
  }

  renderDate(date) {
    if (date) {
      const options = { weekday: 'long', month: 'long', day: 'numeric' }
      return date.toLocaleString('en-US', options);
    }
    return ""
  }

  updateFields(event) {
    const target = event.target;
    const name = target.name;

    this.setState({ [name]: target.value });
  }

  updateSocialLinks() {
    if (this.uploading) return false;
    var path = "/events/" + this.props.token + "/update_social_post_block?social_post_block=" + encodeURIComponent(this.state.social_links);

    this.uploading = true;

    $.ajax({
      method: "POST",
      url: path,
      data: {},
      processData: false,
      contentType: false,
      success: (data) => {
        this.uploading = false;
        this.setState({ show_edit: false, social_links: data.social_post_block });
      },
      error: (error) => {
        this.uploading = false;
      }
    });
  }

  renderHeader() {
    return (
      <>
        <div className="event-report-spacer">
          <div className="event-report-header">
            <div className="event-report-title-container">
              {this.props.header_image_url &&
                <div className="title-image-cropper">
                  <img src={this.props.header_image_url || ""} alt="" />
                </div>
              }
              <div className="event-report-title">
                <div className="title-label">{this.all_dates}</div>
                <div className="main-title">{this.props.name}</div>
              </div>
            </div>
            <div className="event-report-date">
              {
                // this.state.can_edit &&
                //   <div className={"edit-button-container" + (this.state.show_edit ? "" : " can-hide-edit")}>
                //     <button onClick={this.selectEditButton} className={"event-report-edit " + edit_button_classes}>{this.state.show_edit ? "Save Changes" : "Edit"}</button>
                //     <button onClick={this.hideEditButton} className={"event-report-edit hide-edit " + edit_button_classes + (this.state.show_edit ? "" : " show-hide-edit")}>✕</button>
                //   </div>
              }
            </div>
          </div>
        </div>
        <div className="event-report-spacer dummy-spacer">
          <div className="event-report-header">
            <div className="event-report-title-container">
              {this.props.header_image_url &&
                <div className="title-image-cropper">
                  <img src={this.props.header_image_url || ""} alt="" />
                </div>
              }
              <div className="event-report-title">
                <div className="title-label">{this.all_dates}</div>
                <div className="main-title">{this.props.name}</div>
              </div>
            </div>
            <div className="event-report-date">
              {
                // this.state.can_edit &&
                //   <div className={"edit-button-container" + (this.state.show_edit ? "" : " can-hide-edit")}>
                //     <button onClick={this.selectEditButton} className={"event-report-edit " + edit_button_classes}>{this.state.show_edit ? "Save Changes" : "Edit"}</button>
                //     <button onClick={this.hideEditButton} className={"event-report-edit hide-edit " + edit_button_classes + (this.state.show_edit ? "" : " show-hide-edit")}>✕</button>
                //   </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }

  header_report_date(event_date) {
    let date_string = moment(convertDateString(event_date.date)).format("MMM Do, YYYY");

    return (
      <>
        {date_string}
        <div className="sub-header">Checked-in / Sold / Max Tickets</div>
      </>
    )
  }

  render_ticket_breakdown() {
    if(this.props.event_type == "Lottery"){
      return (<></>);
    }

    return (
      <div className="event-report-general-breakdown">
        <div className="event-report-general-breakdown-title">{`Event Type: ${this.props.event_type}`}</div>
        <div className="event-report-top-table">
          <div className="event-report-top-table-header-row">
            <div className="event-report-top-table-header-cell"></div>
            {this.state.sorted_dates.map((ed, index) => (
              <div className="event-report-top-table-header-cell" key={`edh-${index}`}>
                {this.header_report_date(ed)}
              </div>
            ))}
          </div>
          {this.sorted_times.map((minutes, index) => (
            <div className="event-report-top-table-row" key={`edr-${index}`}>
              {this.render_ticket_breakdown_row_cells(minutes)}
            </div>
          ))}
        </div>
      </div>
    )
  }

  render_ticket_breakdown_row_cells(minutes) {
    return (
      <>
        <div className="event-report-top-table-header-cell time-bg">
          {this.minutesToTimeString(minutes)}
        </div>
        {this.state.sorted_dates.map((ed, index) => {
          let message = this.event_time_ticket_string(this.time_rows[minutes][ed.id]);
          return (
            <div data-tooltip="Checked-in / Sold / Max Tickets" className={"event-report-top-table-cell" + (message == "N/A" ? " empty-timeslot" : "")} key={`edc-${index}`}>
              {message}
            </div>
          )
        })}
      </>
    )
  }

  event_time_ticket_string(event_time) {
    if (!event_time) {
      return "N/A";
    }
    return `${event_time.calc_redeemed_tickets || 0} / ${event_time.calc_tickets || 0} / ${event_time.max_tickets}`;
  }

  render() {

  let first_time_customer = this.getPercentSurveyAnswer("first_time", "first_time_at_business");
    let would_go_again = this.getPercentSurveyAnswer("go_back", "will_go_again");
    let formatter = new EventReportFormatter();

    return (
      <div className={"event-report-page" + (this.state.admin_banner ? " show-admin-banner" : "")}>
        {this.props.viewing_as_admin &&
          <div className="admin-banner">
            <div className="admin-spacer">
              <div className="event-report-title">
                <div className="main-title">Admin</div>
                {this.renderTicketPriceBreakdown()}
                You will need to scroll down to edit social links.
                <button onClick={this.selectEditButton} className={"event-report-edit " + (this.state.show_edit ? "save-changes" : "")}>{this.state.show_edit ? "Save Changes" : "Edit Social Links"}</button>
              </div>
              <div className="event-report-nav-links">
                <EventNavLinks id={this.props.id} token={this.props.token} current='report' />
                {this.render_ticket_breakdown()}
              </div>
            </div>
          </div>
        }
        {this.renderHeader()}
        <div className="stat-cells">
          <div className="stat-cell">
            <img src={IconOverallRating} alt="" />
            <div className="stat-cell-title">Overall Rating</div>
            <div className={"stat-cell-value" + (this.getAverageRating() == "NO DATA" ? " no-data" : "")}>{this.getAverageRating()}</div>
          </div>

          <div className="stat-cell">
            <img src={IconMembersMatched} alt="" />
            <div className="stat-cell-title">{formatter.label_presenter("tickets_sold", this.total_tickets)}</div>
            <div className={"stat-cell-value"}>{formatter.data_presenter("tickets_sold", this.total_tickets)}</div>
          </div>

          <div className="stat-cell">
            <img src={IconTotalCustomers} alt="" />
            <div className="stat-cell-title">{formatter.label_presenter("checked_in", this.total_checkings)}</div>
            <div className={"stat-cell-value"}>{formatter.data_presenter("checked_in", this.total_checkings)}</div>
          </div>

          <div className="stat-cell bnof">
            <img src={IconNewCustomers} alt="" />
            <div className="stat-cell-title">{formatter.label_presenter("first_time_customer", first_time_customer)}</div>
            <div className={"stat-cell-value" + (first_time_customer == 0 ? " no-data" : "")}>{formatter.data_presenter("first_time_customer", first_time_customer)}</div>
          </div>

          <div className="stat-cell">
            <img src={IconWouldReturn} alt="" />
            <div className="stat-cell-title">{formatter.label_presenter("would_go_again", would_go_again)}</div>
            <div className={"stat-cell-value" + (formatter.data_presenter("would_go_again", would_go_again) == "NO DATA" ? " no-data" : "")}>{formatter.data_presenter("would_go_again", would_go_again)}</div>
          </div>

        </div>
        <EventAboveFourStars
          event_assignments={this.highly_reviewed_assignments}
        />
        <EventBelowThreeStars
          event_assignments={this.low_reviewed_assignments}
        />
        <SocialImpact
          social_links={this.state.social_links}
          updateFields={this.updateFields}
          edit_mode={this.state.show_edit}
          report_type="event-report"
        />

        <div className="background-bar"></div>
      </div>
    );
  }
}

export default EventReportV2