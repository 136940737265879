import React from "react";
import moment from "moment-timezone";

class AccountOffers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded_all_offers: false,
      show_loading: false,
    };

    this.network_busy = false;

    this.load_all_offer_assignments = this.load_all_offer_assignments.bind(this);
    this.submitPost = this.submitPost.bind(this);
    this.render_offer_rows = this.render_offer_rows.bind(this);
    this.update_offer_assignment = this.update_offer_assignment.bind(this);
  }

  update_offer_assignment(offer_assignment) {
    let new_offer_assignments = this.props.offer_assignments.map((oa) => {
      if (oa.id == offer_assignment.id) {
        return offer_assignment;
      }
      return oa;
    });

    this.props.load_all_offer_assignments(new_offer_assignments);
  }

  submitPost(e) {
    let target = e.target;
    let sudo_form = target.parentElement;

    if (target.getAttribute("data-confirm") && !confirm(target.getAttribute("data-confirm"))) {
      return;
    }
    
    let path = sudo_form.getAttribute("action");

    if (this.network_busy) {
      return;
    }
    this.network_busy = true;

    $.ajax({
      method: sudo_form.getAttribute("method").toUpperCase(),
      url: path,
      processData: false,
      contentType: false,
      success: (data) => {
        this.network_busy = false;
        if (typeof data === "object") {
          showFlashMessage(sudo_form.dataset.success);
          this[`${sudo_form.dataset.update}`](data);
        }
      },
      error: (error) => {
        this.network_busy = false;
        // showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }

  load_all_offer_assignments() {
    let path = `/accounts/${this.props.account.id}/get_all_offer_assignments`;

    if (this.network_busy) {
      return;
    }
    this.network_busy = true;
    this.setState({ show_loading: true });
    $.ajax({
      method: "GET",
      url: path,
      processData: false,
      contentType: false,
      success: (data) => {
        this.setState({ loaded_all_offers: true, show_loading: false });
        this.network_busy = false;
        this.props.load_all_offer_assignments(data.offer_assignments);
      },
      error: (error) => {
        this.network_busy = false;
        this.setState({ show_loading: false });
        // showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }

  render_offer_rows() {
    return (
      <>
        {this.props.offer_assignments.map((offer_assignment) => {
          return (
            <tr key={"oa" + offer_assignment.id}>
              <td>{offer_assignment.market_name}</td>
              <td>
                <a target="_blank" href={"/offers/" + offer_assignment.offer.id + "/edit"}>
                  {offer_assignment.offer.name}
                </a>
              </td>
              <td>
                {offer_assignment.redemption_status}
                <br />
                <small>{offer_assignment.assigned_by}</small>
              </td>
              <td>{offer_assignment.assignment_slot.id == -1 ? "No Assign. Slot" : offer_assignment.assignment_slot.name}</td>
              <td>
                {moment(offer_assignment.offer_start).format("MMM Do, YYYY") + " - "}<br />
                {moment(offer_assignment.offer_end).format("MMM Do, YYYY")}
              </td>
              <td>{offer_assignment.vendor_review || ""}</td>
              <td>{offer_assignment.customer_rating || ""}</td>
              <td>{offer_assignment.date_redeemed && moment(offer_assignment.date_redeemed).format("MMM Do, YYYY hh:mm a")}</td>
              <td>
                <a
                  data-remote="true"
                  href={"/offer_assignments/" + offer_assignment.id + "/swap_options"}
                >
                  Swap
                </a>
              </td>
              <td id={"offer-extend-"+ offer_assignment.id} className="space-options">
                <div
                  className="button_to"
                  method="post"
                  action={"/offer_assignments/" + offer_assignment.id + "/extend?as_json=true"}
                  data-remote="true"
                  data-success="Offer extended successfully"
                  data-update="update_offer_assignment"
                >
                  <input
                    data-confirm={"Are you sure you want to extend the offer: " + offer_assignment.offer.name + "?"}
                    type="button"
                    onClick={this.submitPost}
                    value={offer_assignment.can_extend ? "Extend" : "Not Extendable"}
                    disabled={offer_assignment.can_extend ? "" : "disabled"}
                  />
                </div>
                {offer_assignment.date_redeemed ?
                  <div
                    className="button_to"
                    method="post"
                    action={"/offer_assignments/clear_redemption?id="+offer_assignment.id+"&view=true&as_json=true"}
                    data-remote="true"
                    data-success="Offer redeem cleared successfully"
                    data-update="update_offer_assignment"
                  >
                    <input
                      data-confirm={`Are you sure you want to clear the redemption for ${offer_assignment.offer.name}?`}
                      className="clear-redemption-button"
                      type="submit"
                      value="Clear Redemption"
                      onClick={this.submitPost}
                    />
                  </div>
                  :
                  <div
                    className="button_to"
                    method="post"
                    action={"/offer_assignments/admin_redeem_offer?id="+offer_assignment.id+"&view=true&as_json=true"}
                    data-remote="true"
                    data-success="Offer redeemed successfully"
                    data-update="update_offer_assignment"
                  >
                    <input
                      data-confirm={"Are you sure you want to redeem: " + offer_assignment.offer.name+ " for this user?"}
                      className="clear-redemption-button"
                      type="submit"
                      value="Redeem"
                      onClick={this.submitPost}
                    />
                  </div>
                }
                <a
                  style={{marginTop: "0.35em", cursor: "pointer", display: "block"}}
                  target="_blank"
                  className="button_to"
                  method="get"
                  href={"/rails_admin/offer_assignment/" + offer_assignment.id}
                >
                  <input type="submit" value="Rails Admin" />
                </a>
              </td>
            </tr>
          )
        })}
      </>
    )
  }

  render() {
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    return (
      <>
        <h2 onClick={() => {
          let collapse_action = this.props.collapse_action;
          collapse_action.action(collapse_action.args);
        }}>
          Offers:{" "}
          <span
            className={caret_style}
            data-section-id="offer-section"
          />
        </h2>
        <div id="offer-section" style={collapsed_style}>
          <table className="admin__table">
            <thead>
              <tr>
                <th className="table-left">Market</th>
                <th className="table-left">Offer</th>
                <th className="table-left">Status</th>
                <th className="table-left">Assignment Slot</th>
                <th className="table-left">Offer Range</th>
                <th className="table-left">Vendor's Review</th>
                <th className="table-left">Customer's Rating</th>
                <th className="table-left">Date Redeemed</th>
                <th className="table-left">Swap</th>
                <th className="table-left">Actions</th>
              </tr>
            </thead>
            <tbody id="offers">
              {this.render_offer_rows()}
            </tbody>
          </table>
          <br />
          <div style={{display: "inline-flex"}}>
            {!this.state.loaded_all_offers &&
              <input type="button" value="Load All Offers" onClick={this.load_all_offer_assignments} className="admin__btn--create-new" />}
            {this.state.show_loading &&

              <div className="loader-container">
                <div className="loader"></div>
              </div>
            }
          </div>
        </div>
      </>
    );
  }
}

export default AccountOffers;