import React from "react";

class BackButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if(!this.props.onPress) return (<></>);

    let button_class = "signup-back-button";
    if (this.props.disabled) button_class += " disable-button";
    if (this.props.className) button_class += " " + this.props.className;

    return (
      <button
        className={button_class}
        onClick={this.props.onPress}
        id={this.props.gtm_id}>
        back
      </button>
    );
  }
}

export default BackButton