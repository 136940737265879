import React from "react";
import {getSubscriptionTermsText } from "../../../sign_up/sign_up_user_messages";
import { convertDateString } from '../../../../helpers/datestring_formatter'

class WhenToStartInput extends React.Component {
  constructor(props) {
    super(props);

    this.onRadioUpdate = this.onRadioUpdate.bind(this);
  }

  onRadioUpdate(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  getDeferralStartMonth(itter = 1) {
    const current_date = new Date();

    let current_month = current_date.getMonth();
    let next_month = current_month + itter;
    let year = current_date.getFullYear();

    while (next_month > 11) {
      next_month = next_month - 12;
      year++;
    }

    // If this market has a launch date in the future, members will defer to the start date
    if (this.props.selected_area && this.props.selected_area.start_date) {
      var converted_date = convertDateString(this.props.selected_area.start_date);
      if (converted_date > new Date()) {
        next_month = converted_date.getMonth();
        year = converted_date.getFullYear();
      }
    }
    return new Date(year, next_month, 1)
  }

  dateToLabel(date) {
    return `${this.dateGetMonth(date)} ${this.dateGetOrdinal(date)}, ${date.getFullYear()}`;
  }

  dateGetMonth(date) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[date.getMonth()];
  }

  dateGetOrdinal(date) {
    const ordinals = ["th", "st", "nd", "rd", "th"];
    let day = date.getDate() % 10;
    if (day > 4) {
      day = 4;
    }
    return `${date.getDate()}${ordinals[day]}`;
  }

  rederDeferralText() {
    
    if (this.props.skip_text) {
      return null;
    }

    let terms = []
    let future_date = this.getDeferralStartMonth(1)
    if (this.props.when_to_start == "today") {
      let last_day = new Date(future_date.getFullYear(), future_date.getMonth(), 0);
      terms = [
        `You'll pick your first restaurants today and have until ${this.dateGetMonth(last_day)} ${this.dateGetOrdinal(last_day)} to use them.`,
        "We'll send you a a reminder 7 days before your trial ends."
      ]
    } else {
      terms = [
        `Your trial will begin on ${this.dateToLabel(future_date)} (You'll pick your first restaurants that day).`,
        "We'll send you a a reminder 7 days before your trial ends."
      ]
    }

    return (
      <>
        <ul className="sign-up-list-container">
          {terms.map((term, i) => <li key={"sub_term_" + i} className="input-subtitle list">{term}</li>)}
        </ul>
      </>
    );
  }

  render() {
    let deferral_only = this.props.selected_area && this.props.selected_area.deferral_only

    return (
      <div className="when-to-start-input">
        <div className="radio-grouping">
          { !deferral_only &&
            <div className="radio-option">
              <input
                type="radio"
                value="today"
                id="today"
                name="when_to_start"
                checked={this.props.when_to_start == "today"}
                onChange={this.props.changeInputField}
              />
              <label htmlFor="today">Today</label>
            </div>
          }
          <div className="radio-option">
            <input
              type="radio"
              value="defer"
              id="defer"
              name="when_to_start"
              checked={this.props.when_to_start == "defer"}
              onChange={this.props.changeInputField}
            />
            <label htmlFor="defer">{this.dateToLabel(this.getDeferralStartMonth())}</label>
          </div>
        </div>
        {this.rederDeferralText()}
      </div>
    );
  }
}

export default WhenToStartInput