import React from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentElement from "../../../../sign_up/stripe_payment_element";
import SectionWithHeader from "../section_with_header";
import PaymentDueRecap from "./payment_due_recap";
import BulletPoint from "../bullet_point";
import ExampleSlider from "../example_slider";
import { getIDforGTM } from "../../state_components/gtm_methods";
import IconLock from "icon-secure.svg";

import CreditCards from "credit_cards.svg";
import LikesBubble from "likes_bubble.svg";
import Megaphone from "megaphone.svg";

class PaymentInputGUI extends React.Component {
  constructor(props) {
    super(props);
    this.stripePromise = loadStripe(this.props.stripe_key);
  }

  render() {
    const fonts = [
      { cssSrc: "https://fonts.googleapis.com/css?family=Zilla+Slab:400,400i,600,600i|Patrick+Hand+SC&display=swap" },
      { cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i|Source+Sans+Pro:400,400i,700,700i" },
      { cssSrc: "https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700;800&display=swap" }
    ];

    return (
      <div className="signup-payment-column">
        <div className="payment-description-container">
          <div className="payment-title">
            <img src={IconLock} alt="" />
            <span>First month <span className="payment-underscore">free</span></span>
          </div>
          <div className="payment-description">
            <div className="description-item">
              ✅ $0 due today
            </div>
            <div className="description-item">
              ✅ skip/cancel any time
            </div>
            <div className="description-item">
              {`✅ we'll text ${this.props.phone_number || "you"} before your first charge so you have a heads up`}
            </div>
          </div>
        </div>
        <Elements
          stripe={this.stripePromise}
          options={
            {
              clientSecret: this.props.setup_intent.client_secret,
              fonts: fonts,
              appearance: {
                variables: {
                  fontFamily: "Zilla Slab",
                  fontWeightNormal: "600",
                  colorPrimary: "#000"
                },
                rules: {
                  '.Label': {
                    fontSize: '16px'
                  },
                  '.Input': {
                    boxShadow: 'none',
                    border: "2px solid #000"
                  },
                  '.Tab--selected': {
                    backgroundColor: "#FFBD54",
                    border: "3px solid #000"
                  },
                  '.Text--terms': {
                    color: "rgba(0,0,0,0.0)",
                    fontSize: "0.1px"
                  }
                }
              }
            }
          }>
          <StripePaymentElement
            redirect_url={this.props.confirmation_path}
            button_class={"full-blue-button smaller-text"}
            subscription_terms_object={{ payment_submit_text: "START YOUR FREE TRIAL" }}
            submit_event={this.props.trackPaymentSubmit}
            button_id={getIDforGTM("submit-payment", this.props)}
          />

        </Elements>
        <div id={getIDforGTM("back-to-previous-screen", this.props)} className="footer-back-button-link signup-desktop-styling" onClick={this.props.previousScreen}>
          back
        </div>
      </div>
    );
  }
}

export default PaymentInputGUI