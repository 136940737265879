import React from "react";

class PickYourSlot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignment_slot: this.props.assignment_slot,
      selected_offer_ids: this.props.selected_offer_ids
    }

    this.renderOfferChoice = this.renderOfferChoice.bind(this);
  }

  renderOfferChoice() {

    return (
      <div></div>
      // <OfferChoice 
      //valid_offers={this.state.assignment_slot.valid_offers}
      //selected_offers={this.state.selected_offer_ids}
      // />
    )
  }


  render() {
    return (
      <div>
        <h4>I'm a slot</h4>
        {this.renderOfferChoice()}
      </div>
    );
  }
}

export default PickYourSlot