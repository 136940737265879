import React from "react";

class ReportFormatter extends React.Component {
  constructor(props) {
    super(props);

    this.data_rules = {
      // Top cells
      "overall_rating": {
        "null_message": "NO DATA",
        "formatter": this.dollarValue
      },
      "members_matched": {
        "null_message": "0",
        "formatter": this.noFormat
      },
      "total_customers": {
        "label": "Members Redeemed",
        "null_message": "0",
        "formatter": this.noFormat
      },
      "new_customers": {
        "label": "New Customers",
        "null_message": "NO DATA",
        "formatter": this.percentValue
      },
      "brought_non_offline_friends": {
        "label": "Brought Non-Offline Friends",
        "null_message": "NO DATA",
        "formatter": this.percentValue
      },
      "would_go_again": {
        "label": "Would Return",
        "null_message": "NO DATA",
        "formatter": this.percentValue
      },

      // Financial Impact
      "total_spent": {
        // New Sales Generated by Offline
        "null_message": "NO DATA",
        "label": "New Sales Generated by Offline",
        "formatter": this.dollarValue
      },
      "average_spend_per_user": {
        // Average Spend Per Member
        "null_message": "NO DATA",
        "label": "Average Spend Per Member",
        "formatter": this.dollarValue
      },
      "new_sales_above_discount": {
        // New Sales Above Discount
        "null_message": "NO DATA",
        "label": "New Sales Above Discount",
        "formatter": this.dollarValue
      },
      "discounts": {
        // Discounts
        "null_message": "NO DATA",
        "label": "Discounts",
        "display_as_negative": true,
        "formatter": this.dollarValue
      },
      "total_cost_of_sales": {
        // Total Cost of Sales
        "null_message": "NO DATA",
        "label": "Total Cost of Sales",
        "formatter": this.dollarValue
      },
      "cost_of_goods_sold": {
        // Your Cost of Goods Sold
        "null_message": "NO DATA",
        "label": "Your Cost of Goods Sold",
        "formatter": this.percentValue
      },
      "net_revenue": {
        // New Gross Profit
        "null_message": "NO DATA",
        "label": "New Gross Profit",
        "formatter": this.dollarValue
      },
      "cost_per_customer": {
        // Cost Per Customer
        "null_message": "NO DATA",
        "label": "Gross Profit Per Customer",
        "negative_label": "Cost Per Customer",
        "formatter": this.dollarValue
      }
    }

    this.data_presenter = this.data_presenter.bind(this);
    this.label_presenter = this.label_presenter.bind(this);
    this.percentValue = this.percentValue.bind(this);
    this.dollarValue = this.dollarValue.bind(this);
    this.noFormat = this.noFormat.bind(this);
  }

  noFormat(value) {
    return value;
  }

  percentValue(value) {
    if (!value) return "NO DATA";
    if (value >= -1 && value <= 1) value = value * 100;
    if (typeof value === 'number') return Math.floor(value).toFixed(0) + '%';
    return value;
  }

  dollarValue(value, null_message = "NO DATA") {
    if (typeof value === 'number') return `${value < 0 ? '-' : ''}$${Math.abs(value).toFixed(2)}`;
    if (value.includes('-')) {
      return "-$" + value.replace('-', '');
    } else {
      return "$" + value;
    }
  }

  data_presenter(name, value) {
    name = name.startsWith("ex_") ? name.replace("ex_", "") : name; // 'ex_' prefix means the value is extrapolated.  We don't do anything with this info currently.
    if(!value || isNaN(parseFloat(value))) {
      if (value && typeof value === "string"){
        return value;
      }
      return this.data_rules[name].null_message;
    } else {
      let mod = this.data_rules[name].display_as_negative ? -1 : 1;
      return this.data_rules[name].formatter(value * mod, this.data_rules[name].null_message);
    }
  }

  label_presenter(name, value) {
    name = name.startsWith("ex_") ? name.replace("ex_", "") : name; // 'ex_' prefix means the value is extrapolated.  We don't do anything with this info currently.
    let val_num = parseFloat(value);
    if(isNaN(val_num)) {
      return this.data_rules[name].label;
    } else if (val_num < 0){
      return this.data_rules[name].negative_label || this.data_rules[name].label;
    } else {
      return this.data_rules[name].label;
    }
  }
  
  render() {
    return (<></>)
  }
}

export default ReportFormatter