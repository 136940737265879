import React from "react";
import ImageSelect from "./image_select"
import FormBuilder from "../shared/form_builder";

class ImageEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changes_made: false,
      new_header_image: null,
      credit_text: props.credit_text || '',
      link: props.link || ''
    };

    this.changeInputField = this.changeInputField.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.saveImageChanges = this.saveImageChanges.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.repositionImage = this.repositionImage.bind(this);
  }

  saveImageChanges(event) {
    var form_builder = new FormBuilder();
    var form_object = {
      image: {
        credit_text: this.state.credit_text,
        link: this.state.link.trim()
      }
    }

    if (this.state.new_header_image) {
      form_object.image.header_image = this.state.new_header_image;
    }

    var form_data = form_builder.createForm(form_object);

    $.ajax({
      method: "PATCH",
      url: "/images/"+this.props.image_id,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        this.props.onImageUpdate(data.image);
        this.setState({changes_made: false});
      },
      error: (error) => {
        
      }
    });
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;

    this.setState({ [name]: target.value, changes_made: true });
  }

  handleImageChange(image_data, image_url) {

    var form_builder = new FormBuilder();
    var form_object = {
      image: {
        header_image: image_data
      }
    }
    var form_data = form_builder.createForm(form_object);

    $.ajax({
      method: "PATCH",
      url: "/images/"+this.props.image_id,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        this.props.onImageUpdate(data.image, true);
      },
      error: (error) => {
        
      }
    });
  }

  removeImage(event) {
    if (confirm("Remove this image?") == true) {
      $.ajax({
        method: "DELETE",
        url: "/images/"+this.props.image_id,
        processData: false,
        contentType: false,
        success: (data) => {
          this.props.onImageDelete(data.image_id);
        },
        error: (error) => {
          
        }
      });
    }
  }

  repositionImage(event) {
    var adjustment_direction = event.target.dataset["positionAdjustment"];

    $.ajax({
      method: "POST",
      url: "/images/"+this.props.image_id+"/shift_position",
      data: {adjustment: adjustment_direction},
      dataType: 'json',
      success: (data) => {
        this.props.onImageRearrangement(data.images, this.props.image_id);
      },
      error: (error) => {
        
      }
    });
  }

  render() {
    return (
      <div className='builder__multi-col admin__app-image-edit'>
        <div className='multi-col__size-2'>
          <ImageSelect
            image_url={this.props.header_image_url}
            onImageUploaded={this.handleImageChange} />
          { !this.props.no_delete &&
            <button
              style={{marginTop: '10px'}}
              className="standard-btn--rounded--red"
              onClick={this.removeImage}>
              Delete Image
            </button>
          }
        </div>
        <div className={this.props.dataClassName || 'multi-col__size-2'}>
          <div className='builder__multi-col' style={{marginBottom: '10px'}}>
            <div className='multi-col__size-2'>
              { this.props.position > 1 &&
                <button
                  style={{width: '100%', height: '2rem'}}
                  className="standard-btn--rounded"
                  data-position-adjustment={-1}
                  onClick={this.repositionImage}>
                  ← Move Left
                </button>
              }
            </div>
            <div className='multi-col__size-2'>
              { this.props.position < this.props.highestPosition &&
                <button
                  style={{width: '100%', height: '2rem'}}
                  className="standard-btn--rounded"
                  data-position-adjustment={1}
                  onClick={this.repositionImage}>
                  Move Right →
                </button>
              }
            </div>
          </div>
          <div>
            <input
              name='credit_text'
              className='builder__textarea'
              type="text"
              placeholder="credit text"
              value={this.state.credit_text}
              onChange={this.changeInputField} />
            <input
              name='link'
              className='builder__textarea'
              type="text"
              placeholder="website"
              value={this.state.link}
              onChange={this.changeInputField} />
          </div>
          <button
            className={this.state.changes_made ? "standard-btn--rounded--blue" : "standard-btn--rounded--gray"}
            onClick={this.saveImageChanges}>
            Save Changes
          </button>
        </div>
      </div>
    )
  }
}

export default ImageEditor;