import React from "react";
import FilterOffers from "./filter_offers";
import OfferSlider from "../../gui_components/cyoo/offer_slider";
import OfferInfoSlider from "./offer_info_slider";

class ChooseOffer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_new_offers_tab: true,
      categories: [...props.all_categories],
      locations: [...props.all_locations],
      offer_types: [...props.all_offer_types],
      show_offer_slider: false,
      offer_preview_id: null,
      offer_count: props.all_valid_offers.length
    };

    this.menu_ref = null;

    this.select_offer = this.select_offer.bind(this);
    this.get_filtered_offers = this.get_filtered_offers.bind(this);
    this.loaded_offer = this.loaded_offer.bind(this);
    this.open_slider = this.open_slider.bind(this);
    this.close_slider = this.close_slider.bind(this);
    this.update_slider_ref = this.update_slider_ref.bind(this);
    this.update_live_count = this.update_live_count.bind(this);
    this.open_filter = this.open_filter.bind(this);
    this.close_filter = this.close_filter.bind(this);
  }

  componentDidMount() {
    document.scrollingElement.scrollTop = 0;
    if (analytics_loaded) {
      mixpanel.track(`Signup: CYOO ${this.props.choose_index + 1}`);
      posthog.capture(`Signup: CYOO ${this.props.choose_index + 1}`);
    }
  }

  get_filtered_offers(filter_options = {}) {
    let filter = {
      locations: this.state.locations,
      categories: this.state.categories,
      offer_types: this.state.offer_types
    }

    Object.assign(filter, filter_options);
    let {
      locations,
      categories,
      offer_types
    } = filter;

    if (typeof locations[0] == "object" && locations[0].id) {
      locations = locations.map(location => location.id);
    }

    if (typeof categories[0] == "object" && categories[0].id) {
      categories = categories.map(category => category.id);
    }

    if (typeof offer_types[0] == "object" && offer_types[0].id) {
      offer_types = offer_types.map(offer_type => offer_type.id);
    }

    let cards = this.props.all_valid_offers;
    let order = [1, 2, 3];

    // Put cards in the order of the member's offer_types
    cards = [
      ...cards.filter(card => card.offer_type_id == order[0]),
      ...cards.filter(card => card.offer_type_id == order[1]),
      ...cards.filter(card => card.offer_type_id == order[2])
    ]

    let filtered_cards = [];
    // Filter Locations
    // cards = cards.filter(card => locations.some(location_id => card.locations.includes(location_id)));
    for (let card of cards) {
      for (let location of card.locations) {
        if (locations.includes(location)) {
          filtered_cards.push(card);
          break;
        }
      }
    }
    cards = [...filtered_cards];

    // Filter Categories
    // cards = cards.filter(card => categories.some(category_id => card.categories.includes(category_id)));
    filtered_cards = [];
    for (let card of cards) {
      for (let category of card.categories) {
        if (categories.includes(category)) {
          filtered_cards.push(card);
          break;
        }
      }
    }
    cards = [...filtered_cards];

    // Filter Offer Types
    cards = cards.filter(card => offer_types.includes(card.offer_type_id));
    this.live_count = cards.length;

    // Filter out cards that have already been selected
    let selected_offer_ids = this.props.selected_offers
                                 .map(offer => offer.id)
                                 .filter(id => id != this.props.selected_offer?.id);
    cards = cards.filter(card => !selected_offer_ids.includes(card.id));
     
    // Sort cards by number of interested members
    cards = cards.sort((a, b) => b.chosen_this_month - a.chosen_this_month);
    cards = cards.sort((a, b) => b.total_interested - a.total_interested);

    return cards;
  }

  update_slider_ref(ref) {
    this.menu_ref = ref;
  }

  // Event Handlers
  update_live_count(params) {
    let offer_count = 0;
    if (params) {
      offer_count = this.get_filtered_offers(params).length;
    } else {
      offer_count = this.get_filtered_offers().length;
    }
    this.setState({ offer_count });
  }

  open_filter() {
    this.props.open_filter();
  }

  close_filter(data) {
    let update_state = {};
    if (data) {
      Object.assign(update_state, data);
      update_state.offer_count = this.get_filtered_offers(data).length;
      this.scroll_to_beginning();
    }
    this.setState(update_state);
    this.props.close_filter();
  }

  loaded_offer(offer) {
    this.props.update_offer_cache(offer);
  }

  open_slider(offer_id) {
    this.setState({ show_offer_slider: true, offer_preview_id: offer_id });
    document.documentElement.classList.add("no-scroll");
  }

  close_slider() {
    this.setState({ show_offer_slider: false });
    document.documentElement.classList.remove("no-scroll");
  }

  select_offer(offer) {
    // this.setState({ selected_offer: offer });
    this.props.select_offer(offer);
  }

  // Helper Functions
  parseCommaSeparatedStringOfInt(string) {
    return string.split(',').map(n => parseInt(n))
  }

  scroll_to_beginning() {
    if (this.menu_ref) {
      this.menu_ref.scrollLeft = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  render() {
    return (
      <>
        <OfferSlider
          offers={this.get_filtered_offers()}
          selected_offer={this.props.selected_offer}
          select_offer={this.select_offer}
          open_offer_info={this.open_slider}
          choose_index={this.props.choose_index}
          update_slider_ref={this.update_slider_ref}
        />
        {this.state.show_offer_slider &&
          <OfferInfoSlider
            offer={this.props.offer_cache[this.state.offer_preview_id]}
            offer_id={this.state.offer_preview_id}
            close_slider={this.close_slider}
            loaded_offer={this.loaded_offer}
          />
        }
        <FilterOffers
          locations={this.state.locations}
          categories={this.state.categories}
          offer_types={this.state.offer_types}
          all_locations={this.props.all_locations}
          all_categories={this.props.all_categories}
          all_offer_types={this.props.all_offer_types}
          close_filter={this.close_filter}
          update_live_count={this.update_live_count}
          live_count={this.state.offer_count}
          show_offer_filter={this.props.show_offer_filter}
          choose_index={this.props.choose_index}
        />
      </>
    );
  }
}

export default ChooseOffer