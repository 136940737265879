import React from "react";
import CityDropdown from "../shared/city_dropdown";
import ExtendedFooterContent from "./extended_footer_content";
import FAQSection from "./sign_up_sections/faq_section";
import TestimonialSection from "./sign_up_sections/testimonial_section";
import FindOffersSection from "./sign_up_sections/find_offers_section";
import ReviewSection from "./sign_up_sections/review_section";

class InitialUserForm extends React.Component {
  constructor(props) {
    super(props);

    let markets = this.props.areas.map((area) => { return { label: (area.name + ", " + (area.state || "")), value: area.id, city: area.name } });
    let referral_code = props.referral_details?.referral.referral_code
      || props.referral_details?.referral.gift_code
      || '';

    this.state = {
      markets: markets,
      selected_market: props.selected_market,
      referral_code: referral_code,
      valid_referral: referral_code,
      referral_details: props.referral_details,
      email: props.email || "",
      phone_number: props.phone_number || "",
      first_name: props.first_name || "",
      last_name: props.last_name || "",
      popular_offers: props.popular_offers,
      show_edit_button: referral_code ? true : false
    }

    this.pending_submission = false;

    this.changeInputField = this.changeInputField.bind(this);
    this.updateStateValue = this.updateStateValue.bind(this);
    this.canContinue = this.canContinue.bind(this);
    this.validateReferralCode = this.validateReferralCode.bind(this);
    this.removeReferralCode = this.removeReferralCode.bind(this);
    this.validateAccountDetails = this.validateAccountDetails.bind(this);
    this.referralCodeInputStatus = this.referralCodeInputStatus.bind(this);
    this.renderReferral = this.renderReferral.bind(this);
    this.renderV9Fields = this.renderV9Fields.bind(this);
    this.most_popular_offers = this.most_popular_offers.bind(this);
    this.get_area_abbreviation = this.get_area_abbreviation.bind(this);
    this.setMixpanelAquisition = this.setMixpanelAquisition.bind(this);
    this.renderReferralSuccessMessage = this.renderReferralSuccessMessage.bind(this);
    this.edit_referral_code = this.edit_referral_code.bind(this);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Profile");
      posthog.capture("Signup: Profile");
    }
    this.setMixpanelAquisition(this.props.referral_details);

    if (this.props.signup_version == 9) {
      let area_abbreviation = this.get_area_abbreviation(this.state.selected_market);
      if(this.props.popular_offers[area_abbreviation]) {
        localStorage.setItem(("mpo_" + area_abbreviation), JSON.stringify(this.props.popular_offers[area_abbreviation]));
      }
      
    }
  }

  setMixpanelAquisition(referral_details) {
    if (analytics_loaded && referral_details) {
      var aquistion = "Partner referral";
      if (referral_details.type == "user") {
        aquistion = "Account referral";
      } else if (referral_details.type == "giftcode") {
        aquistion = "Gift code";
      }
      mixpanel.people.set({"Acquisition Type": aquistion});
    }
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;
    if (target.type == "checkbox") {
      return this.setState({ [name]: target.checked });
    }

    var new_state = { [name]: target.value };
    if (name == "email") {
      new_state.email = target.value.toLowerCase().replace(/\s/g, '');
    }
    if (this.state["error_" + name]) {
      new_state["error_" + name] = null;
    }
    this.setState(new_state);
    this.props.onStateUpdate(name, target.value);
  }

  updateStateValue(name, value) {
    let new_state = { [name]: value };
    if (name == "selected_market" && this.state.valid_referral) {
      new_state.valid_referral = null;
      this.setState(new_state);
      this.validateReferralCode(null, value);
      this.most_popular_offers(value);
    } else if (name == "selected_market") {
      this.setState(new_state);
      this.most_popular_offers(value);
    } else {
      this.setState(new_state);
    }


    this.props.onStateUpdate(name, value);
  }

  canContinue() {
    return (this.state.email && this.state.phone_number && this.state.valid_referral && this.state.first_name && this.state.last_name)
  }

  referralCodeInputStatus() {
    let className = this.state.valid_referral ? "disable-input" : "";

    if (this.state.error_referral_code) {
      className += " error";
    } else if (this.state.referral_details) {
      className += " success";
    }
    return className;
  }

  get_area_abbreviation(area_id) {
    return this.props.areas.find(area => area.id == area_id).abbreviation;
  }

  most_popular_offers(area_id) {
    if(this.props.signup_version != 9) return null;

    let area_abbreviation = this.get_area_abbreviation(area_id);
    let update_state = this.state.popular_offers;

    if (localStorage.getItem(("mpo_" + area_abbreviation))) {
      let area_offers = JSON.parse(localStorage.getItem(("mpo_" + area_abbreviation)));
      
      if (area_offers && area_offers.length > 0) {
        update_state[area_abbreviation] = area_offers;
        return this.setState({ popular_offers: update_state });
      }
    }

    $.ajax({
      method: "GET",
      url: "/most_popular_offers",
      data: { area_abbr: area_abbreviation },
      dataType: 'json',
      success: (data) => {
        let area_offers = data && data.area_offers && data.area_offers.length > 0 ? data.area_offers : null;
        if (!area_offers) return null;
        
        localStorage.setItem(("mpo_" + area_abbreviation), JSON.stringify(area_offers));
        update_state[area_abbreviation] = area_offers;
        this.setState({ popular_offers: update_state });
      },
      error: (error) => {
        console.log(error);
      }
    })
  }

  validateReferralCode(event, area_id) {
    area_id = area_id || this.state.selected_market;
    $.ajax({
      method: "POST",
      url: "/referral-code",
      data: { referral_code: this.state.referral_code, area_id },
      dataType: 'json',
      success: (data) => {
        this.setState({ valid_referral: this.state.referral_code, referral_details: data, error_referral_code: null, show_edit_button: true });
        this.props.onStateUpdate('referral_details', data);
        this.setMixpanelAquisition(data);
      },
      error: (error) => {
        this.setState({ error_referral_code: error.responseJSON.error, referral_details: null, show_edit_button: false });
        this.props.onStateUpdate('referral_details', null);
      }
    });
  }

  removeReferralCode() {
    this.setState({ referral_code: "", valid_referral: undefined, referral_details: undefined })
  }

  validateAccountDetails() {
    var no_errors = true;
    var phone = this.state.phone_number.replace(/\D/g, '');
    if (phone && phone[0] == "1") {
      phone = phone.slice(1)
    }
    if (phone.length != 10) {
      no_errors = false;
      this.setState({ error_phone_number: "Invalid phone number" })
    }

    if (analytics_loaded) {
      var signup_version = "1.0";
      if (typeof(global_signup_version) != 'undefined') {
        signup_version = global_signup_version +".0";
      }

      mixpanel.people.set({
        "$email": this.state.email,
        "$name": this.state.first_name + " " + this.state.last_name,
        "$phone": `+1${phone}`,
        "First Name": this.state.first_name,
        "Last Name": this.state.last_name,
        "Referral Code Used During Signup": this.state.valid_referral,
        "Signup Version": signup_version
      })
    }

    if (no_errors) {
      if (this.pending_submission) {
        return false;
      }
      this.pending_submission = true;
      var signup_version = null;
      if (typeof(global_signup_version) != 'undefined') {
        signup_version = global_signup_version +".0";
      }

      if (typeof(cvg) != 'undefined') {
        var state = this.props.areas.find(area => area.id == this.state.selected_market).state_name
        var sanitized_email = this.state.email.toLowerCase().replace(/\s/g, '');
        cvg({
          method: "track",
          eventName: "Started Checkout",
          properties: {
            total_price: 0,
          },
          profileProperties: {
            // Pass if available
            $email: sanitized_email,
            $first_name: this.state.first_name,
            $last_name: this.state.last_name,
            $phone_number: this.state.phone_number,
            $country_code: "US",
            $state: state
          },
          aliases: ["urn:email:"+sanitized_email],
        });
      }
      

      $.ajax({
        method: "POST",
        url: "/account-validate",
        data: {
          email: this.state.email,
          phone_number: this.state.phone_number,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          referral_code: this.state.valid_referral,
          selected_market: this.state.selected_market,
          signup_version: signup_version
        },
        dataType: 'json',
        success: (data) => {
          this.pending_submission = false;
          this.props.onStateUpdate("waitlist_id", data.waitlist_id);
          this.props.nextAction();
        },
        error: (error) => {
          this.pending_submission = false;
          for (let e of error.responseJSON.errors) {
            if (e.type == "email" || e.type == "invalid-email") {
              this.setState({ error_email: e.message });
            }
            if (e.type == "phone-number") {
              this.setState({ error_phone_number: e.message });
            }
          }
        }
      });
    }
  }

  edit_referral_code() {
    this.setState({ show_edit_button: false});
  }

  renderReferral() {
    if (this.state.show_edit_button) return (<></>);

    let button_text = "APPLY";
    let button_class = "sign-up-button-min inline";
    let onClick = this.validateReferralCode;

    if (this.state.valid_referral) {
      button_text = "REMOVE";
      button_class = "inline remove-referral"
      onClick = this.removeReferralCode;
    }

    return (
      <>
        <input type="text"
          id="input_referral_code"
          name="referral_code"
          placeholder="Enter Referral Code"
          autoCapitalize="none"
          className={this.referralCodeInputStatus()}
          onChange={this.changeInputField}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClick();
            }
          }}
          disabled={this.state.valid_referral ? true : false}
          value={this.state.referral_code} />
        <button
          id="submit_referral_code"
          className={button_class}
          onClick={onClick}>
          {button_text}
        </button>
      </>
    )
  }

  renderV9Fields() {
    let area_abbreviation = this.get_area_abbreviation(this.state.selected_market);
    let popular_offers = this.state.popular_offers[area_abbreviation] || [];

    return (
      <>
        <ReviewSection area_name={this.props.areas.find(area => area.id == this.state.selected_market).name} />
        <ExtendedFooterContent>
          <FindOffersSection popular_offers={popular_offers} />
          <TestimonialSection testimonials={this.props.testimonials} />
          <FAQSection faqs={this.props.faqs} />
        </ExtendedFooterContent>
      </>
    )
  }

  renderReferralSuccessMessage() {
    if (this.state.referral_details) {
      if (this.state.referral_details.type == "partner" && this.state.referral_details.referral.message_for_monthly_checkout && this.state.referral_details.referral.message_for_monthly_checkout.length > 0) {
        return this.state.referral_details.referral.message_for_monthly_checkout
      } else {
        return "Your code has been successfully applied! 🥳"
      }
    }
    return "" 
  }

  render() {
    let number_description = "Only real team members will text you...promise";

    if (this.props.signup_version == 9) {
      number_description = "We hate spam. We'll only text you for important updates.";
    }

    return (
      <div className="sign-up-column">
        <div className="market-header">
          <div className="step-header">Welcome to Offline</div>
          <CityDropdown
            options={this.state.markets}
            value={this.state.selected_market}
            onChange={this.updateStateValue}
            className="sign-up"
          />
        </div>
        <div className="market-subtitle hide-on-9">
          Not a member yet? You're in the right place.
          <br />
          <a href={`https://www.letsgetoffline.com/join${location.search}`} target="_blank">Learn more</a>
        </div>
        <br className='hide-on-9'/>

        <div className="input-label">Name</div>
        <div className="referral-container">
          <input type="text"
            name="first_name"
            placeholder="First Name"
            onChange={this.changeInputField}
            value={this.state.first_name} />
          <input type="text"
            name="last_name"
            placeholder="Last Name"
            onChange={this.changeInputField}
            value={this.state.last_name}
            style={{ marginLeft: '1rem' }} />
        </div>

        <div className="input-label small-top-margin">Email Address</div>
        <input type="text"
          id="input_email"
          name="email"
          placeholder="Email Address"
          onChange={this.changeInputField}
          value={this.state.email} />
        <div className="input-error">
          { this.state.error_email && 
            <span>
              {this.state.error_email}<br/>
              If you were a previous member, please redownload the app (
              <a href="https://apps.apple.com/us/app/offline-restaurant-club/id1632270215" >iOS App Store</a> | 
              <a href="https://play.google.com/store/apps/details?id=com.letsgetoffline.lgomobile">Google Play Store</a>
              ) to sign back in.
            </span>
          }
        </div>

        <div className="input-label small-top-margin">Phone Number</div>
        <input type="text"
          id="input_phone_number"
          name="phone_number"
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="Phone Number"
          onChange={this.changeInputField}
          value={this.state.phone_number} />
        <div className="input-error">
          {this.state.error_phone_number &&
            <span>
              {this.state.error_phone_number}<br/>
              If you were a previous member, please redownload the app (
              <a href="https://apps.apple.com/us/app/offline-restaurant-club/id1632270215" >iOS App Store</a> | 
              <a href="https://play.google.com/store/apps/details?id=com.letsgetoffline.lgomobile">Google Play Store</a>
              ) to sign back in.
            </span>
          }
        </div>
        <div className="input-subtitle">{number_description}</div>

        <div className="ref-code-label-container ref-code">
          <div className="input-label small-top-margin ref-code">Referral Code</div>
          <button className={"ref-code-edit" + (this.state.show_edit_button ? "" : " hide-button")} onClick={this.edit_referral_code}>Edit</button>
        </div>
        {!this.state.show_edit_button &&
          <div className="referral-container ref-code">
            {this.renderReferral()}
          </div>
        }
        <div className="input-error ref-code">{this.state.error_referral_code}</div>
        <div className="input-success ref-code">{this.renderReferralSuccessMessage()}</div>
        <br />

        {this.props.signup_version == 9 &&
          <>
            {this.renderV9Fields()}
          </>
        }

        <div className="sign-up-nav-footer">
          <button
            id="submit_step_1"
            className={"sign-up-button-full " + (this.canContinue() ? "" : "inactive")}
            disabled={!this.canContinue()}
            onClick={this.validateAccountDetails}>
            CONTINUE →
          </button>
          {/*<button className="sign-up-button-anchor">Back</button>*/}
        </div>
      </div>
    );
  }
}

export default InitialUserForm