import React from "react";

class AccountEvents extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show_event_modal_id: null
    }

    this.modal_ref = React.createRef();

    this.network_busy = false;

    this.show_event_modal = this.show_event_modal.bind(this);
    this.hide_event_modal = this.hide_event_modal.bind(this);
    this.reset_ticket_status = this.reset_ticket_status.bind(this);
    this.render_event_modal = this.render_event_modal.bind(this);
    this.submitPost = this.submitPost.bind(this);
  }

  show_event_modal(e) {
    let target = e.target;
    let id = target.getAttribute("data-id");
    this.setState({ show_event_modal_id: id });
  }

  hide_event_modal(e) {
    let target = e.target;
    if (this.modal_ref.current == target || target.className == "event-modal-close") {
      this.setState({ show_event_modal_id: null });
    }
  }

  update_event_assignment(event_assignment) {
    let new_event_assignments = this.props.event_assignments.map((ea) => {
      if (ea.id == event_assignment.id) {
        return event_assignment;
      }
      return ea;
    });

    this.props.load_all_event_assignments(new_event_assignments);
  }

  submitPost(e) {
    let target = e.target;
    let sudo_form = target.parentElement;

    if (target.getAttribute("data-confirm") && !confirm(target.getAttribute("data-confirm"))) {
      return;
    }
    
    let path = sudo_form.getAttribute("action");

    if (this.network_busy) {
      return;
    }
    this.network_busy = true;

    $.ajax({
      method: sudo_form.getAttribute("method").toUpperCase(),
      url: path,
      processData: false,
      contentType: false,
      success: (data) => {
        this.network_busy = false;
        if (typeof data === "object") {
          showFlashMessage(sudo_form.dataset.success);
          this[`${sudo_form.dataset.update}`](data);
        }
      },
      error: (error) => {
        this.network_busy = false;
        // showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }

  reset_ticket_status() {
    //reset_ticket_status
    // ajax POST to /event_assignments/:id/reset_ticket_status

    $.ajax({
      method: "POST",
      url: `/event_assignments/${this.props.id}/reset_ticket_status?as_json=true`,
      data: {},
      processData: false,
      contentType: false,
      success: (data) => {
        console.log(data);
      },
      error: (error) => {
        showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }

  render_event_modal() {
    if (!this.state.show_event_modal_id) return (<></>);

    let event_assignment = this.props.event_assignments.find((ea) => { return ea.id == parseInt(this.state.show_event_modal_id) });
    return (
      <div className="event-modal-overlay" ref={this.modal_ref} style={{ display: "flex" }} onClick={this.hide_event_modal}>
        <div className="event-modal-container">
          <div className="event-modal-header">
            <div className="event-modal-title">Event Assignment Info</div>
            <div className="event-modal-close" onClick={this.hide_event_modal}>✕</div>
          </div>
          <div className="event-modal-body">
            <div className="event-modal-table-title">General Stats</div>
            <table className="event-modal-general-table">
              <tr>
                <th>ID</th>
                <th>Event</th>
                <th>Event Date</th>
                <th>Status</th>
                <th>Timeslot</th>
                <th>Tickets</th>
                <th>Tickets Redeemed</th>
                <th>Tickets No Charge</th>
              </tr>
              <tr className="fill-data">
                <td>{event_assignment.id}</td>
                <td>{event_assignment.event_name}</td>
                <td>{event_assignment.date}</td>
                <td>{event_assignment.status}</td>
                <td>{event_assignment.timeslot}</td>
                <td>{event_assignment.tickets}</td>
                <td>{event_assignment.tickets_redeemed}</td>
                <td>{event_assignment.tickets_no_charge}</td>
              </tr>
            </table>
            <div className="event-modal-table-title">Review Stats</div>
            <table className="event-modal-rating-table">
              <tr>
                <th>Customer Rating</th>
                <th>Ambience Rating</th>
                <th>Food and Drink Rating</th>
                <th>Business Staff Rating</th>
                <th>Offline Staff Rating</th>
                <th>First Time at Business</th>
                <th>Will Go Again</th>
                <th>Areas of Improvement</th>
                <th>Feedback</th>
              </tr>
              <tr className="fill-data">
                <td>{event_assignment.rating}</td>
                <td>{event_assignment.rating_ambience}</td>
                <td>{event_assignment.rating_food_drink}</td>
                <td>{event_assignment.rating_business_staff}</td>
                <td>{event_assignment.rating_offline_staff}</td>
                <td>{event_assignment.first_time}</td>
                <td>{event_assignment.will_go_again}</td>
                <td>{event_assignment.areas_of_improvement}</td>
                <td>{event_assignment.feedback}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    )
  }

  render() {
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    return (
      <>
        <h2 onClick={() => {
          let collapse_action = this.props.collapse_action;
          collapse_action.action(collapse_action.args);
        }}>
          Events:{" "}
          <span
            className={caret_style}
            data-section-id="event-section"
          />
        </h2>
        <div id="event-section" style={collapsed_style}>
          <table className="admin__table">
            <thead>
              <tr>
                <th className="table-left">Event</th>
                <th className="table-left">Tickets</th>
                <th className="table-left">Tickets Redeemed</th>
                <th className="table-left">Event Date</th>
                <th className="table-left">Status</th>
                <th className="table-left">Actions</th>
              </tr>
            </thead>
            <tbody id="events">
              {this.props.event_assignments.map((event_assignment, index) => {
                return (
                  <tr
                    key={"ea" + event_assignment.id}
                    id={"ea" + event_assignment.id}
                    data-eventid={event_assignment.event_id}
                    data-id={event_assignment.id}
                    data-eventname={event_assignment.event_name}
                    data-date={event_assignment.date}
                    data-timeslot={event_assignment.timeslot}
                    data-status={event_assignment.status}
                    data-rating={event_assignment.rating}
                    data-tickets={event_assignment.tickets}
                    data-ticketsnocharge={event_assignment.tickets_no_charge}
                    data-ticketsredeemed={event_assignment.tickets_redeemed}
                    data-feedback={event_assignment.feedback}
                    data-ratingambience={event_assignment.rating_ambience}
                    data-ratingfooddrink={event_assignment.rating_food_drink}
                    data-ratingbusinessstaff={event_assignment.rating_business_staff}
                    data-ratingofflinestaff={event_assignment.rating_offline_staff}
                    data-firsttime={event_assignment.first_time}
                    data-willgoagain={event_assignment.will_go_again}
                    data-areasofimprovement={event_assignment.areas_of_improvement}
                  >
                    <td>
                      <a target="_blank" href={"/events/" + event_assignment.event_id + "/edit"}>
                        {event_assignment.event_name}
                      </a>
                    </td>
                    <td>{event_assignment.tickets}</td>
                    <td>{event_assignment.tickets_redeemed}</td>
                    <td>{event_assignment.date}</td>
                    <td>{event_assignment.status}</td>
                    <td>
                      <div
                        className="button_to"
                        method="post"
                        action={"/event_assignments/" + event_assignment.id + "/reset_ticket_status?view=true&as_json=true"}
                        data-remote="true"
                        data-success="Event reset successfully"
                        data-update="update_event_assignment"
                      >
                        <input
                          data-confirm="Are you sure you want to reset the status of the event_assignment: SPRING GARDEN PARTY AT NAMU#13936 for this user? (This is meant for QA purposes)"
                          className="clear-redemption-button"
                          type="submit"
                          value="Reset"
                          onClick={this.submitPost}
                        />
                      </div>
                      <button
                        name="button"
                        type="button"
                        className="clear-redemption-button"
                        onClick={this.show_event_modal}
                        data-id={event_assignment.id}
                      >
                        Details
                      </button>
                    </td>
                  </tr>
                )
              })
              }

            </tbody>
          </table>
        </div>
        {this.render_event_modal()}
      </>
    );
  }
}

export default AccountEvents;