import React from "react";


class AccountLinkSummary extends React.Component {
  constructor(props) {
    super(props);
  }


  // TODO remove on group rollout
  // linkedAccountClassFormat() {
  //   if (this.props.two_way_link && this.props.matching_preferences) {
  //     return "member__link-initials"
  //   } else {
  //     return "member__link-initials red-link"
  //   }
  // }

  render() {

    return (
      <div className="member__link-initials" data-sync-id={this.props.account_id}>
        {this.props.first_name[0]}{this.props.last_name[0]}
      </div>
    );
  }
}

export default AccountLinkSummary