import React from "react";

class EventNavLinks extends React.Component {
  constructor(props) {
    super(props);
  }

  buttonStyle() {
    return {
      backgroundColor: 'white',
      color: '#444', 
      width: '10em', 
      height: '3em', 
      margin: '0 .5em 0', 
      borderRadius: '0.55em', 
      border: '2px solid #444', 
      boxShadow: '2px 2px 0px 0px #444'
    };
  }

  editEventLink() {
    return "/events/"+this.props.id+"/edit"
  }

  shareLink() {
    return "/event/"+this.props.id+"/share"
  }

  inviteAndNotificationsLink() {
    return "/events/"+this.props.id+"/messaging"
  }

  checkinEventLink() {
    return "/events/"+this.props.token+"/checkin"
  }

  reportEventLink() {
    return "/events/"+this.props.token+"/report_v2"
  }

  render() {
    return (
      <>
        { this.props.current != "share" &&
          <a href={this.shareLink()}>
            <button style={this.buttonStyle()}>Share</button>
          </a>
        }
        { this.props.current != "invites_and_notifications" &&
          <a href={this.inviteAndNotificationsLink()}>
            <button style={this.buttonStyle()}>Invite + Notifications</button>
          </a>
        }
        { this.props.current != "edit" &&
          <a href={this.editEventLink()}>
            <button style={this.buttonStyle()}>Edit</button>
          </a>
        }
        { this.props.current != "checkin" &&
          <a href={this.checkinEventLink()}>
            <button style={this.buttonStyle()}>Check In</button>
          </a>
        }
        { this.props.current != "report" &&
          <a href={this.reportEventLink()}>
            <button style={this.buttonStyle()}>Report</button>
          </a>
        }
      </>
    );
  }
}

export default EventNavLinks