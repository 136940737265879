import React from "react";
import ReferralCodeInputV3 from "../state_components/referral_code_input_v3";
import { getIDforGTM } from "../../v2/state_components/gtm_methods"

class NameAndReferralCodeGUI extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="signup-v3-column signup-column membership-information">
          <div className="membership-info-title">Welcome to Offline</div>
          <div className='membership-info-subtitle'>Let’s get you set up. What should we call you?</div>
          <div className="name-input">
            <div className="membership-info-input-container">
              <div className="input-wrapper">
                <input type="text"
                  className="membership-info-input signup-v3-input signup-input"
                  id="input_first_name"
                  name="first_name"
                  placeholder="First Name"
                  onChange={this.props.changeInputField}
                  value={this.props.first_name} />
                <div className="input-error">
                  {this.props.error_first_name}
                </div>
              </div>
              <div className="input-wrapper">
                <input type="text"
                  className="membership-info-input signup-v3-input signup-input"
                  id="input_last_name"
                  name="last_name"
                  placeholder="Last Name"
                  onChange={this.props.changeInputField}
                  value={this.props.last_name}
                />
                <div className="input-error">
                  {this.props.error_last_name}
                </div>
              </div>

            </div>
          </div>
          <ReferralCodeInputV3
            hide_label={true}
            referral_code={this.props.referral_code}
            valid_referral={this.props.valid_referral}
            referral_details={this.props.referral_details}
            selected_market={this.props.selected_market}
            error_referral_code={this.props.error_referral_code}
            onRefferalSubmit={this.props.onRefferalSubmit}
            onReferralClear={this.props.onReferralClear}
            signup_version={this.props.signup_version}
            gtm_screen_name={this.props.gtm_screen_name}
            version_test_tags={this.props.version_test_tags} />
          <button
            className={"full-blue-button smaller-text aux-full-width aux-footer-low"}
            // disabled={this.canContinue() ? "" : "disabled"}
            onClick={this.props.validateAccountDetails}
            id={getIDforGTM('submit-name-and-referral-code', this.props)}>
            CONTINUE
          </button>
        </div>
      </>
    );
  }
}

export default NameAndReferralCodeGUI