import React from "react";
import CityBulletListSelect from "../shared/city_bullet_list_select";

class UserReactivateForm extends React.Component {
  constructor(props) {
    super(props);

    let referral_code = props.referral_details?.referral.referral_code
      || props.referral_details?.referral.gift_code
      || '';

    this.state = {
      areas: props.areas,
      selected_market: props.selected_market,
      referral_code: referral_code,
      valid_referral: referral_code,
      referral_details: props.referral_details,
      email: props.email || "",
      phone_number: props.phone_number || ""
    }

    this.pending_submission = false;

    this.changeInputField = this.changeInputField.bind(this);
    this.updateStateValue = this.updateStateValue.bind(this);
    this.canContinue = this.canContinue.bind(this);
    this.validateReferralCode = this.validateReferralCode.bind(this);
    this.removeReferralCode = this.removeReferralCode.bind(this);
    this.validateAccountDetails = this.validateAccountDetails.bind(this);
    this.referralCodeInputStatus = this.referralCodeInputStatus.bind(this);
    this.renderReferral = this.renderReferral.bind(this);
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;
    if (target.type == "checkbox") {
      return this.setState({ [name]: target.checked });
    }

    var new_state = { [name]: target.value };
    if (this.state["error_" + name]) {
      new_state["error_" + name] = null;
    }
    this.setState(new_state);
    this.props.onStateUpdate(name, target.value);
  }

  updateStateValue(name, value) {
    let new_state = { [name]: value };
    if (name == "selected_market" && this.state.valid_referral) {
      new_state.valid_referral = null;
      this.setState(new_state);
      this.validateReferralCode(null, value);
    } else {
      this.setState(new_state);
    }


    this.props.onStateUpdate(name, value);
  }

  canContinue() {
    return (this.state.email && this.state.phone_number && this.state.valid_referral)
  }

  referralCodeInputStatus() {
    let className = this.state.valid_referral ? "disable-input" : "";

    if (this.state.error_referral_code) {
      className += " error";
    } else if (this.state.referral_details) {
      className += " success";
    }
    return className;
  }

  validateReferralCode(event, area_id) {
    area_id = area_id || this.state.selected_market;
    if (this.state.referral_code == this.props.account.slug) {
      this.setState({ error_referral_code: "You can not use your own referral code", referral_details: null });
      this.props.onStateUpdate('referral_details', null);
      return;
    }

    $.ajax({
      method: "POST",
      url: "/referral-code",
      data: { referral_code: this.state.referral_code, area_id },
      dataType: 'json',
      success: (data) => {
        this.setState({ valid_referral: this.state.referral_code, referral_details: data, error_referral_code: null });
        this.props.onStateUpdate('referral_details', data);
      },
      error: (error) => {
        this.setState({ error_referral_code: error.responseJSON.error, referral_details: null });
        this.props.onStateUpdate('referral_details', null);
      }
    });
  }

  removeReferralCode() {
    this.setState({ referral_code: "", valid_referral: undefined, referral_details: undefined })
  }

  validateAccountDetails() {
    var no_errors = true;
    var phone = this.state.phone_number.replace(/\D/g, '');
    if (phone && phone[0] == "1") {
      phone = phone.slice(1)
    }
    if (phone.length != 10) {
      no_errors = false;
      this.setState({ error_phone_number: "Invalid phone number" })
    }

    if (no_errors) {
      if (this.pending_submission) {
        return false;
      }
      this.pending_submission = true;

      $.ajax({
        method: "POST",
        url: "/account-validate",
        data: {
          reactivation_token: this.props.account.token,
          email: this.state.email,
          phone_number: this.state.phone_number,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          referral_code: this.state.valid_referral,
          selected_market: this.state.selected_market
        },
        dataType: 'json',
        success: (data) => {
          this.pending_submission = false;
          this.props.onStateUpdate("waitlist_id", data.waitlist_id);
          this.props.nextAction();
        },
        error: (error) => {
          this.pending_submission = false;
          for (let e of error.responseJSON.errors) {
            if (e.type == "email" || e.type == "invalid-email") {
              this.setState({ error_email: e.message });
            }
            if (e.type == "phone-number") {
              this.setState({ error_phone_number: e.message });
            }
          }
        }
      });
    }
  }

  renderReferral() {
    let button_text = "APPLY";
    let button_class = "sign-up-button-min inline";
    let onClick = this.validateReferralCode;

    if (this.state.valid_referral) {
      button_text = "REMOVE";
      button_class = "inline remove-referral"
      onClick = this.removeReferralCode;
    }

    return (
      <>
        <input type="text"
          id="input_referral_code"
          name="referral_code"
          placeholder="Enter Referral Code"
          autoCapitalize="none"
          className={this.referralCodeInputStatus()}
          onChange={this.changeInputField}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClick();
            }
          }}
          disabled={this.state.valid_referral ? true : false}
          value={this.state.referral_code} />
        <button
          id="submit_referral_code"
          className={button_class}
          onClick={onClick}>
          {button_text}
        </button>
      </>
    )
  }

  render() {
    return (
      <div className="sign-up-column">
        <div className="market-header">
          <div className="step-header">Confirm Your Info</div>
        </div>
        <div className="market-subtitle">
          We auto-filled your membership information from before you canceled.
        </div>
        <br />
        <CityBulletListSelect
            areas={this.state.areas}
            selected_area_id={this.state.selected_market}
            onChange={this.updateStateValue}
            className="sign-up"/>
        <div className="input-label">Email Address</div>
        <input type="text"
          id="input_email"
          name="email"
          placeholder="Email Address"
          onChange={this.changeInputField}
          value={this.state.email} />
        <div className="input-error">{this.state.error_email}</div>
        <br />

        <div className="input-label">Phone Number</div>
        <input type="text"
          id="input_phone_number"
          name="phone_number"
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder="Phone Number"
          onChange={this.changeInputField}
          value={this.state.phone_number} />
        <div className="input-error">{this.state.error_phone_number}</div>
        <div className="input-subtitle">Only real team members will text you...promise</div>
        <br />

        <div className="input-label">Referral Code</div>
        <div className="referral-container">
          {this.renderReferral()}
        </div>
        <div className="input-error">{this.state.error_referral_code}</div>
        <div className="input-success">{(this.state.referral_details ? "Your code has been successfully applied! 🥳" : "")}</div>
        <br />

        <div className="sign-up-nav-footer">
          <button
            id="submit_step_1"
            className={"sign-up-button-full " + (this.canContinue() ? "" : "inactive")}
            disabled={!this.canContinue()}
            onClick={this.validateAccountDetails}>
            CONTINUE →
          </button>
        </div>
      </div>
    );
  }
}

export default UserReactivateForm