import React from "react";
import OfferCard from "./offer_card";
import InvitationCard from "./invitation_card";

import MapIcon from 'icon-last-chance-map.svg';
import IconClose from "icon-close-bold.svg";
import IconUhOh from "icon-uh-oh.svg";
import IconMap from "icon-last-chance-map.svg";
import IconMessage from 'icon-message-box';
import IconFilter from 'icon-filter.svg';
import IconBackArrow from 'icon-button-back-arrow.svg';
import SurpriseMe from 'surprise-me-background.svg';
import OfferFilter from "./offer_filter";
import OfferInfoSlider from "./offer_info_slider";

class OfferChoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_new_offers_tab: true,
      categories: props.slot.categories.split(',').map(n => parseInt(n)),
      locations: props.slot.locations.split(',').map(n => parseInt(n)),
      offer_types: props.slot.offer_types.split(',').map(n => parseInt(n)),
      selected_friends: [props.account_id],
      show_offer_slider: false,
      offer_preview_id: null,
      show_offer_filter: false,
      offer_count: 0,
      show_last_chance_offers: true,
      show_repeat_offers: true,
      show_interested_friends: false,
      show_message: false,
      message_box_text: ""
    }

    this.ignore_order_interested = [];
    this.display_order_interested = [];
    this.menu_ref = React.createRef();
    this.setTab = this.setTab.bind(this);
    this.scroll_to_beginning = this.scroll_to_beginning.bind(this);
    this.surprise_me = this.surprise_me.bind(this);
    this.renderOfferChoice = this.renderOfferChoice.bind(this);
    this.selectCard = this.selectCard.bind(this);
    this.selectInvitationCard = this.selectInvitationCard.bind(this);
    this.toggle_message = this.toggle_message.bind(this);
    this.close_message = this.close_message.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.open_slider = this.open_slider.bind(this);
    this.close_slider = this.close_slider.bind(this);
    this.loaded_offer = this.loaded_offer.bind(this);
    this.open_filter = this.open_filter.bind(this);
    this.close_filter = this.close_filter.bind(this);
    this.update_live_count = this.update_live_count.bind(this);
    this.updateOfferData = this.updateOfferData.bind(this);
    this.get_filtered_offers = this.get_filtered_offers.bind(this);
    this.renderLastChanceInfoModal = this.renderLastChanceInfoModal.bind(this);
    this.renderSurpriseMe = this.renderSurpriseMe.bind(this);
  }

  componentDidMount() {
    this.update_live_count();
  }

  setTab(event) {
    let is_new_offers_tab = false;
    if (event.target.getAttribute('name') == "new-offers") {
      is_new_offers_tab = true;
    }

    this.setState({ is_new_offers_tab });
  }

  scroll_to_beginning() {
    if (this.menu_ref.current) {
      this.menu_ref.current.scrollLeft = 0;
    }
  }

  surprise_me(cards) {
    let offer = cards[Math.floor(Math.random() * cards.length)];
    this.selectCard(offer.id || offer.props.offer.id, true);
  }

  selectCard(offer_id, is_surprise_me = false) {
    // this.setState({selected_offer_choice: offer_id});
    this.props.update_offer_selection(this.props.slot_id, offer_id, this.props.is_last_chance, is_surprise_me);
    this.props.fetch_friends_to_invite(offer_id);
  }

  selectInvitationCard(invitation_id, offer_id) {
    this.props.update_offer_selection(this.props.slot_id, offer_id, false, false, invitation_id);
  }

  toggle_message(message_box_text) {
    let show_message = !this.state.show_message;

    this.setState({ show_message, message_box_text });
  }

  close_message() {
    this.setState({ show_message: false });
  }

  openModal() {
    if (this.props.openModal) {
      this.props.openModal(this.renderLastChanceInfoModal())
    }
  }

  closeModal() {
    if (this.props.closeModal) {
      this.props.closeModal();
    }
  }

  open_slider(offer_id) {
    this.setState({ show_offer_slider: true, offer_preview_id: offer_id });
  }

  loaded_offer(offer) {
    this.props.update_offer_cache(offer);
  }

  close_slider() {
    this.setState({ show_offer_slider: false });
  }

  open_filter() {
    this.setState({ show_offer_filter: true });
  }

  close_filter(data) {
    let update_state = { show_offer_filter: false };
    if (data) {
      Object.assign(update_state, data);
      update_state.offer_count = this.get_filtered_offers(data.locations, data.categories, data.offer_types, data.show_repeat_offers, data.show_last_chance_offers).length;
      this.scroll_to_beginning();
    }
    this.ignore_order_interested = [];
    this.display_order_interested = [];
    this.setState(update_state);
  }

  update_live_count(params) {
    let offer_count = 0;
    if (params) {
      offer_count = this.get_filtered_offers(params.locations, params.categories, params.offer_types, params.show_repeat_offers, params.show_last_chance_offers, params.selected_friends, params.show_interested_friends).length;
    } else {
      offer_count = this.get_filtered_offers().length;
    }
    this.setState({ offer_count });
  }

  updateOfferData(offer) {
    if (offer.interested.map(a => a.id).includes(this.props.account_id)) {
      this.ignore_order_interested.push(offer.id);
    } else {
      this.display_order_interested.push(offer.id);
    }

    this.props.updateOfferData(offer);
  }

  get_filtered_offers(locations = this.state.locations, categories = this.state.categories, offer_types = this.state.offer_types, show_repeat_offers = this.state.show_repeat_offers, show_last_chance_offers = this.state.show_last_chance_offers, selected_friends = this.state.selected_friends, show_interested_friends = this.state.show_interested_friends) {
    let cards = this.props.valid_offers;
    let order = this.props.slot.offer_types.split(',').map(n => parseInt(n));

    // This reordered things too much
    // cards = cards.sort((a, b) => {
    //   return order.indexOf(a.offer_type_id) - order.indexOf(b.offer_type_id);
    // });

    // Put cards in the order of the member's offer_types
    cards = [
      ...cards.filter(card => card.offer_type_id == order[0]),
      ...cards.filter(card => card.offer_type_id == order[1]),
      ...cards.filter(card => card.offer_type_id == order[2])
    ]

    let filtered_cards = [];
    if (show_interested_friends) {
      for (let card of cards) {
        for (let interested of card.interested) {
          if (selected_friends.includes(interested.id)) {
            filtered_cards.push(card);
            break;
          }
        }
      }
      return filtered_cards;
    }

    // Filter Locations
    // cards = cards.filter(card => locations.some(location_id => card.locations.includes(location_id)));
    for (let card of cards) {
      for (let location of card.locations) {
        if (locations.includes(location)) {
          filtered_cards.push(card);
          break;
        }
      }
    }
    cards = [...filtered_cards];

    // Filter Categories
    // cards = cards.filter(card => categories.some(category_id => card.categories.includes(category_id)));
    filtered_cards = [];
    for (let card of cards) {
      for (let category of card.categories) {
        if (categories.includes(category)) {
          filtered_cards.push(card);
          break;
        }
      }
    }
    cards = [...filtered_cards];

    // Filter Offer Types
    cards = cards.filter(card => offer_types.includes(card.offer_type_id));

    let last_chance_cards = [];
    let repeat_cards = [];
    let new_cards = [];
    let interested_cards = [];

    if (!show_last_chance_offers) {
      cards = cards.filter(offer => !this.props.last_chance_offers.includes(offer.identifier));
    } else {
      cards = cards.map(x => Object.assign({}, x));
      for (let card of cards) {
        if (this.props.last_chance_offers.includes(card.identifier)) {
          card.last_chance_offer = true;
          last_chance_cards.push(card);
        }
      }
      cards = cards.filter(c => !last_chance_cards.includes(c));
    }

    if (!show_repeat_offers) {
      cards = cards.filter(offer => !this.props.repeat_offers.includes(offer.identifier))
    } else {
      cards = cards.map(x => Object.assign({}, x));
      for (let card of cards) {
        if (this.props.repeat_offers.includes(card.identifier)) {
          card.repeat_offer = true;
          repeat_cards.push(card);
        }
      }
      cards = cards.filter(c => !repeat_cards.includes(c));
    }

    if (this.props.total_months_active > this.props.cutoff_new_member) {
      for (let card of cards) {
        if (card.is_new_offer) {
          new_cards.push(card);
        }
      }
      cards = cards.filter(c => !new_cards.includes(c));
    } else {
      cards = cards.map(x => Object.assign({}, x));
      cards.forEach(card => card.is_new_offer = false);
    }

    cards = cards.map(x => Object.assign({}, x));
    for (let card of cards) {
      if (this.ignore_order_interested.includes(card.id)) {
        continue;
      }
      if (this.display_order_interested.includes(card.id)) {
        interested_cards.push(card);
      } else if (card.interested.map(interest => interest.id).includes(this.props.account_id)) {
        interested_cards.push(card);
      }
    }
    let all_cards = [...new_cards, ...cards, ...last_chance_cards, ...repeat_cards];

    all_cards = all_cards.filter(c => !interested_cards.includes(c));
    all_cards = [...interested_cards, ...all_cards];

    all_cards.forEach((card, index) => {
      this.props.offers_going_fast.includes(card.identifier) ? card.going_fast_offer = true : card.going_fast_offer = false;
    });
    this.live_count = all_cards.length;

    return all_cards;
  }

  renderLastChanceInfoModal() {
    return (
      <div className="last-chance-info">
        <div className="modal-body">
          <img className="map" src={MapIcon} alt="" />
          <div className="flavor-text">Last chance Offers give you one additional chance to use a past expired Offer.</div>
          <div className="body-text">If you don't use this Offer this time around, it's gone forever, so be sure to select when you know you'll use!</div>
          <button className="offer-button-full full" onClick={this.closeModal}>DISMISS</button>
          <img className="close" src={IconClose} onClick={this.closeModal} alt="" />
        </div>
      </div>
    )
  }

  renderSurpriseMe(cards) {
    return (
      <div className="offer-card full-height surprise-me">
        <img src={SurpriseMe} className="surprise-background" alt="" />
        <div className="surprise-title">Can't Decide?</div>
        <div className="surprise-body">
          Tap below + <span className="embolden"> we'll pick for you based on the filters you have on right now. </span>It's like the cooler, local version of Google's “I'm Feeling Lucky” button.
        </div>
        <button className="offer-button-min surprise-button" onClick={() => { this.surprise_me(cards) }}>PICK FOR ME</button>
      </div>
    )
  }

  renderOfferChoice() {
    let source = this.props.valid_offers;

    if (this.state.is_new_offers_tab && source.length == 0) {
      return (
        <div className="in-offer-notification-container">
          <img className="in-offer-image" src={IconUhOh} alt=":(" />
          <div className="in-offer-title">UH-OH</div>
          <div className="in-offer-text">
            <p>We don't have any new Offers in the locations + categories you have selected.</p>
            <p>You can change your preferences in app if you want to try to expand your options.</p>
            <p><span className="in-offer-bold">Choose a Last Chance Offer above</span> if you don't want to change your preferences.</p>
          </div>
        </div>
      )
    }

    let cards = this.get_filtered_offers();
    let extra_cards = (<></>);

    if (cards.length == 0) {
      return (
        <div className="must-adjust-filter">
          <img src={IconMap} alt="" />
          <div className="adjust-title">No Offer matches found!</div>
          <div className="adjust-body">
            Please expand your preferences above and try searching again.
          </div>
        </div>
      )
    } else {
      extra_cards = (
        <>
          <div className="offer-card full-height want-more">
            <div className="emoji">🤔</div>
            <div className="more-title">Want More?</div>
            <div className="more-body">Filters let you expand the restaurants you can choose from. Check 'em out:</div>
            <button className="offer-button-full more-button" onClick={this.open_filter}>
              <img src={IconFilter} alt="" />
              <div>OPEN FILTERS</div>
            </button>
            <button className="scroll-back" onClick={this.scroll_to_beginning}>
              <img src={IconBackArrow} alt="" />
              <div>Scroll Back to Beginning</div>
            </button>
          </div>
          {this.renderSurpriseMe(cards)}
        </>
      )
    }

    cards = cards.map((offer, i) =>
      <OfferCard
        key={offer.id}
        token={this.props.token}
        account_id={this.props.account_id}
        index={i}
        offer={offer}
        is_selected={offer.id == this.props.selected_offer_choice && this.props.selected_invitation == null}
        full_height={true}
        on_select={this.selectCard}
        toggle_message={this.toggle_message}
        open_slider={this.open_slider}
        updateOfferData={this.updateOfferData}
      />
    );

    let invitations = this.props.invitations.map((invitation, i) =>
      <InvitationCard
        key={invitation.id}
        token={this.props.token}
        offer={invitation.offer}
        invite_id={invitation.id}
        invited_by={invitation.invited_by}
        invited_members={invitation.other_invited_members}
        is_selected={invitation.id == this.props.selected_invitation}
        on_select={this.selectInvitationCard}
        account_id={this.props.account_id}
        onDecline={this.props.removeInvite}/>
    );

    return (
      <div className={"offer-slide-background " + (this.props.is_last_chance ? "last-chance" : "")}>
        <div className="offer-slide-container" ref={this.menu_ref}>
          <div className="offer-slide-menu">
            {invitations}
            {cards}
            {extra_cards}
          </div>
        </div>
      </div>
    );
  }

  // componentDidUpdate(prev_props) {
  //   if (this.props.index != prev_props.index && this.menu_ref.current) {
  //     this.menu_ref.current.scrollLeft = 0;
  //     this.setState({ is_new_offers_tab: true });
  //   }

  //   // If we're not selecting a new offer, make sure that the selected card is in view
  //   if (!this.state.show_message && this.props.selected_offer_choice == prev_props.selected_offer_choice) {
  //     let selected = document.querySelector('.card-selected');
  //     if (selected) {
  //       selected.scrollIntoView({ block: 'nearest', inline: 'center' })
  //     }
  //   }
  // }

  render() {
    return (
      <>
        <div className={"choose-offers-title"}>
          <div>{"Choose Offer " + this.props.index}</div>
          <button className="filter-btn" onClick={this.open_filter}>
            <img src={IconFilter} alt="" />
            <div>Filter</div>
          </button>
        </div>
        <div className={"offer-tally" + (this.state.offer_count == 0 ? " zero-offers" : "")}>{this.state.offer_count + " Offers"}</div>
        {this.renderOfferChoice()}
        <div className="message-overlay" style={{ display: this.state.show_message ? "" : "none" }} onTouchMove={this.close_message} onWheel={this.close_message} onClick={this.close_message}>
          <div className="message-container">
            <div className="message-box">
              <div className="message-body">
                <div className="message-text">
                  {this.state.message_box_text}
                </div>
                <img src={IconMessage} alt="" />
              </div>
            </div>
          </div>
        </div>
        {this.state.show_offer_slider &&
          <OfferInfoSlider
            offer={this.props.offer_cache[this.state.offer_preview_id]}
            offer_id={this.state.offer_preview_id}
            close_slider={this.close_slider}
            loaded_offer={this.loaded_offer}
          />
        }
        <OfferFilter
          friends={this.props.friends}
          account_id={this.props.account_id}
          selected_friends={this.state.selected_friends}
          locations={this.state.locations}
          categories={this.state.categories}
          offer_types={this.state.offer_types}
          all_locations={this.props.all_locations}
          all_categories={this.props.all_categories}
          all_friends={this.props.all_friends}
          all_offer_types={this.props.all_offer_types}
          show_last_chance_offers={this.state.show_last_chance_offers}
          show_repeat_offers={this.state.show_repeat_offers}
          close_filter={this.close_filter}
          update_live_count={this.update_live_count}
          live_count={this.state.offer_count}
          show_offer_filter={this.state.show_offer_filter}
        />
      </>
    );
  }
}

export default OfferChoice