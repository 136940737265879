import React from "react";

// Network Requests
import {updateStripeCustomer, updatePeopleProfile, trackCioEvent} from "./utils/analytic_tracking.jsx";
import { getScreen } from "./signup_v4_screens.jsx";

class SignupV4 extends React.Component {
  constructor(props) {
    super(props);

    // Setup key variables
    let area = this.getAreaFromId(props.preselected_market_id) || { name: "Raleigh" };
    let when_to_start = new Date().getDate() <= 10 ? "today" : "defer";

    let currentScreen = props.currentScreen || 0;
    let signup_version = props.signup_version || "4.0";

    let markets = this.props.areas.map((area) => { return { label: (area.name + ", " + (area.state || "")), value: area.id, city: area.name } });

    let mixpanel_original_id = null;
    if (analytics_loaded) {
      mixpanel_original_id = mixpanel.get_distinct_id();
    }
    // Setup state
    this.state = {
      currentScreen,
      signup_version,
      mixpanel_original_id,
      email: "",
      phone_number: "",
      first_name: "",
      last_name: "",
      reason_to_become_member: "",
      supporting_local: "",
      valid_referral: props.referral_details?.referral?.referral_code || "",
      referral_details: props.referral_details,
      selected_plan: props.selected_plan || "monthly",
      selected_area: area,
      markets,
      deferral_only: area.deferral_only,
      when_to_start: when_to_start,
      waitlist_id: null,
      setup_intent: props.setup_intent,
      bad_fit: false,
      version_test_tags: props.version_test_tags || [],
    }

    // bind methods
    this.getAreaFromId = this.getAreaFromId.bind(this);
    this.updateArea = this.updateArea.bind(this);
    this.updateStateAndProgress = this.updateStateAndProgress.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.createCioProgressCallback = this.createCioProgressCallback.bind(this);

    this.markets = this.props.areas.map((area) => { return { label: (area.name + ", " + (area.state || "")), value: area.id, city: area.name } });

    this.screen_order = [
      "SignupV4Gist",
      "SignupV4ProfileName",
      "SignupV4PhoneNumber",
      "SignupV4MemberQuestions",
      "SignupV4Subscription",
      "SignupV4Payment"
    ]

    if (analytics_loaded) {
      mixpanel.register({
        "Market": area.name,
        "Signup Version": signup_version,
      });
      posthog.register({
        "Market": area.name,
        "Signup Version": signup_version,
      });
    }
  }

  componentDidMount() {
    if (analytics_loaded) {
      setTimeout(() => {
        posthog.reloadFeatureFlags();
      }, 1550);
    }
  }

  previousStep() {
    this.setState({ currentScreen: Math.max(0, this.state.currentScreen - 1) });
  }

  updateStateAndProgress(updated_state_data, update_screen = true) {
    if (update_screen) {
      updated_state_data.currentScreen = this.state.currentScreen + 1;
    }
    this.setState(updated_state_data);
    updateStripeCustomer(this, updated_state_data);
    updatePeopleProfile(this, updated_state_data);
  }

  createCioProgressCallback(step_completed) {
    return () => {
      trackCioEvent(this, step_completed);
    }
  }

  // helper methods
  getAreaFromId(id) {
    let areas = this.props.areas || [];
    return areas.filter(area => area.id == id)[0];
  }

  updateArea(action, area_id) {
    let area = this.getAreaFromId(area_id);
    let update_state = {
      selected_area: area,
      deferral_only: area.deferral_only,
    }

    let new_url = '/signup' + this.props.signup_version + '/' + area.slug + window.location.search
    history.pushState({ pageID: area.slug }, area.slug, new_url);
    this.setState(update_state);

    if (analytics_loaded) {
      posthog.capture('Area Changed', {
        "Area": area.name,
      });
      setTimeout(() => {
        posthog.reloadFeatureFlags();
      }, 2050);
    }
  }

  getCurrentScreenCode() {
    if (this.state.bad_fit) {
      return "SignupV4BadFit"
    }
    return this.screen_order[this.state.currentScreen];
  }

  render() {
    let screen = getScreen(this.getCurrentScreenCode(), this)
    return (
      <div className="signup-body">
        <div className={"signup-content"}>
          {screen}
        </div>
      </div>
    );
  }
}

export default SignupV4