export const checkEqualPreferenceData = (pref1, pref2) => {
  if (!pref1 || !pref2) {
    return false;
  }
  if (!compareArrays(pref1.categories, pref2.categories)) {
    return false;
  }
  if (!compareArrays(pref1.locations, pref2.locations)) {
    return false;
  }
  return true;
};

export const checkEqualPreferences = (pref1, pref2) => {
  if (!pref1 || !pref2) {
    return false;
  }
  if (!compareArrays(pref1.cached_category_ids, pref2.cached_category_ids)) {
    return false;
  }
  if (!compareArrays(pref1.cached_location_ids, pref2.cached_location_ids)) {
    return false;
  }
  return true;
};

export const compareArrays = (array1, array2) => {
  // If either is blank or falsy, 
  if (!array1 || !array2) {
    return false;
  }
  if (array1.length != array2.length) {
    return false;
  }
  // Compare each element
  for (var i = 0, l=array1.length; i < l; i++) {
    if (array1[i] != array2[i]) { 
      return false;   
    }           
  }       
  return true;
};