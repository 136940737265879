import React from "react";
import ProgressBar from "../../../shared/progress_bar";
import SignupHeader from "./signup_header";
import SignupFooter from "./signup_footer";
import { getIDforGTM } from "../state_components/gtm_methods";

import ArrowRight from "icon-arrow-right.svg";
import BackIcon from "icon-back-left.svg";

import CheckboxIcon from "icon-checkbox.svg";
import Chef from "icon-chef.svg";
import Piggybank from "icon-piggybank.svg";
import Alarmclock from "icon-alarmclock.svg";

class MembershipOverview extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.scrollingElement.scrollTop = 0;
    if (analytics_loaded) {
      mixpanel.track("Signup: Membership Overview");
      posthog.capture("Signup: Membership Overview");
    }
  }

  render() {
    let header = (<SignupHeader
      constrain_middle="100px"
      mobile_left={this.props.currentScreen == 0 ? (null) : (<img className="header-back-arrow-button" id={getIDforGTM('back-to-previous-screen', this.props)} src={BackIcon} alt="Back" onClick={this.props.previousScreen} />)}
      mobile_center={<ProgressBar step={this.props.currentScreen + 1} total_steps={this.props.total_screens + 1} />}
      desktop_left={this.props.city_logo_img}
      desktop_right={<ProgressBar step={this.props.currentScreen + 1} total_steps={this.props.total_screens + 1} />}
    />);

    let footer = (<SignupFooter
      center={
        <div className="membership-continue-button">
          <button id={getIDforGTM('continue-to-next-screen', this.props)} className={"full-blue-button smaller-text squished-text"} onClick={this.props.progressToNextScreen}>
            Pick 2 Restaurants
          </button>
          <div className='footer-sub-message text-center membership-info-link'>still got questions? <a href="https://www.letsgetoffline.com/" target="_blank">Learn more about Offline</a>.</div>
        </div>
      }
      bottom={
        this.props.currentScreen == 0 ? (null) : (<img className="header-back-arrow-button" id={getIDforGTM('back-to-previous-screen', this.props)} src={BackIcon} alt="Back" onClick={this.props.previousScreen} />)
      }
      sticky={true}
      />);


    return (
      <>
        {header}
        <div className="signup-v2-column text-center mobile-text-left">
          <div className="membership-info-title">What does an Offline membership include?</div>
          <div className='membership-info-subtitle'>Choose 2 local restaurants and we’ll make sure you actually try them</div>

          <div className='narrow-column'>
            <div className="bordered-block-grouping text-center spacer">
              <div className="bordered-block">
                <img src={Chef} alt=""/>
                <h4>2 restaurants<br/> / month</h4>
              </div>
              <div className="bordered-block">
                <img src={Piggybank} alt=""/>
                <h4>$15-30 to spend<br/> @ each</h4>
              </div>
            </div>
            <div className='bordered-block-grouping'>
              <div className="bordered-block text-left">
                <div className='header'>
                  <h4>All for $15 / month</h4>
                  <span className='tag'>First Month Free</span>
                </div>
                <div className='message faded-text'><img src={Alarmclock} alt=""/>We’ll always remind you before your trial ends!</div>
              </div>
            </div>
            <div className="signup-v2-list spacer">
              <h3 style={{marginBottom: "12px"}}>Additional Benefits</h3>
              <div><img src={CheckboxIcon} alt=""/>New restaurants every month</div>
              <div><img src={CheckboxIcon} alt=""/>Membership pays for itself</div>
              <div><img src={CheckboxIcon} alt=""/>Curated, local restaurants</div>
              <div><img src={CheckboxIcon} alt=""/>Monthly Member-exclusive events</div>
              <div><img src={CheckboxIcon} alt=""/>Skip a month or cancel any time</div>
              <div><img src={CheckboxIcon} alt=""/>Rockstar support 7 days/week</div>
            </div>
          </div>
        </div>
        {footer}
      </>
    );
  }
}

export default MembershipOverview