import React from "react";
import MembershipInformationGUI from "../gui_components/membership_information_gui";
import ReferralCodeInput from "./referral_code_input";
import SignupHeader from "../gui_components/signup_header";
import SignupFooter from "../gui_components/signup_footer";
import ProgressBar from "../../../shared/progress_bar";
import NotGoodFit from "../gui_components/not_good_fit";
import { getIDforGTM } from "./gtm_methods";

import BackIcon from "icon-back-left.svg";
import ArrowRight from "icon-arrow-right.svg";

class MembershipInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email || "",
      phone_number: this.props.phone_number || "",
      first_name: this.props.first_name || "",
      last_name: this.props.last_name || "",
      referral_code: this.props.valid_referral || "",
      valid_referral: this.props.valid_referral || "",
      referral_details: this.props.referral_details || null,
      reason_to_become_member: this.props.reason_to_become_member || "",
      supporting_local: this.props.supporting_local || "",
      bad_fit: false
    }

    this.pending_submission = false;
    this.canContinue = this.canContinue.bind(this);
    this.changeState = this.changeState.bind(this);
    this.validateAccountDetails = this.validateAccountDetails.bind(this);
    this.receiveReferralDetails = this.receiveReferralDetails.bind(this);
    this.clearedReferralCode = this.clearedReferralCode.bind(this);
    this.setMixpanelAquisition = this.setMixpanelAquisition.bind(this);
  }

  componentDidMount() {
    document.scrollingElement.scrollTop = 0;
    this.setMixpanelAquisition(this.props.referral_details);
  }

  setMixpanelAquisition(referral_details) {
    if (analytics_loaded && referral_details) {
      var aquistion = "Partner referral";
      if (referral_details.type == "user") {
        aquistion = "Account referral";
      } else if (referral_details.type == "giftcode") {
        aquistion = "Gift code";
      }
      mixpanel.people.set({"Acquisition Type": aquistion});
    }
  }

  changeState(key, value) {
    this.setState({ [key]: value });
  }

  receiveReferralDetails(referral_code, referral_details) {
    this.setMixpanelAquisition(referral_details);
    this.setState({ referral_details: referral_details, valid_referral: referral_code, referral_code: referral_code });
  }

  clearedReferralCode() {
    this.setState({ referral_details: null, valid_referral: "", referral_code: "" });
  }

  canContinue() {
    return (
      this.state.email && 
      this.state.phone_number && 
      this.state.valid_referral && 
      this.state.first_name && 
      this.state.last_name &&
      this.state.reason_to_become_member &&
      this.state.supporting_local
    )
  }

  validateAccountDetails() {

    var no_errors = true;
    var phone = this.state.phone_number.replace(/\D/g, '');
    if (phone && phone[0] == "1") {
      phone = phone.slice(1)
    }
    if (phone.length != 10) {
      no_errors = false;
      this.setState({ error_phone_number: "Invalid phone number" })
    }

    if (this.state.supporting_local == "tipping_is_questionable" || this.state.reason_to_become_member == "save_money") {
      this.setState({bad_fit: true});
      no_errors = false;
    }

    if (analytics_loaded) {
      mixpanel.people.set({
        "$email": this.state.email,
        "$name": this.state.first_name + " " + this.state.last_name,
        "$phone": `+1${phone}`,
        "First Name": this.state.first_name,
        "Last Name": this.state.last_name,
        "Referral Code Used During Signup": this.state.valid_referral,
        "Supporting Local": this.state.supporting_local,
        "Number One Reason": this.state.reason_to_become_member
      })
    }

    if (no_errors) {
      if (this.pending_submission) {
        return false;
      }
      this.pending_submission = true;

      $.ajax({
        method: "POST",
        url: "/signup2/account-submit",
        data: {
          email: this.state.email,
          phone_number: this.state.phone_number,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          valid_referral: this.state.valid_referral,
          reason_to_become_member: this.state.reason_to_become_member,
          supporting_local: this.state.supporting_local,
          area_id: this.props.selected_area.id,
          start_immediately: this.props.when_to_start,
          selected_offer_1: this.props.selected_offer_1,
          selected_offer_2: this.props.selected_offer_2,
          waitlist_id: this.props.waitlist_id,
          intent_id: this.props.setup_intent?.id,
          customer_id: this.props.setup_intent?.customer
        },
        dataType: 'json',
        success: (data) => {
          this.pending_submission = false;
          let data_to_update = {
            email: this.state.email,
            phone_number: this.state.phone_number,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            valid_referral: this.state.valid_referral,
            reason_to_become_member: this.state.reason_to_become_member,
            supporting_local: this.state.supporting_local
          };
          if (data.waitlist_id) {
            data_to_update.waitlist_id = data.waitlist_id;
          }
          // if (data.intent) {
          //   data_to_update.setup_intent = data.intent;
          //   if (analytics_loaded) {
          //     if (mixpanel.get_distinct_id() != data.intent.customer) {
          //       mixpanel.alias(data.intent.customer, mixpanel.get_distinct_id());
          //       mixpanel.identify(data.intent.customer);
          //       posthog.identify(data.intent.customer);
          //     }
          //   }
          // }

          this.props.updateState(data_to_update, true);
        },
        error: (error) => {
          this.pending_submission = false;
          for (let e of error.responseJSON.errors) {
            if (e.type == "email" || e.type == "invalid-email") {
              this.setState({ error_email: e.message });
            }
            if (e.type == "phone-number") {
              this.setState({ error_phone_number: e.message });
            }
          }
        }
      });
    }
  }


  render() {
    if (this.state.bad_fit) {
      let instagram_link = this.props.selected_area.instagram_link || "https://www.instagram.com/offlineraleighdurham/";
      return (
        <>
          <NotGoodFit
            reason_to_become_member={this.state.reason_to_become_member}
            supporting_local={this.state.supporting_local}
            selected_area={this.props.selected_area}
            />
          <SignupFooter
          center={
            <a href={instagram_link} style={{textDecoration: "none"}}>
              <button id={getIDforGTM('go-to-instagram', this.props)} className={"full-blue-button smaller-text"}>
                Take me to Instagram
                <img src={ArrowRight} alt="" className="signup-desktop-styling" />
              </button>
            </a>
          }
          sticky={true}/>
        </>
      )
    }

    return (
      <>
        <SignupHeader
          mobile_left={this.props.currentScreen == 0 ? (null) : (<img className="header-back-arrow-button" id={getIDforGTM('back-to-previous-screen', this.props)} src={BackIcon} alt="Back" onClick={this.props.previousScreen} />)}
          mobile_center={<ProgressBar step={this.props.currentScreen + 1} total_steps={this.props.total_screens + 1} />}
          desktop_left={this.props.city_logo_img}
          desktop_right={<ProgressBar step={this.props.currentScreen + 1} total_steps={this.props.total_screens + 1} />}
        />
        <MembershipInformationGUI
          email={this.state.email}
          error_email={this.state.error_email}
          phone_number={this.state.phone_number}
          error_phone_number={this.state.error_phone_number}
          first_name={this.state.first_name}
          last_name={this.state.last_name}
          reason_to_become_member={this.state.reason_to_become_member}
          supporting_local={this.state.supporting_local}
          onStateUpdate={this.changeState}>
            <ReferralCodeInput
              referral_code={this.state.referral_code}
              valid_referral={this.state.valid_referral}
              referral_details={this.state.referral_details}
              error_referral_code={this.state.error_referral_code}
              onRefferalSubmit={this.receiveReferralDetails}
              onReferralClear={this.clearedReferralCode}
              signup_version={this.props.signup_version}
              gtm_screen_name={this.props.gtm_screen_name}
              version_test_tags={this.props.version_test_tags}/>
        </MembershipInformationGUI>
        <SignupFooter
          center={
            <button
              className={"full-blue-button smaller-text " + (this.canContinue() ? "" : "inactive")}
              disabled={this.canContinue() ? "" : "disabled"}
              onClick={this.validateAccountDetails}
              id={getIDforGTM('submit-membership-info', this.props)}>
              CONTINUE
              <img src={ArrowRight} alt="" className="signup-desktop-styling" />
            </button>
          }
          bottom={
            this.props.currentScreen == 0 ? (null) : (<img className="header-back-arrow-button" id={getIDforGTM('back-to-previous-screen', this.props)} src={BackIcon} alt="Back" onClick={this.props.previousScreen} />)
          }
          />
      </>
    );
  }
}

export default MembershipInformation