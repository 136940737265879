import React from "react";

// Image Assets
import CityLogo from 'logo-city.png'
import CharlotteLogo from 'logo-charlotte.png'
import RaleighLogo from 'logo-raleigh.png'
import DurhamLogo from 'logo-durham.png'

// React Components
import CYOOFlow from './v2/state_components/cyoo/cyoo_flow.jsx'
import MembershipInformation from "./v2/state_components/memebership_information.jsx";
import MembershipOverview from "./v2/gui_components/membership_overview.jsx";
import PaymentInput from "./v2/state_components/payment_input.jsx";
import UserReferralLandingScreen from "./v2/state_components/user_referral_landing_screen.jsx";
import LandingLoadingScreen from "./v2/gui_components/landing_loading_screen.jsx";

// Network Requests
import { retrieveSignupOffers, createOrUpdateStripeCustomer } from "./v2/state_components/network_methods.jsx";

class SignupV2 extends React.Component {
  constructor(props) {
    super(props);

    // Setup key variables
    let area = this.getAreaFromId(props.selected_market)
      || { name: "Raleigh" };
    let membership_start = new Date().getDate() <= 10 ? "today" : "defer";
    this.city_logos = { CityLogo, CharlotteLogo, RaleighLogo, DurhamLogo }
    let currentScreen = props.currentScreen || 0;
    let signup_version = props.signup_version || "2.0";

    if (analytics_loaded) {
      mixpanel.register({
        "Market": area.name,
        "Signup Version": signup_version,
      });
      posthog.register({
        "Market": area.name,
        "Signup Version": signup_version,
      });
    }
    
    let mixpanel_original_id = null;
    if (analytics_loaded) {
      mixpanel_original_id = mixpanel.get_distinct_id();
    }
    // Setup state
    this.state = {
      currentScreen: props.currentScreen || 0,
      selected_market: props.selected_market || 2,
      mixpanel_original_id,
      email: "",
      phone_number: "",
      first_name: "",
      last_name: "",
      reason_to_become_member: "",
      supporting_local: "",
      valid_referral: props.referral_details?.referral?.referral_code || "",
      referral_details: props.referral_details,
      selected_plan: props.selected_plan,
      selected_area: area,
      deferral_only: area.deferral_only,
      membership_start,
      offer_information: props.offer_information,
      selected_offer_1: null,
      selected_offer_2: null,
      when_to_start: null,
      waitlist_id: null,
      setup_intent: null,
      city_logo: this.city_logos[area.name + "Logo"] || CityLogo,
      network_loading_offers: false,
      signup_version,
      version_test_tags: props.version_test_tags || [],
    }

    // bind methods
    this.loadOffers = this.loadOffers.bind(this);
    this.afterRetrieveSignupOffers = this.afterRetrieveSignupOffers.bind(this);
    this.getScreenDefinitions = this.getScreenDefinitions.bind(this);
    this.getNextScreen = this.getNextScreen.bind(this);
    this.previousScreen = this.previousScreen.bind(this);
    this.progressToNextScreen = this.progressToNextScreen.bind(this);
    this.getAreaFromId = this.getAreaFromId.bind(this);
    this.loadScreen = this.loadScreen.bind(this);
    this.updateArea = this.updateArea.bind(this);
    this.pick_offers = this.pick_offers.bind(this);
    this.finalize_offers = this.finalize_offers.bind(this);
    this.updateState = this.updateState.bind(this);

    // Setup Screen Definitions
    const [screens, screens_order_lookup, default_screen_props]
      = this.getScreenDefinitions(props);
    this.screens = screens;
    this.screens_order_lookup = screens_order_lookup;
    this.default_screen_props = default_screen_props;
    this.state["currentScreenName"] = screens_order_lookup[currentScreen].page_name;

    // Setup Offers cache
    this.offers_cache = {};
  }

  componentDidMount() {
    this.loadOffers();
  }

  updateState(updated_state_data, update_screen = false) {

    if (update_screen) {
      let [next_screen_id, next_screen_name] = this.getNextScreen();
      updated_state_data.currentScreen = next_screen_id;
      updated_state_data.currentScreenName = next_screen_name;
    }
    this.setState(updated_state_data);
    this.updateStripeCustomer(updated_state_data);
  }

  updateStripeCustomer(params_being_updated) {
    let state_copy = Object.assign({}, this.state);
    Object.assign(state_copy, params_being_updated);
    let params = {
      email: state_copy.email,
      phone_number: state_copy.phone_number,
      first_name: state_copy.first_name,
      last_name: state_copy.last_name,
      valid_referral: state_copy.valid_referral,
      plan_id: state_copy.plan_id,
      reason_to_become_member: state_copy.reason_to_become_member,
      supporting_local: state_copy.supporting_local,
      area_id: state_copy.selected_area.id,
      start_immediately: state_copy.when_to_start,
      selected_offer_1: state_copy.selected_offer_1,
      selected_offer_2: state_copy.selected_offer_2,
      waitlist_id: state_copy.waitlist_id,
      intent_id: state_copy.setup_intent?.id,
      customer_id: state_copy.setup_intent?.customer
    };

    createOrUpdateStripeCustomer(params, (data) => {
      if (data.intent) {
        this.setState({ setup_intent: data.intent })
        if (analytics_loaded) {
          if (mixpanel.get_distinct_id() != data.intent.customer) {
            mixpanel.alias(data.intent.customer, mixpanel.get_distinct_id());
            mixpanel.identify(data.intent.customer);
            posthog.identify(data.intent.customer);
          }
        }
      }
    });
  }


  loadOffers(area = this.state.selected_area) {
    // If we have already loaded the offers for this area,
    // then we can use the cached version.
    if (this.offers_cache[area.abbreviation]) {
      this.afterRetrieveSignupOffers(this.offers_cache[area.abbreviation], area);
      return;
    }

    this.setState({ network_loading_offers: true })
    retrieveSignupOffers(area, this.afterRetrieveSignupOffers);
  }

  afterRetrieveSignupOffers(offer_information, area) {
    let update_state = {
      offer_information,
      network_loading_offers: false,
      selected_area: area,
      selected_market: area.id,
      deferral_only: area.deferral_only,
    };
    
    this.offers_cache[this.state.selected_area.abbreviation] = offer_information;
    this.setState(update_state);
  }

  getScreenDefinitions(props) {
    /* ==================================================
      Define which screens can be loaded.
      Each screen has a component and specific_props to load.
      We will combine the specific_props with a default set of
      props, "default_screen_props".
    ===================================================== */
    const screens = {
      LOADING: {
        component: LandingLoadingScreen,
        page_name: "LandingLoadingScreen",
        gtm_screen_name: "loading-screen",
        specific_props: {}
      },
      MEMBERSHIP_OVERVIEW: {
        component: MembershipOverview,
        page_name: "MembershipOverview",
        gtm_screen_name: "membership-overview",
        specific_props: { 
          progressToNextScreen: this.progressToNextScreen,
        },
        specific_props_from_state: {
          currentScreen: "currentScreen",
        }
      },
      USER_REFERRAL_LANDING_SCREEN: {
        component: UserReferralLandingScreen,
        page_name: "UserReferralLandingScreen",
        gtm_screen_name: "membership-overview",
        specific_props: { 
          update_area: this.updateArea,
          pick_offers: this.pick_offers,
          areas: props.areas,
        },
        specific_props_from_state: {
          network_busy: "network_loading_offers",
          selected_area_id: "selected_market",
          referral_details: "referral_details",
          currentScreen: "currentScreen",
        }
      },
      CYOO_FLOW: {
        component: CYOOFlow,
        page_name: "CYOOFlow",
        gtm_screen_name: "cyoo-flow",
        specific_props: { finalize_offers: this.finalize_offers },
        specific_props_from_state: {
          // component prop name : state_key
          offer_information: "offer_information",
          selected_offer_1: "selected_offer_1",
          selected_offer_2: "selected_offer_2",
          when_to_start: "when_to_start",
          deferral_only: "deferral_only",
          currentScreen: "currentScreen",
        }
      },
      INITIAL_USER_INFORMATION: {
        component: MembershipInformation,
        page_name: "MembershipInformation",
        gtm_screen_name: "membership-information",
        specific_props: {
          updateState: this.updateState,
          selected_area: this.state.selected_area,
        },
        specific_props_from_state: {
          email: "email",
          phone_number: "phone_number",
          first_name: "first_name",
          last_name: "last_name",
          valid_referral: "valid_referral",
          referral_details: "referral_details",
          reason_to_become_member: "reason_to_become_member",
          supporting_local: "supporting_local",
          selected_offer_1: "selected_offer_1",
          selected_offer_2: "selected_offer_2",
          when_to_start: "when_to_start",
          selected_area: "selected_area",
          waitlist_id: "waitlist_id",
          setup_intent: "setup_intent",
          currentScreen: "currentScreen",
        }
      },
      PAYMENT_INFORMATION: {
        component: PaymentInput,
        page_name: "PaymentInput",
        gtm_screen_name: "payment-screen",
        specific_props: {
          stripe_key: props.stripe_key,
          confirmation_path: props.confirmation_path,
          updateState: this.updateState
        },
        specific_props_from_state: {
          setup_intent: "setup_intent",
          phone_number: "phone_number",
          waitlist_id: "waitlist_id",
          currentScreen: "currentScreen",
          area: "selected_area",
          valid_referral: "valid_referral",
        }
      }
    }

    /*
      We use the screen's order value to create an array
      of screens in the correct order. This is so we can
      easily iterate through the screens in order.
    */

    const signup_screen_order = props.signup_screen_order || [
      "MEMBERSHIP_OVERVIEW",
      "CYOO_FLOW",
      "INITIAL_USER_INFORMATION",
      "PAYMENT_INFORMATION"
    ];

    const screens_order_lookup = props.signup_screen_order.map(screen_name => screens[screen_name]);

    // These props will be passed to every screen
    const default_screen_props = {
      previousScreen: this.previousScreen,
      city_logo_img: <a href={`https://www.letsgetoffline.com/join${location.search}`} target="_blank"><img className="signup-header-nav-image" src={this.state.city_logo} /></a>,
      total_screens: screens_order_lookup.length,
    }

    return [screens, screens_order_lookup, default_screen_props]
  }

  // helper methods
  getAreaFromId(id) {
    let areas = this.props.areas || [];
    return areas.filter(area => area.id == id)[0];
  }

  // screen navigation methods
  getNextScreen() {
    let currentScreen = this.state.currentScreen;
    let nextScreen = this.screens_order_lookup[currentScreen + 1];
    if (!nextScreen) return [this.state.currentScreen, this.state.currentScreenName];
    return [this.state.currentScreen + 1, nextScreen.page_name];
  }

  previousScreen() {
    let currentScreen = this.state.currentScreen;
    let previousScreen = this.screens_order_lookup[currentScreen - 1];
    if (!previousScreen) return [this.state.currentScreen, this.state.currentScreenName];
    this.setState({ currentScreen: this.state.currentScreen - 1, currentScreenName: previousScreen.page_name });
  }

  progressToNextScreen() {
    let currentScreen = this.state.currentScreen;
    let nextScreen = this.screens_order_lookup[currentScreen + 1];
    if (!nextScreen) return [this.state.currentScreen, this.state.currentScreenName];
    this.setState({ currentScreen: this.state.currentScreen + 1, currentScreenName: nextScreen.page_name });
  }

  loadScreen(id) {
    let screen = this.screens_order_lookup[id];
    if (!screen || !screen.component) return (<></>);
    let ScreenComponent = screen.component;
    let screenProps = {
      ...this.default_screen_props,
      ...screen.specific_props
    };

    if (screen.specific_props_from_state) {
      for (let [key, value] of Object.entries(screen.specific_props_from_state)) {
        screenProps[key] = this.state[value]
      }
    }

    // Load gtm id information
    screenProps["gtm_screen_name"] = screen.gtm_screen_name
    screenProps["signup_version"] = this.state.signup_version
    screenProps["version_test_tags"] = this.state.version_test_tags

    return <ScreenComponent {...screenProps} />;
  }

  updateArea(area_id) {
    let area = this.getAreaFromId(area_id);
    this.loadOffers(area);
  }

  finalize_offers(offer_id_1, offer_id_2, when_to_start) {
    let [currentScreen, currentScreenName] = this.getNextScreen();

    this.setState({ selected_offer_1: offer_id_1, selected_offer_2: offer_id_2, when_to_start, currentScreen, currentScreenName })
    this.updateStripeCustomer({ selected_offer_1: offer_id_1, selected_offer_2: offer_id_2, when_to_start, currentScreen, currentScreenName });
  }

  pick_offers() {
    let [currentScreen, currentScreenName] = this.getNextScreen();
    this.updateStripeCustomer({});
    this.setState({currentScreen, currentScreenName })
  }
  

  render() {
    return (
      <div className="signup-v2-body">
        <div className={"signup-v2-content " + this.state.currentScreenName}>
          {this.loadScreen(this.state.currentScreen)}
        </div>
      </div>
    );
  }
}

export default SignupV2