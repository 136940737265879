import React from "react";
import FormBuilder from "../../shared/form_builder";

class BaseInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: this.props.account.first_name,
      last_name: this.props.account.last_name,
      email: this.props.account.email,
      phone_number: this.props.account.phone_number,
      social_handles: this.props.account.social_handles,
      notes: this.props.account.notes,
      on_notice: this.props.account.on_notice
    };

    this.commit_updates = this.commit_updates.bind(this);
    this.input_update = this.input_update.bind(this);
  }

  commit_updates() {
    let path = `/accounts/${this.props.account.slug}`;

    let form_builder = new FormBuilder();
    let form_object = {
      account: {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_number: this.state.phone_number,
        social_handles: this.state.social_handles,
        notes: this.state.notes,
        on_notice: this.state.on_notice
      }
    };

    var form_data = form_builder.createForm(form_object);

    $.ajax({
      method: "PATCH",
      url: path,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        showFlashMessage("Account updated");
        // this.setState({ event_assignments })
      },
      error: (error) => {
        showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }

  input_update(e) {
    let target = e.target;
    let name = target.id;
    name = name ? name.replace("account_", "") : "";
    if (!name) return;
    this.setState({ [name]: target.value });
  }

  render() {
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    return (
      <>
        <h2 onClick={() => {
          let collapse_action = this.props.collapse_action;
          collapse_action.action(collapse_action.args);
        }}>
          Main Account Info:{" "}
          <span
            className={caret_style}
            data-section-id="member-app-section"
          />
        </h2>
        <br />
        <div style={{ "textAlign": "center", ...collapsed_style }}>
          <div>
            <div style={{ "display": "inline-block", "marginRight": "4px" }}>
              <div><label className="builder__input-label" htmlFor="account_first_name">First name</label></div>
              <input className="input" type="text" value={this.state.first_name} onChange={this.input_update} name="account[first_name]" id="account_first_name">
              </input>
            </div>
            <div style={{ "display": "inline-block", "marginRight": "4px" }}>
              <div><label className="builder__input-label" htmlFor="account_last_name">Last name</label></div>
              <input className="input" type="text" value={this.state.last_name} onChange={this.input_update} name="account[last_name]" id="account_last_name">
              </input>
            </div>

            <div style={{ "display": "inline-block", "marginRight": "4px" }}>
              <div><label className="builder__input-label" htmlFor="account_email">Email</label></div>
              <input className="input" type="text" value={this.state.email} onChange={this.input_update} name="account[email]" id="account_email">
              </input>
            </div>
            <div style={{ "display": "inline-block", "marginRight": "4px" }}>
              <div><label className="builder__input-label" htmlFor="account_phone_number">Phone number</label></div>
              <input className="input" type="text" value={this.state.phone_number} onChange={this.input_update} name="account[phone_number]" id="account_phone_number">
              </input>
            </div>
            <div style={{ "display": "inline-block", "marginRight": "4px" }}>
              <div><label className="builder__input-label" htmlFor="account_social_handles">Social handles</label></div>
              <input className="input" type="text" value={this.state.social_handles} onChange={this.input_update} name="account[social_handles]" id="account_social_handles">
              </input>
            </div>
          </div>
          <div style={{ "marginTop": "10px" }}>
            <div style={{ "display": "inline-block", "marginRight": "4px", "width": "50%" }}>
              <div><label className="builder__input-label" htmlFor="account_notes">Notes</label></div>
              <textarea className="input" style={{ "height": "5em", "width": "100%" }} value={this.state.notes} onChange={this.input_update} name="account[notes]" id="account_notes"></textarea>
            </div>
            <div style={{ "display": "inline-block", "marginRight": "4px", "verticalAlign": "top" }}>
              <div><label className="builder__input-label" htmlFor="account_on_notice">On Notice (YYYY-MM-DD)</label></div>
              <input className="input" type="text" value={this.state.on_notice} onChange={this.input_update} name="account[on_notice]" id="account_on_notice" />
            </div>
          </div>
          <input type="submit" onClick={this.commit_updates} name="commit" value="Update" className="admin__btn--create-new" style={{ "marginTop": "10px", "padding": "0px 40px", "paddingBottom": "0px", "paddingLeft": "40px", "paddingRight": "40px", "paddingTop": "0px" }} data-disable-with="Update" />
        </div>
      </>
    );
  }
}

export default BaseInfo;