import React from "react";
import iconSelected from 'icon-selected.svg'
import { getSubscriptionCardMessaging, getSubscriptionTermsText } from "./sign_up_user_messages";
import { convertIntegerToCleanPrice } from '../../helpers/price_formatter';
import { convertDateString } from '../../helpers/datestring_formatter'
import WrappingTooltip from "../signup_vx/shared/wrapping-tooltip";
import FreeTrialBanner from "./free_trial_banner";
import PlanComparison from "./plan_comparison";
import AnnualBenefitSummary from "./annual_benefit_summary";
import ExtendedFooterContent from "./extended_footer_content";
import PaysForItselfSection from "./sign_up_sections/pays_for_itself_section";
import JoinTheMovementSection from "./sign_up_sections/join_the_movement_section";
import MessagingSection from "./sign_up_sections/messaging_section";
import AnnualConversionModal from "./annual_conversion_modal";

class SubscriptionForm678 extends React.Component {
  constructor(props) {
    super(props);

    this.selected_area = this.props.areas.find(area => area.id == this.props.selected_market);

    this.state = {
      annual_price: this.selected_area.yearly_subscription_price || 10788,
      monthly_price: this.selected_area.monthly_subscription_price || 1299,
      has_active_annual_sale: this.selected_area["has_active_annual_sale?"] || false,
      annual_sale_discount: this.selected_area.annual_sale_discount || null,
      annual_sale_discount_valid_through: this.selected_area.annual_sale_discount_valid_through || null,
      remaining_cost: props.remaining_cost,
      referral_can_discount: !isNaN(parseInt(props.remaining_cost)),
      selected_plan: props.selected_plan,
      membership_start: props.membership_start,
      show_both_plans: props.signup_version == 8 || props.signup_version == 9
    }

    this.state["annual_saving_percentage"] = props.annual_saving_percentage || this.percentage(this.state.annual_price, this.state.monthly_price);

    if(this.selected_area["has_active_annual_sale?"]){
      this.state["special_annual_price"] = this.state.annual_sale_discount ? Math.floor(this.state.annual_price - this.state.annual_sale_discount) / 1200 : null,
      this.state["special_annual_saving_percentage"] = this.percentage(this.state.annual_price - this.state.annual_sale_discount, this.state.monthly_price);
    }

    this.pending_submission = false;
    this.reactivation_token = this.props.account ? this.props.account.token : null;

    this.show_both_plans = this.show_both_plans.bind(this);
    this.onRadioUpdate = this.onRadioUpdate.bind(this);
    this.onSelectPlan = this.onSelectPlan.bind(this);
    this.updateParent = this.updateParent.bind(this);
    this.getCurrentSelectedPlanMonthlyPrice = this.getCurrentSelectedPlanMonthlyPrice.bind(this);
    this.renderPlanCard = this.renderPlanCard.bind(this);
    this.renderAnnualChoices = this.renderAnnualChoices.bind(this);
    this.renderDeferral = this.renderDeferral.bind(this);
    this.renderV9Fields = this.renderV9Fields.bind(this);
    this.submitAccountDetails = this.submitAccountDetails.bind(this);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Subscription");
      posthog.capture("Signup: Subscription");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected_plan != this.props.selected_plan) {
      this.setState({ selected_plan: this.props.selected_plan });
    }
  }

  percentage(annual, monthly) {
    let percentage = 100 - Math.round(100 * annual / (monthly * 12));
    return percentage > 0 ? `${percentage}%` : null;
  }

  getCurrentSelectedPlanMonthlyPrice() {
    if(!this.state.selected_plan) return null;
    return this.state.selected_plan == "annual" ? Math.floor(this.state.annual_price / 1200) : Math.floor(this.state.monthly_price / 100);
  }

  stripeCustomerIdToUse() {
    if (this.props.setup_intent) {
      return this.props.setup_intent.customer;
    }
    if (this.props.account) {
      return this.props.account.stripe_id;
    }
    return null;
  }

  show_both_plans() {
    this.setState({ show_both_plans: true });
  }

  onRadioUpdate(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.updateParent(event.target.name, event.target.value);
  }

  onSelectPlan(event, selected_plan = null) {
    let plan = selected_plan || event.target.dataset.plan;

    this.setState({ selected_plan: plan });
    this.updateParent('selected_plan', plan);
  }

  getDeferralStartMonth(itter = 1) {
    const current_date = new Date();

    let current_month = current_date.getMonth();
    let next_month = current_month + itter;
    let year = current_date.getFullYear();

    while (next_month > 11) {
      next_month = next_month - 12;
      year++;
    }

    // If this market has a launch date in the future, members will defer to the start date
    if (this.selected_area.start_date) {
      var converted_date = convertDateString(this.selected_area.start_date);
      if (converted_date > new Date()) {
        next_month = converted_date.getMonth();
        year = converted_date.getFullYear();
      }
    }

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${months[next_month]} 1st, ${year}`;
  }

  updateParent(key, value) {
    if (this.props.onStateUpdate) {
      this.props.onStateUpdate(key, value)
    }
  }

  submitAccountDetails(event, selected_plan=this.state.selected_plan) {
    if (this.pending_submission || !selected_plan || !this.state.membership_start) return false;
    if (this.props.signup_version == 9 && selected_plan != "annual" && this.props.can_show_conversion_modal) {
      return this.props.show_conversion_modal();
    }
    
    this.pending_submission = true;
    var valid_referral = this.props.referral_details.referral.referral_code || this.props.referral_details.referral.gift_code;

    var signup_version = null;
    if (typeof(global_signup_version) != 'undefined') {
      signup_version = global_signup_version + ".0";
    }

    $.ajax({
      method: "POST",
      url: "/signup-submit",
      data: {
        customer: {
          valid_referral: valid_referral,
          plan_id: selected_plan,
          area_id: this.props.selected_market,
          first_name: this.props.first_name,
          last_name: this.props.last_name,
          email: this.props.email,
          phone_number: this.props.phone_number,
          start_immediately: this.state.membership_start,
          waitlist_id: this.props.waitlist_id,
          mixpanel_original_id: this.props.mixpanel_original_id,
        },
        customer_id: this.stripeCustomerIdToUse(),
        intent_id: this.props.setup_intent ? this.props.setup_intent.id : null,
        reactivation_token: this.reactivation_token,
        signup_version: signup_version
      },
      dataType: 'json',
      success: (data) => {
        this.pending_submission = false;
        if (data.intent) {
          this.updateParent("setup_intent", data.intent)
          if (analytics_loaded) {
            if (mixpanel.get_distinct_id() != data.intent.customer && (this.reactivation_token == null)) {
              mixpanel.alias(data.intent.customer, mixpanel.get_distinct_id());
              mixpanel.identify(data.intent.customer);
              posthog.identify(data.intent.customer);
            }
          }
        }
        this.props.nextAction();
      },
      error: (error) => {
        this.pending_submission = false;
      }
    });
  }

  renderV9Fields() {
    if (this.props.signup_version != 9) return (<></>);

    return (
      <>
        {this.props.render_conversion_modal &&
          <AnnualConversionModal
            closeModal={()=>{
              this.submitAccountDetails();
              this.props.show_conversion_modal();
            }}
            acceptUpgrade={()=>{
              this.onSelectPlan(null, "annual");
              this.submitAccountDetails(null, "annual");
              this.props.show_conversion_modal();
            }}
          />
        }

        <ExtendedFooterContent>
          <PaysForItselfSection selected_monthly_cost={this.getCurrentSelectedPlanMonthlyPrice()}/>
          <JoinTheMovementSection />
          <MessagingSection />
        </ExtendedFooterContent>
      </>
    )
  }

  renderAnnualChoices() {
    let signup_version = this.props.signup_version;

    if (signup_version == 6) {
      return <AnnualBenefitSummary
        price={Math.floor(this.state.annual_price / 1200)}
        special_price={this.state.special_annual_price}
      />
    }

    if (signup_version == 7) {
      if (this.props.show_both_plans) {
        return <PlanComparison
          plans={
            [
              {
                name: "annual",
                price: Math.floor(this.state.annual_price / 1200),
                special_price: this.state.special_annual_price
              },
              {
                name: "monthly",
                price: Math.floor(this.state.monthly_price / 100)
              }]}
          save_percentage={this.state.annual_saving_percentage}
          special_save_percentage={this.state.special_annual_saving_percentage || null}
          selected_plan={this.props.selected_plan}
          onSelectPlan={this.onSelectPlan}
          areas={this.props.areas}
        />
      } else {
        return <AnnualBenefitSummary
          price={Math.floor(this.state.annual_price / 1200)}
          special_price={this.state.special_annual_price}
          can_show_both_plans={true}
          show_plans={this.props.toggle_show_both_plans}
        />
      }
    }

    if (signup_version == 8 || signup_version == 9) {
      return <PlanComparison
      plans={
        [
          {
            name: "annual",
            price: Math.floor(this.state.annual_price / 1200),
            special_price: this.state.special_annual_price
          },
          {
            name: "monthly",
            price: Math.floor(this.state.monthly_price / 100)
          }]}
        save_percentage={this.state.annual_saving_percentage}
        special_save_percentage={this.state.special_annual_saving_percentage || null}
        selected_plan={this.props.selected_plan}
        onSelectPlan={this.onSelectPlan}
        areas={this.props.areas}
      />
    }

    return (
      <></>
    )
  }

  renderPlanCard(plan_type = "annual") {
    let { annual_cost_today,
      annual_cost,
      monthly_cost_today,
      monthly_cost,
      show_discount_message } = getSubscriptionCardMessaging(this.props.referral_details.referral,
        this.selected_area);

    let plan_name = "Annually";
    let saving_percentage = this.state.annual_saving_percentage;
    let months_off = 12 - Math.round(this.state.annual_price / this.state.monthly_price);
    let sizzle_text = months_off > 0 && months_off <= 12 ? `${months_off} months free!` : "";
    let price_string = `$${convertIntegerToCleanPrice(annual_cost)}/yr`;
    let due_today = annual_cost_today;
    let id = "select_annual_plan";

    // AB Test Data
    if (signup_annual_copy_test && signup_annual_copy_test.includes(this.selected_area.name)) {
      price_string = `$${convertIntegerToCleanPrice(Math.round(this.state.annual_price / 12))}/mo`;
      sizzle_text = `Billed $${convertIntegerToCleanPrice(annual_cost)}/yr`;
    }

    if (plan_type == "monthly") {
      plan_name = "Monthly";
      saving_percentage = null;
      sizzle_text = "cancel anytime";
      price_string = `$${convertIntegerToCleanPrice(monthly_cost)}/mo`;
      due_today = monthly_cost_today;
      id = "select_monthly_plan"
    }

    let plan_select = (
      <button
        id={id}
        className="sign-up-button-min full"
        data-plan={plan_type}
        onClick={this.onSelectPlan}
      >
        SELECT
      </button>
    )

    let card_class = "plan-card"
    if (this.state.selected_plan == plan_type) {
      card_class += " plan-active"
      plan_select = (
        <div className="plan-selected">
          <img src={iconSelected} />
          <div>SELECTED</div>
        </div>
      )
    }

    return (
      <div className={card_class}>
        <div className="plan-header">
          <div>{plan_name}</div>
          {saving_percentage &&
            <div className="savings-callout">
              Save {saving_percentage}
            </div>
          }
        </div>
        <div
          className={
            "plan-price"
            + (show_discount_message
              ? " has-discount" : "")
          }
        >
          {price_string}
        </div>
        {show_discount_message &&
          <div className="plan-discount">
            ${convertIntegerToCleanPrice(due_today)} due today
          </div>
        }
        {plan_select}
        <div className="plan-footer">{sizzle_text}</div>
      </div>
    )
  }

  renderDeferral() {
    return (
      <div className="radio-grouping">
        {!this.selected_area.deferral_only &&
          <div className="radio-option">
            <input
              type="radio"
              value="today"
              id="today"
              name="membership_start"
              checked={this.state.membership_start == "today"}
              onChange={this.onRadioUpdate}
            />
            <label htmlFor="today">Today</label>
          </div>}
        <div className="radio-option">
          <input
            type="radio"
            value="defer"
            id="defer"
            name="membership_start"
            checked={this.state.membership_start == "defer"}
            onChange={this.onRadioUpdate}
          />
          <label htmlFor="defer">{this.getDeferralStartMonth()}</label>
        </div>
      </div>
    )
  }

  rederDeferralText() {
    let terms = getSubscriptionTermsText(this.props.referral_details.referral,
      this.state.membership_start,
      this.state.selected_plan,
      this.selected_area);

    if(this.selected_area["has_active_annual_sale?"] && this.state.selected_plan == "annual"){
      terms.push("Subscription will renew at non-sale price on your next renewal date.")
    }

    return (
      <div className="sign-up-list-container">
        {terms.map((term, i) => <div key={"sub_term_" + i} className="input-subtitle list">{term}</div>)}
      </div>
    );
  }

  render() {
    var show_free_trial_banner = this.props.referral_details.referral.percent_discounted == 100;
    return (
      <div className="sign-up-column">
        {/* <div className="market-header">
          <div className="step-header">Your membership</div>
        </div> */}
        {/* <div className="market-subtitle">
          Time to put a label on our relationship. 😬
        </div> */}
        <br />

        {/* <div className="subscription-plans">
          {this.renderPlanCard("annual")}
          {this.renderPlanCard("monthly")}
        </div> */}

        { show_free_trial_banner && <FreeTrialBanner />}
        {this.renderAnnualChoices()}

        <div className="input-label">When should your membership start?</div>

        {this.renderDeferral()}
        {this.rederDeferralText()}

        {this.renderV9Fields()}

        <div className="sign-up-nav-footer">
          <button
            id="submit_step_2"
            className={
              "sign-up-button-full" +
              (!this.state.selected_plan || !this.state.membership_start ? " inactive" : "")
            }
            onClick={this.submitAccountDetails}
          >CONTINUE →</button>
          <button id="back_to_step_1" className="sign-up-button-anchor" onClick={this.props.previousAction}>Back</button>
        </div>
      </div>
    );
  }
}

export default SubscriptionForm678
