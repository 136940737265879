import React from "react";
import AlertIcon from "event-ticket-alert.svg";

class EventTicketAlertModal extends React.Component {
  constructor(props) {
    super(props);

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    if (typeof this.props.closeModal == "function") {
      this.props.closeModal();
    }
  }

  render() {
    return (
      this.props.show &&
      <div className="event-ticket-modal">
        <div className="event-ticket-modal-box">
          <div className="event-ticket-modal-header">
            <img src={AlertIcon} alt="Offline" />
          </div>
          <div className="event-ticket-modal-content">
            <div className="event-ticket-modal-ohno">Oh no!</div>
            <div className="event-ticket-modal-message">{this.props.message}</div>
          </div>
          <div className="event-ticket-modal-footer">
            <button className="event-ticket-modal-first-button" onClick={this.props.fixCard || this.closeModal}>
              {this.props.buttonText}
            </button>
            {this.props.secondButtonText && <button className="event-ticket-modal-second-button" onClick={this.closeModal}>
              {this.props.secondButtonText}
            </button>}
          </div>
        </div>
      </div>
    )
  }
}

export default EventTicketAlertModal;