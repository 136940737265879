import moment from 'moment-timezone';

export const convertDateString = (dateString) => {
  if (!dateString) {
    return null;
  }

  let utcDate = new Date(dateString + "T00:00:00Z");
  utcDate.setTime(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000)
  return utcDate;
};

export const convertDateTimeString = (datetimeString, timezone = "Eastern Time (US & Canada)") => {
  if (!datetimeString) {
    return null;
  }
  return moment(datetimeString).tz(timezone_map[timezone]);
}

export const updateMomentToTimezone = (moment, timezone) => {
  if(!moment) return null;
  let new_moment = moment.clone();
  new_moment.tz(timezone_map[timezone], true);
  return new_moment;
}

export const renderDate = (date) => {
  if (date) {
    const options = { month: 'long', day: 'numeric', year: 'numeric' }
    return date.toLocaleString('en-US', options);
  }
  return ""
}

export const timezone_map = {
  "Eastern Time (US & Canada)": 'America/New_York',
  "Central Time (US & Canada)": 'America/Chicago',
  "Mountain Time (US & Canada)": 'America/Denver',
  "Pacific Time (US & Canada)": 'America/Los_Angeles'
}

// Take a string in the format of HH:MM and convert it to minutes
export const convertTimeStringToMinutes = (time) => {
  if (!time) return null;
  let hours = parseInt(time.split(":")[0]);
  let minutes = parseInt(time.split(":")[1]);
  return hours * 60 + minutes;
}

export const convertMinutesToTimeString = (minutes) => {
  if (!minutes) return "";
  let hours = Math.floor(minutes / 60);
  let minutes_remainder = minutes % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes_remainder.toString().padStart(2, '0')}`;
}

export const getCurrentDateInTimezone = (timezone)  => {
  return moment.tz(timezone_map[timezone]);
}
