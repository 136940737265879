import React from "react";

class ConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: this.props.width || "20em",
            height: this.props.height || "12em",
            // show: this.props.show || false
        };

        this.classOnCancel = this.classOnCancel.bind(this);
        this.classOnConfirm = this.classOnConfirm.bind(this);
    }

    classOnCancel() {
        // this.setState({show: false});
        if (typeof this.props.onCancel == "function") {
            this.props.onCancel(this.props.show);
        }
    }

    classOnConfirm() {
        // this.setState({show: false});
        if (typeof this.props.onCancel == "function") {
            this.props.onConfirm(this.props.show);
        }
    }

    render() {
        return (
            this.props.show &&
            <div className="confirmation-modal-container">
                <div className={"confirmation-modal-box " + (this.props.className ? this.props.className : "")} style={{
                    width: this.state.width,
                    height: this.state.height
                }}>
                    <div className="confirmation-title">
                        {this.props.title || "Confirmation"}
                    </div>
                    <div className="confirmation-message">
                        {this.props.message || "Are you sure you would like to continue?"}
                    </div>
                    <div className="confirmation-footer">
                        <button className="confirmation-cancel-button" onClick={this.classOnCancel}>
                            Cancel
                        </button>
                        <button className="confirmation-confirm-button" onClick={this.classOnConfirm}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConfirmationModal;