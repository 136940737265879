import React from "react";
import FormSelect from "../shared/form_select";
import FormBuilder from "../shared/form_builder";
import EventNavLinks from './event_nav_links';
import ContentSection from "../shared/content_section";
import RichTextEditor from 'react-rte';
import FormMarkdownInput from "../shared/form_markdown_input";

import { convertIntegerToPrice } from '../../helpers/price_formatter';
import { convertPriceToInteger } from '../../helpers/price_formatter';
import { convertDateString, convertDateTimeString, updateMomentToTimezone } from '../../helpers/datestring_formatter';
import EventDateSelector from "../event_dates/event_date_selector";
import PlacesAutocomplete from "../shared/places_autocomplete";
import TimezoneDateTimePicker from "../shared/timezone_date_time_picker";
import ImageCollectionManager from "../images/image_collection_manager";
import EventAppPreview from "./event_app_preview";
import LocationPicker from "../shared/location_picker";


class EventEdit extends React.Component {
  constructor(props) {
    super(props);

    var new_event_dates = [...this.props.event_dates];
    for (let event_date of new_event_dates) {
      event_date.date = convertDateString(event_date.date);
      event_date.event_times.sort((a, b) => a.minutes - b.minutes);
    }
    new_event_dates.sort((a, b) => a.date - b.date);

    // Even with the correct response headers Chrome WILL NOT
    // pull in fresh information when 'Going back a page' from
    // the web navigation bar.  It will keep certain DOM changes,
    // so for now we can log if an AJAX call was previously made
    // and if it was, force the browser to reload with new rails
    // data.
    let reloading = false
    if (document.querySelector('.ajax_call_made')) {
      reloading = true;
      window.location.href = window.location.href;
    }

    var header_image = null;
    if (this.props.images[0]) {
      header_image = this.props.images[0].header_image_url;
    }

    var timezone = this.getTimeZoneFromLocationIDs(this.props.location_ids);
    this.state = {
      name: props.name || '',
      instagram_link: props.instagram_link || '',
      website: props.website || '',
      description: props.description || '',
      policies: props.policies || '',
      status: props.status,
      last_saved_status: props.status,
      has_sms: props.has_sms,
      sms_message: props.sms_message,
      start_date: convertDateTimeString(props.start_date, timezone),
      end_date: convertDateTimeString(props.end_date, timezone),
      redeem_start_date: convertDateTimeString(props.redeem_start_date, timezone),
      redeem_end_date: convertDateTimeString(props.redeem_end_date, timezone),
      redeem_capacity: props.redeem_capacity || 0,
      event_dates: new_event_dates,
      location_ids: props.location_ids,
      sections: [...props.sections],
      address: props.address,
      menu_link: props.menu_link || '',
      google_review_url: props.google_review_url || '',
      lottery_link: props.lottery_link || '',
      price: convertIntegerToPrice(props.ticket_price),
      offline_split: convertIntegerToPrice(props.offline_split),
      partner_split: convertIntegerToPrice(props.partner_split),
      video_url: props.video_url || '',
      winner_information_body: props.winner_information_body,
      winner_information_subject: props.winner_information_subject,
      reloading,
      header_image,
      timezone,
      event_type: props.event_type,
    }

    this.new_section_count = 0;
    this.first_load = true;

    this.changeInputField = this.changeInputField.bind(this);
    this.saveEventChanges = this.saveEventChanges.bind(this);
    this.importDescriptionHTML = this.importDescriptionHTML.bind(this);
    this.onSectionCreate = this.onSectionCreate.bind(this);
    this.onSectionUpdate = this.onSectionUpdate.bind(this);
    this.onSectionChangePosition = this.onSectionChangePosition.bind(this);
    this.onSectionDelete = this.onSectionDelete.bind(this);
    this.onAddressUpdate = this.onAddressUpdate.bind(this);
    this.updateEventDates = this.updateEventDates.bind(this);
    this.clearEventDates = this.clearEventDates.bind(this);
    this.getTimeZoneFromLocationIDs = this.getTimeZoneFromLocationIDs.bind(this);
    this.selectAllLocations = this.selectAllLocations.bind(this);
    this.froalaEditorUpdate = this.froalaEditorUpdate.bind(this);
    this.updateStateItem = this.updateStateItem.bind(this);
    this.changePriceSplit = this.changePriceSplit.bind(this);
    this.updateHeaderImage = this.updateHeaderImage.bind(this);
  }

  froalaEditorUpdate(data) {
    this.setState({ description: data });
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;
    if (target.type == "checkbox") {
      return this.setState({ [name]: target.checked });
    }

    this.setState({ [name]: target.value });
  }

  updateStateItem(name, value) {
    console.log(name, value)
    let update = { [name]: value };
    if (name == "location_ids") {
      let timezone = this.getTimeZoneFromLocationIDs(value);
      if (timezone) {
        update.timezone = timezone;
      }
      if (this.state.timezone != timezone) {
        update.start_date = updateMomentToTimezone(this.state.start_date, timezone);
        update.end_date = updateMomentToTimezone(this.state.end_date, timezone);
        update.redeem_start_date = updateMomentToTimezone(this.state.redeem_start_date, timezone);
        update.redeem_end_date = updateMomentToTimezone(this.state.redeem_end_date, timezone);
      }
    }
    this.setState(update);
  }

  updateHeaderImage(url) {
    this.setState({ header_image: url });
  }

  changePriceSplit(event) {
    const target = event.target;
    const name = target.name;

    const total_price = convertPriceToInteger(this.state.price);
    const this_price = convertPriceToInteger(target.value);
    let other_price = total_price - this_price;
    other_price = convertIntegerToPrice(other_price);

    const other_name = name == "offline_split" ? "partner_split" : "offline_split"
    this.setState({ [name]: target.value, [other_name]: other_price });
  }

  getTimeZoneFromLocationIDs(location_ids) {
    var timezone = "Eastern Time (US & Canada)"; //default

    var first_location_id = location_ids[0];
    if (!first_location_id) return timezone;

    var location = this.props.locations.filter(loc => loc.value == first_location_id)[0];

    var area = this.props.areas.filter(a => a.id == location.area_id)[0];

    return area.timezone ? area.timezone : timezone;
  }

  selectAllLocations(event) {
    const area_id = event.target.dataset["areaId"];
    var options = this.props.locations.filter(location => location.area_id == area_id);
    var location_ids = options.map((option) => option.value);
    var combinded_ids = this.state.location_ids.concat(location_ids);

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const unique_ids = combinded_ids.filter(onlyUnique);
    let timezone = this.getTimeZoneFromLocationIDs(unique_ids);
    let update = { location_ids: unique_ids, timezone }

    if (this.state.timezone != timezone) {
      update.start_date = updateMomentToTimezone(this.state.start_date, timezone);
      update.end_date = updateMomentToTimezone(this.state.end_date, timezone);
    }
    this.setState(update);
  }

  importDescriptionHTML(event) {
    let markdown = RichTextEditor.createValueFromString(this.state.description, 'html')
      .toString('markdown')
      .replace(/[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000]/g, '');

    var sections_copy = [...this.state.sections];
    this.new_section_count++;

    sections_copy.push({
      temp_id: "new-section-" + this.new_section_count,
      content: markdown
    });
    this.setState({ sections: sections_copy });
  }

  onSectionCreate() {
    var sections_copy = [...this.state.sections];
    this.new_section_count++;

    sections_copy.push({
      temp_id: "new-section-" + this.new_section_count
    });
    this.setState({ sections: sections_copy });
  }

  onSectionUpdate(section, index) {
    var sections_copy = [...this.state.sections];
    sections_copy[index] = section;
    this.setState({ sections: sections_copy });
  }

  onSectionChangePosition(index, change = -1) {
    // If attempting to move to an index out of bounds, do nothing
    if (index + change < 0 || index + change >= this.state.sections.length) {
      return null
    }
    var sections_copy = [...this.state.sections];
    // Swap section positions
    var original_section = sections_copy[index];
    sections_copy[index] = sections_copy[index + change];
    sections_copy[index + change] = original_section;
    this.setState({ sections: sections_copy });
  }

  onSectionDelete(index) {
    var sections_copy = [...this.state.sections];
    var section_to_remove = sections_copy[index];
    if (!section_to_remove.id) {
      sections_copy.splice(index, 1);
    } else {
      sections_copy[index]["_destroy"] = true;
    }
    this.setState({ sections: sections_copy });
  }

  onAddressUpdate(address) {
    this.setState({ address });
  }

  updateEventDates(event_dates) {
    this.setState({ event_dates });
  }

  clearEventDates(event) {
    this.setState({ event_dates: [] });
  }

  pageLogChange() {
    let ajax_call_made = document.querySelector('.ajax_call_made');
    if (!ajax_call_made) {
      ajax_call_made = document.createElement('div');
      ajax_call_made.className = "ajax_call_made";
      window.document.body.append(ajax_call_made);
    }
  }

  saveEventChanges() {
    this.pageLogChange();
    var path = "/events/" + this.props.id;


    var new_event_dates = JSON.parse(JSON.stringify(this.state.event_dates))
    new_event_dates.map(event_date => {
      event_date.event_times_attributes = event_date.event_times;
      delete event_date.event_times;
      return event_date;
    })

    var form_builder = new FormBuilder();

    var sections_copy = [...this.state.sections];
    for (let [i, section] of sections_copy.entries()) {
      section.position = i;
      if (section.image) {
        section.image_id_to_attach = section.image.id;
        delete section.image;
      }
    }

    var form_object = {
      event: {
        name: this.state.name,
        instagram_link: this.state.instagram_link.trim(),
        website: this.state.website.trim(),
        description: this.state.description,
        policies: this.state.policies,
        status: this.state.status,
        has_sms: this.state.has_sms,
        sms_message: this.state.sms_message,
        location_ids: this.state.location_ids,
        address_attributes: this.state.address,
        event_dates_attributes: new_event_dates,
        ticket_price: convertPriceToInteger(this.state.price),
        offline_split: convertPriceToInteger(this.state.offline_split),
        partner_split: convertPriceToInteger(this.state.partner_split),
        menu_link: this.state.menu_link.trim(),
        google_review_url: this.state.google_review_url.trim(),
        lottery_link: this.state.lottery_link.trim(),
        video_url: this.state.video_url.trim(),
        winner_information_body: this.state.winner_information_body,
        winner_information_subject: this.state.winner_information_subject,
        sections_attributes: sections_copy,
        event_type: this.state.event_type,
        redeem_capacity: this.state.redeem_capacity,
      }
    }

    var form_data = form_builder.createForm(form_object);

    // Adding these outside FormBuilder because we want to keep these as datetimes with timezone intact.
    form_data.append('event[start_date]', this.state.start_date?.toDate() || "");
    form_data.append('event[end_date]', this.state.end_date?.toDate() || "");
    form_data.append('event[redeem_start_date]', this.state.redeem_start_date?.toDate() || "");
    form_data.append('event[redeem_end_date]', this.state.redeem_end_date?.toDate() || "");

    $.ajax({
      method: "PUT",
      url: path,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        var new_event_dates = [...data.event_dates];
        for (let event_date of new_event_dates) {
          event_date.date = convertDateString(event_date.date);
        }

        this.setState({
          name: data.name || '',
          instagram_link: data.instagram_link || '',
          website: data.website || '',
          description: data.description || '',
          policies: data.policies || '',
          status: data.status,
          last_saved_status: data.status,
          has_sms: data.has_sms,
          sms_message: data.sms_message,
          start_date: convertDateTimeString(data.start_date, this.state.timezone),
          end_date: convertDateTimeString(data.end_date, this.state.timezone),
          redeem_start_date: convertDateTimeString(data.redeem_start_date, this.state.timezone),
          redeem_end_date: convertDateTimeString(data.redeem_end_date, this.state.timezone),
          event_dates: new_event_dates,
          menu_link: data.menu_link,
          google_review_url: data.google_review_url,
          lottery_link: data.lottery_link,
          sections: data.sections,
          video_url: data.video_url || '',
          winner_information_body: data.winner_information_body,
          winner_information_subject: data.winner_information_subject,
          event_type: data.event_type,
        })
        if (data.notice) {
          showFlashErrorMessage(data.notice);
        } else {
          showFlashMessage("Event Saved");
        }
      },
      error: (error) => {
        showFlashErrorMessage(error.responseJSON.message.join(' '));
      }
    });
  }

  renderStatusPill() {
    if (this.state.last_saved_status == "Active (live)") {
      return (<span className="admin__app-pill pill-top pill-size-small pill-green">Live</span>);
    } else {
      return (<span className="admin__app-pill pill-top pill-size-small">{this.state.last_saved_status}</span>);
    }
  }

  renderSections() {
    let minimized = this.first_load;
    this.first_load = false;

    return (this.state.sections.map((section, index) =>
      <ContentSection
        key={section.id || section.temp_id}
        index={index}
        section={section}
        section_icons={this.props.section_icons}
        max_index={this.state.sections.length - 1}
        sectionUpdated={this.onSectionUpdate}
        sectionPositionChange={this.onSectionChangePosition}
        sectionDeleted={this.onSectionDelete}
      />
    ))
  }

  renderLotteryOrTicketFields() {
    if (this.state.event_type == "AnyTime") {
      return (
        <>
          <h3 className='med-spaced'>Redeem Dates:</h3>
          <div>
            <div className='small-spaced'>Redeem Starts</div>
            <TimezoneDateTimePicker
              name="redeem_start_date"
              disableClock={true}
              value={this.state.redeem_start_date}
              timezone={this.state.timezone}
              onChange={value => {
                this.updateStateItem("redeem_start_date", value)
              }} />
          </div>
          <div>
            <div className='small-spaced'>Redeem Ends</div>
            <TimezoneDateTimePicker
              name="redeem_end_date"
              disableClock={true}
              value={this.state.redeem_end_date}
              timezone={this.state.timezone}
              onChange={value => {
                this.updateStateItem("redeem_end_date", value)
              }} />
          </div>
          <div>
            <div className='small-spaced'>Redeem Capacity</div>
            <input
              id='redeem_capacity'
              name='redeem_capacity'
              className='builder__textarea'
              type="number"
              placeholder="Redeem Capacity"
              value={this.state.redeem_capacity}
              onChange={this.changeInputField} />
          </div>
        </>
      )
    } else {
      return (
        <>
          <div>
            <h3 className='med-spaced' style={{ marginBottom: '0', lineHeight: '1.5rem' }}>Event Date(s)</h3>
            <EventDateSelector
              event_id={this.props.id}
              event_dates={this.state.event_dates}
              onUpdate={this.updateEventDates} />
          </div>
        </>
      )
    }
  }



  render() {
    if (this.state.reloading) {
      return (
        <div style={{ width: '100%' }}>
          <div className="admin__page-header">
            <div className="admin__page-title">
              The Page is Reloading, Please Wait...
            </div>
            <div className="admin__page-actions">
            </div>
          </div>
          <div className='event-preview-wrapper'>
            <div className='event-preview' />
          </div>
        </div>
      );
    }

    let max_sections_reached = this.state.sections.filter(x => !x["_destroy"]).length > 2;

    return (
      <div style={{ width: '100%' }}>
        <div className="admin__page-header">
          <div className="admin__page-title">
            {this.renderStatusPill()} Modify Event
          </div>
          <div style={{ display: "flex", alignItems: "center" }} className="admin__page-actions">
            <EventNavLinks id={this.props.id} token={this.props.token} current='edit' />
            <button
              className={'admin__actionbar__preview'}
              onClick={this.saveEventChanges}>
              Save
            </button>
          </div>
        </div>
        <div className='builder builder--experience builder__multi-col'>
          <div className='multi-col__size-5'>
            <div>
              <h3>Event Info</h3>
              <div>
                <h4>Event Name</h4>
                <div>

                  <input
                    id='event-name'
                    name='name'
                    className='builder__textarea'
                    type="text"
                    placeholder="Event Name"
                    value={this.state.name}
                    onChange={this.changeInputField} />
                </div>
                <h4>Event Ticket Price:</h4>
                <div className="event-ticket-price-container">
                  <input
                    name='price'
                    className='builder__textarea'
                    type="text"
                    placeholder="Price Per ticket"
                    value={this.state.price}
                    onChange={this.changeInputField} />
                </div>
                <div className='builder__multi-col'>
                  <div className='multi-col__size-5'>
                    <h5>Offline Portion:</h5>
                    <div className="event-ticket-price-container">
                      <input
                        name='offline_split'
                        className='builder__textarea'
                        type="text"
                        placeholder="Offline Split"
                        value={this.state.offline_split}
                        onChange={this.changePriceSplit} />
                    </div>
                  </div>
                  <div className='multi-col__size-5'>
                    <h5>Partner Portion:</h5>
                    <div className="event-ticket-price-container">
                      <input
                        name='partner_split'
                        className='builder__textarea'
                        type="text"
                        placeholder="Partner Split"
                        value={this.state.partner_split}
                        onChange={this.changePriceSplit} />
                    </div>
                  </div>
                </div>
              </div>

              <div className='med-spaced'>
                <h4>Event Locations:</h4>
                <LocationPicker
                  areas={this.props.areas}
                  locations={this.props.locations}
                  location_ids={this.state.location_ids}
                  selectAllLocations={this.selectAllLocations}
                  updateStateItem={this.updateStateItem}
                />
              </div>

              <div className='builder__multi-col med-spaced'>
                <div className='multi-col__size-2'>
                  <h4>Instagram Handle:</h4>
                  <input
                    id='instagram-handle'
                    name='instagram_link'
                    className='builder__textarea'
                    type="text"
                    placeholder="@yourhandle"
                    value={this.state.instagram_link}
                    onChange={this.changeInputField} />
                  <h4>Website Link:</h4>
                  <input
                    id='website-link'
                    name='website'
                    className='builder__textarea'
                    type="text"
                    placeholder="event website"
                    value={this.state.website}
                    onChange={this.changeInputField} />
                  <h4>Menu Link:</h4>
                  <div>
                    <input
                      name='menu_link'
                      className='builder__textarea'
                      type="text"
                      value={this.state.menu_link}
                      onChange={this.changeInputField} />
                  </div>
                  <h4>Google Review Link:</h4>
                  <div>
                    <input
                      name='google_review_url'
                      className='builder__textarea'
                      type="text"
                      placeholder="Add a link here"
                      value={this.state.google_review_url}
                      onChange={this.changeInputField} />
                  </div>
                </div>
              </div>

              <div className='med-spaced froala-react-wrapper'>
                <h3>Event Address</h3>
                <PlacesAutocomplete
                  address={this.state.address}
                  onWebRequestComplete={this.onAddressUpdate}
                  canReset={true}
                />
              </div>

              <div className='med-spaced froala-react-wrapper'>
                <h3>Event Description</h3>
                <br />
                <FormMarkdownInput
                  name="description"
                  value={this.state.description}
                  onChange={this.updateStateItem}
                  show_import={this.props.show_import}
                />
              </div>
              <hr />

              <div className='med-spaced'>
                <h3>Event Sections</h3>
                {this.renderSections()}
                <button
                  style={{ margin: '10px 10px 0 0' }}
                  className={"standard-btn--rounded" + (max_sections_reached ? " disable-input" : "")}
                  onClick={this.onSectionCreate}>
                  Add New Section
                </button>
                {max_sections_reached &&
                  "There can only be a maximum of 3 sections for events"
                }
                {/* <button
                  style={{ margin: '10px 10px 0 0' }}
                  className="standard-btn--rounded"
                  onClick={this.importDescriptionHTML}>
                  Import HTML Description to New Section
                </button> */}
                <hr />
              </div>

              <div className='med-spaced'>
                <h3>Event Policies</h3>
                <FormMarkdownInput
                  name="policies"
                  value={this.state.policies}
                  onChange={this.updateStateItem} />
              </div>

            </div>
            <div className='med-spaced'>
              <h3 className='small-spaced'>Sale Date Range</h3>
              <div>
                <p style={{ fontStyle: 'italic', marginTop: '0', lineHeight: '1.5rem' }}>
                  The dates this popup will be visible to members in app for purchase.
                  No start date means it will never show up.
                </p>
                <div>
                  <div className='small-spaced'>Sale Starts</div>
                  <TimezoneDateTimePicker
                    name="start_date"
                    disableClock={true}
                    value={this.state.start_date}
                    timezone={this.state.timezone}
                    onChange={value => {
                      this.updateStateItem("start_date", value)
                    }} />
                </div>
                <div>
                  <div className='small-spaced'>Sale Closes</div>
                  <TimezoneDateTimePicker
                    name="end_date"
                    disableClock={true}
                    value={this.state.end_date}
                    timezone={this.state.timezone}
                    onChange={value => {
                      this.updateStateItem("end_date", value)
                    }} />
                </div>
              </div>

              <hr className='med-spaced' />
              <h3 className='med-spaced'>Event Type:</h3>
              <div style={{ marginBottom: "8px" }}>
                <FormSelect
                  options={this.props.event_types}
                  name="event_type"
                  id="event_type"
                  className='large-input'
                  value={this.state.event_type}
                  labelAsValue={true}
                  onChange={this.updateStateItem} />
              </div>

              {this.renderLotteryOrTicketFields()}

              
            </div>
          </div>

          { /* Right Side of editor */}
          <div className='multi-col__size-4'>
            <br />
            <FormSelect
              options={this.props.statuses}
              name="status"
              id="event_status"
              className='large-input'
              value={this.state.status}
              labelAsValue={true}
              onChange={this.updateStateItem} />
            <div>
              <br></br>
              <h4>Mux Video URL</h4>
              <input
                id='video-url'
                name='video_url'
                className='builder__textarea'
                type="text"
                placeholder="Video URL"
                value={this.state.video_url}
                onChange={this.changeInputField} />
            </div>
            <div>
              <br></br>
              <h4>Photo(s)</h4>
              <ImageCollectionManager
                imageable_id={this.props.id}
                imageable_type={"Event"}
                images={this.props.images}
                onHeaderImageChange={this.updateHeaderImage} />
            </div>
            <div style={{ marginTop: "20px" }}>
              <h4>App Preview:</h4>
              <EventAppPreview
                id={this.props.id} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventEdit