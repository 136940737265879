import React from "react";
import LoadingIcon from 'loading.gif';

class CardValidationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poll_count: 0,
      timeout: false
    }

    this.checkIfSetupComplete = this.checkIfSetupComplete.bind(this);
    this.logSignupTimeout = this.logSignupTimeout.bind(this);
  }

  componentDidMount() {
    // If valid props have been provided, start the watchdog
    // process
    this.intervalId = setInterval(this.checkIfSetupComplete, 1000);
  }

  checkIfSetupComplete() {
    $.ajax({
      method: "GET",
      url: "/setup-status-check",
      data: {
        setup_intent: this.props.intent.id
      },
      dataType: 'json',
      success: (data) => {
        if (data.account_status == "success") {
          clearInterval(this.intervalId);
        }
        this.props.handleResponse(data);
      }
    });

    if (this.state.poll_count >= 30) {
      clearInterval(this.intervalId);
      this.setState({ timeout: true });
      this.logSignupTimeout();
    } else {
      this.setState({ poll_count: this.state.poll_count + 1 });
    }
  }

  logSignupTimeout() {
    $.ajax({
      method: "POST",
      url: "/setup-timeout",
      data: {
        setup_intent: this.props.intent.id
      },
      dataType: 'json',
      success: (data) => {

      }
    });
  }

  render() {
    return (
      <div className="sign-up-column" style={{ textAlign: 'center' }}>
        <div className="market-header">
          <div className="step-header">{this.props.messaging}</div>
        </div>
        <div className="market-subtitle" style={{textAlign: 'center'}}>
          This should only take a moment.
        </div>
        <br />

        {!this.state.timeout &&
          <div className="">
            <img className="" src={LoadingIcon} />
          </div>
        }
        {this.state.timeout &&
          <div>
            <p>This has taken longer than expected, please refresh the page by <a href='' onClick={() => window.location.reload()}> clicking here</a> or feel free to reach out to
              <a style={{ color: '#004FFF' }} href="mailto:support@letsgetoffline.com"> support@letsgetoffline.com </a> for further assistance.
            </p>
          </div>
        }
      </div>
    );
  }
}

export default CardValidationPage