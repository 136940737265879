import React from "react";
import SignupHeader from "../gui_components/signup_header";
import ProgressBar from "../../../shared/progress_bar";
import UserReferralLandingScreenGUI from "../gui_components/user_referral_landing_screen_gui";
import SignupFooter from "../gui_components/signup_footer";
import ArrowRight from "icon-arrow-right.svg";
import BackIcon from "icon-back-left.svg";

class UserReferralLandingScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_area_id: this.props.selected_area_id
    }

    this.update_area = this.update_area.bind(this);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Market Select");
      posthog.capture("Signup: Market Select");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.network_busy != this.props.network_busy && !this.props.network_busy) {
      this.setState({ selected_area_id: this.props.selected_area_id });
    }
  }

  update_area(area_id) {
    this.setState({ selected_area_id: area_id });
    this.props.update_area(area_id);
  }

  render() {
    let referral_name = "";
    let referral_details = this.props.referral_details;

    if (referral_details && referral_details.type == "user" && referral_details.referral.name) {
      referral_name = referral_details.referral.name;
    }

    let button_text = "pick 2 restaurants";
    if (this.props.network_busy) {
      button_text = "loading restaurants";
    }

    return (
      <>
        <SignupHeader
          mobile_center={<div className="header-user-referral-screen-title">welcome to Offline</div>}
          mobile_left={
            this.props.currentScreen == 0 ? (null) : (<img className="header-back-arrow-button" src={BackIcon} alt="Back" onClick={this.props.previousScreen} />)
          }
          desktop_left={this.props.city_logo_img}
        />
        <div className="desktop-inline-header">
          <div></div>
          <div className="header-user-referral-screen-title">welcome to Offline</div>
          <div></div>
        </div>
        <UserReferralLandingScreenGUI
          areas={this.props.areas}
          update_area={this.update_area}
          pick_offers={this.props.pick_offers}
          selected_area_id={this.state.selected_area_id}
          network_busy={this.props.network_busy}
          referral_name={referral_name}
        />
        <SignupFooter
          center={
            <button className={"full-blue-button smaller-text signup-v2-stripe" + (this.props.network_busy ? " inactive" : "")} onClick={this.props.pick_offers} disabled={this.props.network_busy} >
              {button_text}
              <img src={ArrowRight} alt="" className="signup-desktop-styling" />
            </button>
          }
          bottom={
            this.props.currentScreen == 0 ? (null) : (<img className="header-back-arrow-button" src={BackIcon} alt="Back" onClick={this.props.previousScreen} />)
          }
          sticky={true}
        />
      </>
    );
  }
}

export default UserReferralLandingScreen