import React from "react";
import moment from 'moment-timezone';
import IconGreyReviewStar from 'icon-grey-review-star.svg';
import IconGreenReviewStar from 'icon-green-review-star.svg';
import IconYellowReviewStar from 'icon-yellow-review-star.svg';
import ToggleSwitch from "../shared/toggle_switch";

class OfferAssignmentReviews extends React.Component {
  constructor(props) {
    super(props);

    this.debounce_interval = null;
    this.renderReviewHeader = this.renderReviewHeader.bind(this);
    this.renderReviewRows = this.renderReviewRows.bind(this);
    this.renderStars = this.renderStars.bind(this);
    this.debounce_resize = this.debounce_resize.bind(this);
    this.newsletter_ready_value = this.newsletter_ready_value.bind(this);
  }

  componentDidMount() {
    this.update_comment_css();
    window.addEventListener("resize", this.debounce_resize(e => {
      this.update_comment_css(true);
    }));
  }

  componentDidUpdate() {
    this.update_comment_css();
  }

  update_comment_css(resize) {
    let rows = document.querySelectorAll(".customer-reviews-row");

    for (let row of rows) {
      let comment = row.querySelector(".comment-content");
      if (comment && !row.classList.contains("show-full") && comment.offsetHeight == comment.scrollHeight) {
        row.querySelector(".show-full-btn")?.classList.add("do-not-show");
      } else if (resize && comment.offsetHeight < comment.scrollHeight) {
        row.querySelector(".show-full-btn")?.classList.remove("do-not-show");
      }
    }
  }

  debounce_resize(end_function) {
    return (event) => {
      if (this.debounce_interval) clearTimeout(this.debounce_interval);
      this.debounce_interval = setTimeout(end_function, 100, event);
    };
  }

  survey_answer_boolean(value) {
    if (value == "yes") return true;
    if (value == "no") return false;
    return value;
  }

  isABoolean(value) {
    return typeof value === 'boolean';
  }

  broughtNewPeople(offer_assignment) {
    let brought_new_people = offer_assignment.went_with_non_member != null ? offer_assignment.went_with_non_member : this.survey_answer_boolean(offer_assignment.survey_answers?.went_with_non_premium);
    return this.answerInEnglish(brought_new_people);
  }

  beenInPriorMonths(offer_assignment) {
    let been_in_prior_months = offer_assignment.been_in_prior_months != null ? offer_assignment.been_in_prior_months : this.survey_answer_boolean(offer_assignment.survey_answers?.has_been_before);
    return this.answerInEnglish(been_in_prior_months);
  }

  willGoAgain(offer_assignment) {
    let will_go_again = offer_assignment.will_go_again != null ? offer_assignment.will_go_again : this.survey_answer_boolean(offer_assignment.survey_answers?.will_go_again);
    return this.answerInEnglish(will_go_again);
  }

  answerInEnglish(value) {
    if (value === true) return "Yes";
    if (value === false) return "No";
    return "";
  }

  showFull(e) {
    let toggle_element = e.target;
    let escape = 4;
    while (!toggle_element.classList.contains("customer-reviews-row")) {
      toggle_element = toggle_element.parentElement;
      escape--;
      if (escape == 0) return;
    }

    toggle_element.classList.toggle("show-full");
  }

  newsletter_ready_value(offer_assignment) {
    if (this.props.ready_ids.includes(offer_assignment.id)) {
      return [true, true];
    }

    if (this.props.unready_ids.includes(offer_assignment.id)) {
      return [false, true];
    }

    return offer_assignment.newsletter_ready ? [true, false] : [false, false];
  }

  renderStars(rating) {
    let srcStar = this.props.highly_reviewed ? IconGreenReviewStar : IconYellowReviewStar;
    let stars = [<div className="rating-value" key="star00">{rating + ".0"}</div>];
    for (let i = 1; i <= 5; i++) {
      stars.push(<img key={'star' + i} src={i > rating ? IconGreyReviewStar : srcStar} className="review-star"></img>)
    }
    return stars;
  }

  renderReviewHeader() {
    if (this.props.highly_reviewed) {
      return (
        <div className="customer-reviews-header-row">
          <div className="customer-reviews-cell">Add to Newsletter</div>
          <div className="customer-reviews-cell">Rating</div>
          <div className="customer-reviews-cell">Date & Time</div>
          {!this.props.display_min &&
            <>
              <div className="customer-reviews-cell">Brought new People</div>
              <div className="customer-reviews-cell">REPEAT CUSTOMER</div>
              <div className="customer-reviews-cell">Willing to come back</div>
            </>
          }
          <div className="customer-reviews-cell">Comments</div>
        </div>
      )
    }

    return (
      <div className="customer-reviews-header-row">
        <div className="customer-reviews-cell">Rating</div>
        <div className="customer-reviews-cell">Date & Time</div>
        <div className="customer-reviews-cell">Improvement Suggestions</div>
        {/* <div className="customer-reviews-cell">Comments</div> */}
      </div>
    )
  }

  renderReviewRows() {
    // let answered_has_went_with_non_member = reviewed_offer_assignments.filter(oa => oa.went_with_non_member != null || this.isABoolean(this.survey_answer_boolean(oa.survey_answers?.went_with_non_premium)))
    if (this.props.highly_reviewed) {
      return this.props.offer_assignment.map((oa, i) => {
        const [ready, unsaved_changes] = this.newsletter_ready_value(oa);

        return (
          <div key={'cr' + i} className={"customer-reviews-row" + (unsaved_changes ? " unsaved-changes" : "")} onClick={this.showFull}>
            <div className="customer-reviews-cell" style={{flexDirection: "column", alignItems: "start"}}>
              <ToggleSwitch
                value={ready}
                onChange={(e) => { this.props.toggle_newsletter(oa) }}
              />
              {oa.newsletter_ready &&
                <b style={{whiteSpace: "break-spaces"}}>{"Marked Ready on:\n" + moment(oa.newsletter_ready).format("MMM Do YYYY")}</b>
              }
            </div>
            <div className="customer-reviews-cell" style={{ flexDirection: "column", alignItems: "baseline", fontWeight: "600", fontSize: "12px" }}>
              {oa.offer.name}
              <div className="customer-reviews-cell rating">
                {this.renderStars(oa.customer_rating)}
              </div>
            </div>
            <div className="customer-reviews-cell">{moment(oa.date_reviewed || oa.date_reviewed).format("MMMM Do YYYY HH:mm")}</div>
            {/* <div className="customer-reviews-cell">{oa.offer.name}</div> */}
            {!this.props.display_min &&
              <>

                <div className="customer-reviews-cell">{this.beenInPriorMonths(oa)}</div>
                <div className="customer-reviews-cell">{this.willGoAgain(oa)}</div>
              </>
            }
            <div className="customer-reviews-cell comments">
              <b>{`${oa.account.first_name} ${oa.account.last_name[0]}.`}</b>
              <div className="comment-content">{oa.customer_feedback}</div>
              <div className={"show-full-btn" + (oa.customer_feedback ? "" : " do-not-show")}>View Full</div>
            </div>
          </div>
        )
      });
    }

    return this.props.offer_assignment.map((oa, i) => (
      <div key={'cr' + i} className="customer-reviews-row" onClick={this.showFull}>
        <div className="customer-reviews-cell rating">{this.renderStars(oa.customer_rating)}</div>
        <div className="customer-reviews-cell">{moment(oa.date_reviewed || oa.date_reviewed).format("MMMM Do YYYY, HH:mm")}</div>
        <div className="customer-reviews-cell">{oa.areas_of_improvement.join(', ')}</div>
        {/* <div className="customer-reviews-cell comments hide-initial">
          <div className="comment-content">{oa.customer_feedback}</div>
          <div className="show-full-btn" style={(oa.customer_feedback ? { opacity: '1' } : {})}>View Full</div>
        </div> */}
      </div>
    ))
  }

  render() {
    return (
      <div className={"partner-report-customer-reviews" + (this.props.highly_reviewed ? " four-above" : " three-below") + " " + this.props.class_name + " " + (this.props.display_min ? "display-min" : "")}>
        {this.renderReviewHeader()}
        <div className="review-body">
          {this.renderReviewRows()}
        </div>
      </div>
    )
  }
}

export default OfferAssignmentReviews