import React from "react";

class CSVInput extends React.Component {
    constructor(props) {
        super(props);
        this.currentReadCSV = null;

        this.handleCSVFileLoad = this.handleCSVFileLoad.bind(this);
    }

    async readCSV(file) {
        let csvReader = new FileReader();

        // Wrap FileReader listeners in Promise so 'readCSV' can be 'awaited.'
        let readerPromise = new Promise((resolve, reject) => {
            csvReader.onload = () => {
                resolve(csvReader.result);
            }
            csvReader.onerror = (error) => {
                reject(error);
            }
        });

        csvReader.readAsText(file);
        return readerPromise;
    }

    parseCSVString(fileString) {
        let csvArray = fileString.trim()
            .split(/\r\n|\n/g)
            .map(line => {
                let csvRawCells = line.split(',');
                let csvCells = [];
                let holdCell = null;
                for (let csvCell of csvRawCells) {
                    let hasOddNumberOfQuotes = csvCell.replaceAll(/[^"]/g, "").length % 2;
                    if (hasOddNumberOfQuotes) {
                        if (holdCell) {
                            holdCell += "," + csvCell;
                            holdCell = holdCell.slice(1, -1)
                            csvCells.push(holdCell);
                            holdCell = null;
                        } else {
                            holdCell = csvCell;
                        }
                    } else if (holdCell) {
                        holdCell += "," + csvCell;
                    } else {
                        csvCells.push(csvCell);
                    }
                }
                return csvCells.map(cell => cell.replaceAll('"', '').trim());
            });

        return { headers: csvArray.shift(), rows: csvArray };
    }

    async handleCSVFileLoad(event) {
        if (event.target.files && event.target.files[0]) {
            let fileString = await this.readCSV(event.target.files[0]);
            this.currentReadCSV = this.parseCSVString(fileString);
            if (this.props.onCSVLoad) {
                this.props.onCSVLoad(this.currentReadCSV, event);
            }
            event.target.value = null;
        }
    }

    render() {
        return (
            <div className="csv-input">
                {this.props.uploadLabel || "Upload CSV:"}
                <input type="file" className={this.props.className && this.props.className} name={this.props.name || "upload_csv"} accept=".csv" onChange={this.handleCSVFileLoad} />
            </div>
        )
    }
}

export default CSVInput;