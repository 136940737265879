import DateTimePicker from 'react-datetime-picker';
import { timezone_map } from '../../helpers/datestring_formatter'
import moment from 'moment-timezone';

import React from "react";

class TimezoneDateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.translate_area_timezone = this.translate_area_timezone.bind(this);
    this.dateToMoment = this.dateToMoment.bind(this);
  }

  // componentDidUpdate(prev_props) {
  //   if (this.props.value.tz() != prev_props.value.tz()) {
  //     this.setState({date_from_moment: this.translate_area_timezone(this.props.value)})
  //   }
  // }

  translate_area_timezone(date) {
    if (!date) return null;
    let browser_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timezone_adjusted = date.clone().tz(browser_timezone, true);
    return timezone_adjusted.toDate();
  }

  dateToMoment(date) {
    if (date) {
      let timezone = this.props.value;
      timezone = timezone ? timezone.tz() : timezone_map[this.props.timezone];
      let moment_value = moment(date).tz(timezone, true);
      this.props.onChange(moment_value);
    } else {
      this.props.onChange(null);
    }
  }

  render() {
    return (
      <div className='timezone-picker'>
        <DateTimePicker
          name="start_date"
          disableClock={this.props.disableClock}
          value={this.translate_area_timezone(this.props.value)}
          onChange={this.dateToMoment} />
        {this.props.value &&
          <div className="timezone-abbr">
            {this.props.value.zoneAbbr()}
          </div>
        }
      </div>
    );
  }
}

export default TimezoneDateTimePicker