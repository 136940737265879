import React, { components } from "react";

class MultiTextInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entries: this.props.entries || [],
            focused: false,
            value: ""
        }
        this.textInput = React.createRef();
        this.container = React.createRef();
    }

    onFocus = (event) => {
        this.setState({ focused: true })
    }

    onBlur = (event) => {
        this.setState({ focused: false, value: "" })
        this.textInput.current.value = "";
    }

    onChange = (event) => {
        let value = event.target.value.trim();
        this.setState({ value })
    }

    onKeyDown = (event) => {
        if (event.key == "Escape") {
            event.preventDefault();
            event.stopPropagation();
            this.textInput.current.blur();
        } else if (event.key == "Return" || event.key == "Enter") {
            event.preventDefault();
            event.stopPropagation();
            this.addEntry();
        }
    }

    onPaste = (event) => {
        let rawText = event.clipboardData.getData("Text");
        if (!rawText) {
            event.preventDefault();
            return false;
        }

        let entries = rawText.split(/\r\n|\n/g);
        let first = entries.shift() || "";
        // Clear empty lines
        entries = entries
            .map(entry => entry.trim())
            .filter(entry => entry);

        event.preventDefault();

        document.execCommand("insertHTML", false, first);
        this.setState({ entries: [...new Set([...entries, ...this.state.entries])] });
    }

    removeEntry = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ entries: this.state.entries.filter(entry => entry != event.target.dataset.entry) })
    }

    removeAll = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ value: "", entries: [] })
    }

    addEntry = (event) => {
        if (event) event.preventDefault();
        let value = this.state.value;
        if (!value) return;
        this.setState({ entries: [value.trim(), ...this.state.entries] })
        this.textInput.current.value = "";
    }

    renderEntries = () => {
        return (
            <div className="entry-container">
                {this.state.entries.map((entry, count) =>
                    <div key={'e' + count} className="multiline-entry">
                        <div>{entry}</div>
                        <button data-entry={entry} onClick={this.removeEntry}>✕</button>
                    </div>
                )}
            </div>
        )
    }

    renderDialog = () => {
        if (!this.state.focused
            || !this.state.value
            || this.state.entries.includes(this.state.value)) {
            return
        };

        return (
            <div className="multiline-dialog" data-add={true} onMouseDown={this.addEntry}>
                {'Add "' + this.state.value + '"'}
            </div>
        )
    }

    renderClearAll = () => {
        if (!this.state.focused) return;

        return (
            <button className="multiline-clear-all" onMouseDown={this.removeAll}>✕</button>
        )
    }

    renderFormSelect = () => {
        return (
            <select name={this.props.select_name + '[]'} style={{ display: 'none' }} value={this.state.entries} multiple={true} onChange={() => { }}>
                {this.state.entries.map((entry) =>
                    <option key={'hide' + entry} label="" value={entry} />
                )}
            </select>
        )
    }

    render() {
        return (
            <div className="multiline-container"
                ref={this.container}>

                <input type="text"
                    onPaste={this.onPaste}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onKeyDown={this.onKeyDown}
                    className={this.props.className || false}
                    ref={this.textInput}
                />
                {this.renderEntries()}
                {this.renderClearAll()}
                {this.renderDialog()}
                {this.renderFormSelect()}
            </div>
        )
    }
}

export default MultiTextInput;