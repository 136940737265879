import React from "react";
import PhoneInputGUI from "../gui_components/phone_input_gui";
import SignupHeaderV3 from "../gui_components/signup_header_v3";
import { validatePhoneNumber } from "./network_methods_v3";

class PhoneInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phone_number: this.props.phone_number || "",
      error_phone_number: null,
    }

    this.changeInputField = this.changeInputField.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.validateAccountDetails = this.validateAccountDetails.bind(this);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Phone");
      posthog.capture("Signup: Phone");
    }
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;

    let check_state = Object.assign({}, this.state);
    check_state[name] = target.value;

    let check_errors = this.checkErrors(check_state);
    let update_state = check_errors.update_state;
    for (let update in check_errors.update_state) {
      if (check_errors.update_state[update]) {
        delete update_state[update];
      }
    }

    if (target.type == "checkbox") {
      update_state[name] = target.checked;
      return this.setState(update_state);
    } else {
      update_state[name] = target.value;
      return this.setState(update_state)
    }
  }

  validateAccountDetails() {
    let check_errors = this.checkErrors();
    let valid = check_errors.valid;
    let update_state = check_errors.update_state;

    if (valid) {
      validatePhoneNumber(this.state.phone_number, (data) => {
        this.props.updateState({
          phone_number: this.state.phone_number,
        }, true)
      }, (error_data) => {
        if (error_data.responseJSON?.error?.type == "phone-number") {
          this.setState({ error_phone_number: error_data.responseJSON.error.message });
        }
      })
    }

    this.setState(update_state);
  }

  checkErrors(local_state = this.state) {
    let valid = true;
    let update_state = {
      error_phone_number: local_state.error_phone_number,
    };

    if (!local_state.phone_number) {
      valid = false;
      update_state.error_phone_number = "Invalid phone number";
    } else {
      var phone = local_state.phone_number.replace(/\D/g, '');
      if (phone && phone[0] == "1") {
        phone = phone.slice(1)
      }

      if (phone.length != 10) {
        valid = false;
        update_state.error_phone_number = "Invalid phone number";
      } else {
        update_state.error_phone_number = null;
      }
    }

    return { valid, update_state };
  }

  render() {
    return (
      <>
        <SignupHeaderV3
          markets={this.props.markets}
          selected_market={this.props.selected_market}
          updateStateValue={() => { }}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
          select_enabled={false}
          currentScreen={this.props.currentScreen}
          total_screens={this.props.total_screens}
        />
        <PhoneInputGUI
          phone_number={this.state.phone_number}
          error_phone_number={this.state.error_phone_number}
          changeInputField={this.changeInputField}
          validateAccountDetails={this.validateAccountDetails}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
          previousScreen={this.props.previousScreen}
        />
      </>
    );
  }
}

export default PhoneInput