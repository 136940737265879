import React from "react";

import EmailInput from "../shared/account_inputs/email_input";
import ContinueButton from "../shared/continue_button";
import BackButton from "../shared/back_button";
import StyledList from "../shared/styled_list";
import { getIDforGTM } from "../v2/state_components/gtm_methods";
import { validateEmail } from "../utils/account_validations";

import CheckboxIcon from "icon-checkbox.svg";
import Chef from "icon-chef.svg";
import Piggybank from "icon-piggybank.svg";
import Alarmclock from "icon-alarmclock.svg";
import MarketSelect from "../shared/market_select";

class SignupV5Gist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.email || '',
      error_email: ''
    }
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value || "",
      ["error_" + event.target.name]: ''
    });
  }

  render() {
    return (
      <div className="signup-v4-gist">
        <StyledList
          header="Quick Recap"
          icon={CheckboxIcon}
          items={[
            "Choose 2 restaurants to try for free",
            "Up to $30 off the tab at each spot",
            "Curated, local restaurants",
            "Monthly Member-exclusive events",
            "Cancel any time",
            "Rockstar support 7 days/week"
          ]}
        />
        <EmailInput
          email={this.state.email}
          error_email={this.state.error_email}
          changeInputField={this.onInputChange}
        />
        <MarketSelect
          markets={this.props.markets}
          selected_market={this.props.selected_area?.id}
          updateStateValue={this.props.updateArea}
        />
        <ContinueButton
          disabled={this.state.email.length == 0}
          onPress={validateEmail(this, this.props.onSubmit)}
          gtm_id={getIDforGTM('continue-to-next-screen', this.props)}
        />

        <BackButton
          onPress={this.props.onBack}
          gtm_id={getIDforGTM('back-to-previous-screen', this.props)}
        />
      </div>
    );
  }
}

export default SignupV5Gist