import React from "react";
import LocationFlag from "flag.svg";
import OfferSelected from "offer-selected.svg";
import CheveronDown from "icon-chevron-down.svg";
import CheckedSlider from "icon-slider-checked.svg";
import UncheckedSlider from "icon-slider-unchecked.svg";
import FriendInterseted from "icon-friend-interested.svg";
import FastCasual from "icon-fast-casual";
import CasualDining from "icon-casual-dining";
import HigherEnd from "icon-higher-end";

class OfferFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [...this.props.locations],
      categories: [...this.props.categories],
      selected_friends: [...this.props.selected_friends],
      offer_types: [...this.props.offer_types],
      reset_locations: [...this.props.locations],
      reset_categories: [...this.props.categories],
      reset_offer_types: [...this.props.offer_types],
      locations_expanded: true,
      categories_expanded: true,
      offer_types_expanded: true,
      show_repeat_offers: this.props.show_repeat_offers,
      show_last_chance_offers: this.props.show_last_chance_offers,
      show_interested_friends: this.props.show_interested_friends || false,
      reset_interested_friends: [...this.props.selected_friends],
      reset_show_repeat_offers: this.props.show_repeat_offers,
      reset_show_last_chance_offers: this.props.show_last_chance_offers,
      reset_show_interested_friends: this.props.show_interested_friends || false,
    }

    this.get_dining_image = this.get_dining_image.bind(this);
    this.toggle_filter_section = this.toggle_filter_section.bind(this);
    this.update_filter = this.update_filter.bind(this);
    this.close_filter = this.close_filter.bind(this);
    this.close_overlay = this.close_overlay.bind(this);
    this.cancel_filter = this.cancel_filter.bind(this);
    this.getFriendLabel = this.getFriendLabel.bind(this);
  }

  componentDidUpdate(prev_props) {
    let check_props = ["locations", "categories", "offer_types", "show_repeat_offers", "show_last_chance_offers"];
    let update_state = {};
    for (let prop of check_props) {
      if (this.props[prop] != prev_props[prop]) {
        update_state[prop] = this.props[prop];
        if (Array.isArray(this.props[prop])) {
          update_state["reset_" + prop] = [...this.props[prop]];
        } else {
          update_state["reset_" + prop] = this.props[prop];
        }
      }
    }
  }

  get_dining_image(name) {
    name = name.toLowerCase();
    if (name == "fast casual") return FastCasual;
    if (name == "casual dining") return CasualDining;
    if (name == "higher end") return HigherEnd;
    return "";
  }

  toggle_filter_section(event) {
    let target = event.target;
    let name = target.dataset.name;
    if (this.state[name]) {
      event.target.classList.add("collapse-filter");
    } else {
      event.target.classList.remove("collapse-filter");
    }

    this.setState({ [name]: !this.state[name] });
  }

  update_filter(event) {
    let target = event.target;
    let name = target.name;
    let id = parseInt(target.dataset.id);
    let checked = target.checked;

    let filter_value = this.state[name];
    if (!target.dataset.id) {
      filter_value = checked;
    } else if (checked) {
      filter_value.push(id);
    } else {
      filter_value = filter_value.filter(c_id => c_id != id);
    }

    let update = {
      locations: this.state.locations,
      categories: this.state.categories,
      offer_types: this.state.offer_types,
      show_repeat_offers: this.state.show_repeat_offers,
      show_last_chance_offers: this.state.show_last_chance_offers,
      show_interested_friends: this.state.show_interested_friends,
      selected_friends: this.state.selected_friends,
    }

    update[name] = filter_value;

    this.setState({ [name]: filter_value });
    this.props.update_live_count(update)
  }

  close_overlay(e) {
    if (e.target.classList.contains('offer-select-filter-overlay')) {
      this.close_filter();
    }
  }

  close_filter() {
    this.props.close_filter({
      locations: this.state.locations,
      categories: this.state.categories,
      offer_types: this.state.offer_types,
      show_repeat_offers: this.state.show_repeat_offers,
      show_last_chance_offers: this.state.show_last_chance_offers,
      show_interested_friends: this.state.show_interested_friends,
      selected_friends: this.state.selected_friends,
    });
    this.setState({
      reset_locations: [...this.state.locations],
      reset_categories: [...this.state.categories],
      reset_offer_types: [...this.state.offer_types],
      reset_show_repeat_offers: this.state.show_repeat_offers,
      reset_show_last_chance_offers: this.state.show_last_chance_offers,
      reset_show_interested_friends: this.state.show_interested_friends,
      reset_interested_friends: [...this.state.selected_friends],
    })
  }

  cancel_filter() {
    this.props.close_filter({
      locations: this.state.reset_locations,
      categories: this.state.reset_categories,
      offer_types: this.state.reset_offer_types,
      show_repeat_offers: this.state.reset_show_repeat_offers,
      show_last_chance_offers: this.state.reset_show_last_chance_offers,
      show_interested_friends: this.state.reset_show_interested_friends,
      selected_friends: this.state.reset_interested_friends,
    });
    this.setState({
      locations: [...this.state.reset_locations],
      categories: [...this.state.reset_categories],
      offer_types: [...this.state.reset_offer_types],
      show_repeat_offers: this.state.reset_show_repeat_offers,
      show_last_chance_offers: this.state.reset_show_last_chance_offers,
      show_interested_friends: this.state.reset_show_interested_friends,
      selected_friends: [...this.state.reset_interested_friends],
    })
  }

  getCategoriesLength(categories, all_categories) {
    let all_valid_ids = all_categories.map(category => category.id);
    return categories.filter(id => all_valid_ids.includes(id)).length;
  }

  getFriendLabel(friend_obj){
    if(this.props.account_id == friend_obj.id) return "ME"
    return [friend_obj.first_name, friend_obj.last_name[0]].join(' ')
  }

  render() {
    let show_interested = this.state.show_interested_friends ? " disable-filter-inputs" : "";
    return (
      <div style={{ display: this.props.show_offer_filter ? "" : "none" }} className="offer-select-filter-overlay" onMouseDown={this.close_overlay}>
        <div className="offer-select-filter-container">
          <div className="offer-select-filter-title">FILTER OFFERS</div>
          <div className="offer-select-filter-body">
            <div className="filter-slider for-interested-friends">
              <img src={FriendInterseted} alt="" />
              <div className="slider-text for-interested-friends">
                <div className="slider-title">Show Interested Only</div>
                <div className="slider-subtitle">This will ignore all other filters and only show offers marked interested by selected friends</div>
                <div className={"interested-friend-list" + (this.state.show_interested_friends ? "" : " hide-friends")}>
                  {this.props.all_friends.map(friend => (
                    <div className="friend-option" key={"f" + friend.id}>
                      <input type="checkbox"
                        name="selected_friends"
                        id={"f" + friend.id}
                        data-id={friend.id}
                        checked={this.state.selected_friends.includes(friend.id)}
                        onChange={this.update_filter}
                      />
                      <label htmlFor={"f" + friend.id}>{this.getFriendLabel(friend)}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="slider-input">
                <input type="checkbox"
                  name="show_interested_friends"
                  id="show_interested_friends"
                  checked={this.state.show_interested_friends}
                  onChange={this.update_filter}
                />
                <label className="label-container" htmlFor="show_interested_friends">
                  <div className="label-knob">
                    <img className="slider-checked" src={CheckedSlider} alt="" />
                    <img className="slider-unchecked" src={UncheckedSlider} alt="" />
                  </div>
                </label>
              </div>
            </div>
            <div className={"filter-title" + (show_interested)}>
              <div className="filter-text">{`Categories (${this.getCategoriesLength(this.state.categories, this.props.all_categories)})`}</div>
              <img src={CheveronDown} alt="Toggle" data-name="categories_expanded" onClick={this.toggle_filter_section} />
            </div>
            <div className={"categories" + (show_interested) + (this.state.categories_expanded ? "" : " hide-filter-options")}>
              {this.props.all_categories.map(category => (
                <div className="category-option" key={"c" + category.id}>
                  <input type="checkbox"
                    name="categories"
                    id={"c" + category.id}
                    data-id={category.id}
                    checked={this.state.categories.includes(category.id)}
                    onChange={this.update_filter}
                  />
                  <label htmlFor={"c" + category.id}>{[category.emoji, category.name].join(' ')}</label>
                </div>
              ))}
            </div>
            <div className={"filter-title" + (show_interested)}>
              <div className="filter-text">{`Dining Type (${this.state.offer_types.length})`}</div>
              <img src={CheveronDown} alt="Toggle" data-name="offer_types_expanded" onClick={this.toggle_filter_section} />
            </div>
            <div className={"offer_types" + (show_interested) + (this.state.offer_types_expanded ? "" : " hide-filter-options")}>
              {this.props.all_offer_types.map(offer_type => (
                <div className="offer-type-option" key={"o" + offer_type.id}>
                  <input type="checkbox"
                    name="offer_types"
                    id={"o" + offer_type.id}
                    data-id={offer_type.id}
                    checked={this.state.offer_types.includes(offer_type.id)}
                    onChange={this.update_filter}
                  />
                  <label className="label-container" htmlFor={"o" + offer_type.id}>
                    <img src={this.get_dining_image(offer_type.name)} alt="" />
                    <div className="label-text">
                      {offer_type.name}
                    </div>
                  </label>
                </div>
              ))}
            </div>
            <div className={"filter-title" + (show_interested)}>
              <div className="filter-text">{`Locations (${this.state.locations.length})`}</div>
              <img src={CheveronDown} alt="Toggle" data-name="locations_expanded" onClick={this.toggle_filter_section} />
            </div>
            <div className={"locations" + (show_interested) + (this.state.locations_expanded ? "" : " hide-filter-options")}>
              {this.props.all_locations.map(location => (
                <div className="location-option" key={"l" + location.id}>
                  <input type="checkbox"
                    name="locations"
                    id={"l" + location.id}
                    data-id={location.id}
                    checked={this.state.locations.includes(location.id)}
                    onChange={this.update_filter}
                  />
                  <label htmlFor={"l" + location.id}>{location.location_name}</label>
                </div>
              ))}
            </div>
            <div className={"filter-slider for-repeat-offers" + (show_interested)}>
              <div className="slider-text">
                <div className="slider-title">Show Repeat Offers</div>
                <div className="slider-subtitle">Repeat Offers are Offers you've had more than a year ago and rated highly.</div>
              </div>
              <div className="slider-input">
                <input type="checkbox"
                  name="show_repeat_offers"
                  id="show_repeat_offers"
                  checked={this.state.show_repeat_offers}
                  onChange={this.update_filter}
                />
                <label className="label-container" htmlFor="show_repeat_offers">
                  <div className="label-knob">
                    <img className="slider-checked" src={CheckedSlider} alt="" />
                    <img className="slider-unchecked" src={UncheckedSlider} alt="" />
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="offer-select-filter-footer">
            <button className="offer-select-cancel" onClick={this.cancel_filter}>CANCEL</button>
            <button className="offer-select-close" onClick={this.close_filter}>{"SHOW " + this.props.live_count + " OFFERS"}</button>
          </div>
        </div>
      </div>
    )
  }
}

export default OfferFilter