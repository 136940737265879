import React from "react";
import ImageSelect from "./image_select"
import ImageEditor from "./image_editor"
import FormBuilder from "../shared/form_builder";

class NewImageEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.image
    };
    this.onImageUploaded = this.onImageUploaded.bind(this);
    this.handleImageUpdate = this.handleImageUpdate.bind(this);
  }

  handleImageUpdate(image, keep_editing=true) {
    this.setState({image: image});
    this.props.onImageUpdate(image);
  }

  onImageUploaded(image_data, image_url) {

    var form_builder = new FormBuilder();
    var form_object = {
      image: {
        header_image: image_data
      }
    }

    if (this.props.imageable_id) {
      form_object.image.imageable_id = this.props.imageable_id;
      form_object.image.imageable_type = this.props.imageable_type;
    }

    var form_data = form_builder.createForm(form_object);

    $.ajax({
      method: "POST",
      url: "/images",
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        this.setState({image: data.image});
        this.props.onImageUpdate(data.image);
      },
      error: (error) => {
        
      }
    });
  }

  render() {
    if (this.state.image) {
      return (
        <ImageEditor
          key={this.state.image.id}
          image_id={this.state.image.id}
          header_image_url={this.state.image.header_image_url}
          credit_text={this.state.image.credit_text || ''}
          link={this.state.image.link || ''}
          onImageUpdate={this.handleImageUpdate}
          no_delete={true}
          dataClassName='multi-col__size-5'/>
      )
    }
    return (
      <ImageSelect
        image_url={''}
        onImageUploaded={this.onImageUploaded} />
    )
  }
}

export default NewImageEditor;