import React from "react";

class AccountFriends extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    return (
      <>
        <h2 onClick={() => {
          let collapse_action = this.props.collapse_action;
          collapse_action.action(collapse_action.args);
        }}>
          Friends:{" "}
          <span
            className={caret_style}
            data-section-id="friend-section"
          />
        </h2>
        <div id="friend-section" style={collapsed_style}>
          {this.props.friends.map((friend, index) => {
            return (
              <div key={"friend" + friend.id}>
                <a target="_blank" href={"/accounts/" + friend.id + "/edit"}>
                  {friend.first_name + " " + friend.last_name}
                </a>
              </div>
            )
          })}
        </div>
      </>

    );
  }
}

export default AccountFriends;