import React from "react";
import OfferCard from "./offer_card"

import IconMonthlyCredit from "icon-monthly-credit.svg";
import IconSurpriseCard from "icon-surprise-offer-card.svg";

class ConfirmYourOffers extends React.Component {
  constructor(props) {
    super(props);

    this.renderNoOffer = this.renderNoOffer.bind(this);
    this.renderSelectedOffers = this.renderSelectedOffers.bind(this);
  }

  componentDidMount() {
    if (this.props.child_ref.current) {
      setTimeout(() => {
        this.props.child_ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 10)
    }
  }

  renderNoOffer(i) {
    return (
      <div key={'con' + i} className="no-offer-card">
        <img src={IconMonthlyCredit} />
        <div className="no-offer-title">MISSING OFFER CREDIT X1</div>
        <div className="no-offer-body">Each credit is the equivalent of 50% of your monthly membership cost. Credits will be automatically used on future charges (membership fees, events, etc).</div>
      </div>
    )
  }

  renderSelectedOffers() {
    let selected_offers = this.props.selected_offers || [];

    let cards = selected_offers
      .map((offer, i) => {
        let assignment_slot = this.props.assignment_slots[i];

        // If this slot already has an assignment, show the offer card for that assignment.
        if (assignment_slot.assignment) {
          return (
            <OfferCard 
              key={'con' + i} 
              index={i} 
              offer={assignment_slot.assignment.offer} 
              is_selected={false} 
              full_height={false} 
              chosen={true} />
          );
        }

        if (offer.is_surprise_me) {
          return (
            <div key={'con' + i} className="no-offer-card">
              <img src={IconSurpriseCard} />
              <div className="no-offer-title">{"SURPRISE OFFER"}</div>
              <div className="no-offer-body">{"We're choosing this restaurant for ya. No peeking 👀"}</div>
            </div>
          )
        }

        let invitation_id = offer.invitation_id;
        let invitation = null;
        if (invitation_id && this.props.invitations.length > 0) {
          invitation = this.props.invitations.find(invitation => invitation.id == invitation_id);
        }
        return (
          <OfferCard 
            key={'con' + i} 
            index={i} 
            offer={this.props.offer_map[offer.offer_id]}
            invitation={invitation}
            invite_list={offer.new_invitation}
            is_selected={false} 
            full_height={false} />
        );
        // return this.renderNoOffer(i);

      });
    return (
      <>
        {cards}

      </>
    );
  }


  render() {
    return (
      <div>
        {this.renderSelectedOffers()}
        <div className="redo-container">
          <button className="redo-button" onClick={this.props.redo_selection}>Redo My Choices</button>
        </div>
      </div>
    );
  }
}

export default ConfirmYourOffers