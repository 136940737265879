import React from "react";

import EmailInput from "../shared/account_inputs/email_input";
import ContinueButton from "../shared/continue_button";
import BackButton from "../shared/back_button";
import StyledList from "../shared/styled_list";
import { getIDforGTM } from "../v2/state_components/gtm_methods";
import { validateEmail } from "../utils/account_validations";

import CheckboxIcon from "icon-checkbox.svg";
import Chef from "icon-chef.svg";
import Piggybank from "icon-piggybank.svg";
import Alarmclock from "icon-alarmclock.svg";
import MarketSelect from "../shared/market_select";

class SignupV4Gist extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.email || '',
      error_email: ''
    }
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value || "",
      ["error_" + event.target.name]: ''
    });
  }

  render() {
    return (
      <div className="signup-v4-gist">
        <div className="bordered-block-grouping text-center spacer">
          <div className="bordered-block">
            <img src={Chef} alt="" />
            <h4>2 restaurants<br /> / month</h4>
          </div>
          <div className="bordered-block">
            <img src={Piggybank} alt="" />
            <h4>$15-30 to spend<br /> @ each</h4>
          </div>
        </div>
        <div className='bordered-block-grouping'>
          <div className="bordered-block text-left">
            <div className='header'>
              <span className='tag'>First Month Free</span>
            </div>
            <div className='message faded-text'><img src={Alarmclock} alt="" />We’ll always remind you before your trial ends!</div>
          </div>
        </div>
        <StyledList
          header="Additional Benefits"
          icon={CheckboxIcon}
          items={[
            "New restaurants every month",
            "Membership pays for itself",
            "Curated, local restaurants",
            "Monthly Member-exclusive events",
            "Skip a month or cancel any time",
            "Rockstar support 7 days/week",
          ]}
        />
        <EmailInput
          email={this.state.email}
          error_email={this.state.error_email}
          changeInputField={this.onInputChange}
        />
        <MarketSelect
          markets={this.props.markets}
          selected_market={this.props.selected_area?.id}
          updateStateValue={this.props.updateArea}
        />
        <ContinueButton
          disabled={this.state.email.length == 0}
          onPress={validateEmail(this, this.props.onSubmit)}
          gtm_id={getIDforGTM('continue-to-next-screen', this.props)}
        />

        <BackButton
          onPress={this.props.onBack}
          gtm_id={getIDforGTM('back-to-previous-screen', this.props)}
        />
      </div>
    );
  }
}

export default SignupV4Gist