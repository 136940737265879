import React from "react";

class ModalOverlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: '',
      is_showing: false,
      x_pos: 0,
      y_pos: 0,
      has_animated: false,
      is_raw_html: false
    }

    this.addNewContent = this.addNewContent.bind(this);
    this.checkToClose = this.checkToClose.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderContent = this.renderContent.bind(this);
    Modal.initializeModal(this);
  }

  addNewContent(new_data, x = 0, y = 0) {
    const use_y = $('html').scrollTop();
    this.setState({ content: new_data, is_showing: true, x_pos: x, y_pos: use_y, is_raw_html: false });
    $('html').addClass('modal__block-body');

    const self = this;
    setTimeout(function () { self.animateDisplay(); }, 50);
  }

  addNewHtmlContent(new_data, x = 0, y = 0) {
    const use_y = $('html').scrollTop();
    this.setState({ content: new_data, is_showing: true, x_pos: x, y_pos: use_y, is_raw_html: true });
    $('html').addClass('modal__block-body');

    const self = this;
    setTimeout(function () { self.animateDisplay(); }, 50);
  }

  wrapperAnimationStyle() {
    if (this.state.has_animated) {
      return { display: this.state.is_showing ? "block" : "none", top: this.state.y_pos + 'px' }
    } else {
      return { display: this.state.is_showing ? "block" : "none", backgroundColor: '#0000' }
    }
  }

  contentAnimationStyle() {
    if (this.state.has_animated) {
      return { top: '0px' }
    } else {
      return { top: '100vh' }
    }
  }

  animateDisplay() {
    if (this.state.is_showing) {
      this.setState({ has_animated: true });
    }
  }

  checkToClose(e) {
    if ($(e.target).hasClass('member__app-modal-wrapper')) {
      this.closeModal();
    }
  }

  closeModal() {
    this.setState({ is_showing: false, has_animated: false });
    $('html').removeClass('modal__block-body');
  }

  renderContent() {
    if (this.state.is_raw_html) {
      return (<div className='event-preview' dangerouslySetInnerHTML={{ __html: this.state.content }} />);
    } else {
      return (this.state.content);
    }
  }

  render() {
    return (
      <div
        className='member__app-modal-wrapper'
        style={this.wrapperAnimationStyle()}
        onClick={this.checkToClose}>
        {this.state.is_showing &&
          <div className="member__app-modal" style={this.contentAnimationStyle()}>
            <div className="modal-close" onClick={this.closeModal}>✖</div>
            {this.renderContent()}
          </div>
        }
      </div>
    );
  }
}

export default ModalOverlay