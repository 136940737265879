import React from "react";

class ReviewRating extends React.Component {
  constructor(props) {
    super(props);
    this.updateSurveyAnswer = this.updateSurveyAnswer.bind(this);
  }

  updateSurveyAnswer(event) {
    var value = event.target.getAttribute("value");
    this.props.onRatingChange(this.props.question_key, value);
  }

  render() {
    const current_value = this.props.value || 0;
    return (
      <>
        <div>{this.props.question}</div>
        <div className='premium__user-rating no-padding' style={{marginTop: '0'}}>
          <span className='user-rating small'
                value={1}
                data-user-score='1'
                onClick={this.updateSurveyAnswer}>
            {current_value >= 1 ? "★" : "☆"}
          </span>
          <span className='user-rating small'
                value={2}
                data-user-score='2'
                onClick={this.updateSurveyAnswer}>
            {current_value >= 2 ? "★" : "☆"}
          </span>
          <span className='user-rating small'
                value={3}
                data-user-score='3'
                onClick={this.updateSurveyAnswer}>
            {current_value >= 3 ? "★" : "☆"}
          </span>
          <span className='user-rating small'
                value={4}
                data-user-score='4'
                onClick={this.updateSurveyAnswer}>
            {current_value >= 4 ? "★" : "☆"}
          </span>
          <span className='user-rating small'
                value={5}
                data-user-score='5'
                onClick={this.updateSurveyAnswer}>
            {current_value >= 5 ? "★" : "☆"}
          </span>
        </div>
      </>
    );
  }
}

export default ReviewRating