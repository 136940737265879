import React from "react";
import EmailInputGUI from "../gui_components/email_input_gui";
import SignupHeaderV3 from "../gui_components/signup_header_v3";
import { validateEmail } from "./network_methods_v3";

class EmailInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.email || "",
      error_email: null,
    }

    this.changeInputField = this.changeInputField.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.validateAccountDetails = this.validateAccountDetails.bind(this);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Email");
      posthog.capture("Signup: Email");
    }
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;

    let check_state = Object.assign({}, this.state);
    check_state[name] = target.value;

    let check_errors = this.checkErrors(check_state);
    let update_state = check_errors.update_state;
    for (let update in check_errors.update_state) {
      if (check_errors.update_state[update]) {
        delete update_state[update];
      }
    }

    if (target.type == "checkbox") {
      update_state[name] = target.checked;
      return this.setState(update_state);
    } else {
      update_state[name] = target.value;
      return this.setState(update_state)
    }
  }

  validateAccountDetails() {
    let check_errors = this.checkErrors();
    let valid = check_errors.valid;
    let update_state = check_errors.update_state;


    if (valid) {
      validateEmail(this.state.email, (data) => {
        this.props.updateState({
          email: this.state.email,
        }, true)
      }, (error_data) => {
        if(error_data.responseJSON?.error?.type == "email") {
          this.setState({error_email: error_data.responseJSON.error.message});
        }
      })
    }
    
    this.setState(update_state);
  }

  checkErrors(local_state = this.state) {
    let valid = true;
    let update_state = {
      error_email: local_state.error_email,
    };

    if (!local_state.email) {
      valid = false;
      update_state.error_email = "Invalid email";
    } else {
      update_state.error_email = null;
    }

    return { valid, update_state };
  }

  render() {
    return (
      <>
        <SignupHeaderV3
          markets={this.props.markets}
          selected_market={this.props.selected_market}
          updateStateValue={()=>{}}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
          select_enabled={false}
          currentScreen={this.props.currentScreen}
          total_screens={this.props.total_screens}
        />
        <EmailInputGUI
          email={this.state.email}
          error_email={this.state.error_email}
          changeInputField={this.changeInputField}
          validateAccountDetails={this.validateAccountDetails}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
          previousScreen={this.props.previousScreen}
        />
      </>
    );
  }
}

export default EmailInput