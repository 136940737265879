import React from "react";
import LockIcon from "icon-locked-min.svg";

class PaymentDueRecap extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='payment-recap'>
        <div className='payment-recap-icon'>
          <img src={LockIcon} />
        </div>
        <div className='payment-recap-text'>
          $0.00 due today<br/>skip/cancel any time
        </div>
      </div>
    );
  }
}

export default PaymentDueRecap