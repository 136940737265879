import React from "react";
import BannerImg from 'free-trial-banner-background.svg'

class FreeTrialBanner extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className="free-trial-container">
        <div className="free-trial-banner">
          <div className="free-trial-banner-title">Your first month is free! 🥳</div>
          <div className="free-trial-banner-message">$0 due today</div>
          <div className="free-trial-banner-background">
            <img src={BannerImg} />
          </div>
        </div>
      </div>
    )
  }
}

export default FreeTrialBanner