import React from "react";
import moment from 'moment-timezone';
import FinancialImpact from "./financial_impact";
import AboveFourStars from "./above_four_stars";
import BelowThreeStars from "./below_three_stars";

import IconTotalCustomers from "icon-total-customers.svg";
import IconBroughtFriends from "icon-brought-friends.svg";
import IconOverallRating from "icon-overall-rating.svg";
import IconNewCustomers from "icon-new-customers.svg";
import IconWouldReturn from "icon-would-return.svg";
import FormBuilder from "../admin_app/shared/form_builder";
import SocialImpact from "./social_impact";
import IconMembersMatched from "icon-members-matched.svg";
import ReportCalculator from "./report_calculator";
import ReportFormatter from "./report_formatter";

class PartnerReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.recalculateState(props, true);

    this.renderHeader = this.renderHeader.bind(this);
    this.recalculateState = this.recalculateState.bind(this);
    this.navigateReports = this.navigateReports.bind(this);
    this.updateFields = this.updateFields.bind(this);
    this.hideEditButton = this.hideEditButton.bind(this);
    this.selectEditButton = this.selectEditButton.bind(this);
    this.saveReportChanges = this.saveReportChanges.bind(this);
    this.getPartnerReportUpdates = this.getPartnerReportUpdates.bind(this);
  }

  recalculateState(data, load = false, input = null) {
    let calculator = new ReportCalculator();
    let report_fields = {};
    if (load) {
      report_fields = calculator.calculateReportFields(data.partner_report);
    } else {
      report_fields = Object.assign({}, this.state);
    }

    if (input != null) {
      if (input.discounts != null) {
        report_fields.discounts = parseFloat(input.discounts) ? input.discounts : null;
      }
      if (input.total_spent != null) {
        // total_cost_of_sales = parseFloat(input.total_cost_of_sales) ? input.total_cost_of_sales : null;
        report_fields.total_spent = input.total_spent;
      }
      if (input.cost_of_goods_sold != null) {
        // cost_of_goods_sold = parseFloat(input.cost_of_goods_sold) ? input.cost_of_goods_sold : null;
        report_fields.cost_of_goods_sold = input.cost_of_goods_sold;
      }
      if (input.total_customers != null) {
        // cost_of_goods_sold = parseFloat(input.total_customers) ? input.total_customers : null;
        report_fields.total_customers = input.total_customers;
      }
      if (input.total_customers != null) {
        // total_customers = parseFloat(input.total_customers) ? input.total_customers : null;
        report_fields.total_customers = input.total_customers;
      }

      if (input.social_links != null) {
        report_fields.social_links = input.social_links;
      }
    }

    if (load) {
      report_fields.can_edit = data.can_edit || false;
      report_fields.show_edit = report_fields.can_edit && data.show_edit;
      report_fields.changes = false;

      report_fields.og_discounts = report_fields.discounts;
      report_fields.og_total_spent = report_fields.total_spent;
      report_fields.og_cost_of_goods_sold = report_fields.cost_of_goods_sold;
      report_fields.og_total_customers = report_fields.total_customers;
      report_fields.og_social_links = report_fields.social_links;

      report_fields.og_can_edit = data.can_edit || false;
      report_fields.og_show_edit = data.show_edit;
      report_fields.dates = data.dates;

      report_fields.no_previous_reports = false;
      report_fields.is_extrapolated = false;

      let should_try_to_extrapolate = !report_fields.total_spent || !report_fields.discounts || !report_fields.cost_of_goods_sold;
      should_try_to_extrapolate = should_try_to_extrapolate ? (report_fields.total_customers > 0) : should_try_to_extrapolate;

      if (should_try_to_extrapolate) {
        report_fields.previous_report_calcs = [];
        data.previous_month_partner_reports.forEach((report) => {
          let calculated_report = calculator.calculateReportFields(report);
          let can_extrapolate_from = !(!calculated_report.net_revenue || calculated_report.net_revenue < 0 || !calculated_report.total_spent || !calculated_report.discounts || !calculated_report.cost_of_goods_sold || !calculated_report.total_customers);

          if (can_extrapolate_from) {
            report_fields.previous_report_calcs.push(calculated_report);
          }
        });

        if (report_fields.previous_report_calcs.length == 0) {
          report_fields.no_previous_reports = true;
        } else {
          report_fields.is_extrapolated = true;

          let cache = {
            total_spent: report_fields.total_spent,
            discounts: report_fields.discounts,
            total_cash: report_fields.total_cash,
            net_revenue: report_fields.net_revenue,
            cost_per_customer: report_fields.cost_per_customer,
            new_sales_above_discount: report_fields.new_sales_above_discount,
            total_cost_of_sales: report_fields.total_cost_of_sales,
            average_spend_per_user: report_fields.average_spend_per_user,
            cost_of_goods_sold: report_fields.cost_of_goods_sold,
          }

          report_fields.cost_of_goods_sold = this.average_report_fields(report_fields.previous_report_calcs, "cost_of_goods_sold");
          // let local_members_matched = this.average_report_fields(report_fields.previous_report_calcs, "members_matched");
          let local_total_customers = report_fields.total_customers || this.average_report_fields(report_fields.previous_report_calcs, "total_customers");

          report_fields.average_spend_per_user = report_fields.previous_report_calcs[0].average_spend_per_user;
          let last_average_discount_per_user = report_fields.previous_report_calcs[0].average_discount_per_user;

          report_fields.total_spent = local_total_customers * report_fields.average_spend_per_user;
          report_fields.discounts = local_total_customers * last_average_discount_per_user;
          report_fields.total_cash = report_fields.total_spent - report_fields.discounts;
          report_fields.net_revenue = (report_fields.total_cash - (report_fields.total_spent * report_fields.cost_of_goods_sold)).toFixed(2);
          report_fields.cost_per_customer = (report_fields.net_revenue / local_total_customers).toFixed(2);
          report_fields.new_sales_above_discount = report_fields.total_spent - report_fields.discounts;
          report_fields.total_cost_of_sales = report_fields.total_spent * report_fields.cost_of_goods_sold;

          report_fields.ex_total_spent = report_fields.total_spent;
          report_fields.ex_cost_of_goods_sold = report_fields.cost_of_goods_sold
          report_fields.ex_discounts = report_fields.discounts;
          report_fields.ex_total_cash = report_fields.total_cash;
          report_fields.ex_net_revenue = report_fields.net_revenue;
          report_fields.ex_cost_per_customer = report_fields.cost_per_customer;
          report_fields.ex_new_sales_above_discount = report_fields.new_sales_above_discount;
          report_fields.ex_total_cost_of_sales = report_fields.total_cost_of_sales;
          report_fields.ex_average_spend_per_user = report_fields.average_spend_per_user;

          Object.assign(report_fields, cache);
        }

      }
    } else {
      report_fields.changes = this.state.og_discounts != report_fields.discounts
        || this.state.og_total_spent != report_fields.total_spent
        || this.state.og_cost_of_goods_sold != report_fields.cost_of_goods_sold
        || this.state.og_total_customers != report_fields.total_customers
        || this.state.og_social_links != report_fields.social_links;
    }

    return report_fields;
  }

  average_report_fields(reports, field_name) {
    let total = 0;
    reports.forEach((report) => {
      total += report[field_name];
    });

    return total / reports.length;
  }

  navigateReports(event) {
    if (event.target.value != this.props.report_date) {
      window.location.href = `/partner_reports/${this.props.partner_report.offer_id}?date=${event.target.value}`;
    }
  }

  updateFields(event) {
    const target = event.target;
    const name = target.name;

    this.setState(this.recalculateState(this.props, false, { [name]: target.value }));
  }

  selectEditButton(event) {
    if (!this.state.can_edit) return false;

    if (!this.state.show_edit) {
      this.setState({ show_edit: true });
      return true;
    }

    if (this.state.changes) {
      this.saveReportChanges();
    }
  }

  hideEditButton(event) {
    if (this.state.show_edit) {

      if (this.state.changes) {
        if (confirm("You have unsaved changes. Are you sure you want to stop editing and revert your changes?")) {
          this.setState({
            discounts: this.state.og_discounts,
            total_spent: this.state.og_total_spent,
            cost_of_goods_sold: this.state.og_cost_of_goods_sold,
            total_customers: this.state.og_total_customers,
            social_links: this.state.og_social_links,
            changes: false,
            show_edit: false
          })
        }
      } else {
        this.setState({ show_edit: false });
      }
      return false;
    }
    this.setState({ can_edit: false });
  }

  getPartnerReportUpdates() {
    let partner_report = {};

    let total_discounted = parseFloat(this.state.discounts);
    let total_spent = parseFloat(this.state.total_spent);
    let cost_of_goods_sold = parseFloat(this.state.cost_of_goods_sold);
    let customers_redeemed = parseFloat(this.state.total_customers);

    if (!isNaN(total_discounted)) {
      partner_report.total_discounted = total_discounted;
    } else {
      partner_report.total_discounted = null;
    }

    if (!isNaN(total_spent)) {
      partner_report.total_spent = total_spent;
    } else {
      partner_report.total_spent = null;
    }

    if (!isNaN(cost_of_goods_sold)) {
      partner_report.cost_of_goods_sold = cost_of_goods_sold;
    } else {
      partner_report.cost_of_goods_sold = null;
    }

    if (!isNaN(customers_redeemed)) {
      partner_report.customers_redeemed = customers_redeemed;
    } else {
      partner_report.customers_redeemed = null;
    }

    partner_report.social_post_block = this.state.social_links

    return partner_report;
  }

  saveReportChanges() {
    var path = "/partner_reports/" + this.props.partner_report.offer_id + "?date=" + this.props.report_date;

    var form_builder = new FormBuilder();

    var form_object = {
      partner_report: this.getPartnerReportUpdates(),
      edit: this.state.og_show_edit
    }

    var form_data = form_builder.createForm(form_object);

    $.ajax({
      method: "PUT",
      url: path,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        let update_state = this.recalculateState(data, true, null);
        this.setState(update_state);
      },
      error: (error) => {
        console.log('uh oh')
      }
    });
  }

  survey_answer_boolean(value) {
    if (value == "yes") return true;
    if (value == "no") return false;
    return value;
  }

  isABoolean(value) {
    return typeof value === 'boolean';
  }

  renderHeader(edit_button_classes) {
    return (
      <>
        <div className="partner-report-spacer">
          <div className="partner-report-header">
            <div className="partner-report-title-container">
              {this.props.offer_image &&
                <div className="title-image-cropper">
                  <img src={this.props.offer_image || ""} alt="" />
                </div>
              }
              <div className="partner-report-title">
                <div className="title-label">Partner Report</div>
                <div className="main-title">{this.props.partner_report.partner_name}</div>
              </div>
            </div>
            <div className="partner-report-date">
              {this.state.can_edit &&
                <div className={"edit-button-container" + (this.state.og_show_edit ? "" : " can-hide-edit")}>
                  <button onClick={this.selectEditButton} className={"partner-report-edit " + edit_button_classes}>{this.state.show_edit ? "Save Changes" : "Edit"}</button>
                  <button onClick={this.hideEditButton} className={"partner-report-edit hide-edit " + edit_button_classes + (this.state.og_show_edit ? "" : " show-hide-edit")}>✕</button>
                </div>
              }
              <div className="select-fix">
                <select name="report-month" id="report-month" defaultValue={this.props.report_date} onChange={this.navigateReports}>
                  {this.state.dates.map((report_date, index) => (
                    <option key={index} value={report_date}>{moment(report_date).format("MMMM YYYY")}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="partner-report-spacer dummy-spacer">
          <div className="partner-report-header">
            <div className="partner-report-title-container">
              {this.props.offer_image &&
                <div className="title-image-cropper">
                  <img src={this.props.offer_image || ""} alt="" />
                </div>
              }
              <div className="partner-report-title">
                <div className="title-label">Partner Report</div>
                <div className="main-title">{this.props.partner_report.partner_name}</div>
              </div>
            </div>
            <div className="partner-report-date">
              {this.state.can_edit &&
                <div className={"edit-button-container" + (this.state.og_show_edit ? "" : " can-hide-edit")}>
                  <button onClick={this.selectEditButton} className={"partner-report-edit " + edit_button_classes}>{this.state.show_edit ? "Save Changes" : "Edit"}</button>
                  <button onClick={this.hideEditButton} className={"partner-report-edit hide-edit " + edit_button_classes + (this.state.og_show_edit ? "" : " show-hide-edit")}>✕</button>
                </div>
              }
              <div className="select-fix">
                <select name="report-month" id="report-month" defaultValue={this.props.report_date} onChange={this.navigateReports}>
                  {this.state.dates.map((report_date, index) => (
                    <option key={index} value={report_date}>{moment(report_date).format("MMMM YYYY")}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  render() {
    let formatter = new ReportFormatter();
    let total_customer_field = (<div className={"stat-cell-value" + (this.state.total_customers == "NO DATA" ? " no-data" : "")}>{formatter.data_presenter("total_customers", this.state.total_customers)}</div>);
    let edit_classes = [];

    if (this.state.can_edit) {
      edit_classes.push("can-edit");
    }
    if (this.state.show_edit) {
      edit_classes.push("show-edit");
      total_customer_field = (<input type="number" name="total_customers" className={"stat-cell-value" + (this.state.total_customers == "NO DATA" ? " no-data" : "")} value={this.state.total_customers} onChange={this.updateFields} />);
    }

    if (this.state.changes) {
      edit_classes.push("save-changes");
    }

    let edit_button_classes = edit_classes.join(" ");


    return (
      <div className={"partner-report-page" + (this.state.show_edit ? " show-edit" : "")}>
        {this.renderHeader(edit_button_classes)}
        <div className="stat-cells">
          <div className="stat-cell">
            <img src={IconOverallRating} alt="" />
            <div className="stat-cell-title">Overall Rating</div>
            <div className={"stat-cell-value" + (this.state.brought_non_offline_friends == "NO DATA" ? " no-data" : "")}>{this.state.overall_rating}</div>
          </div>

          <div className="stat-cell">
            <img src={IconMembersMatched} alt="" />
            <div className="stat-cell-title">Members Matched</div>
            <div className={"stat-cell-value"}>{this.state.members_matched}</div>
          </div>

          <div className="stat-cell">
            <img src={IconTotalCustomers} alt="" />
            <div className="stat-cell-title">{formatter.label_presenter("total_customers", this.state.total_customers)}</div>
            {total_customer_field}
          </div>

          <div className="stat-cell">
            <img src={IconNewCustomers} alt="" />
            <div className="stat-cell-title">{formatter.label_presenter("new_customers", this.state.new_customers)}</div>
            <div className={"stat-cell-value" + (this.state.new_customers == 0 ? " no-data" : "")}>{formatter.data_presenter("new_customers", this.state.new_customers)}</div>
          </div>

          <div className="stat-cell bnof">
            <img src={IconBroughtFriends} alt="" />
            <div className="stat-cell-title">{formatter.label_presenter("brought_non_offline_friends", this.state.brought_non_offline_friends)}</div>
            <div className={"stat-cell-value" + (this.state.brought_non_offline_friends == "NO DATA" ? " no-data" : "")}>{formatter.data_presenter("brought_non_offline_friends", this.state.brought_non_offline_friends)}</div>
          </div>

          <div className="stat-cell">
            <img src={IconWouldReturn} alt="" />
            <div className="stat-cell-title">{formatter.label_presenter("would_go_again", this.state.would_go_again)}</div>
            <div className={"stat-cell-value" + (this.state.brought_non_offline_friends == "NO DATA" ? " no-data" : "")}>{formatter.data_presenter("would_go_again", this.state.would_go_again)}</div>
          </div>

        </div>
        <FinancialImpact
          average_spend_per_user={this.state.average_spend_per_user}
          total_customers={this.state.total_customers}
          discounts={this.state.discounts}
          total_cost_of_sales={this.state.total_cost_of_sales}
          new_sales_above_discount={this.state.new_sales_above_discount}
          cost_of_goods_sold={this.state.cost_of_goods_sold}
          total_cash={this.state.total_cash}
          total_spent={this.state.total_spent}
          net_revenue={this.state.net_revenue}
          kickback_due={this.state.kickback_due}
          cost_per_customer={this.state.cost_per_customer}
          update_fields={this.updateFields}
          edit_mode={this.state.show_edit}
          is_extrapolated={this.state.is_extrapolated}
          no_previous_reports={this.state.no_previous_reports}
          ex_cost_of_goods_sold={this.state.ex_cost_of_goods_sold}
          ex_total_spent={this.state.ex_total_spent}
          ex_discounts={this.state.ex_discounts}
          ex_total_cash={this.state.ex_total_cash}
          ex_net_revenue={this.state.ex_net_revenue}
          ex_cost_per_customer={this.state.ex_cost_per_customer}
          ex_new_sales_above_discount={this.state.ex_new_sales_above_discount}
          ex_total_cost_of_sales={this.state.ex_total_cost_of_sales}
          ex_average_spend_per_user={this.state.ex_average_spend_per_user}
        />
        <AboveFourStars
          offer_assignments={this.state.highly_reviewed_offer_assignments}
          new_customers={this.state.above_4_new_customers}
          brought_non_offline_friends={this.state.above_4_brought_non_offline_friends}
          would_go_again={this.state.above_4_would_go_again}
        />
        <BelowThreeStars
          offer_assignments={this.state.other_reviewed_offer_assignments}
        />
        <SocialImpact
          social_links={this.state.social_links}
          updateFields={this.updateFields}
          edit_mode={this.state.show_edit}
        />

        <div className="background-bar"></div>
      </div>
    );
  }
}

export default PartnerReport