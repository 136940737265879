import React from "react";
import moment from 'moment-timezone';
import YouReIn from "you-re-in.svg";
import IconFlag from "flag.svg";
import IconCalendar from "icon-calendar.svg";

class EventTicketConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.closeConfirmed = this.closeConfirmed.bind(this);
  }

  closeConfirmed() {
    if (typeof this.props.closeConfirmed == "function") {
      this.props.closeConfirmed();
    }
  }

  render() {
    const { name, description, address, event_type, redeem_start_date, redeem_end_date } = this.props.event;
    let cost = ((this.props.event.ticket_price * this.props.tickets_count)/100.0)
    if(Number.isInteger(parseFloat(cost))) {
      cost = cost.toString();
    } else {
      cost = cost.toFixed(2);
    }

    var date_field = moment(redeem_start_date).format("MMMM Do [@] h:mma");
    if (event_type === 'AnyTime') {
      if (redeem_end_date) {
        var date_field = moment(redeem_start_date).format("MMMM Do") + " - " + moment(redeem_end_date).format("MMMM Do, YYYY");
      } else {
        var date_field = moment(redeem_start_date).format("MMMM Do YYYY");
      }
    }

    return (
      <>
        {event_type === 'DateTime' ?
          <div className="event-ticket-confirmed">
            <div className="event-ticket-confirmed-header">
              <div>Tickets Confirmed & In App</div>
            </div>
            <div className="event-ticket-confirmed-box">
              <img src={this.props.event.images[0].image} alt="Offline" />
              <div className="event-ticket-confirmed-info">
                <div className="event-ticket-confirmed-title">
                  {name}
                </div>
                <div className="event-ticket-confirmed-description">
                  {description}
                </div>
                <div className="event-ticket-confirmed-details">
                  <div>
                    <span>Location</span>
                    <span>{address.city}, {address.state}</span>
                  </div>
                  <div>
                    <span>Date</span>
                    <span>{moment(this.props.event_date.date).format("MMM Do YYYY")}</span>
                  </div>
                  <div>
                    <span>Arrival Time</span>
                    <span>{moment(this.props.event_time.time, "HH:mm A").format("h:mm A")}</span>
                  </div>
                  <div>
                    <span>Tickets</span>
                    <span>{this.props.tickets_count}</span>
                  </div>
                  <div>
                    <span>Total</span>
                    <span>${cost}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="event-ticket-confirmed popup-offer">
            {this.props.confirmed ?
              <>
                <div className="you-re-in">
                  <img src={YouReIn} alt="Offline" />
                </div>
                <div className="event-details">
                  <div>
                    <div>
                      <img src={IconFlag} alt="Offline" />
                      <span>&nbsp;{address.city}, {address.state}</span>
                    </div>
                    <div>
                      <img src={IconCalendar} alt="Offline" />
                      <span>&nbsp;{date_field}</span>
                    </div>
                  </div>
                  <div>
                    <img src={this.props.event.images[0].image} alt="Offline" />
                    <div>
                      {name}
                    </div>
                  </div>
                </div>
                <div className="popup-offer-desc">
                  {description}
                </div>
              </> :
              <>
                <div className="event-ticket-confirmed-header">
                  <div>Confirm And Pay</div>
                </div>
                <div className="event-ticket-confirmed-box">
                  <img src={this.props.event.images[0].image} alt="Offline" />
                  <div className="event-ticket-confirmed-info">
                    <div className="event-ticket-confirmed-title">
                      Pop Up Offer: {name}
                    </div>
                    <div className="event-ticket-confirmed-description">
                      {description}
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        }
      </>
    )
  }
}

export default EventTicketConfirmation;