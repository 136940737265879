import React from "react";
import Select from 'react-select'

class FormSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (props.isERB) {
      let defaultValue = this.props.multiselect ? [] : null;
      let localValue = props.value;
      if (typeof localValue == "string") {
        localValue = [localValue]
      } else if (!localValue) {
        localValue = defaultValue
      }
      this.state = {
        value: !localValue ?
          []
          : localValue.map(value => {
            if (isNaN(parseInt(value))) {
              return value;
            }
            return parseInt(value);
          })
      }
    }

    this.scroll_ref = React.createRef();
    this.last_value = undefined;

    this.options = props.defaultOption ? [props.defaultOption, ...props.options] : props.options;

    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.onMenuOpen = this.onMenuOpen.bind(this);
    this.getSelectedOptions = this.getSelectedOptions.bind(this);
    this.renderFormSelect = this.renderFormSelect.bind(this);
  }

  // Make sure to check if the props have updated
  componentDidUpdate(prevProps) {
    if (this.props.multiselect) {
      let prev_options = JSON.stringify(prevProps.options);
      let current_options = JSON.stringify(this.props.options);
      if (prev_options !== current_options) {
        this.setState({ dummy_value: !this.state.dummy_value });
        this.options = this.props.defaultOption ? [this.props.defaultOption, ...this.props.options] : this.props.options;
      }
    }
  }

  getSelectedOptions() {
    const key = this.props.labelAsValue ? "label" : "value";
    let array_of_values = [];
    if (this.props.isERB) {
      array_of_values = Array.isArray(this.state.value) ? this.state.value : [this.state.value];
    } else {
      array_of_values = Array.isArray(this.props.value) ? this.props.value : [this.props.value];
    }

    // react-select expects its values to be set an array of options from option property
    // we need to map the value only property to the options
    let selected_options = this.options.filter(option => array_of_values.includes(option[key]));
    return this.props.multiselect ? selected_options : selected_options[0];
  }

  onSelectionChange(selection) {
    // Set what we will send back, either the value or the label
    const key = this.props.labelAsValue ? "label" : "value";

    // If this is multiselect and we have an array, map all the values to send back
    let values = null;
    if (Array.isArray(selection)) {
      values = selection.map((option) => option[key]);
      if (this.props.scrollCloseToLastOption) {
        let selection_options = this.getSelectedOptions();
        this.last_value = selection.filter(x => !selection_options.includes(x))[0];
      }
    } else {
      values = selection[key];
    }

    if (!this.props.isERB) {
      this.props.onChange(this.props.name, values);
    } else {
      this.setState({ value: values })
    }

  }

  onMenuOpen(event) {
    if (this.props.scrollCloseToLastOption && this.last_value && this.scroll_ref.current) {
      setTimeout(() => {
        let index = this.options.indexOf(this.last_value);
        let find_option = this.scroll_ref.current.querySelector(`[id*="option-${index}"]`)

        if (find_option) {
          let menulist = find_option.parentElement;

          menulist.scrollTop = (find_option.clientHeight * (index - this.getSelectedOptions().length)) - (menulist.clientHeight / 2);
        }
      }, 50);
    }
  }

  getScrollParent(node) {
    if (node == null) {
      return null;
    }

    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return getScrollParent(node.parentNode);
    }
  }

  renderFormSelect() {
    if (!this.props.isERB) {
      return;
    }

    let selected_options = this.getSelectedOptions();
    let selected_values = 0;
    if (selected_options) {
      selected_values = Array.isArray(selected_options) ?
        selected_options.map(option => option.value)
        : selected_options.value;
    }

    return (
      <select name={this.props.select_name} style={{ display: 'none' }} value={selected_values} multiple={this.props.multiselect} onChange={() => { }}>
        {this.options.map((option) =>
          <option key={'hide' + option.value} label={option.label} value={option.value} />
        )}
      </select>
    )
  }

  render() {
    let value = this.getSelectedOptions();
    let scrollToValue = undefined;
    if (!this.props.renderOptions && this.props.scrollCloseToLastOption) {
      const key = this.props.labelAsValue ? "label" : "value";

      if (this.last_value) {
        scrollToValue = this.last_value;
      } else if (Array.isArray(value) && value.length) {
        scrollToValue = value[0];
      } else if (value) {
        scrollToValue = value;
      }

      let default_index = this.options.indexOf(scrollToValue);
      let next_option = this.options[default_index + 1];
      let prev_option = this.options[default_index - 1];

      scrollToValue = next_option ? next_option : prev_option;

      this.last_value = scrollToValue;
    }

    let render_options = this.props.renderOptions || this.options;

    return (
      <div ref={this.scroll_ref} className={this.props.className || ""}>
        <Select
          name={this.props.name}
          id={this.props.id}
          value={value}
          className={this.props.className || false}
          options={render_options}
          onChange={this.onSelectionChange}
          onMenuOpen={this.onMenuOpen}
          isMulti={this.props.multiselect}

        >
        </Select>
        {this.renderFormSelect()}
        <div style={{display: "none", opacity: (this.state.dummy_value ? "1" : "0")}}>{this.state.dummy_value}</div>
      </div>
    )
  }
}

export default FormSelect;