import React from "react";

import {trackScreenLoad} from "../utils/analytic_tracking.jsx";

class BasicScreenLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.scrollingElement.scrollTop = 0;
    if (this.props.analytic_tracking) {
      trackScreenLoad(this.props.analytic_tracking);
    }
  }

  componentWillUnmount() {
    if (this.props.customerio_tracking) {
      this.props.customerio_tracking();
    }
  }

  render() {
    return (
      <>
        {this.props.header}
        <div className="signup-column basic-screen-layout">
          { this.props.title &&
            <div className="basic-screen-layout-title">{this.props.title}</div>
          }
          { this.props.subtitle &&
            <div className="basic-screen-layout-subtitle">{this.props.subtitle}</div>
          }
          {this.props.children && 
            <div className="basic-screen-layout-content">
              {this.props.children}
            </div>
          }
        </div>
        {this.props.footer}
      </>
    );
  }
}

export default BasicScreenLayout