import React from "react";
import OfferFull from "./offer_full";

class OfferInfoSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offer_id: this.props.offer_id,
      offer: this.props.offer || null
    }

    this.close_slider = this.close_slider.bind(this);
    this.close_overlay = this.close_overlay.bind(this);
  }

  componentDidMount() {
    if (!this.state.offer) {
      $.ajax({
        method: "GET",
        url: "/offers/" + this.state.offer_id + "/info",
        data: null,
        processData: false,
        contentType: false,
        success: (data) => {
          let offer = Object.assign({}, data);
          this.setState({ offer: offer });
          this.props.loaded_offer(offer);
        },
        error: (error) => {

        }
      });
    }
  }

  close_overlay(e) {
    if (e.target.classList.contains('offer-select-slider-overlay')) {
      this.close_slider();
    }
  }

  close_slider() {
    this.props.close_slider({
    });
  }

  render() {

    return (
      <div className="offer-select-slider-overlay signup-v2" onMouseDown={this.close_overlay}>
        <div ref={this.slider_ref} className="offer-select-slider-container">
          {/* <div className="offer-select-slider-title">Optional Title </div> */}
          <div className="offer-select-slider-body">
            <div className={"loading-container" + (this.state.offer ? " no-display" : "")}>
              <h3>Loading Offer</h3>
              <div className="loading-spin"><div></div><div></div><div></div><div></div></div>
            </div>
            {this.state.offer &&
              <OfferFull
                show_video={true}
                show_image={true}
                offer={this.state.offer}
                className="offer_select_page"
              />
            }
          </div>
          <div className="offer-select-slider-footer">
            <button className="offer-select-cancel" onClick={this.close_slider}>Close</button>
          </div>
        </div>
      </div>
    )
  }
}

export default OfferInfoSlider