import React from "react";
import BannerImg from 'free-trial-banner-background.svg'

class ExtendedFooterContent extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    let extra_class = this.props.extra_class ? this.props.extra_class : ""

    return (
      <div className={"extended-footer-content " + extra_class}>
        {this.props.children}
      </div>
    )
  }
}

export default ExtendedFooterContent