import React from "react";
import IconCheck from 'icon-plan-check.svg';
import IconInfo from 'icon-plan-info.svg';
import WrappingTooltip from "../signup_vx/shared/wrapping-tooltip";

class PlanComparisonCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_signup_9: false
    }
  }

  componentDidMount() {
    this.setState({ is_signup_9: document.querySelector('.sign-up-main-content.signup_v9') !== null });
  }

  renderSummary(summary, idx, plan) {
    let bottom_offset_adjustment = summary.bottom_offset || 30;
    if (this.state.is_signup_9) {
      let line_height = 30;
      let signup_9_line_height = 16;
      let line_count = summary.bottom_offset / line_height;
      bottom_offset_adjustment = signup_9_line_height * line_count;
    }

    return (
      <div className="plan" key={idx}>
        <div className={`${summary.plan && summary.plan !== plan ? 'hidden' : ''}`}>
          {!summary.content &&
            <span>{summary.title}</span>
          }
          {summary.content &&
            <WrappingTooltip
              open_upwards={true}
              bottom_offset={bottom_offset_adjustment}
              max_width={160}
              tooltip_element={summary.content}>
              <span>{summary.title}</span>
              <img src={IconInfo} data-tooltip="Checked-in / Sold / Max Tickets" alt="" />
            </WrappingTooltip>
          }
        </div>
        {summary.plan && summary.plan !== plan &&
          <hr className="hidden-plan" />
        }
      </div>
    )
  }

  price_number_formatter(price) {
    return Number.isInteger(price) ? price : price.toFixed(2);
  }

  render() {
    const { plan, summaries, isSelected } = this.props;
    let price = this.props.special_price || this.props.price;
    let save = this.props.special_save_percentage || this.props.save || 0;

    return (
      <div className={`plan-card ${isSelected ? 'selected' : ''}`}>
        {save > 0 &&
          <>
            {this.props.special_save_percentage &&
            <div className="plan-save">{`Save `}
              <del>{` ${this.props.save}%`}</del>
              {` ${save}%`}
            </div>
            }
            {!this.props.special_save_percentage &&
              <div className="plan-save">Save {save}%</div>
            }
          </>
        }
        <div className="plan-header">
          <label className="plan-tag">{plan}</label>
          <div className="plan-price">
            <div>
              {this.props.special_price &&
                <>
                  <span className="price-dollar strike">$</span>
                  <span className="price-number strike">{this.price_number_formatter(this.props.price)}</span>
                </>
              }
              <span className="price-dollar">$</span>
              <span className="price-number">{this.price_number_formatter(price)}</span>
              <span className="price-term">/mo</span>
            </div>
            <div className={plan !== 'annual' ? `hidden` : ''}>
              {this.props.special_save_percentage &&
                <span>{`Billed `}
                  <del>{`$${this.price_number_formatter(this.props.price * 12)}`}
                  </del>
                  {` $${this.price_number_formatter(price * 12)}/yr`}
                </span>
              }
              {!this.props.special_save_percentage &&
                <span>{`Billed $${this.price_number_formatter(price * 12)}/yr`}</span>
              }
            </div>
          </div>
          <div className="plan-select">
            {!isSelected ?
              <button className="plan-button" data-plan={plan} onClick={this.props.onSelectPlan}>Select</button>
              :
              <div className="plan-button-selected">
                <img src={IconCheck} alt="" />
                Selected
              </div>
            }
          </div>
        </div>
        <div className="plan-list">
          {summaries.map((summary, idx) => this.renderSummary(summary, idx, plan))}
        </div>
      </div >
    );
  }
};

export default PlanComparisonCard
