import React from "react";

class SignupHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="signup-header">
        <div className="signup-header-content signup-mobile-styling">
          {this.props.mobile_left   || (<div></div>)}
          {this.props.mobile_center || (<div></div>)}
          {this.props.mobile_right  || (<div></div>)}
        </div>
        <div className="signup-header-content signup-desktop-styling">
          {this.props.desktop_left   || (<div></div>)}
          {this.props.desktop_center || (<div></div>)}
          {this.props.desktop_right  || (<div></div>)}
        </div>
      </div>
    );
  }
}

export default SignupHeader