import React from "react";
import IconFlag from "flag.svg";
import IconLinkMenu from "link-menu.png";
import IconLinkWebsite from "link-website.png";
import IconLinkInstagram from "link-instagram.png";
import IconLinkReserve from "link-reserve.png";
import IconDayOpen from "icon-fpd-open.svg";
import IconDayClosed from "icon-fpd-closed.svg";
import IconDayWarning from "icon-fpd-warning.svg";
import SharePageBackground from "share-page-background.svg";
import IconCloseShareFooter from "icon-close-share-footer.svg";
import ReactHlsPlayer from 'react-hls-player';
// import MuxPlayer from "@mux/mux-player-react";
import GoogleMapReact from 'google-map-react';

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import moment from "moment-timezone";

class eventShare extends React.Component {
  constructor(props) {
    super(props);

    let video_image_url = this.props.event.video_url ? this.props.event.video_url.split('/').filter(s => s.endsWith('.m3u8'))[0] : null;
    if (video_image_url) {
      video_image_url = `https://image.mux.com/${video_image_url.replace('.m3u8', '')}/thumbnail.jpg`;
    }

    let start_date = moment(this.props.event.start_date);
    let end_date = moment(this.props.event.end_date);
    let lottery_status = "DONE";

    let now = moment();
    if (this.props.event.start_date && start_date.isAfter(now)) {
      lottery_status = "UPCOMING";
    } else if (this.props.event.end_date && end_date.isAfter(now)) {
      lottery_status = "OPEN";
    } else {
      lottery_status = "CLOSED";
    }

    this.state = {
      show_video: true,
      show_image: true,
      video_image_url: video_image_url || "",
      video_playing: false,
      hide_footer: false,
      lottery_status
    }

    this.player_ref = React.createRef();
    this.countdown_ref_one = React.createRef();
    this.countdown_ref_two = React.createRef();
    this.renderDates = this.renderDates.bind(this);
    this.render_outbound_links = this.render_outbound_links.bind(this);
    this.hide_footer = this.hide_footer.bind(this);
    this.map_image_to_fine_print_day = this.map_image_to_fine_print_day.bind(this);
    this.get_lottery_message = this.get_lottery_message.bind(this);
  }

  componentDidMount() {
    if (this.player_ref.current) {
      this.player_ref.current.poster = this.state.video_image_url;
      this.player_ref.current.addEventListener('play', () => {
        this.setState({ video_playing: true });
      });
      this.player_ref.current.addEventListener('pause', () => {
        this.setState({ video_playing: false });
      });
    }

    if (this.state.lottery_status == "OPEN" && this.countdown_ref_two.current && this.countdown_ref_one.current) {
      setInterval(() => {
        if (this.countdown_ref_two.current && this.countdown_ref_one.current) {
          this.countdown_ref_two.current.textContent = this.get_lottery_message();
          this.countdown_ref_one.current.textContent = this.get_lottery_message();
        }
      }, 1000);
    }

  }

  get_lottery_message() {
    if (this.state.lottery_status == "UPCOMING") {
      return "UPCOMING EVENT";
    } else if (this.state.lottery_status == "CLOSED") {
      return "RSVP Closed";
    } else if (this.state.lottery_status == "OPEN") {
      let count_to = moment(this.props.event.end_date);
      let days_until = count_to.diff(moment(), 'days');
      if (days_until > 1) {
        return `${days_until} DAYS LEFT TO RSVP`;
      } else {
        let duration = moment.duration(count_to.diff(moment()));
        return `${String(duration.hours() + (duration.days() * 24)).padStart(2, '0')}:${String(duration.minutes()).padStart(2, '0')}:${String(duration.seconds()).padStart(2, '0')} LEFT TO RSVP`;
      }
    } else if (this.state.lottery_status == "DONE") {
      return "THIS EVENT HAS PAST";
    }
  }

  map_image_to_fine_print_day(day) {
    if (day.state == "Valid") {
      return IconDayOpen;
    } else if (day.state == "Not Valid") {
      return IconDayClosed;
    } else if (day.state == "Closed") {
      return IconDayClosed;
    } else if (day.state == "Warning") {
      return IconDayWarning;
    }
  }

  hide_footer() {
    this.setState({ hide_footer: true });
  }

  renderDates() {
    if (this.props.event.event_type == "AnyTime") {
      return "From " + moment(this.props.event.redeem_start_date).format("MMMM D") + " to " + moment(this.props.event.redeem_end_date).format("MMMM D");
    }
    let dates = this.props.event.event_dates;
    let date_strings = [];

    if (!dates || dates.length == 0) {
      date_strings.push("Date: TBD");
    }

    for (let date of dates) {
      let date_string = moment(date.date).format("dddd, MMMM D");

      if (date.times.length > 1) {
        let first = date.times[0].toLowerCase().replace(":00", "").replace(" ", "").replace("m", "");
        let last = date.times[date.times.length - 1].toLowerCase().replace(":00", "").replace(" ", "").replace("m", "");

        date_string += ` @ ${first} - ${last}`;
      } else if (date.times.length == 1) {
        let first = date.times[0].toLowerCase().replace(":00", "").replace(" ", "").replace("m", "");
        date_string += ` @ ${first}`;
      }

      date_strings.push(date_string);
    }

    return date_strings.join("\n");
  }

  render_outbound_links() {
    let buttons = [];

    if (this.props.event.menu_link) {
      buttons.push(
        <div className="out-link">
          <a target="_blank" href={this.props.event.menu_link}>
            <div><img src={IconLinkMenu} /></div>
            <div className="">MENU</div>
          </a>
        </div>
      );
    }

    if (this.props.event.web_link) {
      buttons.push(
        <div className="out-link">
          <a target="_blank" href={this.props.event.web_link}>
            <div><img src={IconLinkWebsite} /></div>
            <div className="button-text">WEBSITE</div>
          </a>
        </div>
      )
    }

    if (this.props.event.instagram_link) {
      buttons.push(
        <div className="out-link">
          <a target="_blank" href={this.props.event.instagram_link}>
            <div><img src={IconLinkInstagram} /></div>
            <div className="button-text">INSTA</div>
          </a>
        </div>
      )
    }

    if (this.props.event.reservation_link) {
      buttons.push(
        <div className="out-link">
          <a target="_blank" href={this.props.event.reservation_link}>
            <div><img src={IconLinkReserve} /></div>
            <div className="button-text">RESERVE</div>
          </a>
        </div>
      )
    }

    buttons = [].concat(...buttons.map((button, index) => [button, (<div key={"spacer_" + index} className="out-link-spacer"></div>)])).slice(0, -1)

    if (buttons.length == 0) {
      return (<></>);
    }

    return (
      <div className="content-out-links">
        {buttons}
      </div>
    )
  }

  handleApiLoaded(map, maps, address) {
    if (map) {
      map.setOptions({
        clickableIcons: false,
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        fullscreenControl: false,
        gestureHandling: "none",
        keyboardShortcuts: false,
        draggable: false,
      });

      let marker = new maps.Marker({
        position: {
          lat: address.latitude,
          lng: address.longitude
        },
        map,
        title: address.name
      });
    }
  }

  render() {
    let img_url = this.props.event.images[0]?.image;
    let instagram_url = this.props.event.images[0]?.credit_link;
    let video_container_class = this.state.video_playing ? " video-playing-now" : " video-paused-now";
    let address = this.props.event.address;


    return (
      <>
        <img src={SharePageBackground} className="desktop-share-background" alt="" />
        <div id={this.props.event.id} className="share-event-page">
          <div className="share-event-grid">
            <div className="grid-video" /* event Video or Header Image */>
              {this.state.show_image && (!this.state.show_video || !this.props.event.video_url) &&
                <>
                  { instagram_url && instagram_url.length > 0 &&
                    <a target="_blank" href={instagram_url}>
                      <img className="grid-video-content" align="middle" src={img_url} />
                    </a>
                  } 
                  { (!instagram_url || instagram_url.length == 0) &&
                    <img className="grid-video-content" align="middle" src={img_url} />
                  }
                </>
              }
              {this.state.show_video && this.props.event.video_url &&
                <ReactHlsPlayer
                  className={"grid-video-content" + video_container_class}
                  src={this.props.event.video_url}
                  playerRef={this.player_ref}
                  controls={true}
                  autoPlay={true}
                  muted={true}
                />
              }
              <div className={"content-event-amount countdown" + (this.state.lottery_status == "DONE" ? " cd-past" : "")} ref={this.countdown_ref_one}>
                {this.get_lottery_message()}
              </div>
            </div>

            <div className="grid-content" /* Main event Content */ >

              <div className="content-title" /* Heading and location */>
                <div className="pre-title desktop-view">
                  <div className="content-dates">{this.renderDates()}</div>
                  <div className="content-cost-heading">
                    <div className="content-event-amount bubble">
                      {this.props.event.ticket_cost}
                    </div>
                  </div>
                </div>
                <div className="content-heading">
                  {this.props.event.name}
                </div>
              </div>
              {/* <div className="content-teaser">{this.props.event.business_teaser}</div> */}
              <ReactMarkdown
                className="content-teaser"
                remarkPlugins={[remarkGfm]}
                children={this.props.event.description}
              />
              <div className="event-time-block">
                <div className="event-times">{this.renderDates()}</div>
                <div className="event-price">{this.props.event.ticket_cost + '/ticket'}</div>
              </div>
              {this.render_outbound_links()}
              {/* <div className="fine-print-label">event AVAILABILITY</div> */}
              {/* <div className="content-fine-print-days">
                {
                  this.props.event.fine_print_days.map((day, i) =>
                    <div key={i + day.name} className={"day-square " + day.state.toLowerCase().replaceAll(' ', '-')}>
                      <div>{day.name.slice(0, 2)}</div>
                      <img src={this.map_image_to_fine_print_day(day)} alt="" />
                    </div>
                  )
                }
              </div> */}
              {/* <div className="fine-print-note">Check the business website for up-to-date hours</div> */}
              <div className="desktop-devider" />
              <div className={"content-event-amount countdown desktop-view" + (this.state.lottery_status == "DONE" ? " cd-past" : "")} ref={this.countdown_ref_two}>
                {this.get_lottery_message()}
              </div>
              <div className="content-main">
                <div className="sp-address-block">
                  {this.props.event.address &&
                    <div className="sp-address-section">
                      <div className="sp-address-slide">
                        <div className="sp-address-slide-card">
                          <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyDn0-hIOkUfB3xu2eJEYVKGePEZ02bTKyw" }}
                            center={{
                              lat: address.latitude,
                              lng: address.longitude
                            }}
                            zoom={16}
                            className="sp-address-image"
                            yesIWantToUseGoogleMapApiInternals={true}
                            onGoogleApiLoaded={({ map, maps }) => { this.handleApiLoaded(map, maps, address) }}
                          >
                          </GoogleMapReact>
                          <div className="sp-address-text">
                            <div className="sp-address-name">{address.name}</div>
                            <p>
                              {address.street}<br />
                              {address.city}, {address.state} {address.zip}
                            </p>
                          </div>
                          <div className="sp-address-spacer"></div>
                          <div className='sp-address-btn'>
                            <a href={"https://www.google.com/maps/search/?api=1&output=classic&query=" + encodeURIComponent([address.name, address.street, address.city, address.state, address.zip].join(', '))} target="_blank">Get Directions</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="content-sections">
                  {this.props.event.sections.map((section, i) => (
                    <div key={i}>
                      <div className="content-section-title">
                        {section.icon &&
                          <img src={section.icon} alt="" />
                        }
                        <span>{section.title}</span>
                      </div>
                      {
                        section.content_type == "text" && section.content &&
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          children={section.content}
                        />
                      }
                      {
                        section.content_type == "image" &&
                        <p>
                          <img src={section.content} alt="" />
                        </p>
                      }
                    </div>
                  ))}

                </div>
              </div>
            </div>

            <div className={"grid-footer" + (this.state.hide_footer ? " done" : "")} /* Footer Marketing */>
              <img src={IconCloseShareFooter} className="close-grid-footer" alt="" onClick={this.hide_footer} />
              <div className="footer-title">Want to go?</div>
              <div className="footer-content">
                <span>Sign up for Offline + get invited to badass events like this<span className="content-bold"> every. single. month.</span></span>
              </div>
              <div className="footer-out-link">
                <a target="_blank" href="/signup">
                  <div className="button-text">SIGN UP TODAY</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

class Marker extends React.Component {
  render() {
    return <div className="g-map-pin">{this.props.name}</div>
  }
}

export default eventShare