export const trackScreenLoad = (event_name) => {
  if (analytics_loaded) {
    mixpanel.track(event_name);
    posthog.capture(event_name);
  }
};

export const updatePeopleProfile = (context, params_being_updated) => {
  if (analytics_loaded) {
    let state_copy = Object.assign({}, context.state);
    Object.assign(state_copy, params_being_updated);
    let mixpanel_data = {
      "$email": state_copy.email,
      "$name": (state_copy.first_name + " " + state_copy.last_name).trim(),
      "$phone": state_copy.phone_number,
      "First Name": state_copy.first_name,
      "Last Name": state_copy.last_name,
      "Referral Code Used During Signup": state_copy.valid_referral,
      "Supporting Local": state_copy.supporting_local,
      "Number One Reason": state_copy.reason_to_become_member
    };

    for (let key in mixpanel_data) {
      if (!mixpanel_data[key]) {
        delete mixpanel_data[key];
      }
    }
    mixpanel.people.set(mixpanel_data)
  }
}

export const updateStripeCustomer = (context, params_being_updated) => {
  let state_copy = Object.assign({}, context.state);
  Object.assign(state_copy, params_being_updated);
  let mixpanel_distinct_id = null;
  if (analytics_loaded) {
    mixpanel_distinct_id = mixpanel.get_distinct_id();
  }
  let params = {
    email: state_copy.email,
    phone_number: state_copy.phone_number,
    first_name: state_copy.first_name,
    last_name: state_copy.last_name,
    valid_referral: state_copy.valid_referral,
    plan_id: state_copy.plan_id,
    reason_to_become_member: state_copy.reason_to_become_member,
    supporting_local: state_copy.supporting_local,
    area_id: state_copy.selected_area.id,
    start_immediately: state_copy.when_to_start,
    selected_offer_1: state_copy.selected_offer_1,
    selected_offer_2: state_copy.selected_offer_2,
    waitlist_id: state_copy.waitlist_id,
    intent_id: state_copy.setup_intent?.id,
    customer_id: state_copy.setup_intent?.customer,
    mixpanel_distinct_id: mixpanel_distinct_id,
    mixpanel_original_id: state_copy.mixpanel_original_id,
  };

  $.ajax({
    method: "POST",
    url: `/signupvx/update-stripe-customer`,
    data: params,
    dataType: 'json',
    success: (data) => {
      if (data.intent) {
        context.setState({ setup_intent: data.intent })
        if (analytics_loaded) {
          if (mixpanel.get_distinct_id() != data.intent.customer) {
            mixpanel.alias(data.intent.customer, mixpanel.get_distinct_id());
            mixpanel.identify(data.intent.customer);
            posthog.identify(data.intent.customer);
          }
        }
      }
    },
    error: () => { }
  });
}

export const trackCioEvent = (context, step_completed) => {
  let params = {
    email:                    context.state.email,
    phone_number:             context.state.phone_number,
    first_name:               context.state.first_name,
    last_name:                context.state.last_name,
    valid_referral:           context.state.valid_referral,
    plan_id:                  context.state.plan_id,
    reason_to_become_member:  context.state.reason_to_become_member,
    supporting_local:         context.state.supporting_local,
    area_id:                  context.state.selected_area.id,
    start_immediately:        context.state.when_to_start,
    selected_offer_1:         context.state.selected_offer_1,
    selected_offer_2:         context.state.selected_offer_2,
    waitlist_id:              context.state.waitlist_id,
    step:                     step_completed
  };

  $.ajax({
    method: "POST",
    url: `/signupvx/track-progress`,
    data: params,
    dataType: 'json',
    success: (data) => {
      if (!context.waitlist_id && data.waitlist_id) {
        context.setState({ waitlist_id: data.waitlist_id })
      }
    },
    error: () => { }
  });
}

export const setMixpanelAquisition = (referral_details) => {
  if (analytics_loaded && referral_details) {
    var aquistion = "Partner referral";
    if (referral_details.type == "user") {
      aquistion = "Account referral";
    } else if (referral_details.type == "giftcode") {
      aquistion = "Gift code";
    }
    mixpanel.people.set({"Acquisition Type": aquistion});
  }
}

export const trackPaymentSubmit = (context) => {
  if (analytics_loaded) {
    mixpanel.track("Signup: Complete");
    posthog.capture("Signup: Complete");
  }

  if(typeof(dataLayer) !== "undefined" && dataLayer) {
    let value = context.props.area?.monthly_subscription_price || 1500;
    dataLayer.push({
      event: "purchase",
      ecommerce: {
        currency: "USD",
        items: [
          {
            item_id: context.props.area?.premium_monthly_plan,
            item_name: context.props.area?.name + " Monthly",
            coupon: context.props.valid_referral,
            item_category: "Subscription",
            item_category_2: "Trial Activation",
            item_variant: "Monthly",
            price: (value / 100),
          }
        ],
        transaction_id: context.props.setup_intent?.id,
        value: (value / 100),
      }
    });
  }

  $.ajax({
    method: "POST",
    url: "/payment-submit",
    data: {
      waitlist_id: context.state.waitlist_id
    },
    dataType: 'json',
    success: (data) => {
    },
    error: (error) => {
    }
  });
}
