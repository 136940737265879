import React from "react";
import iconSelected from 'icon-selected.svg'

class CardUpdateSuccess extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="sign-up-column">
        <div className="market-header">
          <div className="step-header">Payment Info Updated!</div>
        </div>
        <div className="market-subtitle">
          We'll keep you exploring your city with new Offers, Perks, and Events each month!
        </div>
        <br />
      </div>
    );
  }
}

export default CardUpdateSuccess