import React from "react";

class ContinueButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let button_class = "signup-continue-button";
    if (this.props.disabled) button_class += " disable-button";
    if (this.props.className) button_class += " " + this.props.className;

    return (
      <button
        className={button_class}
        onClick={this.props.onPress}
        id={this.props.gtm_id}>
        CONTINUE
      </button>
    );
  }
}

export default ContinueButton