import React from "react";

class LocationSummary extends React.Component {
  constructor(props) {
    super(props);
  }

  categoryRowData(location) {
    var data = [];
    for (const category of this.props.categories) {
      data.push(<td key={category.id} className='table-left'>{location.preferences_per_category[category.id]}</td>);
    }
    return data;
  }

  render() {
    const category_headers = this.props.categories.map(category =>(
      <th key={category.id} className='table-left'>{category.name}</th>
    ))


    const row_data = this.props.locations.map(location =>(
      <tr key={location.id}>
        <td className='table-left'><a data-turbolinks="false" href={"/algorithms/location/"+location.id}>{location.name}</a></td>
        <td className='table-left'>{location.total_offers}</td>
        <td className='table-left'>{location.unique_accounts}</td>
        <td className='table-left'>{location.total_assignment_preferences}</td>
        <td className='table-left'>{location.total_offer_min_capacity}</td>
        <td className='table-left'>{location.total_offer_target_capacity}</td>
        <td className='table-left'>{location.total_offer_max_capacity}</td>
        {this.categoryRowData(location)}
      </tr>
    ))

    return (
      <div>
        <table className='admin__table'>
          <thead>
            <tr>
              <th className='table-left'>Name</th>
              <th className='table-left'>Offers</th>
              <th className='table-left'>Accounts</th>
              <th className='table-left'>Slots w/ Preference</th>
              <th className='table-left'>Total Min</th>
              <th className='table-left'>Total Target</th>
              <th className='table-left'>Total Max</th>
              {category_headers}
            </tr>
          </thead>
          <tbody>
            {row_data}
          </tbody>
        </table>
      </div>
    );
  }
}

export default LocationSummary