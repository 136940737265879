import React from "react";

import CityLogo from 'logo-city.png'


class SetupComplete extends React.Component {
  constructor(props) {
    super(props);
    this.checkIfSetupComplete = this.checkIfSetupComplete.bind(this);

    let intervalId = setInterval(this.checkIfSetupComplete, 1000);

    
    this.state = {
      intervalId: intervalId,
      attempt_count: 0,
      display_error: false
    }
  }

  checkIfSetupComplete() {
    if (this.props.is_active) {
      let self = this;
      $.ajax({
        method: "GET",
        url: "/login/setup_status",
        dataType: 'json',
        success: function(data) {
          if (data.complete) {
            window.location.href = self.props.homePath
          }
        }
      });

      if (this.state.attempt_count >= 15) {
        clearInterval(this.state.intervalId);
        this.setState({display_error: true});
      } else {
        this.setState({attempt_count: this.state.attempt_count + 1});
      }
    }
  }


  render() {
    return (
      <div>
        <div className="member__app-section-padded">
          <div className="text-center">
            <img style={{maxWidth: '75px', margin: '10px'}} src={CityLogo} />
            <h2 className='bottom-margin-zero top-margin-zero'>Please Wait...</h2>
            <p className='bottom-margin-zero'>while we finish setting up your account. You will be automatically redirected in just one moment.</p>
            { this.state.display_error && 
              <div>
                <br/>
                <p>
                  This is taking longer than expected. 
                  Try refreshing the page or reach out to 
                  <a style={{color: '#004FFF'}} href="mailto:support@letsgetoffline.com"> support@letsgetoffline.com </a> 
                  for further assistance.
                </p>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default SetupComplete