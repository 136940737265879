import React from "react";
import { getIDforGTM } from "../v2/state_components/gtm_methods";
import BadMatch from "bad-match.svg";

class NotGoodFit extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let instagram_link = this.props.selected_area.instagram_link || "https://www.instagram.com/offlineraleighdurham/";
    return (
      <div className="signup-column not-good-fit" style={{textAlign: 'center'}}>
        <div><img src={BadMatch} className="page-main-image" /></div>
        <p className='emphasis' style={{marginTop: '0'}}>Bit of a mix-up here, huh?</p>
        <p>At Offline, we're passionate about backing our local restaurants, which includes tipping and a bit of spending. We're definitely not a second act of Groupon.</p>
        { this.props.supporting_local == "tipping_is_questionable" &&
          <p className='emphasis'>Given your not-so-sure stance on tipping, we might not be the perfect match.</p>
        }
        { this.props.reason_to_become_member == "save_money" &&
          <p className='emphasis'>Given your main motivation of saving money eating out, we might not be the perfect match.</p>
        }
        <p>{`But hey, don't disappear! We'd love to help you stay in the know about ${this.props.selected_area.name} via our Instagram. Lots of local gems to discover there, just presented a bit differently.`}</p>
        <a href={instagram_link} style={{textDecoration: "none"}}>
          <button id={getIDforGTM('go-to-instagram', this.props)} className={"full-blue-button smaller-text"}>
            Take me to Instagram
          </button>
        </a>
      </div>
    );
  }
}

export default NotGoodFit