import React from "react";
import LogoArea from "icon-area-location.svg";
import CityDropdown from "../../shared/city_dropdown";
import { getIDforGTM } from "../v2/state_components/gtm_methods";

class MarketSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let label = this.props.label || "You're signing up for: ";
    return (
      <div className="market-select">
        <div className="label">{label}</div>
        <CityDropdown
          options={this.props.markets}
          value={this.props.selected_market}
          onChange={this.props.updateStateValue}
          className="open-upwards enable-select sign-up"
          icon={LogoArea}
          id={getIDforGTM("choose-city", this.props)}
        />
      </div>
    );
  }
}

export default MarketSelect