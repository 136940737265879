import React from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentElement from "./stripe_payment_element";

class StripePaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.stripePromise = loadStripe(this.props.stripe_key);
  }

  renderCurrentCard() {
    if (!this.props.card) {
      return ""
    }
    return (
      <>
        <div><b>Current Card:</b></div>
        <div>exp: {this.props.card.exp_month} / {this.props.card.exp_year}</div>
        <div>{this.props.card.brand}: {this.props.card.last4}</div>
      </>
    )
  }

  render() {
    return (
      <div className="sign-up-column">
        <div className="market-header">
          <div className="step-header">Update your payment method</div>
        </div>
        <div className="market-subtitle">
          <p className='font-italic signup__help'>Email us with any questions at <a href='mailto:support@letsgetoffline.com'>support@letsgetoffline.com</a></p>
          <p>{this.props.message}</p>
        </div>
        <div style={{ textAlign: 'center' }}>
          {this.renderCurrentCard()}
        </div>

        <Elements stripe={this.stripePromise} options={{ clientSecret: this.props.setup_intent.client_secret }}>
          <StripePaymentElement
            redirect_url={this.props.confirmation_path}
            button_class={"sign-up-button-full"} />

        </Elements>
      </div>

    );
  }
}

export default StripePaymentForm