import React from "react";
import moment from "moment-timezone";

class AccountLotteryPoints extends React.Component {
  constructor(props) {
    super(props);

    this.network_busy = false;
    this.submitPost = this.submitPost.bind(this);
  }

  update_lottery_points(data) {
    let lottery_points = this.props.lottery_points;
    lottery_points.unshift(data);
    this.props.load_lottery_points(lottery_points, data.points + this.props.total_lottery_points);
  }

  submitPost(e) {
    let target = e.target;
    let sudo_form = target.parentElement;

    if (target.getAttribute("data-confirm") && !confirm(target.getAttribute("data-confirm"))) {
      return;
    }
    
    let path = sudo_form.getAttribute("action");

    if (this.network_busy) {
      return;
    }
    this.network_busy = true;

    $.ajax({
      method: sudo_form.getAttribute("method").toUpperCase(),
      url: path,
      processData: false,
      contentType: false,
      success: (data) => {
        this.network_busy = false;
        if (typeof data === "object") {
          showFlashMessage(sudo_form.dataset.success);
          this[`${sudo_form.dataset.update}`](data);
        }
      },
      error: (error) => {
        this.network_busy = false;
        // showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }
  
  render() {
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    return (
      <>
        <h2 onClick={() => {
          let collapse_action = this.props.collapse_action;
          collapse_action.action(collapse_action.args);
        }}>
          {"Lottery Points: " + this.props.total_lottery_points + " "}
          <span
            className={caret_style}
            data-section-id="offer-section"
          />
        </h2>
        <div id="social-credit-wrapper" style={collapsed_style}>
          <div
            className="button_to"
            method="post"
            action={"/accounts/" + this.props.account.slug + "/add_social_credit?as_json=true"}
            data-remote="true"
            data-success="+5 Lottery Points Added"
            data-update="update_lottery_points"
          >
            <input
              id="lottery_point"
              type="submit"
              value="+5 Lottery for Social (Sends Notification)"
            />
          </div>
        </div>
        <div id="lottery-point-wrapper" style={collapsed_style}>
          <div
            className="button_to"
            method="post"
            action={"/accounts/" + this.props.account.slug + "/add_lottery_point?as_json=true"}
            data-remote="true"
            data-success="+1 Lottery Point Added"
            data-update="update_lottery_points"
          >
            <input
              className="social-button"
              type="submit"
              value="+1 Lottery (No Notification)"
              onClick={this.submitPost}
            />
          </div>
          <div
            className="button_to"
            method="post"
            action={"/accounts/" + this.props.account.slug + "/add_lottery_point?points=5&as_json=true"}
            data-remote="true"
            data-success="+5 Lottery Points Added"
            data-update="update_lottery_points"
          >
            <input
              onClick={this.submitPost}
              className="social-button"
              type="submit"
              value="+5 Lottery (No Notification)"
            />
          </div>
          <div
            className="button_to"
            method="post"
            action={"/accounts/" + this.props.account.slug + "/add_lottery_point?points=20&as_json=true"}
            data-remote="true"
            data-success="+20 Lottery Points Added"
            data-update="update_lottery_points"
          >
            <input
              onClick={this.submitPost}
              className="social-button"
              type="submit"
              value="+20 Lottery (No Notification)"
            />
          </div>
        </div>
        <div className="admin__table-page admin__table-page--has-filters" style={collapsed_style}>
          <section className="builder__tabs">
            <table className="admin__table">
              <thead>
                <tr>
                  <th className="table-left">Date</th>
                  <th className="table-left">Points</th>
                  <th className="table-left">Reason</th>
                </tr>
              </thead>
              <tbody id="offers">
                {this.props.lottery_points.map((lottery_point, index) => {
                  return (
                    <tr key={"lp" + lottery_point.id}>
                      <td>{moment(lottery_point.created_at).format("MMM Do, YYYY")}</td>
                      <td>{lottery_point.points}</td>
                      <td>{lottery_point.description}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </section>
        </div>
      </>
    );
  }
}

export default AccountLotteryPoints;