import React from "react";

class PauseAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      months_to_pause: 0,
      show_required_fields: false,
      pause_reason: '',
      done: false
    }

    this.changeSelectOption = this.changeSelectOption.bind(this);
    this.pauseAccount = this.pauseAccount.bind(this);
  }

  pauseAccount() {
    if (this.state.pause_reason == '' || this.state.months_to_pause == 0) {
      return this.setState({show_required_fields: true})
    }
    var self = this;
    $.ajax({
      method: "POST",
      url: "/settings/"+this.props.id+"/pause",
      data: {
        account_id: this.props.account_id,
        months_to_pause: this.state.months_to_pause,
        pause_reason: this.state.pause_reason
      },
      dataType: 'json',
      success: function(data) {
        self.setState({done: true})
      },
      error: function(error) {
      }
    });
  }


  changeSelectOption(event) {
    const target = event.target;
    const name = target.name;

    this.setState({[name]: target.value, show_required_fields: false});
  }

  getFirstOfNextMonth() {
    const cmonth = new Date();
    const month_name = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return (month_name[new Date(cmonth.getFullYear(), cmonth.getMonth() + 1, 1).getMonth()] +" 1st");
  }

  getUnpauseDate() {
    const months_paused = parseInt(this.state.months_to_pause);
    if  (isNaN(months_paused) || months_paused == 0) {
      return ""
    }
    const cmonth = new Date();
    const month_name = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return (month_name[new Date(cmonth.getFullYear(), cmonth.getMonth() + 1 + months_paused, 1).getMonth()] +" 1st");
  }
  
  render() {
    const cmonth = new Date();
    const month_name = ["January","February","March","April","May","June","July","August","September","October","November","December"];

    if (this.state.done) {
      return (
        <div className='text-center'>
          <h2 className='bottom-margin-zero'>Done</h2>
          <div className="member__app-section-padded-large">
            <p>We’ve paused your account. You can use your Offers up until the end of your current membership period.</p>
            <p>Your account will unpause at the end of your pause period. If you need to un-pause your account early, please write into support.</p>
            <p>You can see the status of your membership on the profile page anytime.</p>
            <p><a className='member__app-btn btn-primary' href={this.props.backLink}>Home</a></p>
          </div>
        </div>
      )
    }

    return (
      <div className='text-left'>
        <div className='one-line' style={{margin: "13px"}}>
          <div style={{width: '100%'}}>
            <a className='member__app-btn btn-small' href={this.props.backLink}>back</a>
          </div>
        </div>
        <h2 className='bottom-margin-zero'>Pause Your Membership</h2>
        
        <div className="member__app-section-padded-large">

          <div className="added-margin">
            <h5>Reactivate my membership on:</h5>
            <select name="months_to_pause" id="months_to_pause" className='large-input' value={this.state.months_to_pause || 0} onChange={this.changeSelectOption}>
              <option disabled value="0" style={{display: 'none'}}>--</option>
              <option value="1">{month_name[new Date(cmonth.getFullYear(), cmonth.getMonth() + 2, 1).getMonth()]} 1st</option>
              <option value="2">{month_name[new Date(cmonth.getFullYear(), cmonth.getMonth() + 3, 1).getMonth()]} 1st</option>
              <option value="3">{month_name[new Date(cmonth.getFullYear(), cmonth.getMonth() + 4, 1).getMonth()]} 1st</option>
              <option value="4">{month_name[new Date(cmonth.getFullYear(), cmonth.getMonth() + 5, 1).getMonth()]} 1st</option>
              <option value="5">{month_name[new Date(cmonth.getFullYear(), cmonth.getMonth() + 6, 1).getMonth()]} 1st</option>
              <option value="6">{month_name[new Date(cmonth.getFullYear(), cmonth.getMonth() + 7, 1).getMonth()]} 1st</option>
            </select>
            {this.state.months_to_pause == 0 && this.state.show_required_fields && 
              <p className='error-text'>Required</p>
            }
          </div>


          <div className="added-margin">
            <h5>Why are you pausing your account?</h5>
            <select name="pause_reason" id="pause_reason" className='large-input' value={this.state.pause_reason || ''} onChange={this.changeSelectOption}>
              <option disabled value="" style={{display: 'none'}}>--</option>
              <option value="covid">COVID</option>
              <option value="moving">Moving</option>
              <option value="busy">Too Busy</option>
              <option value="budget">Budget</option>
              <option value="traveling_for_work_not_able_to_use_perks">Traveling</option>
              <option value="unsatisfied">Unsatisfied</option>
            </select>
            {this.state.pause_reason == '' && this.state.show_required_fields && 
              <p className='error-text'>Required</p>
            }
          </div>

          <div className='text-center'>
            <p>
              <small>Your account will be paused on:</small><br/>
              <span className='small__caps'>
                {this.getFirstOfNextMonth()}
              </span>
            </p>

            <p>
              <small>Your account will be reactivated on:</small><br/>
              <span className='small__caps'>{this.getUnpauseDate()}</span>
            </p>

            <button className='member__app-btn btn-primary' onClick={this.pauseAccount}>
              Pause my Account
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PauseAccount