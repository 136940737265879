import React from "react";
import SparkleLeft from 'sparkle-left.svg'
import SparkleRight from 'sparkle-right.svg'
import DiscoverArrow from 'discover-arrow.svg'
import ConfirmationButton from "../../offer_selection/confirmation_button";
import BaseModal from "../../helpers/base_modal";

class ExtendOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_conformation_modal: false,
      show_spinner: false
    }
    this.confirm_extension = this.confirm_extension.bind(this);
    this.show_conformation_modal = this.show_conformation_modal.bind(this);
    this.render_conformation_modal = this.render_conformation_modal.bind(this);
  }

  confirm_extension = () => {
    if (this.state.show_spinner) return;
    this.setState({ show_spinner: true });
    $.ajax({
      method: "PUT",
      url: `/extend/${this.props.token}`,
      dataType: 'json',
      success: (data) => { 
          location.href = window.location.href
       },
      error: (error) => {
        this.setState({ show_spinner: false });
        console.log(error)
      }
    });
  }

  show_conformation_modal() {
    this.setState({ show_conformation_modal: true });
  }

  render_conformation_modal() {
    if (!this.state.show_conformation_modal) return (<></>);

    return (
      <BaseModal
        title="You're about to extend your offers!"
        message="You will be billed $2.00 on your next month's subscription."
        confirm_button="Confirm + Extened"
        confirm_action={this.confirm_extension}
        cancel_button="Close"
        show_spinner={this.state.show_spinner}
        cancel_action={() => this.setState({ show_conformation_modal: false })}
      />
    )
  }

  render_offer_extension_details(already_extended, extendable, extended_date, expiration_date) {
    if (!extendable.length && !already_extended.length) {
      return (
        <></>
      )
    }
    
    if (!extendable.length && already_extended.length) {
      return (
        <div className="extend-offer-details">
          <div className="your-offers">
            <h4>Your Extended Offers</h4>
            <h5>Now expires on {expiration_date}</h5>
            <div className="offer-assignments">
              {already_extended.map((assignment, index) => {
                return <div key={index}>
                  {assignment.offer_name}
                </div>
              })}
            </div>
          </div>
        </div>
      )
    }


    return (
      <div className="extend-offer-details">
        <div className="your-offers">
          <h4>Your Offers</h4>
          <h5>Originally expires on {expiration_date}</h5>
          <div className="offer-assignments">
            {extendable.map((assignment, index) => {
              return <div key={index}>
                {assignment.offer_name}
              </div>
            })}
          </div>
        </div>
        <div className="extended-through">
          <h4>Extended Through</h4>
          <h5>{extended_date}</h5>
        </div>
        <div className="payment">
          <h4>Payment</h4>
          <div className="payment-details">
            <div>
              <h5>Extend Offers</h5>
              <h6>billed on next month's subscription</h6>
            </div>
            <div className="price">
              $2.00
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { already_extended, extendable, extended_date, expiration_date } = this.props

    let is_extended = extendable.length == 0 && already_extended.length > 0

    let title_text = is_extended ? "Your monthly Offers have been extended!" : "Get an extra 2 weeks to use your Offers"
    if (!extendable.length && !already_extended.length ) {
      title_text = "No Offers to extend at this time."
    }

    return (
      <>
        {this.render_conformation_modal()}
        <div className="basic-layout__body">
          <div className="basic-layout__grow_content">
            <div className="basic-layout__dynamic_height-col">
              <h1 className="extend-offer-title">
                {title_text}
              </h1>
              {this.render_offer_extension_details(already_extended, extendable, extended_date, expiration_date)}
            </div>
            <div className="basic-layout__footer">
              {extendable.length > 0 &&
                <ConfirmationButton
                  on_click_action={this.show_conformation_modal}
                  button_label="Confirm Extension"
                  is_active={true}
                  error_message={null}
                  wrapper_class={"no-margin"}
                />
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ExtendOffer