import React from "react";

// import SignupFooter from "./signup_footer";


class StyledList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    let listed_items = this.props.items.map(item => {
      return (
        <div key={item}>
          {this.props.icon && <img src={this.props.icon} alt=""/>}
          {item}
        </div>
      )
    })
    return (
      <div className="signup-list spacer">
        { this.props.header &&
          <h3 style={{marginBottom: "12px"}}>{this.props.header}</h3>
        }
        {listed_items}
      </div>
    );
  }
}

export default StyledList