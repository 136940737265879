import React from "react";
import { getIDforGTM } from "../../v2/state_components/gtm_methods"

class MemberQeustionsGUI extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="signup-v3-column signup-column membership-information">
          <div className="membership-info-title">Lastly, real quick...</div>
          <div className='membership-info-subtitle'>Before you pick your restaurants tell us about yourself.</div>
          <div className="new-member-questions">
            <div className="input-label">When it comes to <i>supporting</i> local restaurants, you</div>
            <select
              id="supporting_local"
              name="supporting_local"
              onChange={this.props.changeInputField}
              value={this.props.supporting_local}
              style={{ color: (this.props.supporting_local ? "#000" : "rgba(0, 0, 0, 0.6)") }} >
              <option value="" label="Select"></option>
              <option value="constantly_tip">Constantly tip generously</option>
              <option value="tip_based_on_service">Vary tip based on service</option>
              <option value="tipping_is_questionable">Think the concept of tipping is questionable</option>
            </select>
            {this.props.error_supporting_local &&
              <div className="input-error">{this.props.error_supporting_local}</div>
            }

            <div className="input-label">If you had to pick one, what's the #1 reason you want to become a Member?</div>
            <select
              id="reason_to_become_member"
              name="reason_to_become_member"
              onChange={this.props.changeInputField}
              value={this.props.reason_to_become_member}
              style={{ color: (this.props.reason_to_become_member ? "#000" : "rgba(0, 0, 0, 0.6)") }} >
              <option value="" label="Select"></option>
              <option value="explore_restaurants">Explore new restaurants</option>
              <option value="save_money">Save a few bucks dining out</option>
              <option value="meet_people">Meet new people in town</option>
            </select>
            {this.props.error_reason_to_become_member &&
              <div className="input-error">{this.props.error_reason_to_become_member}</div>
            }
          </div>
          <button
            className={"full-blue-button smaller-text aux-full-width aux-footer"}
            // disabled={this.canContinue() ? "" : "disabled"}
            onClick={this.props.validateAccountDetails}
            id={getIDforGTM('submit-phone-number', this.props)}>
            CONTINUE
          </button>
          <button
            className={"footer-back-button-link aux-full-width aux-footer-low"}
            // disabled={this.canContinue() ? "" : "disabled"}
            onClick={this.props.previousScreen}
            id={getIDforGTM('back-to-previous-screen', this.props)}>
            back
          </button>
        </div>
      </>
    );
  }
}

export default MemberQeustionsGUI