import React from "react";

import OfferChoice from "./offer_choice";
import OfferInvite from "./offer_invite";
import OfferIntertitials from "./offer_interstitials";
import NoOffersAvailable from "./no_offers_available";
import ConfirmYourOffers from "./confirm_your_offers";
import ConfirmationButton from "./confirmation_button";
import ActiveSelection from "./active_selection";
import BottomBar from "./bottom_bar";

import FormBuilder from "../admin_app/shared/form_builder";

import IconConfirmation from "icon-confirmation";
import IconOfferSelectModel from "icon-offer-select-modal.svg";
import IconClose from "icon-close.svg";
import OfferLoadingInterstitial from "./offer_loading_interstitial";

class PickYourOffers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      assignment_slots: [],
      choice_index: 0,
      selected_offers: [],
      blacklist_offers: [],
      assignment_map: {},
      offer_map: {},
      is_ready_to_submit: false,
      show_error_message: false,
      network_busy: false,
      show_confirmation: false,
      open_modal: null,
      confirmed_loaded: false,
      still_loading: true,
      interstitial_confirmed: true,
      all_locations: [],
      all_categories: [],
      all_offer_types: [],
      all_valid_offers: [],
      offer_cache: {},
      all_friends: props.friends,
      invitations: [],
      show_invite_modal: false,
      show_invite_spinner: false,
    }

    this.loading_invite_statuses = false;
    this.confirmation_button_ref = React.createRef();

    this.submit_offer_selection = this.submit_offer_selection.bind(this);
    this.get_offer_information = this.get_offer_information.bind(this);
    this.clear_selection = this.clear_selection.bind(this);
    this.confirmInterstitial = this.confirmInterstitial.bind(this);
    this.update_offer_selection = this.update_offer_selection.bind(this);
    this.fetch_friends_to_invite = this.fetch_friends_to_invite.bind(this);
    this.redo_selection = this.redo_selection.bind(this);
    this.on_take_credit = this.on_take_credit.bind(this);
    this.update_offer_invitation = this.update_offer_invitation.bind(this);
    this.next_offer = this.next_offer.bind(this);
    this.next_available_choice_index = this.next_available_choice_index.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.update_offer_cache = this.update_offer_cache.bind(this);
    this.modalOverlayClick = this.modalOverlayClick.bind(this);
    this.interstitialRenderItems = this.interstitialRenderItems.bind(this);
    this.offerSelectionRenderItems = this.offerSelectionRenderItems.bind(this);
    this.offerInviteRender = this.offerInviteRender.bind(this);
    this.creditRenderItems = this.creditRenderItems.bind(this);
    this.confirmationRenderItems = this.confirmationRenderItems.bind(this);
    this.renderAlreadyAssignedModal = this.renderAlreadyAssignedModal.bind(this);
    this.renderOverCapacityModal = this.renderOverCapacityModal.bind(this);
    this.render_confirmation = this.render_confirmation.bind(this);
    this.render_loading_interstitial = this.render_loading_interstitial.bind(this);
    this.updateOfferData = this.updateOfferData.bind(this);
    this.removeInvite = this.removeInvite.bind(this);
    this.can_invite_friends = this.can_invite_friends.bind(this);
  }

  componentDidMount() {
    if (this.state.still_loading) {
      this.get_offer_information();
    }
    if(analytics_loaded && this.props.mixpanel_id) {
      posthog.identify(this.props.mixpanel_id);
    }
  }

  sortFriends(friends) {

  }

  get_offer_information() {
    if (this.state.network_busy) return false;
    this.setState({ network_busy: true });

    $.ajax({
      method: "GET",
      url: "/offer_select/" + this.props.token + "/retrieve_offers",
      dataType: 'json',
      success: (data) => {
        // Sort offer_slots by number of valid_offers available
        let assignment_slots = [...data.assignment_slots];
        assignment_slots.sort((a, b) => a.name - b.name);

        let assignment_map = {};
        let offer_map = {};
        let choice_index = 0;
        let index_found = false;
        let all_friends = [...data.friends];

        assignment_slots.forEach((assignment_slot, i) => {
          assignment_map[assignment_slot.id] = {
            slot_id: assignment_slot.id,
            offer_id: null,
            invitation_id: null,
            new_invitation: null,
            chose_credit: false,
            is_last_chance: false
          }

          // assignment_slot.valid_offers.forEach(offer => {
          //   if (offer_map[offer.id]) return;
          //   offer_map[offer.id] = offer;
          // })

          // assignment_slot.last_chance_offers.forEach(offer => {
          //   if (offer_map[offer.id]) return;
          //   offer_map[offer.id] = offer;
          // })

          if (!index_found && !assignment_slot.assignment) {
            choice_index = i;
            index_found = true;
          }
        });

        offer_map = data.all_valid_offers.reduce((offer_map, vo) => Object.assign(offer_map, { [vo.id]: vo }), {});
        let friend_impact_map = {};
        data.all_valid_offers.filter(vo => vo.interested.length > 0).forEach(vo => {
          vo.interested.forEach(friend => {
            friend_impact_map[friend.id] = friend_impact_map[friend.id] || 0;
            friend_impact_map[friend.id] += 1;
          });
        });

        all_friends.sort((a, b) => {
          let a_impact = friend_impact_map[a.id] || 0;
          let b_impact = friend_impact_map[b.id] || 0;
          return b_impact - a_impact;
        });
        let me_friend = all_friends.filter(f => f.id == this.props.account_id)[0];
        all_friends = all_friends.filter(f => f.id != this.props.account_id);
        all_friends.unshift(me_friend);

        this.setState({
          offer_map,
          assignment_map,
          assignment_slots,
          choice_index,
          is_ready_to_submit: !index_found,
          network_busy: false,
          still_loading: false,
          all_locations: data.all_locations,
          all_categories: data.all_categories,
          all_offer_types: data.all_offer_types,
          all_valid_offers: data.all_valid_offers,
          all_friends,
          offers_going_fast: data.offers_going_fast,
          total_months_active: data.total_months_active,
          cutoff_new_member: data.cutoff_new_member,
          invitations: data.invitations,
        })
      }
    });
  }

  updateOfferData(offer) {
    let offer_map = Object.assign({}, this.state.offer_map);
    offer_map[offer.id] = offer;
    let all_valid_offers = [...this.state.all_valid_offers];
    let index = all_valid_offers.findIndex(o => o.id == offer.id);
    if (index > -1) {
      all_valid_offers[index] = offer;
    }
    this.setState({ offer_map, all_valid_offers });
  }

  submit_offer_selection() {
    if (this.state.network_busy) return false;
    this.setState({ network_busy: true });

    var form_builder = new FormBuilder();

    // let selected_assignments = Object.assign({}, this.state.assignment_map);
    let selected_assignments = JSON.parse(JSON.stringify(this.state.assignment_map));

    for (let assignment in selected_assignments) {
      if (selected_assignments[assignment].new_invitation != null) {
        selected_assignments[assignment].new_invitation = selected_assignments[assignment].new_invitation.map(f => f.id).join(",");
      }
    }
    var form_object = {
      assignment_choices: selected_assignments
    }
    var form_data = form_builder.createForm(form_object);

    $.ajax({
      method: "PUT",
      url: "/offer_select/" + this.props.token,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        let update_state = { network_busy: false, show_confirmation: true }
        if (data.errors) {
          let alreadyAssignedErrors = data.errors.filter(e => e.type == "AlreadyAssignedError");
          let noCapacityErrors = data.errors.filter(e => e.type == "NoCapacityError");

          if (noCapacityErrors.length) {
            update_state.blacklist_offers = this.state.blacklist_offers;
            noCapacityErrors.forEach(error => {
              let assignment_slot = this.state.assignment_map[error.assignment_slot_id];
              let offer_id = assignment_slot?.offer_id;
              update_state.blacklist_offers.push(offer_id);
            })


            update_state.open_modal = this.renderOverCapacityModal(noCapacityErrors[0].assignment_slot_id);
            update_state.show_confirmation = false;
          } else if (alreadyAssignedErrors.length) {
            // update_state.open_modal = this.renderAlreadyAssignedModal();
          }
        }

        // update_state.all_valid_offers = data.all_valid_offers;
        // update_state.all_locations = data.all_locations;
        // update_state.all_categories = data.all_categories;
        // update_state.all_offer_types = data.all_offer_types;

        if (data.assignment_slots) {
          let current_assignment_slots = [...this.state.assignment_slots];
          let incoming_assignment_slots = [...data.assignment_slots];
          let assignment_slots = [];

          current_assignment_slots.forEach(assignment_slot => {
            let incoming_assignment_slot = incoming_assignment_slots.filter(as => as.id == assignment_slot.id)[0];

            if (incoming_assignment_slot) {
              assignment_slots.push(incoming_assignment_slot);
            } else {
              assignment_slots.push(assignment_slot);
            }
          })

          assignment_slots.sort((a, b) => a.name - b.name);

          let assignment_map = { ...this.state.assignment_map };
          let offer_map = {};
          let choice_index = 0;
          let index_found = false;
          assignment_slots.forEach((assignment_slot, i) => {
            if (!assignment_slot.assignment) {
              assignment_map[assignment_slot.id].offer_id = null;
            }

            // assignment_slot.valid_offers.forEach(offer => {
            //   if (offer_map[offer.id]) return;
            //   offer_map[offer.id] = offer;
            // })

            // assignment_slot.last_chance_offers.forEach(offer => {
            //   if (offer_map[offer.id]) return;
            //   offer_map[offer.id] = offer;
            // })

            if (!index_found && !assignment_slot.assignment /*  && (!assignment_map[assignment_slot.id].chose_credit || assignment_slot.valid_offers.length || assignment_slot.last_chance_offers.length) */) {
              choice_index = i;
              index_found = true;
            }
          });
          offer_map = data.all_valid_offers.reduce((offer_map, vo) => Object.assign(offer_map, { [vo.id]: vo }), {});

          let state_offer_map = { ...this.state.offer_map };
          Object.assign(state_offer_map, offer_map);

          Object.assign(update_state, { offer_map: state_offer_map, assignment_map, assignment_slots, choice_index, show_confirmation: !index_found, network_busy: false, still_loading: false })
        }

        document.body.scrollTop = 0;
        this.setState(update_state);
      },
      error: (error) => {
        //TODO, show some sort of error
        this.setState({ network_busy: false });
      }
    });
  }

  redo_selection(close_modal = false) {
    let assignment_map = Object.assign({}, this.state.assignment_map);
    for (let slot_id in assignment_map) {
      assignment_map[slot_id].offer_id = null;
      assignment_map[slot_id].invitation_id = null;
      assignment_map[slot_id].new_invitation = null;
      if (!close_modal) {
        assignment_map[slot_id].chose_credit = false;
      }
    }

    document.body.scrollTop = 0;
    let choice_index = this.next_available_choice_index(-1);
    let is_ready_to_submit = false;
    if (choice_index == this.state.assignment_slots.length) {
      is_ready_to_submit = true;
      choice_index--;
    }

    let update_state = {
      choice_index: choice_index,
      is_ready_to_submit: is_ready_to_submit,
      assignment_map,
      selected_offers: []
    }

    if (close_modal) {
      update_state.open_modal = null;
    }
    this.setState(update_state)
  }

  clear_selection(slot_id) {
    this.update_offer_selection(slot_id, null);
  }

  confirmInterstitial() {
    document.body.scrollTop = 0;
    this.setState({ interstitial_confirmed: true });
  }

  update_offer_invitation(new_invitation) {
    let assignment_map = Object.assign({}, this.state.assignment_map);
    let slot_id = this.state.assignment_slots[this.state.choice_index].id;
    assignment_map[slot_id].new_invitation = new_invitation || null;

    let update_state = {assignment_map};
    Object.assign(update_state, this.next_offer(null, true));
    this.setState(update_state);
  }

  update_offer_selection(slot_id, offer_id, is_last_chance, is_surprise_me, invitation_id, new_invitation) {
    this.confirmation_button_ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    let assignment_map = this.state.assignment_map;

    assignment_map[slot_id].offer_id = offer_id;
    assignment_map[slot_id].is_last_chance = is_last_chance || false;
    assignment_map[slot_id].is_surprise_me = is_surprise_me || false;
    assignment_map[slot_id].invitation_id = invitation_id || null;
    assignment_map[slot_id].new_invitation = new_invitation || null;

    let update_state = { assignment_map, show_error_message: false, selected_offers: Object.values(assignment_map).map(a => a.offer_id).filter(a => a) };

    if (is_surprise_me) {
      Object.assign(update_state, this.next_offer(null, true));
    }
    this.setState(update_state);
  }

  fetch_friends_to_invite(offer_id) {
    if (this.loading_invite_statuses) return false;

    if (this.state.offer_map[offer_id].invite_list) {
      // already fetched the friends for this offer
    } else {
      this.setState({ show_invite_spinner: true });
      this.loading_invite_statuses = true;
      $.ajax({
        method: "GET",
        url: "/offer_invite_status/" + this.props.token,
        data: { offer_id, friend_account_ids: this.props.friends.map(f => f.id) },
        dataType: 'json',
        success: (data) => {
          let offer_map = Object.assign({}, this.state.offer_map);
          offer_map[offer_id].invite_list = data.friends;
          this.loading_invite_statuses = false;
          this.setState({ offer_map, show_invite_spinner: false });
        },
        error: (error) => {
          this.loading_invite_statuses = false;
          this.setState({ show_invite_spinner: false });
        }
      });
    }
  }

  on_take_credit(slot_id, chose_credit) {
    let assignment_map = this.state.assignment_map;

    assignment_map[slot_id].chose_credit = chose_credit;
    if (chose_credit) {
      this.confirmation_button_ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    this.setState({ assignment_map, show_error_message: false, selected_offers: Object.values(assignment_map).map(a => a.offer_id).filter(a => a) });
  }

  next_available_choice_index(choice_index = this.state.choice_index) {
    choice_index++;
    let assignment_slot = this.state.assignment_slots[choice_index];
    while (choice_index < this.state.assignment_slots.length && assignment_slot.assignment/* && (assignment_slot.assignment || (this.state.assignment_map[assignment_slot.id].chose_credit && assignment_slot.valid_offers.length == 0)) */) {
      choice_index++;
      assignment_slot = this.state.assignment_slots[choice_index];
    }
    return choice_index;
  }

  can_invite_friends(assignment_map) {
    // Can not invite if accepting someone else's invite
    if (assignment_map.invitation_id != null) {
      return false
    }
    
    let offer = this.state.offer_map[assignment_map.offer_id];
    // If we have not fetched the friends for this offer yet, we'll skip the inviting.
    // This could be a race condition if someone has a lot of friends or clicks really quickly
    if (offer.invite_list == null) {
      return false;
    }
    return true;

    // let friend_list = offer.invite_list;
    // let anyone_to_invite = false;
    // // If at least one person can be invited...
    // Object.keys(friend_list).forEach(friend_key => {
    //   if (friend_list[friend_key].can_get_offer) {
    //     anyone_to_invite = true;
    //   }
    // });
    // return anyone_to_invite;
  }

  next_offer(event, params_only = false) {
    if(this.loading_invite_statuses) return false;

    let choice_index = this.state.choice_index;
    let is_ready_to_submit = this.state.is_ready_to_submit;
    let current_assignment_slot = this.state.assignment_slots[this.state.choice_index]
    let current_assignment_map = this.state.assignment_map[current_assignment_slot.id];
    let currently_inviting = this.state.show_invite_modal;
    let show_invite_modal = false;

    // If no offer or credit has been selected, show error message
    if (!params_only && !current_assignment_map.offer_id && !current_assignment_map.chose_credit) {
      this.setState({ show_error_message: true })
      return;
    }

    // TODO check here if it is a new offer (not accepting an invite) and if so, show the invite modal
    if (!currently_inviting && this.can_invite_friends(current_assignment_map)) {
      show_invite_modal =  true;
    }

    if (!show_invite_modal) {
      choice_index = this.next_available_choice_index(choice_index);

      if (!this.state.assignment_slots[choice_index]) {
        is_ready_to_submit = true;
        choice_index--;
      }
    }
    document.body.scrollTop = 0;

    let params = { choice_index, is_ready_to_submit, interstitial_confirmed: true, show_invite_modal };
    if (params_only) {
      return params;
    } else {
      this.setState(params);
    }
  }

  modalOverlayClick(event) {
    if (event.target.classList.contains("modal-overlay")) {
      this.setState({ open_modal: null });
    }
  }

  update_offer_cache(offer) {
    if (!offer || !offer.id) return false;
    let offer_cache = this.state.offer_cache;
    offer_cache[offer.id] = offer;
    this.setState({ offer_cache });
  }

  openModal(render_element) {
    let render_modal = (
      <div className="modal-overlay" onClick={this.modalOverlayClick}>
        {render_element}
      </div>
    )

    this.setState({ open_modal: render_modal })
  }

  closeModal() {
    this.setState({ open_modal: null })
  }

  removeInvite(invite_id) {
    let invitations = [...this.state.invitations];
    invitations = invitations.filter(i => i.id != invite_id);
    this.setState({ invitations });
  }

  getCurrentMonth() {
    const cmonth = new Date();
    const month_name = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return (month_name[new Date(cmonth.getFullYear(), cmonth.getMonth(), 1).getMonth()]);
  }

  getCurrentMonthAbbreviated() {
    const cmonth = new Date();
    const year = cmonth.getFullYear();
    let feb = "Feb 28";
    if ((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) {
      feb = "Feb 29";
    }

    const month_name = ["Jan 31", feb, "Mar 31", "Apr 30", "May 31", "Jun 30", "Jul 31", "Aug 31", "Sep 30", "Oct 31", "Nov 30", "Dec 31"];
    return (month_name[new Date(year, cmonth.getMonth(), 1).getMonth()]);
  }

  interstitialRenderItems(current_assignment_slot) {
    return {
      header_content: (<></>),
      main_content: (
        <OfferIntertitials
          offer_slot_number={this.state.choice_index + 1}
        />
      ),
      footer_content: (
        <>
          <ConfirmationButton
            on_click_action={this.confirmInterstitial}
            button_label="Get Started"
            is_active={true}
            error_message={this.state.show_error_message ? "" : null}
            child_ref={this.confirmation_button_ref}
          />
        </>
      )
    }
  }

  offerSelectionRenderItems(current_assignment_slot, current_assignment_map, current_valid_offers, current_valid_last_chance_offers, current_valid_repeat_offers, available_invitations) {
    return {
      header_content: (<></>),
      main_content: (
        <>
          <OfferChoice
            token={this.props.token}
            account_id={this.props.account_id}
            friends={this.props.friends}
            valid_offers={current_valid_offers}
            offers_going_fast={this.state.offers_going_fast}
            total_months_active={this.state.total_months_active}
            cutoff_new_member={this.state.cutoff_new_member}
            last_chance_offers={current_valid_last_chance_offers}
            repeat_offers={current_valid_repeat_offers}
            selected_offer_choice={current_assignment_map.offer_id}
            selected_invitation={current_assignment_map.invitation_id}
            index={this.state.choice_index + 1}
            slot_id={current_assignment_slot.id}
            slot={current_assignment_slot}
            slot_total={this.state.assignment_slots.length}
            update_offer_selection={this.update_offer_selection}
            fetch_friends_to_invite={this.fetch_friends_to_invite}
            month={this.getCurrentMonth()}
            all_locations={this.state.all_locations}
            all_categories={this.state.all_categories}
            all_friends={this.state.all_friends}
            all_offer_types={this.state.all_offer_types}
            offer_cache={this.state.offer_cache}
            update_offer_cache={this.update_offer_cache}
            updateOfferData={this.updateOfferData}
            invitations={available_invitations}
            removeInvite={this.removeInvite}
          />
        </>
      ),
      footer_content: (
        <>
          {/* TODO: Add invitation selection in here */}
          <ActiveSelection
            selected_offer={this.state.offer_map[current_assignment_map.offer_id]}
            total_selected_offers={this.state.choice_index + 1}
            clear_selection={this.clear_selection}
            slot_id={current_assignment_slot.id}
            error_message={this.state.show_error_message ? 'Select an Offer to Continue' : null}
          />
          <ConfirmationButton
            on_click_action={this.next_offer}
            button_label="Continue"
            is_active={true}
            error_message={null}
            show_spinner={this.state.show_invite_spinner}
            child_ref={this.confirmation_button_ref}
          />
        </>
      )
    }
  }

  offerInviteRender(current_assignment_map) {
    let offer = this.state.offer_map[current_assignment_map.offer_id];
    let list = offer.invite_list;
    let friends = Object.keys(list).map((friend_key) => list[friend_key]);
    return {
      header_content: (<></>),
      main_content: (
        <>
          <OfferInvite 
            offer={offer}
            friends={friends}
            update_invite_list={this.update_offer_invitation}
            continue={this.next_offer}
            />
        </>
      )
    }
  }

  creditRenderItems(current_assignment_slot, current_assignment_map) {
    return {
      header_content: (
        <div className={"offers-title credit"}>
          {this.state.is_ready_to_submit ? "Confirm Your " + this.getCurrentMonth() + " Choices" : "Choose Your " + this.getCurrentMonth() + " Offers"}
        </div>
      ),
      main_content: (
        <NoOffersAvailable
          take_credits_for_month={current_assignment_map.chose_credit}
          on_take_credit={this.on_take_credit}
          index={this.state.choice_index + 1}
          slot_id={current_assignment_slot.id}
          slot_name={current_assignment_slot.name}
          slot_total={this.state.assignment_slots.length}
          error_message={this.state.show_error_message ? 'To continue, update your preferences or select "take credits for this month."' : null}
        />
      ),
      footer_content: (
        <>
          <ConfirmationButton
            on_click_action={this.next_offer}
            button_label="Continue"
            is_active={true}
            error_message={null}
            child_ref={this.confirmation_button_ref}
          />
        </>
      )
    }
  }

  confirmationRenderItems() {
    return {
      header_content: (
        <div className={"offers-title"}>
          {"Confirm Your " + this.getCurrentMonth() + " Choices"}
        </div>
      ),
      main_content: (
        <ConfirmYourOffers
          offer_map={this.state.offer_map}
          selected_offers={Object.values(this.state.assignment_map)}
          invitations={this.state.invitations}
          redo_selection={this.redo_selection}
          child_ref={this.confirmation_button_ref}
          assignment_slots={this.state.assignment_slots}
        />
      ),
      footer_content: (
        <>
          {this.state.selected_offers.length > 0 && <div className="wrap-text">
            {"You have until " + this.getCurrentMonthAbbreviated() + " to use.\r\nMake sure you're at restaurant to redeem."}
          </div>}
          <ConfirmationButton
            on_click_action={this.submit_offer_selection}
            show_spinner={this.state.network_busy}
            button_label="Confirm Selections"
            is_active={true}
            error_message={this.state.show_error_message ? "" : null}
            child_ref={this.confirmation_button_ref}
          />
        </>
      )
    }
  }

  renderAlreadyAssignedModal() {
    return (
      <div className="modal-overlay" onClick={this.modalOverlayClick}>
        <div className="last-chance-info">
          <div className="modal-body">
            <img className="map" src={IconOfferSelectModel} alt="" />
            <div className="flavor-text">Offer(s) Already Confirmed</div>
            <div className="body-text">It looks like someone else from a group you're linked to has chosen an Offer(s) for your group so we've automatically loaded that into your account.</div>
            <button className="offer-button-full full" onClick={this.closeModal}>DISMISS</button>
            <img className="close" src={IconClose} onClick={this.closeModal} alt="" />
          </div>
        </div>
      </div>
    )
  }

  renderOverCapacityModal(assignment_slot_id) {
    let assignment_slot = this.state.assignment_map[assignment_slot_id];
    let offer_id = assignment_slot.offer_id;
    let offer = this.state.offer_map[offer_id];

    return (
      <div className="modal-overlay">
        <div className="last-chance-info">
          <div className="modal-body">
            <img className="map" src={IconOfferSelectModel} alt="" />
            <div className="flavor-text">{`Sorry, ${offer.name} is over capacity.`}</div>
            <div className="body-text">{` Unfortunately, we couldn't get you ${offer.name}. Sometimes we run out of capacity for a partner while members are choosing. Please pick another Offer to complete your monthly choices.`}</div>
            <button className="offer-button-full full" onClick={() => { this.redo_selection(true) }}>DISMISS</button>
            <img className="close" src={IconClose} onClick={() => { this.redo_selection(true) }} alt="" />
          </div>
        </div>
      </div>
    )
  }

  render_confirmation() {

    return (
      <div id="pick_your_own_offers">
        <div className="confirmation-container">
          <div className="offer-confirmation-title">Offers Secured!!</div>
          <div className="offer-confirmation-subtitle">Head back to the app to check out your new Offers</div>
          <img src={IconConfirmation} className="confirmation-icon" />
          {/* <div className="confirmation-back-to-app">
            <a className="offer-button-full full" href="/close_web_view">Close Window</a>
          </div> */}
        </div>
        {this.state.open_modal != null && this.state.open_modal}
      </div>
    )
  }

  render_loading() {

    return (
      <div id="pick_your_own_offers">
        <div className="confirmation-container">
          <div className="offer-confirmation-title">Loading Offers</div>
          <div className="offer-confirmation-subtitle">One sec while we get your Offer choices ready.</div>
          <img src={IconConfirmation} className="confirmation-icon" />
        </div>
      </div>
    )
  }

  render_loading_interstitial() {
    return (
      <div className="pick_your_own_offers">
        <OfferLoadingInterstitial/>
        <BottomBar
          hide_subtitle={true}
          content={
            <ConfirmationButton
              on_click_action={() => {
                this.setState({ confirmed_loaded: true });
              }}
              button_label={this.state.still_loading ? "Loading Offers" : "Get Started"}
              is_active={!this.state.still_loading}
              show_spinner={this.state.still_loading}
              error_message={this.state.show_error_message ? "" : null}
              child_ref={this.confirmation_button_ref}
              className="offer-loading-confirm-button"
            />
          }
        />
      </div>
    )
  }

  render() {

    if (!this.state.confirmed_loaded) {
      return this.render_loading_interstitial();
      // return this.render_loading();
    }

    if (this.state.assignment_slots.length == 0) {
      return (
        <div className="offers-title">No offer slots available. Please close this window.</div>
      )
    }

    if (this.state.show_confirmation) {
      return this.render_confirmation();
    }

    let current_assignment_slot = this.state.assignment_slots[this.state.choice_index]
    let current_assignment_map = this.state.assignment_map[current_assignment_slot.id];
    let current_valid_offers = this.state.all_valid_offers.filter(o => {
      return (!current_assignment_slot.invalid_offers.includes(o.identifier) && !this.state.blacklist_offers.includes(o.id) && !this.state.selected_offers.includes(o.id)) || current_assignment_map.offer_id == o.id
    });
    let current_valid_last_chance_offers = current_assignment_slot.last_chance_offers
    let current_valid_repeat_offers = current_assignment_slot.repeat_offers;

    let selected_invitation_ids = Object.entries(this.state.assignment_map).map(a => a[1].invitation_id);
    let available_invitations = this.state.invitations.map(invitation => {
      let offer = this.state.offer_map[invitation.offer_id];
      return { ...invitation, offer }
    })

    // Filter out invitations if they or the related offer has already been selected, unless this assignment slot is the one that has accepted the invitation
    let current_valid_invitations = available_invitations.filter(i => {
      return (
        (!selected_invitation_ids.includes(i.id) && !this.state.selected_offers.includes(i.offer_id)) || 
        (current_assignment_map.invitation_id == i.id || current_assignment_map.offer_id == i.offer_id)
      )
    });

    let render_items = null;
    if (this.state.is_ready_to_submit) { // If all offers have been selected, show confirmation screen
      render_items = this.confirmationRenderItems();
    } else if (!this.state.interstitial_confirmed) { // If we need to show an interstitial, show it
      render_items = this.interstitialRenderItems(current_assignment_slot);
      // } else if (current_valid_offers.length == 0 && current_valid_last_chance_offers.length == 0) { // If there are no available offers, show credit screen
      //   render_items = this.creditRenderItems(current_assignment_slot, current_assignment_map);
    } else { // If none of the other options apply, then show offer choice screen
      if (this.state.show_invite_modal) {
        render_items = this.offerInviteRender(current_assignment_map);
      } else {
        render_items = this.offerSelectionRenderItems(current_assignment_slot, current_assignment_map, current_valid_offers, current_valid_last_chance_offers, current_valid_repeat_offers, current_valid_invitations);
      }
    }

    return (
      <div id="pick_your_own_offers">
        {render_items.header_content}
        {render_items.main_content}
        {render_items.footer_content &&
          <BottomBar
            content={render_items.footer_content}
          />
        }
        {this.state.open_modal != null && this.state.open_modal}
      </div>
    );
  }
}

export default PickYourOffers