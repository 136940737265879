import React from "react";
import moment from "moment-timezone";
import SortableTable from "../shared/sortable_table";
import ExpandingText from "../shared/expanding_text";
import FormSelect from "../shared/form_select";
import IconLinkedGroup from "icon-linked-group.svg";

class InstagramPosts extends React.Component {
  constructor(props) {
    super(props);

    // let instagram_accounts = this.props.areas.map(area => area.instagram_handle);
    // instagram_accounts = Array.from(new Set(instagram_accounts));
    let instagram_accounts = [];
    let instagram_accounts_map = {};

    for (let area of this.props.areas) {
      let ig_handle = area.instagram_handle;
      if (ig_handle) {
        if (!instagram_accounts.includes(ig_handle)) {
          instagram_accounts.push(ig_handle);
        }
        if (!instagram_accounts_map[ig_handle]) {
          instagram_accounts_map[ig_handle] = [];
        }
        instagram_accounts_map[ig_handle].push(area.id);
      }
    }

    let offer_options = this.props.offers.sort((a, b) => a.name.trim() > b.name.trim() ? 1 : -1).map(offer => {
      return { value: offer.id, label: ((offer.hubspot_ig ? `${offer.hubspot_ig} - ` : '') + offer.name) }
    });

    offer_options = [{ value: null, label: "None" }, ...offer_options];

    let offers_with_ig = this.props.offers.filter(offer => offer.hubspot_ig);

    let filter_date = localStorage.getItem("filter_date") || "";
    let filter_ig_handle = localStorage.getItem("filter_ig_handle") || "";
    let filter_caption = localStorage.getItem("filter_caption") || "";
    let filter_offline_accounts = JSON.parse(localStorage.getItem("filter_offline_accounts")) || [];

    this.state = {
      instagram_accounts,
      instagram_accounts_map,
      filter_date,
      filter_ig_handle,
      filter_caption,
      filter_offline_accounts,
      offer_options,
      offers_with_ig,
      posts: this.props.posts,
      post_updates: {},
      show_user_modal: null,
      account_id_user_modal: null,
      account_id_map: {},
    };

    this.state.ig_handle_map = this.props.posts.reduce((acc, post) => {
      acc[post.author] = null;
      return acc;
    }, {});


    this.state.network_busy = false;

    this.query_for_user = this.query_for_user.bind(this);
    this.render_posts = this.render_posts.bind(this);
    this.get_account_from_account_id = this.get_account_from_account_id.bind(this);
    this.get_account_from_handle_map = this.get_account_from_handle_map.bind(this);
    this.update_row_mention = this.update_row_mention.bind(this);
    this.get_account_from_handle = this.get_account_from_handle.bind(this);
    this.get_filtered_area_ids = this.get_filtered_area_ids.bind(this);
    this.find_partners_in_caption = this.find_partners_in_caption.bind(this);
    this.update_filter = this.update_filter.bind(this);
    this.update_filter_offline_accounts = this.update_filter_offline_accounts.bind(this);
    this.render_user_modal = this.render_user_modal.bind(this);
  }

  query_for_user(row) {
    this.get_account_from_handle(row.author)
    let update = {
      show_user_modal: row,
      account_id_user_modal: null
    }
    this.setState(update);
  }

  give_points(post_id, update, account) {
    let path = '/give_points_for_ig_post';
    let data = {
      post_id,
      offer_id: update.offer_id,
      account_id: update.account_id
    }

    this.setState({ network_busy: true });
    $.ajax({
      method: "POST",
      url: path,
      data,
      success: (data) => {
        let posts = this.state.posts;

        let i = 0;
        for (let post of posts) {
          if (post.id == post_id) {
            posts[i] = data;
            break;
          }
          i++;
        }

        let updates = this.state.post_updates;
        updates[post_id] = null;

        this.setState({ posts, network_busy: false, post_updates: updates });
      },
      error: (error) => {
        this.setState({ network_busy: false });
        showFlashErrorMessage(error.responseJSON.message);
      }
    });
  }

  get_account_from_account_id(account_id) {
    let path = '/find_member_from_account_id?account_id=' + account_id;
    this.setState({ network_busy: true });

    $.ajax({
      method: "GET",
      url: path,
      success: (data) => {
        this.setState({ network_busy: false });
        let row = this.state.show_user_modal;
        if (!data || !data.accounts || data.accounts.length == 0 || !row) {
          return;
        }
        
        let post_id = row.id;

        let updates = this.state.post_updates;
        updates[post_id] = updates[post_id] || {};
        updates[post_id].account_id = account_id;

        let account_id_map = this.state.account_id_map;

        account_id_map[account_id] = data.accounts;

        this.setState({ post_updates: updates, account_id_map, show_user_modal: null, account_id_user_modal: null });
      },
    });
  }

  get_account_from_handle(handle) {
    let path = '/find_member_from_instagram_handle?ig_handle=' + handle;
    this.setState({ network_busy: true });
    $.ajax({
      method: "GET",
      url: path,
      success: (data) => {
        this.network_busy = false;
        if (!data || !data.accounts || data.accounts.length == 0) {
          this.setState({ network_busy: false });
          return;
        }

        let ig_handle_map = this.state.ig_handle_map;
        ig_handle_map[handle] = data.accounts;

        this.setState({ ig_handle_map, network_busy: false });
      },
      error: (error) => {
        this.setState({ network_busy: false });
        showFlashErrorMessage(error.responseJSON.message);
      }
    });
  }

  find_partners_in_caption(caption) {
    let offers = this.state.offers_with_ig;
    let matching_offers = [];

    for (let offer of offers) {
      if (caption.includes(offer.hubspot_ig)) {
        matching_offers.push(offer.id);
        break;
      }
    }

    return matching_offers[0] || null;
  }

  get_account_from_handle_map(handle) {
    return this.state.ig_handle_map[handle];
  }

  update_row_mention(post, value, is_guess) {

    let updates = this.state.post_updates;
    updates[post.id] = updates[post.id] || {};
    updates[post.id].offer_id = value;
    if (is_guess) {
      updates[post.id].stop_guessing = true;
    }

    this.setState({ post_updates: updates });
  }

  get_filtered_area_ids() {
    if (this.state.filter_offline_accounts.length > 0) {
      return this.state.filter_offline_accounts.map(ig_handle => this.state.instagram_accounts_map[ig_handle]).flat();
    }
    return [];
  }

  update_filter(e) {
    let filter_value = e.target.value;
    let filter_name = e.target.dataset.value;

    this.setState({ [filter_name]: filter_value });
    localStorage.setItem(filter_name, filter_value);
  }

  update_filter_offline_accounts(context, values) {
    this.setState({ filter_offline_accounts: values });
    localStorage.setItem("filter_offline_accounts", JSON.stringify(values));
  }

  render_user_modal() {
    let user = this.state.show_user_modal;
    if (!user) {
      return (<></>);
    }

    let accounts = this.state.ig_handle_map[user.author];

    let cancel_button = (
      <button className="btn btn-primary" onClick={() => this.setState({ show_user_modal: null, account_id_user_modal: null })}>Cancel</button>
    )
    let confirm_button = (
      <button className="btn btn-primary" onClick={() => {
        let update = {
          show_user_modal: null, account_id_user_modal: null
        }

        let updates = this.state.post_updates;
        updates[user.id] = updates[user.id] || {};
        updates[user.id].account_id = this.state.account_id_user_modal;

        if (this.state.account_id_user_modal) {
          let ig_handle_map = this.state.ig_handle_map;
          ig_handle_map[user.author] = accounts.filter(account => account.account_id == this.state.account_id_user_modal);
          update.post_updates = updates;
          update.ig_handle_map = ig_handle_map;
        } else {
          let ig_handle_map = this.state.ig_handle_map;
          ig_handle_map[user.author] = null;
          update.ig_handle_map = ig_handle_map;
        }

        this.setState(update)
      }}>Confirm</button>
    )

    let modal_body = (
      <>
        <div className="user-modal-body">
          <div className="user-modal-title">Searching...</div>
          <div className="user-modal-subtitle">Please wait while we search for the user...</div>
          <div className="result-table"></div>
        </div>
      </>
    )

    let modal_footer = (
      <>
        <div className="user-modal-footer">
          {cancel_button}
        </div>
      </>
    )

    if (this.state.network_busy) {
      return (
        <div className="user-modal-container">
          <div className="user-modal-box">
            {modal_body}
            {modal_footer}
          </div>
        </div>
      )
    }

    modal_footer = (
      <>
        <div className="user-modal-footer">
          {cancel_button}
          {confirm_button}
        </div>
      </>
    )

    if (!accounts) {
      modal_body = (
        <>
          <div className="user-modal-body">
            <div className="user-modal-title">No User Found</div>
            <div className="user-modal-subtitle">No user was found with the handle {user.author}.</div>
            <div>If you have a Account ID for a user to attribute the post to, enter it here:</div>
            <input type="text" value={this.state.account_id_user_modal} onInput={(e)=>{
              this.setState({ account_id_user_modal: e.target.value });
            }}></input>
          </div>
        </>
      )
      confirm_button = (
        <button className="btn btn-primary" onClick={() => {
          this.get_account_from_account_id(this.state.account_id_user_modal);
        }}>Confirm</button>
      )
      modal_footer = (
        <>
          <div className="user-modal-footer">
            {cancel_button}
            {confirm_button}
          </div>
        </>
      )
    } else if (accounts.length == 1) {
      let account = accounts[0];
      modal_body = (
        <>
          <div className="user-modal-body">
            <div className="user-modal-title">User Found</div>
            <div className="user-modal-subtitle">{`User "(${account.account_id}) ${account.slug}" was found with the handle ${account.social_handles}.`}</div>
            <div>Would you like to attribute this post to this user?</div>
          </div>
        </>
      )
    } else {
      modal_body = (
        <div className="user-modal-body">
          <div className="user-modal-title">Multiple Users Found</div>
          <div className="user-modal-subtitle">Multiple users were found with the handle {user.author}.</div>
          <div>Select the user you would like to attribute this post to:</div>
          <FormSelect
            options={accounts.map(account => {
              return { value: account.account_id, label: `(${account.account_id}) ${account.slug} - ${account.social_handles}` }
            })}
            value={this.state.account_id_user_modal}
            onChange={(context, value) => this.setState({ account_id_user_modal: value })}
            multiselect={false}
            className=""
          />
        </div>
      )
    }


    return (
      <div className="user-modal-container">
        <div className="user-modal-box">
          {modal_body}
          {modal_footer}
        </div>
      </div>
    )
  }

  render_posts() {
    let posts = this.state.posts;

    posts = posts.sort((a, b) => {
      return moment(a.post_date).isBefore(b.post_date) ? 1 : -1
    });

    let filtered = false;

    posts = posts.filter(post => {
      if (this.state.filter_date) {
        filtered = true;
        return moment(post.post_date).isAfter(this.state.filter_date);
      }
      return true;
    });

    posts = posts.filter(post => {
      if (this.state.filter_ig_handle) {
        filtered = true;
        return post.author.includes(this.state.filter_ig_handle);
      }
      return true;
    });

    posts = posts.filter(post => {
      if (this.state.filter_caption) {
        filtered = true;
        return post.caption.includes(this.state.filter_caption);
      }
      return true;
    });

    posts = posts.filter(post => {
      if (this.state.filter_offline_accounts.length > 0) {
        filtered = true;
        return this.get_filtered_area_ids().includes(post.area_id);
      }
      return true;
    });

    let row_templates = [
      {
        header_label: "Offline Account",
        sort_by_property: "area_id",
        render_cell: (row) => {
          let area = this.props.areas.find(area => area.id === row.area_id);
          return area.abbreviation + " Instagram";
        },
        sort_function: (a, b) => {
          let area_a_abbr = this.props.areas.find(area => area.id === a.area_id).abbreviation;
          let area_b_abbr = this.props.areas.find(area => area.id === b.area_id).abbreviation;
          return area_a_abbr > area_b_abbr ? 1 : -1;
        }
      },
      {
        header_label: "Instagram Post",
        sort_by_property: "author",
        render_cell: (row) => {
          let author = row.author;
          let accounts = this.get_account_from_handle_map(author);

          if(!accounts) {
            let updates = this.state.post_updates[row.id];
            if(updates && updates.account_id) {
              accounts = this.state.account_id_map[updates.account_id];
            }
          }

          let button = (
            <div>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => this.query_for_user(row)}
              >Search For User</button>
            </div>
          )

          if (accounts) {
            author = (
              <a href={"/accounts/" + accounts[0].slug + "/edit"} style={{ marginBottom: "6px" }} target="_blank">
                {author + " - edit (" + accounts[0].id + ")"}
                <img src={IconLinkedGroup} alt=" (Link)" style={{ marginLeft: "6px", filter: "hue-rotate(180deg)", width: "16px" }} />
              </a>
            )
            button = (<></>);
          }

          return (
            <>
              {author}
              {button}
              <div style={{ marginTop: "8px" }}>
                <a href={row.post_url} target="_blank" style={{ display: "flex", gap: "6px", alignItems: "self-end" }}>
                  {moment(row.post_date).format("MMM Do, YYYY h:mm a")}
                  <img src={IconLinkedGroup} alt=" (Link)" style={{ filter: "hue-rotate(180deg)", width: "16px" }} />
                </a>
              </div>
            </>
          )
        },
        sort_function: (a, b) => {

          if (a.author < b.author) {
            return -1;
          }

          return moment(a.post_date).isBefore(b.post_date) ? 1 : -1;
        }
      },
      {
        header_label: "Caption",
        sort_by_property: "caption",
        render_cell: (row) => {
          return (
            <ExpandingText
              value={row.caption}
              extra_classes="mention-value"
            />
          )
        }
      },
      {
        header_label: "Mentioned Partner",
        sort_by_property: "at_mentions",
        render_cell: (row) => {
          let value = row.mentions.map(mention => mention.offer_id);
          value = value.filter(v => v);

          let update = this.state.post_updates[row.id];
          let is_updated = false;
          let can_guess = true;
          if (update) {
            if (update.offer_id) {
              is_updated = value != update.offer_id
              value = update.offer_id;
            }
            can_guess = !update.stop_guessing;
          }

          let is_guess = false;
          if ((!value || value.length == 0) && can_guess) {
            value = this.find_partners_in_caption(row.caption);
            is_guess = value ? true : false;
          }
          return (
            <>
              <div className={"mention-value label-text" + (is_updated ? " cell-updated" : "")}>
                {"Mentioned Partner: "}
              </div>
              {is_guess &&
                <div className="mention-value" style={{ display: "flex", gap: "2px" }}>
                  <div className="guess-highlight">
                    {"(Auto Guessed)"}
                  </div>
                  <button className="btn btn-primary btn-sm" onClick={() => { this.update_row_mention(row, value, true) }}>
                    {"Confirm Guess"}
                  </button>
                </div>
              }
              <FormSelect
                options={this.state.offer_options.filter(offer => {
                  if (offer.area_id == null) return true;
                  return this.get_filtered_area_ids().includes(offer.area_id);
                })}
                value={value}
                onChange={(context, value) => this.update_row_mention(row, value, is_guess)}
                className={"max-width-250px" + (is_guess ? " is-guess" : "")}
                multiselect={false}
              />
            </>
          )
        }
      },
      {
        header_label: "Action",
        sort_by_property: "action",
        render_cell: (row) => {
          let value = row.mentions.map(mention => mention.offer_id);
          value = value.filter(v => v);
          let already_redeemed = row.mentions.some(mention => mention.credited);

          let update = this.state.post_updates[row.id];
          let is_updated = false;
          let can_guess = true;
          if (update) {
            if (update.offer_id) {
              is_updated = value != update.offer_id
              value = update.offer_id;
            }
            can_guess = !update.stop_guessing;
          }

          let is_guess = false;
          if ((!value || value.length == 0) && can_guess) {
            value = this.find_partners_in_caption(row.caption);
            is_guess = value ? true : false;
          }

          let accounts = this.get_account_from_handle_map(row.author);
          let account = accounts ? accounts[0] : null;

          if (update && update.account_id && !account) {
            accounts = this.state.account_id_map[update.account_id]
            account = accounts ? accounts[0] : null;
          }

          return (
            <>
              <div className="mention-value label-text">
                {"Action: "}
              </div>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => {
                  this.give_points(row.id, update, account);
                }}
                disabled={(!already_redeemed && is_updated && account) ? false : true}
              >{already_redeemed ? "Points Given" : "Give Points (+5)"}</button>
            </>
          )
        },
        sort_function: (a, b) => {
          return 0;
        }
      }
    ]

    let new_table = (
      <>
        <div className="instagram-post-count">
          {posts.length + (filtered ? " Matching Posts" : " Posts")}
        </div>
        <SortableTable
          rows_data={posts}
          row_templates={row_templates}
        />
      </>
    )

    return new_table;
  }

  render() {
    return (
      <>
        <div className="instagram-member-credit">
          <div className="header_items">
            <div className="left-items">
              <h2>User Generated @mentions</h2>
              {/* <div className="show-all-areas">
                <div className="show-all-areas-label">Show All Areas:</div>
                <ToggleSwitch
                  value={!this.state.show_all_areas}
                  onChange={this.toggle_show_all_areas}
                />
              </div> */}
            </div>
            <div className="right-items">
              {/* <input type="button" value="Save Changes" className="admin__btn--create-new" /> */}
            </div>
          </div>
          <div className="instagram-post-controls">
            <div className="instagram-toolbar">
              <div className="instagram-toolbar-item">
                <div className="toolbar-label">
                  Show Posts After:
                </div>
                <input type="date" data-value={"filter_date"} value={this.state.filter_date} onChange={this.update_filter} />
              </div>
              <div className="instagram-toolbar-item">
                <div className="toolbar-label">
                  Show Only Offline Accounts:
                </div>
                <FormSelect
                  options={this.state.instagram_accounts.map(ig_handle => {
                    return { value: ig_handle, label: ig_handle }
                  })}
                  value={this.state.filter_offline_accounts}
                  onChange={this.update_filter_offline_accounts}
                  multiselect={true}
                  className=""
                />
              </div>
              <div className="instagram-toolbar-item">
                <div className="toolbar-label">
                  Search IG Handle:
                </div>
                <input type="text" data-value={"filter_ig_handle"} value={this.state.filter_ig_handle} onChange={this.update_filter} />
              </div>
              <div className="instagram-toolbar-item">
                <div className="toolbar-label">
                  Search Captions:
                </div>
                <input type="text" data-value={"filter_caption"} value={this.state.filter_caption} onChange={this.update_filter} />
              </div>
            </div>
          </div>
          {this.render_posts()}
          {this.render_user_modal()}
        </div>
      </>
    )
  }
}

export default InstagramPosts;