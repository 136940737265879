import React from "react";
import { convertIntegerToPrice } from '../../../../helpers/price_formatter';
import { getIDforGTM } from "../../v2/state_components/gtm_methods"
import { validateReferralCode } from "./network_methods_v3"
import { data } from "jquery";

class ReferralCodeInputV3 extends React.Component {
  constructor(props) {
    super(props);

    this.validateReferralCode = this.validateReferralCode.bind(this);
    this.removeReferralCode = this.removeReferralCode.bind(this);
    this.updateInputReferralCode = this.updateInputReferralCode.bind(this);
    this.referralSuccessMessage = this.referralSuccessMessage.bind(this);
    this.referralErrorMessage = this.referralErrorMessage.bind(this);

    this.state = {
      referral_code: props.referral_code || "",
      valid_referral: props.valid_referral || "",
      referral_details: props.referral_details || null,
      error_referral_code: null,
      hide_status: false
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.selected_market != this.props.selected_market) {
      this.removeReferralCode();
      this.validateReferralCode();
    }
  }

  updateInputReferralCode(event) {
    this.setState({ referral_code: event.target.value });
  }

  validateReferralCode(event, area_id) {
    area_id = area_id || this.props.selected_market;
    validateReferralCode(this.state.referral_code, area_id, (data) => {
      this.props.onRefferalSubmit(this.state.referral_code, data);
      this.setState({ valid_referral: this.state.referral_code, referral_details: data, error_referral_code: null, hide_status: false });
    }, (error) => {
      this.setState({ error_referral_code: error.responseJSON.error, referral_details: null, hide_status: false });
    })
  }

  referralCodeInputStatus() {
    let className = this.props.valid_referral ? "disable-input" : "";

    if (this.state.hide_status) {
      className;
    } else if (this.state.error_referral_code) {
      className += " error";
    } else if (this.state.referral_details && this.props.valid_referral) {
      className += " success";
    }
    return className;
  }

  removeReferralCode() {
    this.props.onReferralClear();
    // this.setState({ hide_status: true })
    // this.setState({ referral_code: "", valid_referral: undefined, referral_details: undefined })
  }

  referralSuccessMessage() {
    let message = "";
    if (!this.state.referral_details || !this.props.valid_referral || this.state.hide_status) {
      return message;
    }

    if (this.state.referral_details.type == "giftcode") {
      message = "Your gift code has been successfully applied! Your account will be credited $" + convertIntegerToPrice(this.state.referral_details.referral?.amount) + ". 🥳";
    } else if (this.state.referral_details.referral?.message_for_monthly_checkout) {
      message = this.state.referral_details.referral.message_for_monthly_checkout;
    } else {
      message = "Your code has been successfully applied! 🥳";
    }
    return message;
  }

  referralErrorMessage() {
    if (this.state.hide_status) {
      return "";
    }

    if (this.state.error_referral_code) {
      return this.state.error_referral_code;
    }

    if (this.props.error_referral_code) {
      return this.props.error_referral_code;
    }

    return "";
  }

  render() {
    let button_text = "APPLY";
    let button_class = "sign-up-button-min inline";
    let onClick = this.validateReferralCode;
    let button_id = getIDforGTM("apply-referral-code", this.props);

    if (this.props.valid_referral) {
      button_text = "REMOVE";
      button_class = "inline remove-referral"
      onClick = this.removeReferralCode;
      button_id = getIDforGTM("remove-referral-code", this.props);
    }

    let error_message = this.referralErrorMessage();
    let success_message = this.referralSuccessMessage();

    return (
      <div className="input-referral-code">
        <div className="referral-container">
          <input type="text"
            id="input_referral_code"
            name="referral_code"
            placeholder="Enter Referral Code"
            autoCapitalize="none"
            className={this.referralCodeInputStatus() + " membership-info-input"}
            onChange={this.updateInputReferralCode}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClick();
              }
            }}
            disabled={this.props.valid_referral ? true : false}
            value={this.state.referral_code} />
          <button
            id={button_id}
            className={button_class}
            onClick={onClick}>
            {button_text}
          </button>
        </div>
        {!success_message && !error_message &&
          <div className="referral-code-description">**Offline requires a referral code to enter
          </div>}
        <div className="input-error">{error_message}</div>
        <div className="input-success">{success_message}</div>
      </div>
    )
  }
}

export default ReferralCodeInputV3