import React from "react";
import FormBuilder from "../shared/form_builder";

class FriendshipEdit extends React.Component {
  constructor(props) {
    super(props);
    let sent = null;

    this.state = {
      invite_account_id: "",
      new_friend_account_id: "",
      friendships: this.props.friendships,
      status_message: {
        message: "",
        warning: false,
        hide: true
      },
    }

    this.acceptFriendRequest = this.acceptFriendRequest.bind(this);
    this.createTotalFriendship = this.createTotalFriendship.bind(this);
    this.createInvite = this.createInvite.bind(this);
    this.destroyFriendship = this.destroyFriendship.bind(this);
    this.setAutoAcceptStatus = this.setAutoAcceptStatus.bind(this);
    this.changeInputField = this.changeInputField.bind(this);
    this.render_friendship_row = this.render_friendship_row.bind(this);
  }

  acceptFriendRequest(e) {
    if(!confirm("Are you sure you want to accept this friend request?")) return;

    let path = "/friendships";
    let formBuilder = new FormBuilder();
    let friendship = this.state.friendships.filter(f => f.id == e.target.dataset.id)[0];

    let friendship_data = {
      friendship: {
        friend_id: this.props.account.id,
        account_id: friendship.friend.id,
        invite_only: true
      }
    };

    var form_data = formBuilder.createForm(friendship_data);
    $.ajax({
      method: "POST",
      url: path,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        let friendships = this.state.friendships.filter(f => f.id != e.target.dataset.id);
        friendships.push(data);
        this.setState({ friendships });
      },
      error: (error) => {
        showFlashErrorMessage('There was an error accepting this friend request.');
        // this.setState({
        //   status_message: {message: error.responseJSON.message, warning: true, hide: false},
        // });
      }
    });
  }

  destroyFriendship(e) {
    if(!confirm("Are you sure you want to deny/destroy this friendship?")) return;

    let path = "/friendships/" + e.target.dataset.id;
    $.ajax({
      method: "DELETE",
      url: path,
      data: {},
      processData: false,
      contentType: false,
      success: (data) => {
        let friendships = this.state.friendships.filter(f => f.id != e.target.dataset.id);
        this.setState({ friendships });
      },
      error: (error) => {
        showFlashErrorMessage('There was an error destroying this friendship.');
        // this.setState({
        //   status_message: {message: error.responseJSON.message, warning: true, hide: false},
        // });
      }
    });
  }

  createTotalFriendship(e) {
    let path = "/friendships";
    let formBuilder = new FormBuilder();
    let friendship_data = {
      friendship: {
        account_id: this.props.account.id,
        friend_id: this.state.new_friend_account_id
      }
    };

    var form_data = formBuilder.createForm(friendship_data);
    $.ajax({
      method: "POST",
      url: path,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        let friendships = this.state.friendships.filter(f => f.id != data.id);
        friendships.push(data);
        this.setState({ friendships });
      },
      error: (error) => {
        showFlashErrorMessage('There was an error creating this friendship.');
        // this.setState({
        //   status_message: {message: error.responseJSON.message, warning: true, hide: false},
        // });
      }
    });
  }

  createInvite(e) {
    let path = "/friendships";
    let formBuilder = new FormBuilder();
    let friendship_data = {
      friendship: {
        account_id: this.props.account.id,
        friend_id: this.state.invite_account_id,
        invite_only: true
      }
    };

    var form_data = formBuilder.createForm(friendship_data);
    $.ajax({
      method: "POST",
      url: path,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        let friendships = this.state.friendships.filter(f => f.id != data.id);
        friendships.push(data);
        this.setState({ friendships });
      },
      error: (error) => {
        showFlashErrorMessage('There was an error creating this friendship invite.');
        // this.setState({
        //   status_message: {message: error.responseJSON.message, warning: true, hide: false},
        // });
      }
    });
  }

  setAutoAcceptStatus(e) {
    

    let path = "/friendships/" + e.target.dataset.id;
    let friend = this.state.friendships.filter(f => f.id == e.target.dataset.id)[0];

    let formBuilder = new FormBuilder();
    let friendship_data = {
      friendship: {
        auto_accept: !friend.auto_accept
      }
    };

    if(!confirm("Are you sure you want to this friendship's auto-accept status to " + !friend.auto_accept + "?")) return;

    var form_data = formBuilder.createForm(friendship_data);

    $.ajax({
      method: "PUT",
      url: path,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        let friendships = this.state.friendships.filter(f => f.id != data.id);
        friendships.push(data);
        this.setState({ friendships });
      },
      error: (error) => {
        showFlashErrorMessage("There was an error changing this friendship's auto-accept status.");
        // this.setState({
        //   status_message: {message: error.responseJSON.message, warning: true, hide: false},
        // });
      }
    });
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;
    if (target.type == "checkbox") {
      return this.setState({ [name]: target.checked });
    }

    this.setState({ [name]: target.value });
  }

  render_friendship_row(friendship) {
    return (
      <>
        <div className="friendship-name">
          <a className="friendship-account-link" target="_blank" href={`/accounts/${friendship.friend.slug}/edit`}>{friendship.friend.first_name + " " + friendship.friend.last_name}</a>
        </div>
        {this.render_friend_options(friendship)}
      </>
    )
  }

  render_friend_options(friendship) {
    if (friendship.friendship_id) {
      return (
        <div className="friendship-options">
          <div className="auto-accept-label">{
            friendship.auto_accept ? "Auto-Accept Is On" : "Auto-Accept Is Off"
          }</div>
          <button
            style={{ marginTop: '10px' }}
            className={"standard-btn--rounded" + (friendship.auto_accept ? " auto-accept-on" : " auto-accept-off")}
            data-id={friendship.id}
            onClick={this.setAutoAcceptStatus}>
            {friendship.auto_accept ? "Turn off Auto-Accept" : "Turn on Auto-Accept"}
          </button>
          <button
            style={{ marginTop: '10px' }}
            className="standard-btn--rounded"
            data-id={friendship.id}
            onClick={this.destroyFriendship}>
            Destroy Friendship
          </button>
        </div>
      )
    } else if (friendship.account_id == this.props.account.id) {
      return (
        <div className="friendship-options">
          <div className="auto-accept-label">{
            friendship.auto_accept ? "Auto-Accept Is On" : "Auto-Accept Is Off"
          }</div>
          <button
            style={{ marginTop: '10px' }}
            className={"standard-btn--rounded" + (friendship.auto_accept ? " auto-accept-on" : " auto-accept-off")}
            data-id={friendship.id}
            onClick={this.setAutoAcceptStatus}>
            {friendship.auto_accept ? "Turn off Auto-Accept" : "Turn on Auto-Accept"}
          </button>
          <button
            style={{ marginTop: '10px' }}
            className="standard-btn--rounded"
            data-id={friendship.id}
            onClick={this.acceptFriendRequest}>
            Accept Invite On Behalf Of {friendship.friend.first_name}
          </button>
        </div>
      )
    } else {
      return (
        <div className="friendship-options">
          <div className="auto-accept-label">{
            friendship.auto_accept ? "Auto-Accept Is On" : "Auto-Accept Is Off"
          }</div>
          <button
            style={{ marginTop: '10px' }}
            className={"standard-btn--rounded" + (friendship.auto_accept ? " auto-accept-on" : " auto-accept-off")}
            data-id={friendship.id}
            onClick={this.setAutoAcceptStatus}>
            {friendship.auto_accept ? "Turn off Auto-Accept" : "Turn on Auto-Accept"}
          </button>
          <button
            style={{ marginTop: '10px' }}
            className="standard-btn--rounded"
            data-id={friendship.id}
            onClick={this.destroyFriendship}>
            Deny Friend Request
          </button>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="friendship-edit-react">

        <div className='builder builder--experience builder__multi-col react-body-container'>

          <div className='multi-col__size-9'>
            <h2>{this.props.account.first_name + " " + this.props.account.last_name + "'s Friendships"}</h2>
            <div className="comp-grid">
              <h3>Friends</h3>
              <div style={{ minWidth: "500px" }}>
                {this.state.friendships.filter(f => f.friendship_id).map((friendship, index) =>
                  <div key={"f" + index} className="friendship-row">{this.render_friendship_row(friendship)}</div>
                )}
              </div>
              <div className="create-new-friend">
                <div className="builder__input-block--inline">
                  <div style={{ maxWidth: '120px', padding: '5px 20px 5px', textAlign: 'center' }}>Account id</div>
                  <input
                    name='new_friend_account_id'
                    className='builder__textarea'
                    type="text"
                    placeholder="Add an account id here"
                    value={this.state.new_friend_account_id}
                    onChange={this.changeInputField} />
                </div>
                <button
                  style={{ marginTop: '10px' }}
                  className={"standard-btn--rounded"}
                  onClick={this.createTotalFriendship}>
                  Create complete friendship with account:
                </button>
              </div>
            </div>
            <div className="comp-grid">
              <h3>Friend Requests</h3>
              <div style={{ minWidth: "500px" }}>
                {this.state.friendships.filter(f => !f.friendship_id && f.friend_id == this.props.account.id).map((friendship, index) =>
                  <div key={"f" + index} className="friendship-row">{this.render_friendship_row(friendship)}</div>
                )}
              </div>
            </div>
            <div className="comp-grid">
              <h3>Sent Invites</h3>
              <div style={{ minWidth: "500px" }}>
                {this.state.friendships.filter(f => (!f.friendship_id && f.account_id == this.props.account.id)).map((friendship, index) =>
                  <div key={"f" + index} className="friendship-row">{this.render_friendship_row(friendship)}</div>
                )}
              </div>
              <div className="send-new-invite">
                <div className="builder__input-block--inline">
                  <div style={{ maxWidth: '120px', padding: '5px 20px 5px', textAlign: 'center' }}>Account id</div>
                  <input
                    name='invite_account_id'
                    className='builder__textarea'
                    type="text"
                    placeholder="Add an account id here"
                    value={this.state.invite_account_id}
                    onChange={this.changeInputField} />
                </div>
                <button
                  style={{ marginTop: '10px' }}
                  className={"standard-btn--rounded"}
                  onClick={this.createInvite}>
                  Send Invite to account:
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FriendshipEdit;