import React from "react";
import AssignmentSlotDetails from "./assignment_slot_details"
import ProfileIcon from 'profile-icon.svg'
import OfflineIcon from 'home/offline-logo.png'

import ShareEmail from 'home/share-email.svg'
import ShareSms from 'home/share-sms.svg'
import ShareCopy from 'home/share-copy.svg'


class MemberProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resent: false
    }
  }

  referralEmailMessage() {
    const subject = encodeURIComponent('Thought you might want to try Offline (for free)');
    const body = encodeURIComponent('I’m sure you’ve already heard me blather on about Offline but it really is an awesome way to try new things in the city each month. If you want to give it a shot for free, use my link to skip the waitlist get your first month on the house. ');
    const link = encodeURIComponent(this.props.referralLink);
    return "mailto:?subject="+subject+"&body="+body+link;
  }

  referralSMSMessage() {
    const body = encodeURIComponent("👋 FWDing you a referral into Offline (that subscription service that helps you try something new in the city each month) \n\nClick here if you want to skip the waitlist give it a shot for free: ");
    const link = encodeURIComponent(this.props.referralLink);
    return 'sms:?&body='+body+link;
  }

  isAccountCanceled() {
    return (this.props.status == 'canceled')
  }

  render() {
    var assignment_slots = []
    for (const assignment_slot of this.props.assignment_slots) {
      assignment_slots.push(
        <AssignmentSlotDetails 
          key={assignment_slot.id}
          name={assignment_slot.name}
          link_with_friends={assignment_slot.link_with_friends}
          id={assignment_slot.id}
          account_id={this.props.id}
        />
      )
    }

    return (
      <div>
        <div style={{display: 'flex', margin: "13px 13px 0"}}>
          <div className='text-left' style={{width: '100%'}}>
            <img style={{maxWidth: '73px'}} src={OfflineIcon} />
          </div>
          <div className='text-right'>
            <a href={this.props.editAccountPath}><img src={ProfileIcon} /></a>
          </div>
        </div>
        <h2 className='offline-account bottom-margin-zero' data-account-sync={this.props.id}>{this.props.first_name} {this.props.last_name}</h2>
        <p className='top-margin-zero'>{this.props.lottery_entries} Lotto { this.props.lottery_entries == 1 ? "Entry" : "Entries"}</p>
        <div className="offlineid">Offline ID: 
          <span> {this.props.premium_id}</span>
        </div>
        { this.isAccountCanceled() && 
          <>
            <h5>Your account has been canceled</h5>
            <p><small><i>Please write into support if you want to reactivate.</i></small></p>
          </>
        }
        { !this.isAccountCanceled() && 
          <div className='member__app-full-body corner-curve'>
            <div>
              <h3>Your offers</h3>
              {assignment_slots}
            </div>

            <div style={{padding: '30px 0 50px'}} id="referral">
              <h3 className='bottom-margin-zero'>Your Referral Code</h3>
              <p>Give a month. Get a month.</p>
              <div style={{display: 'inline-flex'}}>
                <div className='referral__option'>
                  <a data-btn-name='Email' href={this.referralEmailMessage()}>
                    <img className='referral__share-icon' alt='Share via email' src={ShareEmail} />
                    <div className='subheader'>EMAIL</div>
                  </a>
                </div>
                <div className='referral__option'>
                  <span className='copy-select member__app-toggle'>
                    <img className='referral__share-icon' alt='Share via link' src={ShareCopy} />
                    <div className='subheader copy-indicator'>COPY</div>
                  </span>
                </div>
                <div className='referral__option mobile__only'>
                  <a data-btn-name='SMS' href={this.referralSMSMessage()}>
                    <img className='referral__share-icon' alt='Share via text' src={ShareSms} />
                    <div className='subheader'>TEXT</div>
                  </a>
                </div>
              </div>
            </div>

          </div>
        }
      </div>
    );
  }
}

export default MemberProfile