import React from "react";

import CityLogo from 'logo-city.png'
import InfoIcon from 'icon-info.svg'
import Checkmark from 'large-check.svg'

class CodeOfConduct extends React.Component {
  constructor(props) {
    super(props);
    this.acceptCodeOfConduct = this.acceptCodeOfConduct.bind(this);
  }

  acceptCodeOfConduct(event) {
    let self = this;
    $.ajax({
      method: "POST",
      url: "/login/"+this.props.account_id+"/accept_code_of_conduct",
      dataType: 'json',
      success: function(data) {
        self.props.proceedWithSetup(self.props.setupStep + 1); 
      },
      error: function(error) {
        // TODO Something here
      }
    });
  }

  render() {
    return (
      <div>
        <div className="member__app-section-padded">
          <div className="text-center">
            <img style={{maxWidth: '75px', margin: '10px'}} src={CityLogo} />
            <h2 className='bottom-margin-zero top-margin-zero'>Code of Conduct</h2>
            <p className='bottom-margin-zero'>We require all members to opt-in to our community contract to keeps the love f
              lowing during thousands of monthly interactions between members, partner businesses + our team.
            </p>
            <p>
              <a href="https://support.letsgetoffline.com/article/82-what-s-the-code-of-conduct" 
                  target="_blank">
                <img style={{verticalAlign: 'middle', marginRight: '5px'}} src={InfoIcon} />
                  <span className="blue-underline">more info</span>
              </a>
            </p>
          </div>
        </div>

        <div className='member__app-section-padded-large'>  
          <br/>
          <h4 style={{fontSize: '0.875em'}} className='text-center'>As an offliner you agree to:</h4>
          <br/>

          <div className='one-line' style={{marginTop: '20px'}}>
            <div className='text-right'><img src={Checkmark} /></div>
            <div className='text-left'>
              <h4>BE RESPECTFUL</h4>
              <p className='top-margin-zero'>To other members, business, staff + us.</p>
            </div>
          </div>
          <div className='one-line' style={{marginTop: '20px'}}>
            <div className='text-right'><img src={Checkmark} /></div>
            <div className='text-left'>
              <h4>SUPPORT LOCAL BUSINESSES</h4>
              <p className='top-margin-zero'>As much as possible, we expect you to spend money with our partners in addition to your Offer amount. </p>
            </div>
          </div>
          <div className='one-line' style={{marginTop: '20px'}}>
            <div className='text-right'><img src={Checkmark} /></div>
            <div className='text-left'>
              <h4>CARE FOR STAFF</h4>
              <p className='top-margin-zero'>Tipping is the lifeblood of the service industry so when you receive good service, we expect that you’ll tip appropriately.</p>
            </div>
          </div>
          <div className='one-line' style={{marginTop: '20px'}}>
            <div className='text-right'><img src={Checkmark} /></div>
            <div className='text-left'>
              <h4>GIVE + TAKE FEEDBACK</h4>
              <p className='top-margin-zero'>Mutual feedback helps us to improve, address any issues + keep the service working for everyone.</p>
            </div>
          </div>
          <br/>
        </div>
        <div className='text-center'>
          <button 
            className='member__app-btn btn-primary'
            onClick={this.acceptCodeOfConduct}>
            I Agree
          </button>
          <p style={{fontSize: '.875em'}} className='subscript'>— a full copy of this contract will be sent to your inbox —</p>
        </div>
      </div>
    );
  }
}

export default CodeOfConduct