import React from "react";
import DatePicker from 'react-date-picker';
import FormTimeMultiselect from "../shared/form_time_multiselect";
import { convertTimeStringToMinutes, convertMinutesToTimeString } from '../../helpers/datestring_formatter';

class EventDateSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      event_dates: props.event_dates,
      copied_times: null
    }

    this.copyTimes = this.copyTimes.bind(this);
    this.pasteTimes = this.pasteTimes.bind(this);
    this.addNewEventDate = this.addNewEventDate.bind(this);
    this.removeEventDate = this.removeEventDate.bind(this);
    this.clearEventDates = this.clearEventDates.bind(this);
    this.addNewEventTime = this.addNewEventTime.bind(this);
    this.removeEventTime = this.removeEventTime.bind(this);
    this.updateEventDateValue = this.updateEventDateValue.bind(this);
    this.updateEventTimeValue = this.updateEventTimeValue.bind(this);
    this.updateEventDateTimes = this.updateEventDateTimes.bind(this);
  }

  copyTimes(event) {
    let index = event.target.dataset.index;
    let times = this.state.event_dates[index].event_times.map(event_time => event_time.minutes)
    this.setState({ copied_times: times })
  }

  pasteTimes(event) {
    if (!this.state.copied_times) return;

    let index = event.target.dataset.index;
    this.updateEventDateTimes(this.state.copied_times, index, true)
  }

  addNewEventDate() {
    var new_event_dates = [...this.state.event_dates];
    new_event_dates.push({ id: null, date: null, event_times: [] });
    this.setState({ event_dates: new_event_dates });
    this.props.onUpdate(new_event_dates)
  }

  addNewEventTime(event_date_index) {
    let new_event_dates = this.deepCopyEventDates(this.state.event_dates)
    let date_updated = new_event_dates[event_date_index];
    date_updated.event_times.push({ event_date_id: date_updated.id, event_id: this.props.event_id, id: null, max_tickets: 0});
    this.setState({ event_dates: new_event_dates })
    this.props.onUpdate(new_event_dates)
  }

  removeEventDate(event) {
    var target = event.target;
    var event_date_index = parseInt(target.dataset["event_date_index"]);
    var copy_event_dates = [...this.state.event_dates];
    var new_event_dates = [];
    copy_event_dates.forEach((event_date, i) => {
      if (i != event_date_index) {
        return new_event_dates.push(event_date)
      }

      if (event_date.id) {
        event_date["_destroy"] = true;
        return new_event_dates.push(event_date)
      }
    })

    this.setState({ event_dates: new_event_dates });
    this.props.onUpdate(new_event_dates)
  }

  removeEventTime(event_date_index, index) {
    let new_event_dates = this.deepCopyEventDates(this.state.event_dates)
    let date_updated = new_event_dates[event_date_index];
    if (date_updated.event_times[index].id) {
      date_updated.event_times[index]["_destroy"] = true;
    } else {
      date_updated.event_times.splice(index, 1);
    }
    this.setState({ event_dates: new_event_dates })
    this.props.onUpdate(new_event_dates)
  }

  deepCopyEventDates(event_dates) {
    return event_dates.map(event_date => {
      return {
        id: event_date.id,
        date: event_date.date,
        event_times: event_date.event_times.map(event_time => {
          return {
            id: event_time.id,
            event_date_id: event_time.event_date_id,
            event_id: event_time.event_id,
            minutes: event_time.minutes,
            minutes_end: event_time.minutes_end,
            max_tickets: event_time.max_tickets
          }
        })
      }
    })
  }

  clearEventDates() {
    var copy_event_dates = [...this.state.event_dates];
    var new_event_dates = [];
    copy_event_dates.forEach((event_date, i) => {
      if (!i) return new_event_dates.push(event_date)
      if (event_date.id) {
        event_date["_destroy"] = true;
        return new_event_dates.push(event_date)
      }
    })
    this.setState({ event_dates: new_event_dates });
    this.props.onUpdate(new_event_dates)
  }

  updateEventDateValue(date, index) {
    var new_event_dates = [...this.state.event_dates];
    new_event_dates[index].date = date;
    this.setState({ event_dates: new_event_dates });
    this.props.onUpdate(new_event_dates)
  }

  updateEventTimeValue(value, key, event_date_index, index) {
    let new_event_dates = this.deepCopyEventDates(this.state.event_dates)
    let date_updated = new_event_dates[event_date_index];
    date_updated.event_times[index][key] = value;
    this.setState({ event_dates: new_event_dates })
    this.props.onUpdate(new_event_dates)
  }

  updateEventDateTimes(times, index, paste = false) {
    let new_event_dates = [...this.state.event_dates]

    let new_event_times = [];
    new_event_dates[index].event_times.forEach(event_time => {
      if (!times.includes(event_time.minutes)) {
        if (paste) {
          delete event_time["_destroy"];
          return new_event_times.push(event_time);
        }
        if (event_time.id) {
          event_time["_destroy"] = true;
          return new_event_times.push(event_time);
        }
      } else {
        delete event_time["_destroy"];
        times = times.filter(time => time != event_time.minutes);
        return new_event_times.push(event_time);
      }
    })

    times.forEach(time => {
      new_event_times.push({
        event_id: this.props.event_id,
        minutes: time
      })
    })

    new_event_dates[index].event_times = new_event_times
    this.setState({ event_dates: new_event_dates })
    this.props.onUpdate(new_event_dates)
  }

  renderEventDates() {
    return (
      this.state.event_dates.map((event_date, i) =>
        <div
          style={{ display: event_date["_destroy"] ? "none" : "" }}
          key={event_date.id}
          className="select-date" >
          <DatePicker
            name={"event_date-" + event_date.id}
            value={event_date.date}
            onChange={value => { this.updateEventDateValue(value, i) }} />
          <span
            style={{fontSize: '24px', marginLeft: '10px', marginTop: '4px'}}
            onClick={this.removeEventDate}
            data-event_date_index={i}>
            🅧
          </span>
          {/* <FormTimeMultiselect
                times={event_date.event_times.map(time => {
                    if (!time["_destroy"]) {
                        return time.minutes
                    }
                })}
                index={i}
                onChange={this.updateEventDateTimes}
            /> */}
          {this.renderEventTimes(event_date, i)}
          {/* <button
            className="copy-times"
            onClick={this.copyTimes}
            data-index={i} >
            copy times
          </button>
          <button
            className={"paste-times" + (this.state.copied_times ? "" : " no-available")}
            data-index={i}
            onClick={this.pasteTimes} >
            paste times
          </button>
          <button
            className="remove-date"
            onClick={this.removeEventDate}
            data-event_date_index={i}>
            remove
          </button> */}
          <br />
        </div>
      )
    );
  }

  renderEventTimes(event_date, event_date_index) {
    return (
      <div className='select-times'>
        <h4>Time Slots & Tickets</h4>
        { event_date.event_times.map((event_time, i) =>
          <div className='small-spaced' key={i} style={{ display: event_time["_destroy"] ? "none" : "" }}>
            <input
              index={i}
              type="time"
              value={convertMinutesToTimeString(event_time.minutes)}
              onChange={e => { this.updateEventTimeValue(convertTimeStringToMinutes(e.target.value), "minutes", event_date_index, i) }} />
            <span style={{marginRight: '10px'}}>to</span>
            <input
              index={i}
              type="time"
              value={convertMinutesToTimeString(event_time.minutes_end)}
              onChange={e => { this.updateEventTimeValue(convertTimeStringToMinutes(e.target.value), "minutes_end", event_date_index, i) }} />
            <input
              index={i}
              value={event_time.max_tickets}
              onChange={e => { this.updateEventTimeValue(e.target.value, "max_tickets", event_date_index, i) }} />
            <span
              style={{fontSize: '24px', marginLeft: '10px', marginTop: '4px'}}
              onClick={e => {this.removeEventTime(event_date_index, i)}}>
              🅧
            </span>
          </div>
        )}
        <div>
        <div className='small-spaced'>
          <button
            className={'member__app-btn btn-small'}
            onClick={e => {this.addNewEventTime(event_date_index)}}>
            + Add a Time Slot
          </button>
        </div>

        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="event-date-selector">
        {this.renderEventDates()}
        <div>
          <button
            className={'member__app-btn btn-small'}
            onClick={this.addNewEventDate}>
            + Add a Day
          </button>
          {this.state.event_dates.length > 0 &&
            <button
              className={'member__app-btn btn-small'}
              onClick={this.clearEventDates}>
              Clear
            </button>
          }
        </div>
      </div>
    )
  }
}

export default EventDateSelector;