import React from "react";


class LocationEdit extends React.Component {
  constructor(props) {
    super(props);
    var location_values = {}
    var pref_data = this.props.assignment_preference.preference_data;
    if (pref_data ==  null) {
      pref_data = this.props.assignment_preference.full_preference_data;
    }
    this.areas = {};
    for (const location of this.props.locations) { 
      location_values[location.id] = pref_data.locations.includes(location.id)
      this.areas[location.primary_area.id] ||= {id: location.primary_area.id, name: location.primary_area.name, locations: []}
      this.areas[location.primary_area.id]["locations"].push(location)
    }

    const first_area_id = this.props.area_id;
    this.ordered_area_ids = Object.keys(this.areas).sort(function(a,b) {return (parseInt(b) == first_area_id ? 1 : -1) - (parseInt(a) == first_area_id ? 1 : -1)});

    this.state = {
      location_values: location_values,
      error_message: ""
    }

    this.getSelectedLocationIds = this.getSelectedLocationIds.bind(this);
    this.changeLocationToggle = this.changeLocationToggle.bind(this);
  }

  getSelectedLocationIds(selected_hash) {
    var location_ids = [];
    Object.keys(selected_hash).forEach(function(id) {
      if (selected_hash[id]) {
        location_ids.push(parseInt(id));
      }
    });
    return location_ids;
  }

  changeLocationToggle(event) {
    var location_id = $(event.target).data('id');
    var location_values_copy = Object.assign({},this.state.location_values);
    location_values_copy[location_id] = !location_values_copy[location_id];

    if (!Object.values(location_values_copy).includes(true)) {
      return this.setState({error_message: "you must have at least one location selected"})
    }

    this.setState({
      location_values: location_values_copy,
      error_message: ''
    });

    this.props.updateToLocations(this.getSelectedLocationIds(location_values_copy));

    $.ajax({
      method: "POST",
      url: "/home/update_assignment_preferences/" + this.props.assignment_preference.id,
      data: {assignment_preferences: {location_ids: this.getSelectedLocationIds(location_values_copy)}},
      dataType: 'json'
    });
  }
  
  renderAreas() {
    return (this.ordered_area_ids.map(area_id => (
        <div key={area_id}>
          <h4 style={{padding: '10px 0 5px'}}>{this.areas[area_id].name}</h4>
          <div>
            {this.renderLocations(this.areas[area_id].locations)}
          </div>
        </div>
      )));

  }

  renderLocations(locations) {
    return (locations.map(location =>(
        <button
          className={this.state.location_values[location.id] == true ? "member__app-pill member__app-toggle" : "member__app-pill member__app-toggle pill-grey"}
          key={location.id}
          data-id={location.id}
          onClick={this.changeLocationToggle} >
            {location.name}
        </button>
      )));
  }


  render() {
    return (
      <div className="member__app-section member__app-section-body">
        <h3 className='flat'>Where to explore?</h3>
        <p>Adding more areas gives us a larger selection of Offers to choose from for you.</p>
        <div className='text-left'>
          { this.state.error_message != "" &&
            <p className="subscript error-text text-center">{this.state.error_message}</p>
          }
          {this.renderAreas()}
        </div>
      </div>
    );
  }
}

export default LocationEdit