import React from "react";
import SignupOfferConfirmCard from "../../gui_components/cyoo/signup_offer_confirm_card";
import LabeledDropdown from "../../gui_components/labeled_dropdown";
import { getIDforGTM } from "../gtm_methods";

import moment from 'moment-timezone';

class ConfirmSignupOffers extends React.Component {
  constructor(props) {
    super(props);

    this.editStep = this.editStep.bind(this);
    this.endOfThisMonthString = moment().endOf('month').format('MMMM Do');
    this.beginningOfNextMonthString = moment().add(1, 'month').startOf('month').format('MMMM Do');
    this.endOfNextMonthString = moment().add(1, 'month').endOf('month').format('MMMM Do');
  }

  componentDidMount() {
    document.scrollingElement.scrollTop = 0;
    if (analytics_loaded) {
      mixpanel.track("Signup: CYOO Confirm");
      posthog.capture("Signup: CYOO Confirm");
    }
  }

  editStep(num) {
    this.props.editStep(num);
  }

  renderWhenToStartSelection() {
    if (this.props.deferral_only) {
      return (
        <LabeledDropdown
          label={`When do you want to try ${this.props.selected_offer_1.name} and ${this.props.selected_offer_2.name}?`}
          options={[
            { value: 'defer', label: `Next month (you'll have ${this.beginningOfNextMonthString} - ${this.endOfNextMonthString} to go)` },
          ]}
          update_input_value={this.props.update_input_value}
          selected_value={this.props.selected_start}
          show_error={this.props.show_error}
          error_message={"Choose your start option."}
          id={getIDforGTM('choose-start-date', this.props)}
        />
      )
    }
    return (
      <LabeledDropdown
        label={`When do you want to try ${this.props.selected_offer_1.name} and ${this.props.selected_offer_2.name}?`}
        options={[
          { value: 'today', label: `This month (you'll have until ${this.endOfThisMonthString} to go)` },
          { value: 'defer', label: `Next month (you'll have ${this.beginningOfNextMonthString} - ${this.endOfNextMonthString} to go)` },
        ]}
        update_input_value={this.props.update_input_value}
        selected_value={this.props.selected_start}
        show_errors={this.props.show_errors}
        error_message={"Choose your start option."}
        id={getIDforGTM('choose-start-date', this.props)}
      />
    )
  }

  render() {
    return (
      <div className="signup-confirm-offers" style={{ "--footer-offset": "138px" }}>
        {this.renderWhenToStartSelection()}
        <SignupOfferConfirmCard
          offer={this.props.selected_offer_1}
          edit_offer={() => { this.editStep(0) }}
        />
        <SignupOfferConfirmCard
          offer={this.props.selected_offer_2}
          edit_offer={() => { this.editStep(1) }}
        />
      </div>
    );
  }
}

export default ConfirmSignupOffers