import React from "react";
import moment from "moment-timezone";

class AccountPerks extends React.Component {
  constructor(props) {
    super(props);

    this.network_busy = false;

    this.assign_perk = this.assign_perk.bind(this);
  }

  assign_perk(e) {
    let target = e.target;
    let perk_id = target.getAttribute("data-id");

    if (this.network_busy) {
      return;
    }

    let path = "/accounts/" + this.props.account.id + "/assign_perk_to_account?as_json=true&account_id=" + this.props.account.id + "&perk_id=" + perk_id;
    this.network_busy = true;
    $.ajax({
      method: "POST",
      url: path,
      success: (data) => {
        this.network_busy = false;
        if (typeof data === "object") {
          showFlashMessage("Perk assigned successfully");
          this.props.load_perk_assignments([...this.props.perk_assignments.filter((perk_assignment) => perk_assignment.id !== data.id), data]);
        } else {
          showFlashErrorMessages(data.split("\n")[0]);
        }
      },
      error: (data) => {
        this.network_busy = false;
        showFlashMessage("Perk assignment failed");
      }
    });
  }

  render() {
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    return (
      <>
        <h2 onClick={() => {
          let collapse_action = this.props.collapse_action;
          collapse_action.action(collapse_action.args);
        }}>
          Perks:{" "}
          <span
            className={caret_style}
            data-section-id="perk-section"
          />
        </h2>
        <div id="perk-section" style={collapsed_style}>
          <br />
          <h3>User Perk Categories</h3>
          <div className="dynamic-grid-list">
            {this.props.perk_categories.map((perk_category, i) => {
              return (
                <span key={"pc" + i}>
                  <a target="_blank" href={"/rails_admin/perk_category/" + perk_category.id}>
                    {perk_category.name}
                  </a>
                </span>
              )
            })}
          </div>
          <br />
          <h3>Active Perk Assignments</h3>
          <table className="admin__table">
            <thead>
              <tr>
                <th className="table-left">Name</th>
                <th className="table-left">Status</th>
                <th className="table-left">Date Redeemed</th>
                <th className="table-left">Start Date</th>
                <th className="table-left">End Date</th>
              </tr>
            </thead>
            <tbody id="perk_assignments">
              {this.props.perk_assignments.map((perk_assignment) => {
                return (
                  <tr key={"pa" + perk_assignment.id}>
                    <td>
                      <a target="_blank" href={"/rails_admin/perk/" + perk_assignment.perk_id}>
                        {perk_assignment.perk_name}
                      </a>
                    </td>
                    <td>{perk_assignment.status}</td>
                    <td>{perk_assignment.date_redeemed ? moment(perk_assignment.date_redeemed).format("DD MMM, YYYY") : ""}</td>
                    <td>{perk_assignment.start_date ? moment(perk_assignment.start_date).format("DD MMM, YYYY") : ""}</td>
                    <td>{perk_assignment.end_date ? moment(perk_assignment.end_date).format("DD MMM, YYYY") : ""}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <br />
          <h3>Active Assignable Perks</h3>
          <table className="admin__table">
            <thead>
              <tr>
                <th className="table-left">Name</th>
                <th className="table-left">Start Date</th>
                <th className="table-left">End Date</th>
                <th className="table-left">Assign</th>
              </tr>
            </thead>
            <tbody id="assignable-perks">
              {this.props.active_perks.map((perk) => {
                return (
                  <tr key={"ap" + perk.id}>
                    <td>
                      <a target="_blank" href={"/rails_admin/perk/" + perk.id}>
                        {perk.name}
                      </a>
                    </td>
                    <td>{perk.start_date ? moment(perk.start_date).format("DD MMM, YYYY") : ""}</td>
                    <td>{perk.end_date ? moment(perk.end_date).format("DD MMM, YYYY") : ""}</td>
                    <td>
                      <input type="button" value="Assign" data-id={perk.id} onClick={this.assign_perk}/>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default AccountPerks;