import React from "react";
import FormBuilder from "../shared/form_builder";
import DatePicker from 'react-date-picker';
import FormSelect from "../shared/form_select";
import { convertDateString } from '../../helpers/datestring_formatter';
import { renderDate } from '../../helpers/datestring_formatter';


class PerkIndexItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start_date: convertDateString(props.start_date),
      end_date: convertDateString(props.end_date),
      capacity: props.capacity,
      perk_category_ids: props.perk_category_ids,
      is_editing: false
    }

    this.startEditing = this.startEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.saveChanges = this.saveChanges.bind(this);

    this.updateStateItem = this.updateStateItem.bind(this);
    this.changeInputField = this.changeInputField.bind(this);
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;
    if (target.type == "checkbox") {
      return this.setState({ [name]: target.checked });
    }

    this.setState({ [name]: target.value });
  }

  updateStateItem(name, value) {
    this.setState({ [name]: value });
  }

  saveChanges() {
    alert("'saved'");
  }

  startEditing() {
    this.setState({is_editing: true});
  }

  cancelEditing() {
    this.setState({is_editing: false});
  }

  saveChanges() {
    var form_builder = new FormBuilder();
    var form_object = {
      perk: {
        perk_category_ids: this.state.perk_category_ids,
        capacity: this.state.capacity
      }
    }
    var form_data = form_builder.createForm(form_object);

    // Adding these outside FormBuilder because we want to keep these as datetimes with timezone intact.
    form_data.append('perk[start_date]', this.state.start_date || "");
    form_data.append('perk[end_date]', this.state.end_date || "");

    $.ajax({
      method: "PUT",
      url: "/perks/" + this.props.id,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        this.props.onUpdate(data);
        this.setState({is_editing: false});
        showFlashMessage("Perk Saved");
      },
      error: (error) => {
        alert("Failed to save");
      }
    });
  }


  render() {
    if (this.state.is_editing) {
      return (
        <tr>
          <td style={{width: '10%'}}>
            {this.props.name || "[Untitled]"}
            </td>
          <td style={{width: '15%', padding: '5px'}}>
            <DatePicker
              value={this.state.start_date}
              onChange={value => { this.updateStateItem("start_date", value) }} />
          </td>
          <td style={{width: '15%', padding: '5px'}}>
            <DatePicker
              value={this.state.end_date}
              onChange={value => { this.updateStateItem("end_date", value) }} />
          </td>
          <td style={{width: '10%'}}>
            {this.props.location_ids.length} Locations
          </td>
          <td style={{width: '5%', padding: '5px'}}>
            <input
              style={{width: '100%'}}
              name="capacity"
              type="text"
              value={this.state.capacity}
              onChange={this.changeInputField} />
          </td>
          <td style={{width: '5%'}}>
            {this.props.active_assigned}
          </td>
          <td style={{width: '5%'}}>
            {this.props.redeemed_this_month}
          </td>
          <td style={{width: '20%', padding: '5px'}}>
            <FormSelect
                options={this.props.perk_categories}
                name="perk_category_ids"
                className='large-input'
                multiselect={true}
                value={this.state.perk_category_ids}
                onChange={this.updateStateItem} />
          </td>
          <td style={{width: '10%'}}>
            <button onClick={this.saveChanges}>done</button>
            <button onClick={this.cancelEditing}>cancel</button>
          </td>
        </tr>
      )
    } else {
      return (
        <tr>
          <td style={{width: '10%'}}><a href={"/perks/"+this.props.id+"/edit"}>{this.props.name || "[Untitled]"}</a></td>
          <td style={{width: '15%'}}>{renderDate(convertDateString(this.props.start_date))}</td>
          <td style={{width: '15%'}}>{renderDate(convertDateString(this.props.end_date))}</td>
          <td style={{width: '10%'}}>{this.props.location_ids.length} Locations</td>
          <td style={{width: '5%'}}>{this.props.capacity}</td>
          <td style={{width: '5%'}}>{this.props.active_assigned}</td>
          <td style={{width: '5%'}}>{this.props.redeemed_this_month}</td>
          <td style={{width: '20%'}}>{this.props.perk_category_ids.length}</td>
          <td style={{width: '10%'}}><button onClick={this.startEditing}>edit</button></td>
        </tr>
      )
    }
  }
}

export default PerkIndexItem;