import React from "react";
import moment from 'moment-timezone';
import UpdateTable from "../shared/update-table";
import EventNavLinks from "./event_nav_links";
import ToggleSwitch from "../shared/toggle_switch";

class EventMessaging extends React.Component {
  constructor(props) {
    super(props);

    this.event_date_map = {};
    this.props.event_dates.forEach(eventDate => {
      this.event_date_map[eventDate.date] = eventDate;
    })

    this.event_date_map_by_id = {};
    this.props.event_dates.forEach(eventDate => {
      this.event_date_map_by_id[eventDate.id] = eventDate.date;
    })

    this.chart_entry_changes = JSON.parse(JSON.stringify(this.props.event_entries));
    let render_entries = JSON.parse(JSON.stringify(this.props.event_entries));
    render_entries.forEach(x => x.changes = null);

    this.chart_assignment_changes = JSON.parse(JSON.stringify(this.props.event_assignments));
    let render_assignments = JSON.parse(JSON.stringify(this.props.event_assignments));
    render_assignments.forEach(x => x.changes = null);

    this.state = {
      name: this.props.name,
      render_assignments,
      render_entries,
      updating_notification_table: this.props.job_id ? true : false,
      disable_ui: this.props.job_id ? true : false,
      disable_message: this.props.job_id ? "There is a background service running to modify this event.  If the process seems to be taking too long you can refresh to page to check if the job has concluded." : false,
      enable_day_of_notification: this.props.enable_day_of_notification,
      collapse_upcoming: false,
      collapse_sent: false,
      collapse_event_stats: true,
      sent_event_invite_message: this.props.sent_event_invite_message,
      sent_event_invite_date: this.props.sent_event_invite_date,
      event_stats: this.props.event_stats,
      loaded_event_invite_email_subject: this.props.event_invite_email_subject,
      event_invite_email_subject: this.props.event_invite_email_subject
    }

    this.getAssignmentsWithoutNotifications = this.getAssignmentsWithoutNotifications.bind(this);
    this.getEntriesWithoutNotifications = this.getEntriesWithoutNotifications.bind(this);
    this.setAssignmentDataFromResponse = this.setAssignmentDataFromResponse.bind(this);
    this.checkJobStatus = this.checkJobStatus.bind(this);
    this.sendEventInvites = this.sendEventInvites.bind(this);
    this.sendTestEventInvites = this.sendTestEventInvites.bind(this);
    this.saveEventInviteSubject = this.saveEventInviteSubject.bind(this);
    this.updateNotificationSettings = this.updateNotificationSettings.bind(this);
    this.resetEventDate = this.resetEventDate.bind(this);
    this.toggleShowSentNotifications = this.toggleShowSentNotifications.bind(this);
    this.toggleShowUpcomingNotifications = this.toggleShowUpcomingNotifications.bind(this);
    this.toggleShowEventStats = this.toggleShowEventStats.bind(this);
    this.updateEmailSubjectLine = this.updateEmailSubjectLine.bind(this);
    this.renderAssignmentTable = this.renderAssignmentTable.bind(this);
    this.renderNotificationOptions = this.renderNotificationOptions.bind(this);
    this.renderInviteOptions = this.renderInviteOptions.bind(this);
    this.renderUpcomingAssignmentTable = this.renderUpcomingAssignmentTable.bind(this);
  }

  componentDidMount() {
    if (this.props.job_id) {
      this.checkJobStatus();
    }
  }

  minutesToTimeString(minutes) {
    if (!minutes) return "";
    let date = moment().startOf('day');
    date.minutes(minutes);
    return date.format('h:mm A');
  }

  formatDateString(date) {
    if (!date) return "";
    return moment(date).format("MMM Do YYYY");
  }

  formatNotification(raw_notification_string) {
    if (!raw_notification_string) return "";

    let browser_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let notifications = raw_notification_string.split(',').filter(x => x);
    notifications = notifications.map(notification => {
      let pieces = notification.split(":")
      let time_moment = moment(pieces[1], "x").tz(browser_timezone);
      let abbr = " " + time_moment.zoneAbbr();

      return `${pieces[0]} (${time_moment.format("MMM Do YYYY, h:mm A") + abbr})`
    })

    return notifications.join('\n').trim();
  }

  getAssignmentsWithoutNotifications(assignments = this.props.event_assignments, notification = "event_ticket_assigned") {
    return assignments.filter(assignment => {
      return !assignment.sent_notifications || !assignment.sent_notifications.includes(notification);
    })
  }

  getEntriesWithoutNotifications(entries = this.props.event_entries, notification = "did_not_win_ticket") {
    return entries.filter(entry => {
      return !entry.sent_notifications || !entry.sent_notifications.includes(notification);
    })
  }

  setAssignmentDataFromResponse(data, force_enable_ui = false) {
    if (!data) return false;

    let state_update = {};

    state_update.updating_notification_table = data.job_id ? true : false;
    state_update.disable_ui = data.job_id ? true : false;
    state_update.disable_message = data.job_id ? "There is a background service running to modify this event.  If the process seems to be taking too long you can refresh to page to check if the job has concluded." : false;

    if (force_enable_ui) {
      state_update = {
        updating_notification_table: false,
        disable_ui: false,
        disable_message: null
      }
    }
    this.event_date_map = {};
    data.event_dates.forEach(eventDate => {
      this.event_date_map[eventDate.date] = eventDate;
    })

    this.event_date_map_by_id = {};
    data.event_dates.forEach(eventDate => {
      this.event_date_map_by_id[eventDate.id] = eventDate.date;
    })

    this.chart_assignment_changes = JSON.parse(JSON.stringify(data.event_assignments));
    let render_assignments = JSON.parse(JSON.stringify(data.event_assignments));
    render_assignments.forEach(x => x.changes = null);
    state_update.render_assignments = render_assignments;

    this.chart_entry_changes = JSON.parse(JSON.stringify(data.event_entries));
    let render_entries = JSON.parse(JSON.stringify(data.event_entries));
    render_entries.forEach(x => x.changes = null);
    state_update.render_entries = render_entries;

    state_update.name = data.name;
    state_update.enable_day_of_notification = data.enable_day_of_notification;
    state_update.sent_event_invite_message = data.sent_event_invite_message;
    state_update.sent_event_invite_date = data.sent_event_invite_date;
    state_update.event_stats = data.event_stats;
    state_update.loaded_event_invite_email_subject = data.event_invite_email_subject;
    state_update.event_invite_email_subject = data.event_invite_email_subject;

    this.setState(state_update);
  }

  saveEventInviteSubject() {
    this.setState({ updating_notification_table: true });
    var path = `/events/${this.props.id}/save_event_invite_email_subject?email_subject=${encodeURIComponent(this.state.event_invite_email_subject)}`;

    $.ajax({
      method: "POST",
      url: path,
      data: null,
      processData: false,
      contentType: false,
      success: (data) => {
        if (!data || data.job_id) {
          this.checkJobStatus();
        }
        showFlashMessage("Email Subject Updated");
        this.setAssignmentDataFromResponse(data);
      },
      error: (error) => {
        this.setState({
          error_message: error.responseJSON.message,
          error_key: error.responseJSON.key,
          updating_notification_table: false
        });
      }
    });
  }

  checkJobStatus(saving_table = null) {
    var path = "/events/" + this.props.id + '/check_job_status/';
    $.ajax({
      method: "GET",
      url: path,
      data: null,
      processData: false,
      contentType: false,
      success: (data) => {
        if (!data.job_complete) {
          setTimeout(() => {
            this.checkJobStatus(saving_table);
          }, 1500);
          return false;
        }
        this.setAssignmentDataFromResponse(data.event, true);
      },
      error: (error) => {
        this.setState({
          error_message: error.responseJSON.message,
          error_key: error.responseJSON.key,
          //updating_entry_table: false
        });
      }
    });
  }

  sendEventInvites() {
    if (!this.state.event_invite_email_subject) {
      alert('No email subject line is defined.')
      return false;
    }
    var member_path = "/events/" + this.props.id + '/how_many_users_will_receive_invites/';
    var path = "/events/" + this.props.id + '/send_event_invites/?email_subject=' + this.state.event_invite_email_subject;
    this.setState({ updating_notification_table: true });
    $.ajax({
      method: "GET",
      url: member_path,
      data: null,
      processData: false,
      contentType: false,
      success: (data) => {
        let message = ""
        if (data.number_of_members) {
          message = "This will send event invites out to " + data.number_of_members + " members.  This event can only be sent once."
        } else {
          alert("No members could be found from the Event's location ids.");
          this.setState({ updating_notification_table: false });
          return false;
        }

        if (!confirm(message)) {
          this.setState({ updating_notification_table: false });
          return false;
        }

        $.ajax({
          method: "POST",
          url: path,
          data: null,
          processData: false,
          contentType: false,
          success: (data) => {
            if (!data || data.job_id) {
              this.checkJobStatus();
            }

            this.setAssignmentDataFromResponse(data);
          },
          error: (error) => {
            this.setState({
              error_message: error.responseJSON.message,
              error_key: error.responseJSON.key,
              updating_notification_table: false
            });
          }
        });
      },
      error: (error) => {
        this.setState({
          error_message: error.responseJSON.message,
          error_key: error.responseJSON.key,
          updating_notification_table: false
        });
      }
    });
  }

  sendTestEventInvites() {
    if (!this.state.event_invite_email_subject) {
      alert('No email subject line is defined.')
      return false;
    }
    this.setState({ updating_notification_table: true });

    var path = "/events/" + this.props.id + '/send_event_invite_to_current_admin/?email_subject=' + this.state.event_invite_email_subject;

    $.ajax({
      method: "POST",
      url: path,
      data: null,
      processData: false,
      contentType: false,
      success: (data) => {
        if (!data || data.job_id) {
          this.checkJobStatus();
        }
        showFlashMessage("Test Email Sent");
        this.setAssignmentDataFromResponse(data);
      },
      error: (error) => {
        this.setState({
          error_message: error.responseJSON.message,
          error_key: error.responseJSON.key,
          updating_notification_table: false
        });
      }
    });
  }

  updateNotificationSettings() {
    let enable = !this.state.enable_day_of_notification;
    let confirmation_message = enable ?
      'Are you sure you want to enable "Your Ticket is Today" Notifications?'
      : 'Are you sure you want to disable "Your Ticket is Today" Notifications?';

    if (!window.confirm(confirmation_message + " This choice will be saved immediately.")) {
      return false;
    }


    this.setState({ enable_day_of_notification: enable })
    var path = `/events/${this.props.id}/toggle_day_of_notification?enable_day_of_notification=${String(enable)}`;

    $.ajax({
      method: "POST",
      url: path,
      data: null,
      processData: false,
      contentType: false,
      success: (data) => {
        if (!data || data.job_id) {
          this.checkJobStatus();
        }

        this.setAssignmentDataFromResponse(data);
      },
      error: (error) => {
        this.setState({
          error_message: error.responseJSON.message,
          error_key: error.responseJSON.key,
          updating_notification_table: false
        });
      }
    });
  }

  resetEventDate() {
    if (!window.confirm("It is highly irregular to reset an Event Invite email. By continuing, you are confirming that you would like to reset the Sent Event Invite Date.")) {
      return false;
    }
    let prompt_input = prompt('To finalize the reset request, please type in:  I am sure');
    if("I am sure" != prompt_input) {
      if(prompt_input != ""){
        showFlashErrorMessage(`The confirmation input "${prompt_input}" did not match "I am sure"`)
      }
      return false;
    }


    this.setState({ updating_notification_table: true })
    var path = `/events/${this.props.id}/reset_event_invite?confirmation=${encodeURIComponent(prompt_input)}`;

    $.ajax({
      method: "POST",
      url: path,
      data: null,
      processData: false,
      contentType: false,
      success: (data) => {
        if (!data || data.job_id) {
          this.checkJobStatus();
        }
        showFlashMessage("sent_event_invite_date reset successfully");
        this.setAssignmentDataFromResponse(data);
      },
      error: (error) => {
        this.setState({
          error_message: error.responseJSON.message,
          error_key: error.responseJSON.key,
          updating_notification_table: false
        });
      }
    });
  }

  toggleShowSentNotifications() {
    this.setState({ collapse_sent: !this.state.collapse_sent });
  }

  toggleShowUpcomingNotifications() {
    this.setState({ collapse_upcoming: !this.state.collapse_upcoming });
  }

  toggleShowEventStats() {
    this.setState({ collapse_event_stats: !this.state.collapse_event_stats });
  }

  updateEmailSubjectLine(event) {
    this.setState({ event_invite_email_subject: event.target.value });
  }

  renderAssignmentTable() {
    let table_row_templates = [
      {
        data_label: "premium_id",
        value_name: "premium_id"
      },
      {
        data_label: "event_date_id",
        value_name: "event_date_id",
        formatter: (v) => { return this.formatDateString(this.event_date_map_by_id[v]) }
      },
      {
        data_label: "timeslot",
        value_name: "timeslot"
      },
      {
        data_label: "tickets",
        value_name: "tickets"
      },
      {
        data_label: "status",
        value_name: "status",
        formatter: (v) => { return v || "pending finalization" }
      },
      {
        data_label: "sent_notifications",
        value_name: "sent_notifications",
        formatter: this.formatNotification
      },
    ]
    let sorted_assignments = [...this.state.render_assignments];
    sorted_assignments.sort(this.sortAccountIds);
    sorted_assignments.sort(this.sortChanges);
    return (
      <>
        <UpdateTable
          header_labels={[
            "OFFLINEID",
            "DATESLOT",
            "TIMESLOT",
            "TICKETS",
            "STATUS",
            "SENT NOTIFICATIONS",
          ]}
          row_templates={table_row_templates}
          rows={sorted_assignments}
          selectable={false}
          loading_data={this.state.updating_notification_table}
        />
      </>
    )
  }

  renderNotificationOptions() {
    return (
      <div className={"notifications-options" + (this.state.disable_ui ? " disable-input" : "")}>
        <div className="toggle-day-of-notificaitons">
          <div>Enable "Your Ticket is Today" Notifications:</div>
          <ToggleSwitch
            large={true}
            value={this.state.enable_day_of_notification}
            onChange={this.updateNotificationSettings}
          />
        </div>
      </div>
    )
  }

  renderAllEmailMetrics() {
    let test_emails = this.state.sent_event_invite_message.test_emails || [];
    let event_invite_email = this.state.sent_event_invite_message.event_invite_email
    return (
      <>
        <div className="email-metric-container">
        <button
            className={'reset-button-style'}
            style={{display: (this.state.sent_event_invite_date? "block" : "none")}}
            onClick={this.resetEventDate}
          >
            Clear Event Invite Email
          </button>
          <div className="email-metric-title">Sent Invite Metrics</div>
          {this.renderPreviousEmailData(event_invite_email, "sent_key")}
        </div>
        <div className="email-metric-container">
          {/* <div className="email-metric-title">Sent Test Invite Metrics</div>
          {test_emails.map((test_email, i) => this.renderPreviousEmailData(test_email, i))} */}
        </div>
      </>
    )
  }

  renderPreviousEmailData(email_hash, key) {
    if (!email_hash || email_hash.email_subject === null) return (<></>);

    let sent_at = null;
    if (email_hash.sent_datetime) {
      sent_at = moment(email_hash.sent_datetime, "x").format("MMM Do YYYY, h:mm A");
    } else if (email_hash.datetime_failed) {
      sent_at = moment(datetime_failed, "x").format("MMM Do YYYY, h:mm A");
    } else {
      sent_at = moment(this.state.sent_event_invite_date).format("MMM Do YYYY");
    }

    let email_failures = email_hash.email_failures;
    let email_failure_list = (<></>);

    if (email_failures.length > 0) {
      let entries = email_failures.map((mixpanel_id, i) =>
        <li key={key + "test" + i}>{mixpanel_id}</li>
      );
      email_failure_list = (<>
        <li>Failed to send invites to mixpanel_ids:</li>
        <ul>{entries}</ul>
      </>)
    }

    return (
      <div key={key}>
        <div className="email-metric-subject">{"Email Subject: " + email_hash.email_subject}</div>
        <ul>
          <li>{"Sent: " + sent_at}</li>
          <li>{"Number of Recipients: " + email_hash.number_of_recipients}</li>
          {email_failure_list}
        </ul>
      </div>
    )
  }

  renderPercentageIndicator(total_tests, total_passed_tests, percentage) {
    let degrees = percentage == 1 ? 100 : Math.floor(percentage * 60);
    let message = (percentage == 1 ? "" : "Only ") + `${total_passed_tests} of ${total_tests} Event Properties and Conditions Found`;
    return (
      <div className="percentage-indicator" style={{ '--hue-degrees': `${degrees}deg` }}>
        {message}
      </div>
    )
  }

  renderEventStatBlock() {
    let event_stats = this.state.event_stats;
    let event_stat_keys = Object.keys(event_stats);
    // If we want to sort the 'bad' stats to the front:
    // event_stat_keys.sort((a, b) => event_stats[a] - event_stats[b]);
    return (
      <div className="event-stat-block">
        {event_stat_keys.map((stat_label, i) => <div key={"sl" + i} className="event-stat"><div>{stat_label}</div><div>{event_stats[stat_label] ? "✅" : "❌"}</div></div>)}
      </div>
    )
  }

  renderInviteOptions() {
    let event_stats = this.state.event_stats;
    let total_tests = Object.values(event_stats).length;
    let total_passed_tests = Object.values(event_stats).reduce((a, b) => a + b);
    let percentage = total_tests == 0 ? 0 : total_passed_tests / total_tests;
    let message = percentage == 1 ? "" : "(Show Event Invite Properties)"
    return (
      <>
        <div className="table-block-header one-space">
          <h4>Event Properties:</h4>
          {this.renderPercentageIndicator(total_tests, total_passed_tests, percentage)}
          {message}
          <ToggleSwitch
            hide={percentage == 1}
            value={!this.state.collapse_event_stats}
            onChange={this.toggleShowEventStats}
          />
        </div>
        <div style={{ "--max-height": "270px" }} className={"one-space table-block" + (this.state.collapse_event_stats ? " collapse-block" : "")}>
          {this.renderEventStatBlock()}
        </div>
        <div className={"email-subject-input one-space" + (this.state.disable_ui ? " disable-input" : "") + (this.state.loaded_event_invite_email_subject != this.state.event_invite_email_subject ? " subject-changed" : "")}>
          <div>Email Subject Line:</div>
          <input
            className="builder__textarea"
            type="text"
            value={this.state.event_invite_email_subject}
            onChange={this.updateEmailSubjectLine}
          />
          <button
            className={'assignment-button'}
            onClick={this.saveEventInviteSubject}
          >
            Save Email Subject
          </button>
        </div>
        <div className={"notifications-options" + (this.state.disable_ui ? " disable-input" : "")}>
          <div style={{ width: "100%", display: "flex" }} className={this.state.event_invite_email_subject ? "" : "disable-input"}>
            <button
              className={'assignment-button' + (this.state.sent_event_invite_date ? " disable-input" : "")}
              onClick={this.sendEventInvites}
            >
              Send Event Invite
            </button>
            <button
              className={'assignment-button'}
              onClick={this.sendTestEventInvites}
            >
              Send Test Event Invite
            </button>
            <div className="test-email-info">
              <div className="recipient">
                {"Test Email Recipient: " + this.props.test_email_info?.email}
              </div>
              <a href={this.props.test_email_info?.mixpanel_link} target="_blank" rel="noopener noreferrer">
                {"CIO Profile: " + this.props.test_email_info?.mixpanel_id}
              </a>
            </div>
          </div>
        </div>
        <div className={"notifications-options"}>
          {this.renderAllEmailMetrics()}
        </div>
      </>
    )
  }

  renderUpcomingAssignmentTable() {
    let table_row_templates = [
      {
        data_label: "premium_id",
        value_name: "premium_id"
      },
      {
        data_label: "event_date_id",
        value_name: "event_date_id",
        formatter: (v) => { return this.formatDateString(this.event_date_map_by_id[v]) }
      },
      {
        data_label: "timeslot",
        value_name: "timeslot"
      },
      {
        data_label: "tickets",
        value_name: "tickets"
      },
      {
        data_label: "status",
        value_name: "status",
        formatter: (v) => { return v || "pending finalization" }
      },
      {
        data_label: "sent_notifications",
        value_name: "sent_notifications",
        formatter: this.formatNotification
      },
    ]
    let now = moment();
    let tomorrow = moment().startOf('day').add('1', 'day');
    let cron_cutoff = moment().tz('UTC', true).set({ 'hour': 20, 'minute': 0, 'second': 0 });
    let a_week_from_tomorrow = moment(tomorrow).add('1', 'week');
    let sorted_assignments = [];

    if (this.state.enable_day_of_notification) {
      // Filter down to all assignments whose event date is within the next week
      sorted_assignments = [...this.state.render_assignments].filter(assignment => {
        let assignment_moment = moment(this.event_date_map_by_id[assignment.event_date_id]);

        // If the assignment's event_date is tomorrow, only show as upcoming if it's
        // before the cron job runs at 7:00PM EST
        if (assignment_moment.isSame(now.clone().add('1', 'day'), 'day')) {
          return now.isBefore(cron_cutoff);
        }

        return moment(this.event_date_map_by_id[assignment.event_date_id]).isBetween(tomorrow, a_week_from_tomorrow, null, []);
      });
      // Filter out assignments that have recieved the 'event_day_of_reminder' notification
      sorted_assignments = sorted_assignments.filter(assignment => !assignment.sent_notifications.includes(",event_day_of_reminder:"));
      // Clean Objects so they do not mutate props
      sorted_assignments = sorted_assignments.map(v => Object.assign({}, v));
      // Add upcoming 'event_day_of_reminder' information to assignments
      sorted_assignments = sorted_assignments.map(v => Object.assign(v, { sent_notifications: (",(UPCOMING) event_day_of_reminder:" + moment(this.event_date_map_by_id[v.event_date_id]).tz('UTC', true).set({ 'hour': 20, 'minute': 0, 'second': 0 }).subtract('1', 'day').valueOf()) }));

      sorted_assignments.sort(this.sortAccountIds);
      sorted_assignments.sort(this.sortChanges);
    }

    return (
      <>
        <UpdateTable
          header_labels={[
            "OFFLINEID",
            "DATESLOT",
            "TIMESLOT",
            "TICKETS",
            "STATUS",
            "UPCOMING NOTIFICATIONS",
          ]}
          row_templates={table_row_templates}
          rows={sorted_assignments}
          selectable={false}
          loading_data={this.state.updating_notification_table}
        />
      </>
    )
  }

  render() {
    let warning = (<></>);
    if (this.state.disable_ui && this.state.disable_message) {
      warning = (
        <div className="page-warning two-space" style={{ padding: "0" }}>
          <h2>Job running</h2>
          <div>{this.state.disable_message}</div>
        </div>
      )
    }

    return (
      <>
        <div style={{ width: '100%', paddingTop: "3.5rem" }}>
          <div className="admin__page-header">
            <div className="admin__page-title">
              Invite + Notifications
            </div>
            <div style={{ display: "flex", alignItems: "center" }} className="admin__page-actions">
              <EventNavLinks id={this.props.id} token={this.props.token} current='invites_and_notifications' />
            </div>
          </div>
        </div>
        <div style={{ padding: ' 0 2.5rem' }}>
          <h2 className="one-space">{"Event: " + this.state.name}</h2>
          {warning}
          <h3 className="one-space">Send Invites:</h3>
          {this.renderInviteOptions()}
          <div className='one-line one-space'></div>
          <h3 className="one-space">Send Notifications:</h3>
          {this.renderNotificationOptions()}
          <div className='one-line one-space'></div>
          <div className="table-block-header one-space">
            <h2 style={{ marginRight: "1em" }}>Upcoming Notificaitons:</h2>
            (Show)
            <ToggleSwitch
              value={!this.state.collapse_upcoming}
              onChange={this.toggleShowUpcomingNotifications}
            />
          </div>
          <div style={{ "--max-height": "256px" }} className={"two-space table-block" + (this.state.collapse_upcoming ? " collapse-block" : "")}>
            <h3>Event Tickets:</h3>
            {this.renderUpcomingAssignmentTable()}
          </div>
          <div className="table-block-header one-space">
            <h2 style={{ marginRight: "1em" }}>Sent Notificaitons:</h2>
            (Show)
            <ToggleSwitch
              value={!this.state.collapse_sent}
              onChange={this.toggleShowSentNotifications}
            />
          </div>
          <div style={{ "--max-height": "770px" }} className={"table-block" + (this.state.collapse_sent ? " collapse-block" : "")}>
            <h3>Event Tickets:</h3>
            {this.renderAssignmentTable()}
          </div>
        </div>
      </>
    );
  }
}

export default EventMessaging