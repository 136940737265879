import React from "react";

class BottomBar extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className="bottom-bar">
        {this.props.content}
        {!this.props.hide_subtitle &&
          <div className="bottom-bar-subtitle">You can not change your selected Offer after confirming.</div>
        }
      </div>
    );
  }
}

export default BottomBar