import React from "react";

class OfferNumberedList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="offer-numbered-list-container">
        {this.props.children.map((child, index) =>
          <div className="li-replacement" key={index}>
            {child}
          </div>
        )}
      </div>
    );
  }
}

export default OfferNumberedList