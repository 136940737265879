import React from "react";
import iconSelected from 'icon-selected.svg'
import { getSubscriptionCardMessaging, getSubscriptionTermsText } from "../../../sign_up/sign_up_user_messages";
import { convertIntegerToCleanPrice } from '../../../../helpers/price_formatter';
import { convertDateString } from '../../../../helpers/datestring_formatter'
import SignupFooter from "../../v2/gui_components/signup_footer";
import { getIDforGTM } from "../../v2/state_components/gtm_methods"
import SignupHeaderV3 from "../gui_components/signup_header_v3";

class SubscriptionForm extends React.Component {
  constructor(props) {
    super(props);

    this.selected_area = this.props.areas.find(area => area.id == this.props.selected_market);

    this.state = {
      annual_price: this.selected_area.yearly_subscription_price || 10788,
      annual_saving_percentage: props.annual_saving_percentage || "30%",
      monthly_price: this.selected_area.monthly_subscription_price || 1299,
      remaining_cost: props.remaining_cost,
      referral_can_discount: !isNaN(parseInt(props.remaining_cost)),
      selected_plan: props.selected_plan,
      membership_start: props.membership_start
    }

    this.pending_submission = false;
    this.reactivation_token = this.props.account ? this.props.account.token : null;

    this.onRadioUpdate = this.onRadioUpdate.bind(this);
    this.onSelectPlan = this.onSelectPlan.bind(this);
    this.renderPlanCard = this.renderPlanCard.bind(this);
    this.renderDeferral = this.renderDeferral.bind(this);
    this.submitSubscriptionDetails = this.submitSubscriptionDetails.bind(this);
    this.canContinue = this.canContinue.bind(this);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Subscription");
      posthog.capture("Signup: Subscription");
    }
  }

  onRadioUpdate(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSelectPlan(event) {
    this.setState({ selected_plan: event.target.dataset.plan });
  }

  getDeferralStartMonth(itter = 1) {
    const current_date = new Date();

    let current_month = current_date.getMonth();
    let next_month = current_month + itter;
    let year = current_date.getFullYear();

    while (next_month > 11) {
      next_month = next_month - 12;
      year++;
    }

    // If this market has a launch date in the future, members will defer to the start date
    if (this.selected_area.start_date) {
      var converted_date = convertDateString(this.selected_area.start_date);
      if (converted_date > new Date()) {
        next_month = converted_date.getMonth();
        year = converted_date.getFullYear();
      }
    }

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${months[next_month]} 1st, ${year}`;
  }

  canContinue() {
    return (this.state.selected_plan && this.state.membership_start);
  }

  submitSubscriptionDetails() {
    if (this.pending_submission || !this.state.selected_plan || !this.state.membership_start) return false;
    this.pending_submission = true;
    this.props.finalSubscriptionInfo({
      membership_start: this.state.membership_start,
      when_to_start: this.state.membership_start,
      selected_plan: this.state.selected_plan,
    })
  }

  renderPlanCard(plan_type = "annual") {
    let { annual_cost_today,
      annual_cost,
      monthly_cost_today,
      monthly_cost,
      show_discount_message } = getSubscriptionCardMessaging(this.props.referral_details.referral,
        this.selected_area);

    let plan_name = "Annually";
    let saving_percentage = this.state.annual_saving_percentage;
    let months_off = 12 - Math.round(this.state.annual_price / this.state.monthly_price);
    let sizzle_text = months_off > 0 && months_off <= 12? `${months_off} months free!` : "";
    let price_string = `$${convertIntegerToCleanPrice(annual_cost)}/yr`;
    let due_today = annual_cost_today;
    let id = "select_annual_plan";

    // AB Test Data
    if (signup_annual_copy_test && signup_annual_copy_test.includes(this.selected_area.name)) {
      price_string = `$${convertIntegerToCleanPrice(Math.round(this.state.annual_price / 12))}/mo`;
      sizzle_text = `Billed $${convertIntegerToCleanPrice(annual_cost)}/yr`;
    }

    if (plan_type == "monthly") {
      plan_name = "Monthly";
      saving_percentage = null;
      sizzle_text = "Cancel anytime";
      price_string = `$${convertIntegerToCleanPrice(monthly_cost)}/mo`;
      due_today = monthly_cost_today;
      id = "select_monthly_plan"
    }

    let plan_select = (
      <button
        id={id}
        className="sign-up-button-min full"
        data-plan={plan_type}
        onClick={this.onSelectPlan}
      >
        SELECT
      </button>
    )

    let card_class = "plan-card"
    if (this.state.selected_plan == plan_type) {
      card_class += " plan-active"
      plan_select = (
        <div className="plan-selected">
          <img src={iconSelected} />
          <div>SELECTED</div>
        </div>
      )
    }

    return (
      <div className={card_class}>
        <div className="plan-header">
          <div>{plan_name}</div>
          {saving_percentage &&
            <div className="savings-callout">
              Save {saving_percentage}
            </div>
          }
        </div>
        <div
          className={
            "plan-price"
            + (show_discount_message
              ? " has-discount" : "")
          }
        >
          {price_string}
        </div>
        {show_discount_message &&
          <div className="plan-discount">
            ${convertIntegerToCleanPrice(due_today)} due today
          </div>
        }
        {plan_select}
        <div className="plan-footer">{sizzle_text}</div>
      </div>
    )
  }

  renderDeferral() {
    return (
      <div className="radio-grouping">
        {!this.selected_area.deferral_only &&
          <div className="radio-option">
            <input
              type="radio"
              value="today"
              id="today"
              name="membership_start"
              checked={this.state.membership_start == "today"}
              onChange={this.onRadioUpdate}
            />
            <label htmlFor="today">Today</label>
          </div>}
        <div className="radio-option">
          <input
            type="radio"
            value="defer"
            id="defer"
            name="membership_start"
            checked={this.state.membership_start == "defer"}
            onChange={this.onRadioUpdate}
          />
          <label htmlFor="defer">{this.getDeferralStartMonth()}</label>
        </div>
      </div>
    )
  }

  rederDeferralText() {
    let terms = getSubscriptionTermsText(this.props.referral_details.referral,
      this.state.membership_start,
      this.state.selected_plan,
      this.selected_area);

    return (
      <ul className="sign-up-list-container">
        {terms.map((term, i) => <li key={"sub_term_" + i} className="input-subtitle list">{term}</li>)}
      </ul>
    );
  }

  render() {
    return (
      <>
        <SignupHeaderV3
          markets={this.props.markets}
          selected_market={this.props.selected_market}
          updateStateValue={() => { }}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
          select_enabled={false}
          currentScreen={this.props.currentScreen}
          total_screens={this.props.total_screens}
        />
        <div className="signup-v3-column signup-column sub-fix">
          <div className='subscription-information'>
            <h1>Choose Your Plan</h1>
            <div className='membership-info-subtitle'>Choose the plan type below</div>
            <br />

            <div className="subscription-plans">
              {this.renderPlanCard("annual")}
              {this.renderPlanCard("monthly")}
            </div>

            <div className="input-label">When should your membership start?</div>

            {this.renderDeferral()}
            {this.rederDeferralText()}
          </div>
          <button
            className={"full-blue-button smaller-text aux-full-width" + (this.canContinue() ? "" : " inactive")}
            disabled={this.canContinue() ? "" : "disabled"}
            onClick={this.submitSubscriptionDetails}
            id={getIDforGTM('submit-phone-number', this.props)}>
            CONTINUE
          </button>
          <button
            className={"footer-back-button-link aux-full-width"}
            // disabled={this.canContinue() ? "" : "disabled"}
            onClick={this.props.previousScreen}
            id={getIDforGTM('back-to-previous-screen', this.props)}>
            back
          </button>
        </div>
        {/* <SignupFooter
          center={
            <div className="membership-continue-button">
              <button
                className={"full-blue-button smaller-text "+(this.canContinue() ? "" : "inactive")}
                disabled={this.canContinue() ? "" : "disabled"}
                onClick={this.submitSubscriptionDetails}
                id={getIDforGTM('submit-phone-number', this.props)}>
                CONTINUE
              </button>
              <div className='footer-sub-message text-center membership-info-link'>have questions? <a href="https://support.letsgetoffline.com/en/://www.letsgetoffline.com/" target="_blank">Chat with us</a></div>
            </div>
          }
          sticky={true}
        /> */}
      </>
    );
  }
}

export default SubscriptionForm