import React from "react";
import moment from "moment-timezone";

class AccountOfferCredits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      offerCredits: [],
      availableCredits: 0,
      availableBonusCredits: 0,
      isLoading: true,
      error: null
    };

    this.fetchOfferCredits = this.fetchOfferCredits.bind(this);
    this.awardBonusCredit = this.awardBonusCredit.bind(this);
  }

  componentDidMount() {
    this.fetchOfferCredits();
  }

  fetchOfferCredits() {
    fetch(`/offer_credits/get_credits_for_account?account_id=${this.props.account.id}`)
      .then(response => response.json())
      .then(data => {
        const availableCredits = data.credits.filter(credit => !credit.is_used && !credit.bonus).length;
        const availableBonusCredits = data.credits.filter(credit => !credit.is_used && credit.bonus).length;
        this.setState({ 
          offerCredits: data.credits, 
          availableCredits, 
          availableBonusCredits, 
          isLoading: false 
        });
      })
      .catch(error => {
        this.setState({ error: "Failed to fetch offer credits", isLoading: false });
      });
  }

  awardBonusCredit(numberOfCredits = 1) {
    fetch(`/offer_credits/award_support_credit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ 
        account_id: this.props.account.id,
        number_of_credits: numberOfCredits
      })
    })
      .then(response => response.json())
      .then(data => {
        this.fetchOfferCredits(); // Refresh the list after awarding credits
        showFlashMessage(`${numberOfCredits} bonus credit(s) awarded successfully`);
      })
      .catch(error => {
        showFlashErrorMessage(`Failed to award ${numberOfCredits} bonus credit(s)`);
      });
  }


  render() {
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    let p_style = {"display":"inline","marginRight":"15px","paddingRight":"15px","boxShadow":"1px 0 0 0 #000"};

    return (
      <>
        <h2 onClick={() => {
          let collapse_action = this.props.collapse_action;
          collapse_action.action(collapse_action.args);
        }}>
          Offer Credits:{" "}
          <span
            className={caret_style}
            data-section-id="offer-credits-section"
          />
        </h2>
        <div id="offer-credits-section" style={collapsed_style}>
          {this.state.isLoading ? (
            <p>Loading offer credits...</p>
          ) : this.state.error ? (
            <p>{this.state.error}</p>
          ) : (
            <>
              <div className="credit-summary" style={{marginBottom: "10px"}}>
                <h3>Available Credits:</h3>
                <p style={p_style}>Regular Credits: {this.state.availableCredits}</p>
                <p style={p_style}>Bonus Credits: {this.state.availableBonusCredits}</p>
                <p style={p_style}>Total Available: {this.state.availableCredits + this.state.availableBonusCredits}</p>
              </div>
              <div className="credit-actions">
                <button style={{marginRight: "12px"}} onClick={() => this.awardBonusCredit(1)} className="admin__btn--create-new">
                  Award 1 Bonus Credit
                </button>
                <button onClick={() => this.awardBonusCredit(2)} className="admin__btn--create-new">
                  Award 2 Bonus Credits
                </button>
              </div>
              <table className="admin__table">
                <thead>
                  <tr>
                    <th>Created At</th>
                    <th>Is Used</th>
                    <th>Is Bonus</th>
                    <th>Creation Note</th>
                    <th>Usage Note</th>
                    <th>Used At</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.offerCredits.map((credit, index) => (
                    <tr key={index}>
                      <td>{moment(credit.created_at).format("MMM Do, YYYY h:mm A")}</td>
                      <td>{credit.is_used ? "Yes" : "No"}</td>
                      <td>{credit.bonus ? "Yes" : "No"}</td>
                      <td>{credit.creation_note}</td>
                      <td>{credit.usage_note}</td>
                      <td>{credit.used_at ? moment(credit.used_at).format("MMM Do, YYYY h:mm A") : "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </>
    );
  }
}

export default AccountOfferCredits;