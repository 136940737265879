import React from "react";
import CheveronDown from "icon-chevron-down.svg";
import FastCasual from "icon-fast-casual";
import CasualDining from "icon-casual-dining";
import HigherEnd from "icon-higher-end";

class FilterOffers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: [...this.props.locations].map(l => l.id),
      categories: [...this.props.categories].map(c => c.id),
      offer_types: [...this.props.offer_types].map(ot => ot.id),
      reset_locations: [...this.props.locations].map(l => l.id),
      reset_categories: [...this.props.categories].map(c => c.id),
      reset_offer_types: [...this.props.offer_types].map(ot => ot.id),
      locations_expanded: true,
      categories_expanded: true,
      offer_types_expanded: true,
    }

    this.get_dining_image = this.get_dining_image.bind(this);
    this.toggle_filter_section = this.toggle_filter_section.bind(this);
    this.update_filter = this.update_filter.bind(this);
    this.close_filter = this.close_filter.bind(this);
    this.close_overlay = this.close_overlay.bind(this);
    this.cancel_filter = this.cancel_filter.bind(this);
    this.getFriendLabel = this.getFriendLabel.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.choose_index != this.props.choose_index) {
      let update = {
        locations: this.state.locations,
        categories: this.state.categories,
        offer_types: this.state.offer_types,
      }
      
      this.props.update_live_count(update)
    }
    
  }

  get_dining_image(name) {
    name = name.toLowerCase();
    if (name == "fast casual") return FastCasual;
    if (name == "casual dining") return CasualDining;
    if (name == "higher end") return HigherEnd;
    return "";
  }

  toggle_filter_section(event) {
    let target = event.target;
    let name = target.dataset.name;
    if (this.state[name]) {
      event.target.classList.add("collapse-filter");
    } else {
      event.target.classList.remove("collapse-filter");
    }

    this.setState({ [name]: !this.state[name] });
  }

  update_filter(event) {
    let target = event.target;
    let name = target.name;
    let id = parseInt(target.dataset.id);
    let checked = target.checked;

    let filter_value = this.state[name];
    if (!target.dataset.id) {
      filter_value = checked;
    } else if (checked) {
      filter_value.push(id);
    } else {
      filter_value = filter_value.filter(c_id => c_id != id);
    }

    let update = {
      locations: this.state.locations,
      categories: this.state.categories,
      offer_types: this.state.offer_types,
    }

    update[name] = filter_value;

    this.setState({ [name]: filter_value });
    this.props.update_live_count(update)
  }

  close_overlay(e) {
    if (e.target.classList.contains('offer-select-filter-overlay')) {
      this.close_filter();
    }
  }

  close_filter() {
    this.props.close_filter({
      locations: this.state.locations,
      categories: this.state.categories,
      offer_types: this.state.offer_types
    });
    this.setState({
      reset_locations: [...this.state.locations],
      reset_categories: [...this.state.categories],
      reset_offer_types: [...this.state.offer_types],
    })
  }

  cancel_filter() {
    this.props.close_filter({
      locations: this.state.reset_locations,
      categories: this.state.reset_categories,
      offer_types: this.state.reset_offer_types,
    });
    this.setState({
      locations: [...this.state.reset_locations],
      categories: [...this.state.reset_categories],
      offer_types: [...this.state.reset_offer_types],
    })
  }

  getCategoriesLength(categories, all_categories) {
    let all_valid_ids = all_categories.map(category => category.id);
    return categories.filter(id => all_valid_ids.includes(id)).length;
  }

  getFriendLabel(friend_obj) {
    if (this.props.account_id == friend_obj.id) return "ME"
    return [friend_obj.first_name, friend_obj.last_name[0]].join(' ')
  }

  render() {
    return (
      <div style={{ display: this.props.show_offer_filter ? "" : "none" }} className="offer-select-filter-overlay" onMouseDown={this.close_overlay}>
        <div className="offer-select-filter-container">
          <div className="offer-select-filter-title">FILTER OFFERS</div>
          <div className="offer-select-filter-body">
            <div className={"filter-title"}>
              <div className="filter-text">{`Categories (${this.getCategoriesLength(this.state.categories, this.props.all_categories)})`}</div>
              <img src={CheveronDown} alt="Toggle" data-name="categories_expanded" onClick={this.toggle_filter_section} />
            </div>
            <div className={"categories" + (this.state.categories_expanded ? "" : " hide-filter-options")}>
              {this.props.all_categories.map(category => (
                <div className="category-option" key={"c" + category.id}>
                  <input type="checkbox"
                    name="categories"
                    id={"c" + category.id}
                    data-id={category.id}
                    checked={this.state.categories.includes(category.id)}
                    onChange={this.update_filter}
                  />
                  <label htmlFor={"c" + category.id}>{[category.emoji, category.name].join(' ')}</label>
                </div>
              ))}
            </div>
            <div className={"filter-title"}>
              <div className="filter-text">{`Dining Type (${this.state.offer_types.length})`}</div>
              <img src={CheveronDown} alt="Toggle" data-name="offer_types_expanded" onClick={this.toggle_filter_section} />
            </div>
            <div className={"offer_types" + (this.state.offer_types_expanded ? "" : " hide-filter-options")}>
              {this.props.all_offer_types.map(offer_type => (
                <div className="offer-type-option" key={"o" + offer_type.id}>
                  <input type="checkbox"
                    name="offer_types"
                    id={"o" + offer_type.id}
                    data-id={offer_type.id}
                    checked={this.state.offer_types.includes(offer_type.id)}
                    onChange={this.update_filter}
                  />
                  <label className="label-container" htmlFor={"o" + offer_type.id}>
                    <img src={this.get_dining_image(offer_type.name)} alt="" />
                    <div className="label-text">
                      {offer_type.name}
                    </div>
                  </label>
                </div>
              ))}
            </div>
            <div className={"filter-title"}>
              <div className="filter-text">{`Locations (${this.state.locations.length})`}</div>
              <img src={CheveronDown} alt="Toggle" data-name="locations_expanded" onClick={this.toggle_filter_section} />
            </div>
            <div className={"locations" + (this.state.locations_expanded ? "" : " hide-filter-options")}>
              {this.props.all_locations.map(location => (
                <div className="location-option" key={"l" + location.id}>
                  <input type="checkbox"
                    name="locations"
                    id={"l" + location.id}
                    data-id={location.id}
                    checked={this.state.locations.includes(location.id)}
                    onChange={this.update_filter}
                  />
                  <label htmlFor={"l" + location.id}>{location.location_name}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="offer-select-filter-footer">
            <button className="offer-select-cancel" onClick={this.cancel_filter}>CANCEL</button>
            <button className="offer-select-close" onClick={this.close_filter}>{"SHOW " + this.props.live_count + " OFFERS"}</button>
          </div>
        </div>
      </div>
    )
  }
}

export default FilterOffers