import React from "react";

import FriendLink from 'friend-link-icon.svg'
import TextReminder from 'text-reminder-icon.svg'

class SetupPhoneNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone_number: this.props.phone_number,
      error_message: ''
    }

    this.changePhoneNumber = this.changePhoneNumber.bind(this);
    this.submitPhoneNumber = this.submitPhoneNumber.bind(this);
    this.goBackWithSetup = this.goBackWithSetup.bind(this);
  }

  changePhoneNumber(event) {
    const target = event.target;
    this.setState({phone_number: target.value, error_message: ''});
  }

  submitPhoneNumber(event) {

    if (this.state.phone_number && this.state.phone_number.length > 0) {
      var phone = this.state.phone_number.replace(/\D/g,'');
      if (phone && phone[0] == "1") {
        phone = phone.slice(1)
      }
      if (phone.length != 10) {
        this.setState({error_message: "Invalid Phone Number"})
        return
      }
    }
    let self = this;

    $.ajax({
      method: "PUT",
      url: "/login/"+this.props.account_id,
      data: {account: {phone_number: this.state.phone_number, preference_setup_step: 3}},
      dataType: 'json',
      success: function(data) {
        self.props.syncPhoneNumber(self.state.phone_number);
        self.props.proceedWithSetup(self.props.setupStep + 1);
      },
      error: function(error) {
        self.setState({error_message: error.responseJSON.message})
      }
    });
  }

  goBackWithSetup() {
    this.props.goBackOneStep(this.props.setupStep - 1);
  }

  render() {
    return (
      <div>
        <div style={{margin: "13px"}}>
          <a className='member__app-btn btn-small' href={this.props.accountPath} onClick={this.goBackWithSetup}>back</a>
        </div>
        <div className='member__app-section-padded'>
          <h2>Check Your Phone Number</h2>
        </div>
        <div className="member__app-section-padded-large">
          <p className='text-center'>Promise not to send a bunch of annoying notifications or late-night “WYD” texts.
            <br/><br/>
            Add your digits + you’ll be able to:
          </p>
          <div>
            <div className='one-line' style={{marginTop: '20px'}}>
              <div className='text-right'><img src={FriendLink} /></div>
              <div className='text-left'>
                <h4>link Offers W friends</h4>
              </div>
            </div>
            <p className='top-margin-zero'>Find your friends + request the same Offers so you can use them together.</p>
          </div>

          <div>
            <div className='one-line' style={{marginTop: '20px'}}>
              <div className='text-right'><img src={TextReminder} /></div>
              <div className='text-left'>
                <h4>GET TEXT REMINDERS</h4>
              </div>
            </div>
            <p className='top-margin-zero'>Nothing worse than getting to the end of the month + realizing you forgot to use an Offer. That’s why we send helpful—never spammy—nudges to help you avoid such tragedies + keep you in the loop with any other of-the-moment info. </p>
          </div>

          <div className="added-margin">
            <h5>Phone Number:</h5>
            <input 
              className='large-input'
              type="text"
              value={this.state.phone_number || ''}
              onChange={this.changePhoneNumber}
              name="account[phone_number]"/>
              { this.state.error_message != "" &&
                <p className="subscript error-text bottom-margin-zero">{this.state.error_message}</p>
              }
          </div>

          <div className='text-center'>
            <button 
              className='member__app-btn btn-primary'
              onClick={this.submitPhoneNumber}>
              Next
            </button>
          </div>
        </div>

      </div>
    );
  }
}

export default SetupPhoneNumber