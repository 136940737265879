import React from "react";

class CategorySummary extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    const row_data = this.props.categories.map(category =>(
      <tr key={category.id}>
        <td className='table-left'><a href={"/algorithms/category/"+category.id}>{category.name}</a></td>
        <td className='table-left'>{category.total_offers}</td>
        <td className='table-left'>{category.total_offer_min_capacity} – {category.total_offer_target_capacity} – {category.total_offer_max_capacity}</td>
        <td className='table-left'>{category.unique_accounts}</td>
        <td className='table-left'>{category.total_assignment_preferences}</td>
      </tr>
    ))

    return (
      <div>
        <table className='admin__table'>
          <thead>
            <tr>
              <th className='table-left'>Name</th>
              <th className='table-left'>Offers</th>
              <th className='table-left'>Capacity (Min/Target/Max)</th>
              <th className='table-left'>Accounts</th>
              <th className='table-left'>Slots w/ Preference</th>
            </tr>
          </thead>
          <tbody>
            {row_data}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CategorySummary