import React from "react";

import NameInput from "../shared/account_inputs/name_input";
import ContinueButton from "../shared/continue_button";
import BackButton from "../shared/back_button";
import { getIDforGTM } from "../v2/state_components/gtm_methods";
import { validateName } from "../utils/account_validations";

class SignupV4ProfileName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: this.props.first_name || '',
      last_name: this.props.last_name || ''
    }
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value || "",
      ["error_"+event.target.name]: ''
    });
  }

  render() {
    return (
      <div className="signup-v4-profile-name">
        <NameInput
          first_name={this.state.first_name}
          error_first_name={this.state.error_first_name}
          last_name={this.state.last_name}
          error_last_name={this.state.error_last_name}
          changeInputField={this.onInputChange} />

        <ContinueButton
          onPress={validateName(this, this.props.onSubmit)}
          gtm_id={getIDforGTM('continue-to-next-screen', this.props)} />

        <BackButton
          onPress={this.props.onBack}
          gtm_id={getIDforGTM('back-to-previous-screen', this.props)}
        />
      </div>
    );
  }
}

export default SignupV4ProfileName