import React from "react";
import SectionWithHeader from "./section_with_header";
import BulletPoint from "./bullet_point";
import LabeledDropdown from "./labeled_dropdown";

import Bullet01 from "icon-bullet-01.svg";
import Bullet02 from "icon-bullet-02.svg";
import Bullet03 from "icon-bullet-03.svg";
import Bullet04 from "icon-bullet-04.svg";
import Dollars from "dollars.svg";

class UserReferralLandingScreenGUI extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let subtitle = this.props.referral_name? `Looks like your good-looking friend\n${this.props.referral_name} referred you.` : "";
    return (
      <div className="user-referral-landing-screen">
        <div className="user-referral-subtitle">
          {subtitle}
        </div>
        <LabeledDropdown
          label={"Confirm your city"}
          options={this.props.areas.map((area) => {
            return { value: area.id, label: area.name }
          })}
          update_input_value={this.props.update_area}
          selected_value={this.props.selected_area_id}
          disabled={this.props.network_busy}
        />
        <SectionWithHeader
          header={<div className="user-referral-section-header">
            <img src={Dollars} alt="" />
            <div className="user-referral-section-header-text">Offline is completely *free* until you try your first restaurant + love it</div>
          </div>}>
          <BulletPoint
            icon={Bullet01}
            text={"Choose two curated, local restaurants."} />
          <BulletPoint
            icon={Bullet02}
            text={"Get $10-$25 to spend at each spot."} />
          <BulletPoint
            icon={Bullet03}
            text={"The catch? You have 30 days to visit before your credits expire."} />
          <BulletPoint
            icon={Bullet04}
            text={"Review + repeat next month."} />
          <a className="user-referral-outlink" href="https://letsgetoffline.com" target="_blank">Learn more about Offline</a>
        </SectionWithHeader>
      </div>
    );
  }
}

export default UserReferralLandingScreenGUI