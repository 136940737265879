import React from "react";
import CalculatorIcon from 'icon-calculator.svg'
import ConfettiIcon from 'icon-confetti-background.svg'

class PaysForItselfSection extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    let monthly_cost = this.props.selected_monthly_cost || 10;

    return (
      <div className="signup-bubble-sections pay-section">
        <div className="bubble-title">
          How Offline <div className="title-highlight">pays for itself</div>
        </div>
        <div className="bubble-subtitle">
          <img src={CalculatorIcon} alt="" />
          The dollar amount of your monthly credits ($10-30 at two spots) will always exceed the cost of your membership.
        </div>
        <div className="breakdown-container">
          <div className="you-gain-box">
            <div className="pay-cell-title">{"🎉\nYou Gain"}</div>
            <div className="offer-cell-amount confetti-bg">
              <div>{`$${35 - monthly_cost}`}</div>
              <img src={ConfettiIcon} alt="" />
            </div>
          </div>
          <div className="offer-one-box">
            <div className="pay-cell-title">{"🌮\nRestaurant Offer #1"}</div>
            <div className="offer-cell-amount">$25</div>
          </div>
          <div className="offer-two-box">
            <div className="pay-cell-title">{"🍝\nRestaurant Offer #2"}</div>
            <div className="offer-cell-amount">$10</div>
          </div>
          <div className="membership-cost-box">
            <div className="pay-cell-title">{"🧾\nMembership Cost"}</div>
            <div className="offer-cell-amount">{`-$${monthly_cost}`}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default PaysForItselfSection