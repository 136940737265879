import React from "react";

// Import Icons
import LocationFlag from "flag.svg";
import OfferSelected from "icon-cheers.svg";
import IconChosen from "icon-cyoo-chosen.svg";
import IconIntersted from "icon-cyoo-interested.svg";
import IconDetails from 'icon-right-circle.svg';

class SignupOfferCard extends React.Component {
  /* ====== PROPS ======
    * offer:
        An Offer object
    * index (optional):
        Index of offer in parent array
    * is_selected (optional):
        boolean indicating if offer is selected
    * disable_outlinks (optional):
        boolean indicating if links should be disabled
    * select_offer (optional):
        function to call when card is selected
  =================== */
  constructor(props) {
    super(props);

    this.card_ref = React.createRef();

    this.select_offer = this.select_offer.bind(this);
    this.send_card_info = this.send_card_info.bind(this);
    this.render_interested = this.render_interested.bind(this);
  }

  componentDidMount() {
    // if (this.props.scroll_to_if_selected && this.props.is_selected) {
    //   document.scrollingElement.scrollTop = 0;
    //   this.card_ref.current?.scrollIntoView({ block: "nearest", inline: "start" });
    // }
  }

  componentDidUpdate(prevProps) {
    if (this.props.is_selected && prevProps.is_selected != this.props.is_selected) {
      if (this.props.scroll_to_if_selected && prevProps.scroll_to_if_selected != this.props.scroll_to_if_selected) {
        document.scrollingElement.scrollTop = 0;
        this.card_ref.current?.scrollIntoView({ block: "nearest", inline: "start" });
      }
    }
  }

  select_offer() {
    if (this.props.select_offer) {
      this.props.select_offer(this.props.offer);
    }
  }

  send_card_info() {
    if (this.props.open_offer_info) {
      this.props.open_offer_info(this.props.offer.id);
    }
  }

  render_fine_print_days(offer) {
    if (!offer) return (<></>);

    let day_indicators = offer.fine_print_days.map((fpd, i) =>
      <div className="day" key={`${offer.id}${i}`} data-lbl={fpd.state} >
        {fpd.name.substr(0, 1)}
      </div>
    )

    return (
      <div className="fine-print-days">
        {day_indicators}
      </div>
    );
  }

  render_interested() {
    let display_chosen = <></>;
    let display_interested = <></>;

    if (this.props.offer.total_interested > 0) {
      display_interested = (
        <div className="cyoo-interested">
          <img className="cyoo-interested-icon" src={IconIntersted} alt="" />
          <div className="simple-text">{this.props.offer.total_interested + " Interested Members"}</div>
        </div>
      )
    }

    if (this.props.offer.chosen_this_month > 0) {
      display_chosen = (
        <div className="cyoo-chosen">
          <img className="cyoo-chosen-icon" src={IconChosen} alt="" />
          <div className="simple-text">{this.props.offer.chosen_this_month + " Chosen this Month"}</div>
        </div>
      )
    }

    return (
      <>
        <div className="card-spacer"></div>
        <div className="cyoo-card-info-subtitles">
          {display_interested}
          {display_chosen}
        </div>
      </>
    )
  }

  render_card_inputs() {
    let content = (
      <>
        <div className="card-select-options">
          <button onClick={this.select_offer} className="offer-button-min choose-offer-button full">
            select this offer
          </button>
        </div>
      </>
    );
    if (this.props.is_selected) {
      content = (
        <>
          <div className="footer-message">
            <div>OFFER SELECTED</div>
          </div>
        </>
      );
    }

    return (
      <div className={"card-footer" + (this.props.is_selected ? " selected-footer" : "")}>
        {content}
      </div>
    )
  }

  render() {
    if (!this.props.offer) {
      return (<></>);
    }

    let img_url = this.props.offer.images[0]?.image;
    let instagram_url = this.props.offer.images[0]?.credit_link;

    let banner_media = <img className="card-header-image" src={img_url || ""} />;
    if (!this.props.disable_outlinks && instagram_url) {
      banner_media = (
        <a className="card-header-image" href={instagram_url} target="_blank">
          {banner_media}
        </a>
      )
    }

    let banner_msg = (<></>);
    return (
      <div ref={this.card_ref} data-num={this.props.index || 0} className={"signup-offer-card" + (this.props.is_selected ? " card-selected" : "")}>
        <div className="is-selected">
          <div className="selected-overlay"></div>
          <div className="image-container" ref={this.confetti_ref}>
            <img className="selected-image" src={OfferSelected} />
          </div>
        </div>
        {/* <div className={"already-selected-card" + (this.props.chosen ? "" : " hide-selected")}>
          <div className="stamp-container">
            <div className="stamp">
              ALREADY CONFIRMED
            </div>
          </div>
        </div> */}
        {banner_media}
        {banner_msg}
        <div className="spending-banner">
          <div className="max-two-text-lines w-side-pad">
            {this.props.offer?.offer_amount || ""}
          </div>
        </div>
        <div className="text-area relative">
          <div className="location-subtitle">
            <img src={LocationFlag} />
            {this.props.offer?.location || ""}
          </div>
          <div className="more-details" onClick={this.send_card_info}>
            <img src={IconDetails} alt="" />
          </div>
          <div className="signup-offer-card-title max-two-text-lines">{this.props.offer.name}</div>
          <div className="business-teaser">
            <div className="max-two-text-lines">
              {this.props.offer?.business_teaser || ""}
            </div>
          </div>
          {this.render_fine_print_days(this.props.offer)}
          {this.render_interested()}
        </div>
        {this.render_card_inputs()}
      </div>
    );
  }
}

export default SignupOfferCard;