import React from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePaymentElement from "./stripe_payment_element";

import { convertIntegerToPrice } from '../../helpers/price_formatter';
import { getPaymentTerms } from "./sign_up_user_messages";
import LockIcon from "icon-locked-min.svg";
import CostBox from "./cost_box";

class StripePaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      is_signup_9: false,
      subscription_terms: props.subscription_terms
        || "Your card will be charged $12.99 on October 1, 2022."
    }

    this.getSubscriptionTermsObject = this.getSubscriptionTermsObject.bind(this);
    this.trackPaymentSubmit = this.trackPaymentSubmit.bind(this);
    this.shouldUsePaymentIntent = this.shouldUsePaymentIntent.bind(this);

    this.stripePromise = loadStripe(this.props.stripe_key);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Payment");
      posthog.capture("Signup: Payment");
    }
    this.setState({ is_signup_9: document.querySelector('.sign-up-main-content.signup_v9') !== null });
  }

  getNextMonth(itter = 1) {
    const current_date = new Date();

    let current_month = current_date.getMonth();
    let next_month = current_month + itter;
    let year = current_date.getFullYear();

    while (next_month > 11) {
      next_month = next_month - 12;
      year++;
    }

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${months[next_month]} 1st, ${year}`;
  }

  getSubscriptionTermsObject() {
    let referral_details = this.props.referral_details;
    let selected_plan = this.props.selected_plan;
    let selected_area = this.props.selected_area;
    let membership_start = this.props.membership_start;

    return getPaymentTerms(referral_details, membership_start, selected_plan, selected_area);
  }

  updateParent(key, value) {
    if (this.props.onStateUpdate) {
      this.props.onStateUpdate(key, value)
    }
  }

  shouldUsePaymentIntent() {
    if (this.props.setup_intent.id.substr(0, 2) == "pi") {
      return true;
    }
    return false;
  }

  trackPaymentSubmit() {
    var signup_version = null;
    if (typeof (global_signup_version) != 'undefined') {
      signup_version = global_signup_version + ".0";
    }
    $.ajax({
      method: "POST",
      url: "/payment-submit",
      data: {
        waitlist_id: this.props.waitlist_id,
        signup_version: signup_version
      },
      dataType: 'json',
      success: (data) => {
      },
      error: (error) => {
      }
    });
  }

  render() {
    const fonts = [
      { cssSrc: "https://fonts.googleapis.com/css?family=Zilla+Slab:400,400i,600,600i|Patrick+Hand+SC&display=swap" },
      { cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700,700i|Source+Sans+Pro:400,400i,700,700i" },
      { cssSrc: "https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700;800&display=swap" }
    ];

    return (
      <div className="sign-up-column">
        <div className="payment-header">
          <div className="payment-header-text">{this.state.is_signup_9 ? "Secure your membership" : "Choose How to Pay"}</div>
          <img src={LockIcon} />
        </div>
        {!this.state.is_signup_9 &&
          <div className="market-subtitle payment-page">
            Time to put a ring on it. 💍
          </div>
        }


        <br />
        <br />

        <CostBox
          referral_details={this.props.referral_details}
          selected_plan={this.props.selected_plan}
          selected_area={this.props.selected_area}
          membership_start={this.props.membership_start}
        />

        <Elements
          stripe={this.stripePromise}
          options={
            {
              clientSecret: this.props.setup_intent.client_secret,
              fonts: fonts,
              appearance: {
                variables: {
                  fontFamily: "Zilla Slab",
                  fontWeightNormal: "600",
                  colorPrimary: "#000"
                },
                rules: {
                  '.Label': {
                    fontSize: '16px'
                  },
                  '.Input': {
                    boxShadow: 'none',
                    border: "2px solid #000"
                  },
                  '.Tab--selected': {
                    backgroundColor: "#FFBD54",
                    border: "3px solid #000"
                  },
                  '.Text--terms': {
                    color: "rgba(0,0,0,0.0)",
                    fontSize: "0.1px"
                  }
                }
              }
            }
          }>
          <StripePaymentElement
            redirect_url={this.props.confirmation_path}
            button_class={"sign-up-button-full"}
            subscription_terms_object={this.getSubscriptionTermsObject()}
            submit_event={this.trackPaymentSubmit}
            use_payment_intent={this.shouldUsePaymentIntent()}
            customer_id={this.props.setup_intent.customer}
          />

        </Elements>
        <div className="sign-up-nav-footer">
          <button id="back_to_step_2" className="sign-up-button-anchor" onClick={this.props.previousAction}>Back</button>
        </div>
      </div>

    );
  }
}

export default StripePaymentForm