import React from "react";
import IconFlag from "flag.svg";
import IconDollar from "icon-dollar.svg";
import IconClose from "icon-close.svg";
import IconPlus from "icon-plus.svg";
import IconMinus from "icon-minus.svg";
import moment from 'moment-timezone';
import CheckedSlider from "icon-slider-checked.svg";
import UncheckedSlider from "icon-slider-unchecked.svg";
import EventTicketAlertModal from './event_ticket_alert_modal';
import EventTicketConfirmation from "./event_ticket_confirmation";

class EventTicket extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.purhcased_ticket) {
      this.state = {
        close: false,
        selectedDate: { date: this.props.purhcased_ticket.date },
        selectedTime: { time: this.props.purhcased_ticket.time },
        ticketsCount: this.props.purhcased_ticket.tickets,
        showAlert: false,
        alertMessage: "",
        alertButtonText: "",
        alertSecondButtonText: "",
        confirmed: true,
        accepted_event_policy: false,
        should_reload: false,
        error_code: null,
        purchasing: false
      };
    } else {
      const { event } = this.props;
      if (event.event_type === 'AnyTime') {
        this.state = {
          close: false,
          showAlert: false,
          alertMessage: "",
          alertButtonText: "",
          alertSecondButtonText: "",
          confirmed: false,
          accepted_event_policy: false,
          should_reload: false,
          error_code: null,
          purchasing: false,
          ticketsCount: 1
        };
      } else {
        let { event_date, event_time } = this.getFirstEventDateWithTwoTickets(event.event_dates);

        this.state = {
          close: false,
          selectedDate: event_date,
          selectedTime: { time: null },
          ticketsCount: event_time ? 2 : 0,
          showAlert: false,
          alertMessage: "",
          alertButtonText: "",
          alertSecondButtonText: "",
          confirmed: false,
          accepted_event_policy: false,
          should_reload: false,
          error_code: null,
          purchasing: false
        };
      }
    }

    this.selectDate = this.selectDate.bind(this);
    this.selectTime = this.selectTime.bind(this);
    this.fixCard = this.fixCard.bind(this);
    this.acceptEventPolicy = this.acceptEventPolicy.bind(this);
    this.incresaeTicketsCount = this.incresaeTicketsCount.bind(this);
    this.decreaseTicketsCount = this.decreaseTicketsCount.bind(this);
    this.shouldDisableConfirmationButton = this.shouldDisableConfirmationButton.bind(this);
    this.confirmAndPay = this.confirmAndPay.bind(this);
    this.closeAlertModal = this.closeAlertModal.bind(this);
    this.closeConfirmed = this.closeConfirmed.bind(this);
  }

  getFirstEventDateWithTwoTickets(event_dates) {
    let sorted_event_dates = [...event_dates].sort((a, b) => moment(a.date).isAfter(moment(b.date)) ? 1 : -1);
    sorted_event_dates = sorted_event_dates.filter(event_date => moment(event_date.date).isAfter(moment().startOf('day').subtract(1, 'days')));

    for (let event_date of sorted_event_dates) {
      for (let event_time of event_date.event_times) {
        if (event_time.remaining_tickets >= 2 &&
          moment(event_date.date + ' ' + event_time.time, 'YYYY-MM-DD HH:mm A').isAfter(moment().add(1, 'hour'))) {
          return { event_date, event_time }
        }
      }
    }
    return { event_date: sorted_event_dates[0], event_time: null }
  }

  incresaeTicketsCount() {
    const ticketsCount = this.state.ticketsCount;
    this.setState({ ticketsCount: ticketsCount + 1 });
  }

  decreaseTicketsCount(event) {
    const ticketsCount = this.state.ticketsCount;
    if (ticketsCount > 0) {
      this.setState({ ticketsCount: ticketsCount - 1 });
    }
  }

  confirmAndPay() {
    const { selectedDate, selectedTime, ticketsCount } = this.state;

    this.setState({ purchasing: true });

    $.ajax({
      method: "POST",
      data: {
        event_ticket: {
          event_date_id: selectedDate?.id,
          event_time_id: selectedTime?.id,
          tickets_count: ticketsCount
        },
        setup_intent: this.props.setup_intent || null,
      },
      dataType: 'json',
      success: () => {
        this.setState({ confirmed: true, purchasing: false });
      },
      error: (error) => {
        let alertButtonText = "";
        let alertSecondButtonText = "";
        if (error.responseJSON.code == 1) {
          alertButtonText = "Change Time Slot";
        } else if (error.responseJSON.code == 2) {
          alertButtonText = "Find a Different Event";
        } else if (error.responseJSON.code == 3 || error.responseJSON.code == 6) {
          alertButtonText = "Fix my card";
          alertSecondButtonText = "I'll Fix Later"
        } else {
          alertButtonText = "Back";
        }
        this.setState({ purchasing: false, alertMessage: error.responseJSON.message, alertButtonText, alertSecondButtonText, error_code: error.responseJSON.code, showAlert: true, should_reload: (error.responseJSON.should_reload || false) })
      }
    });
  }

  closeAlertModal() {
    // this.setState({ showAlert: false });
    if (this.state.should_reload) {
      window.location.reload();
    } else {
      this.setState({ showAlert: false });
    }
  }

  fixCard() {
    window.location.href = `/add-new-card?id=${this.props.account_charge_id}&event_token=${this.props.event.token}`
  }

  closeConfirmed() {
    if (this.state.should_reload) {
      window.location.reload();
    } else {
      this.setState({ showAlert: false });
    }
  }

  selectDate(event_date) {
    if (event_date.id !== this.state.selectedDate.id) {
      this.setState({ selectedDate: event_date, selectedTime: { time: null } });
    }
  }

  selectTime(event_time) {
    if(moment(this.state.selectedDate.date + ' ' + event_time.time, 'YYYY-MM-DD HH:mm A').isBefore(moment().add(1, 'hour'))) return;
    if (event_time.remaining_tickets && event_time.remaining_tickets >= this.state.ticketsCount) {
      this.setState({ selectedTime: event_time });
    }
  }

  acceptEventPolicy() {
    this.setState({ accepted_event_policy: !this.state.accepted_event_policy });
  }

  shouldDisableConfirmationButton() {
    const { selectedDate, selectedTime, ticketsCount } = this.state;
    return !selectedDate?.id || !selectedTime?.id || ticketsCount === 0 || !this.state.accepted_event_policy || selectedTime.remaining_tickets < ticketsCount || moment(selectedDate.date + ' ' + selectedTime.time, 'YYYY-MM-DD HH:mm A').isBefore(moment().add(1, 'hour'));
  }

  render() {
    const { selectedDate, selectedTime, ticketsCount, showAlert, alertMessage, alertButtonText, alertSecondButtonText, confirmed, purchasing } = this.state;
    const { event } = this.props;

    let cost = ((event.ticket_price * ticketsCount) / 100.0);
    if (Number.isInteger(parseFloat(cost))) {
      cost = cost.toString();
    } else {
      cost = cost.toFixed(2);
    }

    let confirm_button_text = event.ticket_price === 0 ? `CONFIRM MY ${ticketsCount} TICKET` : `PURCHASE ${ticketsCount} TICKET FOR $${cost}`;
    if (ticketsCount > 1) {
      confirm_button_text = confirm_button_text.replace("TICKET", "TICKETS");
    }

    return (

      <div id={event.id} className="ticket-event-page">
        {purchasing &&
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        }
        <EventTicketAlertModal
          width="24em"
          message={alertMessage}
          buttonText={alertButtonText}
          secondButtonText={alertSecondButtonText}
          closeModal={this.closeAlertModal}
          show={showAlert}
          fixCard={this.state.error_code == 3 || this.state.error_code == 6 ? this.fixCard : null}
        />
        <div className={"ticket-event-grid" + (confirmed || event.event_type === 'AnyTime' ? " force-white-background" : "")}>
          {confirmed || event.event_type === 'AnyTime' ?
            <EventTicketConfirmation
              event={event}
              event_date={selectedDate}
              event_time={selectedTime}
              tickets_count={ticketsCount}
              closeConfirmed={this.closeConfirmed}
              confirmed={confirmed}
            /> :
            <div className="grid-content">
              <div className="header" style={{ height: "70px", border: "none" }}>
                <h1>Buy Tickets</h1>
              </div>

              <div className="content-title">
                <div className="event-title">
                  {event.name}
                </div>
                <div className="event-location-price">
                  <div>
                    <img src={IconFlag} alt="Offline" style={{ marginRight: "3px" }} />
                    <span>{event.address.city}, {event.address.state}</span>
                  </div>
                  <div>
                    <img src={IconDollar} style={{ marginRight: "3px" }} alt="Offline" />
                    <span>{event.ticket_cost + '/ticket'}</span>
                  </div>
                </div>
              </div>

              <div className="content-main overflow-correction">
                <div className="content-date">
                  <div className="content-heading">
                    Which Date?
                  </div>
                  <div className="event-dates-scroll-container">
                    <div className="event-dates">
                      {event.event_dates.map((event_date, i) => (
                        <div key={"ed" + i} className={"event-date-block " + (selectedDate.id === event_date.id ? 'selected ' : '') + (moment(event_date.date).isBefore(moment().startOf('day')) || event_date.event_times.length === 0 || !event_date.event_times.some(c => c.remaining_tickets > 0) ? 'disabled' : '')} onClick={() => this.selectDate(event_date)}>
                          <div className="event-date-day">{moment(event_date.date).format("DD")}</div>
                          <div className="event-date-month">{moment(event_date.date).format("MMM")}</div>
                        </div>
                      ))}
                      <div style={{ minWidth: "6px" }}></div>
                    </div>
                  </div>
                </div>
                <div className="content-tickets">
                  <div className="content-heading">
                    How Many Tickets?
                  </div>
                  <div className="event-tickets">
                    <button onClick={this.decreaseTicketsCount} disabled={ticketsCount < 1}>
                      <img src={IconMinus} alt="Offline" />
                    </button>
                    <span>{ticketsCount}</span>
                    <button onClick={this.incresaeTicketsCount}>
                      <img src={IconPlus} alt="Offline" />
                    </button>
                  </div>
                </div>
                {selectedDate.id && <div className="content-time" >
                  <div className="content-heading">
                    What Arrival Time?
                  </div>
                  <div className="event-times">
                    {selectedDate.event_times.map((event_time, i) => (
                      <div key={"et" + i} className={"event-time-block " + (selectedTime.id === event_time.id ? 'selected ' : '') + (!event_time.remaining_tickets || event_time.remaining_tickets < ticketsCount || moment(selectedDate.date + ' ' + event_time.time, 'YYYY-MM-DD HH:mm A').isBefore(moment().add(1, 'hour')) ? 'disabled' : '')} onClick={(e) => this.selectTime(event_time)}>
                        <div className="event-time">{event_time.time}</div>
                        {event_time.remaining_tickets > 0 ? (
                          <div className="event-time-status">{event_time.remaining_tickets + " Available"}</div>
                        ) : (
                          <div className="event-time-status">Sold Out</div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>}
              </div>
            </div>
          }

          <div className="grid-footer">
            {confirmed ?
              <></>
              :
              <>
                {event.event_type === 'DateTime' ?
                  <>
                    <div className="footer-content">
                      <div>
                        <div className="footer-date">
                          {selectedDate.id && <span>{moment(selectedDate.date).format("ddd, MMM DD")}</span>}
                          {selectedTime.id && <span> • {selectedTime.time}</span>}
                        </div>
                        <div className="footer-tickets">
                          {ticketsCount} Ticket{ticketsCount !== 1 && <span >s</span>}
                        </div>
                      </div>
                      <div className="footer-price">
                        ${cost}
                      </div>
                    </div>
                    <div className="accept-event-policy-container">
                      <div className="footer-note" style={{ width: '100%' }}>I agree to Offline's <a href="https://support.letsgetoffline.com/en/articles/7157053-offline-terms-of-service#h_dd662de066" target="_blank" className="blue-text">Event Policy</a></div>
                      <div className="slider-input">
                        <input type="checkbox"
                          name="accepted_event_policy"
                          id="accepted_event_policy"
                          checked={this.state.accepted_event_policy}
                          onChange={this.acceptEventPolicy}
                        />
                        <label className="label-container" htmlFor="accepted_event_policy">
                          <div className="label-knob">
                            <img className="slider-checked" src={CheckedSlider} alt="" />
                            <img className="slider-unchecked" src={UncheckedSlider} alt="" />
                          </div>
                        </label>
                      </div>
                    </div>
                    <button onClick={this.confirmAndPay} disabled={this.shouldDisableConfirmationButton()}>
                      {confirm_button_text}
                    </button>
                  </>
                  :
                  <>
                    <div className="footer-content">
                      <div>
                        <div className="footer-popup">
                          {event.name}
                        </div>
                        <div className="footer-valid">
                          Valid {moment(event.redeem_start_date).format("MMM DD")} - {moment(event.redeem_end_date).format("MMM DD")}
                        </div>
                      </div>
                      <div className="footer-price">
                        ${cost}
                      </div>
                    </div>
                    <div className="accept-event-policy-container">
                      <div className="footer-note" style={{ width: '100%' }}>I agree to Offline's <a href="https://support.letsgetoffline.com/en/articles/7157053-offline-terms-of-service#h_dd662de066" target="_blank" className="blue-text">Offer Policy</a></div>
                      <div className="slider-input">
                        <input type="checkbox"
                          name="accepted_event_policy"
                          id="accepted_event_policy"
                          checked={this.state.accepted_event_policy}
                          onChange={this.acceptEventPolicy}
                        />
                        <label className="label-container" htmlFor="accepted_event_policy">
                          <div className="label-knob">
                            <img className="slider-checked" src={CheckedSlider} alt="" />
                            <img className="slider-unchecked" src={UncheckedSlider} alt="" />
                          </div>
                        </label>
                      </div>
                    </div>
                    <button onClick={this.confirmAndPay} disabled={!this.state.accepted_event_policy || moment().add(1, 'hour').isAfter(moment(event.redeem_end_date))}>
                      {confirm_button_text}
                    </button>
                  </>
                }
              </>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default EventTicket