import React from "react";

import ContinueButton from "../shared/continue_button";
import BackButton from "../shared/back_button";
import { convertIntegerToCleanPrice } from '../../../helpers/price_formatter';

import { getIDforGTM } from "../v2/state_components/gtm_methods";
import iconSelected from 'icon-selected.svg'

class SignupV5Subscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    }

    this.submitData = this.submitData.bind(this);
    this.renderPlanCard = this.renderPlanCard.bind(this);
    this.onSelectPlan = this.onSelectPlan.bind(this);
    this.percentage = this.percentage.bind(this);
  }

  onSelectPlan(event) {
    this.props.onSubmit({plan_id: event.target.dataset.plan}, false);
  }

  submitData() {
    if (this.props.plan_id == null) {
      this.setState({error: "Please select a plan"});
    } else {
      this.props.onSubmit({}, true);
    }
  }

  percentage(annual, monthly) {
    let percentage = 100 - Math.round(100 * annual / (monthly * 12));
    return percentage > 0? `${percentage}%`: null;
  }

  renderPlanCard(plan_type = "annual") {
    let annual_cost = this.props.selected_area.yearly_subscription_price;
    let monthly_cost = this.props.selected_area.monthly_subscription_price;

    let plan_name = "Annually";
    let saving_discount = `$${convertIntegerToCleanPrice((monthly_cost*12) - annual_cost)} off`;

    let saving_percentage = this.percentage(annual_cost, monthly_cost);
    let months_off = 12 - Math.round(annual_cost / monthly_cost);
    let sizzle_text = months_off > 0 && months_off <= 12? `${months_off} months free!` : "";
    let price_string = `$${convertIntegerToCleanPrice(annual_cost)}/yr`;

    let due_today = 0;
    let id = "select_annual_plan";

    // AB Test Data
    if (signup_annual_copy_test && signup_annual_copy_test.includes(this.props.selected_area.name)) {
      saving_discount = saving_percentage;
      price_string = `$${convertIntegerToCleanPrice(Math.round(annual_cost / 12))}/mo`;
      sizzle_text = `Billed $${convertIntegerToCleanPrice(annual_cost)}/yr`;
    }

    if (plan_type == "monthly") {
      plan_name = "Monthly";
      saving_discount = null;
      sizzle_text = "Keep it flexible";
      price_string = `$${convertIntegerToCleanPrice(monthly_cost)}/mo`;
      due_today = 0;
      id = "select_monthly_plan"
    }

    let plan_select = (
      <button
        id={id}
        className="sign-up-button-min full"
        data-plan={plan_type}
        onClick={this.onSelectPlan}
      >
        SELECT
      </button>
    )

    let card_class = "plan-card"
    if (this.props.plan_id == plan_type) {
      card_class += " plan-active"
      plan_select = (
        <div className="plan-selected">
          <img src={iconSelected} />
          <div>SELECTED</div>
        </div>
      )
    }

    return (
      <div className={card_class}>
        <div className="plan-header">
          <div>{plan_name}</div>
          { saving_discount && <div className="savings-callout">{saving_discount}</div> }
        </div>
        <div className={"plan-price has-discount"}>
          {price_string}
        </div>
        <div className="plan-discount">
          $0.00 due today
        </div>
        {plan_select}
        <div className="plan-footer">{sizzle_text}</div>
      </div>
    )
  }

  render() {
    return (
      <div className="signup-v5-subscription">
        <div className="sub-fix">
          <div className='subscription-information'>
            <div className='membership-info-subtitle'>Choose the plan type below</div>
            <div className="subscription-plans">
              {this.renderPlanCard("annual")}
              {this.renderPlanCard("monthly")}
            </div>
          </div>
        </div>
        {this.state.error && <div className="error text-center">{this.state.error}</div>}
        <ContinueButton
          onPress={this.submitData}
          gtm_id={getIDforGTM('continue-to-next-screen', this.props)} />
        <BackButton
          onPress={this.props.onBack}
          gtm_id={getIDforGTM('back-to-previous-screen', this.props)}
        />
      </div>
    );
  }
}

export default SignupV5Subscription