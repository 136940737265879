import React from "react";

class RedirectToken extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user_token: "",
      long_lived_token: "",
    };
  }

  componentDidMount() {
    // The data is stored in an # tag in the url
    var hash_data_array = window.location.hash.substr(1).split("&");
    var hash_data = hash_data_array.reduce((acc, val) => {
      var parts = val.split("=");
      acc = { ...acc, [parts[0]]: parts[1] };
      return acc;
    }, {});

    console.log(hash_data);
    window.opener.postMessage(hash_data, "/");
    this.setState({ user_token: hash_data.access_token, long_lived_token: hash_data.long_lived_token });
  }
  
  render() {
    return (
      <div style={{margin: "30px"}}>
        <h1>Success!</h1>
        <p>Your Instagram Access Token is: <b style={{overflowWrap: "anywhere"}}>{this.state.user_token}</b></p>
        <p>Your Long-Lived Instagram Access Token is: <b style={{overflowWrap: "anywhere"}}>{this.state.long_lived_token}</b></p>
        <p>The Long-Lived Token has been saved to the page so feel free to close this window.</p>
        <input type="button" className="admin__btn--create-new" value="Close Window" onClick={() => { window.close() }} />
      </div>
    )
  }
}

export default RedirectToken;