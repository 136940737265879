import React from "react";
import CloseIcon from 'icon-modal-close.svg'

class AnnualConversionModal extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className="annual-conversion-modal-anchor">
        <div className="annual-conversion-modal">
          <div className="annual-conversion-modal-close">
            <img src={CloseIcon} alt="" onClick={this.props.closeModal} />
          </div>
          <div className="modal-spacing">
            <div className="modal-title">Don't miss out on Annual Membership perks!</div>
            <div className="perk-list">
              <div className="perk">
                Save 40% off your membership
              </div>
              <div className="perk">
                Swap restaurants anytime
              </div>
              <div className="perk">
                Early access + exclusive invites
              </div>
              <div className="perk">
                Pick restaurants in any Offline city
              </div>
            </div>
            <button className="modal-upgrade-button" onClick={this.props.acceptUpgrade}>Upgrade to Annual</button>
            <button className="modal-cancel-button" onClick={this.props.closeModal}>I Don't want annual</button>
          </div>
        </div>
        <div className="annual-conversion-modal-background"></div>
      </div>
    )
  }
}

export default AnnualConversionModal