import React from "react";

class CancelAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      months_to_pause: 0,
      show_required_fields: false,
      cancel_reason: '',
      cancel_comment: '',
      done: false
    }

    this.changeSelectOption = this.changeSelectOption.bind(this);
    this.cancelAccount = this.cancelAccount.bind(this);
    this.changeCancelComment = this.changeCancelComment.bind(this);
  }

  cancelAccount() {
    if (this.state.cancel_reason == '') {
      return this.setState({show_required_fields: true})
    }
    var self = this;
    $.ajax({
      method: "POST",
      url: "/settings/"+this.props.id+"/cancel",
      data: {
        account_id: this.props.account_id,
        cancel_comment: this.state.cancel_comment,
        cancel_reason: this.state.cancel_reason
      },
      dataType: 'json',
      success: function(data) {
        self.setState({done: true})
      },
      error: function(error) {
      }
    });
  }

  changeSelectOption(event) {
    const target = event.target;
    const name = target.name;

    this.setState({[name]: target.value, show_required_fields: false});
  }

  changeCancelComment(event) {
    this.setState({cancel_comment: event.target.value})
  }

  getFirstOfNextMonth() {
    const cmonth = new Date();
    const month_name = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return (month_name[new Date(cmonth.getFullYear(), cmonth.getMonth() + 1, 1).getMonth()] +" 1st");
  }
  
  render() {
    if (this.state.done) {
      return (
        <div className='text-center'>
          <h2 className='bottom-margin-zero'>Done</h2>
          <div className="member__app-section-padded-large">
            <p>We’ve cancelled your account. You can use your Offers up until the end of your current membership period.</p>
            <p>Your cancel date will be in your profile page at the bottom. If you need to un-cancel your account before it officially cancels, please write into support.</p>
            <p>You can see the status of your membership on the profile page anytime.</p>
            <p><a className='member__app-btn btn-primary' href={this.props.backLink}>Home</a></p>
          </div>
        </div>
      )
    }

    return (
      <div className='text-left'>
        <div className='one-line' style={{margin: "13px"}}>
          <div style={{width: '100%'}}>
            <a className='member__app-btn btn-small' href={this.props.backLink}>back</a>
          </div>
        </div>
        <h2 className='bottom-margin-zero'>Cancel Your Account</h2>
        
        <div className="member__app-section-padded-large">

          <div className="added-margin">
            <h5>Why are you cancelling?</h5>
            <select name="cancel_reason" id="cancel_reason" className='large-input' value={this.state.cancel_reason || ''} onChange={this.changeSelectOption}>
              <option disabled value="" style={{display: 'none'}}>--</option>
              <option value="covid">COVID</option>
              <option value="moving">Moving</option>
              <option value="busy">Too Busy</option>
              <option value="budget">Budget</option>
              <option value="traveling_for_work_not_able_to_use_perks">Traveling</option>
              <option value="unsatisfied">Unsatisfied</option>
            </select>
            {this.state.cancel_reason == '' && this.state.show_required_fields && 
              <p className='error-text'>Required</p>
            }
          </div>

          <div className="added-margin">
            <h5>Any other comments?</h5>
            <textarea
              className='large-input'
              id="cancel_comment" 
              name="cancel_comment" 
              value={this.state.cancel_comment} 
              onChange={this.changeCancelComment}
              style={{width: '100%', boxSizing: 'border-box'}}/>
          </div>

          <div className='text-center'>
            <p>
            By cancelling you will:
            <br/>1) Lose your previously accumulated lottery points
            <br/>2) Lose access to Offers, Perks, and event invites
            <br/>
            <br/>Your cancellation will take effect at the end of your current period. 
            <br/>
            <br/>You will still be able to use your Offline membership. We don't refund or credit for partially used billing periods.
            </p>

            <p>
              <a className='member__app-btn btn-primary' href={'/settings/'+this.props.id+'/pause'}>Pause Instead</a>
            </p>
            <p>
              <button className='member__app-btn btn-warning' onClick={this.cancelAccount}>Cancel My Membership</button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default CancelAccount