import React from "react";
import FormBuilder from "../shared/form_builder";
import BaseInfo from "./edit_page/base_info";
import AccountCYOO from "./edit_page/acconut_cyoo";
import AccountActions from "./edit_page/account_actions";
import MemberAppInfo from "./edit_page/member_app_info";
import AccountSubscriptionActions from "./edit_page/account_subscription_actions";
import AccountFriends from "./edit_page/account_friends";
import AccountInvitations from "./edit_page/account_invitations";
import AccountAssignmentSlots from "./edit_page/account_assignment_slots";
import AccountOffers from "./edit_page/account_offers";
import AccountEvents from "./edit_page/account_events";
import AccountPerks from "./edit_page/account_perks";
import AccountLotteryPoints from "./edit_page/account_lottery_points";
import AccountOfferCredits from "./edit_page/account_offer_credits";

class AccountEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      base_info_collapsed: false,
      cyoo_collapsed: false,
      actions_collapsed: false,
      member_app_info_collapsed: false,
      subscription_actions_collapsed: false,
      friends_collapsed: false,
      invitations_collapsed: false,
      assignment_slots_collapsed: false,
      offers_collapsed: false,
      events_collapsed: false,
      perks_collapsed: false,
      lottery_points_collapsed: false,
      offer_credits_collapsed: false,
      offer_assignments: this.props.offer_assignments,
      event_assignments: this.props.event_assignments,
      lottery_points: this.props.lottery_points,
      total_lottery_points: this.props.total_lottery_points,
      subscriptions: this.props.subscriptions,
      account: this.props.account,
      perk_assignments: this.props.perk_assignments
    };

    this.load_perk_assignments = this.load_perk_assignments.bind(this);
    this.toggle_collapse = this.toggle_collapse.bind(this);
    this.load_all_offer_assignments = this.load_all_offer_assignments.bind(this);
    this.load_all_event_assignments = this.load_all_event_assignments.bind(this);
    this.load_lottery_points = this.load_lottery_points.bind(this);
    this.load_all_subscriptions = this.load_all_subscriptions.bind(this);
    this.load_account = this.load_account.bind(this);
  }

  toggle_collapse(section) {
    this.setState({ [section]: !this.state[section] });
  }

  load_all_subscriptions(subscriptions) {
    this.setState({ subscriptions: subscriptions });
  }

  load_lottery_points(lottery_points, total_lottery_points) {
    this.setState({ lottery_points: lottery_points, total_lottery_points: total_lottery_points });
  }

  load_perk_assignments(perk_assignments) {
    this.setState({ perk_assignments: perk_assignments });
  }

  load_all_offer_assignments(offer_assignments) {
    this.setState({ offer_assignments: offer_assignments });
  }

  load_all_event_assignments(event_assignments) {
    this.setState({ event_assignments: event_assignments });
  }

  load_account(account) {
    this.setState({ account: account });
  }

  render() {
    return (
      <div className="report__body">
        <BaseInfo
          account={this.state.account}
          is_collapsed={this.state.base_info_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "base_info_collapsed" }}
        />
        <hr />
        <AccountCYOO
          job={this.props.job}
          account={this.state.account}
          is_collapsed={this.state.cyoo_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "cyoo_collapsed" }}
        />
        <hr />
        <AccountActions
          account={this.state.account}
          referral_type={this.props.referral_type}
          referral_code={this.props.referral_code}
          stripe_customer={this.props.stripe_customer}
          accepted_toc={this.props.accepted_toc}
          load_account={this.load_account}
          is_collapsed={this.state.actions_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "actions_collapsed" }}
        />
        <hr />
        <MemberAppInfo
          account={this.state.account}
          is_collapsed={this.state.member_app_info_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "member_app_info_collapsed" }}
        />
        <hr />
        <AccountOfferCredits
          account={this.state.account}
          is_collapsed={this.state.offer_credits_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "offer_credits_collapsed" }}
        />
        <hr />
        <AccountSubscriptionActions
          subscriptions={this.state.subscriptions}
          load_all_subscriptions={this.load_all_subscriptions}
          is_collapsed={this.state.subscription_actions_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "subscription_actions_collapsed" }}
        />
        <hr />
        <AccountFriends
          friends={this.props.offer_select.account_scope.friends}
          is_collapsed={this.state.friends_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "friends_collapsed" }}
        />
        <hr />
        <AccountInvitations
          invitations={this.props.invitations}
          is_collapsed={this.state.invitations_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "invitations_collapsed" }}
        />
        <hr />
        <AccountAssignmentSlots
          assignment_slots={this.props.assignment_slots}
          all_valid_offers={this.props.all_valid_offers}
          offer_select={this.props.offer_select}
          offer_assignments={this.state.offer_assignments}
          is_collapsed={this.state.assignment_slots_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "assignment_slots_collapsed" }}
          can_get_expensive={this.props.can_get_expensive}
        />
        <hr />
        <AccountOffers
          account={this.state.account}
          offer_assignments={this.state.offer_assignments}
          offer_select={this.props.offer_select}
          load_all_offer_assignments={this.load_all_offer_assignments}
          is_collapsed={this.state.offers_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "offers_collapsed" }}
        />
        <hr />
        <AccountEvents
          events={this.props.events}
          event_assignments={this.state.event_assignments}
          load_all_event_assignments={this.load_all_event_assignments}
          is_collapsed={this.state.events_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "events_collapsed" }}
        />
        <hr />
        <AccountPerks
          account={this.state.account}
          perk_categories={this.props.perk_categories}
          perk_assignments={this.state.perk_assignments}
          active_perks={this.props.active_perks}
          is_collapsed={this.state.perks_collapsed}
          load_perk_assignments={this.load_perk_assignments}
          collapse_action={{ action: this.toggle_collapse, args: "perks_collapsed" }}
        />
        <hr />
        <AccountLotteryPoints
          account={this.state.account}
          lottery_points={this.state.lottery_points}
          total_lottery_points={this.state.total_lottery_points}
          load_lottery_points={this.load_lottery_points}
          is_collapsed={this.state.lottery_points_collapsed}
          collapse_action={{ action: this.toggle_collapse, args: "lottery_points_collapsed" }}
        />
      </div>
    );
  }
}

export default AccountEdit;