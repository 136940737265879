import React from "react";
import SignupOfferCard from "./signup_offer_card";

import IconMap from "icon-last-chance-map.svg";

class OfferSlider extends React.Component {
  constructor(props) {
    super(props);

    this.slider_ref = React.createRef();
    this.scroll_to_selected = true;
  }

  componentDidMount() {
    this.scroll_to_selected = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.choose_index != this.props.choose_index) {
      if (this.props.selected_offer) {
        this.scroll_to_selected = true;
        return;
      }

      let slider_ref = this.slider_ref.current;
      if (slider_ref) {
        slider_ref.scrollLeft = 0;
      }
    } else {
      this.scroll_to_selected = false;
    }

    if (this.props.update_slider_ref) {
      this.props.update_slider_ref(this.slider_ref.current);
    }
  }

  render() {
    let offers = this.props.offers || [];
    return (
      <div className={"offer-slider-background " + (this.props.extra_class || "")} ref={this.background_ref} style={{ "--footer-offset": "138px" }}>
        <div className="offer-slider-container" ref={this.slider_ref}>
          <div className="offer-slider-menu">
            {offers.map((offer, index) =>
              <SignupOfferCard
                key={offer.id}
                index={index}
                offer={offer}
                is_selected={this.props.selected_offer?.id == offer.id}
                select_offer={this.props.select_offer}
                open_offer_info={this.props.open_offer_info}
                scroll_to_if_selected={this.scroll_to_selected}
              />
            )}
            {offers.length == 0 &&
              <div className="must-adjust-filter">
                <img src={IconMap} alt="" />
                <div className="adjust-title">No Offer matches found!</div>
                <div className="adjust-body">
                  Please expand your preferences above and try searching again.
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default OfferSlider