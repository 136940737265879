import React from "react";
import FormSelect from "./form_select";

class FormTimeMultiselect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            times: props.times || []
        }

        this.changeDropdownSelection = this.changeDropdownSelection.bind(this);
    }

    static getDerivedStateFromProps = (derived_props) => {
        return {times: derived_props.times || []};
    }

    changeDropdownSelection(name, value) {
        this.setState({ times: value });
        if (this.props.onChange) {
            this.props.onChange(value, this.props.index)
        }
    }

    timeStringFromMinutes(minutes) {
        var str_hours = Math.floor(minutes / 60);
        var str_minutes = (minutes % 60).toString().padStart(2, '0');
        var am_pm = str_hours >= 12 ? 'PM' : 'AM';

        str_hours = str_hours % 12;
        str_hours = str_hours ? str_hours : 12;

        return `${str_hours}:${str_minutes} ${am_pm}`;
    }

    getAllTimesFromMinuteIncrements(minute_increment = 15, start_from = 840) {
        let times = [];
        let before_start_times = [];
        for (let index = 0; index < 1440; index += minute_increment) {
            if (index < start_from) {
                before_start_times.push({ value: index, label: this.timeStringFromMinutes(index) })
            } else {
                times.push({ value: index, label: this.timeStringFromMinutes(index) })
            }
        }
        return [...times, ...before_start_times];
    }

    render() {
        const options = this.getAllTimesFromMinuteIncrements();

        return (
            <FormSelect
                options={options}
                name="times"
                className='event-date-time large-input'
                multiselect={true}
                value={this.state.times}
                onChange={this.changeDropdownSelection}
                scrollCloseToLastOption={true}
            />
        )
    }
}

export default FormTimeMultiselect;