import React from "react";
import EventReportComment from "./event_report_comment";
import EventReportOfflineComment from "./event_report_offline_comment";
import TicketBreakdown from "./ticket_breakdown";
import EventNavLinks from '../event_nav_links';

import { convertIntegerToPrice } from '../../../helpers/price_formatter';
import { convertDateString } from '../../../helpers/datestring_formatter'

class EventReport extends React.Component {
  constructor(props) {
    super(props);

    let date_array = this.props.event_dates.map(ed => { return convertDateString(ed.date) });
    date_array.sort((a, b) => a - b);

    this.earliest_date = date_array[0];
    this.latest_date = date_array.at(-1);


    // Ticket Data
    let ticket_count = 0;
    let checkin_count = 0;
    let tickets_no_charge_count = props.viewing_as_admin ? 0 : null;
    for (let assignment of this.props.event_assignments) {
      ticket_count += assignment.tickets;
      checkin_count += assignment.tickets_redeemed;
      if (props.viewing_as_admin) {
        tickets_no_charge_count += assignment.tickets_no_charge;
      }
    }

    this.total_tickets = ticket_count;
    this.total_checkings = checkin_count;
    this.percent_attended = checkin_count / ticket_count * 100;
    this.total_no_charge_tickets = tickets_no_charge_count;
    this.total_paid_tickets = this.total_tickets - this.total_no_charge_tickets;

    // Price Data
    this.offline_split = this.props.offline_split || this.props.ticket_price;
    this.partner_split = this.props.partner_split || 0;
    this.offline_price = convertIntegerToPrice(this.offline_split);
    this.partner_price = convertIntegerToPrice(this.partner_split);
    this.offline_revenue = convertIntegerToPrice(this.total_paid_tickets * this.offline_split);
    this.partner_revenue = convertIntegerToPrice(this.total_paid_tickets * this.partner_split);
    this.total_revenue = convertIntegerToPrice(this.total_paid_tickets * this.props.ticket_price);

    // Review Data
    this.reviewed_assignments = [...this.props.event_assignments];
    this.reviewed_assignments = this.reviewed_assignments.filter(ea => ea.status == "reviewed")

    let reviewed_count = 0;
    let reviewed_score = 0;
    for (let review of this.reviewed_assignments) {
      // 0 Implies they didn't give it a rating from 1-5 and skipped this part of the review
      if (review.customer_rating > 0) {
        reviewed_count++;
        reviewed_score += review.customer_rating;
      }
    }
    this.reviewed_average_rating = reviewed_score / reviewed_count;

    // this.getTotalReviewed = this.getTotalReviewed.bind(this);
    this.renderDate = this.renderDate.bind(this);
  }

  getTotalReviewed() {
    return this.reviewed_assignments.length
  }

  getPercentReviewed() {
    if (this.props.event_assignments.length == 0) {
      return ""
    }
    return (this.reviewed_assignments.length / this.props.event_assignments.length * 100).toFixed(1);
  }

  getAverageRating() {
    return this.reviewed_average_rating.toFixed(1);
  }

  getAverageSurveyRating(key) {
    let count = 0;
    let score = 0;
    for (let assignment of this.reviewed_assignments) {
      if (assignment.survey_answers[key] && assignment.survey_answers[key] != "") {
        count++;
        score += parseInt(assignment.survey_answers[key]);
      }
    }
    if (count == 0) {
      return ""
    }
    return (score / count).toFixed(1);
  }

  getPercentSurveyAnswer(key) {
    let count = 0;
    let score = 0;
    for (let assignment of this.reviewed_assignments) {
      if (assignment.survey_answers[key] && assignment.survey_answers[key] != "") {
        count++;
        if (assignment.survey_answers[key] == "yes")
          score++;
      }
    }
    if (count == 0) {
      return ""
    }
    return (score * 100 / count).toFixed(1);
  }

  renderTicketPriceBreakdown() {
   if (this.props.viewing_as_admin) {
      return (
        <p>
          Ticket Price: ${convertIntegerToPrice(this.props.ticket_price)}<br/>
          Split (Offline/Partner): ${this.offline_price} / ${this.partner_price}<br/>
          Offline Revenue: ${this.offline_revenue}<br/>
          Partner Revenue: ${this.partner_revenue}<br/>
          Total Revenue: ${this.total_revenue}<br/>
        </p>
      )
    }
    return ""
  }

  renderPartnerComments() {
    return (this.reviewed_assignments.filter(e => e.customer_feedback).map((e) =>
      <EventReportComment
        key={e.id}
        rating={e.customer_rating}
        comment={e.customer_feedback} />
    ));
  }

  renderOfflineComments() {
    return (this.reviewed_assignments.map((e) =>
      <EventReportOfflineComment key={e.id} event_assignment={e} />
    ));
  }

  renderDate(date) {
    if (date) {
      const options = { weekday: 'long', month: 'long', day: 'numeric' }
      return date.toLocaleString('en-US', options);
    }
    return ""
  }

  render() {
    return (
      <div style={{ textAlign: 'left', paddingBottom: '40px' }}>
        {this.props.viewing_as_admin &&
          <div style={{ position: 'relative', top: '10px', textAlign: 'right', display:"flex", alignItems:"center", justifyContent:"flex-end" }}>
            <EventNavLinks id={this.props.id} token={this.props.token} current='report' />
          </div>
        }
        <h1 className='small-spacer'>{this.props.name}</h1>
        <div>
          <h2 className='small-spacer'>Event Dates</h2>
          <p style={{ marginTop: '0' }}>{this.renderDate(this.earliest_date)} — {this.renderDate(this.latest_date)}</p>
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ width: '300%' }}>
            <h2 className='small-spacer'>Ticket Breakdown</h2>
            <TicketBreakdown
              event_dates={this.props.event_dates}
              event_assignments={this.props.event_assignments} />
          </div>
          <div style={{ width: '200%' }}>
            <h2 className='small-spacer'>Check In Breakdown</h2>
            <p>
              Tickets: {this.total_tickets}<br />
              {this.total_no_charge_tickets != null && <>No Charge Tickets: {this.total_no_charge_tickets}<br /></>}
              Checked Ins: {this.total_checkings}<br />
              Attended: {this.percent_attended.toFixed(1)}%
            </p>
            {this.renderTicketPriceBreakdown()}
          </div>
        </div>

        <div>
          <h2 className='small-spacer'>Event Rating</h2>
          <div>
            <p>
              # Reviewed: {this.getTotalReviewed()}<br />
              % Reviewed: {this.getPercentReviewed()}%<br />
              Event Rating: {this.getAverageRating()}<br />
              ambience: {this.getAverageSurveyRating("ambience")}<br />
              Food/Drinks: {this.getAverageSurveyRating("food_drinks")}<br />
              Staff: {this.getAverageSurveyRating("staff")}<br />
              % First Time Visiting : {this.getPercentSurveyAnswer("first_time")}%<br />
              % Would Go Back: {this.getPercentSurveyAnswer("go_back")}%<br />
            </p>
          </div>
          <p className="section-header text-left">Member Comments</p>
          <div>
            {this.renderPartnerComments()}
          </div>
        </div>

        {this.props.viewing_as_admin &&
          <div>
            <h2 className='small-spacer'>Offline Event Rating</h2>
            <div>
              <p>
                Event Rating: {this.getAverageSurveyRating("offline_rating")}<br />
                % Brought Non-Offline: {this.getPercentSurveyAnswer("non_offline_friend")}%<br />
              </p>
            </div>
            <p className="section-header text-left">Offline Comments</p>
            <div>
              <div className="text-left" style={{ marginBottom: '10px' }}>
                <div className='user-rating'>
                </div>
                <div className='user-comment'>
                  <div style={{ width: '47%', display: 'inline-block', marginLeft: '2%' }}>
                    <b>Anything you loved?</b>
                  </div>
                  <div style={{ width: '47%', display: 'inline-block', marginLeft: '2%' }}>
                    <b>Better next time?</b>
                  </div>
                </div>
              </div>

              {this.renderOfflineComments()}
            </div>
          </div>
        }
      </div>
    );
  }
}

export default EventReport