import React from "react";
import LocationEdit from "../assignment_preference/location_edit"
import CategoryEdit from "../assignment_preference/category_edit"
import LocationEditDisabled from "../assignment_preference/location_edit_disabled"
import CategoryEditDisabled from "../assignment_preference/category_edit_disabled"
import {checkEqualPreferenceData} from '../../helpers/preference_comparison'
import InfoIcon from 'icon-info.svg'


class AssignmentSlotEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignment_preference: this.props.assignment_slot.assignment_preference,
      link_with_friends: this.props.assignment_slot.link_with_friends
    }
    this.updatePreferenceCategories = this.updatePreferenceCategories.bind(this);
    this.updatePreferenceLocations = this.updatePreferenceLocations.bind(this);
    this.handleLinkWithFriendsChange = this.handleLinkWithFriendsChange.bind(this);
  }

  updatePreferenceCategories(new_category_ids) {
    var new_preference = Object.assign({},this.state.assignment_preference);
    new_preference.preference_data.categories = new_category_ids;
    this.setState({assignment_preference: new_preference});
  }

  updatePreferenceLocations(new_location_ids) {
    var new_preference = Object.assign({},this.state.assignment_preference);
    new_preference.preference_data.locations = new_location_ids;
    this.setState({assignment_preference: new_preference});
  }

  dateOfNextMonth() {
    if (this.props.area_launch_date) {
      return this.props.area_launch_date;
    } 
    var now = new Date();
    var next_month = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return (next_month.getMonth() + 1)+"/1/"+next_month.getFullYear();
  }

  handleLinkWithFriendsChange() {
    var new_state = !this.state.link_with_friends
    this.setState({
      link_with_friends: new_state
    })
    $.ajax({
      method: "POST",
      url: "/home/customize/"+this.props.assignment_slot.id,
      data: {link_with_friends: new_state},
      dataType: 'json'
    })
  }

  checkIfUsingGroupPreference() {
    return this.state.link_with_friends
  }

  render() {
    return (
      <div className='text-left'>
        <div style={{margin: "13px"}}>
          <a className='member__app-btn btn-small' href={this.props.accountPath}>back</a>
        </div>
        <h2 style={{margin: '0 26px 26px'}} className='text-center'>{this.props.assignment_slot.name}</h2>
        <div>
          { !this.checkIfUsingGroupPreference() &&
            <>
              <LocationEdit
                locations={this.props.locations}
                area_id={this.props.area_id}
                assignment_preference={this.state.assignment_preference}
                updateToLocations={this.updatePreferenceLocations} />
              <CategoryEdit
                categories={this.props.categories}
                assignment_preference={this.state.assignment_preference}
                updateToCategories={this.updatePreferenceCategories} />
            </>
          }
          { this.checkIfUsingGroupPreference() && 
            <>
              <LocationEditDisabled
                locations={this.props.locations}
                area_id={this.props.area_id}
                assignment_preference={this.state.assignment_preference} />
              <CategoryEditDisabled
                categories={this.props.categories}
                assignment_preference={this.state.assignment_preference} />
            </>
          }
        </div>
        <div className='text-center'>
          Your changes will take effect on:
          <h3>
            <a href="https://support.letsgetoffline.com/article/168-when-do-my-preference-changes-take-effect"
                target="_blank">{this.dateOfNextMonth()}
              <img style={{verticalAlign: 'top', marginLeft: '3px'}} src={InfoIcon} />
            </a>
          </h3>
        </div>
      </div>
    );
  }
}

export default AssignmentSlotEdit