import React from "react";


class LocationEditDisabled extends React.Component {
  constructor(props) {
    super(props);
    var location_values = {}
    var pref_data = this.props.assignment_preference.preference_data;
    if (pref_data ==  null) {
      pref_data = this.props.assignment_preference.full_preference_data;
    }

    this.areas = {};
    for (const location of this.props.locations) { 
      location_values[location.id] = pref_data.locations.includes(location.id)
      this.areas[location.primary_area.id] ||= {id: location.primary_area.id, name: location.primary_area.name, locations: []}
      this.areas[location.primary_area.id]["locations"].push(location)
    }

    const first_area_id = this.props.area_id;
    this.ordered_area_ids = Object.keys(this.areas).sort(function(a,b) {return (parseInt(b) == first_area_id ? 1 : -1) - (parseInt(a) == first_area_id ? 1 : -1)});

    this.state = {
      location_values: location_values
    }
  }


  renderAreas() {
    return (this.ordered_area_ids.map(area_id => (
        <div key={area_id}>
          <h4 style={{padding: '10px 0 5px'}}>{this.areas[area_id].name}</h4>
          <div>
            {this.renderLocations(this.areas[area_id].locations)}
          </div>
        </div>
      )));

  }

  renderLocations(locations) {
    return (locations.map(location =>(
        <button
          className={this.state.location_values[location.id] == true ? "member__app-pill member__app-toggle" : "member__app-pill member__app-toggle pill-grey"}
          key={location.id}
          data-id={location.id}
          onClick={this.changeLocationToggle} >
            {location.name}
        </button>
      )));
  }

  render() {
    return (
      <div style={{position: 'relative'}}>
        <div className="member__app-section member__app-section-body" style={{filter: "blur(4px)"}}>
          <h3 className='flat'>Where to explore?</h3>
          <p>Adding more areas gives us a larger selection of Offers to choose from for you.</p>
          <div className='text-left'>
            { this.state.error_message != "" &&
              <p className="subscript error-text text-center">{this.state.error_message}</p>
            }
            {this.renderAreas()}
          </div>
        </div>
        <div className='disabled-section'>
          <div><b>Group preferences overide <br/>your individual prefrences</b></div>
        </div>
      </div>
    );
  }
}

export default LocationEditDisabled