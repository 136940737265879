import React from "react";

class AccountSubscriptionActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      select_for: null,
      selected_reason: null
    };

    this.network_busy = false;

    this.submitPost = this.submitPost.bind(this);
    this.select_cancel_reason = this.select_cancel_reason.bind(this);
    this.render_select_options = this.render_select_options.bind(this);
    this.update_reason = this.update_reason.bind(this);
    this.cancel_subscription = this.cancel_subscription.bind(this);
    this.update_subscription = this.update_subscription.bind(this);
  }

  update_subscription(data) {
    let new_subscriptions = this.props.subscriptions.map((sub) => {
      if (sub.id == data.id) {
        return data;
      }
      return sub;
    });

    this.props.load_all_subscriptions(new_subscriptions);
  }

  submitPost(e, path = null) {
    let target = e.target;
    let sudo_form = target.parentElement;

    if (target.getAttribute("data-confirm") && !confirm(target.getAttribute("data-confirm"))) {
      return;
    }

    path = path || sudo_form.getAttribute("action");

    if (!path.includes("?")) {
      path += "?as_json=true";
    } else if (!path.includes("as_json")) {
      path += "&as_json=true";
    }

    if (this.network_busy) {
      return;
    }
    this.network_busy = true;

    $.ajax({
      method: sudo_form.getAttribute("method").toUpperCase(),
      url: path,
      processData: false,
      contentType: false,
      success: (data) => {
        this.network_busy = false;
        if (typeof data === "object") {
          showFlashMessage(sudo_form.dataset.success);
          this[`${sudo_form.dataset.update}`](data);
          this.setState({ select_for: null, selected_reason: null });
        } else if (typeof data === "string"){
          showFlashErrorMessage(data.split('\n')[0]);
        }
      },
      error: (error) => {
        this.network_busy = false;
        if (error && typeof error.responseText === "string") {
          showFlashErrorMessage(error.responseText.split('\n')[0]);
        }
        // showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }

  update_reason(e) {
    let target = e.target;
    let reason = target.value;

    this.setState({ selected_reason: reason });
  }

  cancel_subscription(e) {
    let target = e.target;
    let sudo_form = target.parentElement;

    if (this.state.selected_reason === null || this.state.selected_reason == "") {
      return;
    }

    let path = sudo_form.getAttribute("action");
    path += "?reason=" + this.state.selected_reason + "&submit_type=" + target.value + "&as_json=true";

    this.submitPost(e, path);
  }

  select_cancel_reason(e) {
    let tartget = e.target;
    let subscription = this.props.subscriptions.find((sub) => sub.id == parseInt(tartget.getAttribute("data-subscription-id")));

    this.setState({ select_for: subscription.id, selected_reason: null });
  }

  render_select_options(subscription_id) {
    if (subscription_id != this.state.select_for) {
      return (<></>)
    }

    return (
      <select
        className="cancel-select-reason"
        name="reason"
        id={"reason_" + subscription_id}
        onChange={this.update_reason}
        value={this.state.selected_reason}
      >
        <option value="" />
        <option value={0}>unpaid</option>
        <option value={1}>moving</option>
        <option value={2}>busy</option>
        <option value={3}>unsatisfied</option>
        <option value={4}>budget</option>
        <option value={5}>canceling_trial</option>
        <option value={6}>duplicate_account</option>
        <option value={7}>
          traveling_for_work_not_able_to_use_perks
        </option>
        <option value={8}>other</option>
        <option value={9}>covid</option>
        <option value={10}>change_to_togo</option>
        <option value={11}>subscription_conversion</option>
        <option value={12}>change_to_classic</option>
      </select>
    )
  }

  render() {
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    return (
      <>
        <div id="subs-data-wrapper">
          <h2 onClick={() => {
            let collapse_action = this.props.collapse_action;
            collapse_action.action(collapse_action.args);
          }}>
            Subscriptions:{" "}
            <span
              className={caret_style}
              data-section-id="subscription-section"
            />
          </h2>
          <div id="subscription-section" style={{ ...collapsed_style }}>
            <table className="report__table">
              <thead>
                <tr>
                  <th>
                    <b>Area</b>
                  </th>
                  <th>
                    <b>Type</b>
                  </th>
                  <th>
                    <b>Status</b>
                  </th>
                  <th>
                    <b>Trial End</b>
                  </th>
                  <th />
                  <th>
                    <b>Renewal Date</b>
                  </th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.props.subscriptions.map((subscription, index) => {
                  return (
                    <tr key={"sub" + subscription.id}>
                      <td>{subscription.area}</td>
                      <td>
                        {subscription.is_annual ? "Annual" : "Monthly"}
                        {subscription.is_upgrading_to_annual &&
                          <>
                            <br />
                            <span style={{ color: "red" }}>Upgrading to Annual</span>
                          </>
                        }
                      </td>
                      <td id={"sub-status-" + subscription.id}>{subscription.status + (subscription.will_be_canceled_on ? ("\n- canceling on " + subscription.will_be_canceled_on) : "")}</td>
                      <td>{subscription.trial_end ? subscription.trial_end : "N/A"}</td>
                      <td>
                        {subscription.status === "trialing" &&
                          <div id={subscription.id + "-trial-extend-wrapper"}>
                            <div
                              action={"/subscriptions/" + subscription.id + "/extend_trial"}
                              data-remote="true"
                              method="post"
                              data-success="Subscription trial extended"
                              data-update="update_subscription">
                              <button
                                name="button"
                                type="submit"
                                data-confirm="Attempt to extend the trial period for 1 month?"
                                onClick={this.submitPost}>
                                Extend Trial
                              </button>
                            </div>
                          </div>
                        }
                      </td>
                      <td>{subscription.renewal_date ? subscription.renewal_date : "needs Stripe Sync"}</td>
                      <td style={{ padding: 10 }}>
                        {(this.state.select_for != subscription.id && (subscription.status != "canceled" || subscription.will_be_canceled_on)) &&
                          <span
                            data-subscription-id={subscription.id}
                            data-id={subscription.id}
                            className="admin__account-pre-cancel standard-btn--rounded--red"
                            id={"pre-cancel-" + subscription.id}
                            onClick={this.select_cancel_reason}
                          >
                            Cancel Subscription
                          </span>
                        }
                        <div className={this.state.select_for == subscription.id ? "" : "admin__account-cancel-row"} id={"cancel-row-" + subscription.id}>
                          <div
                            action={"/subscriptions/" + subscription.id + "/cancel"}
                            acceptCharset="UTF-8"
                            data-remote="true"
                            method="post"
                            data-success="Subscription cancel request successful"
                            data-update="update_subscription"
                          >
                            {this.render_select_options(subscription.id)}
                            <button
                              name="button"
                              type="submit"
                              value="immediately"
                              className="cancel-submit"
                              onClick={this.cancel_subscription}
                              disabled={this.state.selected_reason === null || this.state.selected_reason == "" ? "disabled" : ""}
                            >
                              Cancel immediately
                            </button>
                            <button
                              name="button"
                              type="submit"
                              value="end of month"
                              className="cancel-submit"
                              onClick={this.cancel_subscription}
                              disabled={this.state.selected_reason === null || this.state.selected_reason == "" ? "disabled" : ""}
                            >
                              Cancel at end of month
                            </button>
                          </div>
                        </div>
                        {subscription.will_be_canceled_on &&
                          <div id={"undo-cancel-row-" + subscription.id}>
                            <div
                              action={"/subscriptions/" + subscription.id + "/undo_scheduled_cancel"}
                              data-remote="true"
                              method="post"
                              data-success="Subscription undo cancel successful"
                              data-update="update_subscription">
                              <button
                                name="button"
                                type="submit"
                                onClick={this.submitPost}>
                                Undo Scheduled Cancel
                              </button>
                            </div>
                          </div>
                        }
                      </td>
                      <td>
                        <span style={{ display: "inline-block" }}>
                          <div
                            className="button_to"
                            method="post"
                            action={"/subscriptions/" + subscription.id + "/refund_credit?p=50"}
                            data-remote="true"
                            data-success="Credit 1/2 added"
                            data-update="update_subscription"
                          >
                            <input type="submit" value="Credit 1/2" onClick={this.submitPost} />
                          </div>
                        </span>
                        <span style={{ display: "inline-block" }}>
                          <div
                            className="button_to"
                            method="post"
                            action={"/subscriptions/" + subscription.id + "/refund_credit?p=100"}
                            data-remote="true"
                            data-success="Credit 1 added"
                            data-update="update_subscription"
                          >
                            <input type="submit" value="Credit 1" onClick={this.submitPost} />

                          </div>
                        </span>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default AccountSubscriptionActions;