import React from "react";

class DontForgetItemSelection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_items: this.props.selected_items
    }

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event) {
    var state_copy = [...this.state.selected_items];
    const checked = event.target.checked;
    const value = parseInt(event.target.value);
    if (checked) {
      if (!state_copy.includes(value)) {
        state_copy.push(value);
      }
    } else {
      var index = state_copy.indexOf(value);
      if (index > -1) {
        state_copy.splice(index, 1);
      }
    }
    this.setState({selected_items: state_copy});
    this.props.onChecklistChange("dont_forget_item_ids", state_copy);
  }

  render() {
    const items = this.props.dont_forget_items.map((item) =>
      <div key={item.id}>
        <input 
          type="checkbox"
          value={item.id}
          checked={this.state.selected_items.includes(item.id)}
          onChange={this.handleOnChange} />
        {item.message}
      </div>
    )

    return (
      <>
        <div>
          {items}
        </div>
      </>
    )
  }
}

export default DontForgetItemSelection;