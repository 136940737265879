import React from "react";

import {convertDateString} from '../../../helpers/datestring_formatter'

class TicketBreakdown extends React.Component {
  constructor(props) {
    super(props);

    let total_timeslots = this.props.event_assignments.map((assignment) => assignment.timeslot);
    this.time_slots = [...new Set(total_timeslots)].sort();
    this.renderDate = this.renderDate.bind(this);
  }

  ticketCountFor(eventdate, time=null) {
    let count = 0;
    for (let assignment of this.props.event_assignments) {
      if (assignment.event_date_id == eventdate.id) {
        if (time == null || assignment.timeslot == time) {
          count += assignment.tickets;
        }
      }
    }
    return count;
  }

  renderDate(date_string) {
    const date = convertDateString(date_string);
    if (date) {
      const options = {month: 'long', day: 'numeric', year: 'numeric'}
      return date.toLocaleString('en-US', options);
    }
    return ""
  }

  renderDateHeaders() {
    return (this.props.event_dates.map((event_date) => 
      <th key={event_date.id}>{this.renderDate(event_date.date)}</th>
    ));
  }

  renderTimeRows() {
    let rows = (this.time_slots.map(timeslot =>
      <tr key={timeslot}>
        <td>{timeslot}</td>
        {this.renderTimeCells(timeslot)}
      </tr>
    ));

    rows.push(
      <tr key="total">
        <td>TOTAL:</td>
        {this.renderTimeCells(null)}
      </tr>
    );

    return rows
  }

  renderTimeCells(timeslot) {
    return (this.props.event_dates.map(event_date =>
      <td key={event_date.id}>{this.ticketCountFor(event_date, timeslot)}</td>
    ));
  }

  render() {
    return (
      <table className="pivot-table">
        <thead>
          <tr>
            <th>TIMESLOT</th>
            {this.renderDateHeaders()}
          </tr>
        </thead>
        <tbody>
          {this.renderTimeRows()}
        </tbody>
      </table>
    );
  }
}

export default TicketBreakdown