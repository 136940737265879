import FormSelect from "./form_select"

import React from "react";

class LocationPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show_only_areas: this.getAreasFromLocationIDs(this.props.location_ids)
    }

    this.getAreasFromLocationIDs = this.getAreasFromLocationIDs.bind(this);
    this.updateStateItem = this.updateStateItem.bind(this);
    this.renderSelectAllButtons = this.renderSelectAllButtons.bind(this);
  }

  getAreasFromLocationIDs(location_ids) {
    var locations = this.props.locations.filter(location => location_ids.includes(location.value));
    return [...new Set(locations.map(location => location.area_id))];
  }

  updateStateItem(name, value) {
    let update = { [name]: value };
    this.setState(update);
  }

  renderSelectAllButtons() {
    let valid_areas = this.props.areas.filter(area => this.state.show_only_areas.includes(area.id));

    return (valid_areas.map((area) =>
      <button
        key={area.id}
        onClick={this.props.selectAllLocations}
        data-area-id={area.id}>
        All {area.name}
      </button>
    ))
  }

  render() {
    let label_areas = this.props.areas.map(area => {
      return { label: area.name, value: area.id };
    });

    let valid_locations = this.props.locations.filter(location => {
      return this.state.show_only_areas.includes(location.area_id)
    });

    let valid_location_ids = valid_locations.map(loc => loc.value);

    let display_error = false;
    for (let location_id of this.props.location_ids) {
      if (!valid_location_ids.includes(location_id)) {
        display_error = true;
        break;
      }
    }

    return (
      <>
        <div className="location-picker-filter-container">
          <div className="picker-label">
            Only show locations from market(s):
          </div>
          <FormSelect
            options={label_areas}
            name="show_only_areas"
            className='large-input'
            multiselect={true}
            value={this.state.show_only_areas}
            onChange={this.updateStateItem} />
        </div>
        {display_error && 
          <div className="show-picker-error">
            There are currently locations selected for a market hidden by the filter.
          </div>
        }
        <FormSelect
          options={this.props.locations}
          renderOptions={valid_locations}
          name="location_ids"
          className={'large-input' + (display_error ? " p-error" : "")}
          multiselect={true}
          value={this.props.location_ids}
          onChange={this.props.updateStateItem} />
        <div className='small-spaced'>
          {this.renderSelectAllButtons()}
        </div>
      </>
    );
  }
}

export default LocationPicker