import React, { useState } from "react";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const StripePaymentElement = ({ redirect_url, button_class, subscription_terms_object = null, submit_event = null, button_id = null, use_payment_intent = false, customer_id = "", onSuccess = null }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();


    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (submit_event) {
      submit_event();
    }

    if (use_payment_intent) {
      let result;
      if (onSuccess) {
        result = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          redirect: 'if_required',
        });
      } else {
        result = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: redirect_url,
          },
        });
      }

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        // Check the error type and exclude specific errors if too many are being logged
        if (typeof(analytics_loaded) != 'undefined') {
          mixpanel.track("Signup Card Error", {
            "Error Message": result.error.message,
          });
          posthog.capture("Signup Card Error", {
            error_message: result.error.message
          });
        }
        setErrorMessage(result.error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        onSuccess(result.paymentIntent);
      }
    } else {
      const result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: redirect_url,
        },
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
        setErrorMessage(result.error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    }
  };

  const renderTermsAndButton = () => {

    return (
      <>
        <div className="subscription-terms">
          {subscription_terms_object &&
            <div>
              {subscription_terms_object.terms}
            </div>
          }
          <div>
            By signing up, you agree to our&nbsp;
            <a href="https://support.letsgetoffline.com/en/articles/7157053-offline-terms-of-service" target="blank">
              terms and conditions
            </a>
          </div>
        </div>
        <button id={button_id || "submit_step_3"} className={button_class} disabled={!stripe} onClick={handleSubmit}>{subscription_terms_object ? subscription_terms_object.payment_submit_text : "NEXT"}</button>
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {renderTermsAndButton()}
      {errorMessage && <div className='input-error'>{errorMessage}</div>}
    </form>

  )
};

export default StripePaymentElement