import React from "react";

class OfferCardMin extends React.Component {
  constructor(props) {
    super(props);

    this.card_ref = React.createRef();

    this.select_offer = this.select_offer.bind(this);
    this.send_card_info = this.send_card_info.bind(this);
    this.render_interested = this.render_interested.bind(this);
  }

  select_offer() {
    if (this.props.select_offer) {
      this.props.select_offer(this.props.offer);
    }
  }

  send_card_info() {
    if (this.props.open_offer_info) {
      this.props.open_offer_info(this.props.offer.id);
    }
  }

  render_fine_print_days(offer) {
    if (!offer) return (<></>);

    let day_indicators = offer.fine_print_days.map((fpd, i) =>
      <div className="day" key={`${offer.id}${i}`} data-lbl={fpd.state} >
        {fpd.name.substr(0, 1)}
      </div>
    )

    return (
      <div className="fine-print-days">
        {day_indicators}
      </div>
    );
  }

  render_interested() {
    let display_chosen = <></>;
    let display_interested = <></>;

    if (this.props.offer.total_interested > 0) {
      display_interested = (
        <div className="generic-interested">
          {/* <img className="generic-interested-icon" src={IconIntersted} alt="" /> */}
          <div className="simple-text">{this.props.offer.total_interested + " Interested Members"}</div>
        </div>
      )
    }

    if (this.props.offer.chosen_this_month > 0) {
      display_chosen = (
        <div className="generic-chosen">
          {/* <img className="generic-chosen-icon" src={IconChosen} alt="" /> */}
          <div className="simple-text">{this.props.offer.chosen_this_month + " Chosen this Month"}</div>
        </div>
      )
    }

    return (
      <>
        <div className="card-spacer"></div>
        <div className="generic-card-info-subtitles">
          {display_interested}
          {display_chosen}
        </div>
      </>
    )
  }

  render_card_inputs() {
    let content = (
      <>
        <div className="card-select-options">
          <button onClick={this.select_offer} className="offer-button-min choose-offer-button full">
            select this offer
          </button>
        </div>
      </>
    );
    if (this.props.is_selected) {
      content = (
        <>
          <div className="footer-message">
            <div>OFFER SELECTED</div>
          </div>
        </>
      );
    }

    return (
      <div className={"card-footer" + (this.props.is_selected ? " selected-footer" : "")}>
        {content}
      </div>
    )
  }

  render() {
    if (!this.props.offer) {
      return (<></>);
    }

    let img_url = this.props.offer.images[0]?.image;
    let instagram_url = this.props.offer.images[0]?.credit_link;

    let banner_media = <img className="card-header-image" src={img_url || ""} />;
    if (!this.props.disable_outlinks && instagram_url) {
      banner_media = (
        <a className="card-header-image" href={instagram_url} target="_blank">
          {banner_media}
        </a>
      )
    }

    let banner_msg = (<></>);
    return (
      <div ref={this.card_ref} data-num={this.props.index || 0} className={"signup-offer-card" + (this.props.is_selected ? " card-selected" : "")}>
        <div className="is-selected">
          <div className="selected-overlay"></div>
          <div className="image-container" ref={this.confetti_ref}>
            {/* <img className="selected-image" src={OfferSelected} /> */}
          </div>
        </div>
        {/* <div className={"already-selected-card" + (this.props.chosen ? "" : " hide-selected")}>
          <div className="stamp-container">
            <div className="stamp">
              ALREADY CONFIRMED
            </div>
          </div>
        </div> */}
        {banner_media}
        {banner_msg}
        <div className="spending-banner">
          <div className="max-two-text-lines w-side-pad">
            {this.props.offer?.offer_amount || ""}
          </div>
        </div>
        <div className="card-body">
          <div className="signup-offer-card-title max-two-text-lines">{this.props.offer.name}</div>
          <div className="offer-metric">
            Is Trending:
            <div className="metric-value">{this.props.offer.trending ? "Yes" : "No"}</div>
          </div>
          <div className="offer-metric">
            Is Popular:
            <div className="metric-value">{this.props.offer.popular ? "Yes" : "No"}</div>
          </div>
          <div className="offer-metric">
            Popularity Score:
            <div className="metric-value">{this.props.offer.popular_score || 0}</div>
          </div>
          <div className="offer-metric">
            Assigned This Month:
            <div className="metric-value">{this.props.offer.trending_score || 0}</div>
          </div>
          <div className="offer-metric">
            Max Capcity:
            <div className="metric-value">{this.props.offer.max_capacity || 0}</div>
          </div>

          <div className="offer-metric">
            Sold Out:
            <div className="metric-value">{this.props.offer.sold_out ? "Yes" : "No"}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default OfferCardMin;