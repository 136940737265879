import React from 'react';
import CityLogo from 'logo-city.png'
import FooterSkyline from 'footer-raleigh-skyline.svg'
import BackIcon from 'back-icon.svg';
import PaymentSummary from './payment_summary';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripePaymentElement from '../member_app/sign_up/stripe_payment_element';

class GiftCodeNew extends React.Component {
  constructor(props) {
    super(props);
    this.stripePromise = loadStripe(this.props.stripe_key);

    this.months = [3, 6, 12];
    this.state = {
      intended_first_name: '',
      intended_last_name: '',
      intended_email: '',
      selected_number_of_months: 12,
      selected_area: this.props.base_areas[this.props.base_areas.length - 1],
      send_immediately: false,
      gift_message: '',
      buyers_first_name: '',
      buyers_last_name: '',
      buyers_email: '',
      validating: false,
      customer_id: null,
      payment_intent: null,
      step: 'billing_info',
      payment_method: 'credit_card',
      errors: {},
    };

    this.continue = this.continue.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectArea = this.selectArea.bind(this);
    this.onPaymentSuccess = this.onPaymentSuccess.bind(this);
  }

  onChange(e) {
    if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  selectArea(e) {
    var { base_areas } = this.props;
    var selected_area = base_areas.find(area => area.id === parseInt(e.target.value));
    this.setState({ selected_area });
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  validateParams() {
    var errors = {};
    let isValid = true;

    const fields = ["intended_first_name", "intended_last_name", "intended_email", "buyers_first_name", "buyers_last_name", "buyers_email"];

    fields.forEach((field) => {
      if (!this.state[field]) {
        errors[field] = "Required";
        isValid = false;
      } else {
        errors[field] = null;
      }
    });

    if (this.state.intended_email !== "" && !this.validateEmail(this.state.intended_email)) {
      errors["intended_email"] = "Email address is invalid"
      isValid = false;
    }

    if (this.state.buyers_email !== "" && !this.validateEmail(this.state.buyers_email)) {
      errors["buyers_email"] = "Email address is invalid";
      isValid = false;
    }

    this.setState({ errors });
    return isValid;
  }

  continue() {
    if (this.validateParams()) {
      var {
        intended_first_name,
        intended_last_name,
        intended_email,
        send_immediately,
        gift_message,
        buyers_first_name,
        buyers_last_name,
        buyers_email,
        customer_id,
        payment_intent,
        selected_number_of_months,
        selected_area,
      } = this.state;

      this.setState({ validating: true });
      $.ajax({
        method: "POST",
        url: "/gift-validate",
        data: {
          gift_code: {
            intended_first_name,
            intended_last_name,
            intended_email,
            send_immediately,
            gift_message,
            buyers_first_name,
            buyers_last_name,
            buyers_email,
            selected_number_of_months,
            selected_area_id: selected_area.id,
            purchaser_stripe_id: customer_id,
            payment_intent_id: payment_intent?.id,
          },
        },
        success: (data) => {
          this.setState({ customer_id: data.customer_id, payment_intent: data.payment_intent, step: 'payment', validating: false });
        },
        error: (error) => {
          console.log(error)
          this.setState({ validating: false });
        }
      });
    }
  }

  onPaymentSuccess(payment_intent) {
    console.log(payment_intent);
    var {
      intended_first_name,
      intended_last_name,
      intended_email,
      send_immediately,
      gift_message,
      buyers_first_name,
      buyers_last_name,
      buyers_email,
      customer_id,
      selected_number_of_months,
      selected_area,
    } = this.state;

    $.ajax({
      method: "POST",
      url: "/gift",
      data: {
        gift_code: {
          intended_first_name,
          intended_last_name,
          intended_email,
          send_immediately,
          gift_message,
          buyers_first_name,
          buyers_last_name,
          buyers_email,
          selected_number_of_months,
          selected_area_id: selected_area.id,
          purchaser_stripe_id: customer_id,
          payment_intent_id: payment_intent.id,
          amount: payment_intent.amount,
        },
      },
      success: (data) => {
        window.location.href = data.redirect_url;
      },
      error: (error) => {
        console.log(error)
      }
    });
  }

  render() {
    var { base_areas } = this.props;
    var {
      intended_first_name,
      intended_last_name,
      intended_email,
      selected_area,
      selected_number_of_months,
      send_immediately,
      buyers_first_name,
      buyers_last_name,
      buyers_email,
      payment_intent,
      step,
      errors,
      validating,
      payment_method,
      gift_message,
      send_immediately,
    } = this.state;
    var working_price = (selected_area.monthly_subscription_price / 100).toFixed(2) * selected_number_of_months;
    if (parseInt(selected_number_of_months) === 12) {
      working_price = (selected_area.yearly_subscription_price / 100).toFixed(2);
    }

    return (
      <section className='signup text-center'>
        <div className='header'>
          <img className='signup__market-logo' alt="Offline" src={CityLogo} />
        </div>
        <div className='signup__body'>
          <h1 className="font-italic"><strong>Gift a membership</strong></h1>

          <div className="narrow__form text-left" id='gift-form'>
            <div className='narrow__form-body'>
              {step === 'billing_info' &&
                <>
                  <p className='font-italic'>Select from 3, 6, or 12 month options. We'll do the rest.</p>
                  <h3 className='font-heavy margin__top'>Who is this gift for?</h3>
                  <div className='form-row'>
                    <div className={`form-item ${errors.intended_first_name ? 'error' : ''}`}>
                      <div className="input--label">First Name</div>
                      <input type="text" className='input' name="intended_first_name" value={intended_first_name} onChange={this.onChange} />
                      <div className='error-message'>{errors.intended_first_name}</div>
                    </div>
                    <div className={`form-item ${errors.intended_last_name ? 'error' : ''}`}>
                      <div className="input--label">Last Name</div>
                      <input type="text" className='input' name="intended_last_name" value={intended_last_name} onChange={this.onChange} />
                      <div className='error-message'>{errors.intended_last_name}</div>
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className={`form-item ${errors.intended_email ? 'error' : ''}`}>
                      <div className="input--label">Email Address</div>
                      <input type="email" className='input' name="intended_email" value={intended_email} onChange={this.onChange} />
                      <div className='error-message'>{errors.intended_email}</div>
                    </div>
                  </div>

                  <h3 className='font-heavy margin__top'>Choose your gift options</h3>
                  <p>What city will they sign up for?</p>
                  {base_areas.map(area => (
                    <div key={area.id}>
                      <input type="radio" name="area" id={`area${area.id}`} value={area.id} onChange={this.selectArea} checked={area.id === selected_area.id} />
                      <label htmlFor={`area${area.id}`}>{area.name}</label><br />
                    </div>
                  ))}
                  <hr />

                  <p>How many months would you like to buy?</p>
                  {this.months.map(month => (
                    <div key={month}>
                      <input type="radio" name="selected_number_of_months" id={`selectedMonth${month}`} value={month} onChange={this.onChange} checked={month === parseInt(selected_number_of_months)} />
                      <label htmlFor={`selectedMonth${month}`}>{month} months</label><br />
                    </div>
                  ))}
                  <hr /><br />

                  <input type="checkbox" name="send_immediately" id="send_immediately" checked={send_immediately} onChange={this.onChange} />
                  <label htmlFor="send_immediately">&nbsp;Notify {intended_first_name} {intended_last_name} immediately?</label>
                  {send_immediately &&
                    <div id='gift_code_gift_message-wrapper' >
                      <label htmlFor="gift_message">Your message:</label>
                      <textarea style={{ width: '100%', border: '0.5px solid #979797', borderRadius: '5px' }} name="gift_message" rows="4" onChange={this.onChange} value={gift_message}></textarea>
                    </div>
                  }

                  <PaymentSummary
                    month={selected_number_of_months}
                    area={selected_area.name}
                    price={working_price}
                  />

                  <p className='font-italic'>We'll send the gift recipient a specific link to sign up. If they opt to change their location or add months on, we'll automatically apply your gift amount towards their payment(s).</p>

                  <h3 className='font-heavy margin__top'>Billing Information</h3>
                  <div className='form-row'>
                    <div className={`form-item ${errors.buyers_first_name ? 'error' : ''}`}>
                      <div className="input--label">First Name</div>
                      <input type="text" className='input' name="buyers_first_name" value={buyers_first_name} onChange={this.onChange} />
                      <div className='error-message'>{errors.buyers_first_name}</div>
                    </div>
                    <div className={`form-item ${errors.buyers_last_name ? 'error' : ''}`}>
                      <div className="input--label">Last Name</div>
                      <input type="text" className='input' name="buyers_last_name" value={buyers_last_name} onChange={this.onChange} />
                      <div className='error-message'>{errors.buyers_last_name}</div>
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className={`form-item ${errors.buyers_email ? 'error' : ''}`}>
                      <div className="input--label">Email Address</div>
                      <input type="email" className='input' name="buyers_email" value={buyers_email} onChange={this.onChange} />
                      <div className='error-message'>{errors.buyers_email}</div>
                    </div>
                  </div>

                  <button
                    className="form-btn btn btn__primary margin__top-small margin__bottom form-submit"
                    onClick={this.continue}
                    disabled={validating}
                  >
                    CONTINUE TO CHECKOUT
                  </button>
                </>
              }
              {step === 'payment' &&
                <div>
                  <div className="back-icon" onClick={() => this.setState({ step: 'billing_info' })}>
                    <img src={BackIcon} />
                  </div>
                  <PaymentSummary
                    month={selected_number_of_months}
                    area={selected_area.name}
                    price={working_price}
                  />
                  <br />
                  <div className='payment-methods'>
                    <div>Choose How to Pay</div>
                    <div>Select a payment method below</div>
                  </div>

                  {payment_intent && payment_method === 'credit_card' &&
                    <Elements stripe={this.stripePromise} options={{ clientSecret: payment_intent.client_secret }}>
                      <StripePaymentElement
                        use_payment_intent={true}
                        button_class={"form-btn btn btn__primary margin__top-small margin__bottom form-submit"}
                        subscription_terms_object={{ payment_submit_text: "PURCHASE" }}
                        onSuccess={this.onPaymentSuccess} />
                    </Elements>
                  }
                </div>
              }
            </div>
          </div>
        </div>
        <div className="signup__footer">
          <img src={FooterSkyline} alt='Offline Loves Your City' />
        </div>
      </section >
    );
  }
};

export default GiftCodeNew;