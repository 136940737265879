import React from "react";
import RecapBanner from 'icon-recap.svg'

class RecapSection extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className="signup-bubble-sections recap-section">
        <div className="bubble-title">Quick Recap</div>
        <div className="recap-columns">
          <img src={RecapBanner} alt="" />
          <div className="recap-column">
            <div className="recap-item">
              <div className="recap-heading">You pick your first restaurants today!</div>
              <div className="recap-body">Choose 2 spots from a list of curated locally-owned restaurants + visit within 30 days. Credits <span className="recap-warning">expire</span>, so better get off your couch!</div>
            </div>
            <div className="recap-item">
              <div className="recap-heading">Support local</div>
              <div className="recap-body">Explore and uplift 300+ locally-owned gems in your city.</div>
            </div>
            <div className="recap-item">
              <div className="recap-heading">We'll start you off with $10-$30 at each spot</div>
              <div className="recap-body">Meet up w friends + let Offline cover part of the check.</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RecapSection