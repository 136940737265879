import React from "react";
import LocationFlag from "flag.svg";
import OfferSelected from "offer-selected.svg";
import CheckMark from "icon-checkmark.svg";
import MinLinkedGroup from 'icon-min-linked-group.svg';
import IconTooltip from 'icon-tooltip-white.svg';
import IconFinePrint from 'icon-fine-print-message.svg';
import IconTooltipBlack from 'icon-tooltip.svg';
import IconInvited from 'icon-invite.svg';
import IconPicked from 'icon-slider-checked.svg';
import IconSolidHeart from 'icon-solid-heart.svg';
import IconDetails from 'icon-right-circle.svg';

class InvitationCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      video_enabled: false,
      video_loaded: false,
      show_message: false,
      show_tooltip: false,
      show_more_invited: false
    }

    this.select_card = this.select_card.bind(this);
    this.showMoreInvited = this.showMoreInvited.bind(this);
    this.showMorePicked = this.showMorePicked.bind(this);
    this.declineInvite = this.declineInvite.bind(this);

    this.confetti_ref = React.createRef();
    this.card_ref = React.createRef();
  }

  componentDidUpdate() {
  }

  select_card() {
    this.props.on_select(this.props.invite_id, this.props.offer.id);
  }

  showMoreInvited(e) {
    if (this.props.invited_members.length < 3) return false;
    this.setState({ show_more_invited: !this.state.show_more_invited, show_tooltip: false, show_more_picked: false });
  }

  showMorePicked(e) {
    if (this.props.offer.picked_this_month.length < 3) return false;
    this.setState({ show_more_picked: !this.state.show_more_picked, show_tooltip: false, show_more_invited: false });
  }

  render_fine_print_days(offer) {
    if (!offer) return (<></>);

    let day_indicators = offer.fine_print_days.map((fpd, i) =>
      <div className="day" key={`${offer.id}${i}`} data-lbl={fpd.state} >{fpd.name.substr(0, 1)}</div>
    )

    return (
      <div className="fine-print-days" >
        {day_indicators}
        <img src={IconTooltipBlack} alt="" />
        <div className={"fine-print-tooltip" + (this.state.show_tooltip ? " display-tooltip" : "")}>
          <img src={IconFinePrint} alt="" />
          <div className="fine-print-tooltip-text">{"Red = unable to use the Offer on that day\nYellow = valid that day, but there are time restrictions\nGreen = valid any time the restaurant is open that day"}</div>
        </div>
      </div>
    );
  }

  // addInterestToOffer() {
  //   $.ajax({
  //     method: "POST",
  //     url: "/interests",
  //     dataType: 'json',
  //     data: { 
  //       token: this.props.token, 
  //       offer_id: this.props.offer.id 
  //     },
  //     success: (data) => {
  //       console.log(data);
  //       this.props.updateOfferData(data);
  //     }
  //   });
  // }

  declineInvite() {
    $.ajax({
      method: "DELETE",
      url: "/inbound_invitations/destroy",
      dataType: 'json',
      data: { 
        token: this.props.token, 
        inbound_invitation_id: this.props.invite_id 
      },
      success: (data) => {
        this.props.onDecline(this.props.invite_id);
      }
    });
  }

  renderPicked(offer) {
    if (offer.picked_this_month.length == 0) return (<></>);

    let ordered_picks = offer.picked_this_month.sort(((a,b) => a.id == this.props.account_id ? -1 : (a.id - b.id)));
    let all_picked = ordered_picks.map(account => account.first_name +" "+ account.last_name).join("\n");

    let first_picked = ordered_picks.slice(0,2);
    let first_picked_string = first_picked.map(account =>
      (account.id == this.props.account_id ? "You" : (account.first_name +" "+ account.last_name.substr(0,1)))
    ).join(", ");

    let more_picked = offer.picked_this_month.slice(2).length
    let more_picked_string = more_picked > 0 ? `, +${more_picked} more` : "";

    return (
      <div className="friend-subtitle" onClick={this.showMorePicked}>
        <img src={IconPicked} alt="" />
        <span>Chosen by <b>{first_picked_string}{more_picked_string}</b></span>
        <div className={"general-tooltip" + (this.state.show_more_picked ? " display-tooltip" : "")}>
          <div className="tooltip-content">{all_picked}</div>
        </div>
      </div>
    );
  }

  render_invited_list() {
    let ordered_invites = this.props.invited_members.sort(((a,b) => a.id == this.props.account_id ? -1 : (a.id - b.id)));
    let all_invites = ordered_invites.map(account => account.first_name +" "+ account.last_name).join("\n");

    let first_invited = ordered_invites.slice(0,2);
    let first_invited_string = first_invited.map(account =>
      (account.id == this.props.account_id ? "You" : (account.first_name +" "+ account.last_name.substr(0,1)))
    ).join(", ");

    let more_invited = this.props.invited_members.slice(2).length
    let more_invited_string = more_invited > 0 ? `, +${more_invited} more` : "";

    let inviting_member = this.props.invited_by.first_name + " " + this.props.invited_by.last_name.substr(0,1);

    return (
      <div className="friend-subtitle" onClick={this.showMoreInvited}>
        <img src={IconInvited} alt="" />
        <span><b>{inviting_member}</b> Invited <b>{first_invited_string}{more_invited_string}</b></span>
        <div className={"general-tooltip" + (this.state.show_more_invited ? " display-tooltip" : "")}>
          <div className="tooltip-content">{all_invites}</div>
        </div>
      </div>
    );
  }

  render_card_buttons() {
    let content = (
      <>
        {this.renderPicked(this.props.offer)}
        {this.render_invited_list()}
        <div className="card-select-options">
          <button onClick={this.select_card} className="offer-button-min choose-offer-button">
            Accept
          </button>
          <button onClick={this.declineInvite} className="offer-button-min decline-button">
            Decline
          </button>
        </div>
      </>
    );
    if (this.props.is_selected) {
      content = (
        <>
          <div className="footer-message">
            <img src={CheckMark} />
            <div>INVITE ACCEPTED!</div>
          </div>
        </>
      );
    }

    return (
      <div className={"card-footer" + (this.props.is_selected ? " selected-footer" : "")}>
        {content}
      </div>
    )
  }


  render() {
    if (!this.props.offer) {
      return (<></>);
    }

    let img_url = this.props.offer.images[0]?.image;
    let instagram_url = this.props.offer.images[0]?.credit_link;

    let banner_media = <img className="card-header-image" src={img_url || ""} />;
    if (instagram_url) {
      banner_media = (
        <a className="card-header-image" href={instagram_url} ref={this.banner_media_ref} target="_blank">
          {banner_media}
        </a>
      )
    }
    return (
      <div ref={this.card_ref} data-num={this.props.index} className={"offer-card full-height" + (this.props.is_selected ? " card-selected" : "")}>
        <div className="is-selected">
          <div className="selected-overlay"></div>
          <div className="image-container" ref={this.confetti_ref}>
            <img className="selected-image" src={OfferSelected} />
          </div>
        </div>
        <div className={"already-selected-card" + (this.props.chosen ? "" : " hide-selected")}>
          <div className="stamp-container">
            <div className="stamp">
              ALREADY CONFIRMED
            </div>
          </div>
        </div>
        {banner_media}
        <div className="spending-banner">
          <div className="max-two-text-lines w-side-pad">
            {this.props.offer?.offer_amount || ""}
          </div>
        </div>
        <div className="text-area relative">
          <div className="location-subtitle">
            <img src={LocationFlag} />
            {this.props.offer?.location || ""}
          </div>
          <div className="more-details" >
            <img src={IconDetails} alt="" />
          </div>
          <div className="offer-card-title max-two-text-lines">{this.props.offer.name}</div>
          <div className="business-teaser">
            <div className="max-two-text-lines">
              {this.props.offer?.business_teaser || ""}
            </div>
          </div>
          {this.render_fine_print_days(this.props.offer)}
        </div>
        {this.render_card_buttons()}
      </div>
    );
  }
}

export default InvitationCard