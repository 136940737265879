import React from "react";
import OfferCardsIcon from 'icon-offer-cards.svg';
import PresentIcon from 'icon-offer-present.svg';
import moment from "moment-timezone";
import OfferNumberedList from "./offer_numbered_list";

class OfferLoadingInterstitial extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let month = moment().format("MMMM");
    return (
      <div className="loading-interstitial-container">
        <div className="int-title-container">
          <div className="offers-title interstitial">{`You're about to pick both of your ${month} Offers`}</div>
          <div className="offers-subtitle interstitial">A few things to keep in mind:</div>
        </div>
        <OfferNumberedList>
          <div className="interstitial-bullet-point">
            <div className="interstitial-bullet-point-bold">
              Offer Invites come first:
            </div>
            <div className="interstitial-bullet-point-text">
              Accept or decline Offer Invites from Friends first. You'll both get the same Offer if you accept.
            </div>
          </div>
          <div className="interstitial-bullet-point">
            <div className="interstitial-bullet-point-bold">
              {`If you run out of time to pick:`}
            </div>
            <div className="interstitial-bullet-point-text">
              We'll choose two Offers that you've shown interest in, or automatically accept any Offer Invites from your Friends.
            </div>
          </div>
          <div className="interstitial-bullet-point">
            <div className="interstitial-bullet-point-bold">
              Send an Offer Invite:
            </div>
            <div className="interstitial-bullet-point-text">
              Found a great Offer? Send an Offer Invite to Friends who haven't picked it yet and try it together.
            </div>
          </div>
        </OfferNumberedList>
        <a href="https://support.letsgetoffline.com/en/articles/7435936-what-is-offer-day" target="_blank" className="interstitial-link">
          More about Offer Day
        </a>
        <img className="faux-card-icon" src={OfferCardsIcon} />
      </div>
    );
  }
}

export default OfferLoadingInterstitial