import React from "react";

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.percentComplete = this.percentComplete.bind(this);
  }

  percentComplete() {
    var percent = ((this.props.step * 100 / this.props.total_steps))
    if (percent > 100) {
      percent = 100;
    }
    return percent + '%'
  }

  progressClassStatus() {
    let status = 'progress-bar'
    if (this.props.step >= this.props.total_steps) {
      status += ' progress-bar-complete'
    }
    return status;
  }

  render() {
    return (
      <div className='progress-bar-wrapper'>
        <div className={this.progressClassStatus()} style={{ width: this.percentComplete() }}></div>
      </div>
    );
  }
}

export default ProgressBar