import React from "react";

class BarGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      include_animation: this.props.include_animation || false
    }

    this.renderGraph = this.renderGraph.bind(this);
    this.renderGraphLegends = this.renderGraphLegends.bind(this);
    this.svg_container_ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.include_animation) {
      setTimeout(() => {
        this.setState({ include_animation: false });
      }, 20);
    }
  }

  format_value(value) {
    return value + "%";
  }

  renderGraph() {
    if (!this.props.graphs || this.props.graphs.length == 0) return (<></>);

    // let total = this.props.graphs.map(graph => graph.value).reduce((a, b) => a + b, 0);
    return this.props.graphs.map((graph, i) => {
      let total = graph.max || 100;
      let percent = Math.round((100 * graph.value / total)) + "%";
      let label = `"${percent}"`
      if (graph.value_as_label) {
        label = (graph.legend_formatter ? graph.legend_formatter(graph.value) : this.format_value(graph.value))
        label = `"${label}"`
      }
      return (
        <div className={"graph-bar " + graph.class_color} key={'gb' + i} style={{ "--percent-value": percent, "--percent-label": label }}></div>
      )
    });
  }

  renderGraphLegends() {
    return this.props.graphs.map((graph, index) => (
      <div className="legend-row" key={'l' + index}>
        <div className="legend-label">
          <div className={"swatch " + graph.class_color}></div>
          <div className="legend-text">{graph.label}</div>
        </div>
        <div className="legend-value">{(graph.legend_formatter ? graph.legend_formatter(graph.value) : this.format_value(graph.value))}</div>
      </div>
    ));
  }

  render() {
    if (!this.props.graphs || this.props.graphs.length == 0) return (<></>);

    return (
      <div className="bar-graph-container">
        <div className="bar-graph-title">{this.props.graph_title}</div>
        <div ref={this.svg_container_ref} className={"bar-container" + (this.state.include_animation ? " include-animation" : "")}>
          {this.renderGraph()}
        </div>
        <div className="graph-legends">
          {this.renderGraphLegends()}
        </div>
      </div>
    )
  }
}

export default BarGraph;