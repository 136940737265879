import React from "react";

class OfferModal extends React.Component {
  constructor(props) {
    super(props);

    this.container_ref = React.createRef();

    this.check_click_outside = this.check_click_outside.bind(this);
  }

  check_click_outside(e) {
    if (this.container_ref.current && !this.container_ref.current.contains(e.target)) {
      this.props.close_modal();
    }
  }

  render() {

    return (
      <>
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            display: "flex",
            top: 0,
            left: 0,
            alignItems: "center",
            justifyContent: "center",
            zIndex: 25
          }}
          onClick={this.check_click_outside}
        >
          <div
            style={{
              width: "50%",
              height: "80%",
              background: "#ffffff",
              border: "solid 2px #777",
              borderRadius: 4,
              zIndex: 28,
              padding: "30px 40px",
              boxShadow: "1px 1px 8px 1px #00000040",
              overflow: "auto",
              position: "relative"
            }}
            ref={this.container_ref}
          >
            <div
              style={{
                position: "absolute",
                fontSize: "70px",
                lineHeight: "40px",
                top: "19px",
                right: "11px",
                color: "#333",
                fontWeight: 300
              }}
              onClick={this.props.close_modal}
            >
              ×
            </div>
            <div>
              <h2>Offer Slot #1</h2>
              <br />
              <b>Valid Offers:</b>
              <ul style={{ marginTop: 0 }}>
                {this.props.all_valid_offers.map((offer) => {
                  return (
                    <li className="offer-id-914" style={{}}>
                      {`(${offer.trending_score}/${offer.max_capacity}) ${offer.name} (`}
                      <a
                        data-remote="true"
                        href={`/offer_assignments/get_created_by_info?url=%2Foffer_assignments%2Fcreate_for_assignment_slot%3Fassignment_slot_id%3D${this.props.assignment_slot.id}%26offer_id%3D${offer.id}`}
                      >
                        assign
                      </a>
                      {")"}
                    </li>
                  )
                })}
              </ul>
              <b>Already assigned but not used:</b>
              <button
                style={{ marginLeft: "0.5em" }}
                onclick="copyLinksToClipboard(this)"
              >
                Copy Offer Links
              </button>
              <ul style={{ marginTop: "1em" }}></ul>
            </div>
          </div>
        </div>
      </>

    );
  }
}

export default OfferModal;