import React from "react";

import WhenToStartInput from "../shared/account_inputs/when_to_start_input";
import ContinueButton from "../shared/continue_button";
import BackButton from "../shared/back_button";
import { getIDforGTM } from "../v2/state_components/gtm_methods";
import { validatePhoneNumber } from "../utils/account_validations";
import WrappingTooltip from "../shared/wrapping-tooltip";

class SignupV4Subscription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      when_to_start: this.props.when_to_start || "today"
    }
    this.onInputChange = this.onInputChange.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value || "",
      ["error_"+event.target.name]: ''
    });
  }

  submitData() {
    this.props.onSubmit({when_to_start: this.state.when_to_start}, true , this.props.cioStep || 0);
  }

  render() {
    return (
      <div className="signup-v4-subscription">
        <WhenToStartInput
          when_to_start={this.state.when_to_start}
          selected_area={this.props.selected_area}
          selected_plan={this.props.selected_plan}
          referral_details={this.props.referral_details}
          changeInputField={this.onInputChange} />
        <WrappingTooltip
          tooltip_text="You can sign up mid-month, but that means you'll have only the rest of the current month to enjoy your first picks. Starting on the 1st of next month and you'll get the entire month to visit your picks."
          open_upwards={true}
          bottom_offset={30}
          max_width={200}>
          <div className="learn-more-text">
            <span>Learn more about why we ask this.</span>
          </div>
        </WrappingTooltip>
        <ContinueButton
          onPress={this.submitData}
          gtm_id={getIDforGTM('continue-to-next-screen', this.props)} />
        <BackButton
          onPress={this.props.onBack}
          gtm_id={getIDforGTM('back-to-previous-screen', this.props)}
        />
      </div>
    );
  }
}

export default SignupV4Subscription