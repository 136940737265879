import React from "react";
import CustomerReviews from "./customer_reviews";
import BarGraph from "../admin_app/shared/bar_graph";

class AboveFourStars extends React.Component {
  constructor(props) {
    super(props);

    this.getGraphData = this.getGraphData.bind(this);
  }

  survey_answer_boolean(value) {
    if (value == "yes") return true;
    if (value == "no") return false;
    return value;
  }

  broughtNewPeople(offer_assignment) {
    return offer_assignment.went_with_non_member != null ? offer_assignment.went_with_non_member : this.survey_answer_boolean(offer_assignment.survey_answers?.went_with_non_premium);
  }

  beenInPriorMonths(offer_assignment) {
    return offer_assignment.been_in_prior_months != null ? offer_assignment.been_in_prior_months : this.survey_answer_boolean(offer_assignment.survey_answers?.has_been_before);
  }

  willGoAgain(offer_assignment) {
    return offer_assignment.will_go_again != null ? offer_assignment.will_go_again : this.survey_answer_boolean(offer_assignment.survey_answers?.will_go_again);
  }

  getGraphData() {
    let percent_brought_non_offline_friends = this.props.brought_non_offline_friends;
    let percent_repeat_customers = this.props.new_customers;
    let percent_will_go_again = this.props.would_go_again;
    let graphs = [];

    graphs.push({
      label: "% Brought Non-Offline Friends",
      value: percent_brought_non_offline_friends,
      class_color: "blue"
    });

    graphs.push({
      label: "% New Customers",
      value: percent_repeat_customers,
      class_color: "teal"
    });

    graphs.push({
      label: "% Would Return",
      value: percent_will_go_again,
      class_color: "yellow"
    });

    return graphs;
  }

  render() {
    if(this.props.offer_assignments.length == 0) return (<></>);
    
    return (
      <div className="partner-report-above-four-stars">
        <div className="above-four-stars-title">
          4 Stars & Above Ratings Breakdown
        </div>
        <div className="above-four-stars-container">
          <BarGraph
            graph_title="Reviewer Stats"
            graphs={this.getGraphData()}
          />
          <CustomerReviews
            offer_assignment={this.props.offer_assignments}
            highly_reviewed={true}
          />
        </div>
      </div>
    )
  }
}

export default AboveFourStars