import React from "react";
import { convertIntegerToCleanPrice } from '../../helpers/price_formatter';

class CostBox extends React.Component {
  constructor(props) {
    super(props);

    this.getCostBoxMessaging = this.getCostBoxMessaging.bind(this);
  }

  getCostBoxMessaging(referral, membership_start, selected_plan, area) {
    let referral_type = referral && referral.hasOwnProperty("gift_code") ? "gift_code" : "referral_code";
    let cost_box_info = {
      main_text: "",
      due_today: "",
      show: false
    }

    let source_cost = selected_plan == "monthly" ? area.monthly_subscription_price : area.yearly_subscription_price;

    if (referral_type == "gift_code") {

      // Do not show cost box if deferring
      // on a gift code
      if (membership_start == "defer") {
        return cost_box_info;
      }

      let is_sale_running = (area["has_active_annual_sale?"] || false) && selected_plan == "annual";
      let discount = referral.amount + (is_sale_running ? area.annual_sale_discount : 0);

      cost_box_info.main_text = "Gift Code Applied";
      cost_box_info.due_today = `$${convertIntegerToCleanPrice(Math.max(source_cost - discount, 0))} due today`;
      cost_box_info.show = true;

      return cost_box_info;
    }

    if (!referral || referral.months_discounted == 0 || referral.percent_discounted == 0) {
      if (membership_start == "defer") {
        return cost_box_info;
      }

      let is_sale_running = (area["has_active_annual_sale?"] || false) && selected_plan == "annual";

      source_cost = is_sale_running ? (source_cost - area.annual_sale_discount) : source_cost;

      cost_box_info.main_text = selected_plan == "monthly" ? "Monthly" : "Annually";
      cost_box_info.due_today = `$${convertIntegerToCleanPrice(source_cost)} due today`;
      cost_box_info.show = true;
      return cost_box_info;
    }

    // Do not show cost box if no discount if the
    // user is deferring into membership with no
    // trial period
    if (referral.percent_discounted < 100 && membership_start == "defer") {
      return cost_box_info;
    }

    if (referral.percent_discounted == 100) {
      let months = referral.months_discounted > 1 ? " Months " : " Month ";

      cost_box_info.main_text = referral.months_discounted + months + "Free";
      cost_box_info.due_today = "$0 due today";
      cost_box_info.show = true;
    } else {
      let months = referral.months_discounted > 1 ? " Months " : " Month ";
      let discount = selected_plan == "monthly" ?
        source_cost * (referral.percent_discounted / 100.0)
        : (source_cost / 12) * (referral.percent_discounted / 100.0) * referral.months_discounted

      let cost = convertIntegerToCleanPrice(source_cost - discount);

      cost_box_info.main_text = referral.months_discounted
        + months
        + referral.percent_discounted
        + "% off";

      cost_box_info.due_today = `$${cost} due today`;
      cost_box_info.show = true;
    }

    return cost_box_info;
  }

  render() {
    let { main_text, due_today, show } = this.getCostBoxMessaging(this.props.referral_details,
      this.props.membership_start,
      this.props.selected_plan,
      this.props.selected_area)

    if (!show) {
      return (<></>);
    }

    return (
      <div className="sign-up-cost-box">
        <div className="cost">{main_text}</div>
        <div className="due-today">{due_today}</div>
      </div>
    );
  }
}

export default CostBox