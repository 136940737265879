import React from "react";

import WhenToStartInput from "../shared/account_inputs/when_to_start_input";
import ContinueButton from "../shared/continue_button";
import BackButton from "../shared/back_button";
import { getIDforGTM } from "../v2/state_components/gtm_methods";
import { validatePhoneNumber } from "../utils/account_validations";
import { convertDateString } from '../../../helpers/datestring_formatter'
import WrappingTooltip from "../shared/wrapping-tooltip";

class SignupV5SubscriptionStartSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event) {
    this.props.onSubmit({when_to_start: event.target.value || "today"}, false);
  }

  rederDeferralText() {
    
    let future_date = this.getDeferralStartMonth(1)
    if (this.props.when_to_start == "today") {
      let last_day = new Date(future_date.getFullYear(), future_date.getMonth(), 0);
      return (
        <div className="when-to-start-input">
          <div className="sign-up-list-container spacer">
            <span>You'll pick your first restaurants today and have until {this.dateGetMonth(last_day)} {this.dateGetOrdinal(last_day)} to use them. We'll send you a a reminder 7 days before your trial ends. </span>
            <span className="learn-more-text" style={{textDecoration: 'underline'}}>
              <span className='underline'>Why do we ask this?</span>
            </span>
          </div>
        </div>
      )
    } else {
      return (
        <div className="when-to-start-input">
          <div className="sign-up-list-container spacer">
            <span>Your trial will begin on {this.dateToLabel(future_date)} (You'll pick your first restaurants that day). We'll send you a a reminder 7 days before your trial ends. </span>
            <span className="learn-more-text" style={{textDecoration: 'underline'}}>
              <span className='underline'>Why do we ask this?</span>
            </span>
          </div>
        </div>
      )
    }
  }

  getDeferralStartMonth(itter = 1) {
    const current_date = new Date();

    let current_month = current_date.getMonth();
    let next_month = current_month + itter;
    let year = current_date.getFullYear();

    while (next_month > 11) {
      next_month = next_month - 12;
      year++;
    }

    // If this market has a launch date in the future, members will defer to the start date
    if (this.props.selected_area && this.props.selected_area.start_date) {
      var converted_date = convertDateString(this.props.selected_area.start_date);
      if (converted_date > new Date()) {
        next_month = converted_date.getMonth();
        year = converted_date.getFullYear();
      }
    }
    return new Date(year, next_month, 1)
  }

  dateToLabel(date) {
    return `${this.dateGetMonth(date)} ${this.dateGetOrdinal(date)}, ${date.getFullYear()}`;
  }

  dateGetMonth(date) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[date.getMonth()];
  }

  dateGetOrdinal(date) {
    const ordinals = ["th", "st", "nd", "rd", "th"];
    let day = date.getDate() % 10;
    if (day > 4) {
      day = 4;
    }
    return `${date.getDate()}${ordinals[day]}`;
  }

  render() {
    return (
      <div className="signup-v5-subscription">
        <WhenToStartInput
          when_to_start={this.props.when_to_start}
          selected_area={this.props.selected_area}
          referral_details={this.props.referral_details}
          changeInputField={this.onInputChange}
          skip_text={true} />
        <WrappingTooltip
          tooltip_text="You can sign up mid-month, but that means you'll have only the rest of the current month to enjoy your first picks. Starting on the 1st of next month and you'll get the entire month to visit your picks."
          open_upwards={true}
          bottom_offset={52}
          max_width={300}
          display_inline={false}>
          {this.rederDeferralText()}
        </WrappingTooltip>
      </div>
    );
  }
}

export default SignupV5SubscriptionStartSelect