import React from "react";
import PaymentInputV3 from "../state_components/payment_input_v3";
import NameAndReferralCode from "../state_components/name_and_referral_code";
import PhoneInput from "../state_components/phone_input";
import EmailInput from "../state_components/email_input";
import MemberQuestions from "../state_components/member_questions";
import SubscriptionForm from "../state_components/subscription_form";

export const getScreenDefinitions = (props, context = this) => {
  /* ==================================================
    Define which screens can be loaded.
    Each screen has a component and specific_props to load.
    We will combine the specific_props with a default set of
    props, "default_screen_props".
  ===================================================== */
  const screens = {
    // INITIAL_USER_INFORMATION: {
    //   component: MembershipInformation,
    //   page_name: "MembershipInformation",
    //   gtm_screen_name: "membership-information",
    //   specific_props: {
    //     updateState: context.updateState,
    //     selected_area: context.state.selected_area,
    //   },
    //   specific_props_from_state: {
    //     email: "email",
    //     phone_number: "phone_number",
    //     first_name: "first_name",
    //     last_name: "last_name",
    //     valid_referral: "valid_referral",
    //     referral_details: "referral_details",
    //     reason_to_become_member: "reason_to_become_member",
    //     supporting_local: "supporting_local",
    //     selected_offer_1: "selected_offer_1",
    //     selected_offer_2: "selected_offer_2",
    //     when_to_start: "when_to_start",
    //     selected_area: "selected_area",
    //     waitlist_id: "waitlist_id",
    //     setup_intent: "setup_intent",
    //     currentScreen: "currentScreen",
    //   }
    // },
    NAME_AND_REFERRAL: {
      component: NameAndReferralCode,
      page_name: "NameAndReferralCode",
      gtm_screen_name: "name-and-referral-code-screen",
      specific_props: {
        updateState: context.updateState,
        updateArea: context.updateArea,
      },
      specific_props_from_state: {
        setup_intent: "setup_intent",
        waitlist_id: "waitlist_id",
        currentScreen: "currentScreen",
        area: "selected_area",
        valid_referral: "valid_referral",
        referral_details: "referral_details",
        first_name: "first_name",
        last_name: "last_name",
      }
    },
    PHONE_INPUT: {
      component: PhoneInput,
      page_name: "PhoneInput",
      gtm_screen_name: "phone-input-screen",
      specific_props: {
        updateState: context.updateState,
      },
      specific_props_from_state: {
        phone_number: "phone_number",
      }
    },
    EMAIL_INPUT: {
      component: EmailInput,
      page_name: "EmailInput",
      gtm_screen_name: "email-input-screen",
      specific_props: {
        updateState: context.updateState,
      },
      specific_props_from_state: {
        email: "email",
      }
    },
    MEMBER_QUESTIONS: {
      component: MemberQuestions,
      page_name: "MemberQuestions",
      gtm_screen_name: "member-questions-screen",
      specific_props: {
        finalAccountInfo: context.finalAccountInfo,
      },
      specific_props_from_state: {
        selected_area: "selected_area",
        reason_to_become_member: "reason_to_become_member",
        supporting_local: "supporting_local",
      }
    },
    PAYMENT_INFORMATION: {
      component: PaymentInputV3,
      page_name: "PaymentInputV3",
      gtm_screen_name: "payment-screen",
      specific_props: {
        stripe_key: props.stripe_key,
        confirmation_path: props.confirmation_path,
        updateState: context.updateState
      },
      specific_props_from_state: {
        setup_intent: "setup_intent",
        phone_number: "phone_number",
        waitlist_id: "waitlist_id",
        currentScreen: "currentScreen",
        area: "selected_area",
        valid_referral: "valid_referral",
      }
    },
    SUBSCRIPTION_INFORMATION: {
      component: SubscriptionForm,
      page_name: "SubscriptionForm",
      gtm_screen_name: "subscription-information",
      specific_props: {
        areas: props.areas,
        finalSubscriptionInfo: context.finalSubscriptionInfo,
      },
      specific_props_from_state: {
        waitlist_id: "waitlist_id",
        selected_market: "selected_market",
        referral_details: "referral_details",
        selected_plan: "selected_plan",
        membership_start: "membership_start",
        setup_intent: "setup_intent",
      }
    }
  }

  /*
    We use the screen's order value to create an array
    of screens in the correct order. This is so we can
    easily iterate through the screens in order.
  */

  const signup_screen_order = props.signup_screen_order || [
    "MEMBERSHIP_OVERVIEW",
    "CYOO_FLOW",
    "INITIAL_USER_INFORMATION",
    "PAYMENT_INFORMATION"
  ];

  const screens_order_lookup = props.signup_screen_order.map(screen_name => screens[screen_name]);

  // These props will be passed to every screen
  const default_screen_props = {
    previousScreen: context.previousScreen,
    city_logo_img: <a href={`https://www.letsgetoffline.com/join${location.search}`} target="_blank"><img className="signup-header-nav-image" src={context.state.city_logo} /></a>,
    total_screens: screens_order_lookup.length,
  }

  return [screens, screens_order_lookup, default_screen_props]
}