import React from "react";

class ImageSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      image_url: props.image_url
    };

    this.handleImageChange = this.handleImageChange.bind(this);
  }

  handleImageChange(event) {
    if (event.target.files && event.target.files[0]) {
      var source_url = URL.createObjectURL(event.target.files[0]);
      this.props.onImageUploaded(event.target.files[0], source_url);
      this.setState({ image_url: source_url });
      event.target.value = null;
    }
  }

  render() {
    return (
      <div>
        <img style={{width: '100%', maxWidth: '300px'}} src={this.state.image_url} />
        <input type="file" name="header_image"accept="image/png, image/jpeg" onChange={this.handleImageChange} />
      </div>
    )
  }
}

export default ImageSelect;