import React from "react";
import EventCustomerReviews from "./event_customer_reviews";
import BarGraph from "../admin_app/shared/bar_graph";

class EventAboveFourStars extends React.Component {
  constructor(props) {
    super(props);

    this.getGraphData = this.getGraphData.bind(this);
  }

  survey_answer_boolean(value) {
    if (value == "yes") return true;
    if (value == "no") return false;
    return value;
  }

  broughtNewPeople(event_assignment) {
    return event_assignment.went_with_non_member != null ? event_assignment.went_with_non_member : this.survey_answer_boolean(event_assignment.survey_answers?.went_with_non_premium);
  }

  beenInPriorMonths(event_assignment) {
    return event_assignment.been_in_prior_months != null ? event_assignment.been_in_prior_months : this.survey_answer_boolean(event_assignment.survey_answers?.has_been_before);
  }

  willGoAgain(event_assignment) {
    return event_assignment.will_go_again != null ? event_assignment.will_go_again : this.survey_answer_boolean(event_assignment.survey_answers?.will_go_again);
  }

  formatPoints(points) {
    return points.toFixed(1);
  }

  getGraphData() {
    if (this.props.event_assignments.length == 0) return [];

    let rating_ambience_assignments = this.props.event_assignments
      .map(event_assignment => parseInt(event_assignment.rating_ambience || event_assignment.survey_answers?.ambiance))
      .filter(rating => rating != null && !isNaN(rating));

    let rating_food_drink_assignments = this.props.event_assignments
      .map(event_assignment => parseInt(event_assignment.rating_food_drink || event_assignment.survey_answers?.food_drinks))
      .filter(rating => rating != null && !isNaN(rating));

    let rating_business_staff_assignments = this.props.event_assignments
      .map(event_assignment => parseInt(event_assignment.rating_business_staff || event_assignment.survey_answers?.staff))
      .filter(rating => rating != null && !isNaN(rating));

    let rating_offline_staff_assignments = this.props.event_assignments
      .map(event_assignment => parseInt(event_assignment.rating_offline_staff || event_assignment.survey_answers?.offline_rating))
      .filter(rating => rating != null && !isNaN(rating));

    let rating_ambience = rating_ambience_assignments.reduce((a, b) => a + b, 0);
    let rating_food_drink = rating_food_drink_assignments.reduce((a, b) => a + b, 0);
    let rating_business_staff = rating_business_staff_assignments.reduce((a, b) => a + b, 0);
    let rating_offline_staff = rating_offline_staff_assignments.reduce((a, b) => a + b, 0);

    let graphs = [];

    if (rating_ambience_assignments.length > 0) {
      let percent_rating_ambience = rating_ambience / rating_ambience_assignments.length;
      graphs.push({
        label: "Ambience",
        value: percent_rating_ambience,
        class_color: "blue",
        max: 5,
        legend_formatter: this.formatPoints,
        value_as_label: true
      });
    }

    if (rating_food_drink_assignments.length > 0) {
      let percent_rating_food_drink = rating_food_drink / rating_food_drink_assignments.length;
      graphs.push({
        label: "Food/Drinks",
        value: percent_rating_food_drink,
        class_color: "teal",
        max: 5,
        legend_formatter: this.formatPoints,
        value_as_label: true
      });
    }

    if (rating_business_staff_assignments.length > 0) {
      let percent_rating_business_staff = rating_business_staff / rating_business_staff_assignments.length;
      graphs.push({
        label: "Business Staff/Service",
        value: percent_rating_business_staff,
        class_color: "yellow",
        max: 5,
        legend_formatter: this.formatPoints,
        value_as_label: true
      });
    }

    if (rating_offline_staff_assignments.length > 0) {
      let percent_rating_offline_staff = rating_offline_staff / rating_offline_staff_assignments.length;
      graphs.push({
        label: "Offline Check-in Staff",
        value: percent_rating_offline_staff,
        class_color: "yellow",
        max: 5,
        legend_formatter: this.formatPoints,
        value_as_label: true
      });
    }

    return graphs;
  }

  render() {
    if (this.props.event_assignments.length == 0) return (<></>);

    return (
      <div className="event-report-above-four-stars">
        <div className="above-four-stars-title">
          4 Stars & Above Ratings Breakdown
        </div>
        <div className="above-four-stars-container">
          <BarGraph
            graph_title="Reviewer Stats"
            graphs={this.getGraphData()}
          />
          <EventCustomerReviews
            event_assignments={this.props.event_assignments}
            highly_reviewed={true}
          />
        </div>
      </div>
    )
  }
}

export default EventAboveFourStars