import React from "react";

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

class PlacesAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      address: props.address
    }

    this.changeAddressName = this.changeAddressName.bind(this);
    this.changeLocalAddressName = this.changeLocalAddressName.bind(this);
    this.clearAddress = this.clearAddress.bind(this);
  }

  clearAddress(event) {
    this.setState({ address: null });
  }

  changeAddressName(event) {
    let full_address_object = Object.assign({}, this.state.address);
    full_address_object.display_name = event.target.value;
    this.setState({ address: full_address_object });

    let event_return_object = this.props.index == undefined ?
          full_address_object
          : { full_address_object, index: this.props.index }

    if (this.props.onWebRequestComplete) this.props.onWebRequestComplete(event_return_object);
  }

  changeLocalAddressName(event) {
    let full_address_object = Object.assign({}, this.state.address);
    full_address_object.display_name = event.target.value;
    this.setState({ address: full_address_object });
  }

  getAddressString = (address) => {
    if (!address || Object.keys(address).length < 2) return '';
    let address_join_array = [];

    address_join_array.push(address.name);
    address_join_array.push(address.street_line_1);
    if (address.street_line_2) address_join_array.push(address.street_line_2);
    address_join_array.push(address.city);
    address_join_array.push(address.state + ' ' + address.zip);

    return address_join_array.join(', ')
  }

  getSelectProps = (address) => {
    let address_string = this.getAddressString(address);
    let address_option = { value: address_string, label: address_string };
    let select_props = {
      onChange: this.changeAddressField,
      debounce: 750,
      onBlur: this.onBlur
    };
    if (address_string) {
      select_props.options = [address_option];
      select_props.value = address_option;
    }

    return select_props
  }

  parseGeocodeAddressComponents = (geocode_result_object, full_address_object) => {
    if (!geocode_result_object) return;

    full_address_object.latitude = geocode_result_object.geometry.location.lat();
    full_address_object.longitude = geocode_result_object.geometry.location.lng();

    let street_line_1 = {
      street_number: '',
      road: ''
    };

    geocode_result_object.address_components.forEach(address_component => {
      let types = address_component.types;
      if (types.includes('subpremise')) return (full_address_object.street_line_2 = address_component.long_name);
      if (types.includes('street_number')) return (street_line_1.street_number = address_component.short_name);
      if (types.includes('route')) return (street_line_1.road = address_component.short_name);
      if (types.includes('locality')) return (full_address_object.city = address_component.short_name);
      if (types.includes('administrative_area_level_1')) return (full_address_object.state = address_component.short_name);
      if (types.includes('postal_code')) return (full_address_object.zip = address_component.short_name);
    })

    full_address_object.street_line_1 =
      street_line_1.street_number
      + ' '
      + street_line_1.road
  }

  changeAddressField = (places_api_return_object) => {
    let full_address_object = {
      id: this.props.address?.id || null,
      addressable_id: this.props.address?.addressable_id || null,
      addressable_type: this.props.address?.addressable_type || null,
      name: places_api_return_object.value.structured_formatting.main_text,
      display_name: places_api_return_object.value.structured_formatting.main_text,
      google_place_id: places_api_return_object.value.place_id
    }

    geocodeByPlaceId(places_api_return_object.value.place_id)
      .then(results => {
        this.parseGeocodeAddressComponents(results[0], full_address_object);
        this.setState({ address: full_address_object, google_address: full_address_object });

        let event_return_object = this.props.index == undefined ?
          full_address_object
          : { full_address_object, index: this.props.index }

        if (this.props.onWebRequestComplete) this.props.onWebRequestComplete(event_return_object);
      })
      .catch(error => console.error(error));
  }

  render() {
    let select_props = this.getSelectProps(this.state.address);

    let content = (
      <GooglePlacesAutocomplete
        apiKey="AIzaSyDn0-hIOkUfB3xu2eJEYVKGePEZ02bTKyw"
        selectProps={select_props}
      />
    );

    if (this.state.address?.name != undefined) {
      content =  (
        <div style={{display: 'flex'}}>
          <div style={{fontSize: '10px', width: '100%', height: '3.5rem'}}>
            <div><b>{this.state.address.display_name}</b></div>
            <div>{this.state.address.street_line_1} {this.state.address.street_line_2}</div>
            <div>{this.state.address.city} {this.state.address.state}, {this.state.address.zip}</div>
          </div>
            <input
            name='address_name'
            className='builder__textarea'
            type="text"
            placeholder="Address Name"
            value={this.state.address.display_name || '' }
            onChange={this.changeLocalAddressName}
            onBlur={this.changeAddressName}
            style={{marginBottom: "0", height: '3.5rem'}} />
        </div>
      );
    } 

    return (
      <>
        {content}
        {this.props.canReset && this.state.address && 
          <button className="btn btn--small btn--danger" onClick={this.clearAddress}>Reset</button>
        }
      </>
    )
  }
}

export default PlacesAutocomplete;