import React from "react";

import CityLogo from 'logo-city.png'
import InfoIcon from 'icon-info.svg'
import Checkmark from 'large-check.svg'

import MemberEdit from "../member_edit"

class SetupProfile extends React.Component {
  constructor(props) {
    super(props);
    this.continueWithSetup = this.continueWithSetup.bind(this);
    this.goBackWithSetup = this.goBackWithSetup.bind(this);
  }

  continueWithSetup() {
    this.props.proceedWithSetup(this.props.setupStep + 1);
  }

  goBackWithSetup() {
    this.props.backAction(this.props.setupStep - 1);
  }

  render() {
    return (
      <div>
        <MemberEdit 
          doneAction={this.continueWithSetup}
          backAction={this.goBackWithSetup}
          id={this.props.account_id}
          social_handles={this.props.social_handles}
          preference_birthday_month={this.props.preference_birthday_month}
          preference_gender={this.props.preference_gender}
          preference_relationship={this.props.preference_relationship}
          preference_income={this.props.preference_income}
          preference_spending={this.props.preference_spending}
          preference_dog_friendly={this.props.preference_dog_friendly}
          preference_family_offers={this.props.preference_family_offers}
          setSetupStep={2}
          in_setup={true}/>
      </div>
    );
  }
}

export default SetupProfile