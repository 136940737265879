import React from "react";

class LocationBreakdown extends React.Component {
  constructor(props) {
    super(props);

  }

  totalCapacityDisplay() {
    var string = this.props.location.total_offer_min_capacity+" – "
    string = string + this.props.location.total_offer_target_capacity+" – "
    string = string + this.props.location.total_offer_max_capacity
    return string
  }

  render() {

    const row_data = this.props.offers.map(offer =>(
      <tr key={offer.id}>
        <td className='table-left'>{offer.name}</td>
        <td className='table-left'>{offer.pref_min_capacity} – {offer.pref_target_capacity} – {offer.pref_max_capacity}</td>
        <td className='table-left'>{offer.potential_assignments}</td>
      </tr>
    ))

    return (
      <div>
        <table className='admin__table'>
          <thead>
            <tr>
              <th className='table-left'>Total Offers</th>
              <th className='table-left'>Total Capacity (Min/Target/Max)</th>
              <th className='table-left'>Total Accounts</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='table-left'>{this.props.location.total_offers}</td>
              <td className='table-left'>{this.totalCapacityDisplay()}</td>
              <td className='table-left'>{this.props.location.unique_accounts}</td>
            </tr>
          </tbody>
        </table>

        <table className='admin__table' style={{marginTop: "25px"}}>
          <thead>
            <tr>
              <th className='table-left'>Name</th>
              <th className='table-left'>Capacity (Min/Target/Max)</th>
              <th className='table-left'>Accounts w/o prior Assignment</th>
            </tr>
          </thead>
          <tbody>
            {row_data}
          </tbody>
        </table>
      </div>
    );
  }
}

export default LocationBreakdown