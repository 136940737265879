import React from "react";
import IconSaveMoney from "icon-save-money.svg";
import ConfirmationButton from "../offer_selection/confirmation_button";

class BaseModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="base-modal-container">
        <div className="base-modal-box">
          <img className="base-image" src={this.props.modal_image || IconSaveMoney} />
          <div className="base-modal-title">
            {this.props.title}
          </div>
          <div className="base-modal-message">
            {this.props.message}
          </div>
          <ConfirmationButton
            on_click_action={this.props.confirm_action}
            button_label={this.props.confirm_button}
            is_active={true}
            error_message={null}
            child_ref={null}
            show_spinner={this.props.show_spinner}
            wrapper_class={"no-margin force-full"}
          />
          {this.props.cancel_button &&
            <ConfirmationButton
              on_click_action={this.props.cancel_action}
              button_label={this.props.cancel_button}
              is_active={true}
              error_message={null}
              child_ref={null}
              wrapper_class={"no-margin force-full min-style"}
            />
          }
        </div>
      </div>
    )
  }
}

export default BaseModal;