export const validatePhoneNumber = (phone_number, onSuccess, onError = null) => {
  if (!onError) {
    onError = () => { };
  }

  $.ajax({
    method: "POST",
    url: "/signup3/validate-phone-number",
    data: {phone_number},
    dataType: 'json',
    success: (data) => { onSuccess(data) },
    error: onError
  });
};

export const validateEmail = (email, onSuccess, onError = null) => {
  if (!onError) {
    onError = () => { };
  }

  $.ajax({
    method: "POST",
    url: "/signup3/validate-email",
    data: {email},
    dataType: 'json',
    success: (data) => { onSuccess(data) },
    error: onError
  });
};

export const validateAccountSubmit = (data, onSuccess, onError = null) => {
  if (!onError) {
    onError = () => { };
  }

  $.ajax({
    method: "POST",
    url: "/signup3/account-submit",
    data,
    dataType: 'json',
    success: (data) => { onSuccess(data) },
    error: onError
  });
};

export const validateReferralCode = (referral_code, area_id, onSuccess, onError = null) => {
  if (!onError) {
    onError = () => { };
  }

  $.ajax({
    method: "POST",
    url: "/referral-code",
    data: {referral_code, area_id},
    dataType: 'json',
    success: (data) => { onSuccess(data) },
    error: onError
  });
}

export const submitSubscriptionPlan = (data, onSuccess, onError = null) => {
  if (!onError) {
    onError = () => { };
  }

  $.ajax({
    method: "POST",
    url: "/signup3/subscription-submit",
    data,
    dataType: 'json',
    success: (data) => { onSuccess(data) },
    error: onError
  });
};
