import React from "react";
import moment from "moment-timezone";

class AccountActions extends React.Component {
  constructor(props) {
    super(props);

    this.network_busy = false;

    this.submitPost = this.submitPost.bind(this);
    this.update_account = this.update_account.bind(this);
    this.pause_account = this.pause_account.bind(this);
    this.state = {
      referral_slug: "",
      network_busy: false,
    };
  }

  update_account(data) {
    this.props.load_account(data);
  }

  pause_account(e) {
    let target = e.target;
    let sudo_form = target.parentElement;

    let path = sudo_form.getAttribute("action");
    path += "?as_json=true";
    path += "&months_paused=" + document.getElementById("months_paused").value;
    path += "&button=" + encodeURIComponent(target.value);

    this.submitPost(e, path);
  }

  submitPost(e, path = null) {
    let target = e.target;
    let sudo_form = target.parentElement;

    if (target.getAttribute("data-confirm") && !confirm(target.getAttribute("data-confirm"))) {
      return;
    }

    path = path || sudo_form.getAttribute("action");

    if (!path.includes("?")) {
      path += "?as_json=true";
    } else if (!path.includes("as_json")) {
      path += "&as_json=true";
    }

    if (this.state.network_busy) {
      return;
    }
    this.setState({ network_busy: true });

    $.ajax({
      method: sudo_form.getAttribute("method").toUpperCase(),
      url: path,
      processData: false,
      contentType: false,
      success: (data) => {
        this.setState({ network_busy: false });
        if (typeof data === "object") {
          showFlashMessage(sudo_form.dataset.success);
          this[`${sudo_form.dataset.update}`](data);
        } else if (typeof data === "string") {
          showFlashErrorMessage(data.split('\n')[0]);
        }
      },
      error: (error) => {
        this.setState({ network_busy: false });
        if (error && typeof error.responseText === "string") {
          showFlashErrorMessage(error.responseText.split('\n')[0]);
        }
        // showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }

  render() {
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (this.props.is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    let when_to_unpause = "Date to unpause: ";
    let status = this.props.account.status;
    if (this.props.account.date_to_unpause) {
      status = this.props.account.date_to_unpause + " (Date to unpause: " + moment(this.props.account.date_to_unpause).format("DD MMM, YYYY") + ")";
    } else if (this.props.account.date_to_pause) {
      status = this.props.account.date_to_pause + " (Date to pause: " + moment(this.props.account.date_to_pause).format("DD MMM, YYYY") + ")";
    }

    return (
      <>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%" }}>
            <h2 onClick={() => {
              let collapse_action = this.props.collapse_action;
              collapse_action.action(collapse_action.args);
            }}>
              Account Status: <span id="account-status">{this.props.account.status}</span>
              <span
                className={caret_style}
                data-section-id="subscription-section"
              />
            </h2>
            <div id="pause-wrapper" style={collapsed_style}>
              <div
                action={`/accounts/${this.props.account.slug}/pause_account`}
                acceptCharset="UTF-8"
                data-remote="true"
                method="post"
                data-success="Account Paused"
                data-update="update_account"
              >
                <label htmlFor="months_paused">{when_to_unpause}</label>
                <br />
                <select name="months_paused" id="months_paused">
                  {[...Array(7).keys()].map((i) => {
                    return (
                      <option value={i} key={i}>
                        {moment().endOf("month").add(i, "months").format("DD MMM, YYYY") + ` (${i} months)`}
                      </option>
                    );
                  })}
                </select>
                <br />
                <button
                  name="button"
                  type="submit"
                  value="pause account immediately"
                  className="pause-button"
                  onClick={this.pause_account}
                >
                  Pause account immediately
                </button>
                <br />
                <button
                  name="button"
                  type="submit"
                  value="pause account at end of month"
                  className="pause-button"
                  onClick={this.pause_account}
                >
                  Pause account at end of month
                </button>
              </div>
              {this.props.account.skip_end_date && 
                <>
                <br />
                <div
                action={`/accounts/${this.props.account.slug}/unpause_account`}
                acceptCharset="UTF-8"
                data-remote="true"
                method="post"
                data-success="Account Unpaused"
                data-update="update_account"
              >
                <button value="Unpause Account" onClick={this.submitPost}>
                  Unpause Account
                </button>
              </div>
                </>
              }
            </div>
            <br />
            {"Terms and Conditions: " + this.props.accepted_toc}
          </div>
          <div style={{ width: "50%", ...collapsed_style }}>
            <h2>Misc Info:</h2>
            <div>
              <span className="stripe-customer-id">Stripe ID:</span>{" "}
              <a
                target="_blank"
                href={"https://dashboard.stripe.com/customers/" + this.props.stripe_customer.id}
              >
                {this.props.stripe_customer.id}
              </a>
              <a className="copy-select" style={{ marginLeft: 10 }} href="#">
                <div className="copy-indicator admin__actionbar__submit">COPY</div>
              </a>
              <input
                type="text"
                name="stripe_id"
                id="stripe_id"
                defaultValue={this.props.stripe_customer.id}
                style={{ position: "fixed", right: "-1000px", fontSize: 24 }}
              />
            </div>
            <div id="stripe-data-wrapper">
              <div
                className="button_to"
                method="get"
                action={`/accounts/${this.props.account.slug}/review_stripe`}
                data-success="Stripe Data Loaded"
                data-update="update_account"
              >
                <input
                  id="stripe-button"
                  type="submit"
                  value="Sync + Load Stripe Data"
                  onClick={this.submitPost}
                />
              </div>
            </div>
            <div style={{ margin: "10px 0 0" }}>
              <div
                className="button_to"
                method="get"
                action={`/accounts/${this.props.account.slug}/update_mixpanel_links`}
                data-success="Mixpanel Resyncing Started..."
                data-update="update_account"
              >
                <input type="submit" value="Resync Mixpanel ID" onClick={this.submitPost} />
              </div>
            </div>
            <br />
            <h3>{`Stripe Credit: $${(this.props.stripe_customer.balance / -100.0).toFixed(2)}`}</h3>
            <h3>{"Referral Credits: " + this.props.account.referral_credits}</h3>
            <div>{"Referred By: " + (this.props.referral_type + ": " + this.props.referral_code)}</div>
            
            { this.props.referral_type === "none" &&
              <div style={{ margin: "10px 0 0" }}>
                <div
                  className="button_to"
                  method="post"
                  action={`/accounts/${this.props.account.slug}/update_referral_slug?referral_slug=${this.state.referral_slug}`}
                  data-success="Referral Updated"
                  data-update="update_account"
                >
                  <input type="input" placeholder="Referral Slug" onChange={(e) => this.setState({ referral_slug: e.target.value })} />
                  <input type="submit" value="Switch Referral" onClick={this.submitPost} disabled={this.state.network_busy || this.state.referral_slug === ""} />
                </div>
              </div>
            }
          </div>
        </div>
      </>
    );
  }
}

export default AccountActions;