import React from "react";
import IconClose from "icon-close.svg"

class CYOOActiveSelectionGUI extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={"offer-indicator" + (this.props.selected_offer ? " offer-selected" : "") + (this.props.error_message ? " error-display" : "")}>
        <div className="offer-title">
          <div className="offer-label">{this.props.label}</div>
        </div>
        <button className="clear-offer-selection" onClick={this.props.clear_selection}>
          <img src={IconClose} alt="✕" />
        </button>
      </div>
    );
  }
}

export default CYOOActiveSelectionGUI