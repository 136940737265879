import React from "react";
import ConfirmBackground from "confirm-background";
import ConfirmFlag from "confirm-flag";
import ConfirmSparkLeft from "confirm-spark-left";
import ConfirmSparkRight from "confirm-spark-right";

class SubscriptionUpdateSuccess extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="confirmation-container">
        <div class="confirmation-background">
          <img src={ConfirmBackground} className="confirmation-main-bg" />
          <img src={ConfirmFlag} className="confirmation-icon" />
          <img src={ConfirmSparkLeft} className="confirmation-spark-left" />
          <img src={ConfirmSparkRight} className="confirmation-spark-right" />
        </div>
        <div className="subscription-confirmation-title" dangerouslySetInnerHTML={{ __html: this.props.title }}></div>
        <div className="subscription-confirmation-subtitle" dangerouslySetInnerHTML={{ __html: this.props.subtitle }}></div>
        <button className="subscription-confirmation-button">Head back to the app by closing this window.</button>
      </div>
    )
  }
}

export default SubscriptionUpdateSuccess;