import React from "react";

class SortableTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sort_by: this.props.sort_by || null,
    }

    this.default_sort_function = this.default_sort_function.bind(this);
    this.set_sort_by = this.set_sort_by.bind(this);
  }

  default_sort_function(a, b) {
    return a[this.state.sort_by] > b[this.state.sort_by] ? 1 : -1;
  }

  set_sort_by(e) {
    let sort_by = e.target.dataset.sort;
    if (this.state.sort_by == sort_by) {
      sort_by = null;
    }
    this.setState({ sort_by });
  }

  render() {
    let rows = [...this.props.rows_data];
    let sort_by = this.props.sort_by || this.state.sort_by;
    if (sort_by) {
      let sort_function = this.props.row_templates.find(template => template.sort_by_property == sort_by).sort_function;
      sort_function = sort_function || this.default_sort_function;
      rows = rows.sort(sort_function);
    }

    let row_templates = this.props.row_templates || [];

    let table_stlye = { "--sortable-column-count": row_templates.length };

    return (
      <div className="sortable-table" style={table_stlye}>
        {row_templates.map((row_template, i) =>
          <div data-sort={row_template.sort_by_property} className={"sortable-table-cell header" + ((sort_by && sort_by == row_template.sort_by_property) ? " sorting-by" : "")} key={"header-" + i} onClick={this.set_sort_by}>
            {row_template.header_label}
          </div>
        )}
        {rows.map((row, i) => {
          return (
            <div className="sortable-table-row" key={"row-" + i}>
              {
                row_templates.map((row_template, j) => {
                  let cell = row_template.render_cell(row);
                  return (
                    <div className={"sortable-table-cell"} key={"row-" + i + "-cell-" + j}>
                      {cell}
                    </div>
                  )
                })
              }
            </div>
          )
        }
        )}
      </div>
    )

  }
}

export default SortableTable;