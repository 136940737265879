import React, { createRef } from "react";
import FormSelect from "../shared/form_select";
import OfferCardMin from "./offer_card_min";
import ToggleSwitch from "../shared/toggle_switch";

class OfferStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort_by: ["most_popular", "most_chosen"],
      filter_by: [],
      list_view: false,
    }

    this.filters = [
      "sold_out",
      "is_trending",
      "is_popular",
      "none_assigned",
      "is_not_trending",
      "is_not_popular",
    ];

    this.page_top = createRef();

    this.update_sort_by = this.update_sort_by.bind(this);
    this.change_market = this.change_market.bind(this);
    this.switch_to_annual_view = this.switch_to_annual_view.bind(this);
    this.update_filter_by = this.update_filter_by.bind(this);
    this.render_filter_by = this.render_filter_by.bind(this);
  }

  componentDidMount() {
    let offer_stats = localStorage.getItem("offer_stats");
    if (offer_stats) {
      this.setState(JSON.parse(offer_stats));
    }
  }

  switch_to_annual_view() {
    window.location.href = window.location.pathname + (this.props.annual_assignment_view ? "" : "?annual_assignments=true");
  }

  change_market(key, value) {
    window.location.href = "/offers/" + value;
  }

  update_sort_by(key, values) {
    let update_state = this.state;
    update_state["sort_by"] = values;
    this.setState(update_state);
    this.update_local_storage(update_state);
  }

  update_local_storage(state) {
    localStorage.setItem("offer_stats", JSON.stringify(state));
  }

  update_filter_by(filter_name, value) {
    let filter_by = this.state.filter_by;
    if (value) {
      filter_by.push(filter_name);
    } else {
      filter_by = filter_by.filter((f) => f != filter_name);
    }
    let update_state = this.state;
    update_state["filter_by"] = filter_by;
    this.setState(update_state);
    this.update_local_storage(update_state);
  }

  style_label_with_index(label) {
    let index = this.state.sort_by.indexOf(label.toLowerCase().replace(" ", "_"));
    if (index == -1) return label;
    return `${label} [${index + 1}]`;
  }

  offer_filter(offers, filter_name) {
    offers = [...offers]

    if (filter_name == "sold_out") {
      return offers.filter((offer) => !offer.sold_out);
    }

    if (filter_name == "is_trending") {
      return offers.filter((offer) => !offer.trending);
    }

    if (filter_name == "is_popular") {
      return offers.filter((offer) => !offer.popular);
    }

    if (filter_name == "is_not_trending") {
      return offers.filter((offer) => offer.trending);
    }

    if (filter_name == "is_not_popular") {
      return offers.filter((offer) => offer.popular);
    }

    if (filter_name == "none_assigned") {
      return offers.filter((offer) => offer.trending_score);
    }

    return offers;
  }

  offer_sort(offers, sort_name) {
    if (sort_name == "most_popular") {
      return offers.sort((a, b) => b.popular_score - a.popular_score);
    }

    if (sort_name == "least_popular") {
      return offers.sort((a, b) => a.popular_score - b.popular_score);
    }

    if (sort_name == "most_chosen") {
      return offers.sort((a, b) => b.trending_score - a.trending_score);
    }

    if (sort_name == "least_chosen") {
      return offers.sort((a, b) => a.trending_score - b.trending_score);
    }

    if (sort_name == "sold_out") {
      return offers.sort((a, b) => b.sold_out - a.sold_out);
    }

    if (sort_name == "is_trending") {
      return offers.sort((a, b) => b.trending - a.trending);
    }

    if (sort_name == "is_popular") {
      return offers.sort((a, b) => b.popular - a.popular);
    }

    return offers;
  }

  render_offer_card_grid() {
    let offers = this.props.all_valid_offers;

    for (let sort_name of [...this.state.sort_by].reverse()) {
      offers = this.offer_sort(offers, sort_name);
    }

    for (let filter_name of this.state.filter_by) {
      offers = this.offer_filter(offers, filter_name);
    }

    return (
      <>
        <div className="grid-label-container">
          <div className="grid-label big extra-margin">{offers.length + " Offers:"}</div>
          <div className="grid-label">List View:</div>
          <ToggleSwitch
            value={this.state.list_view}
            onChange={(e) => { 
              let update_state = this.state;
              update_state["list_view"] = e.target.checked;
              this.setState(update_state);
              this.update_local_storage(update_state);
            }}
            label="List View"
          />
          <div className="grid-label" style={{marginLeft: "12px"}}>Annual Assignments Only:</div>
          <ToggleSwitch
            value={this.props.annual_assignment_view}
            onChange={this.switch_to_annual_view}
            label="List View"
          />
        </div>
        <div className={"offer-card-grid" + (this.state.list_view ? " list-view" : "")}>
          {offers.map((offer, index) => {
            return <OfferCardMin
              key={index + '-' + offer.id}
              index={index}
              offer={offer}
              is_selected={false}
              select_offer={this.props.select_offer}
            />
          })}
        </div>
      </>
    )
  }

  render_filter_by() {
    if (this.filters.length == 0) return (<></>);

    return (
      this.filters.map((filter, i) =>
        <div key={i + 'fs'} className="filter-switch">
          <div className="filter-label">{filter.replaceAll('_', ' ') + ":"}</div>
          <ToggleSwitch
            value={this.state.filter_by.includes(filter)}
            onChange={(e) => { this.update_filter_by(filter, e.target.checked) }}
            label={filter}
          />
        </div>
      )
    )
  }

  render() {
    let options = [
      { value: "most_popular", label: this.style_label_with_index("Most Popular") },
      { value: "least_popular", label: this.style_label_with_index("Least Popular") },
      { value: "most_chosen", label: this.style_label_with_index("Most Chosen") },
      { value: "least_chosen", label: this.style_label_with_index("Least Chosen") },
      { value: "sold_out", label: this.style_label_with_index("Sold Out") },
      { value: "is_trending", label: this.style_label_with_index("Is Trending") },
      { value: "is_popular", label: this.style_label_with_index("Is Popular") },
    ];

    let unselected_options = [];
    let selected_options = [];
    options.forEach((option) => {
      if (option.label.endsWith("]")) {
        selected_options.push(option);
      } else {
        unselected_options.push(option);
      }
    });

    options = [...selected_options.sort((a, b) => parseInt(a.label.split('[')[1]) - parseInt(b.label.split('[')[1])), ...unselected_options];

    return (
      <>
        <div className="basic-layout__body offer-stats-page" style={{ width: "100%" }}>
          <div className="basic-layout__grow_content" style={{ minWidth: "100%" }}>
            <div className="basic-layout__dynamic_height-col">
              <div className="title-container">
                <div ref={this.page_top} className="title-row">
                  <h1>
                    Offer Stats for:
                  </h1>
                  <FormSelect
                    options={this.props.areas.sort((a, b) => a.id - b.id)}
                    name="perk_category_ids"
                    id="perk_location_ids"
                    className='area-select large-input'
                    value={this.props.area.slug}
                    onChange={this.change_market} />
                  <div className="sort-label">Sort By:</div>
                  <FormSelect
                    options={options}
                    name="sort_by"
                    id="sort_by"
                    show_index={true}
                    className='sort-by-select large-input'
                    value={this.state.sort_by}
                    multiselect={true}
                    onChange={this.update_sort_by} />
                </div>
                <div className="title-row">
                  <div className="filter-label">Filter Out:</div>
                  {this.render_filter_by()}
                </div>
              </div>
              {this.render_offer_card_grid()}
            </div>
            <div className="basic-layout__footer" style={{ margin: "20px" }}>
            <button
                style={{ marginTop: '10px' }}
                className="standard-btn--rounded"
                onClick={()=>{
                  this.page_top.current.scrollIntoView({block: 'end'});
                }}>
                Back To Top
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default OfferStats;