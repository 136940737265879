import React from "react";

class ProgressSteps extends React.Component {
  constructor(props) {
    super(props);
    this.progressClassStatus = this.progressClassStatus.bind(this);
  }

  progressClassStatus(index) {
    let status = 'progress-step'
    if (this.props.step == index) {
      status += ' progress-step-active'
    }
    return status;
  }

  render() {
    let steps = [];
    for (let i = 1; i <= this.props.total_steps; i++) {
      steps.push(
        <div className={this.progressClassStatus(i)} key={i}></div>
      )
    }

    return (
      <div className='progress-step-wrapper'>
        {steps}
      </div>
    );
  }
}

export default ProgressSteps