import React from "react";
import OfferCard from "./offer_card"

import IconCheckboxSelected from "icon-checkbox.svg"
import IconCheckboxUnselected from "icon-checkbox-unselected.svg"


class OfferInvite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_friend_ids: []
    }

    this.updateInviteList = this.updateInviteList.bind(this);
    this.submitInviteList = this.submitInviteList.bind(this);
    this.parseDisabledReason = this.parseDisabledReason.bind(this);
  }

  submitInviteList(e) {
    let inviting_friends = [];
    this.state.selected_friend_ids.forEach((friend_id) => {
      let friend = this.props.friends.find((friend) => {
        return friend.id == friend_id;
      });
      inviting_friends.push(friend);
    });

    if (inviting_friends.length == 0) {
      this.props.update_invite_list(null);
      return;
    }
    this.props.update_invite_list(inviting_friends);
  }

  updateInviteList(e) {
    let friend_id = parseInt(e.target.value);
    let selected_friend_ids = this.state.selected_friend_ids;
    let index = selected_friend_ids.indexOf(friend_id);

    if (index > -1) {
      selected_friend_ids.splice(index, 1);
    } else {
      selected_friend_ids.push(friend_id);
    }

    this.setState({
      selected_friend_ids: selected_friend_ids
    });
  }

  parseDisabledReason(friend) {
    if (friend.can_get_offer) {
      return '';
    }
    if (friend.has_already_chosen_offers) {
      return "(already picked their Offers)"
    }
    
    if (friend.last_assigned == null) {
      return "(already picked)";
    } else {
      let date_picked = new Date(friend.last_assigned).toLocaleString('default', {month: 'long', year: 'numeric'});
      return "(already picked in " + date_picked + ")";
    }
  }

  render() {
    let friend_rows = this.props.friends.map ((friend) => {
      let status = friend.can_get_offer ? '' : 'disabled';
      let disabled_reason = this.parseDisabledReason(friend);
      return (
        <div className={'invite-item ' + status } key={friend.id}>
          <div>
            <div className='invited-icon'>
              <div className='invited-initials'>{friend.first_name.substr(0,1)}{friend.last_name.substr(0,1)}</div>
            </div>
          </div>
          <div className='invited-member'>{friend.first_name} {friend.last_name} {disabled_reason}</div>
          <div>
            <div className="credit-input">
              <input
                name={"friend_" + friend.id}
                id={"friend_" + friend.id}
                type="checkbox"
                value={friend.id}
                checked={this.state.selected_friend_ids.includes(friend.id)}
                onChange={this.updateInviteList}
                style={{display: 'none'}}
                disabled={status} />
              <label htmlFor={"friend_"+friend.id} className={"cb-take-credits " + status}>
                <img src={(this.state.selected_friend_ids.includes(friend.id)) ? IconCheckboxSelected : IconCheckboxUnselected} style={{width: '24px'}} />
              </label>
            </div>
          </div>
        </div>
      );
    });

    let btn_class = this.state.selected_friend_ids.length > 0 ? '' : 'disabled';
    return (
      <>
        <div className="choose-offers-title">
          <div style={{width: '100%'}}>Invite Friends <div style={{float: 'right', fontSize: '16px', color: '#004FFF'}}><a onClick={this.props.continue}>Skip</a></div></div>
        </div>
        <OfferCard  
          offer={this.props.offer} 
          is_selected={false} 
          full_height={false} 
          chosen={false} />
        <div className='invite-section' style={{minHeight: '35vh'}}>
          <h4>Invite Friends:</h4>
          <div className="invite-list">
            {friend_rows}
          </div>
        </div>
        <div className="bottom-bar" style={{padding: '20px'}}>
          <button className={"offer-button-full full " + btn_class} onClick={this.submitInviteList}>INVITE</button>
          <div style={{marginTop: '16px'}}className="bottom-bar-subtitle"><a className='text-center' onClick={this.props.continue}>skip</a></div>
        </div>
      </>
    );
  }
}

export default OfferInvite