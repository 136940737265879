import React from "react";

class EventReportOfflineComment extends React.Component {
  constructor(props) {
    super(props);
  }

  getOfflineRating() {
    if (this.props.event_assignment.survey_answers.offline_rating) {
      return parseInt(this.props.event_assignment.survey_answers.offline_rating);
    }
    return 0;
  }

  render() {
    let user = (
      <div>
        {this.props.event_assignment.account?.first_name} {this.props.event_assignment.account?.last_name} ({this.props.event_assignment.account?.premium_id})
      </div>
    )

    if (this.props.event_assignment.account?.email) {
      user = (
        <a href={"mailto:" + this.props.event_assignment.account?.email}>
          {this.props.event_assignment.account?.first_name} {this.props.event_assignment.account?.last_name} ({this.props.event_assignment.account?.premium_id})
        </a>
      )
    }

    return (
      <>
        <div className="text-left">
          <div className='user-rating'>
            {user}
            <div>
              {"★".repeat(this.getOfflineRating())}
            </div>
          </div>
          <div className='user-comment'>
            <div style={{ width: '47%', display: 'inline-block', marginLeft: '2%' }}>
              {this.props.event_assignment.survey_answers.anything_loved}
            </div>
            <div style={{ width: '47%', display: 'inline-block', marginLeft: '2%' }}>
              {this.props.event_assignment.offline_feedback}
            </div>
          </div>
        </div>
        <hr />
      </>
    );
  }
}

export default EventReportOfflineComment