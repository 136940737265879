import React from "react";

import GoodFitQuestions from "../shared/account_inputs/good_fit_questions";
import ContinueButton from "../shared/continue_button";
import BackButton from "../shared/back_button";
import { getIDforGTM } from "../v2/state_components/gtm_methods";
import { validatePhoneNumber } from "../utils/account_validations";

class SignupV4MemberQuestions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reason_to_become_member: this.props.reason_to_become_member || "",
      error_reason_to_become_member: null,
      supporting_local: this.props.supporting_local || "",
      error_supporting_local: null,
      bad_fit: false
    }
    this.onInputChange = this.onInputChange.bind(this);
    this.validateAnswers = this.validateAnswers.bind(this);
  }

  onInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value || "",
      ["error_"+event.target.name]: ''
    });
  }

  validateAnswers() {
    if (this.state.supporting_local == "tipping_is_questionable" || this.state.reason_to_become_member == "save_money") {
      this.props.onSubmit({
        reason_to_become_member: this.state.reason_to_become_member,
        supporting_local: this.state.supporting_local,
        bad_fit: true
      }, true , this.props.cioStep || 0);
    } else {
      this.props.onSubmit({
        reason_to_become_member: this.state.reason_to_become_member,
        supporting_local: this.state.supporting_local,
      }, true , this.props.cioStep || 0);
    }
  }



  render() {
    let disabled_state = (this.state.reason_to_become_member == "") || (this.state.supporting_local == "");
    return (
      <>
        <GoodFitQuestions
          supporting_local={this.state.supporting_local}
          error_supporting_local={this.state.error_supporting_local}
          reason_to_become_member={this.state.reason_to_become_member}
          error_reason_to_become_member={this.state.error_reason_to_become_member}
          changeInputField={this.onInputChange} />
        <ContinueButton
          disabled={disabled_state}
          onPress={this.validateAnswers}
          gtm_id={getIDforGTM('continue-to-next-screen', this.props)} />
        <BackButton
          onPress={this.props.onBack}
          gtm_id={getIDforGTM('back-to-previous-screen', this.props)}
        />
      </>
    );
  }
}

export default SignupV4MemberQuestions