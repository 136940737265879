import React from "react";
import GroupSolo from 'home/group-solo.svg'



class AssignmentSlotDetails extends React.Component {
  constructor(props) {
    super(props);
  }

  iconForSlot() {
    return (<img style={{width: '43px', backgroundColor: 'white', borderRadius: '6px', padding: '1px'}} src={GroupSolo} />);
  }


  render() {
    return (
      <div>
        <div className="member__app-section">
          <a className="blue-underline" href={"/home/customize/" + this.props.id}>
            <div className="member__app-med-body">
              <div>{this.iconForSlot()}</div>
              <div className='text-left' style={{paddingLeft: '10px'}}>
                <h4>{this.props.name}</h4>
                <div>Your solo preferences ➜</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    );
  }
}

export default AssignmentSlotDetails