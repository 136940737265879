import React from "react";
import FormBuilder from "../shared/form_builder";
import ConfirmationModal from "../shared/confirmation_modal";

import EventLogoAnimated from 'offline-events-animated.svg'
import EventLogo from 'offline-events.svg'

import { convertDateString } from '../../helpers/datestring_formatter'

class EventCheckIn extends React.Component {
    constructor(props) {
        super(props);

        this.event_date_map = {};
        this.props.event_dates.forEach(eventDate => {
            this.event_date_map[eventDate.date] = eventDate.id;
        })

        this.event_date_map_by_id = {};
        this.props.event_dates.forEach(eventDate => {
            this.event_date_map_by_id[eventDate.id] = eventDate.date;
        })

        this.event_dates = this.props.event_dates
            .map(date_obj => { return convertDateString(date_obj.date) });

        this.event_dates.sort((a, b) => a - b);

        this.first_date = this.event_dates[0];
        this.last_date = this.event_dates[this.event_dates.length - 1];

        let defaultDate = this.first_date == this.last_date ? this.first_date : null;
        this.notPlayed = true;
        let opened_last = this.getSessionStorage("opened_last", true) || null;

        if (!opened_last) {
            opened_last = new Date();
            opened_last.setDate(-1);
            opened_last = opened_last.toISOString();
        }

        this.setSessionStorage('opened_last', new Date().toISOString(), true)

        this.state = {
            event_assignments: this.props.event_assignments,
            member_filter: this.getSessionStorage("member_filter") || "",
            date_filter: this.getSessionStorage("date_filter") || "",
            date_picker: this.getSessionStorage("date_filter") ?
                new Date(this.getSessionStorage("date_filter"))
                : defaultDate,
            time_filter: this.getSessionStorage("time_filter") || "",
            edit_attendee: null,
            edit_date: "",
            edit_timeslot: "",
            edit_tickets: "",
            edit_tickets_no_charge: "",
            edit_tickets_no_charge_init: "",
            opened_last: new Date(opened_last),
            remove_edit: false,
            remove_attendee: false,
            remove_all_tickets: false
        }

        this.changeInputField = this.changeInputField.bind(this);
        this.checkInOne = this.checkInOne.bind(this);
        this.checkInAll = this.checkInAll.bind(this);
        this.checkInEdit = this.checkInEdit.bind(this);
        this.startEditClose = this.startEditClose.bind(this);
        this.cancelEditClose = this.cancelEditClose.bind(this);
        this.startRemoval = this.startRemoval.bind(this);
        this.cancelRemoval = this.cancelRemoval.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.setClearAll = this.setClearAll.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.updateDateFilter = this.updateDateFilter.bind(this);
        this.destroyEventAssignment = this.destroyEventAssignment.bind(this);
        this.updateAndCloseEditEventAssignment = this.updateAndCloseEditEventAssignment.bind(this);
    }

    setSessionStorage(key, value, permanent = false) {
        if (permanent) {
            localStorage.setItem(key, JSON.stringify(value))
        } else {
            sessionStorage.setItem(key, JSON.stringify(value))
        }
    }

    getSessionStorage(key, permanent = false) {
        return permanent ?
            JSON.parse(localStorage.getItem(key))
            : JSON.parse(sessionStorage.getItem(key))
    }

    updateDateFilter(event) {
        this.setState({ date_filter: event.target.value })
        this.setSessionStorage('date_filter', event.target.value)
    }

    clearFilters() {

        this.setSessionStorage('member_filter', '');
        this.setSessionStorage('date_filter', '');
        this.setSessionStorage('time_filter', '');

        this.setState({
            date_filter: "",
            date_picker: this.defaultDate,
            time_filter: "",
            member_filter: ""
        })
    }

    checkInEdit(event) {
        let edit_attendee = [...this.state.event_assignments]
            .filter(x => x.id == event.target.dataset.id)
            .pop();

        this.setState({
            edit_attendee,
            edit_date: this.event_date_map_by_id[edit_attendee.event_date_id],
            edit_timeslot: edit_attendee.timeslot,
            edit_tickets: edit_attendee.tickets,
            edit_tickets_no_charge: edit_attendee.tickets_no_charge || "",
            edit_tickets_no_charge_init: edit_attendee.tickets_no_charge || ""
        })
    }

    checkInOne(event) {
        var path = "/event_assignments/"
            + event.target.dataset.id
            + "/increment_tickets";

        let form_builder = new FormBuilder();
        let form_object = {
            event_assignments: {
                increment: 1,
                token: event.target.dataset.token
            }
        };
        var form_data = form_builder.createForm(form_object);

        $.ajax({
            method: "POST",
            url: path,
            data: form_data,
            processData: false,
            contentType: false,
            success: (data) => {
                if (!data) {
                    return;
                }
                let event_assignments = [...this.state.event_assignments];
                for (let event_assignment of event_assignments) {
                    if (event_assignment.id == data.id) {
                        event_assignment.tickets_redeemed = data.tickets_redeemed;
                        break;
                    }
                }
                this.setState({ event_assignments })
            },
            error: (error) => {
                self.setState({ error_message: error.responseJSON.message, error_key: error.responseJSON.key });
            }
        });
    }

    checkInAll(event) {
        var path = "/event_assignments/"
            + event.target.dataset.id
            + "/check_in_all_tickets";

        $.ajax({
            method: "POST",
            url: path,
            data: { token: event.target.dataset.token },
            dataType: 'json',
            success: (data) => {
                if (!data) {
                    return;
                }

                let event_assignments = [...this.state.event_assignments];
                for (let event_assignment of event_assignments) {
                    if (event_assignment.id == data.id) {
                        event_assignment.tickets_redeemed = data.tickets_redeemed;
                        break;
                    }
                }
                this.setState({ event_assignments })
            },
            error: (error) => {
                self.setState({ error_message: error.responseJSON.message, error_key: error.responseJSON.key });
            }
        });
    }

    startEditClose(event) {
        if (this.state.edit_tickets_no_charge && this.state.edit_tickets_no_charge != this.state.edit_tickets_no_charge_init) {
            this.setState({ remove_edit: event });
        } else {
            this.updateAndCloseEditEventAssignment(event);
        }
    }

    cancelEditClose(event) {
        this.setState({ remove_edit: false });
    }


    startRemoval(event) {
        this.setState({ remove_attendee: event });
    }

    cancelRemoval() {
        this.setState({ remove_attendee: false });
    }

    destroyEventAssignment(event) {
        if (!this.state.edit_attendee) {
            return;
        }

        var path = "/event_assignments/"
            + event.target.dataset.id
            + "/destroy_event_assignment";

        $.ajax({
            method: "POST",
            url: path,
            data: { token: event.target.dataset.token },
            dataType: 'json',
            success: (data) => {
                let event_assignments = [...this.state.event_assignments]
                    .filter(event_assignment => event_assignment.id != event.target.dataset.id)
                this.setState({
                    event_assignments,
                    edit_attendee: null,
                    edit_date: "",
                    edit_timeslot: "",
                    edit_tickets: "",
                    edit_tickets_no_charge: "",
                    edit_tickets_no_charge_init: "",
                    remove_attendee: false
                })
            },
            error: (error) => {
                self.setState({ error_message: error.responseJSON.message, error_key: error.responseJSON.key });
            }
        });
    }

    updateAndCloseEditEventAssignment(event) {
        if (!this.state.edit_attendee) {
            return;
        }

        var path = "/event_assignments/"
            + event.target.dataset.id
            + "/save_event_assignment_updates";

        let formBuilder = new FormBuilder();

        let event_assignment_data = {
            event_assignment: {}
        };

        let edit_data = {
            tickets: this.state.edit_tickets,
            tickets_no_charge: this.state.edit_tickets_no_charge || "",
            timeslot: this.state.edit_timeslot,
            event_date_id: this.event_date_map[this.state.edit_date],
        }

        Object.assign(event_assignment_data.event_assignment, this.state.edit_attendee);
        Object.assign(event_assignment_data.event_assignment, edit_data);

        var form_data = formBuilder.createForm(event_assignment_data);
        $.ajax({
            method: "POST",
            url: path,
            data: form_data,
            processData: false,
            contentType: false,
            success: (data) => {
                if (!data) {
                    return;
                }

                let event_assignments = [...this.state.event_assignments];
                for (let event_assignment of event_assignments) {
                    if (event_assignment.id == data.id) {
                        Object.assign(event_assignment, data)
                        break;
                    }
                }

                this.setState({
                    event_assignments,
                    edit_attendee: null,
                    edit_date: "",
                    edit_timeslot: "",
                    edit_tickets: "",
                    tickets_no_charge: "",
                    remove_edit: false
                })
            },
            error: (error) => {
                self.setState({ error_message: error.responseJSON.message, error_key: error.responseJSON.key });
            }
        });
    }

    setClearAll(event) {
        this.setState({ remove_all_tickets: event })
    }

    formatEventsAttendedCount(attended_events) {
        switch (attended_events % 10) {
            case 1:  return attended_events + "st";
            case 2:  return attended_events + "nd";
            case 3:  return attended_events + "rd";
            default: return attended_events + "th";
        }
    }

    clearAll() {
        if (!this.state.remove_all_tickets) return;

        let event = this.state.remove_all_tickets;
        var path = "/event_assignments/"
            + event.target.dataset.id
            + "/clear_all_tickets";

        $.ajax({
            method: "POST",
            url: path,
            data: { token: event.target.dataset.token },
            dataType: 'json',
            success: (data) => {
                if (!data) {
                    return;
                }

                let event_assignments = [...this.state.event_assignments];
                for (let event_assignment of event_assignments) {
                    if (event_assignment.id == data.id) {
                        event_assignment.tickets_redeemed = data.tickets_redeemed;
                        break;
                    }
                }
                this.setState({ event_assignments, remove_all_tickets: false })
            },
            error: (error) => {
                self.setState({ error_message: error.responseJSON.message, error_key: error.responseJSON.key });
            }
        });
    }

    getMemberNameAndID(event_assignment) {
        return `${event_assignment.account.first_name} ${event_assignment.account.last_name} (${event_assignment.premium_id})`
    }

    changeInputField(event) {
        const target = event.target;
        const name = target.name;
        if (target.type == "checkbox") {
            return this.setState({ [name]: target.checked });
        }

        this.setState({ [name]: target.value });
        this.setSessionStorage(name, target.value);
    }

    renderEventDates() {
        if (!this.first_date) {
            return "No valid event dates found."
        }

        let last_date = this.first_date == this.last_date ? null : this.last_date;
        return last_date ?
            this.first_date.toDateString() + " - " + last_date.toDateString()
            : this.first_date.toDateString()
    }

    renderDateDropDown(name, onChange, value = "", blankValue = true, className = "large-input") {
        let options = [...this.props.event_dates];
        if (blankValue) {
            options.unshift({ id: "", date: "" });
        }

        return (
            <select className={className} name={name} value={value} onChange={onChange}>
                {options.map(event_date => {
                    return (<option key={name + event_date.id} value={event_date.date}>{event_date.date}</option>)
                })}
            </select>
        )
    }

    renderAttendeeTable() {
        let filtered_assignment = this.state.event_assignments;
        filtered_assignment.sort((a, b) => a.premium_id - b.premium_id);
        if (this.state.member_filter) {
            filtered_assignment = filtered_assignment.filter(assignment => {
                if (String(assignment.premium_id).includes(this.state.member_filter)) {
                    return assignment;
                }

                if ([assignment.account.first_name, assignment.account.last_name]
                    .join(' ').toLowerCase()
                    .includes(this.state.member_filter.toLowerCase())) {
                    return assignment;
                }

                return false;
            });
        }
        filtered_assignment = filtered_assignment
            .filter(assignment => this.event_date_map_by_id[assignment.event_date_id]?.startsWith(this.state.date_filter));
        filtered_assignment = filtered_assignment
            .filter(assignment => assignment.timeslot.startsWith(this.state.time_filter));

        return (
            <div className="check-in-table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Member</th>
                            <th>Date Slot</th>
                            <th>Time Slot</th>
                            <th>Tickets</th>
                            <th>Check In</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtered_assignment.map((event_assignment) =>
                            <tr key={event_assignment.id} data-changes={event_assignment.changes || ''}>
                                <td className="event-checkin-name">
                                    <div className="event-name">{this.getMemberNameAndID(event_assignment)}</div>
                                    <div className="event-member">{[event_assignment.member_for, event_assignment.lottery_points + ' pts', this.formatEventsAttendedCount(event_assignment.attended_events)].join(" : ")}</div>
                                </td>
                                <td>{this.event_date_map_by_id[event_assignment.event_date_id]}</td>
                                <td>{event_assignment.timeslot}</td>
                                <td>
                                    {event_assignment.tickets_redeemed + ' / ' + event_assignment.tickets}
                                    {this.props.view_no_charge && !isNaN(parseInt(event_assignment.tickets_no_charge)) && <div style={{ color: 'red', display: 'inline', paddingLeft: '10px' }}>({event_assignment.tickets_no_charge})</div>}
                                </td>
                                <td>{this.renderAttendeeCheckInOptions(event_assignment)}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        )
    }

    renderAttendeeCheckInOptions(attendee) {
        if (attendee.tickets_redeemed < attendee.tickets) {
            return (
                <>
                    <button
                        className='btn-large-outline'
                        data-id={attendee.id}
                        data-token={attendee.token}
                        onClick={this.checkInOne}
                    >
                        Check In: 1
                    </button>
                    <button
                        className='btn-large-outline'
                        data-id={attendee.id}
                        data-token={attendee.token}
                        onClick={this.checkInAll}
                    >
                        Check In: All
                    </button>
                    <button
                        className='check-in-button-link'
                        data-id={attendee.id}
                        data-token={attendee.token}
                        onClick={this.checkInEdit}
                    >
                        edit
                    </button>
                </>
            )
        } else {
            return (
                <>
                    <button
                        className='btn-large-outline'
                        data-id={attendee.id}
                        data-token={attendee.token}
                        onClick={this.setClearAll}
                    >
                        Clear Check Ins
                    </button>
                    <button
                        className='check-in-button-link'
                        data-id={attendee.id}
                        data-token={attendee.token}
                        onClick={this.checkInEdit}
                    >
                        edit
                    </button>
                </>

            )
        }
    }

    renderCheckInEdit() {
        if (!this.state.edit_attendee) {
            return;
        }

        return (
            <div className="check-in-edit">
                <div className="check-in-edit-body">
                    <h2>Edit</h2>
                    <div>
                        <table>
                            <thead>
                                <tr style={{ textAlign: 'left' }}>
                                    <th>Name</th>
                                    <th>Event Date</th>
                                    <th>Time</th>
                                    <th>Tickets</th>
                                    <th>No Charge Tickets</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={'edit' + this.state.edit_attendee.id} >
                                    <td className="spacer">{this.getMemberNameAndID(this.state.edit_attendee)}</td>
                                    <td>{this.renderDateDropDown('edit_date', this.changeInputField, this.state.edit_date, false, "large-input-thin")}</td>
                                    <td>
                                        <input type="text"
                                            name="edit_timeslot"
                                            className='large-input-thin'
                                            value={this.state.edit_timeslot}
                                            onChange={this.changeInputField} />
                                    </td>
                                    <td>
                                        <input type="text"
                                            name="edit_tickets"
                                            className='large-input-thin'
                                            value={this.state.edit_tickets}
                                            onChange={this.changeInputField} />
                                    </td>
                                    <td>
                                        <input type="text"
                                            name="edit_tickets_no_charge"
                                            className='large-input-thin'
                                            value={this.state.edit_tickets_no_charge}
                                            onChange={this.changeInputField} />
                                    </td>
                                    <td>
                                        <button
                                            className='check-in-button-link'
                                            data-id={this.state.edit_attendee.id}
                                            data-token={this.state.edit_attendee.token}
                                            onClick={this.startRemoval}>
                                            remove from event
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className='check-in-button-link'
                                            data-id={this.state.edit_attendee.id}
                                            data-token={this.state.edit_attendee.token}
                                            onClick={this.startEditClose}>
                                            done
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    renderHeaderImage() {
        let className = "check-in-logo";
        let classNameAfter = "";

        //If the last time the page was opened was more than 8 hours ago
        if (new Date() - this.state.opened_last > (8 * 60 * 60 * 1000) && this.notPlayed) {
            className += " check-in-logo pan-header";
            classNameAfter = "animate";
            this.notPlayed = false;
            return (
                <Img src={EventLogoAnimated} className={className} classNameAfter={classNameAfter} />
            );
        }

        return (
            <Img src={EventLogo} className={className} classNameAfter={classNameAfter} />
        );
    }

    render() {
        return (
            <div className="check-in-body">
                <div className="check-in-container">
                    <div className="">
                        {this.renderHeaderImage()}
                    </div>
                    <div className="check-in-row">
                        <h1>{this.props.name}</h1>
                        <div className="event-button-container"></div>
                    </div>
                    <div className="check-in-row">
                        <h2>Event Dates</h2>
                        {this.renderEventDates()}
                    </div>
                    <div className="check-in-row">
                        <h2>Filter List</h2>
                        <div className="filter-row">
                            <input
                                id='member-filter'
                                name='member_filter'
                                className='large-input'
                                type="text"
                                placeholder="Member Name or ID"
                                value={this.state.member_filter}
                                onChange={this.changeInputField} />
                            {this.renderDateDropDown('date_filter', this.updateDateFilter, this.state.date_filter)}
                            <input
                                id='time-filter'
                                name='time_filter'
                                className='large-input'
                                type="text"
                                placeholder="Time Slot"
                                value={this.state.time_filter}
                                onChange={this.changeInputField} />
                            <button
                                className='btn-large-outline'
                                onClick={this.clearFilters}>
                                Clear Filters
                            </button>
                        </div>
                        {this.renderAttendeeTable()}
                    </div>
                </div>
                {this.renderCheckInEdit()}
                <ConfirmationModal
                    className="check-in-modal"
                    width="24em"
                    message="Are you sure you want to update the no charge tickets value?"
                    show={this.state.remove_edit}
                    onConfirm={this.updateAndCloseEditEventAssignment}
                    onCancel={this.cancelEditClose}
                >
                </ConfirmationModal>
                <ConfirmationModal
                    className="check-in-modal"
                    width="24em"
                    message="Are you sure you would like to remove this attendee?"
                    show={this.state.remove_attendee}
                    onConfirm={this.destroyEventAssignment}
                    onCancel={this.cancelRemoval}
                >
                </ConfirmationModal>
                <ConfirmationModal
                    className="check-in-modal"
                    width="24em"
                    message="Are you sure you would like to clear all tickets?"
                    show={this.state.remove_all_tickets}
                    onConfirm={this.clearAll}
                    onCancel={() => { this.setClearAll(false) }}
                >
                </ConfirmationModal>
            </div>
        );
    }
}

class Img extends React.Component {
    state = { loaded: false };

    onLoad = () => {
        this.setState({ loaded: true });
    };

    render() {
        const { loaded } = this.state;

        return (
            <img
                className={this.props.className + (loaded ? " " + this.props.classNameAfter : "")}
                src={this.props.src}
                onLoad={this.onLoad}
            />
        );
    }
}


export default EventCheckIn