import React from "react";
import NewImageEditor from "../images/new_image_editor";
import FormMarkdownInput from "../shared/form_markdown_input";
import FormSelect from "../shared/form_select";

class ContentSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      section: props.section,
      minimized: props.minimized || false
    };
    this.getIconFromId = this.getIconFromId.bind(this);
    this.changeInputField = this.changeInputField.bind(this);
    this.onSectionDelete = this.onSectionDelete.bind(this);
    this.updateStateItem = this.updateStateItem.bind(this);
    this.handleImageUpdate = this.handleImageUpdate.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.minimizeSection = this.minimizeSection.bind(this);
  }

  isImageContent() {
    return this.state.section.content_type == "image";
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;

    var section_copy = Object.assign({}, this.state.section);

    if (target.type == "checkbox") {
      section_copy[name] = target.checked;
    }
    section_copy[name] = target.value;

    this.props.sectionUpdated(section_copy, this.props.index);
    this.setState({ section: section_copy });
  }

  updateStateItem(name, value) {
    var section_copy = Object.assign({}, this.state.section);
    section_copy[name] = value;

    this.props.sectionUpdated(section_copy, this.props.index);
    this.setState({ section: section_copy });
  }

  handleImageUpdate(image) {
    var section_copy = Object.assign({}, this.state.section);
    section_copy.image = image;

    this.props.sectionUpdated(section_copy, this.props.index);
    this.setState({ section: section_copy });
  }

  handlePositionChange(event) {
    var adjustment_direction = parseInt(event.target.dataset["positionAdjustment"]);
    this.props.sectionPositionChange(this.props.index, adjustment_direction);
  }

  minimizeSection(event){
    this.setState({minimized: !this.state.minimized})
  }

  onSectionDelete(event) {
    if (confirm("Remove this section?") == true) {
      this.props.sectionDeleted(this.props.index);
    }
  }

  getIconFromId(id) {
    return this.props.section_icons.find((icon) => icon.value == id).img || '';
  }

  render() {
    if (this.props.section["_destroy"]) {
      return null;
    }

    var render_content = <></>;

    if (this.isImageContent()) {
      render_content = (
        <div className="small-spaced">
          <NewImageEditor
            image={this.state.section.image}
            onImageUpdate={this.handleImageUpdate}
            imageable_id={this.state.section.id || null}
            imageable_type={this.state.section.id ? "Section" : null} />
        </div>
      );
    } else {
      render_content = (
        <div className="small-spaced">
          <FormMarkdownInput
            name="content"
            value={this.state.section.content}
            onChange={this.updateStateItem} />
        </div>
      );
    }

    return (
      <div className="content-section">
        <div className=''>
          <div className='section-header'>
            <p>Section {this.props.index + 1}: {this.state.section.title || ''}</p>
            <div className="section-header-buttons">
              <button
                className="red-btn header-button"
                onClick={this.onSectionDelete}>
                Delete
              </button>
              {this.props.index > 0 &&
                <button
                  className="standard-btn header-button"
                  data-position-adjustment={-1}
                  onClick={this.handlePositionChange}>
                  ↑
                </button>
              }
              {this.props.index < this.props.max_index &&
                <button
                  className="standard-btn header-button"
                  data-position-adjustment={1}
                  onClick={this.handlePositionChange}>
                  ↓
                </button>
              }
              <button
                className="standard-btn header-button"
                onClick={this.minimizeSection}
              >
                {this.state.minimized? "expand" : "minimize"}
              </button>
            </div>
          </div>
          <div className={'section-body' + (this.state.minimized? " section-min" : "")}>
            <div className='builder__multi-col section-title-info'>
            <label>Icon:</label>
              {this.state.section.section_icon_id &&
                <img style={{ maxWidth: '32px' }} src={this.getIconFromId(this.state.section.section_icon_id)} />
              }
              <div className='icon-input'>
                <FormSelect
                  options={this.props.section_icons}
                  name="section_icon_id"
                  className='large-input'
                  value={this.state.section.section_icon_id || ''}
                  onChange={this.updateStateItem} />
              </div>

              <label style={{paddingLeft: "8px"}}>Title:</label>
              <input
                name="title"
                type="text"
                className="builder__textarea"
                value={this.state.section.title || ''}
                onChange={this.changeInputField} />
            </div>

            <div className="small-spaced">
              <label>Content Type:</label>
              <FormSelect
                options={[{ label: "Text", value: "text" }, { label: "Image", value: "image" }]}
                name="content_type"
                className='large-input'
                value={this.state.section.content_type || "text"}
                onChange={this.updateStateItem} />
            </div>
            {render_content}
          </div>
        </div>
        <hr className='med-spaced' />
      </div>
    )
  }
}

export default ContentSection;