export const convertIntegerToPrice = (price) => {
  if (price && !isNaN(parseInt(price))) {
    return (price / 100.0).toFixed(2).toString();

  } else {
    return "0.00";
  }
};

export const convertIntegerToCleanPrice = (price) => {
  return convertIntegerToPrice(price).replace('.00', '');
};

export const convertPriceToInteger = (price) => {
  if (price && !isNaN(parseFloat(price))) {
    return Math.floor(parseFloat(price) * 100);
  } else {
    return price
  }
};

