import React from "react";
import ReviewRating from "./review_rating"
import ReviewToggle from "./review_toggle"

class EventReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      survey_answers: this.props.survey_answers || {},
      customer_feedback: this.props.customer_feedback || "",
      offline_feedback: this.props.offline_feedback || "",
      customer_rating: this.props.customer_rating,
      show_submitted: false
    }
    this.updateRating = this.updateRating.bind(this);
    this.updateCustomerRating = this.updateCustomerRating.bind(this);
    this.changeInputField = this.changeInputField.bind(this);
    this.changeSurveyInput = this.changeSurveyInput.bind(this);
    this.submitUpdate = this.submitUpdate.bind(this);
    this.saveClicked = this.saveClicked.bind(this);
    this.show_form = this.show_form.bind(this);
  }

  updateRating(key, value, save = true) {
    let copy = Object.assign({}, this.state.survey_answers);
    copy[key] = value;
    this.setState({ survey_answers: copy });
    if (save) {
      let submit_data = { event_review: Object.assign({}, this.state) };
      submit_data.event_review.survey_answers = copy;
      this.submitUpdate(submit_data);
    }
  }

  updateCustomerRating(key, value) {
    this.setState({ customer_rating: parseInt(value) });

    let submit_data = { event_review: Object.assign({}, this.state) };
    submit_data.event_review.customer_rating = value;
    this.submitUpdate(submit_data);
  }

  changeInputField(event) {
    let target = event.target;
    var name = target.getAttribute("name");
    this.setState({ [name]: target.value });
  }

  changeSurveyInput(event) {
    let target = event.target;
    var key = target.getAttribute("name");
    this.updateRating(key, target.value, false);
  }

  saveClicked() {
    let submit_data = { event_review: Object.assign({}, this.state) };
    submit_data["submitted"] = true;
    this.submitUpdate(submit_data, true);
  }

  submitUpdate(submit_data, button_pressed = false) {
    submit_data.event_review.status = 'reviewed';

    $.ajax({
      method: "PUT",
      url: "/event_reviews/" + this.props.token,
      data: submit_data,
      dataType: 'json',
      success: (data) => {
        this.setState({ has_saved: button_pressed, show_submitted: button_pressed });
      },
      error: function (error) {
        // Todo
      }
    });
  }

  show_form() {
    this.setState({ has_saved: false, show_submitted: false });
  }

  getSurveyValue(key) {
    return this.state.survey_answers[key];
  }


  render() {
    return (
      <div className='premium__info' id='user-rating'>
        <div className='premium__info premium__user-comments' style={{ display: 'block' }}>
          <div className='premium__user-options premium__user-feedback'>
            <div className={this.state.show_submitted ? "feedback-form has-submited-review" : "feedback-form"}>
              <h3>Biz feedback:</h3>
              <ReviewRating
                question={"Overall how did you like your experience at " + this.props.event.name + "?"}
                question_key="business_rating"
                value={this.state.customer_rating}
                onRatingChange={this.updateCustomerRating} />
              <ReviewRating
                question="ambience"
                question_key="ambience"
                value={this.getSurveyValue("ambience")}
                onRatingChange={this.updateRating} />
              <ReviewRating
                question="food/drinks"
                question_key="food_drinks"
                value={this.getSurveyValue("food_drinks")}
                onRatingChange={this.updateRating} />
              <ReviewRating
                question="staff"
                question_key="staff"
                value={this.getSurveyValue("staff")}
                onRatingChange={this.updateRating} />
              <ReviewToggle
                question="Was this your first time here?"
                question_key="first_time"
                value={this.getSurveyValue("first_time")}
                onToggleChange={this.updateRating} />
              <ReviewToggle
                question="Would you go back?"
                question_key="go_back"
                value={this.getSurveyValue("go_back")}
                onToggleChange={this.updateRating} />
              <div>Any helpful feedback for the biz?</div>
              <div>
                <textarea
                  className='builder__textarea'
                  name="customer_feedback"
                  value={this.state.customer_feedback}
                  onChange={this.changeInputField}
                  style={{ width: '100%', boxSizing: 'border-box', margin: '0', borderRadius: '10px' }} />
              </div>

              <hr />
              <h3>Offline feedback:</h3>
              <ReviewRating
                question={"Overall how did you like this Offline event at " + this.props.event.name + "?"}
                question_key="offline_rating"
                value={this.getSurveyValue("offline_rating")}
                onRatingChange={this.updateRating} />
              <ReviewToggle
                question="Did you bring a non-Offline friend to this event?"
                question_key="non_offline_friend"
                value={this.getSurveyValue("non_offline_friend")}
                onToggleChange={this.updateRating} />
              <div>Anything you loved?</div>
              <div style={{ paddingBottom: '20px' }}>
                <textarea
                  className='builder__textarea'
                  name="anything_loved"
                  value={this.getSurveyValue("anything_loved") || ""}
                  onChange={this.changeSurveyInput}
                  style={{ width: '100%', boxSizing: 'border-box', margin: '0', borderRadius: '10px' }} />
              </div>

              <div>Anything we can do better next time?</div>
              <div style={{ paddingBottom: '20px' }}>
                <textarea
                  className='builder__textarea'
                  name="offline_feedback"
                  value={this.state.offline_feedback}
                  onChange={this.changeInputField}
                  style={{ width: '100%', boxSizing: 'border-box', margin: '0', borderRadius: '10px' }} />
              </div>
              <hr />
            </div>
            
            <div className='premium__user-feedback-footer'>
              {!this.state.show_submitted &&
                <button className='feedback-submit' onClick={this.saveClicked}>Submit</button>
              }
              <h2 className='premium__user-feedback-submitted'>
                {this.state.has_saved &&
                  <>Thank you for your feedback</>
                }
              </h2>
              {this.state.show_submitted &&
                <button className='feedback-edit' onClick={this.show_form}>Update Review</button>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventReview