import React from "react";
import PerkIndexItem from './perk_index_item';
import FormSelect from "../shared/form_select";
import { convertDateString } from '../../helpers/datestring_formatter';

class PerkIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      perks: props.perks,
      filter_name: this.getSessionStorage("filter_name") || "",
      filter_status: this.getSessionStorage("filter_status") || []
    }

    this.updateFilterName = this.updateFilterName.bind(this);
    this.updateFilterStatus = this.updateFilterStatus.bind(this);
    this.perkUpdated = this.perkUpdated.bind(this);
  }

  setSessionStorage(key, value, permanent = false) {
    if (permanent) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      sessionStorage.setItem(key, JSON.stringify(value))
    }
  }

  getSessionStorage(key, permanent = false) {
    if (permanent) {
      return JSON.parse(localStorage.getItem(key))
    } else {
      return JSON.parse(sessionStorage.getItem(key))
    } 
  }

  updateFilterName(event) {
    const target = event.target;
    const name = target.name;
    this.setState({ [name]: target.value });
    this.setSessionStorage(name, target.value);
  }

  updateFilterStatus(name, value) {
    this.setState({ [name]: value });
    this.setSessionStorage(name, value);
  }

  perkUpdated(new_data) {
    let perk_array = [...this.state.perks];
    var index = 0;
    for (const perk of perk_array) {
      if (perk.id == new_data.id) {
        break;
      }
      index ++;
    }
    perk_array[index] = {
      id: new_data.id,
      name: new_data.name,
      start_date: new_data.start_date,
      end_date: new_data.end_date,
      location_ids: new_data.location_ids,
      capacity: new_data.capacity,
      active_assigned: new_data.active_assigned,
      redeemed_this_month: new_data.redeemed_this_month,
      perk_category_ids: new_data.perk_category_ids
    }

    this.setState({perks: perk_array});
  }

  renderPerkRows() {
    let filtered_perks = [...this.state.perks];

    // Filter by name
    if (this.state.filter_name) {
      filtered_perks = filtered_perks.filter(perk => {
        if (perk.name == null) {
          return false;
        }
        if (String(perk.name.toLowerCase()).includes(this.state.filter_name.toLowerCase())) {
          return perk;
        }
        return false;
      });
    }
    
    // Filter by "status"
    if (this.state.filter_status.length > 0) {
      filtered_perks = filtered_perks.filter(perk => {
        let today = new Date();
        // Include Upcoming
        if (this.state.filter_status.includes("Upcoming")) {
          let start_date = convertDateString(perk.start_date)
          if (start_date == null || start_date > today) {
            return perk
          }
        }

        // Include Active
        if (this.state.filter_status.includes("Active")) {
          let start_date = convertDateString(perk.start_date)
          let end_date = convertDateString(perk.end_date)
          if (start_date && start_date <= today && (end_date == null || end_date > today)) {
            return perk
          }
        }

        // Include Done
        if (this.state.filter_status.includes("Done")) {
          let end_date = convertDateString(perk.end_date)
          if (end_date && end_date <= today) {
            return perk
          }
        }

        return false;
      });
    }


    const items = filtered_perks.map((perk) =>
      <PerkIndexItem
        key={perk.id}
        id={perk.id}
        name={perk.name}
        start_date={perk.start_date}
        end_date={perk.end_date}
        location_ids={perk.location_ids}
        capacity={perk.capacity}
        active_assigned={perk.active_assigned}
        redeemed_this_month={perk.redeemed_this_month}
        perk_category_ids={perk.perk_category_ids}
        perk_categories={this.props.perk_categories}
        onUpdate={this.perkUpdated}
        />
    )
    return items
  }

  renderPerkFilter() {
    return (
      <div className='admin__page-filter-wrapper'>
        <div className='admin__page-filters'>
          <div className="admin__page-filters--text">
            <label className="builder__input-label event-filter-label">Search by Name</label>
            <input
              name="filter_name"
              type="text"
              value={this.state.filter_name}
              onChange={this.updateFilterName}
              placeholder="Filter by name"
              className="builder__input event-filter-input" />
          </div>
          <div className="admin__page-filters--text">
            <label className="builder__input-label event-filter-label">Perk Status:</label>
            <FormSelect
                options={[{label: 'Upcoming', value: 'Upcoming'}, {label: 'Active', value: 'Active'}, {label: 'Done', value: 'Done'}]}
                name="filter_status"
                className='large-input'
                multiselect={true}
                value={this.state.filter_status}
                onChange={this.updateFilterStatus} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderPerkFilter()}
        <table className="admin__table">
          <thead>
            <tr>
              <th className="table-left">Name</th>
              <th className="table-left">Start Date</th>
              <th className="table-left">End Date</th>
              <th className="table-left">Loactions</th>
              <th className="table-left">Capacity</th>
              <th className="table-left">Assigned</th>
              <th className="table-left">Redeemed</th>
              <th className="table-left">Categories</th>
              <th></th>

            </tr>
          </thead>
          <tbody id="perks">
            {this.renderPerkRows()}
          </tbody>
        </table>
      </>
    )
  }
}

export default PerkIndex;