import React from "react";
import SideBanner0 from 'sign-up-side-banner-0.jpg';
import SideBanner480 from 'sign-up-side-banner-480.jpg';
import SideBanner640 from 'sign-up-side-banner-640.jpg';
import SideBanner960 from 'sign-up-side-banner-960.jpg';
import SideBanner1024 from 'sign-up-side-banner-1024.jpg';
import UserReactivateForm from "./user_reactivate_form";
import SubscriptionForm from "./subscription_form";
import StripePaymentForm from "./stripe_payment_form";

// All City Logos
import CityLogo from 'logo-city.png'

import BackArrow from 'icon-back-arrow.svg'
import ProgressFilled from 'icon-progress-filled.svg'
import ProgressEmpty from 'icon-progress-empty.svg'

class SignUpPage extends React.Component {
  constructor(props) {
    super(props);

    // Define which screens can be loaded
    const screens = {
      USER_REACTIVATE_FORM: 0,
      SUBSCRIPTION_INFORMATION: 1,
      PAYMENT_INFORMATION: 2,
      OUTBOUND_ACTION: 3
    }
    this.screens = screens;

    let area = this.getAreaFromId(props.account.default_market_id) || { name: "Raleigh" }

    let membership_start = new Date().getDate() <= 10 ? "today" : "defer";

    // Setup state
    this.state = {
      currentScreen: 0,
      selected_market: props.account.default_market_id || 2,
      email: props.account.email || "",
      phone_number: props.account.phone_number || "",
      first_name: props.account.first_name || "",
      last_name: props.account.last_name || "",
      referral_details: props.referral_details,
      selected_plan: props.selected_plan,
      membership_start
    }

    this.event_button = null;

    // Function bindings
    this.nextAction = this.nextAction.bind(this);
    this.previousAction = this.previousAction.bind(this);
    this.selectContent = this.selectContent.bind(this);
    this.renderStatusBar = this.renderStatusBar.bind(this);
    this.renderMainContent = this.renderMainContent.bind(this);
    this.renderSideContent = this.renderSideContent.bind(this);
    this.updateStateValue = this.updateStateValue.bind(this);
    this.getAreaFromId = this.getAreaFromId.bind(this);
  }

  updateStateValue(name, value) {
    let newState = { [name]: value };
    if (name == "selected_market") {
      let area = this.getAreaFromId(value)

      newState.membership_start = area.deferral_only ? "defer" : this.state.membership_start;
    }
    this.setState(newState)
  }

  nextAction(event) {
    // Only allow an individial back button
    // to be clicked once.
    let target = event?.target;
    if (target && target == this.event_button) {
      return false;
    } else {
      this.event_button = target;
    }

    if (this.state.currentScreen < 2) {
      this.setState({ currentScreen: this.state.currentScreen + 1 });
    }
  }

  previousAction(event) {
    // Only allow an individial back button
    // to be clicked once.
    let target = event?.target;
    if (target && target == this.event_button) {
      return false;
    } else {
      // Allow header back button to be clicked
      // multiple times
      this.event_button = target.dataset.back ? null : target;
    }

    if (this.state.currentScreen > 0) {
      this.setState({ currentScreen: this.state.currentScreen - 1 });
    }
  }

  getAreaFromId(id) {
    let areas = this.props.areas || [];
    return areas.filter(area => area.id == id)[0];
  }

  selectContent(screen_id) {
    switch (screen_id) {
      case this.screens.USER_REACTIVATE_FORM:
        return (
          <UserReactivateForm
            areas={this.props.areas}
            email={this.state.email}
            phone_number={this.state.phone_number}
            selected_market={this.state.selected_market}
            referral_details={this.state.referral_details}
            account={this.props.account}
            nextAction={this.nextAction}
            onStateUpdate={this.updateStateValue}/>
        );

      case this.screens.SUBSCRIPTION_INFORMATION:
        return (
          <SubscriptionForm
            areas={this.props.areas}
            waitlist_id={this.state.waitlist_id}
            email={this.state.email}
            phone_number={this.state.phone_number}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            selected_market={this.state.selected_market}
            referral_details={this.state.referral_details}
            selected_plan={this.state.selected_plan}
            membership_start={this.state.membership_start}
            setup_intent={this.state.setup_intent}
            account={this.props.account}
            onStateUpdate={this.updateStateValue}
            nextAction={this.nextAction}
            previousAction={this.previousAction}
          />
        )

      case this.screens.PAYMENT_INFORMATION:
        return (
          <StripePaymentForm
            waitlist_id={this.state.waitlist_id}
            stripe_key={this.props.stripe_key}
            setup_intent={this.state.setup_intent}
            confirmation_path={this.props.confirmation_path}
            onStateUpdate={this.updateStateValue}
            nextAction={this.nextAction}
            previousAction={this.previousAction}
            referral_details={this.state.referral_details?.referral}
            selected_plan={this.state.selected_plan}
            selected_area={this.getAreaFromId(this.state.selected_market)}
            membership_start={this.state.membership_start}
          />
        )

      case this.screens.OUTBOUND_ACTION:
        return //renderOutboundAction();

      default:
        return //renderInitialUserInformation();
    }
  }

  renderStatusBar() {
    let progress = [];
    for (let i = 0; i < 3; i++) {
      if (i <= this.state.currentScreen) {
        progress.push(ProgressFilled);
      } else {
        progress.push(ProgressEmpty);
      }
    }

    return (
      <>
        {
          progress.map((icon, i) =>
            <img key={"pro" + i} src={icon}></img>
          )
        }
      </>
    )
  }

  renderMainContent() {
    let content = this.selectContent(this.state.currentScreen);
    let button_class = this.state.currentScreen < 1 ? "sign-up-header-back-spacer" : "sign-up-header-back-button"

    return (
      <div className="sign-up-main-content">
        <div className="sign-up-page-header">
          <button
            data-back="true"
            className={button_class}
            onClick={this.previousAction}
          >
            <img src={BackArrow} id={"back_to_step_" + this.state.currentScreen} alt="Back" data-back="true" />
          </button>
          <img className="sign-up-page-header-image" src={CityLogo} alt="Offline" />
          <div className="sign-up-step">
            {"Step " + (this.state.currentScreen + 1) + " of 3"}
          </div>
        </div>

        {content}

        <div className="sign-up-page-status-footer">
          {this.renderStatusBar()}
        </div>

        <div className="sign-up-page-desktop-footer">
          <div>© 2024 Offline Media, Inc.</div>
          <div className="questions-link">
            <a href="https://support.letsgetoffline.com/en/" target="_blank" style={{ color: "#4f3b1a", textDecoration: "none" }}>Have Questions?</a>
          </div>
        </div>
      </div>
    );
  }

  renderSideContent() {
    return (
      <div className="sign-up-side-content">
        <img
          srcSet={`${SideBanner0} 1w, ${SideBanner480} 480w, ${SideBanner640} 640w, ${SideBanner960} 960w, ${SideBanner1024} 1024w`}
          sizes="(max-width: 1224px) 1px, (max-width: 1800px) 480px, (max-width: 2600px) 640px, (max-width: 3200px) 960px, 1024px"
        />
      </div>
    );
  }

  render() {
    return (
      <div className="sign-up-page-container">
        {this.renderMainContent()}
        {this.renderSideContent()}
      </div>
    );
  }
}

export default SignUpPage