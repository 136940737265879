import React from "react";
import DatePicker from "react-date-picker";

import FormBuilder from "../shared/form_builder";
import ImageCollectionManager from "../images/image_collection_manager";
import FormSelect from "../shared/form_select";
import MultiPlacesAutocomplete from "../shared/multi_places_autocomplete";
import ContentSection from "../shared/content_section";
import FormMarkdownInput from "../shared/form_markdown_input";
import LocationPicker from "../shared/location_picker";

import { convertDateString } from '../../helpers/datestring_formatter'


class PerkEdit extends React.Component {
  constructor(props) {
    super(props);
    let sections = [...props.sections].sort((a, b) => a.position - b.position)
    this.state = {
      name: props.name || "",
      teaser: props.teaser || "",
      location_ids: props.location_ids || [],
      perk_category_ids: props.perk_category_ids || [],
      addresses: props.addresses,
      unsaved_changes: false,
      sections,
      website: props.website || "",
      instagram_link: props.instagram_link || "",
      how_to_redeem: props.how_to_redeem || "",
      capacity: props.capacity,
      start_date: convertDateString(props.start_date),
      end_date: convertDateString(props.end_date),
      perk_code: props.perk_code || ""
    }

    this.first_load = true;

    this.changeInputField = this.changeInputField.bind(this);
    this.updateStateItem = this.updateStateItem.bind(this);
    this.onAddressUpdate = this.onAddressUpdate.bind(this);
    this.onSectionUpdate = this.onSectionUpdate.bind(this);
    this.onSectionCreate = this.onSectionCreate.bind(this);
    this.onSectionDelete = this.onSectionDelete.bind(this);
    this.onSectionChangePosition = this.onSectionChangePosition.bind(this);
    this.selectAllLocations = this.selectAllLocations.bind(this);
    this.savePerkChanges = this.savePerkChanges.bind(this);
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;
    if (target.type == "checkbox") {
      return this.setState({ [name]: target.checked });
    }

    this.setState({ [name]: target.value });
  }

  updateStateItem(name, value) {
    this.setState({ [name]: value });
  }

  onAddressUpdate(addresses_array) {
    this.setState({ addresses: addresses_array, unsaved_changes: true })
  }

  onSectionUpdate(section, index) {
    var sections_copy = [...this.state.sections];
    sections_copy[index] = section;
    this.setState({ sections: sections_copy });
  }

  onSectionChangePosition(index, change = -1) {
    // If attempting to move to an index out of bounds, do nothing
    if (index + change < 0 || index + change >= this.state.sections.length) {
      return null
    }
    var sections_copy = [...this.state.sections];
    // Swap section positions
    var original_section = sections_copy[index];
    sections_copy[index] = sections_copy[index + change];
    sections_copy[index + change] = original_section;
    this.setState({ sections: sections_copy });
  }

  onSectionCreate() {
    var sections_copy = [...this.state.sections];
    this.new_section_count++;

    sections_copy.push({
      temp_id: "new-section-" + this.new_section_count
    });
    this.setState({ sections: sections_copy });
  }

  onSectionDelete(index) {
    var sections_copy = [...this.state.sections];
    var section_to_remove = sections_copy[index];
    if (!section_to_remove.id) {
      sections_copy.splice(index, 1);
    } else {
      sections_copy[index]["_destroy"] = true;
    }
    this.setState({ sections: sections_copy });
  }

  selectAllLocations(event) {
    const area_id = event.target.dataset["areaId"];
    var options = this.props.locations.filter(location => location.area_id == area_id);
    var location_ids = options.map((option) => option.value);
    var combinded_ids = this.state.location_ids.concat(location_ids);

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const unique_ids = combinded_ids.filter(onlyUnique);
    this.setState({ location_ids: unique_ids });
  }

  savePerkChanges() {
    var path = "/perks/" + this.props.id;

    var form_builder = new FormBuilder();

    var sections_copy = [...this.state.sections];
    for (let [i, section] of sections_copy.entries()) {
      section.position = i;
      if (section.image) {
        section.image_id_to_attach = section.image.id;
        delete section.image;
      }
    }

    var form_object = {
      perk: {
        name: this.state.name,
        teaser: this.state.teaser,
        location_ids: this.state.location_ids,
        perk_category_ids: this.state.perk_category_ids,
        addresses_attributes: this.state.addresses,
        website: this.state.website,
        instagram_link: this.state.instagram_link,
        how_to_redeem: this.state.how_to_redeem,
        capacity: this.state.capacity,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        perk_code: this.state.perk_code,
        sections_attributes: sections_copy
      }
    }

    var form_data = form_builder.createForm(form_object);

    $.ajax({
      method: "PUT",
      url: path,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        let sections = [...data.sections].sort((a, b) => a.position - b.position)
        this.setState({
          name: data.name || "",
          teaser: data.teaser || "",
          location_ids: data.location_ids || [],
          perk_category_ids: data.perk_category_ids || [],
          addresses: data.addresses,
          unsaved_changes: false,
          sections,
          website: data.website || "",
          instagram_link: data.instagram_link || "",
          how_to_redeem: data.how_to_redeem || "",
          capacity: data.capacity,
          start_date: convertDateString(data.start_date),
          end_date: convertDateString(data.end_date),
          perk_code: data.perk_code || ""
        })
        if (data.notice) {
          showFlashErrorMessage(data.notice);
        } else {
          showFlashMessage("Perk Information Saved");
        }
      },
      error: (error) => {
        self.setState({ error_message: error.responseJSON.message, error_key: error.responseJSON.key });
      }
    });
  }

  renderStatusPill() {
    let pill_class = "admin__app-pill pill-top pill-size-small";
    let pill_text = "Upcoming";

    if (!this.state.start_date || this.state.start_date > new Date()) {
      // The perk is upcoming
    } else if (!this.state.end_date || this.state.end_date > new Date()) {
      pill_text = "Live";
      pill_class += " pill-green"
    } else {
      pill_text = "Done";
    }

    return (
      <span
        style={{ marginLeft: "24px" }}
        className={pill_class}>
        {pill_text}
      </span>
    );
  }

  renderSections() {
    let minimized = this.first_load;
    this.first_load = false;

    return (this.state.sections.map((section, index) =>
      <ContentSection
        key={section.id || section.temp_id}
        index={index}
        section={section}
        section_icons={this.props.section_icons}
        max_index={this.state.sections.length - 1}
        sectionUpdated={this.onSectionUpdate}
        sectionPositionChange={this.onSectionChangePosition}
        sectionDeleted={this.onSectionDelete}
        minimized={minimized}
      />
    ))
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <div className="admin__page-header">
          <div className="admin__page-title">
            Modify Perk{this.renderStatusPill()}
          </div>
          <div className="admin__page-actions">
            <button
              className={'admin__actionbar__preview'}
              onClick={this.savePerkChanges}>
              Save
            </button>
          </div>
        </div>
        <div className='builder builder--experience builder__multi-col'>
          <div className='multi-col__size-5'>
            <div>
              <div className='med-spaced'>
                <h3>Perk Name</h3>
                <input
                  id='perk-name'
                  name='name'
                  className='builder__textarea'
                  type="text"
                  placeholder="Perk Name"
                  value={this.state.name}
                  onChange={this.changeInputField} />
                <h3>Perk Teaser</h3>
                <input
                  name='teaser'
                  className='builder__textarea'
                  type="text"
                  placeholder="Perk Teaser"
                  value={this.state.teaser}
                  onChange={this.changeInputField} />
              </div>

              <div className='med-spaced'>
                <h3>Perk Locations:</h3>
                <LocationPicker
                  areas={this.props.areas}
                  locations={this.props.locations}
                  location_ids={this.state.location_ids}
                  selectAllLocations={this.selectAllLocations}
                  updateStateItem={this.updateStateItem}
                />
              </div>

              <div className='med-spaced'>
                <h3>Perk Categories:</h3>
                <FormSelect
                  options={this.props.perk_categories}
                  name="perk_category_ids"
                  id="perk_location_ids"
                  className='large-input'
                  multiselect={true}
                  value={this.state.perk_category_ids}
                  onChange={this.updateStateItem} />
              </div>

              <div className='med-spaced'>
                <h3>Perk Photo(s)</h3>
                <ImageCollectionManager
                  imageable_id={this.props.id}
                  imageable_type={"Perk"}
                  images={this.props.images} />
              </div>

              <div className='med-spaced'>
                <h4>Address(es)</h4>
                <MultiPlacesAutocomplete
                  addresses={this.state.addresses}
                  onAddressUpdate={this.onAddressUpdate}
                  unsaved={this.state.unsaved_changes}
                />
              </div>

              <div className='med-spaced'>
                <h3>Perk Website</h3>
                <input
                  name='website'
                  className='builder__textarea'
                  type="text"
                  placeholder="Perk Website"
                  value={this.state.website}
                  onChange={this.changeInputField} />
                <h3>Perk Instagram</h3>
                <input
                  name='instagram_link'
                  className='builder__textarea'
                  type="text"
                  placeholder="Perk Instagram Link"
                  value={this.state.instagram_link}
                  onChange={this.changeInputField} />
              </div>

              <div className='med-spaced'>
                <h3>How To Redeem</h3>
                <FormMarkdownInput
                  name="how_to_redeem"
                  value={this.state.how_to_redeem}
                  onChange={this.updateStateItem} />
              </div>

              <div className='med-spaced'>
                <h3>Perk Capacity</h3>
                <input
                  name='capacity'
                  className='builder__textarea'
                  type="text"
                  placeholder="Perk Capacity"
                  value={this.state.capacity}
                  onChange={this.changeInputField} />
              </div>

              <div className='med-spaced' style={{ display: "flex" }}>
                <div>
                  <h3>Start Date</h3>
                  <DatePicker
                    name="start_date"
                    disableClock={true}
                    value={this.state.start_date}
                    onChange={value => { this.updateStateItem("start_date", value) }} />
                </div>
                <div style={{ marginLeft: "24px" }}>
                  <h3>End Date</h3>
                  <DatePicker
                    name="end_date"
                    disableClock={true}
                    value={this.state.end_date}
                    onChange={value => { this.updateStateItem("end_date", value) }} />
                </div>
              </div>

              <div className='med-spaced'>
                <h3>Perk Code</h3>
                <input
                  name='perk_code'
                  className='builder__textarea'
                  type="text"
                  placeholder="Perk Code"
                  value={this.state.perk_code}
                  onChange={this.changeInputField} />
              </div>
            </div>
          </div>

          { /* Right Side of editor */}
          <div className='multi-col__size-4'>
            <div className='med-spaced'>
              <h3>Section(s)</h3>
              {this.renderSections()}
              <button
                style={{ marginTop: '10px' }}
                className="standard-btn--rounded"
                onClick={this.onSectionCreate}>
                Add New Section
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PerkEdit