import React from "react";
import iconGooglePlay from 'icon-download-googleplay.svg'
import iconAppStore from 'icon-download-appstore.svg'
import Cheers from 'cheers.svg'

class WelcomePage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="sign-up-column" style={{textAlign: 'center'}}>
        <img src={Cheers} />
        <div className="confirmation-header">Welcome to the club</div>
        <div className="confirmation-subtitle" style={{fontWeight: '600', marginTop: '10px'}}>Arguably your best decision so far in 2024.</div>
        <div className="confirmation-subtitle">To get rolling, download the Offline app:</div>
        <div className="confirmation-subtitle">+ check your inbox for v important info:</div>


        <div className="app-store-container">
          <a href="https://apps.apple.com/us/app/offline-restaurant-club/id1632270215" target="_blank">
            <img src={iconAppStore} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.letsgetoffline.lgomobile" target="_blank">
            <img src={iconGooglePlay} />
          </a>
        </div>
      </div>
    );
  }
}

export default WelcomePage