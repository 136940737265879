import React from "react";

class EmailInput extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="email-input">
          <input type="text"
            className="membership-info-input signup-input signup-input"
            autoCapitalize="none"
            id="input_email"
            name="email"
            placeholder="Email Address"
            onChange={this.props.changeInputField}
            value={this.props.email} />
          <div className="input-error">
            {this.props.error_email &&
              <span>
                {this.props.error_email}<br />
                If you were a previous member, please redownload the app (
                <a href="https://apps.apple.com/us/app/offline-restaurant-club/id1632270215" >iOS App Store</a> |
                <a href="https://play.google.com/store/apps/details?id=com.letsgetoffline.lgomobile">Google Play Store</a>
                ) to sign back in.
              </span>
            }
          </div>
        </div>
    );
  }
}

export default EmailInput