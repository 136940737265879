import React from "react";

class CityDropdown extends React.Component {
  constructor(props) {
    super(props);

    let value = this.props.value;
    let options = this.props.options || [{ label: "", value: 0, city: "" }];

    if (!value && options[0] && options[0].value) {
      value = this.props.options[0].value;
    }

    this.state = {
      value: value || 0,
      options,
      city: this.cityFromValue(value, options),
      dropdown_open: false
    }

    this.className = this.props.className || "";

    this.closeDropdown = this.closeDropdown.bind(this);
    this.onOpenDropdown = this.onOpenDropdown.bind(this);
    this.onSelectOption = this.onSelectOption.bind(this);
  }

  cityFromValue(value, options = this.state.options) {
    for (let option of options) {
      if (option.value == value) {
        return option.city
      }
    }
    return "";
  }

  closeDropdown() {
    this.setState({ dropdown_open: false });
  }

  onOpenDropdown() {
    this.setState({ dropdown_open: !this.state.dropdown_open })
  }

  onSelectOption(event) {
    let value = event.target.dataset.value;
    this.setState({ value, city: this.cityFromValue(value), dropdown_open: false })
    if (this.props.onChange) this.props.onChange("selected_market", value);
  }

  render() {
    return (
      <div
        className={this.className + " city-dropdown"}
        onClick={this.onOpenDropdown}
        id={this.props.id}
      >
        {this.props.icon &&
          <img src={this.props.icon} alt="" />
        }
        <div className="city-label">
          {this.state.city}
        </div>

        {this.state.dropdown_open &&
          <>
            <div className="city-dropdown-options">
              {this.state.options.map(option => {
                return (
                  <div
                    key={option.value}
                    data-value={option.value}
                    data-selected={option.value == this.state.value}
                    onClick={this.onSelectOption}
                  >
                    {option.label}
                  </div>
                )
              })}
            </div>
            <div className="blank-global" onClick={this.closeDropdown}></div>
          </>
        }
      </div>
    );
  }
}

export default CityDropdown