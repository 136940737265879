import React from "react";
import ReportFormatter from "./report_formatter";
import IconCloseWarningMessage from "icon-close-warning-message.svg";
import IconExtrapolationInfo from "icon-extrapolation-info.svg";
import IconExtrapolationBubble from "icon-extrapolation-bubble.svg";

class FinancialImpact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      can_show_warning_message: true,
      show_no_previous_reports_message: false
    };

    this.close_warning_message = this.close_warning_message.bind(this);
    this.toggle_previous_reports_message = this.toggle_previous_reports_message.bind(this);
  }

  close_warning_message() {
    this.setState({ can_show_warning_message: false });
  }

  toggle_previous_reports_message() {
    this.setState({ show_no_previous_reports_message: !this.state.show_no_previous_reports_message })
  }

  isABoolean(value) {
    return typeof value === 'boolean';
  }

  percentValue(value) {
    if (!value) return "NO DATA";
    if (typeof value === 'number') return Math.floor(value * 100).toFixed(0) + '%';
    return value;
  }

  dollarValue(value) {
    if (isNaN(parseFloat(value))) return "NO DATA";
    if (typeof value === 'number') return "$" + value.toFixed(2);
    return "$" + value;
  }

  render() {
    let prefix = this.props.is_extrapolated ? "ex_" : "";
    let formatter = new ReportFormatter();
    let discount_field = (<div className="impact-value">{formatter.data_presenter((prefix + "discounts"), this.props[prefix + "discounts"])}</div>);
    let cost_of_goods_sold_field = (<div className="impact-value">{formatter.data_presenter((prefix + "cost_of_goods_sold"), this.props[prefix + "cost_of_goods_sold"])}</div>);
    let total_spent = (<div className="impact-value">{formatter.data_presenter((prefix + "total_spent"), this.props[prefix + "total_spent"])}</div>);

    if (this.props.edit_mode) {
      discount_field = (<input type="text" className="impact-value" value={this.props.discounts || ""} onChange={this.props.update_fields} name="discounts" />);
      cost_of_goods_sold_field = (<input type="text" className="impact-value" value={this.props.cost_of_goods_sold || ""} onChange={this.props.update_fields} name="cost_of_goods_sold" />);
      total_spent = (<input type="text" className="impact-value" value={this.props.total_spent || ""} onChange={this.props.update_fields} name="total_spent" />);
    }

    let cost_per_customer = parseFloat(this.props.cost_per_customer)
    let show_warning_message = !isNaN(cost_per_customer) && cost_per_customer < 0 && this.state.can_show_warning_message;

    let extrapolation_message = (<></>);

    if (this.props.is_extrapolated) {
      extrapolation_message = (
        <div className="extrapolation-message" onClick={this.toggle_previous_reports_message}>
          <div className="extrapolation-message-text">extrapolated from previous reports</div>
          <div className="info-toggle">
            <img src={IconExtrapolationInfo} alt="" />
            <div className={"text-bubble-container" + (this.state.show_no_previous_reports_message ? " show-bubble-message" : "")}>
              <div className="bubble-text">
                We don't have POS data this month, so we've estimated using old data. Send your data to see actuals.
              </div>
              <img src={IconExtrapolationBubble} alt="" />
            </div>
          </div>
        </div>
      );
    } else if (this.props.no_previous_reports) {
      extrapolation_message = (
        <div className="extrapolation-message no-prev" onClick={this.toggle_previous_reports_message}>
          <div className="extrapolation-message-text">extrapolated from previous reports</div>
          <div className="info-toggle">
            <img src={IconExtrapolationInfo} alt="" />
            <div className={"text-bubble-container" + (this.state.show_no_previous_reports_message ? " show-bubble-message" : "")}>
              <div className="bubble-text">
                We don't have any POS data on file for your partnership. Send your data to see financial impact.
              </div>
              <img src={IconExtrapolationBubble} alt="" />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="partner-report-financial-impact">
        <div className="financial-impact-title">
          Financial Impact
          {extrapolation_message}
        </div>
        <div className="financial-impact-display">
          <div className="new-sales-offline">
            <div className="new-sales-title">{formatter.label_presenter((prefix + "total_spent"), this.props[prefix + "total_spent"])}</div>
            <div className="new-sales-amount">{formatter.data_presenter((prefix + "total_spent"), this.props[prefix + "total_spent"])}</div>
          </div>
          <div className="new-sales-above">
            <div className="new-sales-title">{formatter.label_presenter((prefix + "average_spend_per_user"), this.props[prefix + "average_spend_per_user"])}</div>
            <div className="new-sales-amount">
              <div className="impact-value">{formatter.data_presenter((prefix + "average_spend_per_user"), this.props[prefix + "average_spend_per_user"])}</div>
            </div>
          </div>
          <div className="financial-impact-stats">

            <div className={"negative-warning-message" + (show_warning_message ? " show-message" : "")}>
              <div className="warning-message-text">Since your Gross Profit Per Customer was negative this month, Offline's Partnerships team will be in touch with next steps ASAP.</div>
              <div className="close-warning-message">
                <img src={IconCloseWarningMessage} onClick={this.close_warning_message} />
              </div>
            </div>

            <div className="financial-impact-stat">
              <div className="impact-label">{formatter.label_presenter((prefix + "total_spent"), this.props[prefix + "total_spent"])}</div>
              <div className="impact-value">{total_spent}</div>
            </div>

            <div className="financial-impact-stat sub-impact-item">
              <div className="impact-label">{formatter.label_presenter((prefix + "discounts"), this.props[prefix + "discounts"])}</div>
              <div className="impact-value">{discount_field}</div>
            </div>

            <div className="financial-impact-stat line-impact">
              <div className="impact-label">{formatter.label_presenter((prefix + "new_sales_above_discount"), this.props[prefix + "new_sales_above_discount"])}</div>
              <div className="impact-value">{formatter.data_presenter((prefix + "new_sales_above_discount"), this.props[prefix + "new_sales_above_discount"])}</div>
            </div>

            <div className="financial-impact-stat">
              <div className="impact-label">Total Cost of Sales (Food/Drink)</div>
              <div className="impact-value">
                <div className="impact-value">{formatter.data_presenter((prefix + "total_cost_of_sales"), this.props[prefix + "total_cost_of_sales"])}</div>
              </div>
            </div>

            <div className="financial-impact-stat sub-impact-item">
              <div className="impact-label">Your COGS%</div>
              <div className="impact-value">{cost_of_goods_sold_field}</div>
            </div>

            <div className="financial-impact-stat">
              <div className="impact-label">New Labor & Overhead Cost</div>
              <div className="impact-value">0</div>
            </div>

            <div className="financial-impact-stat">
              <div className="impact-label">{formatter.label_presenter((prefix + "net_revenue"), this.props[prefix + "net_revenue"])}</div>
              <div className="impact-value">{formatter.data_presenter((prefix + "net_revenue"), this.props[prefix + "net_revenue"])}</div>
            </div>

            <div className="financial-impact-stat">
              <div className="impact-label">{formatter.label_presenter((prefix + "cost_per_customer"), this.props[prefix + "cost_per_customer"])}</div>
              <div className="impact-value">{formatter.data_presenter((prefix + "cost_per_customer"), this.props[prefix + "cost_per_customer"])}</div>
            </div>

          </div>
          <div className="financial-impact-footer">* Not included: the impact of members who brought non-Offline friends, word-of-mouth business generated via social posts or the lifetime value of new repeat customers. We don't report on what we can't track.</div>
        </div>
      </div>
    )
  }
}

export default FinancialImpact