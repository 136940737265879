import React from "react";
import CYOOFlowGUI from "../../gui_components/cyoo/cyoo_flow_gui";
import ChooseOffer from "./choose_offer";
import SignupHeader from "../../gui_components/signup_header";
import ProgressBar from "../../../../shared/progress_bar";
import CYOOActiveSelection from "./cyoo_active_selection";
import ConfirmSignupOffers from "./confirm_signup_offers";
import SignupFooter from "../../gui_components/signup_footer";
import { getIDforGTM } from "../gtm_methods";

import FilterIcon from "icon-filter-header.svg";
import BackIcon from "icon-back-left.svg";
import ArrowRight from "icon-arrow-right.svg";

class CYOOFlow extends React.Component {
  constructor(props) {
    super(props);

    let {
      all_categories,
      all_locations,
      all_offer_types,
      all_valid_offers
    } = this.parseProps(props)

    let selected_offers = [];
    if (props.selected_offer_1) selected_offers.push(props.selected_offer_1);
    if (props.selected_offer_2) selected_offers.push(props.selected_offer_2);

    let offer_map = all_valid_offers.reduce((offer_map, vo) => Object.assign(offer_map, { [vo.id]: vo }), {});
    selected_offers = selected_offers.map(offer_id => offer_map[offer_id]);

    let selected_offer_1 = offer_map[props.selected_offer_1] || null;
    let selected_offer_2 = offer_map[props.selected_offer_2] || null;
    let selected_start = props.when_to_start || null;
    let show_confirmation = selected_offer_1 && selected_offer_2 && selected_start;
    let choose_index = 0;
    if (show_confirmation) {
      choose_index++;
    }

    this.state = {
      is_new_offers_tab: true,
      all_categories,
      all_locations,
      all_offer_types,
      all_valid_offers,
      selected_offers,
      selected_offer_1,
      selected_offer_2,
      show_offer_filter: false,
      choose_index,
      selected_start,
      show_confirmation,
      no_start_date_selected_error: false,
      offer_cache: {},
    };

    this.confirmation_button_ref = React.createRef();
    this.continue_button_ref = React.createRef();

    this.parseProps = this.parseProps.bind(this);
    this.select_offer = this.select_offer.bind(this);
    this.open_filter = this.open_filter.bind(this);
    this.close_filter = this.close_filter.bind(this);
    this.clear_selection = this.clear_selection.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.backStep = this.backStep.bind(this);
    this.update_input_value = this.update_input_value.bind(this);
    this.update_offer_cache = this.update_offer_cache.bind(this);
    this.getSelectedOffer = this.getSelectedOffer.bind(this);
    this.editStep = this.editStep.bind(this);
    this.submitOffers = this.submitOffers.bind(this);
  }

  componentDidMount() {
    document.scrollingElement.scrollTop = 0;
  }

  componentDidUpdate(prev_props) {
    if (prev_props.offer_information?.all_valid_offers.length != this.props.offer_information?.all_valid_offers.length) {
      this.setState(this.parseProps(this.props));
    }
  }

  select_offer(offer) {
    let selected_offers = [offer];

    let update_state = { selected_offers };
    if (this.state.choose_index == 0) {
      update_state.selected_offer_1 = offer;
      if (this.state.selected_offer_2) selected_offers.push(this.state.selected_offer_2);
    } else {
      update_state.selected_offer_2 = offer;
      if (this.state.selected_offer_1) selected_offers.unshift(this.state.selected_offer_1);
    }

    this.setState(update_state);
  }

  open_filter() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Filters Opened");
      posthog.capture("Signup: Filters Opened");
    }
    this.setState({ show_offer_filter: true });
    document.documentElement.classList.add("no-scroll");
  }

  close_filter() {
    this.setState({ show_offer_filter: false });
    document.documentElement.classList.remove("no-scroll");
  }

  parseProps(props) {
    let all_categories = props.offer_information?.all_categories || [];
    let all_locations = props.offer_information?.all_locations || [];
    let all_offer_types = props.offer_information?.all_offer_types || [];
    let all_valid_offers = props.offer_information?.all_valid_offers || [];

    return { all_categories, all_locations, all_offer_types, all_valid_offers };
  }

  clear_selection() {
    let selected_offers = [...this.state.selected_offers];

    if (this.state.choose_index == 0) {
      selected_offers = selected_offers.filter(offer => offer.id != this.state.selected_offer_1.id);
      this.setState({ selected_offer_1: null, selected_offers });
    } else {
      selected_offers = selected_offers.filter(offer => offer.id != this.state.selected_offer_2.id);
      this.setState({ selected_offer_2: null, selected_offers });
    }
  }

  nextStep() {
    let update_state = {};
    if (this.state.choose_index == 0) {
      update_state.choose_index = 1;

      // If the user has already selected a
      // second offer, show confirmation
      if (this.state.selected_offer_2) {
        update_state.show_confirmation = true;
      }

    } else if (this.state.choose_index == 1) {
      update_state.show_confirmation = true;
    }
    document.scrollingElement.scrollTop = 0;

    this.setState(update_state);
  }

  editStep(step_num) {
    let update_state = {
      show_confirmation: false,
      choose_index: step_num
    };

    this.setState(update_state);
  }

  backStep() {
    let update_state = {};
    if (this.state.show_confirmation) {
      update_state.show_confirmation = false;
    } else if (this.state.choose_index == 1) {
      update_state.choose_index = 0;
    }

    this.setState(update_state);
  }

  update_input_value(value) {
    this.setState({ selected_start: value, no_start_date_selected_error: false });
  }

  getSelectedOffer() {
    if (this.state.choose_index == 0) {
      return this.state.selected_offer_1;
    } else {
      return this.state.selected_offer_2;
    }
  }

  update_offer_cache(offer) {
    if (!offer || !offer.id) return false;
    let offer_cache = this.state.offer_cache;
    offer_cache[offer.id] = offer;
    this.setState({ offer_cache });
  }

  submitOffers() {
    if (!this.state.selected_start) {
      this.setState({ no_start_date_selected_error: true });
      return false;
    }
    this.props.finalize_offers(this.state.selected_offer_1.id, this.state.selected_offer_2.id, this.state.selected_start);
  }

  // Helper Functions
  parseCommaSeparatedStringOfInt(string) {
    return string.split(',').map(n => parseInt(n))
  }

  renderCenter() {
    if (this.state.show_confirmation) {
      return (
        <ConfirmSignupOffers
          selected_offers={this.state.selected_offers}
          selected_offer_1={this.state.selected_offer_1}
          selected_offer_2={this.state.selected_offer_2}
          editStep={this.editStep}
          selected_start={this.state.selected_start}
          deferral_only={this.props.deferral_only}
          update_input_value={this.update_input_value}
          show_errors={this.state.no_start_date_selected_error}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
        />
      )
    } else {
      return (
        <ChooseOffer
          key={this.state.choose_index}
          all_categories={this.state.all_categories}
          all_locations={this.state.all_locations}
          all_offer_types={this.state.all_offer_types}
          all_valid_offers={this.state.all_valid_offers}
          selected_offers={this.state.selected_offers}
          selected_offer={this.getSelectedOffer()}
          offer_cache={this.state.offer_cache}
          choose_index={this.state.choose_index}
          show_offer_filter={this.state.show_offer_filter}
          open_filter={this.open_filter}
          close_filter={this.close_filter}
          select_offer={this.select_offer}
          update_offer_cache={this.update_offer_cache}
        />
      )
    }
  }

  render() {
    let back_id_ordinal = this.state.show_confirmation ? "second" : "first";
    let forward_id_ordinal = this.state.choose_index == 0 ? "first" : "second";
    // === Setup Header Information ===
    let back_button = (
      <img id={getIDforGTM(`back-to-${back_id_ordinal}-choice`, this.props)} className="header-back-arrow-button" src={BackIcon} alt="Back" onClick={this.backStep} />
    )

    if (this.state.choose_index == 0 && !this.state.show_confirmation) {
      back_button = this.props.currentScreen != 0 ?
        <img id={getIDforGTM("back-to-previous-screen", this.props)} className="header-back-arrow-button" src={BackIcon} alt="Back" onClick={this.props.previousScreen} />
        : <div className="header-blank-space"></div>;
    }
    //can_return_to_landing
    let filter_button = this.state.show_confirmation ?
      <div></div>
      : <img id={getIDforGTM("open-offer-filter", this.props)} className="header-filter-button" src={FilterIcon} onClick={this.open_filter} alt="" />

    let header_message = "";
    if (this.state.show_confirmation) {
      header_message = "Confirm Your Offers";
    } else if (this.state.choose_index == 0) {
      header_message = "Pick 1st restaurant";
    } else if (this.state.choose_index == 1) {
      header_message = "Pick 2nd restaurant";
    }
    // ================================

    // === Setup Footer Information ===
    let footer_button = null;
    let current_offer_indicator = null;
    let footer_message = null;

    if (this.state.show_confirmation) {
      let disable_footer_button = !this.state.selected_start || this.state.selected_offers.length < 2;
      footer_button = <button id={getIDforGTM("confirm-offer-choices", this.props)} className={"full-blue-button smaller-text signup-v2-stripe"} onClick={this.submitOffers} >
        Confirm Choices
        <img src={ArrowRight} alt="" className="signup-desktop-styling" />
      </button>;
      footer_message = (<div className="footer-sub-message">{/*you can't change your selections after confirming*/}</div>);
    } else {
      current_offer_indicator = (
        <CYOOActiveSelection
          selected_offer={this.getSelectedOffer()}
          error_message={this.state.error_message}
          clear_selection={this.clear_selection}
        />
      );
      footer_button = <button id={getIDforGTM(`confirm-${forward_id_ordinal}-offer`, this.props)} className="full-blue-button" onClick={this.nextStep} disabled={!this.getSelectedOffer()} ref={this.continue_button_ref}>
        Continue
        <img src={ArrowRight} alt="" className="signup-desktop-styling" />
      </button>;
    }
    // ================================

    return (
      <>
        <CYOOFlowGUI
          top={
            <>
              <SignupHeader
                mobile_left={back_button}
                mobile_center={<div className="header-cyoo-title">{header_message}</div>}
                mobile_right={filter_button}
                desktop_left={this.props.city_logo_img}
                desktop_right={<ProgressBar step={this.props.currentScreen + 1} total_steps={this.props.total_screens + 1} />}
              />
              <div className="desktop-inline-header">
                <div></div>
                <div className="header-cyoo-title">{header_message}</div>
                {filter_button}
              </div>
            </>
          }
          center={
            this.renderCenter()
          }
          bottom={
            <SignupFooter
              top={current_offer_indicator}
              center={footer_button}
              bottom={back_button}
              sticky={true}
            />
          }
        />
      </>
    );
  }
}

export default CYOOFlow