import React from "react";

class EventReportComment extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="text-left">
          <div className='user-rating'>
              {"★".repeat(this.props.rating)}
          </div>
          <div className='user-comment'>
            {this.props.comment}
          </div>
        </div>
        <hr/>
      </>
    );
  }
}

export default EventReportComment