import React from "react";
import OfferCard from "./offer_card";

// This sudo-interstitial was not added in the Q1 2023 CYOO upgrade.  May be depricated in the future.

class AlreadyChosen extends React.Component {
  constructor(props) {
    super(props);

    this.renderOfferChoice = this.renderOfferChoice.bind(this);
  }

  renderOfferChoice() {
    return (
      <OfferCard
        index={i}
        offer={this.props.assignment.offer}
        full_height={true}
        hide_button={true}
        chosen={true}
        on_select={this.selectCard}
        key={offer.id}
      />
    );
  }

  componentDidUpdate(prev_props) {
    if (this.props.index != prev_props.index && this.menu_ref.current) {
      this.menu_ref.current.scrollLeft = 0;
      this.setState({ is_new_offers_tab: true });
    }

    // If we're not selecting a new offer, make sure that the selected card is in view
    if (this.props.selected_offer_choice == prev_props.selected_offer_choice) {
      let selected = document.querySelector('.card-selected');
      if (selected) {
        selected.scrollIntoView({ block: 'nearest', inline: 'center' })
      } else {
        document.querySelector('.offer-slide-container').scrollLeft = 0
      }
    }
  }

  render() {
    return (
      <>
        <div className={"offers-title choose-offer"}>
          {"Choose Offer " + this.props.index}
        </div>
        <div className="chosen-subtitle">
          {"Good news! This Offer has already been chosen for the group by " + (this.props.member_name || "another group member") + "."}
        </div>
        <div className={"offer-slide-background already-chosen"}>
          <div className="offer-slide-container" ref={this.menu_ref}>
            <div className="offer-slide-menu center-cards">
              {this.renderOfferChoice()}
            </div>
          </div>
        </div>
        <div className="already-chosen-info redo-button">
          How do groups choose Offers?
        </div>
      </>
    );
  }
}

export default AlreadyChosen