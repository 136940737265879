import 'froala-editor/js/plugins.pkgd.min.js'

export const froalaSettings = (opts) => {
  opts = opts || {};

  var klass = opts.klass || 'builder__froala';
  var buttons = ['paragraphFormat', 'bold', 'italic', 'underline', 'lineHeight', 'inlineStyle', 'align', 'fontFamily',
                'fontSize', 'color', 'insertLink', 'formatOL', 'formatUL', 'html', 'clearFormatting', 'link', 'lists', 'codeView'
                ].concat(opts.buttons || []);
  var char_limit = opts.charCounterMax || -1;

  if (opts.noButtons) {
    buttons = [];
  }

  const config = {
    toolbarButtons: buttons,
    toolbarButtonsMD: buttons,
    toolbarButtonsSM: buttons,
    toolbarButtonsXS: buttons,
    linkAlwaysBlank: true,
    quickInsertButtons: ['photoInsert', 'ul', 'ol', 'hr'],
    imageEditButtons: ['imageAlt', 'imageCaption', 'imageRemove'],
    imageResize: false,
    charCounterMax: char_limit,
    fontFamily: {
      "Open Sans": "Open Sans",
      "avenir, sans-serif": "Avenir",
      "helvetica, sans-serif": "Helvetica",
      "arial, sans-serif": "Arial",
      "Playfair Display, serif": "Playfair Display",
      "Georgia, serif": "Georgia", 
      "Times New Roman, serif": "Times New Roman"
    },
    fontFamilyDefaultSelection: "Open Sans",
    editorClass: 'froala-editor-default-styles',
    fontSize: ['8', '9', '10', '11', '12', '14', '16', '18', '20', '22', '24', '30', '36'],
    lineHeights: {
        Default: '',
        Single: '100%',
        '1.15': '115%',
        '1.5': '150%',
        Double: '200%'
    },
    inlineStyles: {
      'Letter Spacing: 0': 'letter-spacing: 0px;',
      'Letter Spacing: 0.5': 'letter-spacing: 0.5px;',
      'Letter Spacing: 1': 'letter-spacing: 1px;'
    },
    height: opts.height || 200,
    pasteDeniedAttrs: ['style'],
    key: 'SDB17hA5B6D5E4H4F4gpxjlgdvlbcjnflG5hcj1rA1C7A6D6E1D4G3E1C10A6==',
    attribution: false,
    pluginsEnabled: buttons
  }
  return config;
};