import React from "react";
import MemberQeustionsGUI from "../gui_components/member_questions_gui";
import NotGoodFit from "../../v2/gui_components/not_good_fit";
import SignupHeaderV3 from "../gui_components/signup_header_v3";
import SignupFooter from "../../v2/gui_components/signup_footer";
import { getIDforGTM } from "../../v2/state_components/gtm_methods";

class MemberQuestions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reason_to_become_member: this.props.reason_to_become_member || "",
      supporting_local: this.props.supporting_local || false,
      error_reason_to_become_member: null,
      error_supporting_local: null,
    }

    this.changeInputField = this.changeInputField.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.validateAccountDetails = this.validateAccountDetails.bind(this);
  }

  componentDidMount() {
    if (analytics_loaded) {
      mixpanel.track("Signup: Survey");
      posthog.capture("Signup: Survey");
    }
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;

    let check_state = Object.assign({}, this.state);
    check_state[name] = target.value;

    let check_errors = this.checkErrors(check_state);
    let update_state = check_errors.update_state;
    for (let update in check_errors.update_state) {
      if (check_errors.update_state[update]) {
        delete update_state[update];
      }
    }

    if (target.type == "checkbox") {
      update_state[name] = target.checked;
      return this.setState(update_state);
    } else {
      update_state[name] = target.value;
      return this.setState(update_state)
    }
  }

  validateAccountDetails() {
    let check_errors = this.checkErrors();
    let valid = check_errors.valid;
    let update_state = check_errors.update_state;

    if (valid) {
      if (this.state.supporting_local == "tipping_is_questionable" || this.state.reason_to_become_member == "save_money") {
        update_state.bad_fit = true;
      } else {
        this.props.finalAccountInfo({
          reason_to_become_member: this.state.reason_to_become_member,
          supporting_local: this.state.supporting_local,
        });
      }
    }

    this.setState(update_state);
  }

  checkErrors(local_state = this.state) {
    let valid = true;
    let update_state = {
      error_reason_to_become_member: local_state.error_reason_to_become_member,
      error_supporting_local: local_state.error_supporting_local,
    };

    if (!local_state.reason_to_become_member) {
      valid = false;
      update_state.error_reason_to_become_member = "Please select a reason";
    } else {
      update_state.error_reason_to_become_member = null;
    }

    if (!local_state.supporting_local) {
      valid = false;
      update_state.error_supporting_local = "Please select a reason";
    } else {
      update_state.error_supporting_local = null;
    }

    return { valid, update_state };
  }

  render() {
    if (this.state.bad_fit) {
      let instagram_link = this.props.selected_area.instagram_link || "https://www.instagram.com/offlineraleighdurham/";
      return (
        <>
          <NotGoodFit
            reason_to_become_member={this.state.reason_to_become_member}
            supporting_local={this.state.supporting_local}
            selected_area={this.props.selected_area}
          />
          <a href={instagram_link} style={{ textDecoration: "none" }}>
            <button id={getIDforGTM('go-to-instagram', this.props)} className={"full-blue-button smaller-text"}>
              Take me to Instagram
            </button>
          </a>
        </>
      )
    }

    return (
      <>
        <SignupHeaderV3
          markets={this.props.markets}
          selected_market={this.props.selected_market}
          updateStateValue={()=>{}}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
          select_enabled={false}
          currentScreen={this.props.currentScreen}
          total_screens={this.props.total_screens}
        />
        <MemberQeustionsGUI
          reason_to_become_member={this.state.reason_to_become_member}
          supporting_local={this.state.supporting_local}
          error_reason_to_become_member={this.state.error_reason_to_become_member}
          error_supporting_local={this.state.error_supporting_local}
          changeInputField={this.changeInputField}
          validateAccountDetails={this.validateAccountDetails}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
          previousScreen={this.props.previousScreen}
        />
        {/* <SignupFooter
          center={
            <div className="membership-continue-button">
              <button
                className={"full-blue-button smaller-text "}
                // disabled={this.canContinue() ? "" : "disabled"}
                onClick={this.validateAccountDetails}
                id={getIDforGTM('submit-phone-number', this.props)}>
                CONTINUE
              </button>
              <div className='footer-sub-message text-center membership-info-link'>have questions? <a href="https://support.letsgetoffline.com/en/" target="_blank">Chat with us</a></div>
            </div>
          }
          sticky={true}
        /> */}
      </>
    );
  }
}

export default MemberQuestions