import React from "react";
import CategoryToggle from "./category_toggle"

class CategoryEditDisabled extends React.Component {
  constructor(props) {
    super(props);
    var category_values = {}
    var all_true = true;
    var pref_data = this.props.assignment_preference.preference_data;
    if (pref_data ==  null) {
      pref_data = this.props.assignment_preference.full_preference_data;
    }

    this.props.categories.forEach(function(category) { 
      var category_value = pref_data.categories.includes(category.id)
      category_values[category.id] = category_value
      all_true = all_true && category_value
    });

    this.state = {
      category_values: category_values,
      give_all_categories: all_true
    }
  }

  renderAllCategories() {
    if (this.state.give_all_categories) {
      return "";
    }

    const categories = this.props.categories.map(category =>(
      <div key={category.id}>
        <hr className='subtle-divider'/>
        <div className="one-line">
          <div style={{width: "80%"}}>
            <h5>{category.name}</h5>
            <div>{category.description}</div>
          </div>
          <div style={{width: "20%"}} className='text-right'>
            <label className="switch">
              <input 
                type="checkbox"
                value={category.id}
                readOnly
                checked={this.state.category_values[category.id]} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    ))
    return (
      <div>
        {categories}
      </div>
    )
  }

  render() {
    return (
      <div style={{position: 'relative'}}>
        <div className="member__app-section member__app-section-body" style={{filter: "blur(4px)"}}>
          <h3 className='flat'>What type of places?</h3>
          <p>Don't want a certain type of spot? Turn it off and we won't send you Offers in that category.</p>
          <div className='text-left'>
            <div className="one-line">
              <div style={{width: "80%"}}>
                <h5>Give me everything</h5>
              </div>
              <div style={{width: "20%"}} className='text-right'>
                <label className="switch">
                  <input 
                    type="checkbox"
                    value="all"
                    readOnly
                    checked={this.state.give_all_categories}/>
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            {this.renderAllCategories()}
          </div>
        </div>
        <div className='disabled-section'>
          <div><b>Group preferences overide <br/>your individual prefrences</b></div>
        </div>
      </div>
    );
  }
}

export default CategoryEditDisabled