import React from "react";
import moment from "moment-timezone";
import ToggleSwitch from "../shared/toggle_switch";
import FormBuilder from "../shared/form_builder";
import BaseModal from "../../helpers/base_modal";

class InstagramInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show_all_areas: false,
      areas: this.props.areas,
      pending_save: false,
      area_updates: this.props.areas.map(area => {
        return {
          id: area.id,
          instagram_page_id: area.instagram_page_id || "",
          instagram_page_token: area.instagram_page_token || "",
          instagram_token_updated_at: area.instagram_token_updated_at,
          instagram_handle: area.instagram_handle || "",
          updated: false,
          is_active: area.is_active
        }
      }),
      show_instagram_page_ids: false,
      instagram_pages: null
    }
    this.window_listener = null;

    this.copy_token = this.copy_token.bind(this);
    this.save_updates = this.save_updates.bind(this);
    this.get_list_of_pages = this.get_list_of_pages.bind(this);
    this.popup_oauth = this.popup_oauth.bind(this);
    this.handle_message = this.handle_message.bind(this);
    this.update_area_field = this.update_area_field.bind(this);
    this.toggle_show_all_areas = this.toggle_show_all_areas.bind(this);
    this.render_area_info = this.render_area_info.bind(this);
  }

  componentDidMount() {
    let area_prefs = {
      show_all_areas: localStorage.getItem("show_all_areas") === "true"
    }
    this.setState(area_prefs);
  }

  popup_oauth(e) {
    e.preventDefault();
    let url = e.target.getAttribute("href");
    let width = 600;
    let height = 600;
    let left = (window.innerWidth / 2) - (width / 2);
    let top = (window.innerHeight / 2) - (height / 2);

    let new_window = window.open(url, "Instagram OAuth", `width=${width},height=${height},top=${top},left=${left}`);
    if (this.window_listener) {
      window.removeEventListener("message", this.handle_message, false);
    }
    window.addEventListener("message", this.handle_message, false);
    this.window_listener = e.target.dataset.id;
  }

  handle_message(event) {
    if (event.origin !== window.location.origin || !event.data.long_lived_token) {
      return;
    }

    let pending_save = this.state.pending_save;
    let area_updates = JSON.parse(JSON.stringify(this.state.area_updates));
    for (let area_update of area_updates) {
      if (area_update.id == this.window_listener) {
        area_update.instagram_page_token = event.data.long_lived_token;
        area_update.updated = true;
        pending_save = true;
        break;
      }
    }

    this.setState({ area_updates: area_updates, pending_save });
  }

  field_patterns(field_name) {
    if (field_name == "instagram_page_token") {
      return /[^a-zA-Z0-9_]+/g;
    }

    if (field_name == "instagram_page_id") {
      return /\D+/g;
    }

    if (field_name == "instagram_handle") {
      return /[^a-zA-Z0-9_\.]+/g;
    }

    return null;
  }

  copy_token(area_id) {
    let area_updates = JSON.parse(JSON.stringify(this.state.area_updates));
    let area_update = area_updates.find(update => update.id == area_id);

    if (!area_update) return false;

    let pending_save = this.state.pending_save;

    let token = area_update.instagram_page_token;
    for (let update of area_updates) {
      if (update.id != area_id && (this.state.show_all_areas || update.is_active)) {
        update.instagram_page_token = token;
        update.updated = true;
        pending_save = true;
      }
    }

    this.setState({ area_updates: area_updates, pending_save });
  }

  update_area_field(e) {
    let target = e.target;
    let area_id = parseInt(target.dataset.areaId)
    let value = target.value;
    let field = target.dataset.value;

    let pattern = this.field_patterns(field);
    if (pattern) {
      value = value.replaceAll(pattern, "");
    }

    let updated_array = [];

    let area_updates = JSON.parse(JSON.stringify(this.state.area_updates));
    for (let update of area_updates) {
      if (update.id == area_id) {
        let area = this.state.areas.find(area => area.id == area_id);
        update[field] = value;
        update.updated = this.are_values_different(update["instagram_page_id"], area["instagram_page_id"])
                          || this.are_values_different(update["instagram_page_token"], area["instagram_page_token"])
                          || this.are_values_different(update["instagram_handle"], area["instagram_handle"]);
      }
      updated_array.push(update.updated);
    }

    let pending_save = updated_array.includes(true);

    this.setState({ area_updates: area_updates, pending_save });
  }

  are_values_different(value1, value2) {
    if (value1 === null || value1 === undefined) {
      value1 = "";
    }

    if (value2 === null || value2 === undefined) {
      value2 = "";
    }

    return value1 != value2;
  }

  toggle_show_all_areas() {
    let show_all_areas = !this.state.show_all_areas;
    this.setState({ show_all_areas: show_all_areas });
    localStorage.setItem("show_all_areas", show_all_areas);
  }

  save_updates() {
    let path = "/instagram_info";
    let area_updates = this.state.area_updates.filter(update => update.updated);

    if (!this.state.show_all_areas) {
      area_updates = area_updates.filter(update => update.is_active);
    }

    if (area_updates.length == 0) {
      return;
    }

    let data = area_updates.reduce((acc, update) => {
      return {
        ...acc,
        [update.id]: {
          instagram_page_id: update.instagram_page_id,
          instagram_page_token: update.instagram_page_token,
          instagram_token_updated_at: update.instagram_token_updated_at,
          instagram_handle: update.instagram_handle
        }
      }
    }, {});

    let form_builder = new FormBuilder();
    var form_data = form_builder.createForm({
      areas: data
    });

    $.ajax({
      method: "POST",
      url: path,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        let areas = data.areas;

        let area_updates = areas.map(area => {
          let area_update = {
            id: area.id,
            instagram_page_id: area.instagram_page_id,
            instagram_page_token: area.instagram_page_token,
            instagram_token_updated_at: area.instagram_token_updated_at,
            instagram_handle: area.instagram_handle,
            updated: false,
            is_active: area.is_active
          }
          return area_update;
        });

        this.setState({ areas, area_updates, pending_save: false });
      },
      error: (error) => {
        showFlashErrorMessage(error.responseJSON.message);
      }
    });
  }

  get_list_of_pages() {
    if (this.state.instagram_pages) {
      this.setState({ show_instagram_page_ids: true });
      return;
    }

    let path = "/instagram_info/pages";

    let area_updates = [...this.state.area_updates];
    area_updates = area_updates.filter(update => update.instagram_page_token);

    area_updates = area_updates.sort((a, b) => (
      moment(a.instagram_token_updated_at || "1970-01-01").isBefore(moment(b.instagram_token_updated_at || "1970-01-01")) ? 1 : -1
    ))

    area_updates = area_updates.sort((a, b) => {
      if (a.updated && !b.updated) {
        return -1;
      }

      if (!a.updated && b.updated) {
        return 1;
      }

      return 0;
    });

    let area = area_updates[0];
    if (!area) {
      alert("No areas have an Instagram Access Token.  Please update an area with an Instagram Access Token and try again.");
      return;
    }


    $.ajax({
      method: "GET",
      url: path,
      data: {
        ig_token: area.instagram_page_token
      },
      success: (data) => {
        let instagram_pages = data;
        if(!instagram_pages || Object.keys(instagram_pages).length == 0) {
          instagram_pages = null;
        }

        this.setState({ instagram_pages, show_instagram_page_ids: true });
      },
      error: (error) => {
        showFlashErrorMessage(error.responseJSON.message);
      }
    });
  }

  render_area_info() {
    let areas = this.state.areas;

    if (!this.state.show_all_areas) {
      areas = areas.filter(area => area.is_active);
    }

    areas = areas.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1);

    return (
      <div className="instagram-areas">
        {
          areas.map((area) => {
            let last_updated = "Never";

            if (area.instagram_token_updated_at) {
              last_updated = `${moment(area.instagram_token_updated_at).fromNow()} (${moment(area.instagram_token_updated_at).format("MMM Do, YYYY h:mm A")})`;
            }

            let id_pattern = "\d+";
            let token_pattern = "[a-zA-Z0-9_\.]+";

            let instagram_page_id = area.instagram_page_id || "";
            let instagram_page_token = area.instagram_page_token || "";
            let instagram_handle = area.instagram_handle || "";

            let area_updated = false
            let area_update = this.state.area_updates.find(update => update.id == area.id && update.updated);
            let show_copy_token = false;
            if (area_update) {
              instagram_page_id = area_update.instagram_page_id || "";
              instagram_page_token = area_update.instagram_page_token || "";
              instagram_handle = area_update.instagram_handle || "";
              area_updated = true;
              show_copy_token = this.are_values_different(instagram_page_token, area.instagram_page_token)
            }

            let redirect_uri = encodeURIComponent(window.location.origin + "/connect");
              
            return (
              <div key={"a" + area.id} className={"area-info" + (area_updated ? " updated" : "")}>
                <h3>{area.name + " - " + area.state}</h3>
                <div className="instagram-input">
                  <div className="instagram-input-label">Instagram Page ID:</div>
                  <div className="instagram-input-label-sub">{"Last updated: " + last_updated}</div>
                  <input className="builder__textarea" pattern={id_pattern} type="text" data-area-id={area.id} data-value="instagram_page_id" value={instagram_page_id} onInput={this.update_area_field} />
                </div>
                <div className="instagram-input">
                  <div className="instagram-input-label">Instagram Page Handle:</div>
                  <input className="builder__textarea" pattern={token_pattern} type="text" data-area-id={area.id} data-value="instagram_handle" value={instagram_handle} onInput={this.update_area_field} />
                </div>
                <div className="instagram-input">
                  <div className="instagram-input-label">Instagram Access Token:</div>
                  <input className="builder__textarea" pattern={token_pattern} type="text" data-area-id={area.id} data-value="instagram_page_token" value={instagram_page_token} onInput={this.update_area_field} />
                </div>
                <div className="instagram-input">
                  <div className="instagram-input-label">Get Access Token</div>
                  <a onClick={this.popup_oauth} data-id={area.id} href={"https://facebook.com/dialog/oauth?client_id=790687169775305&display=popup&redirect_uri=" + redirect_uri + "&response_type=token&scope=pages_show_list%2Cbusiness_management%2Cinstagram_basic%2Cpage_events"}>
                    Refresh Access Token
                  </a>
                </div>
                <button disabled={!show_copy_token} style={{ marginTop: "10px", opacity: (show_copy_token ? "1" : "0"), cursor: (area_updated ? "pointer" : "default") }} onClick={() => { this.copy_token(area.id) }}>
                  Copy value to all other areas
                </button>
              </div>
            )
          })
        }
      </div>
    )
  }

  render_instagram_page_ids() {
    let instagram_pages = this.state.instagram_pages;

    if (!this.state.show_instagram_page_ids || !instagram_pages) {
      return <></>;
    }

    let pages = (
      <div className="instagram-page-ids">
        <div className="instagram-page-row header">
          <div className="instagram-page-cell">Page Name</div>
          <div className="instagram-page-cell">Instagram Page ID</div>
        </div>
        {
          instagram_pages.map(page => (
            <div key={page.id} className="instagram-page-row">
              <div className="instagram-page-cell">{page.connected_instagram_account.username}</div>
              <div className="instagram-page-cell">{page.connected_instagram_account.id}</div>
            </div>
          ))
        }
      </div>
    )

    return (
      <BaseModal
        title="Instagram page IDs"
        message={pages}
        confirm_button="Close"
        confirm_action={() => this.setState({ show_instagram_page_ids: false })}
      />
    )
  }

  render() {
    return (
      <div className="instagram-info">
        <div className="header_items">
          <div className="left-items">
            <h2>Instagram Info</h2>
            <div className="show-all-areas">
              <div className="show-all-areas-label">Show All Areas:</div>
              <ToggleSwitch
                value={this.state.show_all_areas}
                onChange={this.toggle_show_all_areas}
              />
            </div>
          </div>
          <div className="right-items">
            <input type="button" style={{marginRight: "6px"}} value="Get List of IG Page IDs" onClick={this.get_list_of_pages} className="admin__btn--create-new" />
            <input type="button" value="Save Changes" onClick={this.save_updates} className="admin__btn--create-new" disabled={!this.state.pending_save} />
          </div>
        </div>
        {this.render_area_info()}
        {this.render_instagram_page_ids()}
      </div>
    )
  }
}

export default InstagramInfo;