import React from "react";

// Image Assets
import CityLogo from 'logo-city.png'
import CharlotteLogo from 'logo-charlotte.png'
import RaleighLogo from 'logo-raleigh.png'
import DurhamLogo from 'logo-durham.png'

// Network Requests
import { createOrUpdateStripeCustomer } from "./v2/state_components/network_methods.jsx";
import { validateAccountSubmit, submitSubscriptionPlan } from "./v3/state_components/network_methods_v3.jsx";
import { getScreenDefinitions } from "./v3/screen_definitions/screen_definitions.jsx";
import PostHogEnabled from "../../helpers/posthog_enabled.jsx";
import { data, error } from "jquery";

class SignupV3 extends React.Component {
  constructor(props) {
    super(props);

    // Setup key variables
    let area = this.getAreaFromId(props.selected_market)
      || { name: "Raleigh" };
    let membership_start = new Date().getDate() <= 10 ? "today" : "defer";
    this.city_logos = { CityLogo, CharlotteLogo, RaleighLogo, DurhamLogo }
    let currentScreen = props.currentScreen || 0;
    let signup_version = props.signup_version || "2.0";
    let markets = this.props.areas.map((area) => { return { label: (area.name + ", " + (area.state || "")), value: area.id, city: area.name } });

    if (analytics_loaded) {
      mixpanel.register({
        "Market": area.name,
        "Signup Version": signup_version,
      });
      posthog.register({
        "Market": area.name,
        "Signup Version": signup_version,
      });
    }

    let mixpanel_original_id = null;
    if (analytics_loaded) {
      mixpanel_original_id = mixpanel.get_distinct_id();
    }

    // Setup state
    this.state = {
      currentScreen: props.currentScreen || 0,
      markets,
      mixpanel_original_id,
      selected_market: props.selected_market || 2,
      email: "",
      phone_number: "",
      first_name: "",
      last_name: "",
      reason_to_become_member: "",
      supporting_local: "",
      valid_referral: props.referral_details?.referral?.referral_code || "",
      referral_details: props.referral_details,
      selected_plan: props.selected_plan,
      selected_area: area,
      deferral_only: area.deferral_only,
      membership_start,
      offer_information: props.offer_information,
      selected_offer_1: null,
      selected_offer_2: null,
      when_to_start: null,
      waitlist_id: null,
      setup_intent: null,
      city_logo: this.city_logos[area.name + "Logo"] || CityLogo,
      network_loading_offers: false,
      signup_version,
      version_test_tags: props.version_test_tags || [],
    }

    // bind methods
    this.getNextScreen = this.getNextScreen.bind(this);
    this.previousScreen = this.previousScreen.bind(this);
    this.progressToNextScreen = this.progressToNextScreen.bind(this);
    this.getAreaFromId = this.getAreaFromId.bind(this);
    this.loadScreen = this.loadScreen.bind(this);
    this.updateArea = this.updateArea.bind(this);
    this.updateState = this.updateState.bind(this);
    this.finalAccountInfo = this.finalAccountInfo.bind(this);
    this.finalSubscriptionInfo = this.finalSubscriptionInfo.bind(this);

    // Setup Screen Definitions
    const [screens, screens_order_lookup, default_screen_props]
      = getScreenDefinitions(props, this);
    this.screens = screens;
    this.screens_order_lookup = screens_order_lookup;
    this.default_screen_props = default_screen_props;
    this.state["currentScreenName"] = screens_order_lookup[currentScreen].page_name;

    // Setup Offers cache
    this.offers_cache = {};

    this.pending_submission = false;
  }

  componentDidMount() {
    // we no longer need to reposition the sticky button
    // window.visualViewport.addEventListener("resize", ()=>{
    //   let client_height = parseFloat(window.innerHeight);
    //   let visual_viewport_height = parseFloat(window.visualViewport.height);
    //   let keyboard_offset = 0;

    //   if (/iPhone|iPad|iPod/.test(window.navigator.userAgent)) {
    //     keyboard_offset = (client_height - Math.max(visual_viewport_height, 420));
    //   } else {
    //     keyboard_offset = (client_height - Math.max(visual_viewport_height, 460));
    //   }

    //   document.documentElement.style.setProperty('--keyboard-offset', keyboard_offset.toFixed(2) + "px");
    // });
    if (analytics_loaded) {
      setTimeout(()=>{
        posthog.reloadFeatureFlags();
      }, 1550);
    }
  }

  updateState(updated_state_data, update_screen = false) {

    if (update_screen) {
      let [next_screen_id, next_screen_name] = this.getNextScreen();
      updated_state_data.currentScreen = next_screen_id;
      updated_state_data.currentScreenName = next_screen_name;
    }
    this.setState(updated_state_data);
    this.updateStripeCustomer(updated_state_data);
  }

  finalAccountInfo(updated_state_data) {
    this.setState(updated_state_data);
    this.updateStripeCustomer(updated_state_data);
    this.pending_submission = true;
    let submit_data = {
      email: this.state.email,
      phone_number: this.state.phone_number,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      valid_referral: this.state.valid_referral,
      reason_to_become_member: updated_state_data.reason_to_become_member,
      supporting_local: updated_state_data.supporting_local,
      area_id: this.state.selected_area.id,
      start_immediately: this.state.when_to_start,
      waitlist_id: this.state.waitlist_id,
      intent_id: this.state.setup_intent?.id,
      customer_id: this.state.setup_intent?.customer
    }

    validateAccountSubmit(submit_data, (data) => {
      this.pending_submission = false;
      let data_to_update = {};
      if (data.waitlist_id) {
        data_to_update.waitlist_id = data.waitlist_id;
      }

      let [next_screen_id, next_screen_name] = this.getNextScreen();
      data_to_update.currentScreen = next_screen_id;
      data_to_update.currentScreenName = next_screen_name;

      this.setState(data_to_update);
    }, error => {
      this.pending_submission = false;
      // for (let e of error.responseJSON.errors) {
      //   if (e.type == "email" || e.type == "invalid-email") {
      //     this.setState({ error_email: e.message });
      //   }
      //   if (e.type == "phone-number") {
      //     this.setState({ error_phone_number: e.message });
      //   }
      // }
    });
  }

  finalSubscriptionInfo(updated_state_data) {
    this.setState(updated_state_data);
    this.updateStripeCustomer(updated_state_data);
    this.pending_submission = true;
    let submit_data = {
      start_immediately: updated_state_data.when_to_start,
      selected_plan: updated_state_data.selected_plan,
      waitlist_id: this.state.waitlist_id
    }

    submitSubscriptionPlan(submit_data, (data) => {
      this.pending_submission = false;
      let data_to_update = {};
      let [next_screen_id, next_screen_name] = this.getNextScreen();
      data_to_update.currentScreen = next_screen_id;
      data_to_update.currentScreenName = next_screen_name;

      this.setState(data_to_update);
    }, error => {
      this.pending_submission = false;
    });
  }

  updateStripeCustomer(params_being_updated) {
    let state_copy = Object.assign({}, this.state);
    Object.assign(state_copy, params_being_updated);
    let params = {
      email: state_copy.email,
      phone_number: state_copy.phone_number,
      first_name: state_copy.first_name,
      last_name: state_copy.last_name,
      valid_referral: state_copy.valid_referral,
      plan_id: state_copy.plan_id,
      reason_to_become_member: state_copy.reason_to_become_member,
      supporting_local: state_copy.supporting_local,
      area_id: state_copy.selected_area.id,
      start_immediately: state_copy.when_to_start,
      selected_offer_1: state_copy.selected_offer_1,
      selected_offer_2: state_copy.selected_offer_2,
      waitlist_id: state_copy.waitlist_id,
      intent_id: state_copy.setup_intent?.id,
      customer_id: state_copy.setup_intent?.customer,
      mixpanel_original_id: state_copy.mixpanel_original_id,
    };

    createOrUpdateStripeCustomer(params, (data) => {
      if (data.intent) {
        this.setState({ setup_intent: data.intent })
        if (analytics_loaded) {
          if (mixpanel.get_distinct_id() != data.intent.customer) {
            mixpanel.alias(data.intent.customer, mixpanel.get_distinct_id());
            mixpanel.identify(data.intent.customer);
            posthog.identify(data.intent.customer);
          }
        }
      }
    });
  }

  // helper methods
  getAreaFromId(id) {
    let areas = this.props.areas || [];
    return areas.filter(area => area.id == id)[0];
  }

  // screen navigation methods
  getNextScreen() {
    let currentScreen = this.state.currentScreen;
    let nextScreen = this.screens_order_lookup[currentScreen + 1];
    if (!nextScreen) return [this.state.currentScreen, this.state.currentScreenName];
    return [this.state.currentScreen + 1, nextScreen.page_name];
  }

  previousScreen() {
    let currentScreen = this.state.currentScreen;
    let previousScreen = this.screens_order_lookup[currentScreen - 1];
    if (!previousScreen) return [this.state.currentScreen, this.state.currentScreenName];
    this.setState({ currentScreen: this.state.currentScreen - 1, currentScreenName: previousScreen.page_name });
  }

  progressToNextScreen() {
    let currentScreen = this.state.currentScreen;
    let nextScreen = this.screens_order_lookup[currentScreen + 1];
    if (!nextScreen) return [this.state.currentScreen, this.state.currentScreenName];
    this.setState({ currentScreen: this.state.currentScreen + 1, currentScreenName: nextScreen.page_name });
  }

  loadScreen(id) {
    let screen = this.screens_order_lookup[id];
    if (!screen || !screen.component) return (<></>);
    let ScreenComponent = screen.component;
    let screenProps = {
      ...this.default_screen_props,
      ...screen.specific_props
    };

    if (screen.specific_props_from_state) {
      for (let [key, value] of Object.entries(screen.specific_props_from_state)) {
        screenProps[key] = this.state[value]
      }
    }

    // Load gtm id information
    screenProps["gtm_screen_name"] = screen.gtm_screen_name
    screenProps["signup_version"] = this.state.signup_version
    screenProps["version_test_tags"] = this.state.version_test_tags
    screenProps["markets"] = this.state.markets
    screenProps["selected_market"] = this.state.selected_market
    screenProps["previousScreen"] = this.previousScreen;
    screenProps["currentScreen"] = this.state.currentScreen;

    return <ScreenComponent {...screenProps} />;
  }

  updateArea(action, area_id) {
    let area = this.getAreaFromId(area_id);
    let update_state = {
      selected_area: area,
      selected_market: area.id,
      deferral_only: area.deferral_only,
    }

    let new_url = '/signup3/' + area.slug + window.location.search
    history.pushState({ pageID: area.slug }, area.slug, new_url);
    this.setState(update_state);

    if (analytics_loaded) {
      posthog.capture('Area Changed', {
        "Area": area.name,
      });
      setTimeout(()=>{
        posthog.reloadFeatureFlags();
      }, 2050);
    }
  }

  render() {
    return (
      <div className="signup-v3-body">
        <div className={"signup-v3-content " + this.state.currentScreenName}>
          {this.loadScreen(this.state.currentScreen)}
        </div>
      </div>
    );
  }
}

export default SignupV3