import React from 'react';
import CityLogo from 'logo-city.png'
import LoadingIcon from 'loading.gif';
import FooterSkyline from 'footer-raleigh-skyline.svg'

class GiftCodeConfirmation extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props)
    this.state = {
      poll_count: 0,
      status: 'pending'
    };

    this.intervalId = null;
    this.getGiftStatus = this.getGiftStatus.bind(this);
  }

  componentDidMount() {
    this.intervalId = setInterval(this.getGiftStatus, 1000);
  }

  getGiftStatus() {
    $.ajax({
      method: "GET",
      url: `/gift-status?payment_intent_id=${this.props.gift_code.payment_intent_id}`,
      success: (data) => {
        this.setState({ status: data.status });
        clearInterval(this.intervalId);
      },
      error: (error) => {
        console.log(error)
      }
    });

    if (this.state.poll_count >= 30) {
      clearInterval(this.intervalId);
    } else {
      this.setState({ poll_count: this.state.poll_count + 1 });
    }
  }

  render() {
    const { status } = this.state;

    return (

      <section className='signup text-center'>
        <div className='header'>
          <img className='signup__market-logo' alt="Offline" src={CityLogo} />
        </div>
        <div className='signup__body'>
          <h1 className="font-italic"><strong>Gift a membership</strong></h1>

          <div className="narrow__form text-left" id='gift-form'>
            <div className='narrow__form-body'>
              {status === 'succeeded' ?
                <div className='purchase-status'>
                  <div>Purchase Complete!</div>
                  <div>We can’t wait to see you Offline!</div>
                </div>
                :
                <div className='purchase-status'>
                  <div className='purchase-status'>Please wait while we process your gift purchase</div>
                  <div className='purchase-sub-status'>This should only take a moment</div>
                  <img className="" src={LoadingIcon} />
                </div>
              }
            </div>
          </div>
        </div>
        <div className="signup__footer">
          <img src={FooterSkyline} alt='Offline Loves Your City' />
        </div>
      </section >
    );
  }
};

export default GiftCodeConfirmation;