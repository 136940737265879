import React from "react";

class FindOffersSection extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className="signup-bubble-sections find-offers-section">
        <div className="find-offers-content">
          <div className="bubble-title">Find locally-owned restaurants worth trying</div>
          <div className="bubble-subtitle">Our team curates Raleigh's best restaurants, and you'll pick 2 + get <b>$10-$30 to spend at each, </b>every month. </div>
          <div className="find-offers-flex">
            {
              this.props.popular_offers.map((offer, index) => {
                let offer_amount = offer.offer_amount.toUpperCase();
                let offer_amount_min = offer_amount.match(/\$\d+ TO SPEND/);
                offer_amount_min = offer_amount_min ? offer_amount_min[0] : offer_amount;

                return (
                  <div key={index} className="offer-container">
                    <div className="offer-content">
                      <div className="offer-image">
                        <img src={offer.image} alt="" />
                      </div>
                      <div className="offer-amount">{offer_amount_min}</div>
                      <div className="offer-title">{offer.name}</div>
                      <div className="offer-description">{offer.business_teaser}</div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default FindOffersSection