import React from "react";

class ReviewToggle extends React.Component {
  constructor(props) {
    super(props);
    this.updateSurveyAnswer = this.updateSurveyAnswer.bind(this);
  }

  updateSurveyAnswer(event) {
    var value = event.target.getAttribute("value");
    this.props.onToggleChange(this.props.question_key, value);
  }

  render() {
    const current_value = this.props.value || '';
    return (
      <>
        <div>{this.props.question}</div>
        <div className="feedback-question" style={{paddingBottom: '20px'}}>
          <div style={{marginTop: '0'}}>
            <label 
              className={current_value == "yes" ? 'selected' : ''}
              value="yes" 
              onClick={this.updateSurveyAnswer}>Yes
            </label>
          </div>
          <div style={{marginTop: '0'}}>
            <label 
              className={current_value == "no" ? 'selected' : ''}
              value="no" 
              onClick={this.updateSurveyAnswer}>No
            </label>
          </div>
          <div></div>
        </div>
      </>
    ); 
  }
}

export default ReviewToggle