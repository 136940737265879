import React from "react";
import PlanComparisonCard from "./plan_comparison_card";
import moment from "moment-timezone";

class PlanComparison extends React.Component {
  constructor(props) {
    super(props);

    let areas = ["Raleigh", "Durham", "Charlotte", "Nashville", "Tampa Bay"];
    if(this.props.areas) {
      areas = this.props.areas.filter(area => (!area.start_date || area.start_date <= moment().format("YYYY-MM-DD"))).map(area => area.name);
    }

    let last_area = areas.pop();

    let travel_areas = areas.join(", ") + (areas.length > 1 ? ", and " : "") + last_area;

    this.summaries = [
      {
        title: 'Pick 2 local restaurants per month'
      },
      {
        title: '$10-30 to spend at each'
      },
      {
        title: '2 annual restaurant picks',
        plan: 'annual',
        bottom_offset: 56,
        content: <span><b>Annual Offers:</b> Pick 2 extra restaurants, valid for a whole year. You'll have 365 days before they expire, and you'll pick again on your Offline anniversary</span>,
      },
      {
        title: 'Swap restaurants anytime',
        plan: 'annual',
        bottom_offset: 56,
        content: <span><b>Swap Offers:</b> Switch any restaurant you haven't used yet before the end of the month.</span>,
      },
      {
        title: 'Pick restaurants in any Offline city',
        plan: 'annual',
        bottom_offset: 72,
        content: <span><b>Offline Travel:</b> Pick restaurants in any Offline city. Currently in {travel_areas}.</span>,
      },
      {
        title: 'Get early access to exclusive events',
        plan: 'annual',
        bottom_offset: 92,
        content: <span><b>Early Access:</b> Get exclusive access to all our exciting events + happenings before monthly members.</span>,
      },
    ];

    this.state = {
      plan: "annual"
    };
  }

  render() {

    return (
      <div className="plan-comparison">
        {this.props.plans.map((plan, idx) =>
          <PlanComparisonCard
            key={idx}
            price={plan.price}
            special_price={plan.special_price}
            plan={plan.name}
            save={plan.name === "annual" ? parseInt(this.props.save_percentage) : 0}
            special_save_percentage={(plan.name === "annual" && this.props.special_save_percentage) ? parseInt(this.props.special_save_percentage) : null}
            selectPlan={this.selectPlan}
            isSelected={this.props.selected_plan === plan.name}
            summaries={this.summaries}
            onSelectPlan={this.props.onSelectPlan}
          />
        )}
      </div>
    );
  }
};

export default PlanComparison
