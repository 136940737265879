import React from "react";

class EventReportFormatter extends React.Component {
  constructor(props) {
    super(props);

    this.data_rules = {
      // Top cells
      "overall_rating": {
        "null_message": "NO DATA",
        "formatter": this.noFormat
      },
      "tickets_sold": {
        "label": "Tickets Sold",
        "null_message": "NO DATA",
        "formatter": this.noFormat
      },
      "checked_in": {
        "label": "Checked In",
        "null_message": "NO DATA",
        "formatter": this.noFormat
      },
      "first_time_customer": {
        "label": "1st Time Customer",
        "null_message": "NO DATA",
        "formatter": this.percentValue
      },
      "would_go_again": {
        "label": "Would Return",
        "null_message": "NO DATA",
        "formatter": this.percentValue
      }

    }

    this.data_presenter = this.data_presenter.bind(this);
    this.label_presenter = this.label_presenter.bind(this);
    this.percentValue = this.percentValue.bind(this);
    this.dollarValue = this.dollarValue.bind(this);
    this.noFormat = this.noFormat.bind(this);
  }

  noFormat(value) {
    return value;
  }

  percentValue(value) {
    if (!value) return "NO DATA";
    if (value >= -1 && value <= 1) value = value * 100;
    if (typeof value === 'number') return Math.floor(value).toFixed(0) + '%';
    return value;
  }

  dollarValue(value, null_message = "NO DATA") {
    if (typeof value === 'number') return `${value < 0 ? '-' : ''}$${Math.abs(value).toFixed(2)}`;
    if (value.includes('-')) {
      return "-$" + value.replace('-', '');
    } else {
      return "$" + value;
    }
  }

  data_presenter(name, value) {
    name = name.startsWith("ex_") ? name.replace("ex_", "") : name; // 'ex_' prefix means the value is extrapolated.  We don't do anything with this info currently.
    if(!value || isNaN(parseFloat(value))) {
      if (value && typeof value === "string"){
        return value;
      }
      return this.data_rules[name].null_message;
    } else {
      let mod = this.data_rules[name].display_as_negative ? -1 : 1;
      return this.data_rules[name].formatter(value * mod, this.data_rules[name].null_message);
    }
  }

  label_presenter(name, value) {
    name = name.startsWith("ex_") ? name.replace("ex_", "") : name; // 'ex_' prefix means the value is extrapolated.  We don't do anything with this info currently.
    let val_num = parseFloat(value);
    if(isNaN(val_num)) {
      return this.data_rules[name].label;
    } else if (val_num < 0){
      return this.data_rules[name].negative_label || this.data_rules[name].label;
    } else {
      return this.data_rules[name].label;
    }
  }
  
  render() {
    return (<></>)
  }
}

export default EventReportFormatter