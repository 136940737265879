import React from "react";


class PausedAlert extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    if (this.props.date_to_unpause == null) {
      return (
        <div className='sticky-notification notice'>
          <h5>Your account is permanently paused</h5>
          <p><small><i>Please write into support if you want to unpause.</i></small></p>
        </div>
      )
    } else {
      const date = new Date(this.props.date_to_unpause);
      const unpause_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 3);
      const month_name = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      return (
        <div className='sticky-notification notice'>
          <h5>Your account is paused until:</h5>
          <h3>{month_name[unpause_date.getMonth()]} 1st, {unpause_date.getFullYear()}</h3>
          <p><small><i>Please write into support if you want to unpause early.</i></small></p>
        </div>
      )
    }
  }
}

export default PausedAlert