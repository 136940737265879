import React from "react";

class CYOOFlowGUI extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        {this.props.top || (<div></div>)}
        {this.props.center || (<div></div>)}
        {this.props.bottom || (<div></div>)}
      </>
    );
  }
}

export default CYOOFlowGUI