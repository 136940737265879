import React from "react";
import NameAndReferralCodeGUI from "../gui_components/name_and_referral_code_gui";
import SignupHeaderV3 from "../gui_components/signup_header_v3";

class NameAndReferralCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: this.props.first_name || "",
      last_name: this.props.last_name || "",
      referral_code: this.props.valid_referral || "",
      valid_referral: this.props.valid_referral || "",
      referral_details: this.props.referral_details || null,
      error_first_name: null,
      error_last_name: null,
      error_referral_code: null,
    }

    this.changeInputField = this.changeInputField.bind(this);
    this.changeState = this.changeState.bind(this);
    this.checkErrors = this.checkErrors.bind(this);
    this.validateAccountDetails = this.validateAccountDetails.bind(this);
    this.receiveReferralDetails = this.receiveReferralDetails.bind(this);
    this.clearedReferralCode = this.clearedReferralCode.bind(this);
  }

  componentDidMount() {
    this.setMixpanelAquisition(this.props.referral_details);
    if (analytics_loaded) {
      mixpanel.track("Signup: Name");
      posthog.capture("Signup: Name");
    }
  }

  changeState(key, value) {
    this.setState({ [key]: value });
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;

    let check_state = Object.assign({}, this.state);
    check_state[name] = target.value;

    let check_errors = this.checkErrors(check_state);
    let update_state = check_errors.update_state;
    for (let update in check_errors.update_state) {
      if (check_errors.update_state[update]) {
        delete update_state[update];
      }
    }

    if (target.type == "checkbox") {
      update_state[name] = target.checked;
      return this.setState(update_state);
    } else {
      update_state[name] = target.value;
      return this.setState(update_state)
    }
  }

  setMixpanelAquisition(referral_details) {
    if (analytics_loaded && referral_details) {
      var aquistion = "Partner referral";
      if (referral_details.type == "user") {
        aquistion = "Account referral";
      } else if (referral_details.type == "giftcode") {
        aquistion = "Gift code";
      }
      mixpanel.people.set({ "Acquisition Type": aquistion });
    }
  }

  checkErrors(local_state = this.state) {
    let valid = true;
    let update_state = {
      error_first_name: local_state.error_first_name,
      error_last_name: local_state.error_last_name,
      error_referral_code: local_state.error_referral_code,
    };
    if (!local_state.first_name) {
      valid = false;
      update_state.error_first_name = "Please enter your first name.";
    } else {
      update_state.error_first_name = null;
    }

    if (!local_state.last_name) {
      valid = false;
      update_state.error_last_name = "Please enter your last name.";
    } else {
      update_state.error_last_name = null;
    }

    if (!local_state.valid_referral) {
      valid = false;
      update_state.error_referral_code = "Please apply a valid referral code.";
    } else {
      update_state.error_referral_code = null;
    }

    return { valid, update_state };
  }

  validateAccountDetails() {
    let check_errors = this.checkErrors();
    let valid = check_errors.valid;
    let update_state = check_errors.update_state;

    if (valid) {
      // go forwards
      this.props.updateState({
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        valid_referral: this.state.valid_referral
      }, true)
    } else {
      // show errors
    }
    this.setState(update_state);
  }

  receiveReferralDetails(referral_code, referral_details) {
    this.setMixpanelAquisition(referral_details);
    let checkState = Object.assign({}, this.state);
    Object.assign(checkState, { referral_details: referral_details, valid_referral: referral_code, referral_code: referral_code });
    let check_errors = this.checkErrors(checkState);
    let error_referral_code = check_errors.update_state.error_referral_code;

    let update_state = { error_referral_code };
    update_state.referral_details = referral_details;
    update_state.valid_referral = referral_code;
    update_state.referral_code = referral_code;
    this.setState(update_state);
  }

  clearedReferralCode() {
    this.setState({ referral_details: null, valid_referral: "", referral_code: "" });
  }

  render() {
    return (
      <>
        <SignupHeaderV3
          markets={this.props.markets}
          selected_market={this.props.selected_market}
          updateStateValue={this.props.updateArea}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
          select_enabled={true}
          currentScreen={this.props.currentScreen}
          total_screens={this.props.total_screens}
        />
        <NameAndReferralCodeGUI
          changeInputField={this.changeInputField}
          first_name={this.state.first_name}
          last_name={this.state.last_name}
          referral_code={this.state.valid_referral}
          error_first_name={this.state.error_first_name}
          error_last_name={this.state.error_last_name}
          error_referral_code={this.state.error_referral_code}
          valid_referral={this.state.valid_referral}
          referral_details={this.state.referral_details}
          onRefferalSubmit={this.receiveReferralDetails}
          onReferralClear={this.clearedReferralCode}
          signup_version={this.props.signup_version}
          gtm_screen_name={this.props.gtm_screen_name}
          version_test_tags={this.props.version_test_tags}
          validateAccountDetails={this.validateAccountDetails}
          selected_market={this.props.selected_market}
        />
      </>
    );
  }
}

export default NameAndReferralCode