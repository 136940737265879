import React from "react";
import { convertDateString } from '../../helpers/datestring_formatter';

class CityBulletListSelect extends React.Component {
  constructor(props) {
    super(props);

    this.active_areas = props.areas.filter(area => (!area.start_date || (new Date() >= new Date(area.start_date))));
    this.upcoming_areas = props.areas.filter(area => (area.start_date && (new Date() < new Date(area.start_date))));
    
    this.state = {
      selected_area_id: props.selected_area_id || 0
    }

    this.className = this.props.className || "";
    this.onSelectOption = this.onSelectOption.bind(this);
    this.areaLaunchDate = this.areaLaunchDate.bind(this);
  }

  areaLaunchDate(area) {
    if (!area.start_date) return "";
    let date = convertDateString(area.start_date);
    return date.toLocaleString('en-US', {month: 'long', day: 'numeric', year: 'numeric'});
  }

  onSelectOption(event) {
    let value = event.target.value;
    this.setState({ selected_area_id: value })
    if (this.props.onChange) this.props.onChange("selected_market", value);
  }

  renderActiveAreas() {
    let options = this.active_areas.map(area => 
      <div className="radio-option" key={area.id}>
        <input
          type="radio"
          id={area.id}
          value={area.id}
          name="selected_area_id"
          checked={area.id == this.state.selected_area_id}
          onChange={this.onSelectOption} />
        <label htmlFor={area.id}>{area.name}, {area.state}</label>
      </div>
    );
    return (
      <>
        <div className="input-label">LIVE (Join Today)</div>
        {options}
      </>
    )
  }

  renderUpcomingAreas() {
    if (this.upcoming_areas.length == 0) {
      return "";
    }
    let options = this.upcoming_areas.map(area => 
      <div className="radio-option" key={area.id}>
        <input
          type="radio"
          id={area.id}
          value={area.id}
          name="selected_area_id"
          checked={area.id == this.state.selected_area_id}
          onChange={this.onSelectOption} />
        <label htmlFor={area.id}>{area.name}, {area.state} (launches: {this.areaLaunchDate(area)})</label>
      </div>
    );
    return (
      <>
        <div className="input-label">PRE-LAUNCH (Reserve Your Spot)</div>
        {options}
      </>
    )
  }


  render() {
    return (
      <div className={this.className + " radio-grouping"}>
        {this.renderActiveAreas()}
        {this.renderUpcomingAreas()}
      </div>
    );
  }
}

export default CityBulletListSelect