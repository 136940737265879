import React from 'react';
import moment from 'moment';

class OfferCreditsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: props.account,
      available_credits: props.available_credits,
      used_credits: props.used_credits,
      credit_history: props.credit_history,
      bonus_credits: props.bonus_credits,
      standard_credits: props.standard_credits
    };
  }

  renderCreditHistoryItem(item, index) {
    const isPendingRedemption = item.current_offer_assignment_id && !item.is_used;
    const isRedeemed = item.value === 'Redeemed';
  
    return (
      <div key={index} className="history-item">
        <div className="item-details">
          <div className="item-type">{item.type}</div>
          <div className="item-date">{moment(item.date).format('MMMM D, YYYY')}</div>
          {item.usage_note && <div className="item-usage-note">{item.usage_note}</div>}
          {item.bonus && <div className="item-bonus">Bonus Credit</div>}
          {item.offer_assignment && (
            <div className="item-offer">
              <div>Offer: {item.offer_assignment.offer.name}</div>
              {item.offer_assignment.date_redeemed && (
                <div>Redeemed: {moment(item.offer_assignment.date_redeemed).format('MMMM D, YYYY')}</div>
              )}
            </div>
          )}
        </div>
        <div className={`item-value ${isPendingRedemption ? 'pending' : (isRedeemed ? 'redeemed' : 'positive')}`}>
          {isPendingRedemption ? "Pending Redemption" : 
           isRedeemed ? item.value :
           `+${item.value}`}
        </div>
      </div>
    );
  }

  render() {
    const { account, available_credits, used_credits, credit_history, bonus_credits, standard_credits } = this.state;

    return (
      <div className="offer-credits-page">
        <h1 className="page-title">Offer Credits for {account.first_name} {account.last_name}</h1>
        
        <div className="account-info">
          <p>Email: {account.email}</p>
          <p>Premium ID: {account.premium_id}</p>
        </div>
        
        <div className="credits-summary">
          <h2 className="summary-title">Credits Summary</h2>
          <div className="summary-content">
            <div className="summary-item">
              <div className="item-value">{available_credits}</div>
              <div className="item-label">Available Credits</div>
            </div>
            <div className="summary-item">
              <div className="item-value">{standard_credits}</div>
              <div className="item-label">Subscription Credits</div>
            </div>
            <div className="summary-item">
              <div className="item-value">{bonus_credits}</div>
              <div className="item-label">Bonus Credits</div>
            </div>
            <div className="summary-item">
              <div className="item-value">{used_credits}</div>
              <div className="item-label">Used Credits</div>
            </div>
          </div>
        </div>

        <div className="credits-history">
          <h2 className="history-title">Credits History</h2>
          {credit_history.map((item, index) => this.renderCreditHistoryItem(item, index))}
        </div>
      </div>
    );
  }
}

export default OfferCreditsPage;