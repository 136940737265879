import React from "react";

class SignupFooter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className={"signup-footer" + (this.props.sticky? " sticky" : "")}>
          <div className="signup-footer-content">
            {this.props.top   || (<div></div>)}
            {this.props.center || (<div></div>)}
            {this.props.bottom  || (<div></div>)}
          </div>
        </div>
        {this.props.sticky && 
          <div className="signup-footer-sticky-spacer"></div>
        }
      </>
    );
  }
}

export default SignupFooter