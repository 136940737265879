import React from "react";
import moment from 'moment-timezone';
import UpdateTable from "../shared/update-table";

class EventUpcomingReminders extends React.Component {
  constructor(props) {
    super(props);

    this.event_date_map = {};
    this.event_date_map_by_id = {};
    this.event_map = {};
    this.chart_assignment_changes = [];
    let render_assignments = [];

    this.props.events.forEach(event => {
      event.event_dates.forEach(eventDate => {
        this.event_date_map_by_id[eventDate.id] = eventDate.date;
        this.event_date_map[eventDate.date] = eventDate;
      });

      this.event_map[event.id] = event;
      if (event.event_assignments) {
        let event_assignments = JSON.parse(JSON.stringify(event.event_assignments));
        this.chart_assignment_changes = [...this.chart_assignment_changes, ...event_assignments];
        render_assignments = [...render_assignments, ...event_assignments];
      }
    });

    this.state = {
      render_assignments
    }

    this.renderUpcomingAssignmentTable = this.renderUpcomingAssignmentTable.bind(this);
    this.getNotificationDateStringFromEventDateID = this.getNotificationDateStringFromEventDateID.bind(this);
  }

  formatDateString(date) {
    if (!date) return "";
    return moment(date).format("MMM Do YYYY");
  }

  getNotificationDateStringFromEventDateID(event_date_id) {
    let browser_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let time_moment = moment(this.event_date_map_by_id[event_date_id]).tz('UTC', true).set({ 'hour': 20, 'minute': 0, 'second': 0 }).subtract('1', 'day').tz(browser_timezone);
    let abbr = " " + time_moment.zoneAbbr();
    return `Upcoming Reminder: ${time_moment.format("MMM Do YYYY, h:mm A") + abbr}`
  }

  renderUpcomingAssignmentTable() {
    let table_row_templates = [
      {
        data_label: "token",
        value_name: "token",
        formatter: (v) => {
          let assignment = this.state.render_assignments.filter(ra => ra.token == v)[0];
          let val = "Event Assignment"
          if (assignment) {
            let event_name = this.event_map[assignment.event_id].name;
            val = `${assignment.account.first_name} ${assignment.account.last_name} (${event_name})`;
            return (<a href={"/accounts/" + assignment.account.slug + "/edit"} target="_blank" >{val}</a>)
          }
          return (<></>)
        }
      },
      {
        data_label: "premium_id",
        value_name: "premium_id"
      },
      {
        data_label: "event_date_id",
        value_name: "event_date_id",
        formatter: (v) => { return this.formatDateString(this.event_date_map_by_id[v]) }
      },
      {
        data_label: "timeslot",
        value_name: "timeslot"
      },
      {
        data_label: "tickets",
        value_name: "tickets"
      },
      {
        data_label: "event_date_id",
        value_name: "event_date_id",
        formatter: this.getNotificationDateStringFromEventDateID
      },
    ]
    let now = moment();
    let tomorrow = moment().startOf('day').add('1', 'day');
    let cron_cutoff = moment().tz('UTC', true).set({ 'hour': 20, 'minute': 0, 'second': 0 });
    let a_week_from_tomorrow = moment(tomorrow).add('1', 'week');
    let sorted_assignments = [];

    // Filter down to all assignments whose event date is within the next week
    sorted_assignments = [...this.state.render_assignments].filter(assignment => {
      let assignment_moment = moment(this.event_date_map_by_id[assignment.event_date_id]);

      // If the assignment's event_date is tomorrow, only show as upcoming if it's
      // before the cron job runs at 7:00PM EST
      if (assignment_moment.isSame(now.clone().add('1', 'day'), 'day')) {
        return now.isBefore(cron_cutoff);
      }

      return moment(this.event_date_map_by_id[assignment.event_date_id]).isBetween(tomorrow, a_week_from_tomorrow, null, []);
    });
    sorted_assignments = sorted_assignments.sort((a, b) => a.event_id - b.event_id);
    // Filter out assignments that have recieved the 'event_day_of_reminder' notification
    sorted_assignments = sorted_assignments.filter(assignment => !assignment.sent_notifications.includes(",event_day_of_reminder:"));
    // Clean Objects so they do not mutate props
    sorted_assignments = sorted_assignments.map(v => Object.assign({}, v));

    sorted_assignments.sort(this.sortAccountIds);
    sorted_assignments.sort(this.sortChanges);

    return (
      <>
        <UpdateTable
          header_labels={[
            "EVENT TICKET",
            "OFFLINEID",
            "DATESLOT",
            "TIMESLOT",
            "TICKETS",
            "UPCOMING CIO NOTIFICATIONS",
          ]}
          row_templates={table_row_templates}
          rows={sorted_assignments}
          selectable={false}
          loading_data={this.state.updating_notification_table}
          override_height="400px"
        />
      </>
    )
  }

  render() {
    return (
      <>
        <div style={{ padding: ' 0 2.5rem' }}>
          <h3>Upcoming Reminders:</h3>
          {this.renderUpcomingAssignmentTable()}
        </div>
      </>
    );
  }
}

export default EventUpcomingReminders
