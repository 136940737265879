import React from "react";

class MemberAppInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { account, is_collapsed, collapse_action } = this.props;
    let collapsed_style = {};
    let caret_style = "admin__caret admin__caret-open";
    if (is_collapsed) {
      collapsed_style = { display: "none" };
      caret_style = "admin__caret";
    }

    return (
      <>
        <div id="subs-data-wrapper">
          <h2 onClick={() => {
            collapse_action.action(collapse_action.args);
          }}>
            Mobile App:{" "}
            <span
              className={caret_style}
              data-section-id="member-app-section"
            />
          </h2>
          <div id="member-app-section" style={collapsed_style}>
            <div
              style={{
                marginTop: "1em",
                display: "inline-flex",
                alignItems: "flex-start"
              }}
            >
              <div>
                <h3>Reminder Frequency:</h3>
                <div style={{ marginLeft: "1em" }}>{account.reminder_frequency}</div>
              </div>
              <div style={{ marginLeft: "6em" }}>
                <h3>Accepted Permissions:</h3>
                <ul style={{ marginTop: 0 }}>
                  {(account.accepted_permissions || "").split(',').map((permission, index) => (
                    <li key={index}>{permission.trim()}</li>
                  ))}
                </ul>
              </div>
              <div style={{ marginLeft: "6em" }}>
                <h3>Platform:</h3>       
                {account.misc_data?.platform &&
                  <div style={{ marginLeft: "1em" }}>{account.misc_data.platform + " - " + (account.app_version || "")}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MemberAppInfo;