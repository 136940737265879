import React from "react";
import FormSelect from "../shared/form_select";
import FormBuilder from "../shared/form_builder";

import DatePicker from 'react-date-picker';
import { convertDateString } from '../../helpers/datestring_formatter'

import FormMarkdownInput from "../shared/form_markdown_input";
import ImageCollectionManager from "../images/image_collection_manager";
import FinePrintDays from "../shared/fine_print_days";
import MultiPlacesAutocomplete from "../shared/multi_places_autocomplete";
import ContentSection from "../shared/content_section";
import DontForgetItemSelection from "./dont_forget_item_selection";
import OfferTypeSelection from "./offer_type_selection";
import OfferAppPreview from "./offer_app_preview";
import LocationPicker from "../shared/location_picker";

class OfferEdit extends React.Component {
  constructor(props) {
    super(props);

    let is_expensive = this.props.pref_preference_level == 2;
    let active_market = null;

    if (props.areas.length && props.locations.length && props.location_ids.length) {
      let area = this.props.areas.filter(area => area.id == this.props.locations.filter(location => location.value == this.props.location_ids[0])[0].area_id)[0];
      active_market = area;//area? area.abbreviation : null;
    }

    this.has_start_date = this.props.start_date ? true : false;

    // TODO: Update state params to be for OFFER
    this.state = {
      name: props.name || '',
      business_name: props.business_name || '',
      business_teaser: props.business_teaser || '',
      instagram_link: props.instagram_link || '',
      website: props.website || '',
      description: props.description || '',
      reserve_a_table_link: props.reserve_a_table_link || '',
      google_review_url: props.google_review_url || '',
      offer_amount: props.offer_amount,
      send_sms: props.send_sms,
      custom_sms_message: props.custom_sms_message,
      new_header_image: null,
      new_header_image_url: props.header_image_url,
      location_ids: props.location_ids || [],
      category_ids: props.category_ids || [],
      tag_ids: props.tag_ids || [],
      addresses: props.addresses,
      fine_print_days: props.fine_print_days,
      sections: [...props.sections],
      pref_value: props.pref_value,
      pref_min_capacity: props.pref_min_capacity,
      pref_target_capacity: props.pref_target_capacity,
      pref_max_capacity: props.pref_max_capacity,
      auto_grat: props.auto_grat,
      dont_forget_item_ids: props.dont_forget_item_ids || false,
      offer_type_id: props.offer_type_id,
      menu_button: props.menu_button || '',
      web_button: props.web_button || '',
      instagram_button: props.instagram_button || '',
      important_notes: props.important_notes,
      is_dinein_only: props.is_dinein_only,
      pref_preference_level: is_expensive,
      extendable: props.extendable,
      extendable_through: convertDateString(props.extendable_through),
      start_date: convertDateString(props.start_date),
      end_date: convertDateString(props.end_date),
      video_url: props.video_url || '',
      unsaved_changes: false,
      hubspot_id: props.hubspot_id,
      hubspot_name: props.hubspot_name,
      hubspot_ig: props.hubspot_ig,
      status_message: {
        message: "",
        warning: false,
        hide: true
      },
      active_market,
      new_partner_announced_date: props.new_partner_announced_date
    }

    this.new_section_count = 0;
    this.first_load = true;
    this.message_interval = null;
    this.show_save_message_for = 4000; // duration in ms

    this.changeInputField = this.changeInputField.bind(this);
    this.changeDropdownSelection = this.changeDropdownSelection.bind(this);
    this.saveOfferChanges = this.saveOfferChanges.bind(this);
    this.changeDateField = this.changeDateField.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.selectAllLocations = this.selectAllLocations.bind(this);
    this.froalaEditorUpdate = this.froalaEditorUpdate.bind(this);
    this.onAddressUpdate = this.onAddressUpdate.bind(this);
    this.finePrintDayUpdate = this.finePrintDayUpdate.bind(this);
    this.onSectionUpdate = this.onSectionUpdate.bind(this);
    this.onSectionCreate = this.onSectionCreate.bind(this);
    this.onSectionDelete = this.onSectionDelete.bind(this);
    this.onSectionChangePosition = this.onSectionChangePosition.bind(this);
    this.updateStateItem = this.updateStateItem.bind(this);
    this.clearMessage = this.clearMessage.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.announceNewPartner = this.announceNewPartner.bind(this);
  }

  componentDidMount() {
    // Fix for Emotion CSS not caching properly
    let emotion_style = document.querySelector('[data-emotion-css]');
    if (!emotion_style) {
      emotion_style = document.createElement('style');
      emotion_style.setAttribute('data-emotion-css', '');
      emotion_style.innerHTML = [...document.querySelectorAll('[data-emotion]')].flatMap(({ sheet }) => [...sheet.cssRules].map(rules => rules.cssText)).join('\n');
      document.head.appendChild(emotion_style);
    }
  }

  froalaEditorUpdate(data) {
    this.setState({ description: data });
  }

  changeInputField(event) {
    const target = event.target;
    const name = target.name;
    if (target.type == "checkbox") {
      return this.setState({ [name]: target.checked });
    }

    this.setState({ [name]: target.value });
  }

  updateStateItem(key, value) {
    if (key == "location_ids") {
      let active_market = null;

      if (this.props.areas.length && this.props.locations.length && value.length) {
        let area = this.props.areas.filter(area => area.id == this.props.locations.filter(location => location.value == value[0])[0].area_id)[0];
        active_market = area;
      }
      return this.setState({ [key]: value, active_market });
    }

    this.setState({ [key]: value });
  }

  changeDropdownSelection(name, value) {
    this.setState({ [name]: value });
  }

  selectAllLocations(event) {
    const area_id = event.target.dataset["areaId"];
    var options = this.props.locations.filter(location => location.area_id == area_id);
    var location_ids = options.map((option) => option.value);
    var combinded_ids = this.state.location_ids.concat(location_ids);

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    const unique_ids = combinded_ids.filter(onlyUnique);
    this.setState({ location_ids: unique_ids });
  }

  changeDateField(date, name) {
    this.setState({ [name]: date });
  }

  finePrintDayUpdate(fine_print_days_object) {
    this.setState({ fine_print_days: fine_print_days_object });
  }

  onAddressUpdate(addresses_array) {
    this.setState({ addresses: addresses_array, unsaved_changes: true })
  }

  handleImageChange(image_data, image_url) {
    this.setState({ new_header_image: image_data, new_header_image_url: image_url });
  }

  onSectionUpdate(section, index) {
    var sections_copy = [...this.state.sections];
    sections_copy[index] = section;
    this.setState({ sections: sections_copy });
  }

  onSectionChangePosition(index, change = -1) {
    // If attempting to move to an index out of bounds, do nothing
    if (index + change < 0 || index + change >= this.state.sections.length) {
      return null
    }
    var sections_copy = [...this.state.sections];
    // Swap section positions
    var original_section = sections_copy[index];
    sections_copy[index] = sections_copy[index + change];
    sections_copy[index + change] = original_section;
    this.setState({ sections: sections_copy });
  }

  onSectionCreate() {
    var sections_copy = [...this.state.sections];
    this.new_section_count++;

    sections_copy.push({
      temp_id: "new-section-" + this.new_section_count,
      content_type: 'text'
    });
    this.setState({ sections: sections_copy });
  }

  onSectionDelete(index) {
    var sections_copy = [...this.state.sections];
    var section_to_remove = sections_copy[index];
    if (!section_to_remove.id) {
      sections_copy.splice(index, 1);
    } else {
      sections_copy[index]["_destroy"] = true;
    }
    this.setState({ sections: sections_copy });
  }

  clearMessage() {
    if (this.message_interval) {
      clearInterval(this.message_interval);
    }

    let status_message = JSON.parse(JSON.stringify(this.state.status_message))

    status_message.hide = true

    this.setState({ status_message })
  }

  // TODO Update params to match OFFER
  saveOfferChanges() {
    var path = "/offers/" + this.props.id;
    var form_builder = new FormBuilder();

    this.clearMessage();

    var sections_copy = [...this.state.sections];
    for (let [i, section] of sections_copy.entries()) {
      section.position = i;
      if (section.image) {
        section.image_id_to_attach = section.image.id;
        delete section.image;
      }
    }

    let expensive_state = this.state.pref_preference_level ? 2 : 1

    var form_object = {
      offer: {
        name: this.state.name,
        business_name: this.state.business_name,
        instagram_link: this.state.instagram_link,
        business_teaser: this.state.business_teaser,
        website: this.state.website.trim(),
        description: this.state.description,
        reserve_a_table_link: this.state.reserve_a_table_link.trim(),
        google_review_url: this.state.google_review_url.trim(),
        offer_amount: this.state.offer_amount,
        send_sms: this.state.send_sms,
        custom_sms_message: this.state.custom_sms_message,
        location_ids: this.state.location_ids,
        category_ids: this.state.category_ids,
        tag_ids: this.state.tag_ids,
        fine_print_days: JSON.stringify(this.state.fine_print_days),
        addresses_attributes: this.state.addresses,
        sections_attributes: sections_copy,
        dont_forget_item_ids: this.state.dont_forget_item_ids,
        pref_value: this.state.pref_value,
        pref_min_capacity: this.state.pref_min_capacity,
        pref_target_capacity: this.state.pref_target_capacity,
        pref_max_capacity: this.state.pref_max_capacity,
        auto_grat: this.state.auto_grat,
        extendable: this.state.extendable,
        extendable_through: this.state.extendable_through,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        menu_button: this.state.menu_button.trim(),
        web_button: this.state.web_button.trim(),
        instagram_button: this.state.instagram_button.trim(),
        important_notes: this.state.important_notes,
        is_dinein_only: this.state.is_dinein_only,
        pref_preference_level: expensive_state,
        offer_type_id: this.state.offer_type_id,
        hubspot_id: this.state.hubspot_id,
        video_url: this.state.video_url.trim()
      }
    }
    if (this.state.new_header_image) {
      form_object.event.header_image = this.state.new_header_image;
    }

    var form_data = form_builder.createForm(form_object);

    $.ajax({
      method: "PUT",
      url: path,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        let status_message = {
          hide: false,
          message: "Saved",
          warning: false
        }

        if (data.notice) {
          status_message.message = data.notice;
          status_message.warning = true;
        }

        if (this.message_interval) {
          clearInterval(this.message_interval);
        }

        if (!data.notice) {
          this.message_interval = setInterval(this.clearMessage, this.show_save_message_for);
        }

        let is_expensive = data.pref_preference_level == 2;

        this.setState({
          name: data.name || '',
          business_name: data.business_name || '',
          business_teaser: data.business_teaser || '',
          instagram_link: data.instagram_link || '',
          website: data.website || '',
          description: data.description || '',
          reserve_a_table_link: data.reserve_a_table_link || '',
          google_review_url: data.google_review_url || '',
          last_saved_status: data.status,
          offer_amount: data.offer_amount,
          send_sms: data.send_sms,
          custom_sms_message: data.custom_sms_message,
          addresses: data.addresses,
          sections: data.sections,
          fine_print_days: data.fine_print_days,
          dont_forget_item_ids: data.dont_forget_item_ids,
          pref_value: data.pref_value,
          pref_min_capacity: data.pref_min_capacity,
          pref_target_capacity: data.pref_target_capacity,
          pref_max_capacity: data.pref_max_capacity,
          auto_grat: data.auto_grat,
          offer_type_id: data.offer_type_id,
          extendable: data.extendable,
          extendable_through: convertDateString(data.extendable_through),
          start_date: convertDateString(data.start_date),
          end_date: convertDateString(data.end_date),
          menu_button: data.menu_button || '',
          web_button: data.web_button || '',
          instagram_button: data.instagram_button || '',
          important_notes: data.important_notes,
          is_dinein_only: data.is_dinein_only,
          video_url: data.video_url || '',
          unsaved_changes: false,
          hubspot_id: data.hubspot_id,
          hubspot_name: data.hubspot_name,
          status_message,
          pref_preference_level: is_expensive
        })
        this.has_start_date = data.start_date ? true : false;
      },
      error: (error) => {
        self.setState({ error_message: error.responseJSON.message, error_key: error.responseJSON.key });
      }
    });
  }

  renderStatus() {
    if (this.state.start_date == null) {
      return (<span>Not Active</span>)
    } else if (this.state.start_date > new Date()) {
      return (<span className='admin__app-pill'>Upcoming</span>)
    } else if (this.state.end_date == null || this.state.end_date > new Date()) {
      return (<span className='admin__app-pill pill-green'>Running</span>)
    } else {
      return (<span className='admin__app-pill pill-red'>Done</span>)
    }
  }

  announceNewPartner() {
    if (!window.confirm(`Are you sure? This will send a message to ${this.props.accounts_in_same_area_count} members`)) {
      return false;
    }
    this.setState({ network_busy: true });
    $.ajax({
      method: "POST",
      url: `/offers/${this.props.id}/announce_new_partner`,
      processData: false,
      contentType: false,
      success: (data) => {
        this.setState({ network_busy: false, new_partner_announced_date: data.new_partner_announced_date });
        showFlashMessage(data.message);
      },
      error: () => {
        this.setState({ network_busy: false });
        showFlashErrorMessage('Something went wrong');
      }
    });
  }

  renderSections() {
    let minimized = this.first_load;
    this.first_load = false;

    return (this.state.sections.map((section, index) =>
      <ContentSection
        key={section.id || section.temp_id}
        index={index}
        section={section}
        section_icons={this.props.section_icons}
        max_index={this.state.sections.length - 1}
        sectionUpdated={this.onSectionUpdate}
        sectionPositionChange={this.onSectionChangePosition}
        sectionDeleted={this.onSectionDelete}
        minimized={minimized}
      />
    ))
  }

  // TODO Reformat dispaly
  render() {
    return (
      <div style={{ width: '100%' }}>
        <div className="admin__page-header react-header-container" style={{ width: 'calc(100% - 185px)' }}>
          <div className="admin__page-actions" style={{ width: '100%' }}>
            <button
              className={'admin__actionbar__preview'}
              onClick={this.saveOfferChanges}>
              Save
            </button>
            <div
              style={{ opacity: (this.state.status_message.hide ? 0 : 1) }}
              className={"offer_status_dialog" + (this.state.status_message.warning ? " warning" : "")}>
              {this.state.status_message.message}
            </div>
            <div style={{ float: 'right' }}>
              <a href={"/vendor_app/" + this.props.id} target="_blank" >
                <button
                  className={'admin__actionbar__preview'}>
                  Redeem
                </button>
              </a>
              <a href={"/offers/" + this.props.id + "/partner_reports/offer_index"} target="_blank" >
                <button
                  className={'admin__actionbar__preview'}>
                  Reports
                </button>
              </a>
              <a href={"/offers/" + this.props.id + "/preview"} target="_blank" >
                <button
                  className={'admin__actionbar__preview'}>
                  Preview
                </button>
              </a>
              <a href={"/offers/" + this.props.id + "/edit"}>(old editor)</a>
            </div>
          </div>
        </div>
        <div className='builder builder--experience builder__multi-col react-body-container'>
          <div className='multi-col__size-5'>
            <div className="comp-grid">
              <h3>Offer Info</h3>
              <div>
                <label className='builder__input-label'>Internal Name</label><br />
                <input
                  name='name'
                  className=''
                  type="text"
                  placeholder="Offer Name"
                  value={this.state.name}
                  onChange={this.changeInputField} />
              </div>
              <div>
                <label className='builder__input-label'>Name</label><br />
                <input
                  name='business_name'
                  className='builder__textarea'
                  type="text"
                  placeholder="Offer Name"
                  value={this.state.business_name}
                  onChange={this.changeInputField} />
              </div>
              <div>
                <label className='builder__input-label'>Teaser</label><br />
                <input
                  name='business_teaser'
                  className='builder__textarea'
                  type="text"
                  placeholder="Teaser"
                  value={this.state.business_teaser}
                  onChange={this.changeInputField} />
              </div>
              <label className='builder__input-label'>Offer Amount</label><br />
              <div>
                <input
                  id="offer_amount"
                  name="offer_amount"
                  type="text"
                  className="builder__textarea"
                  value={this.state.offer_amount}
                  onChange={this.changeInputField} />
              </div>
            </div>

            <div className='med-spaced comp-grid'>
              <h3>Locations</h3>
              <LocationPicker
                areas={this.props.areas}
                locations={this.props.locations}
                location_ids={this.state.location_ids}
                selectAllLocations={this.selectAllLocations}
                updateStateItem={this.updateStateItem}
              />
            </div>

            <div className='med-spaced comp-grid'>
              <h3>Address(es)</h3>
              <MultiPlacesAutocomplete
                addresses={this.state.addresses}
                onAddressUpdate={this.onAddressUpdate}
                unsaved={this.state.unsaved_changes}
              />
            </div>

            <div className='med-spaced comp-grid'>
              <h3>Tags</h3>
              <FormSelect
                options={this.props.tags}
                name="tag_ids"
                className='large-input'
                multiselect={true}
                value={this.state.tag_ids}
                onChange={this.changeDropdownSelection} />
            </div>

            <div className='med-spaced comp-grid'>
              <h3>Categories</h3>
              <FormSelect
                options={this.props.categories}
                name="category_ids"
                className='large-input'
                multiselect={true}
                value={this.state.category_ids}
                onChange={this.changeDropdownSelection} />
            </div>

            <div className='med-spaced comp-grid' style={{ display: "flex", alignItems: "flex-end" }}>
              <div>
                <div>
                  Status: {this.renderStatus()}
                </div>
                {(!this.has_start_date && this.state.start_date) &&
                  <div style={{ color: "#dd0404", fontWeight: "500", width: "300px" }}>Warning: By setting the Start Date for this Offer it will appear in the "Upcoming Offers" section of the app. Please be sure to remove this value if the Offer is not ready to be shown.</div>
                }
                <h3>Start Date</h3>
                <DatePicker
                  name="start_date"
                  disableClock={true}
                  value={this.state.start_date}
                  onChange={value => { this.updateStateItem("start_date", value) }} />
              </div>
              <div style={{ marginLeft: "24px" }}>
                <h3>End Date</h3>
                <DatePicker
                  name="end_date"
                  disableClock={true}
                  value={this.state.end_date}
                  onChange={value => { this.updateStateItem("end_date", value) }} />
              </div>
            </div>

            <div className='med-spaced comp-grid'>
              <h3>Targets</h3>
              <div>
                <label className='builder__input-label'>Value</label><br />
                <input
                  name='pref_value'
                  className='builder__textarea'
                  type="text"
                  placeholder="Offer Value"
                  value={this.state.pref_value}
                  onChange={this.changeInputField} />
              </div>
              <div>
                <label className='builder__input-label'>Min Capacity</label><br />
                <input
                  name='pref_min_capacity'
                  className='builder__textarea'
                  type="text"
                  placeholder="Offer Value"
                  value={this.state.pref_min_capacity}
                  onChange={this.changeInputField} />
              </div>
              <div>
                <label className='builder__input-label'>Target Capacity</label><br />
                <input
                  name='pref_target_capacity'
                  className='builder__textarea'
                  type="text"
                  placeholder="Offer Value"
                  value={this.state.pref_target_capacity}
                  onChange={this.changeInputField} />
              </div>
              <div>
                <label className='builder__input-label'>Max Capacity</label><br />
                <input
                  name='pref_max_capacity'
                  className='builder__textarea'
                  type="text"
                  placeholder="Offer Value"
                  value={this.state.pref_max_capacity}
                  onChange={this.changeInputField} />
              </div>
            </div>

            <div className='med-spaced comp-grid'>
              <h3>Options</h3>
              <div>
                <input
                  name="auto_grat"
                  id="auto_grat"
                  type="checkbox"
                  value="Dinein Only"
                  checked={this.state.auto_grat}
                  onChange={this.changeInputField} />
                <label htmlFor="auto_grat">Auto Gratuity</label>
              </div>
              <div>
                <input
                  name="is_dinein_only"
                  id="is_dinein_only"
                  type="checkbox"
                  value="Dinein Only"
                  checked={this.state.is_dinein_only}
                  onChange={this.changeInputField} />
                <label htmlFor="is_dinein_only">Is Dine-In Only? (Adds Dine-In Pill)</label>
              </div>
              <div>
                <input
                  name="pref_preference_level"
                  id="pref_preference_level"
                  type="checkbox"
                  value="Expensive"
                  checked={this.state.pref_preference_level}
                  onChange={this.changeInputField} />
                <label htmlFor="pref_preference_level">Expensive?</label>
              </div>
              <div>
                <input
                  name="extendable"
                  id="extendable"
                  type="checkbox"
                  value="Extendable"
                  checked={this.state.extendable}
                  onChange={this.changeInputField} />
                <label htmlFor="extendable">This Offer can be extended?</label>
              </div>
              {this.state.extendable &&
                <>
                  <label className="builder__input-label">Can only be extended before this date if present</label><br />
                  <DatePicker
                    name="extendable_through"
                    value={this.state.extendable_through}
                    onChange={value => { this.changeDateField(value, "extendable_through") }} />
                </>
              }
            </div>

            <div className='med-spaced comp-grid'>
              <div className="builder__input-block--inline">
                <img style={{ maxWidth: '120px', padding: '5px 20px 5px' }} src="https://premium-production.s3.amazonaws.com/public-assets/newsletter/Menu.png" />
                <input
                  name='menu_button'
                  className='builder__textarea'
                  type="text"
                  placeholder="Add a link here"
                  value={this.state.menu_button}
                  onChange={this.changeInputField} />
              </div>
              <div className="builder__input-block--inline">
                <img style={{ maxWidth: '120px', padding: '5px 20px 5px' }} src="https://premium-production.s3.amazonaws.com/public-assets/newsletter/Web.png" />
                <input
                  name='web_button'
                  className='builder__textarea'
                  type="text"
                  placeholder="Add a link here"
                  value={this.state.web_button}
                  onChange={this.changeInputField} />
              </div>
              <div className="builder__input-block--inline">
                <img style={{ maxWidth: '120px', padding: '5px 20px 5px' }} src="https://premium-production.s3.amazonaws.com/public-assets/newsletter/Instagram.png" />
                <input
                  name='instagram_button'
                  className='builder__textarea'
                  type="text"
                  placeholder="Add a link here"
                  value={this.state.instagram_button}
                  onChange={this.changeInputField} />
              </div>

              <div className="builder__input-block--inline">
                <div style={{ maxWidth: '120px', padding: '5px 20px 5px', textAlign: 'center' }}>Reserve a Table</div>
                <input
                  name='reserve_a_table_link'
                  className='builder__textarea'
                  type="text"
                  placeholder="Add a link here"
                  value={this.state.reserve_a_table_link}
                  onChange={this.changeInputField} />
              </div>

              <div className="builder__input-block--inline">
                <div style={{ maxWidth: '120px', padding: '5px 20px 5px', textAlign: 'center' }}>Google Review Link</div>
                <input
                  name='google_review_url'
                  className='builder__textarea'
                  type="text"
                  placeholder="Add a link here"
                  value={this.state.google_review_url}
                  onChange={this.changeInputField} />
              </div>


            </div>

            <div className='med-spaced comp-grid'>
              <h3>Offer Content:</h3>
              {this.renderSections()}
              <button
                style={{ marginTop: '10px' }}
                className="standard-btn--rounded"
                onClick={this.onSectionCreate}>
                Add New Section
              </button>
              <hr />
            </div>

            <div className='med-spaced comp-grid'>
              <h3>Fine Print Days</h3>
              <FinePrintDays
                fine_print_days={this.state.fine_print_days}
                onChange={this.finePrintDayUpdate}
              />
            </div>
            <div className='med-spaced comp-grid'>
              <label className='builder__input-label'>Important Notes (Only shown when filled out. Highlighted box)</label><br />
              <FormMarkdownInput
                name="important_notes"
                value={this.state.important_notes}
                onChange={this.updateStateItem} />
            </div>
            <div className='med-spaced comp-grid'>
              <h3>Don't Forget</h3>
              <DontForgetItemSelection
                dont_forget_items={this.props.dont_forget_items}
                selected_items={this.props.dont_forget_item_ids}
                onChecklistChange={this.updateStateItem} />
            </div>
            <div className='med-spaced comp-grid'>
              <h3>Offer Type</h3>
              <OfferTypeSelection
                offer_types={this.props.offer_types}
                offer_type_id={this.state.offer_type_id}
                onChange={this.updateStateItem} />
            </div>
            <div className='med-spaced comp-grid'>
              <h4>SMS Options</h4>
              <div>
                <input
                  id="event-has-sms"
                  name="send_sms"
                  type="checkbox"
                  value="Check In SMS"
                  checked={this.state.send_sms}
                  onChange={this.changeInputField} />
                <span>Check In SMS</span>
              </div>
              {this.state.send_sms &&
                <textarea
                  className='builder__textarea'
                  id="event-sms-message"
                  name="custom_sms_message"
                  value={this.state.custom_sms_message}
                  onChange={this.changeInputField}
                  style={{ width: '100%', boxSizing: 'border-box', height: "20em", resize: "none" }} />
              }
              {
                !this.state.send_sms &&
                <div style={{ width: '100%', height: "17em", marginBottom: "0.625rem" }}></div>
              }
            </div>
            {this.props.accounts_in_same_area_count > 0 &&
              <div className='med-spaced comp-grid'>
                <h3>Advanced: {this.props.market_name}</h3>
                <div>
                  {this.state.new_partner_announced_date && 
                    <div>Announced: {this.state.new_partner_announced_date}</div>
                  }

                  <button
                    className="standard-btn--rounded"
                    onClick={this.announceNewPartner}
                    disabled={this.state.new_partner_announced_date || this.state.network_busy}
                  >
                    Announce New Partner
                  </button>
                </div>
              </div>
            }
          </div>

          { /* Right Side of editor */}
          <div className='multi-col__size-4' style={{ minWidth: "450px" }}>
            <div>
              <h4>Hubspot Id
              {this.state.hubspot_name &&
                <span> — linked to "{this.state.hubspot_name}"</span>
              }
              {this.state.hubspot_ig &&
                <span> — linked to IG @"{this.state.hubspot_ig}"</span>
              }
              </h4>
              <input
                name='hubspot_id'
                className='builder__textarea'
                type="text"
                placeholder="Hubspot Company Id"
                value={this.state.hubspot_id}
                onChange={this.changeInputField} />
            </div>
            <div>
              <h4>Mux Video URL</h4>
              <input
                name='video_url'
                className='builder__textarea'
                type="text"
                placeholder="Video URL"
                value={this.state.video_url}
                onChange={this.changeInputField} />
            </div>
            <div>
              <br></br>
              <h4>Photo(s)</h4>
              <ImageCollectionManager
                imageable_id={this.props.id}
                imageable_type={"Offer"}
                images={this.props.images} />
            </div>
            <div style={{ marginTop: "20px" }}>
              <OfferAppPreview
                id={this.props.id} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OfferEdit