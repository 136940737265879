import React from "react";

// If the RelayServerClient disconnects, then attempt
// to reconnect at increasing time intervals
const RECONNECT_TIMES_MS = [0, 1000, 2000, 3000, 5000, 8000, 10000]

class RelayServerClient extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.webSocketWatchDog = this.webSocketWatchDog.bind(this);
    this.mountWebsocket = this.mountWebsocket.bind(this);
    this.intervalTime = this.intervalTime.bind(this);
    this.onUpdateMessage = this.onUpdateMessage.bind(this);
    this.onVersionMessage = this.onVersionMessage.bind(this);

    this.state = {
      show_connected: false
    }

    this.WS_URL = props.relay_url.replace('http://', 'ws://').replace('https://', 'wss://') + "/wsc?channelname=VendorChannel";
    this.can_connect = props.offer_id && props.relay_url;
    this.is_first = true;
    this.ws = null;
    this.ws_is_connected = false;
    this.attempt_number = 0;
    this.attempt_milliseconds = 0;
  }

  componentDidMount() {
    // If valid props have been provided, start the watchdog
    // process
    this.setState({show_connected: false})
    if (this.can_connect) setInterval(this.webSocketWatchDog, 1000);
  }

  webSocketWatchDog() {
    // This process runs once every second.  Check if the
    // websocket is connected and if it is not, wait until
    // the class can try again.

    if (this.ws_is_connected) {
      return true;
    }

    let wait_ms_to_run = this.intervalTime();

    if (this.attempt_milliseconds >= wait_ms_to_run) {
      this.attempt_number++;
      this.attempt_milliseconds = 1000;
      this.mountWebsocket();
    } else {
      this.attempt_milliseconds += 1000;
    }
  }

  mountWebsocket() {
    // If no offer_id is provided, do not attempt to
    // connect to the relay server
    if (!this.can_connect) return false;

    // If a websocket is already defined and is struggling
    // on the attempting_recconect, close it before opening
    // a new one.
    if (this.ws && this.ws.readyState == 0) {
      this.ws.close();
    }

    this.ws = new WebSocket(this.WS_URL);

    this.ws.onopen = () => {
      // After successfully connecting to the relay server
      // reset the attempts and set ws_is_connected to true
      console.log("Successfully connected to WebSocket")
      this.attempt_number = 0;
      this.attempt_milliseconds = 0;
      this.ws_is_connected = true;
      this.setState({show_connected: true});

      // If we successfully connect any time after
      // first loading the page, then we should
      // retrieve some fresh data from the server.
      if (!this.is_first) {
        this.onUpdateMessage();
      } else {
        this.is_first = false;
      }

      // Create the subscription message
      let msg = {
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: 'VendorChannel',
          vendor_id: this.props.offer_id
        }),
      };

      this.ws.send(JSON.stringify(msg));

      // Whenever the websocket closes after successfully
      // connecting, make sure to set ws_is_connected to
      // false
      this.ws.onclose = () => {
        this.ws_is_connected = false;
        this.setState({show_connected: false});
      };
    };

    this.ws.onmessage = event => {
      const data = JSON.parse(event.data);

      // If the websocket recieves an update message,
      // then trigger the 'onUpdateMessage' method.
      if (data.message) {
        if (data.message.update) {
          this.onUpdateMessage();
        }
        if (data.message.version) {
          this.onVersionMessage(data.message.version);
        }
      }
    }

    this.ws.onerror = (err) => {
      console.error('Socket encountered error: ', err.message, 'Closing socket');
      this.ws.close();
    };

  }

  onUpdateMessage() {
    var offer_assignment = window.location.pathname.split('/').at(-1);
    $.ajax({
      url: "/vendor_app/" + offer_assignment,
      type: 'GET',
      dataType: 'script'
    }).done(function (resp) {
      eval(resp);
    }).fail(function (resp) {
      eval(resp.responseText);
    })
  }

  onVersionMessage(version_id_string) {
    if (version_id_string != VENDOR_APP_VERSION) {
      window.location.href = window.location.href;
    }
  }

  intervalTime() {
    if (this.attempt_number >= RECONNECT_TIMES_MS.length) {
      return RECONNECT_TIMES_MS[RECONNECT_TIMES_MS.length - 1];
    }

    return RECONNECT_TIMES_MS[this.attempt_number];
  }

  render() {
    return (
      <div
        className={
          "websocket-status " +
          (this.state.show_connected ? "ws-connected" : "ws-disconnected")}>
        © 2022 Offline Media, Inc.
      </div>
    )
  }
}

export default RelayServerClient;