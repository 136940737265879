import React from "react";
import OfferAssignmentReviews from "./offer_assignment_reviews";
import FormSelect from "../shared/form_select";
import FormBuilder from "../shared/form_builder";
import moment from 'moment-timezone';
import ToggleSwitch from "../shared/toggle_switch";

class MarketReviews extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewed_assignments: props.reviewed_assignments,
      from_date: "last_week",
      ready_ids: [],
      unready_ids: [],
      unsaved_changes: false,
      hide_duplicates: false,
      show_only_newsletter_ready: false
    }

    this.set_range = this.set_range.bind(this);
    this.change_market = this.change_market.bind(this);
    this.toggle_newsletter = this.toggle_newsletter.bind(this);
    this.get_updated_reviews = this.get_updated_reviews.bind(this);
    this.update_reviews = this.update_reviews.bind(this);
    this.hide_duplicates = this.hide_duplicates.bind(this);
    this.show_only_newsletter_ready = this.show_only_newsletter_ready.bind(this);
    this.force_sync = this.force_sync.bind(this);
  }

  componentDidMount() {
    let local_settings = localStorage.getItem("market_reviews");
    if (local_settings) {
      let update_state = this.state;
      update_state = { ...update_state, ...JSON.parse(local_settings) };
      this.setState(update_state);
    } else {
      localStorage.setItem("market_reviews", JSON.stringify({
        hide_duplicates: this.state.hide_duplicates,
        show_only_newsletter_ready: this.state.show_only_newsletter_ready
      }));
    }
  }

  hide_duplicates() {
    let hide_duplicates = this.state.hide_duplicates;
    this.setState({ hide_duplicates: !hide_duplicates });
    localStorage.setItem("market_reviews", JSON.stringify({ hide_duplicates: !hide_duplicates, show_only_newsletter_ready: this.state.show_only_newsletter_ready }));
  }

  show_only_newsletter_ready() {
    let show_only_newsletter_ready = this.state.show_only_newsletter_ready;
    this.setState({ show_only_newsletter_ready: !show_only_newsletter_ready });
    localStorage.setItem("market_reviews", JSON.stringify({ hide_duplicates: this.state.hide_duplicates, show_only_newsletter_ready: !show_only_newsletter_ready }));
  }

  change_market(key, value) {
    if(this.state.unsaved_changes && !confirm("You have unsaved changes. Are you sure you want to leave?")) {
      return;
    }

    window.location.href = "/market_reviews/" + value;
  }

  toggle_newsletter(offer_assignment) {
    let ready_ids = [...this.state.ready_ids];
    let unready_ids = [...this.state.unready_ids];

    let server_ready_value = offer_assignment.newsletter_ready ? true : false;
    let unsaved_changes = this.state.unsaved_changes;

    if (ready_ids.includes(offer_assignment.id)) {
      ready_ids = ready_ids.filter(id => id != offer_assignment.id);
      if (server_ready_value) {
        unready_ids.push(offer_assignment.id);
      }
    } else if (unready_ids.includes(offer_assignment.id)) {
      unready_ids = unready_ids.filter(id => id != offer_assignment.id);
      if (!server_ready_value) {
        ready_ids.push(offer_assignment.id);
      }
    } else if (server_ready_value) {
      unready_ids.push(offer_assignment.id);
    } else if (!server_ready_value) {
      ready_ids.push(offer_assignment.id);
    }

    unsaved_changes = ready_ids.length > 0 || unready_ids.length > 0;

    this.setState({ ready_ids, unready_ids, unsaved_changes });
  }

  set_range(key, value) {
    if(this.state.unsaved_changes && !confirm("You have unsaved changes. If you change the review date scope your changes will be lost. Continue?")) {
      return;
    }
    this.get_updated_reviews(value);
  }

  get_updated_reviews(from_date) {
    let path = this.props.endpoints.get_reviewed_assignments;
    path += "?from_date=" + from_date;

    $.ajax({
      method: "GET",
      url: path,
      success: (data) => {
        this.setState({ reviewed_assignments: data.reviewed_assignments, from_date: from_date });
      },
      error: (error) => {
        showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }

  update_reviews() {
    if (!this.state.unsaved_changes) {
      return;
    }

    let formBuilder = new FormBuilder();
    let review_data = {
      ready_ids: this.state.ready_ids
    }

    if (this.state.unready_ids.length > 0) {
      review_data.unready_ids = this.state.unready_ids;
    }

    var form_data = formBuilder.createForm(review_data);

    $.ajax({
      method: "POST",
      url: this.props.endpoints.mark_as_newsletter_ready,
      data: form_data,
      processData: false,
      contentType: false,
      success: (data) => {
        let reviewed_assignments = JSON.parse(JSON.stringify(this.state.reviewed_assignments));
        let newsletter_ready_reviews = reviewed_assignments.filter(assignment => this.state.ready_ids.includes(assignment.id));
        let newsletter_unready_reviews = reviewed_assignments.filter(assignment => this.state.unready_ids.includes(assignment.id));
        newsletter_ready_reviews.forEach(assignment => {
          assignment.newsletter_ready = moment().format("YYYY-MM-DD");
        });
        newsletter_unready_reviews.forEach(assignment => {
          assignment.newsletter_ready = null;
        });

        this.setState({ reviewed_assignments: reviewed_assignments, ready_ids: [], unready_ids: [], unsaved_changes: false});
        showFlashMessage("Newsletter Reviews Updated!     Reminder: The server is set to sync with Customer.io once overnight.");
      },
      error: (error) => {
        showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }

  force_sync() {
    $.ajax({
      method: "POST",
      url: this.props.endpoints.sync_newsletter_with_cio,
      data: {},
      processData: false,
      contentType: false,
      success: (data) => {
        showFlashMessage("Synced with Customer.io!");
      },
      error: (error) => {
        showFlashErrorMessage(error.responseJSON.message)
      }
    });
  }

  render() {
    let areas = [...this.props.areas];
    areas.sort((a, b) => a.name.localeCompare(b.name));
    let current_area = areas.find(area => area.id == this.props.area.id);
    areas = [current_area, ...areas.filter(area => area.id != this.props.area.id)]

    let reviewed_assignments = [...this.state.reviewed_assignments];
    if (this.state.hide_duplicates) {
      let marked_for_newsletter = reviewed_assignments.filter(assignment => (assignment.newsletter_ready || this.state.ready_ids.includes(assignment.id)));
      let whitelist = marked_for_newsletter.map(assignment => assignment.id);
      let used_account_ids = marked_for_newsletter.map(assignment => assignment.account.id);
      reviewed_assignments = reviewed_assignments.filter(assignment => whitelist.includes(assignment.id) || !used_account_ids.includes(assignment.account.id));
    }
    if (this.state.show_only_newsletter_ready) {
      reviewed_assignments = reviewed_assignments.filter(assignment => {
        if (assignment.newsletter_ready) {
          return true;
        }
        if (this.state.ready_ids.includes(assignment.id)) {
          return true;
        }
      });
    }

    let range_options = [
      { value: "last_week", label: "Last Week" },
      { value: "last_month", label: "Last Month" },
      { value: "last_year", label: "Last Year" },
      { value: "all_time", label: "All Time" }
    ]

    return (
      <div style={{ margin: "20px" }}>
        <div className="market-header-area">
          <h1>{this.props.area.name + ", " + this.props.area.state + " Reviews:"}</h1>
          <div className="right-buttons">
            <button className="admin__actionbar__preview" onClick={this.force_sync}>Force Update CIO Collection</button>
            <button className="admin__actionbar__preview" disabled={!this.state.unsaved_changes} onClick={this.update_reviews}>Save Changes</button>
          </div>
        </div>
        <div className="market-options" style={{ margin: "20px 0", display: "flex", gap: "18px" }}>
          <div className="market-option">
            <div style={{ fontWeight: 600 }}>
              {"Area: "}
            </div>
            <FormSelect
              options={areas}
              name="perk_category_ids"
              id="perk_location_ids"
              className='area-select large-input'
              value={this.props.area.slug}
              onChange={this.change_market} />
          </div>
          <div className="market-option">
            <div style={{ fontWeight: 600 }}>Reviews from:</div>
            <FormSelect
              options={range_options}
              name="from_date"
              id="from_date"
              className='area-select large-input'
              value={this.state.from_date}
              onChange={this.set_range} />
          </div>
          <div className="market-option">
            <div style={{ fontWeight: 600, width: "200px" }}>
              Hide Other Reviews from the Same Member
            </div>
            <ToggleSwitch
              value={this.state.hide_duplicates}
              onChange={this.hide_duplicates}
            />
          </div>
          <div className="market-option">
            <div style={{ fontWeight: 600, width: "200px" }}>
              Show Only Newsletter Ready
            </div>
            <ToggleSwitch
              value={this.state.show_only_newsletter_ready}
              onChange={this.show_only_newsletter_ready}
            />
          </div>
        </div>
        <OfferAssignmentReviews
          offer_assignment={reviewed_assignments}
          ready_ids={this.state.ready_ids}
          unready_ids={this.state.unready_ids}
          highly_reviewed={true}
          display_min={true}
          class_name="market-reviews"
          toggle_newsletter={this.toggle_newsletter}
        />
      </div>
    )
  }
}

export default MarketReviews;