import React from "react";

class CategoryToggle extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div key={this.props.id}>
        <hr className='subtle-divider'/>
        <div className="one-line">
          <div style={{width: "80%"}}>
            <h5>{this.props.name}</h5>
            <div>{this.props.description}</div>
          </div>
          <div style={{width: "20%"}} className='text-right'>
            <label className="switch">
              <input 
                type="checkbox"
                value={this.props.id}
                disabled={this.props.name == 'Offline Picks' ? 'disabled' : ''}
                checked={this.props.isChecked}
                onChange={this.props.handleOnChange} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryToggle