import React from "react";

class PopupBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: '',
      is_showing: false,
      x_pos: 0,
      y_pos: 0,
      is_raw_html: false
    }

    this.addNewContent = this.addNewContent.bind(this);
    this.closePopup = this.closePopup.bind(this);
    Popup.initializePopup(this);
  }

  addNewContent(new_data, x = 0, y = 0) {
    this.setState({ content: new_data, is_showing: true, x_pos: x, y_pos: y, is_raw_html: false });
  }

  addNewHtmlContent(new_data, x = 0, y = 0) {
    this.setState({ content: new_data, is_showing: true, x_pos: x, y_pos: y, is_raw_html: true });
  }

  closePopup() {
    this.setState({ is_showing: false });
  }

  renderContent() {
    if (this.state.is_raw_html) {
      return (<div dangerouslySetInnerHTML={{ __html: this.state.content }} />);
    } else {
      return (this.state.content);
    }
  }

  render() {
    return (
      <div className='member__app-popup-wrapper' style={{ display: this.state.is_showing ? "block" : "none", top: this.state.y_pos, left: this.state.x_pos }}>
        {this.state.is_showing &&
          <div className="member__app-popup">
            <div className="popup-close" onClick={this.closePopup}>✖</div>
            {this.renderContent()}
          </div>
        }
      </div>
    );
  }
}

export default PopupBox