import React from "react";
import ImageEditor from "./image_editor"
import FormBuilder from "../shared/form_builder";

class ImageCollectionManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing_image: props.images && props.images.length ? props.images[0] : null,
      images: props.images
    };
    this.hiddenFileInput = React.createRef();


    // this.changeInputField = this.changeInputField.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.triggerImageSelection = this.triggerImageSelection.bind(this);
    this.openImageEdit = this.openImageEdit.bind(this);
    this.getFirstImage = this.getFirstImage.bind(this);
    this.handleImageUpdate = this.handleImageUpdate.bind(this);
    this.handleImageDelete = this.handleImageDelete.bind(this);
    this.handleNewOrder = this.handleNewOrder.bind(this);
  }

  imageById(id) {
    return this.state.images.filter(image => image.id == id)[0] || null
  }

  getFirstImage(image_array = this.state.images) {
    return image_array[0] || null
  }

  openImageEdit(event) {
    const image_id = event.target.dataset["imageId"];
    this.setState({ editing_image: this.imageById(image_id) });
  }

  // changeInputField(event) {
  //   const target = event.target;
  //   const name = target.name;

  //   this.setState({ [name]: target.value });
  // }

  handleImageUpload(event) {
    if (event.target.files && event.target.files[0]) {
      const image_data = event.target.files[0]
      // this.props.onImageUploaded(event.target.files[0], source_url);
      event.target.value = null;

      var form_builder = new FormBuilder();
      var form_object = {
        image: {
          imageable_id: this.props.imageable_id,
          imageable_type: this.props.imageable_type,
          header_image: image_data
        }
      }
      var form_data = form_builder.createForm(form_object);

      $.ajax({
        method: "POST",
        url: "/images",
        data: form_data,
        processData: false,
        contentType: false,
        success: (data) => {
          var new_images = [...this.state.images];
          new_images.push(data.image);
          this.setState({
            images: new_images,
            editing_image: data.image
          })
          if (this.props.onHeaderImageChange) {
            this.props.onHeaderImageChange(new_images[0] ? new_images[0].header_image_url : null);
          }
          showFlashMessage("Image Added");
        },
        error: (error) => {

        }
      });
    }
  }

  handleImageUpdate(new_image, keep_edit = false) {
    var old_image = this.imageById(new_image.id);
    var index = this.state.images.indexOf(old_image);
    var new_array = [...this.state.images];
    new_array[index] = new_image;
    var new_editing_image = new_image
    this.setState({ images: new_array, editing_image: new_editing_image });
    if (this.props.onHeaderImageChange) {
      this.props.onHeaderImageChange(new_array[0] ? new_array[0].header_image_url : null);
    }
  }

  handleNewOrder(new_images) {
    var new_edit = new_images.filter(image => image.id == this.state.editing_image.id)[0] || null
    this.setState({ images: new_images, editing_image: new_edit });
    if (this.props.onHeaderImageChange) {
      this.props.onHeaderImageChange(new_images[0] ? new_images[0].header_image_url : null);
    }
  }

  handleImageDelete(old_image_id) {
    var old_image = this.imageById(old_image_id);
    var index = this.state.images.indexOf(old_image);
    var new_array = [...this.state.images];
    new_array.splice(index, 1);

    this.setState({ images: new_array, editing_image: this.getFirstImage(new_array) });
    if (this.props.onHeaderImageChange) {
      this.props.onHeaderImageChange(new_array[0] ? new_array[0].header_image_url : null);
    }
  }

  triggerImageSelection() {
    this.hiddenFileInput.current.click();
  }

  renderAllImages() {
    return (this.state.images.map((image) =>
      <img
        className={(this.state.editing_image && this.state.editing_image.id == image.id) ? "selected" : ""}
        key={image.id}
        src={image.header_image_url}
        data-image-id={image.id}
        onClick={this.openImageEdit} />
    ));
  }

  renderImageEditing() {
    if (this.state.editing_image) {
      return (
        <ImageEditor
          key={this.state.editing_image.id}
          image_id={this.state.editing_image.id}
          header_image_url={this.state.editing_image.header_image_url}
          credit_text={this.state.editing_image.credit_text}
          link={this.state.editing_image.link}
          position={this.state.editing_image.position}
          highestPosition={this.state.images.length}
          onImageUpdate={this.handleImageUpdate}
          onImageRearrangement={this.handleNewOrder}
          onImageDelete={this.handleImageDelete} />
      )
    }
    return ""
  }

  render() {
    return (
      <div>
        <div className="admin__app-image-collection">
          {this.renderAllImages()}
          <div className="add-image" onClick={this.triggerImageSelection}>
            Add
            <input
              style={{ display: 'none' }}
              ref={this.hiddenFileInput}
              type="file"
              name="header_image"
              accept="image/png, image/jpeg"
              onChange={this.handleImageUpload} />
          </div>
        </div>
        {this.renderImageEditing()}
      </div>
    )
  }
}

export default ImageCollectionManager;