import React from "react";

class RadialGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      include_animation: this.props.include_animation || false
    }

    this.renderGraph = this.renderGraph.bind(this);
    this.renderGraphLegends = this.renderGraphLegends.bind(this);
    this.svg_container_ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.include_animation) {
      setTimeout(() => {
        this.setState({ include_animation: false });
      }, 20);
    }
  }

  renderGraph() {
    if (!this.props.graphs || this.props.graphs.length == 0) return (<></>);

    let total = this.props.graphs.map(graph => graph.value).reduce((a, b) => a + b, 0);

    let degree_offsets = this.props.graphs.map(graph => ((graph.value / total) * 100) * 3.6);
    let last_degree = -90;
    let translated_offsets = [];
    degree_offsets.forEach((offset, index) => {
      last_degree += (degree_offsets[index - 1] || 0);
      translated_offsets.push(last_degree);
    });

    return this.props.graphs.map((graph, index) => {
      return (
        <svg xmlns="http://www.w3.org/2000/svg"
          key={index} width="235" height="235" viewBox="0 0 235 235" fill="none"
          style={
            {
              transform: `rotate(${translated_offsets[index]}deg)`,
              "--offest-load-start": `${(translated_offsets[index] + 90) * 2}ms`
            }
          }>
          <path className={"radial-graph-stroke " + graph.class_color}
            strokeWidth="60" style={
              {
                strokeDasharray: 550,
                strokeDashoffset: (550 - (((graph.value / total) * 100) * 5.5)),
                "--offest-load-length": `${(degree_offsets[index] * 2) + 100}ms`,
                // "--offest-load-start": `${((translated_offsets[index] + 90) * 3)+100}ms`
              }
            } d="M205 117.5C205 128.991 202.737 140.369 198.339 150.985C193.942 161.601 187.497 171.247 179.372 179.372C171.247 187.497 161.601 193.942 150.985 198.339C140.369 202.737 128.991 205 117.5 205C106.009 205 94.6312 202.737 84.0152 198.339C73.3992 193.942 63.7533 187.497 55.6281 179.372C47.503 171.247 41.0578 161.601 36.6605 150.985C32.2632 140.369 30 128.991 30 117.5C30 106.009 32.2633 94.6312 36.6606 84.0152C41.0578 73.3992 47.5031 63.7533 55.6282 55.6281C63.7533 47.503 73.3992 41.0578 84.0152 36.6605C94.6312 32.2632 106.009 30 117.5 30C128.991 30 140.369 32.2633 150.985 36.6606C161.601 41.0579 171.247 47.5031 179.372 55.6282C187.497 63.7533 193.942 73.3993 198.339 84.0152C202.737 94.6312 205 106.009 205 117.5L205 117.5Z">
            <title>{graph.label}</title>
          </path>
        </svg>
      )
    });
  }

  renderGraphLegends() {
    return this.props.graphs.map((graph, index) => (
      <div className="legend-row" key={'l' + index}>
        <div className="legend-label">
          <div className={"swatch " + graph.class_color}></div>
          <div className="legend-text">{graph.label}</div>
        </div>
        <div className="legend-value">{graph.value + "%"}</div>
      </div>
    ));
  }

  render() {
    return (
      <div className="radial-graph-container">
        <div className="radial-graph-title">{this.props.graph_title}</div>
        <div ref={this.svg_container_ref} className={"svg-container" + (this.state.include_animation ? " include-animation" : "")}>
          {this.renderGraph()}
        </div>
        <div className="graph-legends">
          {this.renderGraphLegends()}
        </div>
      </div>
    )
  }
}

export default RadialGraph;