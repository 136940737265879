import React from "react";

class OfferTypeSelection extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(event) {
    this.props.onChange("offer_type_id", event.target.value);
  }

  render() {
    const items = this.props.offer_types.map((type) =>
      <div key={type.id}>
        <input 
          type="radio"
          value={type.id}
          checked={this.props.offer_type_id == type.id}
          onChange={this.handleOnChange} />
        <div style={{display: 'inline-block'}}>
          <img style={{maxWidth: '36px'}} src={type.icon_url} />
          <div style={{display: 'inline-block'}}>
            <div>{type.name}</div>
            <div>{type.subtitle}</div>
          </div>
        </div>
      </div>
    )

    return (
      <>
        <div>
          {items}
        </div>
      </>
    )
  }
}

export default OfferTypeSelection;