import React from "react";

import {trackScreenLoad} from "../utils/analytic_tracking.jsx";

class InfoScreenLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.scrollingElement.scrollTop = 0;
    if (this.props.analytic_tracking) {
      trackScreenLoad(this.props.analytic_tracking);
    }
  }

  componentWillUnmount() {
    if (this.props.customerio_tracking) {
      this.props.customerio_tracking();
    }
  }

  render() {
    let style_obj = {};
    if (this.props.sticky_footer_offset) {
      style_obj = {marginBottom: this.props.sticky_footer_offset};
    }

    return (
      <>
        {this.props.header}
        <div className="signup-column info-screen-layout" style={style_obj}>
          <div className='info-screen-layout-content'>
            { this.props.banner &&
              <div className="info-screen-layout-banner">{this.props.banner}</div>
            }
            { this.props.title &&
              <div className="info-screen-layout-title">{this.props.title}</div>
            }
            {this.props.children && 
              <div className="info-screen-layout-content">
                {this.props.children}
              </div>
            }
          </div>
        </div>
        {this.props.footer}
      </>
    );
  }
}

export default InfoScreenLayout